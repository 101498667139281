import {AccountCircleOutlined, InsertDriveFileOutlined,AssignmentOutlined} from "@mui/icons-material";
import CompanyProjectListing from "../Component/AboutCompanyDetails/CompanyProjectListing";
import AccountSetting from "../Component/AboutCompanyDetails/form/AccountSetting";
import BasicInformation from "../Component/AboutCompanyDetails/form/BasicInformation";

const menus = [
    {
        id: 'basic',
        name: 'Basic Information',
        icon: InsertDriveFileOutlined,
        component: BasicInformation,
        // code: 'Details'
    },
    {
        id: 'account',
        name: 'Account Setting',
        icon: AccountCircleOutlined,
        component: AccountSetting,
    },
    {
        id: 'projects',
        name: 'My Projects',
        icon: AssignmentOutlined,
        component: CompanyProjectListing,
    },
   
];

export default menus;