import React ,{useEffect,useState} from "react";
import {
  Button,
  Grid, 
  Card,
  CircularProgress,
} from "@mui/material";
import Input from "components/Input";
import { useFormik } from "formik";
import * as yup from "yup";
import PageTitle from "components/PageTitle";
import { GeneralActions, ProjectActions } from "slices/actions";
import { useHistory, useParams } from "react-router-dom";
import { GeneralSelector, ProjectSelector } from "selectors";
import { useDispatch, useSelector } from "react-redux";
import {toast} from "react-toastify";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

function ProjectWagesAdd() {
    const {id} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const userId = localStorage.getItem("userID")
    const projectDetails = useSelector(ProjectSelector.getProjectById());
    const success = useSelector(GeneralSelector.success(ProjectActions.createDailyWages.type));
    const error = useSelector(GeneralSelector.error(ProjectActions.createDailyWages.type))
    const loading = useSelector(GeneralSelector.loader(ProjectActions.createDailyWages.type));
    const successUpdate = useSelector(GeneralSelector.success(ProjectActions.updateDailyWages.type));
    const errorUpdate = useSelector(GeneralSelector.error(ProjectActions.updateDailyWages.type))
    const loadingUpdate = useSelector(GeneralSelector.loader(ProjectActions.updateDailyWages.type));
    const dailyWagesDetails = useSelector(ProjectSelector.getDailyWagesDetails())
    const [startDate,setStartDate] = useState();
    const location = useLocation();
    const data = location.state?.data;
    const key=Object.keys(data?.results)
    console.log("ProjectWagesdata",data?.results[0]?.applyEndDate)
    console.log("key",key)
    const lastKey = key[key.length - 1];

    useEffect(() => {
    if (id) {
        dispatch(ProjectActions.getDailyWagesDetails(id))
     } else {
        dispatch(ProjectActions.getDailyWagesDetailsSuccess({}))
      }
    },[id])
   
    const validationSchema = yup.object({
        startDate: yup.string().required('This field is required'),
        endDate: yup.string().required('This field is required'),
        dw_highSkilled: yup.string().required('This field is required'),
        dw_skilled: yup.string().required('This field is required'),
        dw_semiSkilled: yup.string().required('This field is required'),
        dw_unSkilled: yup.string().required('This field is required'),
    });  

    const formik = useFormik({
        initialValues: {
           dw_highSkilled: dailyWagesDetails?.dw_highSkilled??"",
           dw_skilled: dailyWagesDetails?.dw_skilled??"",
           dw_semiSkilled: dailyWagesDetails?.dw_semiSkilled??"",
           dw_unSkilled: dailyWagesDetails?.dw_unSkilled??"",
           startDate: dailyWagesDetails?.applyStartDate??"",
           endDate: dailyWagesDetails?.applyEndDate??""
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        validateOnChange: true,
        onSubmit: (values) => {
          handleSubmit(values);
        },
    });
    const handleSubmit = (values) => {
        if (id) {
            const params = {
                ...values,
                projectId: dailyWagesDetails?.projectId??"",
                updatedBy: dailyWagesDetails?.updatedBy??"",
                id: id
            }
            dispatch(ProjectActions.updateDailyWages(params));
        } else {
            const params = {
                ...values,
                projectId: projectDetails?.data?.project?._id,
                updatedBy: userId
            }
            dispatch(ProjectActions.createDailyWages(params))
        }
     
       
    };
    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(ProjectActions.createDailyWages.type));
            history.goBack();
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                position: "top-right",
                autoClose: 3000,
                color: "white",
                closeOnClick: true,
            });

            dispatch(GeneralActions.removeError(ProjectActions.createDailyWages.type));
            
        }
    }, [error]);

    useEffect(() => {
        if (successUpdate) {
            toast.success(`${successUpdate?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(ProjectActions.updateDailyWages.type));
            history.goBack();
        }
    }, [successUpdate]);

    useEffect(() => {
        if (errorUpdate) {
            toast.error(`${errorUpdate?.message ?? "Error"}`, {
                position: "top-right",
                autoClose: 3000,
                color: "white",
                closeOnClick: true,
            });

            dispatch(GeneralActions.removeError(ProjectActions.updateDailyWages.type));
            
        }
    }, [errorUpdate]);
    const handleChangeStartDate = (e) => {
        const date = e.target.value 
        formik.setFieldValue('startDate', date);
        setStartDate(date)
      }
  return (
      <>
        <PageTitle isBack={true} title='Wages' />
        <Card>
             <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Start Date"
                            name='startDate'
                            type="date"
                            inputProps={{
                                min: data?.results[0]?.applyEndDate && moment(data.results[lastKey].applyEndDate).add(1, 'day').
                                format().
                                slice(0, 10)
                            }}
                            value={formik.values.startDate.slice(0,10)}
                            onChange={handleChangeStartDate}
                            error={Boolean(formik.touched.startDate) && Boolean(formik.errors.startDate)}
                            helperText={formik.touched.startDate && formik.errors.startDate}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="End Date"
                            name='endDate'
                            type="date"
                            value={formik.values.endDate.slice(0,10)}
                            inputProps={{
                                min: startDate
                              }}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.endDate) && Boolean(formik.errors.endDate)}
                            helperText={formik.touched.endDate && formik.errors.endDate}
                        />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Highly Skilled"
                            name='dw_highSkilled'
                            value={formik.values.dw_highSkilled}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.dw_highSkilled) && Boolean(formik.errors.dw_highSkilled)}
                            helperText={formik.touched.dw_highSkilled && formik.errors.dw_highSkilled}
                        />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Semi-Skilled"
                            name='dw_semiSkilled'
                            value={formik.values.dw_semiSkilled}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.dw_semiSkilled) && Boolean(formik.errors.dw_semiSkilled)}
                            helperText={formik.touched.dw_semiSkilled && formik.errors.dw_semiSkilled}
                        />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Skilled"
                            name='dw_skilled'
                            value={formik.values.dw_skilled}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.dw_skilled) && Boolean(formik.errors.dw_skilled)}
                            helperText={formik.touched.dw_skilled && formik.errors.dw_skilled}
                        />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Unskilled"
                            name='dw_unSkilled'
                            value={formik.values.dw_unSkilled}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.dw_unSkilled) && Boolean(formik.errors.dw_unSkilled)}
                            helperText={formik.touched.dw_unSkilled && formik.errors.dw_unSkilled}
                        />
                    </Grid>
                    <Grid container justifyContent="flex-end" sx={{mt: 2}}>
                       {id?(
                               <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                >
                                {loadingUpdate?(
                                    <CircularProgress color="inherit" size={20}/>
                                ):( 
                                    <>Update</> 
                                )} 
                            </Button>
                            ):(
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                >
                                {loading?(
                                    <CircularProgress color="inherit" size={20}/>
                                ):( 
                                    <>Submit</> 
                                )} 
                            </Button>)}
                    </Grid>
                </Grid>
            </form>
        </Card>
    </>
  )
}

export default ProjectWagesAdd;
