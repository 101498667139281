import React, { useState, useEffect, useRef } from "react";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SelectField from "components/SelectField";
import { Constants } from "utils/constants";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  CompanyActions,
  GeneralActions,
  LabourActions,
  ProjectActions,
  SubContractorActions,
} from "slices/actions";
import { CompanySelector } from "selectors/CompanySelector";
import {
  GeneralSelector,
  LabourSelector,
  ProjectSelector,
  SubContractorSelector,
} from "selectors";
import { useDispatch, useSelector } from "react-redux";
import Input from "components/Input";
import ListSkeleton from "components/Skeleton/ListSkeleton";
import { toast } from "react-toastify";

function LinkUan() {
  const dispatch = useDispatch();
  const loading = useSelector(GeneralSelector.loader(LabourActions.getLabourList.type));
  const uanSuccess = useSelector(GeneralSelector.success(LabourActions.getLabourList.type));
  const uanError = useSelector(GeneralSelector.error(LabourActions.getLabourList.type));
  const updateLoading = useSelector(GeneralSelector.loader(LabourActions.updateUanList.type));
  const updateSuccess = useSelector(GeneralSelector.success(LabourActions.updateUanList.type));
  const updateError = useSelector(GeneralSelector.error(LabourActions.updateUanList.type));
  const uploadDocumentLoading = useSelector(GeneralSelector.loader(LabourActions.labourUanCheckListDocumentUpload.type));
  const uploadDocumentSuccess = useSelector(GeneralSelector.success(LabourActions.labourUanCheckListDocumentUpload.type));
  const uploadDocumentError = useSelector(GeneralSelector.error(LabourActions.labourUanCheckListDocumentUpload.type));
  const [showData, setShowData] = useState(false);
  const companyId = localStorage.getItem("companyID");
  const projectId = localStorage.getItem("projectID");
  const subcontractorId = localStorage.getItem("subcontractorID");
  const userType = localStorage.getItem("userType");
  const companyList = useSelector(CompanySelector.getCompanyList());
  const projectList = useSelector(ProjectSelector.getProjectList());
  const [assignedProject, setAssignedProject] = useState("");
  const labourListData = useSelector(LabourSelector.getLabourList());
  const [labourUanData, setLabourUanData] = useState();
  const assignedProjectToSubcontractorList = useSelector(SubContractorSelector.getAssignedSubcontractor());
  const [errObj, setErrObj] = useState({});
  const [document, setDocument] = useState();
  const [uanFormData, setUanuanFormData] = useState({
    company_id: companyId ? companyId : "",
    project_id: projectId ? projectId : "",
    subcontractor_id: subcontractorId ? subcontractorId : "",
    is_UAN: false,
  });
  const uanNumber = useSelector(LabourSelector.selectUANNumber);
  const inputRefs = useRef([]);
  const [ButtonIndex, setButtonIndex] = useState(null);

  const handleButtonClick = (index) => {
    setButtonIndex(index);
    submitData1(index);
  };

  useEffect(() => {
    // Perform any actions or updates when `uanNumber` changes
    console.log('UAN Number changed:', uanNumber);
    if (!Array.isArray(labourUanData)) {
      return;
    }
    const pf_uan = uanNumber;
    const newData = [...labourUanData];
    newData[ButtonIndex] = { ...newData[ButtonIndex], UAN: pf_uan };
    setLabourUanData(newData);
    const inputField = inputRefs.current[ButtonIndex];
    if (inputField) {
      inputField.value = pf_uan;
    }
  }, [uanNumber]);

  useEffect(() => {
    setLabourUanData(labourListData);
  }, [labourListData]);

  useEffect(() => {
    if (!companyId) {
      dispatch(CompanyActions.getCompanyList());
    }
    if (companyId && !projectId) {
      const params = {
        company_id: companyId,
        status: 1,
      };
      dispatch(ProjectActions.getProjectList(params));
    }
    if (companyId && projectId) {
      const params = {
        company_id: companyId,
        project_id: projectId,
      };
      dispatch(SubContractorActions.getAssignedSubContractor(params));
    }
    if (subcontractorId) {
      const params = {
        subcontractorId: subcontractorId,
      };
      dispatch(SubContractorActions.getAssignedSubContractor(params));
    }
  }, []);

  const handleChangeCompany = (e) => {
    delete errObj.company_id;
    setErrObj({ ...errObj });
    uanFormData.company_id = e.target.value;
    uanFormData.project_id = "";
    uanFormData.subcontractor_id = "";
    setShowData(false);
    setUanuanFormData({ ...uanFormData });
    if (uanFormData.company_id) {
      const params = {
        company_id: uanFormData.company_id,
        status: 1,
      };
      dispatch(ProjectActions.getProjectList(params));
    }
  };

  const handleChangeProject = (e) => {
    delete errObj.project_id;
    setErrObj({ ...errObj });
    uanFormData.project_id = e.target.value;
    uanFormData.subcontractor_id = "";
    setShowData(false);
    setUanuanFormData({ ...uanFormData });
    if (uanFormData.project_id) {
      const params = {
        company_id: uanFormData.company_id || companyId,
        project_id: uanFormData.project_id,
      };
      dispatch(SubContractorActions.getAssignedSubContractor(params));
    }
  };

  const handleChangeSubcontractor = (e) => {
    setShowData(false);
    uanFormData.subcontractor_id = e.target.value;
    setUanuanFormData({ ...uanFormData });
  };

  const handleChangeProjectBySubcontractor = (e) => {
    setAssignedProject(e.target.value);
    setShowData(false);
    for (const i in assignedProjectToSubcontractorList.data) {
      if (
        assignedProjectToSubcontractorList.data[i].projectId._id ===
        e.target.value
      ) {
        uanFormData.project_id = e.target.value;
        uanFormData.company_id =
          assignedProjectToSubcontractorList.data[i].companyId._id;
        setUanuanFormData({ ...uanFormData });
      }
    }
  };

  const validateForm = () => {
    let valid = true;
    if (!uanFormData.company_id) {
      errObj.company_id = "This field is Required";
      valid = false;
    }
    if (!uanFormData.project_id) {
      errObj.project_id = "This field is Required";
      valid = false;
    }
    setErrObj({ ...errObj });
    return valid;
  };
  const searchLabourUanUnmber = () => {
    if (validateForm()) {
      dispatch(LabourActions.getLabourList(uanFormData));
    }
  };
  const addUanNumber = (e, index) => {
    const newListData = [...labourUanData];
    const newObject = {
      labourAttendance: newListData[index].labourAttendance,
      attendanceInTime: newListData[index].attendanceInTime,
      attendanceOutTime: newListData[index].attendanceOutTime,
      attendanceDate: newListData[index].attendanceDate,
      attendanceVarifiedBy: newListData[index].attendanceVarifiedBy,
      _id: newListData[index]._id,
      firstName: newListData[index].firstName,
      lastName: newListData[index].lastName,
      labourCodeNo: newListData[index].labourCodeNo,
      aadharNo: newListData[index].aadharNo,
      logo: newListData[index].logo,
      projectId: newListData[index].projectId,
      projectname: newListData[index].projectname,
      companyId: newListData[index].companyId,
      companyName: newListData[index].companyName,
      subContractorId: newListData[index].subContractorId,
      subcontractorName: newListData[index].subcontractorName,
      mobileNumber: newListData[index].mobileNumber,
      doj: newListData[index].doj,
      status: newListData[index].status,
      projectLabourCode: newListData[index].projectLabourCode,
      UAN: e.target.value,
    };
    newListData[index] = newObject;
    setLabourUanData(newListData);
  };
  const checkUANValidation1 = (index) => {
    let value = false;
    if (labourUanData[index].UAN) {
      value = true;
    }
    return value;
  };
  const submitData1 = (index) => {
    const aadhaarNumber = labourUanData[index].aadharNo;
    if (!checkUANValidation1(index)) {
      dispatch(LabourActions.getUANFromAadhaar({ index, aadhaarNumber }));
    } else {
      toast.error("UAN Number already entered, Click on Update", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });
    }
  };

  const checkUANValidation = () => {
    let value = false;
    for (const i in labourUanData) {
      if (labourUanData[i].UAN) {
        value = true;
      }
    }
    return value;
  };

  const submitData = () => {
    if (checkUANValidation()) {
      const params = {
        data: JSON.stringify(labourUanData),
      };
      dispatch(LabourActions.updateUanList(params));
    } else {
      toast.error("Please fill atleast one field", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });
    }

  };

  useEffect(() => {
    if (uanSuccess) {
      dispatch(GeneralActions.removeSuccess(LabourActions.getLabourList.type));
      setShowData(true);
    }
  }, [uanSuccess]);

  useEffect(() => {
    if (uanError) {
      toast.error(`${uanError?.message ?? "Error"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });
      dispatch(GeneralActions.removeError(LabourActions.getLabourList.type));
    }
  }, [uanError]);

  useEffect(() => {
    if (updateSuccess) {
      toast.success(`${updateSuccess?.message ?? "Success"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });
      dispatch(GeneralActions.removeSuccess(LabourActions.updateUanList.type));
      dispatch(LabourActions.getLabourList(uanFormData));
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (updateError) {
      toast.error(`${updateError?.message ?? "Error"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });
      dispatch(GeneralActions.removeError(LabourActions.updateUanList.type));
    }
  }, [updateError]);

  useEffect(() => {
    if (uploadDocumentError) {
      toast.error(`${uploadDocumentError?.message ?? "Error"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });
      dispatch(GeneralActions.removeError(LabourActions.labourUanCheckListDocumentUpload.type));
    }
  }, [uploadDocumentError]);

  useEffect(() => {
    if (uploadDocumentSuccess) {
      toast.success(`${uploadDocumentSuccess?.message ?? "Error"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });
      dispatch(GeneralActions.removeSuccess(LabourActions.labourUanCheckListDocumentUpload.type));
    }
  }, [uploadDocumentSuccess]);

  const handleChangeDocument = (e) => {
    const file = e.target.files[0];
    setDocument(file)
  }
  const uploadDocument = () => {
    const params = {
      document: document
    }
    dispatch(LabourActions.labourUanCheckListDocumentUpload(params))
  }

  return (
    <Card>
      <Typography variant="h5" sx={{ fontWeight: 600, position: "relative" }}>
        Link UAN
      </Typography>

      <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
        <Grid item lg={12} xs={12}>
          <Grid container spacing={3}>
            {userType === Constants.SUPERADMIN ? (
              <Grid item lg={3} sm={12} xs={12}>
                <Typography sx={{ fontSize: 15 }}>Company Name</Typography>
                <SelectField
                  value={uanFormData.company_id}
                  onChange={handleChangeCompany}
                >
                  {companyList &&
                    companyList.map((item, index) => (
                      <MenuItem key={index} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </SelectField>
                {errObj.company_id && (
                  <Typography sx={{ color: "red", fontSize: 12 }}>
                    {errObj.company_id}
                  </Typography>
                )}
              </Grid>
            ) : null}

            {userType === Constants.SUPERADMIN ||
              userType === Constants.COMPANY_USER ? (
              <Grid item lg={3} sm={12} xs={12}>
                <Typography sx={{ fontSize: 15 }}>Project Name</Typography>
                <SelectField
                  value={uanFormData.project_id}
                  onChange={handleChangeProject}
                >
                  {uanFormData.company_id &&
                    projectList &&
                    projectList.map((item, index) => (
                      <MenuItem key={index} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </SelectField>
                {errObj.project_id && (
                  <Typography sx={{ color: "red", fontSize: 12 }}>
                    {errObj.project_id}{" "}
                  </Typography>
                )}
              </Grid>
            ) : null}

            {userType === Constants.SUB_CONTRACTOR_USER ? (
              <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{ fontSize: 15 }}>Project</Typography>
                <SelectField
                  value={assignedProject}
                  onChange={handleChangeProjectBySubcontractor}
                >
                  {assignedProjectToSubcontractorList && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item, index) => (
                    <MenuItem key={index} value={item.projectId._id}>
                      {item?.projectId?.name}({item?.companyId?.name})
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
            ) : null}

            {userType === Constants.SUPERADMIN ||
              userType === Constants.COMPANY_USER ||
              userType === Constants.PROJECT_USER ? (
              <Grid item lg={3} sm={12} xs={12}>
                <Typography sx={{ fontSize: 15 }}>
                  Subcontractor Name
                </Typography>
                <SelectField
                  value={uanFormData.subcontractor_id}
                  onChange={handleChangeSubcontractor}
                >
                  {uanFormData.project_id &&
                    assignedProjectToSubcontractorList &&
                    assignedProjectToSubcontractorList.data &&
                    assignedProjectToSubcontractorList.data.map((item, index) => (
                      <MenuItem
                        value={item?.subcontractorId?._id}
                        key={index}
                      >
                        {item?.subcontractorId?.subContractorName}
                      </MenuItem>
                    ))}
                </SelectField>
              </Grid>
            ) : null}

            <Grid item lg={3} xs={12}>
              <Typography>&nbsp;</Typography>
              <Button
                variant="contained"
                sx={{ width: "100%", py: 1 }}
                color="primary"
                onClick={searchLabourUanUnmber}
              >
                {loading ? <CircularProgress color="inherit" size={20} /> : (
                  <>
                    <SearchOutlinedIcon />
                    Search
                  </>)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {loading ? (
        <ListSkeleton />
      ) : (
        <>
          {uanFormData.company_id && uanFormData.project_id && showData && labourUanData && labourUanData.length !== 0 ? (
            <>
              <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
                <Grid item lg={5} sm={5} xs={5} sx={{ py: 2 }}>
                  <div className="cursor-pointer w-100 position-relative"
                    style={{ background: '#f1f1f1', padding: '5px 10px', borderRadius: '5px' }}
                  >
                    <input
                      type="file"
                      id="profile1"
                      onChange={handleChangeDocument}
                    />
                  </div>
                </Grid>
                <Grid item lg={3} sm={3} xs={3} sx={{ py: 2 }}>
                  <Button variant="contained" color="primary" onClick={uploadDocument}>
                    {uploadDocumentLoading ? <CircularProgress color="inherit" size={20} /> : (
                      <>
                        Upload
                      </>)}
                  </Button>
                </Grid>
                <Grid item lg={4} sm={4} xs={4} sx={{ py: 2, textAlign: 'right' }}>
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn btn-primary mb-3"
                    table="table-to-xls"
                    filename={"UAN Form"}
                    sheet="tablexls"
                    buttonText="Export"
                  />
                </Grid>
              </Grid>
              <Box>
                <Table id="table-to-xls">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>AadharNo</TableCell>
                      <TableCell>Project Labour Code</TableCell>
                      <TableCell>UAN</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {labourUanData.map((item, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          {item.firstName} {item.lastName}
                        </TableCell>
                        <TableCell>{item.aadharNo}</TableCell>
                        <TableCell>{item.projectLabourCode}</TableCell>
                        <TableCell sx={{ position: "relative" }}>
                          {/* <Input
                            value={item?.UAN}
                            onChange={(e) => addUanNumber(e, index)}
                          /> */}
                          <Input
                            id={`input-${index}`}
                            ref={(ref) => (inputRefs.current[index] = ref)}
                            value={item.UAN}
                            onChange={(e) => addUanNumber(e, index)}
                            placeholder="Please enter UAN or click on Get UAN"
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleButtonClick(index)}
                          // Inside the component JSX, submitData1(index),
                          // <button onClick={() => handleButtonClick(index)}>submitButton1</button>
                          >
                            {updateLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              <>Get UAN</>
                            )}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div style={{ textAlign: 'right', marginRight: "20px" }}>
                  <Button variant="contained" color="primary" onClick={submitData}>
                    {updateLoading ? <CircularProgress color="inherit" size={20} /> : (
                      <>
                        Update
                      </>)}
                  </Button>
                </div>
              </Box>
            </>) : <Typography sx={{ textAlign: "center" }}>No Data</Typography>
          }
        </>
      )}
    </Card>
  );
}
export default LinkUan;
