import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  Typography,
  // Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Pagination,Chip
} from "@mui/material";
import PageTitle from "components/PageTitle";
// import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { CompanySelector } from "selectors/CompanySelector";
// import { useSelector} from 'react-redux';
import { CompanyActions } from "slices/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
// import FormSkeleton from "components/Skeleton/FormSkeleton";
import {LocalPrintshopOutlined} from '@mui/icons-material';
import { format } from 'date-fns';

// function printIdCard () {}

export default function ViewCompany() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const companyDetails = useSelector(CompanySelector.getCompanyView());
  // const loading = useSelector(GeneralSelector.loader(ProjectActions.getProjectVIewDataSuccess.type));
  useEffect(() => {
      if (companyDetails) {
      console.log('companyDetails',JSON.stringify(companyDetails.result))
      }
  },[])

  useEffect(() => {
    console.log('companyview',id);
    if (id) {
      dispatch(CompanyActions.getCompanyVIewData(id));
    }
  }, [id]);
  return (
    <Box>
      <PageTitle isBack={true} title="About Company" />
      {/* {loading ? (
                 <FormSkeleton/>
            ) : */}
      {/* {JSON.stringify(companyDetails)} */}
        <Card sx={{ position: "relative" }}>
        
          <Grid container spacing={3} alignItems='center'>
            <Grid item lg={12} sm={12} xs={12}>
              <Typography
                variant="h6"
                sx={{ borderBottom: "1px solid #3f7ed2", position: 'relative' }}
              >
                {companyDetails && companyDetails.result && companyDetails.result.name}
                <LocalPrintshopOutlined className="printicon" color='primary' onClick={() => window.print()} />
              </Typography>
            </Grid>
            <Grid item lg={2} sm={12} xs={12}>
              <div
                style={{
                  width: "120px",
                  innerHeight: "150px",
                  borderRadius: "50%",
                }}
              >
                <img
                  src={companyDetails && companyDetails.result && companyDetails.result.logo}
                  alt=""
                  style={{ borderRadius: "50%", width: "100%", objectFit: "cover" }}
                />
              </div>
            </Grid>
            <Grid item lg={5} sm={12} xs={12}>
              {/* <Typography>Name : {companyDetails && companyDetails.result && companyDetails.result.name}</Typography> */}
              <Typography>Email : {companyDetails && companyDetails.result && companyDetails.result.email}</Typography>
              <Typography>
                Address : {companyDetails && companyDetails.result && companyDetails.result.address}, 
                {companyDetails && companyDetails.result && companyDetails.result.state}, 
                {companyDetails && companyDetails.result && companyDetails.result.city}, 
                {companyDetails && companyDetails.result && companyDetails.result.pincode}
              </Typography>
            </Grid>
            <Grid item lg={5} sm={12} xs={12}>
              <Typography>Phone Number : {companyDetails && companyDetails.result && companyDetails.result.contactNumber}</Typography>
              <Typography>GST : {companyDetails && companyDetails.result && companyDetails.result.gst}</Typography>
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
              <Typography
                variant="h6"
                sx={{ borderBottom: "1px solid #3f7ed2" }}
              >
                Project Information
              </Typography>
            </Grid>
            {companyDetails.result && companyDetails.result.projects.map((item, i) => (
              <Grid key={i} item lg={4} sm={12} xs={12}>
                <Card className="viewwGrid">
                  <Typography variant='h6' sx={{fontSize: 15}}>Name : {item.name}</Typography>
                  <Typography variant='h6' sx={{fontSize: 14, fontWeight: 'normal'}}>{item.code}</Typography>
                  <Typography variant='h6' sx={{fontSize: 14, fontWeight: 'normal'}}>
                    Start Date : {format(new Date(item.start_date), 'dd/MM/yyyy')}
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 14, fontWeight: 'normal'}}>
                    End Date : {format(new Date(item.end_date), 'dd/MM/yyyy')}
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 14, fontWeight: 'normal'}}>Duration : {item.duration}</Typography>
                </Card>
              </Grid>
            ))}
            {/* <Grid item lg={12} sm={12} xs={12} sx={{ml: 1}}>
                <Typography variant="h6" sx={{fontSize: 14, fontWeight: 'bold'}}>
                  Labour : {companyDetails && companyDetails.result && companyDetails.result.users.length}
                </Typography>
            </Grid> */}
          </Grid>
        </Card>
    </Box>
  );
}
