import {createSelector} from "@reduxjs/toolkit";

const masterDataSelector = (state) => state.masterData;

const getMasterData = () => createSelector(
    masterDataSelector,
    masterData => masterData.masterData
);
const getNatureOfWork = () => createSelector(
    masterDataSelector,
    masterData => masterData.natureOfWork
);
const getLocationListByPincode = () => createSelector(
    masterDataSelector,
    masterData => masterData.pinCodeData
  );
const getMotherTounge = () => createSelector(
    masterDataSelector,
    masterData => masterData.motherTounge  
)

const getsubContractorData = () => createSelector(
    masterDataSelector,
    masterData => masterData.subcontractorData  
)
const getIfscData = () => createSelector(
    masterDataSelector,
    masterData => masterData.ifscData
  );
const getMonthList = () => createSelector(
    masterDataSelector,
    masterData => masterData.monthList
);
const getYearList = () => createSelector(
    masterDataSelector,
    masterData => masterData.yearList
);
const getEmploymentType = () => createSelector(
    masterDataSelector,
    masterData => masterData.employementType
);

export const MasterDataSelector = {
    getMasterData,
    getNatureOfWork,
    getLocationListByPincode,
    getMotherTounge,
    getsubContractorData,
    getIfscData,
    getMonthList,
    getYearList,
    getEmploymentType
};