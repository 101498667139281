import React, { useState } from "react";
import EmailIcon from '@mui/icons-material/Email';
import {
    Card, Grid, Typography
} from "@mui/material";
// IconButton, InputAdornment
// import Input from "components/Input";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
import PropTypes from "prop-types";
import ResetPasswordDialog from "./ResetPasswordDialog";
import DialogConfirm from "components/DialogConfirm";

AccountSetting.propTypes = {
    formik: PropTypes.object,
    email: PropTypes.string
};

export default function AccountSetting(props) {
    const { email } = props;

    // const [showPassword, setShowPassword] = useState(false);

    const [openIdDialog, setOpenIdDialog] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    // const handleClickShowPassword = () => {
    //     setShowPassword(!showPassword);
    // };

    // const handleMouseDownPassword = (event) => {
    //     event.preventDefault();
    // };

    const handleIdDialog = () => {
        setConfirmDelete(true)
    }

    return (
        <Card sx={{ mb: 2 }}>
            <Typography variant='h7' sx={{ mb: 2 }}><EmailIcon sx={{ mr: 2 }} /> {email}</Typography>
            {/* <Typography variant='h5' sx={{ mb: 4 }}>{formik.values.email}</Typography> */}
            <Grid container spacing={3}>
                {/* <Grid item lg={12} xs={12}>
                    <Input
                        label="Email"
                        type="email"
                        name='email'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.email) && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        disabled={true} />
                </Grid> */}
                {/* <Grid item lg={12} xs={12}> */}
                {/* <FormControl fullWidth>
                            <Typography variant='' sx={{fontSize: "15px"}}>Phone Numberr</Typography>
                            <Box sx={{
                                display: 'flex',
                                gap: 1.5
                            }}>
                                <Box sx={{ width: 90 }}>
                                    <Input style={{appearence: 'none'}}
                                        sx={{
                                            padding: "10px 8px",
                                            textAlign: 'center',
                                            // '& .Mui-disabled': {
                                            //     fillColor: theme.palette.common.black
                                            // }
                                        }}
                                        autoComplete='new-password'
                                        name='phoneCode'
                                        startAdornment='+'
                                        value="91"
                                        />
                                </Box>
                                <Input
                                    name='phone'
                                    maxlength="10" 
                                    type="number"    
                                    value={formik.values.phone}
                                    onChange={handleMobileChange}
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    helperText={formik.touched.phone && formik.errors.phone}/>
                            </Box>
                        </FormControl> */}
                {/* <Input
                         disabled={true}
                        label="Password"
                        // inputProps={{
                        //     autoComplete: "new-password"
                        // }}
                        placeholder='●●●●●●●●●●'
                        type={showPassword ? "text" : "password"}
                        name='password'
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {!showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                    />
                </Grid> */}
                <Grid item lg={12} xs={12}>
                    <Typography color='primary' onClick={() => setOpenIdDialog(true)}
                        sx={{ fontSize: 14, fontWeight: 600, my: 2, cursor: 'pointer', mt: 4 }}
                    >
                        Reset Password
                    </Typography>

                </Grid>
            </Grid>

            <ResetPasswordDialog
                open={openIdDialog}
                onClose={() => setOpenIdDialog(false)}
                onSubmit={handleIdDialog}
            />
            <DialogConfirm
                title="Reset Password"
                content="Are you sure want to reset password?"
                open={confirmDelete}
                onClose={() => setConfirmDelete(false)}
            />
        </Card>
    )
}