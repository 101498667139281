import React, {useEffect,useState} from "react";
import {
    Box, Card,Table, TableBody, TableCell, TableHead, TableRow,Grid,MenuItem
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {GeneralSelector, ProjectSelector, SiteSelector} from "selectors";
import ListSkeleton from "../../components/Skeleton/ListSkeleton";
import { CompanyActions, ProjectActions, SiteActions } from "slices/actions";
import SelectField from "components/SelectField";
import { CompanySelector } from "selectors/CompanySelector";
import { Constants } from "utils/constants";
import FloatingButton from "components/FloatingButton";
import {useHistory} from "react-router-dom";

function Site() {
  const history = useHistory()
  const dispatch = useDispatch();
  const loading = useSelector(GeneralSelector.loader(SiteActions.getAllSiteList.type));
  const allSiteListing = useSelector(SiteSelector.getAllSiteList());
  const companyList = useSelector(CompanySelector.getCompanyList());
  const projectList = useSelector(ProjectSelector.getProjectList());
  const role = useSelector(SiteSelector.role());
  const userType = localStorage.getItem('userType')
  const companyId = localStorage.getItem('companyID')
  const projectId = localStorage.getItem('projectID')
  const [searchList,setSearchList] = useState({
    company: companyId?companyId:"",
    project: projectId?projectId:""
  })

useEffect(() => {
    dispatch(SiteActions.getAllSiteList(searchList));
   },[searchList]);

    useEffect(() => {
        if (searchList.company || companyId) {
            const params = {
                company_id: searchList.company ? searchList.company:companyId,
                status: 1
            }
            dispatch(ProjectActions.getProjectList(params))
        }
    },[searchList.company])

    useEffect(() => {
        if (!companyId) {
            dispatch(CompanyActions.getCompanyList());
         }
    },[])

  const handleChangeCompany = (e) => {
    const cId = e.target.value;
    searchList.company = cId;
    searchList.project = "";
    setSearchList({...searchList})
    dispatch(SiteActions.getAllSiteList(searchList));
 }   
  const handleChangeProject = (e) => {
    if (searchList.company) {
    const pId = e.target.value
    searchList.project = pId;
    setSearchList({...searchList})
    dispatch(SiteActions.getAllSiteList(searchList));
    } 
  }
 

  return (
    <Card>
   
    <Typography variant="h5" sx={{ fontWeight: 600, mb: 4 }}>All Site</Typography>

    <Grid container spacing={3} sx={{mb: 2}}>
      {userType !== Constants.COMPANY_USER && userType !== Constants.PROJECT_USER?(
       <Grid item lg={6} sm={12} xs={12}>
            <Typography sx={{fontSize: 15}}>Company</Typography>
            <SelectField
                value={searchList.company}
                onChange={handleChangeCompany}
                >
                {companyList && companyList.map((item,index) => (
                <MenuItem key={index} value={item._id}>
                        {item.name}
                </MenuItem>
                ))}
            </SelectField> 
        </Grid>
      ):""
       }
      {userType !== Constants.PROJECT_USER?(
        <Grid item lg={6} sm={12} xs={12}>
            <Typography sx={{fontSize: 15}}>Project</Typography>
            <SelectField
                value={searchList.project}
                onChange={handleChangeProject}
                >
                {(searchList.company && projectList) && projectList.map((item,index) => (
                <MenuItem key={index} value={item._id}>
                        {item.name}
                </MenuItem>
                ))}
            </SelectField> 
        </Grid> 
      ):""}          
        
    </Grid>
  
    {loading ? (
        <ListSkeleton/>
    ) : (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>   
                        <TableCell>Address</TableCell> 
                        <TableCell>Project Name</TableCell> 
                        <TableCell>Company Name</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                     {allSiteListing && allSiteListing.data && allSiteListing.data.map((item, i) => (
                        <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {item.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.address}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.projectname}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.companyname}
                            </TableCell>
                            <TableCell component="th" scope="row">
                            </TableCell>
                        </TableRow>
                    ))}  
                </TableBody>
            </Table>

         
        </Box>
    )}

{role && Object.keys(role).map((item,index) => (
      <div key={index}>
       {item === "Add"?(
      <FloatingButton
        onClick={() => history.push("/app/site/create/")}/>
        ):null}
        </div>
))}
</Card>
  )
}

export default Site