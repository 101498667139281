import React,{useEffect} from 'react'
import {useFormik} from "formik";
import * as yup from "yup";
import NomineeInfo from './NomineeInfo';
import GuardianDetails from './GuardianDetails';
import PensionnomineeDetails from './PensionNomineeDetails';
import FamilyDetails from './FamilyDetails';
import PropTypes from "prop-types";
import { Box, Button , CircularProgress, Grid } from '@mui/material';
// import PageTitle from 'components/PageTitle';
import { GeneralActions, LabourActions } from 'slices/actions';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GeneralSelector } from 'selectors';
import {toast} from "react-toastify";

NomineAllInfo.propTypes = {
    labourDetails: PropTypes.object
};

function NomineAllInfo (props) {
    const { labourDetails } = props;
    const {id} = useParams();
    const dispatch = useDispatch();
    const success = useSelector(GeneralSelector.success(LabourActions.updateLabourPFBasicInfo.type));
    const loading = useSelector(GeneralSelector.loader(LabourActions.updateLabourPFBasicInfo.type));
    const error = useSelector(GeneralSelector.error(LabourActions.updateLabourPFBasicInfo.type));

    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });
    
            dispatch(GeneralActions.removeSuccess(LabourActions.updateLabourPFBasicInfo.type));
        }
        }, [success]);
    
      useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });
    
            dispatch(GeneralActions.removeError(LabourActions.updateLabourPFBasicInfo.type));
        }
       }, [error]);

    const validationSchema = yup.object({
        pfNomineInfo_EmployeeNo: yup.string().required('This field is required'),
        pfNomineInfo_PFNomineeName: yup.string().required('This field is required'),
        pfNomineInfo_Gender: yup.string().required('This field is required'),
        pfNomineInfo_Relation: yup.string().required('This field is required'),
        pfNomineInfo_DOB: yup.string().required('This field is required'),
        pfNomineInfo_Pincode: yup.string().required('This field is required'),
        pfNomineInfo_District: yup.string().required('This field is required'),
        pfNomineInfo_State: yup.string().required('This field is required'),
        pfNomineInfo_Share: yup.string().required('This field is required'),
        pfNomineInfo_Minor: yup.string().required('This field is required'),
        pfNomineInfo_Lunatic: yup.string().required('This field is required'),
        pfNomineInfoGuardianDetails_name: yup.string().required('This field is required'),
        pfNomineInfoGuardianDetails_Relation: yup.string().required('This field is required'),
        pfNomineInfoGuardianDetails_pincode: yup.string().required('This field is required'),
        pfNomineInfoGuardianDetails_State: yup.string().required('This field is required'),
        pfNomineInfoGuardianDetails_District: yup.string().required('This field is required'),
        pfNomineInfoGuardianDetails_Address: yup.string().required('This field is required'),
        pfNomineInfoPersonNomineeDetails_NomineeName: yup.string().required('This field is required'),
        pfNomineInfoPersonNomineeDetails_Gender: yup.string().required('This field is required'),
        pfNomineInfoPersonNomineeDetails_Relation: yup.string().required('This field is required'),
        pfNomineInfoPersonNomineeDetails_DOB: yup.string().required('This field is required'),
        pfNomineInfoPersonNomineeDetails_Pincode: yup.string().required('This field is required'),
        pfNomineInfoPersonNomineeDetails_State: yup.string().required('This field is required'),
        pfNomineInfoPersonNomineeDetails_District: yup.string().required('This field is required'),
        pfNomineInfoPersonNomineeDetails_Address: yup.string().required('This field is required'),
        pfNomineInfoFamilyDetails_Name: yup.string().required('This field is required'),
        pfNomineInfoFamilyDetails_Gender: yup.string().required('This field is required'),
        pfNomineInfoFamilyDetails_Relation: yup.string().required('This field is required'),
        pfNomineInfoFamilyDetails_DOB: yup.string().required('This field is required'),
        pfNomineInfoFamilyDetails_Pincode: yup.string().required('This field is required'),
        pfNomineInfoFamilyDetails_State: yup.string().required('This field is required'),
        pfNomineInfoFamilyDetails_District: yup.string().required('This field is required'),
        pfNomineInfoFamilyDetails_Address: yup.string().required('This field is required'),
        pfNomineInfoFamilyDetails_Disabled: yup.string().required('This field is required'),
    });
   
    const formik = useFormik({
        initialValues: {
            flag: "PFNomineeInfo",
            pfNomineInfo_EmployeeNo: labourDetails?.result?.pfNomineInfo_EmployeeNo??"",
            pfNomineInfo_PFNomineeName: labourDetails?.result?.pfNomineInfo_PFNomineeName??"",
            pfNomineInfo_Gender: labourDetails?.result?.pfNomineInfo_Gender??"",
            pfNomineInfo_Relation: labourDetails?.result?.pfNomineInfo_Relation??"",
            pfNomineInfo_DOB: labourDetails?.result?.pfNomineInfo_DOB??"",
            pfNomineInfo_Pincode: labourDetails?.result?.pfNomineInfo_Pincode??"",
            pfNomineInfo_District: labourDetails?.result?.pfNomineInfo_District??"",
            pfNomineInfo_State: labourDetails?.result?.pfNomineInfo_State??"",
            pfNomineInfo_Share: labourDetails?.result?.pfNomineInfo_Share??"",
            pfNomineInfo_Minor: labourDetails?.result?.pfNomineInfo_Minor??"",
            pfNomineInfo_Lunatic: labourDetails?.result?.pfNomineInfo_Lunatic??"",
            pfNomineInfoGuardianDetails_name: labourDetails?.result?.pfNomineInfoGuardianDetails_name??"",
            pfNomineInfoGuardianDetails_Relation: labourDetails?.result?.pfNomineInfoGuardianDetails_Relation??"",
            pfNomineInfoGuardianDetails_pincode: labourDetails?.result?.pfNomineInfoGuardianDetails_pincode??"",
            pfNomineInfoGuardianDetails_State: labourDetails?.result?.pfNomineInfoGuardianDetails_State??"",
            pfNomineInfoGuardianDetails_District: labourDetails?.result?.pfNomineInfoGuardianDetails_District??"",
            pfNomineInfoGuardianDetails_Address: labourDetails?.result?.pfNomineInfoGuardianDetails_Address??"",
            pfNomineInfoPersonNomineeDetails_NomineeName: labourDetails?.result?.pfNomineInfoPersonNomineeDetails_NomineeName??"",
            pfNomineInfoPersonNomineeDetails_Gender: labourDetails?.result?.pfNomineInfoPersonNomineeDetails_Gender??"",
            pfNomineInfoPersonNomineeDetails_Relation: labourDetails?.result?.pfNomineInfoPersonNomineeDetails_Relation??"",
            pfNomineInfoPersonNomineeDetails_DOB: labourDetails?.result?.pfNomineInfoPersonNomineeDetails_DOB??"",
            pfNomineInfoPersonNomineeDetails_Pincode: labourDetails?.result?.pfNomineInfoPersonNomineeDetails_Pincode??"",
            pfNomineInfoPersonNomineeDetails_State: labourDetails?.result?.pfNomineInfoPersonNomineeDetails_State??"",
            pfNomineInfoPersonNomineeDetails_District: labourDetails?.result?.pfNomineInfoPersonNomineeDetails_District??"",
            pfNomineInfoPersonNomineeDetails_Address: labourDetails?.result?.pfNomineInfoPersonNomineeDetails_Address??"",
            pfNomineInfoFamilyDetails_Name: labourDetails?.result?.pfNomineInfoFamilyDetails_Name??"",
            pfNomineInfoFamilyDetails_Gender: labourDetails?.result?.pfNomineInfoFamilyDetails_Gender??"",
            pfNomineInfoFamilyDetails_Relation: labourDetails?.result?.pfNomineInfoFamilyDetails_Relation??"",
            pfNomineInfoFamilyDetails_DOB: labourDetails?.result?.pfNomineInfoFamilyDetails_DOB??"",
            pfNomineInfoFamilyDetails_Pincode: labourDetails?.result?.pfNomineInfoFamilyDetails_Pincode??"",
            pfNomineInfoFamilyDetails_State: labourDetails?.result?.pfNomineInfoFamilyDetails_State??"",
            pfNomineInfoFamilyDetails_District: labourDetails?.result?.pfNomineInfoFamilyDetails_District??"",
            pfNomineInfoFamilyDetails_Address: labourDetails?.result?.pfNomineInfoFamilyDetails_Address??"",
            pfNomineInfoFamilyDetails_Disabled: labourDetails?.result?.pfNomineInfoFamilyDetails_Disabled??"",
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
           handleSubmit(values);
        }
    });
    const handleSubmit = (values) => {
        const params = {
            id: id,
          ...values,
      };
      dispatch(LabourActions.updateLabourPFBasicInfo(params));
    }
  return (
    <>
    <Box>
        {/* <PageTitle isBack={true} title='Nominne Information' /> */}
         <form onSubmit={formik.handleSubmit}>
            <Grid item lg={12} sm={12} xs={12}>
                <NomineeInfo formik={formik} />
                <GuardianDetails formik={formik} />
                <PensionnomineeDetails formik={formik} />
                <FamilyDetails formik={formik} />
                {labourDetails?.result?.status !== 99?(
                  <Grid container justifyContent='flex-end'>
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        type="submit"
                        >
                        {loading?<CircularProgress color="inherit" size={20}/>:<>Submit</>}
                        </Button>
                  </Grid>):null}
            </Grid>
        </form>
    </Box>


</>
  )
}

export default NomineAllInfo