import React, { useEffect, useState } from "react";
import {
    Card,
    Grid,
    Typography, Button
} from "@mui/material";
import Input from "components/Input";
import * as yup from "yup";
import { useFormik } from "formik";
import { GeneralActions, MasterDataActions, SubContractorActions, UserActions } from "slices/actions";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { GeneralSelector, MasterDataSelector } from "selectors";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

BankAccountInformationDetails.propTypes = {
    subcontractorDetails: PropTypes.object,
};
export default function BankAccountInformationDetails(props) {
    const { id } = useParams();
    const { subcontractorDetails } = props;
    const dispatch = useDispatch();
    const success = useSelector(GeneralSelector.success(SubContractorActions.updateSubContractor.type));
    const error = useSelector(GeneralSelector.error(SubContractorActions.updateSubContractor.type));
    const loading = useSelector(GeneralSelector.loader(SubContractorActions.updateSubContractor.type));
    const ifscCodeData = useSelector(MasterDataSelector.getIfscData());
    const [ifsccode, setIfscCode] = useState();

    // useEffect(() => {
    //     console.log("bank statement", dispatch(UserActions.getUserById(id)))
    // }, [])

    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });

            dispatch(GeneralActions.removeSuccess(SubContractorActions.updateSubContractor.type));
        }
    }, [success]);

    const handleChangeIfsc = (e) => {
        const code = e.target.value
        formik.setFieldValue('ifscCode', code)
        setIfscCode(code);
    }
    useEffect(() => {
        if (ifsccode && ifsccode.length === 11) {
            dispatch(MasterDataActions.getIfscData(ifsccode));
        }
    }, [ifsccode]);

    useEffect(() => {
        if (ifscCodeData && ifsccode) {
            formik.setFieldValue('bankAddress', ifscCodeData.ADDRESS ? ifscCodeData.ADDRESS : '');
            formik.setFieldValue('bankCity', ifscCodeData.CITY ? ifscCodeData.CITY : '');
            formik.setFieldValue('bankBranchName', ifscCodeData.BRANCH ? ifscCodeData.BRANCH : '');
            formik.setFieldValue('bankState', ifscCodeData.STATE ? ifscCodeData.STATE : '');
            formik.setFieldValue('bankName', ifscCodeData.BANK ? ifscCodeData.BANK : '');
        }
    }, [ifscCodeData]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Success"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });

            dispatch(GeneralActions.removeSuccess(SubContractorActions.updateSubContractor.type));
        }
    }, [error]);

    const validationSchema = yup.object({
        // bankName: yup.string().required("This field is required"),
        bankAccountNo: yup.string().
            matches(/^[0-9]{9,18}$/, "Invalid Bank number").
            required("This field is required"),
        ifscCode: yup.string().
            matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Enter valid ifsc code").
            required("This field is required"),
    })
    const formik = useFormik({
        initialValues: {
            bankName: subcontractorDetails?.bankName ?? "",
            bankAccountNo: subcontractorDetails?.bankAccountNo ?? "",
            ifscCode: subcontractorDetails?.ifscCode ?? "",
            bankAddress: subcontractorDetails?.bankAddress ?? "",
            bankBranchName: subcontractorDetails?.bankBranchName ?? "",
            bankState: subcontractorDetails?.bankState ?? "",
            bankCity: subcontractorDetails?.bankCity ?? "",

        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });
    const handleSubmit = (values) => {
        const params = {
            id: id,
            ...values
        }
        dispatch(SubContractorActions.updateSubContractor(params))
        dispatch(UserActions.getUserById(id));
    };

    return (
        <Card sx={{ mb: 3 }}>
            <Typography variant='h5' sx={{ mb: 4 }}>Bank Account Details</Typography>
            <form onSubmit={formik.handleSubmit}>
                {/* {JSON.stringify(subcontractorDetails)} */}
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label='Bank Account Number *'
                            name='bankAccountNo'
                            type='number'
                            value={formik.values.bankAccountNo}
                            onChange={formik.handleChange}
                            error={formik.touched.bankAccountNo && Boolean(formik.errors.bankAccountNo)}
                            helperText={formik.touched.bankAccountNo && formik.errors.bankAccountNo}
                        />

                    </Grid>
                    <Grid item lg={6} xs={12}>

                        <Input
                            label="IFSC Code *"
                            name='ifscCode'
                            value={formik.values.ifscCode}
                            onChange={(e) => handleChangeIfsc(e)}
                            error={Boolean(formik.touched.ifscCode) && Boolean(formik.errors.ifscCode)}
                            helperText={formik.touched.ifscCode && formik.errors.ifscCode} />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Bank Name"
                            name='bankName'
                            readonly
                            value={formik.values.bankName}
                            // onChange={formik.handleChange}
                            error={Boolean(formik.touched.bankName) && Boolean(formik.errors.bankName)}
                            helperText={formik.touched.bankName && formik.errors.bankName} />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Branch Name"
                            name='bankBranchName'
                            readonly
                            value={formik.values.bankBranchName}
                            // onChange={formik.handleChange}
                            error={Boolean(formik.touched.bankBranchName) && Boolean(formik.errors.bankBranchName)}
                            helperText={formik.touched.bankBranchName && formik.errors.bankBranchName} />
                    </Grid>


                    <Grid item lg={6} xs={12}>
                        <Input
                            label="City Name"
                            name='bankCity'
                            readonly
                            value={formik.values.bankCity}
                            // onChange={formik.handleChange}
                            error={Boolean(formik.touched.bankCity) && Boolean(formik.errors.bankCity)}
                            helperText={formik.touched.bankCity && formik.errors.bankCity} />
                    </Grid>

                    <Grid item lg={6} xs={12}>
                        <Input
                            label="State Name"
                            name='bankState'
                            readonly
                            value={formik.values.bankState}
                            // onChange={formik.handleChange}
                            error={Boolean(formik.touched.bankState) && Boolean(formik.errors.bankState)}
                            helperText={formik.touched.bankState && formik.errors.bankState} />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Input
                            label="Address"
                            name='bankAddress'
                            value={formik.values.bankAddress}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.bankAddress) && Boolean(formik.errors.bankAddress)}
                            helperText={formik.touched.bankAddress && formik.errors.bankAddress} />
                    </Grid>
                    <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {loading ? (
                                <CircularProgress color="inherit" size={20} />
                            ) : (
                                <>Submit</>
                            )}
                        </Button>
                    </Grid>

                </Grid>
            </form>
        </Card>
    )
}