import { createSlice } from "@reduxjs/toolkit";

export const UserSlice = createSlice({
    name: "user",
    initialState: {
        profile: null,
        dashboard: {},
        users: [],
        pagination: {},
        userListByID: [],
        user: {},
        gstComplianceListing: [],
        pfComplianceListing: [],
        gstCompilanceRole: {},
        pfCompilanceRole: {}
    },
    reducers: {
        profileUser: () => {},
        profileUserSuccess: (state, action) => {
            state.profile = action.payload;
        },
        resetProfile: (state) => {
            state.profile = null;
        },
        getDashboardData: () => {},
        getDashboardDataSuccess: (state, action) => {
            state.dashboard = action.payload;
        },
        getUsers: () => {},
        getUsersSuccess: (state, action) => {
            state.user = {};
            state.users = action.payload.data;
            state.pagination = action.payload.pagination;
        },
        getSubcontractor: () => {},
        getSubcontractorSuccess: (state, action) => {
            state.user = {};
            state.users = action.payload.data;
            state.pagination = action.payload.pagination;
        },
        getUserById: () => {},
        getUserByIdSuccess: (state, action) => {
            state.user = action.payload;
        },
        createUser: () => {},
        updateUser: () => {},
        deleteUser: () => {},
        getUserListById: () => {},
        getUserListByIdSucess: (state,action) => {
            state.userListByID = action.payload
        },
        uploadGstCompliance: () => {},
        getGstComplianceListing: () => {},
        getGstComplianceListingSuccess: (state,action) => {
            state.gstComplianceListing = action.payload
            state.gstCompilanceRole = action.payload.role

        },
        uploadPfCompliance: () => {},
        getPfComplianceListing: () => {},
        getPfComplianceListingSuccess: (state,action) => {
            state.pfComplianceListing = action.payload
            state.pfCompilanceRole = action.payload.role
        },

        resetUserPassword: () => {},

    }
});

export default UserSlice;