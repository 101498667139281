import {createSelector} from "@reduxjs/toolkit";
const stateSelector = (state) => state.state;
const getStateList1 = () => createSelector(
    stateSelector,
    state => state.stateList
  
);
const getStateById = () => createSelector(
    stateSelector,
    state => state.stateData
);
const getPagination = () => createSelector(
    stateSelector,
    state => state.pagination
);

const deleteState = () => createSelector(
    stateSelector,
    state => {
        if (state.delete.success) {
            return {
                success: true,
                message: state.delete.success
            }
        }

        return {
            success: false,
            message: state.delete.error
        }
    }
)
const submitState = () => createSelector(
    stateSelector,
    state => {
        if (state.submit.success) {
            return {
                success: true,
                message: state.submit.success
            }
        }

        return {
            success: false,
            message: state.submit.error
        }
    }
)

const GetAllState = () => createSelector(
    stateSelector,
    state => state.stateList
);

export const StateSelector = {
    getStateList1,
    deleteState,
    getStateById,
    submitState,
    getPagination,
    GetAllState

}
