import {all, call, put, takeLatest} from 'redux-saga/effects'
import { MasterDataService } from 'services';
import { GeneralActions, MasterDataActions } from 'slices/actions';

function *getMasterData({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(MasterDataService.GetMasterData, payload);
        yield put(MasterDataActions.getMasterDataSuccess(result.data));
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *getNatureOfWork({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(MasterDataService.GetNatureOfWork, payload);
        yield put(MasterDataActions.getNatureOfWorkSuccess(result.data));
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}
function *fetchDataByPincode({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(MasterDataService.FetchDataByPincode, payload);
        yield put(MasterDataActions.fetchDataByPincodeSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
        } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *getMotherTounge({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(MasterDataService.GetMotherTounge, payload);
        yield put(MasterDataActions.fetchMotherToungeSuccess(result.data));
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}
function *changeStatus({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        // payload.status = payload.status? 1 : 2;
        // console.log('payload.status',payload.status)
       
        const result = yield call(MasterDataService.ChangeStatus, payload.id, payload);
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message,
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}
function *fetchDataByIFSC({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(MasterDataService.FetchDataByIFSC, payload);
        yield put(MasterDataActions.getIfscDataSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
        } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}
function *getMonthList({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(MasterDataService.GetMonthList, payload);
        yield put(MasterDataActions.getMonthListSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
        } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}
function *getYearList({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(MasterDataService.GetYearList, payload);
        yield put(MasterDataActions.getYearListSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
        } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}

function *getEmploymentType({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(MasterDataService.GetEmploymentType, payload);
        yield put(MasterDataActions.getEmploymentTypeSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
        } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}

export function *MasterDataWatcher() {
    yield all([
        yield takeLatest(MasterDataActions.getMasterData.type, getMasterData),
        yield takeLatest(MasterDataActions.getNatureOfWork.type, getNatureOfWork),
        yield takeLatest(MasterDataActions.fetchDataByPincode.type,fetchDataByPincode),
        yield takeLatest(MasterDataActions.fetchMotherTounge.type,getMotherTounge),
        yield takeLatest(MasterDataActions.changeStatus.type,changeStatus),
        yield takeLatest(MasterDataActions.getIfscData.type,fetchDataByIFSC),
        yield takeLatest(MasterDataActions.getMonthList.type,getMonthList),
        yield takeLatest(MasterDataActions.getYearList.type,getYearList),
        yield takeLatest(MasterDataActions.getEmploymentType.type,getEmploymentType)

    ]);
}