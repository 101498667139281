import React, {useEffect, useState} from "react";
import {
    Box, Card, Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Pagination,Chip, Tooltip
} from "@mui/material";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import FloatingButton from "components/FloatingButton";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Delete, Edit, Visibility} from "@mui/icons-material";
import DialogConfirm from "components/DialogConfirm";
import {toast} from "react-toastify";
import {ProjectSelector,GeneralSelector} from "selectors";
import {CompanyActions, GeneralActions, ProjectActions} from "slices/actions";
import {DefaultSort} from "constants/sort";
import Input from "components/Input";
import SelectField from "components/SelectField";
import ListSkeleton from "../../components/Skeleton/ListSkeleton";
import { Constants } from "utils/constants";
import { CompanySelector } from "selectors/CompanySelector";

const FilterBox = styled(Box)(() => ({
    width: "100%",
    marginTop: 30,
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-between"
}));

const Poject = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {status} = useParams()
    const projectList = useSelector(ProjectSelector.getProjectList());
    const loading = useSelector(GeneralSelector.loader(ProjectActions.getProjectList.type));
    const success = useSelector(GeneralSelector.success(ProjectActions.deleteProject.type));
    const pagination = useSelector(ProjectSelector.getPagination());
    const companyList = useSelector(CompanySelector.getCompanyList());
    const [projectStatus,setProjetStatus] = useState(status);
    const companyId = localStorage.getItem('companyID');
    const [company,setCompany] = useState(companyId?companyId:"");
    const userType = localStorage.getItem('userType')
    const [filter, setFilter] = useState({
        sort: DefaultSort.newest.value,
        page: 1
    });
    const [selected, setSelected] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);  
    useEffect(() => {
        if (!companyId) {
           dispatch(CompanyActions.getCompanyList());
        }
    },[])

    useEffect(() => {
        if (status || projectStatus) {
            const params = {
                ...filter,
                company_id: company?company:"",
                status: status?status:projectStatus
            }
            dispatch(ProjectActions.getProjectList(params));
        } else {
            const params = {
                ...filter,
                company_id: company?company:"",
            }
            dispatch(ProjectActions.getProjectList(params)); 
        }
           
    }, [filter]);

    useEffect(() => {
        if (success) {
            setConfirmDelete(false);
            setSelected(null);

            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 4000,
                    closeOnClick: true
                });

            dispatch(GeneralActions.removeSuccess(ProjectActions.deleteProject.type));
            dispatch(ProjectActions.getProjectList(filter));
        }
    }, [success]);
    const handleChangeFilter = ({ target }) => {
       const {name, value} = target;

        setFilter({
            ...filter,
            [name]: value
        });
    }

    const handleChangePagination = (e, val) => {
        setFilter({
            ...filter,
            page: val
        });
    };
    const handleDelete = () => {
      dispatch(ProjectActions.deleteProject(selected));
    };
    const handleChangeCompany = (e) => {
        const cId = e.target.value;
        setCompany(cId)
        const params = {
            ...filter,
            company_id: cId
        }
        dispatch(ProjectActions.getProjectList(params));
     } 
const handleChangeProjectStatus = (e) => {
    setProjetStatus(e.target.value)
    if (e.target.value === 1 || e.target.value === 2) {
        const params = {
            ...filter,
            company_id: company?company:"",
            status: e.target.value 
        }
        dispatch(ProjectActions.getProjectList(params));
    } else if (e.target.value === 0) {
        const params = {
            ...filter,
            company_id: company?company:"",
        }
        dispatch(ProjectActions.getProjectList(params));
    }
} 

   
return (
    <Card>
   
    <Typography variant="h5" sx={{ fontWeight: 600 }}>ALL Projects</Typography>
    
    <FilterBox>
        <Grid container spacing={3} justifyContent="space-between">
            <Grid item lg={5} sm={12} xs={12}>
                <Input
                    label="Search"
                    name='keyword'
                    placeholder='Search by name'
                    value={filter.keyword ?? ''}
                    onChange={handleChangeFilter}/>
            </Grid>
            {userType === Constants.SUPERADMIN ?(
                <Grid item lg={5} sm={12} xs={12}>
                    <Typography sx={{fontSize: 15}}>Company Name</Typography>
                    <SelectField
                        value={company}
                        onChange={handleChangeCompany}
                        >
                        {companyList && companyList.map((item,index) => (
                        <MenuItem key={index} value={item._id}>
                                {item.name}
                        </MenuItem>
                        ))}
                    </SelectField> 
                </Grid>
            ):""
            }
             <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Status</Typography>
                <SelectField
                    value={projectStatus}
                    onChange={handleChangeProjectStatus}
                    >
                    <MenuItem value={1}>
                        Active
                    </MenuItem>
                    <MenuItem value={2}>
                        Inactive
                    </MenuItem>
                    <MenuItem value={0}>
                        All
                    </MenuItem>
                </SelectField> 
              </Grid>
            <Grid item lg={2} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Sort by</Typography>
                <SelectField
                    value={filter.sort}
                    name="sort"
                    onChange={handleChangeFilter}>
                    {Object.keys(DefaultSort).map((key) => (
                        <MenuItem key={key} value={DefaultSort[key].value}>
                            {DefaultSort[key].name}
                        </MenuItem>
                    ))}
                </SelectField> 
            </Grid>
        </Grid>
    </FilterBox>
{projectList && projectList.length !==0? (
    <>
    {loading ? (
        <ListSkeleton/>
    ) : (
        <Box>
       
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Project Name</TableCell>   
                        <TableCell>Company Name</TableCell> 
                         <TableCell>Status</TableCell>  
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    
                      {projectList && projectList.map((item, i) => (
                        <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                           
                           <TableCell component="th" scope="row">
                                {item.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.company_name}
                            </TableCell>
                           
                            <TableCell component="th" scope="row">
                                <Chip
                                label={item.status === 2 ? 'Inactive' : 'Active'}
                                color={item.status === 2 ? 'default' : 'primary'} size='small'/>
                            </TableCell>
                            <TableCell align="right">
                                <Tooltip title="Preview">
                                    <IconButton onClick={() => history.push(`/project/view/viewproject/${item._id}`)}>
                                        <Visibility className="text-primary" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Edit">
                                    <IconButton onClick={() => history.push(`/app/project/update/${item._id}`)}>
                                        <Edit className="text-primary" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Edit">
                                    <IconButton onClick={() => {
                                        setConfirmDelete(true);
                                        setSelected(item._id);
                                        }}>
                                        <Delete className="text-default" />
                                    </IconButton>
                                </Tooltip>
                               </TableCell>
                        </TableRow>
                    ))}  
                </TableBody>
            </Table>
              <Pagination
                page={filter.page}
                count={pagination.pages}
                onChange={handleChangePagination}/>       
        </Box>
    )}
    </>
    ):<Typography sx={{ textAlign: "center" ,marginTop: 7}}>No Data</Typography>} 
        <FloatingButton
         onClick={() => history.push("/app/project/Create/New")}/>
        <DialogConfirm
        title="Delete Data"
        content="Are you sure want to delete this project?"
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onSubmit={handleDelete}/>
</Card>   
  )
}


export default Poject