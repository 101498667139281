import React, {useEffect,useState} from "react";
import {
    Box,
    Button,
    Card, Table, TableHead, TableRow, TableCell, TableBody,
    Grid,
    Typography,
    Switch,
    CircularProgress, MenuItem
} from "@mui/material";
import Input from "components/Input";
import {useHistory, useParams} from "react-router-dom";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import styled from "@emotion/styled";
import {VisibilityOutlined} from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { GeneralActions, LabourActions } from "slices/actions";
import { GeneralSelector, LabourSelector } from "selectors";
import FormSkeleton from "components/Skeleton/FormSkeleton";
import { toast } from "react-toastify";
import SelectField from "components/SelectField";
import PropTypes from "prop-types";

const SwitchBox = styled(Box)(() => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
}));

Fitness.propTypes = {
  labourDetails: PropTypes.object,
};

export default function Fitness(props) {
    const { labourDetails } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    // Labour ID
    const {id} = useParams();
    const labourFormList = useSelector(LabourSelector.getLabourFormList())
    const loader = useSelector(GeneralSelector.loader(LabourActions.getLabourFormList.type))
    const success = useSelector(GeneralSelector.success(LabourActions.createLabourHealth.type));
    const error = useSelector(GeneralSelector.error(LabourActions.createLabourHealth.type));
    const loading = useSelector(GeneralSelector.loader(LabourActions.createLabourHealth.type));
    const documentUploadSuccess = useSelector(GeneralSelector.success(LabourActions.updateHealthDocument.type));
    const [document,setDocument] = useState();
    const bloodGroup = ["A+","A-", "B+", "B-", "AB+", "AB-", "O+", "O-"]
    const [labourHealthListForm, setLabourHealthListForm] = useState([]);
    const [form, setForm] = useState({
      bloodGroup: "",
      height: "",
      weight: "",
      pr: "",
      bp: "",
      skin: "",
      cvs: "",
      chest: "",
      abdomen: "",
      musculoskeletal: ""
    })
    const [errObj,setErrObj] = useState({});

    useEffect(() => {
        if (labourFormList.medical) {
           setLabourHealthListForm(labourFormList.medical)
        } 
        if (labourFormList.labourInfo) {
           form.bloodGroup = labourFormList?.labourInfo?.bloodGroup
            form.weight = labourFormList?.labourInfo?.weight
            form.height = labourFormList?.labourInfo?.height
            form.abdomen = labourFormList?.labourInfo?.abdomen
            form.bp = labourFormList?.labourInfo?.bp
            form.pr = labourFormList?.labourInfo?.pr
            form.chest = labourFormList?.labourInfo?.chest
            form.cvs = labourFormList?.labourInfo?.cvs
            form.skin = labourFormList?.labourInfo?.skin
            form.musculoskeletal = labourFormList?.labourInfo?.musculoskeletal
            setForm({...form})
         }       
    },[labourFormList])

     const handleDeficiencyObservedStatus = (index,e) => {
        const {checked} = e.target
        const newForm = [...labourHealthListForm]
        const obj = {
            name: newForm[index].name,
            DeficiencyObserved: checked,
            satisfactory: newForm[index].satisfactory,
            remark: newForm[index].remark,
          };
          newForm[index] = obj;
          setLabourHealthListForm(newForm)
        };
   
    const handlesatisfactoryStatus = (index,e) => {
        const {checked} = e.target
        const newForm = [...labourHealthListForm]
        const obj = {
            name: newForm[index].name,
            DeficiencyObserved: newForm[index].DeficiencyObserved,
            satisfactory: checked,
            remark: newForm[index].remark,
          };
          newForm[index] = obj;
          setLabourHealthListForm(newForm)

     };
     const handleChangeRemark = (index,e) => {
         const newForm = [...labourHealthListForm]
         const obj = {
            name: newForm[index].name,
            DeficiencyObserved: newForm[index].DeficiencyObserved,
            satisfactory: newForm[index].satisfactory,
            remark: e.target.value,
          };
          newForm[index]=obj;
        setLabourHealthListForm(newForm)

     }
    useEffect(() => {
        if (id) {
        dispatch(LabourActions.getLabourFormList(id))
        }
    },[id])

  const handleSubmitValue = () => {
    if (validateForm()) {
    const params = {
        ...form,
        labourId: labourFormList.labourInfo.LabourId ,
        projectId: labourFormList.labourInfo.projectId,
        expireDate: "",
        createdBy: "",
        approvedBy: "",
        reportdata: JSON.stringify(labourHealthListForm)
    }
    dispatch(LabourActions.createLabourHealth(params))
  }
    };

    const validateForm = () => {
      let valid = true;
      if (!form.bloodGroup) {
        errObj.bloodGroup = 'This field is required';
        valid = false;
      }
      if (!form.height) {
        errObj.height = 'This field is required';
        valid = false;
      }
      if (!form.weight) {
        errObj.weight = 'This field is required';
        valid = false;
      }
      if (!form.pr) {
        errObj.pr = 'This field is required';
        valid = false;
      }
      if (!form.bp) {
        errObj.bp = 'This field is required';
        valid = false;
      }
      if (!form.cvs) {
        errObj.cvs = 'This field is required';
        valid = false;
      }
      if (!form.chest) {
        errObj.chest = 'This field is required';
        valid = false;
      }
      if (!form.abdomen) {
        errObj.abdomen = 'This field is required';
        valid = false;
      }
      if (!form.musculoskeletal) {
        errObj.musculoskeletal = 'This field is required';
        valid = false;
      }
      if (!form.skin) {
        errObj.skin = 'This field is required';
        valid = false;
      }
    
      setErrObj({...errObj});
      return valid;
    };

    useEffect(() => {
      if (success) {
        toast.success(`${success?.message ?? "Success"}`, {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
        });
  
        dispatch(GeneralActions.removeSuccess(LabourActions.createLabourHealth.type));
        dispatch(LabourActions.getLabourFormList(id))

      }
    }, [success]);

 useEffect(() => {
        if (error) {
          toast.success(`${error?.message ?? "Error"}`, {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
          });
    
          dispatch(GeneralActions.removeError(LabourActions.createLabourHealth.type));
        }
      }, [error]);

      useEffect(() => {
        if (documentUploadSuccess) {
          toast.success(`${documentUploadSuccess?.message ?? "Success"}`, {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
          });
    
          dispatch(GeneralActions.removeSuccess(LabourActions.updateHealthDocument.type));
        }
      }, [documentUploadSuccess]);
     
     const handleChangeDocument = (e) => {
      const file = e.target.files[0];
      setDocument(file)
    } 
    const handleSubmitDocument = () => {
   
        const params ={
          medicalDocument: document,
          id: labourFormList.health_report_id
        }
        dispatch(LabourActions.updateHealthDocument(params))
    }
    

    return (
      <Card>
        {loader ? (
          <FormSkeleton />
        ) : (
          <>
            <Typography
              variant="h5"
              sx={{ float: "left", fontWeight: "500" }}
            >
              Fitness
            </Typography>
            {!labourFormList.is_new_health_documents ? (
              <Typography sx={{ float: "right", color: "#2c7be5" }}>
                <VisibilityOutlined
                  sx={{ cursor: "pointer" }}
                  onClick={() => history.push(`/app/labourinduction/fitness/${id}`)}
                />
              </Typography>
            ) : null}
            <Grid container spacing={3} sx={{mt: 0, mb: 3}}>
              <Grid item lg={6} xs={12}>
                  <Input
                      fullWidth
                      label="Height(Feet)*"
                      type="number"
                      name="height"
                      value={form.height}
                      onChange={(e) => {
                        delete errObj.height;
                        setErrObj({...errObj});
                        form.height = e.target.value
                        setForm({...form})
                      }}
                    />
                {errObj.height && <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>}
              </Grid>
              <Grid item lg={6} xs={12}>
                  <Input
                      fullWidth
                      label="weight(KG)*"
                      type="number"
                      name="weight"
                      value={form.weight}
                      error={errObj.weight}
                      onChange={(e) => {
                        delete errObj.weight;
                        setErrObj({...errObj});
                        form.weight = e.target.value
                        setForm({...form})
                      }}
                      />
                {errObj.weight && <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>}
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography varient='h6' sx={{fontSize: '15px', color: '#222'}}>Blood Group *</Typography>
                <SelectField
                    className="text-left"
                    name="bloodGroup"
                    value={form.bloodGroup}
                    error={errObj.bloodGroup}
                    onChange={(e) => {
                      delete errObj.bloodGroup;
                      setErrObj({...errObj});
                      form.bloodGroup = e.target.value
                      setForm({...form})
                    }}
                    >
                    {bloodGroup.map((item,index) => (
                      <MenuItem key={index} value={item}>
                          {item}
                      </MenuItem>
                      ))} 
                </SelectField>
                {errObj.bloodGroup && <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>}
           </Grid>
              <Grid item lg={6} xs={12}>
                  <Input
                      fullWidth
                      label="PR *"
                      name="pr"
                      value={form.pr}
                      error={errObj.pr}
                      onChange={(e) => {
                        delete errObj.pr;
                        setErrObj({...errObj});
                        form.pr = e.target.value
                        setForm({...form})
                      }}
                      />
                {errObj.pr && <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>}
            </Grid>
              <Grid item lg={6} xs={12}>
                  <Input
                      fullWidth
                      label="BP *"
                      name="bp"
                      value={form.bp}
                      error={errObj.bp}
                      onChange={(e) => {
                        delete errObj.bp;
                        setErrObj({...errObj});
                        form.bp = e.target.value
                        setForm({...form})
                      }}
                      />
                {errObj.bp && <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>}
          </Grid>
              <Grid item lg={6} xs={12}>
                  <Input
                      fullWidth
                      label="Skin *"
                      name="skin"
                      value={form.skin}
                      error={errObj.skin}
                      onChange={(e) => {
                        delete errObj.skin;
                        setErrObj({...errObj});
                        form.skin = e.target.value
                        setForm({...form})
                      }}
                      />
                {errObj.skin && <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>}
            </Grid>
              <Grid item lg={6} xs={12}>
                  <Input
                      fullWidth
                      label="C.V.S *"
                      name="cvs"
                      value={form.cvs}
                      error={errObj.cvs}
                      onChange={(e) => {
                        delete errObj.cvs;
                        setErrObj({...errObj});
                        form.cvs = e.target.value
                        setForm({...form})
                      }}
                      />
                {errObj.cvs && <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>}
            </Grid>
              <Grid item lg={6} xs={12}>
                  <Input
                      fullWidth
                      label="Chest *"
                      name="chest"
                      value={form.chest}
                      error={errObj.chest}
                      onChange={(e) => {
                        delete errObj.chest;
                        setErrObj({...errObj});
                        form.chest = e.target.value
                        setForm({...form})
                      }}
                      />
                {errObj.chest && <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>}
            </Grid>
              <Grid item lg={6} xs={12}>
                  <Input
                      fullWidth
                      label="Abdomen *"
                      name="abdomen"
                      value={form.abdomen}
                      error={errObj.abdomen}
                      onChange={(e) => {
                        delete errObj.abdomen;
                        setErrObj({...errObj});
                        form.abdomen = e.target.value
                        setForm({...form})
                      }}
                      />
                {errObj.abdomen && <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>}
           </Grid>
              <Grid item lg={6} xs={12}>
                  <Input
                      fullWidth
                      label="Musculoskeletal *"
                      name="musculoskeletal"
                      value={form.musculoskeletal}
                      error={errObj.musculoskeletal}
                      onChange={(e) => {
                        delete errObj.musculoskeletal;
                        setErrObj({...errObj});
                        form.musculoskeletal = e.target.value
                        setForm({...form})
                      }}
                      />
                {errObj.musculoskeletal && <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>}
            </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={12} xs={12}>
                <Table className="health_Tab">
                  <TableHead>
                    <TableRow>
                      <TableCell width="35%">Particulars</TableCell>
                      <TableCell>Test Conducted</TableCell>
                      <TableCell>Satisfactory</TableCell>
                    </TableRow>
                  </TableHead>
                  {labourHealthListForm &&
                    labourHealthListForm.map((item, index) => (
                      <TableBody key={index}>
                        <TableRow>
                          <TableCell
                            width="35%"
                            rowSpan="2"
                            sx={{ borderRight: "1px solid #ccc" }}
                          >
                            {item.name}
                          </TableCell>
                          <TableCell>
                            <SwitchBox>
                              <Switch
                                checked={item.DeficiencyObserved}
                                name="status"
                                onChange={(e) => handleDeficiencyObservedStatus(index, e)}
                              />
                              {item.DeficiencyObserved ? (
                                <Typography
                                  variant="p"
                                  sx={{ marginLeft: "5px" }}
                                >
                                  Yes
                                </Typography>
                              ) : (
                                <Typography
                                  variant="p"
                                  sx={{ marginLeft: "5px" }}
                                >
                                  No
                                </Typography>
                              )}
                            </SwitchBox>
                          </TableCell>
                          <TableCell>
                            <SwitchBox>
                              <Switch
                                checked={item.satisfactory}
                                name="status"
                                label="y"
                                onChange={(e) => handlesatisfactoryStatus(index, e)}
                              />
                              {item.satisfactory ? (
                                <Typography
                                  variant="p"
                                  sx={{ marginLeft: "5px" }}
                                >
                                  Yes
                                </Typography>
                              ) : (
                                <Typography
                                  variant="p"
                                  sx={{ marginLeft: "5px" }}
                                >
                                  No
                                </Typography>
                              )}
                            </SwitchBox>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan="2" className="inputbg">
                            <TextareaAutosize
                              style={{
                                textAlign: "left",
                                width: "100",
                                height: "80",
                              }}
                              name="remark"
                              maxRows={4}
                              aria-label="maximum height"
                              placeholder="Remark Here.."
                              value={item.remark}
                              onChange={(e) => handleChangeRemark(index, e)}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                </Table>
              </Grid>
            
              {(form.bloodGroup || form.height || form.weight) && labourDetails?.results?.status !== 99 ?(
              <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  onClick={handleSubmitValue}
                >
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <>
                      {!labourFormList.is_new_health_documents ? (
                        <>Update</>
                      ) : (
                        <>Submit</>
                      )}
                    </>
                  )}
                </Button>
              </Grid>):null}
            </Grid>
          </>
        )}
        {!labourFormList.is_new_health_documents ? (
          <Grid container sx={{ py: 2, alignItems: 'center' }}>
            <Grid item lg={6} sm={12} xs={12} sx={{ py: 2 }}>
                <div className="cursor-pointer w-100 position-relative"
                 style={{background: '#f1f1f1', padding: '5px 10px', borderRadius: '5px'}}
                >
                  <input
                    type="file"
                    id= "profile1"
                    onChange={handleChangeDocument}
                  />
                </div>
            </Grid>
            {labourDetails?.results?.status !== 99 ?(
            <Grid item lg={6} sm={12} xs={12} sx={{ py: 2 }}>
              <Typography sx={{textAlign: 'right', mt: 2}}>
                <Button
                  type="submit"
                  disabled={!document}
                  color="primary"
                  variant="contained"
                  onClick={handleSubmitDocument}
                >
                  Submit
                </Button>
              </Typography>
            </Grid>):null}
          </Grid>
        ) : (
          ""
        )}
      </Card>
    );
}