import React, { useEffect ,useState} from "react";
import {
    Box,
    Card, Table, TableCell, TableHead, TableRow,TableBody,
    Typography,Chip,IconButton
 
} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; 
import {useHistory,useParams} from "react-router-dom";
import { GeneralActions, LabourActions } from "slices/actions";
import { useDispatch, useSelector } from "react-redux";
import { GeneralSelector, LabourSelector } from "selectors";
import DialogConfirm from "components/DialogConfirm";
import { toast } from "react-toastify";
import { Delete, Edit } from "@mui/icons-material";
import { Constants } from "utils/constants";
import PropTypes from "prop-types";

SafetyTrainingListing.propTypes = {
    labourDetails: PropTypes.object,
 };
export default function SafetyTrainingListing(props) {
    const { labourDetails} = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const {id} = useParams();
    const [selected, setSelected] = useState(null);
    const safetyTrainigListing = useSelector(LabourSelector.getSafetyTrainingList())
    const [confirmDelete, setConfirmDelete] = useState(false);
    const success = useSelector(GeneralSelector.success(LabourActions.deleteSafetyTraing.type));
    const error = useSelector(GeneralSelector.error(LabourActions.deleteSafetyTraing.type))
    const userType = localStorage.getItem('userType')

    useEffect(() => {
        if (id) {
        dispatch(LabourActions.getSafetyTrainingList(id))
        }
        
    },[id])

    useEffect(() => {
        if (success) {
          setConfirmDelete(false);
          setSelected(null);
    
          toast.success(`${success?.message ?? "Success"}`, {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
          });
    
          dispatch(GeneralActions.removeSuccess(LabourActions.deleteSafetyTraing.type));
          dispatch(LabourActions.getSafetyTrainingList(id))
        }
      }, [success]);

     useEffect(() => {
        if (error) {
          setConfirmDelete(false);
          setSelected(null);
    
          toast.success(`${error?.message ?? "Error"}`, {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
          });
        }
      }, [error]);

      const handleDelete = () => {
        dispatch(LabourActions.deleteSafetyTraing(selected));
      };

    
    return (
        <Card sx={{position: "relative"}}>
            <Typography variant='h5' sx={{ mb: 4 }}>Safety Training</Typography>
            {labourDetails?.result?.status !== 99?(
               <AddCircleOutlineIcon
                onClick={() => history.push(`/app/safetytraining`)}
                fontSize= "large" sx={{position: "absolute", cursor: "pointer", right: "15px", top: "20px", color: "#3f7ed2"}}
             />):null}
            
            <Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Training Type</TableCell>
                            <TableCell>Training Name</TableCell>
                            <TableCell>Status</TableCell>
                            {userType !== Constants.SUB_CONTRACTOR_USER?(
                            <TableCell>Option</TableCell>):null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {safetyTrainigListing && safetyTrainigListing.map((item,index) => (
                        <TableRow key={index}>
                            <TableCell align="left">
                                {item.trainingType}
                            </TableCell>
                            <TableCell align="left">
                                {item.trainingName}
                            </TableCell>
                            <TableCell align="left">
                                    {item.status === 1 && <Chip label="Approve" color="primary"/>}
                                    {item.status === 2 && <Chip label="Reject" />}
                                    {item.status === 0 && <Chip label="Pending" color="success" />}
                            </TableCell>
                            {userType !== Constants.SUB_CONTRACTOR_USER?(
                            <TableCell align="left">
                                <IconButton 
                                    onClick={() => history.push(`/app/safetytraining/${item._id}`)}
                                >
                                    <Edit className="text-primary" />
                                </IconButton>
                                <IconButton 
                                    onClick={() => {
                                        setConfirmDelete(true);
                                        setSelected(item._id);
                                   }}
                                >
                                    <Delete className="text-default" />
                                </IconButton>
                            </TableCell>
                            ):null}
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </Box>    
            <DialogConfirm
                 title="Delete SafetyTraining"
                 content="Are you sure want to delete this Data?"
                 open={confirmDelete}
                 onClose={() => setConfirmDelete(false)}
                 onSubmit={handleDelete}
                />
        </Card>
    )
}