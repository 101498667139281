import {all, call, put, takeLatest} from 'redux-saga/effects';
import { ContractorService } from 'services/ContractorService';
import { ContractorActions, GeneralActions } from 'slices/actions';

function *getContractorList({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(ContractorService.GetContractorList ,payload);
        yield put(ContractorActions.getContractorListSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}


export function *ContractorWatcher() {
    yield all([
      yield takeLatest(ContractorActions.getContractorList.type,getContractorList),
    ]);
}
