import { Box, Card, Chip,Link, Pagination, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ListSkeleton from 'components/Skeleton/ListSkeleton';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GeneralSelector, TransactionSelector } from 'selectors';
import { GeneralActions, TransactionActions } from 'slices/actions';
import { format } from 'date-fns';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DialogConfirm from 'components/DialogConfirm';
import PropTypes from "prop-types";
import { toast } from 'react-toastify';

Listing.propTypes = {
    formData: PropTypes.object
};

export default function Listing(props) {
    const {formData} = props;
    const allTransactionList = useSelector(TransactionSelector.allTransactionList());
    const loading = useSelector(GeneralSelector.loader(TransactionActions.getAllTransactionList.type))
    const pagination = useSelector(TransactionSelector.getPage());
    const [confirmStatusChng, setConfirmStatusChng] = useState(false);
    const [status,setStatus] = useState();
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(null);
    const success = useSelector(GeneralSelector.success(TransactionActions.paymentStatus.type));

    const handleChangePagination = (e, val) => {
        setPage(val);
    };

    const handleStatusChange = () => {
        if (status === 0) {
          const params = {
            transaction_id: selected,
            status: 2
          }
         dispatch(TransactionActions.paymentStatus(params));
        } else if (status === 2) {
          const params = {
            transaction_id: selected,
            status: 1
          }
         dispatch(TransactionActions.paymentStatus(params));
        }
      }

      useEffect(() => {
        if (success) {
            setConfirmStatusChng(false);
            setSelected(null);
      
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true
                });
      
            dispatch(GeneralActions.removeSuccess(TransactionActions.paymentStatus.type));
            dispatch(TransactionActions.getAllTransactionList(formData))
            }
        }, [success]); 

    return (
        <Card>
            {loading ? (
                <ListSkeleton />
            ) : (
                <Box>
                    {!formData.start_date && !formData.end_date &&allTransactionList?.data?.results && allTransactionList?.data?.results?.length === 0 ?(
                                <TableRow>
                                    <TableCell align="center" colSpan={7}>
                                        No Data
                                    </TableCell>
                                </TableRow>
                      ):(
                    <>
                   {formData.start_date && formData.end_date && (
                    <>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Company Name</TableCell>
                                <TableCell>Project Name</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Bill Date</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                         { formData.start_date && formData.end_date && allTransactionList?.data?.results && allTransactionList?.data?.results.map((item, i) => (
                                <TableRow
                                    key={i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>{item.companyName}</TableCell>
                                    <TableCell>{item.projectname}</TableCell>
                                    <TableCell>{item.amount}</TableCell>
                                    <TableCell>{item.billate_date && format(new Date(item.billate_date), 'dd/MM/yyyy')}</TableCell>
                                    <TableCell>
                                        {item.status === 0 &&
                                            <Chip
                                                label="Pending"
                                                color="default"
                                                size='small'
                                                onClick={() => {
                                                    setStatus(item.status)
                                                    setConfirmStatusChng(true);
                                                    setSelected(item._id);
                                                }}
                                            />}
                                        {item.status === 2 &&
                                            <Chip
                                                label="Invoice Generated"
                                                color="default"
                                                size='small'
                                                onClick={() => {
                                                    setStatus(item.status)
                                                    setConfirmStatusChng(true);
                                                    setSelected(item._id);
                                                }}
                                            />}
                                        {item.status === 1 &&
                                            <Chip
                                                label="Paid"
                                                color="primary"
                                                size='small'
                                            />}
                                    </TableCell>
                                    <TableCell>
                                        {item?.invoice ? (
                                            <Link href={allTransactionList?.data?.invoiceURL + item?.invoice} target='_blank' className="cursor-pointer" >
                                                <ArrowDownwardIcon />
                                            </Link>) : null}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Pagination
                        sx={{ mt: 1 }}
                        page={page}
                        count={pagination && pagination.pages}
                        onChange={handleChangePagination} />
                    </>
                    )}
                    </>)}
                </Box>
             )}
            <DialogConfirm
                title={status === 0 ? "Generate Invoice" : "Proceed To Payment"}
                content="Are you sure?"
                status={status}
                open={confirmStatusChng}
                onClose={() => setConfirmStatusChng(false)}
                onSubmit={handleStatusChange}
            />
        </Card>
    );
}