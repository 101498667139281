import React, {useEffect, useState} from "react";
import {
    Box,
    Card,
    CircularProgress,
    Grid,
    Switch,
    Typography
} from "@mui/material";
import PageTitle from "components/PageTitle";
import styled from "@emotion/styled";
import Avatar from "assets/avatar.svg";
import {useDispatch, useSelector} from "react-redux";
import {GeneralSelector} from "selectors";
import {useParams} from "react-router-dom";
import {CompanyActions, GeneralActions, MasterDataActions } from "slices/actions";
import menus from "./constants/menus";
import CompanyMenuForm from "./Component/AboutCompanyDetails/form/CompanyMenuForm";
import FormSkeleton from "components/Skeleton/FormSkeleton";
import { CompanySelector } from "selectors/CompanySelector";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import DialogConfirm from "components/DialogConfirm";
import {toast} from "react-toastify";


const Picker = styled(Box)(() => ({
    width: 120,
    height: 120,
    margin: "40px auto 10px auto",

    "& input": {
        display: "none"
    },

    "& img": {
        width: "100%",
        borderRadius: 100
    }
}));

const SwitchBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
}));

export default function CompanyDetail() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const companyDetails = useSelector(CompanySelector.getCompanyById());
    const loading = useSelector(GeneralSelector.loader(CompanyActions.getCompanyById.type));
    const success = useSelector(GeneralSelector.success(CompanyActions.uploadImage.type));
    const error = useSelector(GeneralSelector.error(CompanyActions.uploadImage.type));
    const imageLoader = useSelector(GeneralSelector.loader(CompanyActions.uploadImage.type));
    const [preview, setPreview] = useState(null);
    const [selected, setSelected] = useState();
    const [openDialog,setOpenDialog] = useState(false);
    const [statusCheck,setStatusCheck] = useState(false);
    const [statusValue,setStatusValue] = useState(false);
    const [logo, setLogo] = useState();


    useEffect(() => {
    const subMenuValue = localStorage.getItem('selectedCompanySubMenuId') 
    if (subMenuValue) {
        setSelected(subMenuValue)   
    } else {
        setSelected(menus[0].id)  
    }  
    },[selected])

   
    useEffect(() => {
     if (id) {
            dispatch(CompanyActions.getCompanyById(id));
        }
    }, [id]);
  
 
    useEffect(() => {
        setLogo(companyDetails.logo)
        if (companyDetails.status === 2) {
            setStatusValue(false);
        } else {
            setStatusValue(true);
        }
        
    }, [companyDetails]);

    const handleChangeImage = (e) => {
        const file = e.target.files[0];
        const objectUrl = URL.createObjectURL(file);
        setLogo(file)
        setPreview(objectUrl);
        const params = {
            logo: file,
            id: id
        }
        dispatch(CompanyActions.uploadImage(params))
    };
    const selectItemFromSubMenu = (id) => {
        setSelected(id)
        localStorage.setItem('selectedCompanySubMenuId',id)
    }

    const changeCompanyStatus = (e) => {
        const { checked } = e.target;
        setOpenDialog(true);
        setStatusCheck(checked);
    }
    const handleToggleStatus = () => {
        setStatusValue(statusCheck)
        console.log('statusCheck',statusCheck)
        const params = {
            id: id,
            status: statusCheck?1:2,
            flag: "Company"
        }
        dispatch(MasterDataActions.changeStatus(params))
        setOpenDialog(false)
    }; 
    useEffect(() => {
        if (success) {
               toast.success(`${success?.message ?? "Success"}`, {
                       position: "top-right",
                       autoClose: 3000,
                       closeOnClick: true,
                   });
   
               dispatch(GeneralActions.removeSuccess(CompanyActions.uploadImage.type));
           }
       }, [success]);
   
       useEffect(() => {
           if (error) {
               toast.error(`${error?.message ?? "Error"}`, {
                   position: "top-right",
                   autoClose: 3000,
                   color: "white",
                   closeOnClick: true,
               });
                 dispatch(GeneralActions.removeError(CompanyActions.uploadImage.type));
               
           }
       }, [error]);

    // window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth',
    // });

    return (
        <Box>
            <PageTitle isBack={true} title={companyDetails.name}/>

            {loading ? (
                 <FormSkeleton/>
            ) : (
                <Grid container spacing={3}>
                    <Grid item lg={4} sm={12} xs={12}>
                        <Card>
                            {imageLoader?(
                            <CircularProgress color="inherit" size={20} />):(
                            <Picker>
                                <input
                                    type="file"
                                    id="profile"
                                    onChange={handleChangeImage}
                                />
                                <label htmlFor="profile">
                                    <img className="cursor-pointer uploadimgfitsize"
                                        alt="profile"
                                        src={preview || logo || Avatar}/>
                                    <span>
                                        <CameraAltOutlinedIcon sx={{fontSize: '25', mr: '2'}} />
                                        Upload Photo
                                    </span>
                                </label>
                            </Picker>
                            )}
                            <Box sx={{ mt: 7 }}>
                                <SwitchBox>
                                    <Typography>{statusValue?"Disable Company":"Enable Company"}</Typography>
                                    <Switch
                                        disabled={companyDetails.role && companyDetails.role.Status === false}
                                        checked={statusValue}
                                        name="status"
                                        onChange={changeCompanyStatus}/>
                                </SwitchBox>
                            </Box> 

                            <Box sx={{ mt: 2 }}>
                                <CompanyMenuForm
                                    selected={selected}
                                    onSelect={(id) => selectItemFromSubMenu(id)}
                                    // role={companyDetails.role}
                                    />

                            </Box>
                        </Card>
                    </Grid>
                    <Grid item lg={8} sm={12} xs={12}>
                        {menus.map(({id, component: Component}, i) => {
                            if (selected === id) {
                                return <Component
                                    key={i}
                                    companyDetails={companyDetails}
                                    />
                            }

                            return <div key={i}/>
                        })}
                    </Grid>
                </Grid>
            )}
            <DialogConfirm
               title="Change Status"
               content={statusValue?"Are you sure want disable Company?":"Are you sure want Enable Company?"}
               open={openDialog}
               onClose={() => setOpenDialog(false)}
               onSubmit={handleToggleStatus} 
            />
        </Box>
    )
}