import React, { useEffect, useState } from "react";
import { Box, Button, Card, CircularProgress, Grid } from "@mui/material";
import PageTitle from "components/PageTitle";
import styled from "@emotion/styled";
import Avatar from "assets/avatar.svg";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { GeneralSelector, UserSelector } from "selectors";
import { DepartmentActions, DesignationActions, GeneralActions, UserActions } from "slices/actions";
import { toast } from "react-toastify";
import BasicInformation from "./components/BasicInformation";
import AccountSetting from "./components/AccountSetting";
import COUNTRIES from "../../constants/countries";
import FormSkeleton from "../../components/Skeleton/FormSkeleton";
import Bankaccountdetail from "./components/Bankaccountdetail";
import TransactionInformation from "./components/TransactionInformation";

const Picker = styled(Box)(() => ({
    width: 120,
    height: 120,
    margin: "40px auto 10px auto",

    "& input": {
        display: "none"
    },

    "& img": {
        width: "100%",
        borderRadius: 100
    }
}));


export default function Profile() {
    const dispatch = useDispatch();
    const profile = useSelector(UserSelector.profile());
    const loading = useSelector(GeneralSelector.loader(UserActions.profileUser.type));
    const success = useSelector(GeneralSelector.success(UserActions.updateUser.type));
    const loaderUpdate = useSelector(GeneralSelector.loader(UserActions.updateUser.type));
    const email = profile?.email ?? ''
    console.log(email)

    const [preview, setPreview] = useState(null);

    const countries = COUNTRIES.map(item => ({
        id: item.id,
        name: item.name,
        phoneCode: item.phoneCode,
        flag: item.flag
    }));

    useEffect(() => {
        dispatch(UserActions.profileUser());
        dispatch(DepartmentActions.getDepartments());
        dispatch(DesignationActions.getDesignations());
    }, []);

    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });
            dispatch(GeneralActions.removeSuccess(UserActions.updateUser.type));
        }
    }, [success]);

    const validationSchema = yup.object({
        state: yup.string().required('State is required'),
        name: yup.string().required('Name is required'),
        // password: yup.string().required('Password is required'),
        // email: yup.string().email().
        //     required('Email is required'),
        
      ...(localStorage.getItem("userType") !== '1' ? {} : { hsn: yup.string().matches(/^[0-9]{1,8}$/, 'HSN code must be between 1 to 8 digits').
            required('HSN Code is required') }),

             ...(localStorage.getItem("userType") !== '1' ? {} : { gstnumber: yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}Z[a-zA-Z0-9]{1}$/, "Invalid GST No").
            required('GST is required') }),

        // gstnumber: yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}Z[a-zA-Z0-9]{1}$/, "Invalid GST No").
        //     required('GST is required'),
        // hsn: yup.string().matches(/^[0-9]{1,8}$/, 'HSN code must be between 1 to 8 digits').
        //     required('HSN Code is required'),
        bankAccountNo: yup.string().
            matches(/^[0-9]{9,18}$/, "Invalid Bank number").
            required("This field is required"),
        ifscCode: yup.string().
            matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Enter valid ifsc code").
            required("This field is required"),

             ...(localStorage.getItem("userType") !== '1' ? {} : { cgst: yup.string().required('cgst is required') }),
        // cgst: yup.string().required('cgst is required'),
        // igst: yup.string().required('igst is required'),
        // sgst: yup.string().required('sgst is required')
             ...(localStorage.getItem("userType") !== '1' ? {} : { igst: yup.string().required('igst is required') }),
             ...(localStorage.getItem("userType") !== '1' ? {} : { sgst: yup.string().required('sgst is required') }),


    });

    const formik = useFormik({
        initialValues: {
            // email: profile?.email ?? '',
            // email: '',
            avatar: profile?.avatar ?? null,
            name: profile?.name ?? '',
            phone: profile?.phone ?? '',
            // birthday: profile?.birthday ?? '',
            // gender: profile?.gender ?? '',
            city: profile?.city ?? '',
            gstnumber: profile?.gstnumber ?? '',
            password: "",
            address: profile?.address ?? '',
            hsn: profile?.hsn ?? '',
            bankName: profile?.bankName ?? "",
            bankAccountNo: profile?.bankAccountNo ?? "",
            ifscCode: profile?.ifscCode ?? "",
            bankAddress: profile?.bankAddress ?? "",
            bankBranchName: profile?.bankBranchName ?? "",
            bankState: profile?.bankState ?? "",
            bankCity: profile?.bankCity ?? "",
            igst: profile?.igst ?? '',
            cgst: profile?.cgst ?? '',
            sgst: profile?.sgst ?? '',
            state: profile?.state ?? ''
            //             email
            // password
            // phoneNumber
            // birthday
            // gender

            // id: 62d946649caceb237088b136
            // email: admin@ecosphere.com
            // avatar: http://103.112.26.98:5000/uploads/6269-01_battery.jpg
            // name: SUPER ADMIN
            // phoneNumber: 9806160101
            // birthday: 2022-07-28
            // gender: female
            // city: Delhi
            // gstnumber: 24AAACC4175D1Z4
            // country: India
            // phoneCode: 91
            // phone: 919806160101


        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    useEffect(() => {
        if (profile) {
            const phone = profile.phone;
            const country = countries.find(e => e.name === profile.country);

            if (country) {
                formik.setFieldValue('country', country);
            }

            if (phone && country) {
                const code = country.phoneCode;

                formik.setFieldValue('phoneCode', code ?? '');
                // formik.setFieldValue('phoneNumber', phone ? phone.substring(code.length ?? 0) : '');
            }
        }
    }, [profile]);

    const handleChangeImage = (e) => {
        const file = e.target.files[0];
        const objectUrl = URL.createObjectURL(file);

        formik.setFieldValue('avatar', file);
        setPreview(objectUrl);
        const params = {
            id: profile._id,
            avatar: file
        }
        dispatch(UserActions.updateUser(params))
    };

    const handleSubmit = (values) => {
        const params = {
            id: profile._id,
            ...values,
            // ...(values.phoneCode && values.phoneNumber && {
            //     phone: values.phoneCode + values.phoneNumber
            // }
            // )
        };

        Object.keys(params).forEach(key => {
            if (params[key] === '') {
                delete params[key];
            }
        })

        dispatch(UserActions.updateUser(params))
    }

    return (
        <Box>
            <PageTitle isBack={true} title='Update Profile' />
            {loading ? (
                <FormSkeleton />
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item lg={4} sm={12} xs={12}>
                            <Card sx={{ paddingBottom: '76px' }}>
                                <Picker>
                                    <input
                                        type="file"
                                        id="profile"
                                        onChange={handleChangeImage} />
                                    <label htmlFor="profile">
                                        <img
                                            alt="profile"
                                            src={preview || formik.values.avatar || Avatar} />
                                    </label>
                                </Picker>
                            </Card>
                            <Grid sx={{ mt: 3 }}>
                                <AccountSetting
                                    formik={formik} email={email} />
                            </Grid>
                        </Grid>


                        <Grid item lg={8}>
                            <BasicInformation
                                formik={formik} />
                            {
                                localStorage.getItem("userType") === '1' && <TransactionInformation
                                    formik={formik} />
                            }

                            <Bankaccountdetail
                                formik={formik} />
                            {/* <AccountSetting
                                formik={formik} /> */}
                            <Grid container justifyContent='flex-end' sx={{ mt: 5 }}>
                                <Button

                                    fullWidth
                                    size='large'
                                    variant='contained'
                                    color='primary'
                                    type='submit'>
                                    {loaderUpdate ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : (
                                        <>Submit</>
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Box>
    )
}