import React, { useEffect,useState } from "react";
import {
   Grid, 
  Card,
  Typography,
  IconButton,
  
} from "@mui/material";
import Input from "components/Input";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useHistory, useParams } from "react-router-dom";
import { GeneralActions, ProjectActions } from "slices/actions";
import { useDispatch, useSelector } from "react-redux";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GeneralSelector, ProjectSelector } from "selectors";
import { format } from 'date-fns';
import { Delete, Edit } from "@mui/icons-material";
import DialogConfirm from "components/DialogConfirm";
import { toast } from "react-toastify";

function ProjectWagesListing() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const success = useSelector(GeneralSelector.success(ProjectActions.deleteDailyWages.type));
  const error = useSelector(GeneralSelector.error(ProjectActions.deleteDailyWages.type));
  const dailyWagesListing = useSelector(ProjectSelector.getDailyWagesListing());
  const [selected, setSelected] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    if (id) {
      const params = {
        projectId: id
      }
     dispatch(ProjectActions.dailyWagesListing(params));
    }
  }, [id]);

  useEffect(() => {
    if (success) {
      setConfirmDelete(false);
      setSelected(null);
     toast.success(`${success?.message ?? "Success"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });
      dispatch(GeneralActions.removeSuccess(ProjectActions.deleteDailyWages.type));
      const params = {
        projectId: id
      }
     dispatch(ProjectActions.dailyWagesListing(params));
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      setConfirmDelete(false);
      setSelected(null);
       toast.error(`${error?.message ?? "Success"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });
      dispatch(GeneralActions.removeError(ProjectActions.deleteDailyWages.type));
    }
  }, [error]);

  const handleDelete = () => {
    dispatch(ProjectActions.deleteDailyWages(selected));
  };

  return (
    <Card sx={{ position: "relative" }}>
      <Typography variant="h5" sx={{ mb: 4 }}>
        Daily Wages Listing
      </Typography>
        <AddCircleOutlineIcon
        // onClick={() => history.push("/app/project/wages/projectwageAdd")}
        onClick={() => history.push("/app/project/wages/projectwageAdd", { data: dailyWagesListing && dailyWagesListing })}

        fontSize="large"
        sx={{
          position: "absolute",
          cursor: "pointer",
          right: "15px",
          top: "20px",
          color: "#3f7ed2",
        }}
      />
    
      <div style={{marginBottom: '20px'}}>
        {dailyWagesListing && dailyWagesListing.results && dailyWagesListing.results.map((item,index) => (
          <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
               >
              <Typography>From {format(new Date(item.applyStartDate),'dd/MM/yyyy')} to {format(new Date(item.applyEndDate),'dd/MM/yyyy')}</Typography>
           </AccordionSummary>
              <AccordionDetails sx={{borderTop: '1px solid #ccc'}}>
                      <Grid container spacing={2}>
                          <Grid item lg={6} xs={12}>
                            <Input
                                label="Highly Skilled"
                                name='highlySkilled'
                                value={item.dw_highSkilled}
                   />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <Input
                                label="Semi-Skilled"
                                name='semiSkilled'
                                value={item.dw_semiSkilled}
                             />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <Input
                                label="Skilled"
                                name='skilled'
                                value={item.dw_skilled}
                              />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <Input
                                label="Unskilled"
                                name='unskilled'
                                value={item.dw_unSkilled}
                              />
                        </Grid>
                        <Grid item lg={12} xs={12} sx={{textAlign: 'right'}}>
                               <IconButton 
                                    onClick={() => history.push(`/app/project/projectwageUpdate/${item._id}`)}
                                >
                                <Edit className="text-primary" />
                                </IconButton>
                                <IconButton 
                                    onClick={() => {
                                        setConfirmDelete(true);
                                        setSelected(item._id);
                                   }}
                                >
                                    <Delete className="text-default" />
                                </IconButton>
                          
                           </Grid>
                      </Grid>
              </AccordionDetails>
          </Accordion>
        ))}
      </div>
           <DialogConfirm
                 title="Delete Wages"
                 content="Are you sure want to delete this Data?"
                 open={confirmDelete}
                 onClose={() => setConfirmDelete(false)}
                 onSubmit={handleDelete}
                />
    </Card>
  );
}

export default ProjectWagesListing;
