import { createSlice } from "@reduxjs/toolkit"

export const HolidaySlice = createSlice({
    name: "holiday",
    initialState: {
       holidayList: [],
       holidayDetails: {}
    },
    reducers: {
        createHoliday: () => {},
        deleteHoliday: () => {},
        updateHoliday: () => {},

        getHolidayById: () => {},
        getHolidayByIdSucess: (state,action) => {
            state.holidayList = action.payload
        },
        getHolidayDetails: () => {},
        getHolidayDetailsSuccess: (state,action) => {
            state.holidayDetails = action.payload.data
        }
      
    }
});

export default HolidaySlice;