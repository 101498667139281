import React ,{useState,useEffect} from "react";
import {
    Box,
    Typography, MenuItem,
    Grid
} from "@mui/material";
import Input from "components/Input";
import SelectField from "components/SelectField";
import PropTypes from "prop-types";
import { MasterDataSelector } from "selectors";
import { MasterDataActions } from "slices/actions";
import { useDispatch, useSelector } from "react-redux";

FamilyDetails.propTypes = {
    formik: PropTypes.object
};

export default function FamilyDetails(props) {
    const { formik } = props;
    const dispatch = useDispatch();
    const locationData = useSelector(MasterDataSelector.getLocationListByPincode());
    const [pincode,setpincode]= useState();

    const handleChangePinCode= (e) => {        
        const pincodeData = e.target.value
        formik.setFieldValue('pfNomineInfoFamilyDetails_Pincode',pincodeData)
        setpincode(pincodeData);
     }
    useEffect(() => {
        if (pincode && pincode.length === 6) {
            dispatch(MasterDataActions.fetchDataByPincode(pincode));
        }
    }, [pincode]); 
    
    useEffect(() => {
        if (locationData && pincode) {
        formik.setFieldValue('pfNomineInfoFamilyDetails_District', locationData.District?locationData.District :'');
        formik.setFieldValue('pfNomineInfoFamilyDetails_State', locationData.StateName?locationData.StateName :'');
        formik.setFieldValue('pfNomineInfoFamilyDetails_Address', locationData.PlaceName?locationData.PlaceName :'');
    }
     setpincode('')
    }, [locationData]);
    
    return (
        <Box>
            <Typography variant= "h6" sx={{my: 3, borderBottom: "1px solid #ccc"}}>Family Details</Typography>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Name *"
                            name='pfNomineInfoFamilyDetails_Name'
                            value={formik.values.pfNomineInfoFamilyDetails_Name}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfoFamilyDetails_Name && Boolean(formik.errors.pfNomineInfoFamilyDetails_Name)}
                            helperText={formik.touched.pfNomineInfoFamilyDetails_Name && formik.errors.pfNomineInfoFamilyDetails_Name}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Typography sx={{fontSize: "15px", fontWeight: "normal"}}>Gender *</Typography>
                        <SelectField
                            className="text-left"
                            name="pfNomineInfoFamilyDetails_Gender"
                            value={formik.values.pfNomineInfoFamilyDetails_Gender}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfoFamilyDetails_Gender && Boolean(formik.errors.pfNomineInfoFamilyDetails_Gender)}
                            helperText={formik.touched.pfNomineInfoFamilyDetails_Gender && formik.errors.pfNomineInfoFamilyDetails_Gender}
                            >
                            <MenuItem value="Male">
                                Male
                            </MenuItem>
                            <MenuItem value="Female">
                                Female
                            </MenuItem>
                        </SelectField>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Relation *"
                            name='pfNomineInfoFamilyDetails_Relation'
                            value={formik.values.pfNomineInfoFamilyDetails_Relation}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfoFamilyDetails_Relation && Boolean(formik.errors.pfNomineInfoFamilyDetails_Relation)}
                            helperText={formik.touched.pfNomineInfoFamilyDetails_Relation && formik.errors.pfNomineInfoFamilyDetails_Relation}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="DOB *"
                            name='pfNomineInfoFamilyDetails_DOB'
                            type='date'
                            value={formik.values.pfNomineInfoFamilyDetails_DOB}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfoFamilyDetails_DOB && Boolean(formik.errors.pfNomineInfoFamilyDetails_DOB)}
                            helperText={formik.touched.pfNomineInfoFamilyDetails_DOB && formik.errors.pfNomineInfoFamilyDetails_DOB}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Pincode *"
                            name='pfNomineInfoFamilyDetails_Pincode'
                            value={formik.values.pfNomineInfoFamilyDetails_Pincode}
                            onChange={handleChangePinCode}
                            error={formik.touched.pfNomineInfoFamilyDetails_Pincode && Boolean(formik.errors.pfNomineInfoFamilyDetails_Pincode)}
                            helperText={formik.touched.pfNomineInfoFamilyDetails_Pincode && formik.errors.pfNomineInfoFamilyDetails_Pincode}
                            />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="State *"
                            name='pfNomineInfoFamilyDetails_State'
                            value={formik.values.pfNomineInfoFamilyDetails_State}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfoFamilyDetails_State && Boolean(formik.errors.pfNomineInfoFamilyDetails_State)}
                            helperText={formik.touched.pfNomineInfoFamilyDetails_State && formik.errors.pfNomineInfoFamilyDetails_State}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="District *"
                            name='pfNomineInfoFamilyDetails_District'
                            value={formik.values.pfNomineInfoFamilyDetails_District}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfoFamilyDetails_District && Boolean(formik.errors.pfNomineInfoFamilyDetails_District)}
                            helperText={formik.touched.pfNomineInfoFamilyDetails_District && formik.errors.pfNomineInfoFamilyDetails_District}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Address *"
                            name='pfNomineInfoFamilyDetails_Address'
                            value={formik.values.pfNomineInfoFamilyDetails_Address}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfoFamilyDetails_Address && Boolean(formik.errors.pfNomineInfoFamilyDetails_Address)}
                            helperText={formik.touched.pfNomineInfoFamilyDetails_Address && formik.errors.pfNomineInfoFamilyDetails_Address}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Typography variant="h6" sx={{fontSize: "15px", fontWeight: "normal"}}>Disabled *</Typography>
                        <SelectField
                            className="text-left"
                            name="pfNomineInfoFamilyDetails_Disabled"
                            value={formik.values.pfNomineInfoFamilyDetails_Disabled}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfoFamilyDetails_Disabled && Boolean(formik.errors.pfNomineInfoFamilyDetails_Disabled)}
                            helperText={formik.touched.pfNomineInfoFamilyDetails_Disabled && formik.errors.pfNomineInfoFamilyDetails_Disabled}
                            >
                            <MenuItem value="Y">
                                Y
                            </MenuItem>
                            <MenuItem value="N">
                                N
                            </MenuItem>
                        </SelectField>
                    </Grid>
                </Grid>
        </Box>
    )
}