import React,{useEffect, useRef} from "react";
import PageTitle from "components/PageTitle";
import {Box, Card, Typography, Table, TableHead, TableRow, TableBody, TableCell, IconButton} from "@mui/material";
import { LocalPrintshopOutlined } from "@mui/icons-material";
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
import ReactToPrint from 'react-to-print';
import { LabourSelector } from "selectors";
import { useDispatch, useSelector } from "react-redux";
import { format } from 'date-fns';
import { LabourActions } from "slices/actions";
import { useParams } from "react-router-dom";
import Avatar from "assets/avatar.svg";


// function printIdCard () {
//     html2canvas(document.querySelector("#capture")).then(canvas => {
//         document.body.appendChild(canvas);
//         const imgData = canvas.toDataURL('image/png');
//         // const pdf = new jsPDF();
//         const pdf = new jsPDF('p', 'mm', [1280, 999]);
//         pdf.addImage(imgData, 'svg', 0, 0);
//         pdf.save("health.pdf"); 
//         location.reload();
//         pdf.close();
//     });
// }
export default function FitnessCertificate() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const labourDetails = useSelector(LabourSelector.getLabourById());
    const labourFormList = useSelector(LabourSelector.getLabourFormList())
    let componentRef=useRef(null);
    const pageStyle = `{ size: 297 mm 420 mm }`;

    useEffect(() => {
        if (id) {
              dispatch(LabourActions.getLabourById(id));
              dispatch(LabourActions.getLabourFormList(id))
        }
      }, [id]);
  
    return (
        <Box sx={{position: 'relative'}}>
            <PageTitle isBack={true} title="Fitness Certificate" sx={{m: 0}} />
            <ReactToPrint
                pageStyle={pageStyle}
                trigger = {() => (
                <IconButton sx={{position: 'absolute', top: 0, right: 0}}>
                <LocalPrintshopOutlined color='primary' sx={{cursor: 'pointer'}} />
                </IconButton>
                )}
                content={() => componentRef}
            />
            <div style={{clear: 'both'}}></div>
            <div id="capture" ref={el => (componentRef = el)}>
                <Card sx={{mb: 2}}>
                    <Table className="">
                        <TableHead className="thead-dark">
                            <TableRow>
                                <TableCell align='left' sx={{py: 0}}>
                                    <img src={`${"data:image/png;base64,"}${labourDetails.base64URL.companyLogo}`}
                                     style={{width: '80px', height: '50px', objectFit: 'contain'}} />
                                </TableCell>
                                <TableCell align='center' sx={{fontSize: 20, py: 0}}>
                                    {labourDetails?.base64URL?.data?.companyName}
                                </TableCell>
                                <TableCell align='right' sx={{py: 0}}>
                                    <img src={labourDetails.base64URL.logo?`${"data:image/png;base64,"}${labourDetails.base64URL.logo}`:Avatar}
                                    width='70px' height='70px' style={{borderRadius: '50%', objectFit: 'cover'}} 
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align='center' colSpan='3' sx={{py: 1}}>
                                    <Typography sx={{fontSize: 20, fontWeight: 'bold'}}>FORM-XI</Typography>
                                    <Typography varient='caption'>[See rule 223 (C)]</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align='center' colSpan='3' sx={{py: 1}}>
                                    <Typography sx={{fontSize: 20, fontWeight: 'bold'}}>
                                        CERTIFICATE OF MEDICAL EXAMINATION
                                    </Typography>
                                    <Typography varient='caption'>(PRE EMPLOYMENT)</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan='2'>
                                    <Typography>1.Certificate Serial No.............</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>Date : 
                                        <span className='BorderSpan'></span>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan='3'>
                                    <Typography>
                                        2. Name : <span className='BorderSpan'>{labourDetails?.result?.firstName} {labourDetails?.result?.lastName}</span>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan='3' sx={{pl: 4}}>
                                {/* <Typography sx={{width: '20px', display: 'inline-block', fontWeight: 'normal'}}></Typography> */}
                                    <Typography sx={{lineHeight: '2rem'}}>Identification Marks:-</Typography>
                                    <Typography sx={{lineHeight: '2rem'}}>
                                        1.<span className='BorderSpan'>{labourDetails?.result?.identificationMark}</span>
                                    </Typography>
                                    <Typography sx={{lineHeight: '2rem'}}>
                                        2.<span className='BorderSpan'></span>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan='3'>
                                    <Typography sx={{lineHeight: '2rem'}}>
                                        3. Father&apos;s Name/Husband&apos;s Name<span className='BorderSpan'>{labourDetails?.result?.gurdianName}</span>
                                    </Typography>
                                    <Typography sx={{lineHeight: '2rem'}}>
                                        4. Sex <span className='BorderSpan'>{labourDetails?.result?.gender}</span>
                                    </Typography>
                                    <Typography sx={{lineHeight: '2rem'}}>
                                        5. Residence <span className='BorderSpan'>
                                        {labourDetails?.result?.currentAddress},
                                        {labourDetails?.result?.currentCity},
                                        {labourDetails?.result?.currentState},
                                        {labourDetails?.result?.currentPincode}
                                        </span>
                                    </Typography>
                                    <Typography sx={{lineHeight: '2rem'}}>
                                        6. Date of Birth, if available <span className='BorderSpan'></span>
                                        and/or certificate age
                                        <span className='BorderSpan'>{labourDetails?.result?.dateOfBirth && format(new Date(labourDetails.result.dateOfBirth), 'dd/MM/yyyy')}</span>
                                    </Typography>
                                    <Typography sx={{lineHeight: '2rem'}}>
                                        7. Physical Fitness <span className='BorderSpan'></span>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan='3'>
                                    <Typography sx={{lineHeight: '2rem'}}>
                                        I hereby certify that, If have persionally examined(name)<span className='BorderSpan'>{labourDetails?.result?.firstName} {labourDetails?.result?.lastName}</span>
                                        Son/ daughter/ wife of<span className='BorderSpan'>{labourDetails?.result?.gurdianName}</span>
                                        residing<span className='BorderSpan' style={{marginRight: '5px'}}>
                                        {labourDetails?.result?.currentAddress},
                                        {labourDetails?.result?.currentCity},
                                        {labourDetails?.result?.currentState},
                                        {labourDetails?.result?.currentPincode} 
                                        </span>
                                         who is desirous of being employed in building a construction work/food handler and that his/her age as nearly as can be ascertained from my examination 
                                        <span className='BorderSpan'></span>
                                        years and that he/she is fit for employment in <span className='BorderSpan'></span>
                                            as adult/adolescent.
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan='3'>
                                    <Typography sx={{lineHeight: '2rem'}}>
                                        8. Reason for
                                    </Typography>
                                    <Typography sx={{lineHeight: '2rem'}}>
                                        (1) Refusal of certificate<span className='BorderSpan'></span>
                                    </Typography>
                                    <Typography sx={{lineHeight: '2rem'}}>
                                        (2) Certificate being revoked<span className='BorderSpan'></span>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan='2'>
                                    <Typography sx={{mt: 4}}>
                                        <span className='BorderSpan'></span><br/>
                                        <b>Signature/ Left Hand Thumb<br/>
                                        impression of building worker</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography sx={{mt: 4}}>
                                        <span className='BorderSpan'></span><br/>
                                        <b>Signature with seal<br/>
                                        Medical Officer</b>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table> 
                </Card>
                <Card>
                    <Table className="">
                        <TableHead className="thead-dark">
                            <TableRow>
                                <TableCell align='left' sx={{py: 0}}>
                                    <img src={`${"data:image/png;base64,"}${labourDetails.base64URL.companyLogo}`}
                                     style={{width: '80px', height: '50px', objectFit: 'contain'}} />
                                </TableCell>
                                <TableCell align='center' colSpan='2' sx={{fontSize: 20, py: 0}}>
                                    {labourDetails?.base64URL?.data?.companyName}
                                </TableCell>
                                <TableCell align='right' sx={{py: 0}}>
                                    <img src={labourDetails.base64URL.logo?`${"data:image/png;base64,"}${labourDetails.base64URL.logo}`:Avatar}
                                    width='70px' height='70px' style={{borderRadius: '50%', objectFit: 'cover'}} 
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align='center' colSpan='4' sx={{py: 1}}>
                                    <Typography sx={{fontSize: 20, fontWeight: 'bold'}}>
                                        MEDICAL FITNESS CERTIFICATE
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell colSpan='3'>
                                    <b>:</b> {labourDetails?.result?.firstName} {labourDetails?.result?.lastName}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>DOB</TableCell>
                                <TableCell colSpan='3'>
                                    <b>:</b> {labourDetails?.result?.dateOfBirth && format(new Date(labourDetails.result.dateOfBirth), 'dd/MM/yyyy')}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Sex</TableCell>
                                <TableCell colSpan='3'>
                                    <b>:</b> {labourDetails?.result?.gender}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Address</TableCell>
                                <TableCell colSpan='3'>
                                    <b>:</b>   
                                    {labourDetails?.result?.currentAddress},
                                        {labourDetails?.result?.currentCity},
                                        {labourDetails?.result?.currentState},
                                        {labourDetails?.result?.currentPincode}
                                </TableCell>
                            </TableRow> */}
                            <TableRow>
                                <TableCell colSpan='4' sx={{fontSize: 18}}>
                                    1) GENERAL PHYSICAL EXAM
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    PR
                                </TableCell>
                                <TableCell>
                                    {labourFormList?.labourInfo?.pr}
                                </TableCell>
                                <TableCell>
                                    BLOOD GROUP
                                </TableCell>
                                <TableCell>
                                    {labourFormList?.labourInfo?.bloodGroup}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    BP
                                </TableCell>
                                <TableCell>
                                    {labourFormList?.labourInfo?.bp}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Skin
                                </TableCell>
                                <TableCell>
                                {labourFormList?.labourInfo?.skin}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan='4' sx={{fontSize: 18}}>
                                    2) SYSTEMETIC EXAM
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>C.V.S</TableCell>
                                <TableCell><b>:</b></TableCell>
                                <TableCell colSpan='2'>
                                    {labourFormList?.labourInfo?.cvs}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Chest</TableCell>
                                <TableCell><b>:</b></TableCell>
                                <TableCell colSpan='2'>
                                    {labourFormList?.labourInfo?.chest}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Abdomen</TableCell>
                                <TableCell><b>:</b></TableCell>
                                <TableCell colSpan='2'>
                                {labourFormList?.labourInfo?.abdomen}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Musculoskeletal</TableCell>
                                <TableCell><b>:</b></TableCell>
                                <TableCell colSpan='2'>
                                    {labourFormList?.labourInfo?.musculoskeletal}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan='4' sx={{fontSize: 18}}>
                                    1) Eye Test
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan='4' sx={{fontSize: 18}}>
                                    2) Hearing
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan='4' sx={{fontSize: 18}}>
                                    3) Mental Status
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan='4' sx={{fontSize: 18}}>
                                    4) Social Status
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan='2'>
                                    <Typography sx={{lineHeight: '2rem'}}>
                                        Date : 
                                    </Typography>
                                    <Typography sx={{lineHeight: '2rem'}}>
                                        Place : 
                                    </Typography>
                                </TableCell>
                                <TableCell align='center' colSpan='2'>
                                    <Typography sx={{lineHeight: '1.5rem'}}>
                                        Signature
                                    </Typography>
                                    <Typography sx={{lineHeight: '1.5rem'}}>
                                        <b>(Medical Officer)</b>
                                    </Typography>
                                    <Typography sx={{lineHeight: '1.5rem'}}>
                                        <b>Registration No : ......</b>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table> 
                </Card>
            </div>
        </Box>
    );

}