import React, {useState, useEffect} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
     Card, 
      Table, TableHead, TableRow, 
       TableCell,
       TableBody
} from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { ReportSelector } from 'selectors/ReportSelector';
import PageTitle from "components/PageTitle";
import { GeneralSelector } from 'selectors';
import { ReportActions } from 'slices/actions';
import { format } from 'date-fns';
import ListSkeleton from 'components/Skeleton/ListSkeleton';


export default function ExitLaborListResult() {
    const dispatch = useDispatch();
    const reportAData = useSelector(ReportSelector.getFormAData());
    const formData = useSelector(ReportSelector.getFormData());
    const loading = useSelector(GeneralSelector.loader(ReportActions.getReportData.type))
    const [formDate,setFormDate] = useState({
        date: "",
     });
    useEffect(() => {
        if (formData) {
            dispatch(ReportActions.getReportAData(formData)); 
        }
    },[formData])
   

    useEffect(() => {
        const tempDate = new Date();
        let month = tempDate.getMonth() + 1;
        if (month < 10) {
            month = `0${month}`   
        }
        formDate.date = "Exit Labour"
        setFormDate({...formDate})
    }, []);

    return (
        <>
            <PageTitle isBack={true} title="Exit Labour" />
            {loading? (
                <ListSkeleton/>
            ):(
            <Card>
                <div style={{maxWidth: '98%', width: '100%', overflowX: 'auto'}}>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button btn btn-primary mb-3"
                        table="table-to-xls"
                        filename={formDate.date}
                        sheet="tablexls"
                        buttonText="Export"
                    />
                    <div className=''>
                        <Table className="table Tableform FormTable" id="table-to-xls">
                            <TableHead className="thead-dark bg-light">
                                <TableRow className='tableform'>
                                    {/* <TableCell>Sl.No</TableCell>
                                    <TableCell>Labour Code</TableCell> */}
                                    <TableCell>Name</TableCell> 
                                    <TableCell>Mobile Number</TableCell>
                                    <TableCell>Aadhar Card</TableCell>
                                    <TableCell>UAN</TableCell> 
                                    <TableCell>Date Of Exit</TableCell> 
                                    <TableCell>Reason Of Exit</TableCell> 
                                    {/* <TableCell>Mobile Number</TableCell> 
                                    <TableCell>Aadhar Card</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reportAData && reportAData.data && reportAData.data.map((res,index) => <TableRow 
                                    key={index} className="tableform">
                                    {/* <TableCell align='center'>{index+1}</TableCell> */}
                                    {/* <TableCell align='left'>{res.labourCode}</TableCell> */}
                                    <TableCell width="40%" align='left'>
                                        {res.firstName} {res.lastName}
                                    </TableCell>
                                    <TableCell>{res.mobileNumber}</TableCell>
                                    <TableCell>{res.aadharNo}</TableCell>
                                    <TableCell>{res.uanNumber}</TableCell>
                                    <TableCell>{res.exitDate && format(new Date(res.exitDate), 'dd/MM/yyyy')}</TableCell>
                                    <TableCell>{res.exitReason}</TableCell>
                                </TableRow>)}
                            </TableBody>   
                        </Table>
                    </div>
                </div>
            </Card>)}
        </>
    )
}