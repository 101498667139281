import React from "react";
import {
  Box,
  Grid,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography, FormControl, FormGroup, FormControlLabel
} from "@mui/material";
import PageTitle from "components/PageTitle";
import {LocalPrintshopOutlined} from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from "react-redux";
import { LabourSelector } from "selectors";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { format } from 'date-fns';

function printIdCard () {
    html2canvas(document.querySelector("#capture")).then(canvas => {
        document.body.appendChild(canvas);
        const imgData = canvas.toDataURL('image/png');
        // const pdf = new jsPDF();
        const pdf = new jsPDF('p', 'mm', [1080, 999]);
        pdf.addImage(imgData, 'svg', 0, 0);
        pdf.save("health.pdf"); 
        pdf.close();
    });
}

function HealthPdf() {
    const labourDetails = useSelector(LabourSelector.getLabourById());
    const medicalInfoForm = useSelector(LabourSelector.getLabourFormList())

    return (
        <>
            <PageTitle isBack={true} title="Health Pdf"/>
            <Card id="capture" sx={{px: 5, py: 5, width: '100%'}}
                // style={{ 
                //     backgroundColor: '#fff',
                //     width: '100%',
                //     minHeight: '297mm',
                //     marginLeft: 'auto',
                //     marginRight: 'auto'
                // }}
            >
                <Grid container spacing={2}>
                    <Grid item lg={12} sm={12} xs={12} sx={{mb: 4}}>
                        <img src={`${process.env.REACT_APP_URL}/img/construction.svg`} className="PdfImg" alt="" />
                        <div className="float-right text-center">
                            <Typography variant="h5" sx={{ mb: 3, fontWeight: 600, color: '#000', textTransform: 'uppercase' }}>
                                Company Name
                            </Typography>
                            <Typography variant="h6" sx={{ mb: 3, fontWeight: 600, color: '#000' }}>
                                Pre- Employment Medical Examination Record
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item lg={6} sm={6} xs={6}>
                        <Grid container spacing={2}>
                            <Grid item lg={6} sm={6} xs={6}>
                                <Typography>Name of the Project :</Typography>
                            </Grid>
                            <Grid item lg={6} sm={6} xs={6}>
                                <Typography>DMRC Metro Construction</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={6} sm={6} xs={6}>
                        <Grid container spacing={2}>
                            <Grid item lg={5} sm={5} xs={5}>
                                <Typography>Sub-Contractor :</Typography>
                            </Grid>
                            <Grid item lg={7} sm={7} xs={7}>
                        <Typography>{labourDetails.subContractorName}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={6} sm={6} xs={6}>
                        <Grid container spacing={2}>
                            <Grid item lg={6} sm={6} xs={6}>
                                <Typography>Name of Employee :</Typography>
                            </Grid>
                            <Grid item lg={6} sm={6} xs={6}>
                                <Typography>{labourDetails.firstName} {"  "} {labourDetails.lastName}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={6} sm={6} xs={6}>
                        <Grid container spacing={2}>
                            <Grid item lg={5} sm={5} xs={5}>
                                <Typography>Designation :</Typography>
                            </Grid>
                            <Grid item lg={7} sm={7} xs={7}>
                                <Typography>Supervisor</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                            <Grid item lg={4} sm={4} xs={4}>
                                <Typography>
                                    Date of birth : {format(new Date(labourDetails.dateOfBirth), 'dd/MM/yyyy')}
                                </Typography>
                            </Grid>
                            <Grid item lg={3} sm={3} xs={3}>
                                <Typography>Height : {labourDetails.height}</Typography>
                            </Grid>
                            <Grid item lg={3} sm={3} xs={3}>
                                <Typography>Weight : {labourDetails.weight}</Typography>
                            </Grid>
                            <Grid item lg={2} sm={2} xs={2}>
                                <Typography>Sex : {labourDetails.gender}</Typography>
                            </Grid>
                            <Grid item lg={4} sm={4} xs={4}>
                                <Typography>Blood Group : {labourDetails.bloodGroup}</Typography>
                            </Grid>
                            <Grid item lg={5} sm={5} xs={5}>
                                <Typography>Identification Mark : {labourDetails.identificationMark}</Typography>
                            </Grid>
                    
                </Grid>

                <Typography variant="h5" sx={{ my: 3, fontWeight: 600, color: '#000', textAlign: 'center', textTransform: 'uppercase' }}>Common To All Workmen</Typography>
                <Box>
                    <Table className="HealthPdf">
                        <TableHead>
                            <TableRow>
                                <TableCell>SNo</TableCell>
                                    <TableCell>Particulars</TableCell>
                                    <TableCell>Test Conducted</TableCell>
                                    <TableCell>Satisfactory</TableCell>
                                <TableCell>Remarks</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {medicalInfoForm.medical && medicalInfoForm.medical.map((item,index) => (
                            <TableRow key={index}>
                                <TableCell>{index+1}</TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.DeficiencyObserved?<>Yes</>:<>No</>}</TableCell>
                                <TableCell>{item.satisfactory?<>Yes</>:<>No</>}</TableCell>
                                <TableCell>{item.remark}</TableCell>
                            </TableRow>
                            ))}
                
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Medically</TableCell>
                                <TableCell colSpan='2' align='center'>
                                    <FormControl variant="standard">
                                        <FormGroup>
                                            <FormControlLabel sx={{ mr: 4 }}
                                                control={
                                                <Checkbox
                                                    // checked={gilad} 
                                                    // onChange={handleChange} 
                                                    name="fit" 
                                                />
                                                }
                                                label="Fit"
                                            />
                                            <FormControlLabel
                                                control={
                                                <Checkbox 
                                                    // checked={gilad} 
                                                    // onChange={handleChange} 
                                                    name="unfit" 
                                                />
                                                }
                                                label="Unfit"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Signature with Seal of Medical Officer :</TableCell>
                                <TableCell colSpan='3'>........</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
                <Typography sx={{textAlign: 'right', mt: 2}}>
                    <LocalPrintshopOutlined onClick={printIdCard} sx={{mr: 1, color: '#2c7be5', cursor: 'pointer'}}/>
                </Typography>
                {/* <FloatingButton
                        onClick={() => history.push("/app/labour/create")}/> */}
            </Card>
        </>
    );
    }

export default HealthPdf;
