import React from 'react';
import {
    Box, Card, Table, TableBody, TableCell,
    TableHead, TableRow,
    Typography,Button
} from "@mui/material";
import { useSelector } from 'react-redux';
import { LabourSelector } from 'selectors';
import { useHistory } from 'react-router-dom';

function DocumentLabourList () {
    const labourDocumentListData = useSelector(LabourSelector.documentLabourData())
    const history = useHistory()

    const goToListing = () => {
        history.push("/app/labour")
    }
    return (
        <>
     <Card>
      <Typography variant="h5" sx={{ fontWeight: 600, position: 'relative' }}>Document Labour List</Typography>

      <Typography>NewLabour: {labourDocumentListData && labourDocumentListData.newLabour}</Typography>
      <Typography>Invalid LabourData: {labourDocumentListData && labourDocumentListData.invalidLabourData}</Typography>
      <Typography>Existing LabourData: {labourDocumentListData && labourDocumentListData.existingLabourData}</Typography>
      

          <Box>
              <Table>
                  <TableHead>
                      <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell>LabourCode</TableCell>
                              <TableCell>AadharNumber</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                        
                             {labourDocumentListData && labourDocumentListData.invaliddata && labourDocumentListData.invaliddata.map((item,index) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.firstName}{"   "}{item.lastName}</TableCell>
                                        <TableCell>{item.LabourCode}</TableCell>
                                        <TableCell>{item.AadharNumber}</TableCell>
                                    </TableRow>

                                 ))
                            }
                   </TableBody>
              </Table>

             </Box>
             <Button 
             variant="contained"
             sx={{ position: "fixed", bottom: 30, right: 20 }} 
             onClick={goToListing}>
             Ok
             </Button>

            </Card>
    </>
    )

}
export default DocumentLabourList;