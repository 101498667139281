import React ,{useEffect} from "react";
import {
  Box,
  Card,
  Grid,
  Typography,
} from "@mui/material";
import PanCard from "./UploadKyc/Pancard";
import Gst from "./UploadKyc/Gst";
import CancelledCheck from "./UploadKyc/CancelledCheque";
import { GeneralActions, SubContractorActions, UserActions } from "slices/actions";
import { useDispatch, useSelector } from "react-redux";
import { GeneralSelector } from "selectors";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";


export default function UploadKycDocumment() {
    const dispatch = useDispatch()
    const success = useSelector(GeneralSelector.success(SubContractorActions.uploadSubcontractorDocument.type));
    const error = useSelector(GeneralSelector.error(SubContractorActions.uploadSubcontractorDocument.type));
    const {id} = useParams();
    useEffect(() => {
        if (success) {
          toast.success(`${success?.message ?? "Success"}`, {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
          });
    
          dispatch(GeneralActions.removeSuccess(SubContractorActions.uploadSubcontractorDocument.type));
          dispatch(UserActions.getUserById(id));
        }
      }, [success]);

      useEffect(() => {
        if (error) {
          toast.error(`${error?.message ?? "Error"}`, {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
          });
    
          dispatch(GeneralActions.removeError(SubContractorActions.uploadSubcontractorDocument.type));
          
        }
      }, [error]);

return (
    
    <Card sx={{ mb: 3 }}>
        <Typography variant="h5" sx={{ mb: 3 }}>
            Documents
        </Typography>
        <Box>
           
                <Grid container spacing={3} sx={{ my: 3 }}>
                    <Grid item lg={6} sm={12} xs={12}>
                        <PanCard />
                    </Grid>
                    <Grid item lg={6} sm={12} xs={12}>
                        <Gst />
                    </Grid>
                    <Grid item lg={6} sm={12} xs={12}>
                    <CancelledCheck /> 
                    </Grid>
                </Grid>
          
        </Box>
    </Card>
  );
}
