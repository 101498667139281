import React, { useEffect } from "react";
import {
    Button,
    Card,
    CircularProgress,
    Grid,
    MenuItem,
    Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Input from "components/Input";
import PropTypes from "prop-types";
import * as yup from "yup";
import { CompanyActions, GeneralActions, ProjectActions, StateActions } from "slices/actions";
import { GeneralSelector, StateSelector } from "selectors";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { CompanySelector } from "selectors/CompanySelector";
import { Constants } from "utils/constants";
import SelectField from "components/SelectField";


BasicInformation.propTypes = {
    projectDetails: PropTypes.object,
};

export default function BasicInformation(props) {
    const { projectDetails } = props;
    const { id } = useParams();
    const dispatch = useDispatch();
    const success = useSelector(GeneralSelector.success(ProjectActions.updateProject.type));
    const companyList = useSelector(CompanySelector.getCompanyList());
    const userType = localStorage.getItem('userType');
    const loading = useSelector(GeneralSelector.loader(ProjectActions.updateProject.type));
    const allStateList = useSelector(StateSelector.GetAllState());

    useEffect(() => {
        dispatch(CompanyActions.getCompanyList())
        dispatch(StateActions.GetAllState())
    }, [])

    useEffect(() => {
        if (success) {
            console.log("success",success)
            toast.success(`${success?.message}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });

            dispatch(GeneralActions.removeSuccess(ProjectActions.updateProject.type));
            dispatch(ProjectActions.getProjectById(id));
        }
    }, [success]);


    const validationSchema = yup.object({
        projectName: yup.string().required('Name is required'),
        // city: yup.string().required('City is required'),
        // state: yup.string().required('State is required'),
        start_date: yup.string().required('Start Date is required'),
        gst: yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}Z[a-zA-Z0-9]{1}$/, "Invalid GST No").
            required('GST is required'),
        location: yup.string().required('Address is required'),
        pincode: yup.string().matches(/^[0-9]+$/, "Must be only digits").
            length(6, 'Pincode must be exactly 6 characters!').
            required('Pincode is required'),
        company_id: yup.string().required('Company Id is required'),

    });

    const formik = useFormik({
        initialValues: {
            projectName: projectDetails.data?.project?.name ?? "",
            company_id: projectDetails.data?.project?.company_id ?? "",
            city: projectDetails.data?.project?.city ?? "",
            state: projectDetails.data?.project?.state ?? "",
            start_date: projectDetails.data?.project?.start_date ?? "",
            gst: projectDetails.data?.project?.gst_number ?? "",
            location: projectDetails.data?.project?.location ?? "",
            pincode: projectDetails.data?.project?.pincode ?? "",
            duration: projectDetails.data?.project?.duration ?? "",
            payment_collection_mode: projectDetails.data?.project?.payment_collection_mode ?? "",
            flag: "basicInfo",
            status: 1,
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = (values) => {
        const params = {
            id: projectDetails.data?.project?._id,
            ...values,
        };

        dispatch(ProjectActions.updateProject(params));

    }

    const handleChangeState = (e) => {
        const state = e.target.value
        formik.setFieldValue('state',state)
    }

    return (
        <Card className="position-relative">
            <Typography variant='h5' sx={{ mb: 4 }}>Basic Information</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Project Name"
                            name='projectName'
                            value={formik.values.projectName}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.projectName) && Boolean(formik.errors.projectName)}
                            helperText={formik.touched.projectName && formik.errors.projectName} />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="GST"
                            name='gst'
                            value={formik.values.gst}
                            onChange={formik.handleChange}
                            error={formik.touched.gst && Boolean(formik.errors.gst)}
                            helperText={formik.touched.gst && formik.errors.gst} />
                    </Grid>
                    {userType === Constants.SUPERADMIN ? (
                        <Grid item lg={6} xs={12}>
                            <Typography variant='h6' sx={{ fontSize: 15 }}>Company</Typography>
                            <SelectField
                                value={formik.values.company_id}
                                name='company_id'
                                onChange={formik.handleChange}
                                error={formik.touched.company_id && Boolean(formik.errors.company_id)}
                                helperText={formik.touched.company_id && formik.errors.company_id}
                            >
                                {companyList && companyList.map((item, index) => (
                                    <MenuItem value={item._id} key={index}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </SelectField>
                        </Grid>) : null}
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Start Date"
                            name='start_date'
                            type='date'
                            value={formik.values.start_date.slice(0, 10)}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.start_date) && Boolean(formik.errors.start_date)}
                            helperText={formik.touched.start_date && formik.errors.start_date}
                        />
                    </Grid>

                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Pincode"
                            name='pincode'
                            value={formik.values.pincode}
                            onChange={formik.handleChange}
                            error={formik.touched.pincode && Boolean(formik.errors.pincode)}
                            helperText={formik.touched.pincode && formik.errors.pincode}
                        />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        {/* <Input
                            label="State"
                            name='state'
                            value={formik.values.state}
                            error={Boolean(formik.touched.state) && Boolean(formik.errors.state)}
                            helperText={formik.touched.state && formik.errors.state}
                            readonly
                            /> */}
                        <Typography variant='h6' sx={{ fontSize: 15 }}>State</Typography>
                        <SelectField
                            value={formik.values.state}
                            onChange={(e) => handleChangeState(e)}
                            error={formik.touched.state && Boolean(formik.errors.state)}
                            helperText={formik.touched.state && formik.errors.state}
                        >
                            {/* {allstate && allstate.map((item, index) => (
                            <MenuItem value={item.code} key={index}>
                                {item.name}
                            </MenuItem>
                        ))} */}

                            {allStateList && allStateList.map((item, index) => (
                                <MenuItem value={item.name} key={index}>
                                    {item.name}
                                </MenuItem>
                            ))}

                        </SelectField>
                    </Grid>

                    <Grid item lg={6} xs={12}>
                        <Input
                            label="City"
                            name='city'
                            value={formik.values.city}
                            error={Boolean(formik.touched.city) && Boolean(formik.errors.city)}
                            helperText={formik.touched.city && formik.errors.city}
                            readonly
                        />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Address"
                            name='location'
                            onChange={formik.handleChange}
                            value={formik.values.location}
                            error={Boolean(formik.touched.location) && Boolean(formik.errors.location)}
                            helperText={formik.touched.location && formik.errors.location} />
                    </Grid>
                    <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained">
                            {loading ? (
                                <CircularProgress color="inherit" size={20} />
                            ) : (
                                <>Update</>
                            )}
                        </Button>
                    </Grid>

                </Grid>
            </form>
        </Card>
    )
}