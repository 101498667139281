import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Grid,
    Typography,Table, TableCell, TableHead, TableBody, TableRow, Box
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { GeneralActions, ProjectActions,TransactionActions} from "slices/actions";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import ProjectExtensionDialog from "./ProjectExtensionDialog";
import moment from "moment";
import { GeneralSelector, TransactionSelector } from "selectors";
import { format } from 'date-fns';


ProjectExtension.propTypes = {
    projectDetails: PropTypes.object,
    listingrefresh: PropTypes.func
};

export default function ProjectExtension(props) {

 const { id } = useParams();
 const dispatch = useDispatch();
 const success = useSelector(GeneralSelector.success(TransactionActions.paymentStatus.type));
 const transactionList = useSelector(TransactionSelector.getTransactionById());


    const { projectDetails,listingrefresh } = props;
    // const loading = useSelector(GeneralSelector.loader(ProjectActions.updateProject.type));

    const [opendialog, setOpendialog] = useState(false)


    useEffect(() => {                                                                                   
        dispatch(TransactionActions.getTransactionById(id))
        },[])
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
    useEffect(() => {
        if (success) {
            console.log("success", success)
            toast.success(`${success?.message}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });

            dispatch(GeneralActions.removeSuccess(ProjectActions.updateProject.type));
            dispatch(ProjectActions.getProjectById(id));
        }
    }, [success]);

    const projectdialog = () => {
        setOpendialog(true)
    }

    const onClose = () => {
        setOpendialog(false)
    }
    return (
        <>
             <Card className="position-relative">
            <Typography variant='h5' sx={{ mb: 4 }}>Project Extension</Typography>
            <Grid sx={{mt: -5,mb: 5}} item container justifyContent="flex-end">
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={projectdialog}
                    >
                        Do you want to extend ?
                    </Button>
                </Grid>
            <Grid container spacing={2}>
                { <Grid item lg={6} xs={12}>
                    <Typography variant='h7' sx={{ mb: 4, mr: 3 }}>Start Date:</Typography>
                    {
                       projectDetails.data?.project?.start_date ? moment(projectDetails.data?.project?.start_date).utc().
                        format('DD/MM/YYYY'):'N/A'
                    }
                </Grid>
                }
                 <Grid item lg={6} xs={12}>
                    <Typography variant='h7' sx={{ mb: 4, mr: 3, ml: 15 }}>End Date:</Typography>
                    {
                    projectDetails.data?.project?.end_date ? moment(projectDetails.data?.project?.end_date).utc().
                        format('DD/MM/YYYY'):'N/A'
                    }
                 </Grid> 
                <ProjectExtensionDialog open={opendialog} onClose={onClose} projectDetails={projectDetails} listingrefresh={listingrefresh}/>
            </Grid>
        </Card>
        <Card sx={{ position: "relative" ,mt: 5}}>
      <Typography variant="h5" sx={{ mb: 4 }}>
       Transaction Listing
      </Typography>
     <Box>
     {transactionList.data && transactionList.data.results.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
            <TableCell>Billing Date</TableCell>
              {/* <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell> */}
              <TableCell>Amount</TableCell>
          </TableRow>
          </TableHead>
          <TableBody>
            {transactionList && transactionList.data && transactionList.data.results.map((item, index) => (
              <TableRow key={index}>
                <TableCell align="left">
                  {item.bill_date ? format(new Date(item.bill_date), 'dd/MM/yyyy') : "N/A"}
                </TableCell>
                {/* <TableCell align="left">
                  {format(new Date(item.duration_start_date), 'dd/MM/yyyy')}
                </TableCell>
                <TableCell align="left">
                  {format(new Date(item.duration_end_date), 'dd/MM/yyyy')}
                </TableCell> */}
                <TableCell align="left">{item.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        ):(
          <Typography>No Transaction List</Typography>
        )}
      </Box>
    </Card>
        </>  
    )
}
