import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import EducationForm from './EducationForm'
import { useParams } from 'react-router-dom';
import { LabourActions } from 'slices/actions';
import { useDispatch } from 'react-redux';
// import Education from './Education';

SelectEducationType.propTypes = {
  onClose: PropTypes.func,
  onOpen: PropTypes.bool,
  radiobutton: PropTypes.bool,
  labourDetails: PropTypes.object,
};

function SelectEducationType(props) {
  const dispatch = useDispatch()
  const { onClose, onOpen, labourDetails } = props
  const [educationSelectValue, setEducationSelectvalue] = useState("");
  const [openEducationForm, setOpenEducationForm] = useState(false);
  const { id } = useParams()
  useEffect(() => {
    setEducationSelectvalue("")
  }, [])
  const handleChange = (e) => {
    const value = e.target.value
      if (value) {
        setEducationSelectvalue(value)
      }
  }
  const handleIllitearal = () => {
    if (educationSelectValue === 'Literate') {
      setOpenEducationForm(true)
      onClose();  
    } else if (educationSelectValue === 'Illiterate') {
      setOpenEducationForm(false)
      onClose();
    } 
    const params = {
      labourID: id,
      // education: JSON.stringify([]),
      literacy: 0
    };
    dispatch(LabourActions.updateLabourEducation(params));
  }
  const closeEducationForm = () => {
    setEducationSelectvalue("")
    setOpenEducationForm(false)
  }
  return (
    <>
      <Dialog open={onOpen} onClose={onClose}>

        <DialogContent>
          <FormLabel id="demo-row-radio-buttons-group-label">Education Type</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={educationSelectValue}
            onChange={handleChange}
          >
            <FormControlLabel value="Illiterate" control={<Radio />} label="Illiterate" />
            <FormControlLabel value="Literate" control={<Radio />} label="Literate" />

          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleIllitearal}>Submit</Button>
        </DialogActions>
      </Dialog>
      <EducationForm onOpen={openEducationForm}
        onClose={closeEducationForm}
        labourDetails={labourDetails} />
    </>
  )
}

export default SelectEducationType