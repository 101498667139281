import { del, post,get,patch } from "../utils/api";

const API_URL = process.env.REACT_APP_API_URL 

const CreateHoliday = async (params) => post(`${API_URL}/projectHoliday`,params);

const DeleteHoliday = async (id) => del(`${API_URL}/projectHoliday/${id}`);

const GetHolidayById = async (params) => get(`${API_URL}/projectHoliday`,params);

const UpdateHoliday = async (id,params) => patch(`${API_URL}/projectHoliday/${id}`, params);

const HolidayDeatils = async (id) => get(`${API_URL}/projectHoliday/${id}`)

export const HolidayService = {
    CreateHoliday,
    DeleteHoliday,
    GetHolidayById,
    UpdateHoliday,
    HolidayDeatils    
};