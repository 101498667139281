import React, {useEffect, useState} from "react";
import {
    Box,
    Card,
    FormControl,
    Grid,
    Typography,
  } from "@mui/material";
import {useDispatch,useSelector} from "react-redux";
import Input from "components/Input";
import PropTypes from "prop-types";
import {MasterDataActions, StateActions } from "slices/actions";
import { MasterDataSelector } from "selectors";


BasicInformation.propTypes = {
    formik: PropTypes.object
};

export default function BasicInformation(props) {
    const { formik } = props;
    const dispatch = useDispatch();
    const locationData = useSelector(MasterDataSelector.getLocationListByPincode());
    const [pincode,setPincode]=useState();

    useEffect(() => {
        dispatch(StateActions.getStateList1());
      
    }, []);
  
    const handleChangePinCode= (e) => {
        const pincodeData = e.target.value
        if (pincodeData.length === 0) {
            formik.setFieldValue('state','')
            formik.setFieldValue('city','')
        }
        
        formik.setFieldValue('pincode',pincodeData)
        setPincode(pincodeData);
    }
    useEffect(() => {
        if (pincode && pincode.length === 6) {
            dispatch(MasterDataActions.fetchDataByPincode(pincode));
        }
    }, [pincode]);
    useEffect(() => {
       if (pincode && locationData) { 
        const { PlaceName,StateName,District } = locationData
        formik.setFieldValue('address',PlaceName);
        formik.setFieldValue('state',StateName);
        formik.setFieldValue('city',District);
        }
    }, [locationData]);

    function handleMobileChange(e) {
        const amount = e.target.value.replace(/[^0-9]/g, "");        
        formik.handleChange(e);
        formik.setFieldValue('companyContactNumber',amount);
      }
    
    // window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth',
    // });
    return (
        <Card sx={{ mb: 3 }}>
            <Typography variant='h5' sx={{ mb: 4 }}>Basic Information</Typography>
            <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Company Name"
                        name='companyName'
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.companyName) && Boolean(formik.errors.companyName)}
                        helperText={formik.touched.companyName && formik.errors.companyName}/>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Company Email Id"
                        name='companyEmail'
                        value={formik.values.companyEmail}
                        onChange={formik.handleChange}
                        error={formik.touched.companyEmail && Boolean(formik.errors.companyEmail)}
                        helperText={formik.touched.companyEmail && formik.errors.companyEmail}/>
                </Grid>
               
                <Grid item lg={6} xs={12}>
                    <FormControl fullWidth>
                        <Typography variant='' sx={{fontSize: "15px"}}>Company Contact Number </Typography>
                        <Box sx={{
                            display: 'flex',
                            gap: 1.5
                        }}>
                            <Box sx={{ width: 90 }}>
                                <Input style={{appearence: 'none'}}
                                    sx={{
                                        padding: "10px 8px",
                                        textAlign: 'center',
                                        // '& .Mui-disabled': {
                                        //     fillColor: theme.palette.common.black
                                        // }
                                    }}
                                    autoComplete='new-password'
                                    name='phoneCode'
                                    startAdornment='+'
                                    value="91"
                                    />
                            </Box>
                            <Input
                                name='companyContactNumber'
                                type="number"    
                                value={formik.values.companyContactNumber}
                                onChange={handleMobileChange}
                                error={formik.touched.companyContactNumber && Boolean(formik.errors.companyContactNumber)}
                                helperText={formik.touched.companyContactNumber && formik.errors.companyContactNumber}/>
                        </Box>
                    </FormControl>
                </Grid>
                
                <Grid item lg={6} xs={12}>
                    <Input
                        label="GST"
                        name='gst'
                        value={formik.values.gst}
                        onChange={formik.handleChange}
                        error={formik.touched.gst && Boolean(formik.errors.gst)}
                        helperText={formik.touched.gst && formik.errors.gst}/>
                </Grid>
                <Grid item lg={6} xs={12}>
                    
                    <Input
                        label='Pincode'
                        name='pincode'
                        type='number'
                        value={formik.values.pincode}
                        onChange={ (e) => handleChangePinCode(e)}
                        error={formik.touched.pincode && Boolean(formik.errors.pincode)}
                        helperText={formik.touched.pincode && formik.errors.pincode}
                    />
                </Grid>
                    
                <Grid item lg={6} xs={12}>
                     <Input
                        label="State"
                        name='state'
                        readonly
                        value={formik.values.state}
                        error={Boolean(formik.touched.state) && Boolean(formik.errors.state)}
                        helperText={formik.touched.state && formik.errors.state}
                        />
                </Grid>
                <Grid item lg={6} xs={12}>
                   <Input
                        label="City"
                        name='city'
                        readonly
                        value={formik.values.city}
                        error={formik.touched.city && Boolean(formik.errors.city)}
                        helperText={formik.touched.city && formik.errors.city}
                        />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Address"
                        name='address'
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        error={formik.touched.address && Boolean(formik.errors.address)}
                        helperText={formik.touched.address && formik.errors.address}
                        />
                </Grid>
                
            </Grid>
        </Card>
    )
}