import {all, call, put, takeLatest} from 'redux-saga/effects'
import {UserService} from "../services";
import {AuthActions, GeneralActions, UserActions} from "../slices/actions";

function *getUsers({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(UserService.GetUsers, payload);

        yield put(UserActions.getUsersSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}

function *getDashboardData({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        
        const result = yield call(UserService.dashboardUrl, payload);

        yield put(UserActions.getDashboardDataSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}


function *getUserById({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(UserService.GetUserById, payload);

        yield put(UserActions.getUserByIdSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}

function *createUser({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(UserService.CreateUser, payload);

        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}

function *updateUser({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        if (payload.country) {
            payload.country = payload.country.name;
        }    
          
        const result = yield call(UserService.UpdateUser, payload.id, payload);
       
        console.log(payload)

        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}

function *deleteUser({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(UserService.DeleteUser, payload);

        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *profileUser({type}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(UserService.Profile);

        if (result) {
            yield put(UserActions.profileUserSuccess(result.data));
        }

        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(AuthActions.logout());
    }
}

function *getUserListById({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(UserService.getUserListById ,payload);
        yield put(UserActions.getUserListByIdSucess(result.data));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *uploadGstCompliance({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(UserService.UploadGstCompliance ,payload);
        yield put(GeneralActions.stopLoading(type))
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *getGstCompilanceListing({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(UserService.FetchGstComplianceListing, payload);

        yield put(UserActions.getGstComplianceListingSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}

function *uploadPfCompliance({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(UserService.UploadPfCompliance ,payload);
        yield put(GeneralActions.stopLoading(type))
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *getPfCompilanceListing({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(UserService.FetchPfComplianceListing, payload);

        yield put(UserActions.getPfComplianceListingSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}
function *resetUserPassword({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(UserService.ResetUserPassword, payload);

        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}


export function *UserWatcher() {
    yield all([
        yield takeLatest(UserActions.getUsers.type, getUsers),
        yield takeLatest(UserActions.getUserById.type, getUserById),
        yield takeLatest(UserActions.createUser.type, createUser),
        yield takeLatest(UserActions.updateUser.type, updateUser),
        yield takeLatest(UserActions.deleteUser.type, deleteUser),
        yield takeLatest(UserActions.profileUser.type, profileUser),
        yield takeLatest(UserActions.getUserListById.type, getUserListById),
        yield takeLatest(UserActions.getDashboardData.type, getDashboardData),
        yield takeLatest(UserActions.uploadGstCompliance.type, uploadGstCompliance),
        yield takeLatest(UserActions.getGstComplianceListing.type, getGstCompilanceListing),
        yield takeLatest(UserActions.uploadPfCompliance.type, uploadPfCompliance),
        yield takeLatest(UserActions.getPfComplianceListing.type, getPfCompilanceListing),
        yield takeLatest(UserActions.resetUserPassword.type, resetUserPassword)
    ]);
}