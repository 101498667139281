import {get, post, patch, del} from "../utils/api";
const API_URL = process.env.REACT_APP_API_URL

const GetCityList = async (params) => get(`${API_URL}/city`,params);
const CreateCity = async (params) => post(`${API_URL}/city`, params);
const GetCityById = async(id) => get(`${API_URL}/city/${id}`);
const UpdateCity = async (id,params) => patch(`${API_URL}/city/${id}`, params);
const DeleteCity = async (id) => del(`${API_URL}/city/${id}`);
export const CityService = {
     GetCityList,
     DeleteCity,
     CreateCity,
     GetCityById,
     UpdateCity
};