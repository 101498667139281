import React,{useState,useEffect} from "react";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
    Box, Card,Table, TableHead, TableRow,TableCell, TableBody
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import { GeneralActions, ProjectActions } from "slices/actions";
import { GeneralSelector, ProjectSelector } from "selectors";
import ListSkeleton from "components/Skeleton/ListSkeleton";
import FormIndex from "../FormIndex";
import { format } from 'date-fns';


const Content = styled(Card)(() => ({
    marginBottom: 20
}));

export default function ProjectList() {
    const dispatch = useDispatch();
    const projectList = useSelector(ProjectSelector.getProjectList());
    const loading = useSelector(GeneralSelector.loader(ProjectActions.getProjectList.type));
    const [showList,setShowList] = useState(false);
    const success = useSelector(GeneralSelector.success(ProjectActions.getProjectList.type));

    useEffect(() => {
        if (success) {
           dispatch(GeneralActions.removeSuccess(ProjectActions.getProjectList.type));
           setShowList(true);
        }
      }, [success]);
    

    return (
        <Box>
            <FormIndex repotType="Project" title="Project Report"/>
            {loading ? (
                <ListSkeleton />
            ) : (
                <Content>
                    {showList && projectList && projectList.length !== 0 ?(
                        <>
                        <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button btn btn-primary mb-3"
                        table="table-to-xls"
                        filename={"Project Report"}
                        sheet="tablexls"
                        buttonText="Export"
                        /> </>):null}
                    <Table className="table" id="table-to-xls">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Company Name</TableCell>
                                <TableCell>Location/State</TableCell>
                                <TableCell>Contact Person Name</TableCell>
                                <TableCell>Phone Number</TableCell>
                                <TableCell>Start Date</TableCell>
                                <TableCell>Client</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(!showList || (projectList && projectList.length === 0)) && (
                                <TableRow>
                                    <TableCell align="center" colSpan={5}>
                                        No Data
                                    </TableCell>
                                </TableRow>
                            )}
                            {showList && projectList && projectList.map((item, i) => (
                                <TableRow
                                    key={i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.company_name}</TableCell>
                                    <TableCell>{item.location}</TableCell>
                                    <TableCell>{item.clientContactPerson}</TableCell>
                                    <TableCell>{item.clientContactNumber}</TableCell>
                                    <TableCell>{item.start_date && format(new Date(item.start_date), 'dd/MM/yyyy')}</TableCell>
                                    <TableCell>{item.clientName}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                   
                </Content>
           )} 
        </Box>
    )
}