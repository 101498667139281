import React from "react";
import {
    Grid, Typography
} from "@mui/material";
import PropTypes from "prop-types";

IdCardBack.propTypes = {
    labourDetails: PropTypes.object,
};
export default function IdCardBack(props) {
    const {labourDetails} = props;
    
    return (
        <>
            <div className="IdCard">
                <Grid container spacing={2} className="fortext">
                    <Grid item lg={5} xs={5}>
                        <Typography sx={{fontWeight: '500'}}>Employee Address :</Typography>
                    </Grid>
                    <Grid item lg={7} xs={7}>
                        <Typography>
                            {labourDetails && labourDetails?.result?.currentAddress},{labourDetails && labourDetails?.result?.currentCity},{labourDetails && labourDetails?.result?.currentState},{labourDetails && labourDetails?.result?.currentPincode}
                        </Typography>
                    </Grid>
                    <Grid item lg={5} xs={5} style={{paddingTop: '0'}}>
                        <Typography sx={{fontWeight: '500'}}>Aadhar No :</Typography>
                    </Grid>
                    <Grid item lg={7} xs={7} style={{paddingTop: '0'}}>
                        <Typography>{labourDetails && labourDetails?.result?.aadharNo}</Typography>
                    </Grid>
                    <Grid item lg={5} xs={5} style={{paddingTop: '0'}}>
                        <Typography sx={{fontWeight: '500'}}>Employee ID No :</Typography>
                    </Grid>
                    <Grid item lg={7} xs={7} style={{paddingTop: '0'}}>
                    <Typography>{labourDetails?.result?.projectLabourCode?labourDetails.result.projectLabourCode:"N/A"}</Typography>
                    </Grid>
                    <Grid item lg={5} xs={5} style={{paddingTop: '0'}}>
                        <Typography sx={{fontWeight: '500'}}>Emergency Contact No :</Typography>
                    </Grid>
                    <Grid item lg={7} xs={7} style={{paddingTop: '0'}}>
                        <Typography>{labourDetails && labourDetails?.result.mobileNumber}</Typography>
                    </Grid>
                    <Grid item lg={12} xs={12} style={{paddingTop: '0'}}>
                        <Typography sx={{fontSize: '8px'}}>
                            1. This Card is the Property of {labourDetails && labourDetails.base64URL.data.companyName} and must be returned on demand and on transfer/cancellation of employment.
                        </Typography>
                        <Typography sx={{fontSize: '8px'}}>
                            2. A Charge will be Levied for replacement of the card due to loss or theft.
                        </Typography>
                        <Typography sx={{fontSize: '8px'}}>
                            3. If found please return it to:
                        </Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <div style={{border: '2px solid #ccc', padding: '5px 10px'}}>
                            <Typography varient='h5' sx={{fontWeight: '500'}}>
                               PROJECT NAME:{labourDetails && labourDetails.base64URL.data.projectName}
                            </Typography>
                            <Typography varient='h6' sx={{fontSize: '10px'}}>
                                Address : {labourDetails && labourDetails.base64URL.data.companyAddress}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}