import React ,{useEffect, useState} from "react";
import {
    Card,
    Grid,
    Typography,
    Button,
    FormControlLabel,
} from "@mui/material";
import Input from "components/Input";
import { useFormik } from "formik";
import { GeneralActions, ProjectActions} from "slices/actions";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { GeneralSelector } from "selectors";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import {toast} from "react-toastify";
import * as yup from "yup";
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';

ProjectSetting.propTypes = {
    projectDetails: PropTypes.object,
};

export default function ProjectSetting(props) {
    const { projectDetails } = props;
    const {id} = useParams();
    const dispatch = useDispatch();
    const success = useSelector(GeneralSelector.success(ProjectActions.updateProjectSetting.type));
    const error = useSelector(GeneralSelector.error(ProjectActions.updateProjectSetting.type));
    const loading = useSelector(GeneralSelector.loader(ProjectActions.updateProjectSetting.type));
    const [showField,setShowField] = useState(false)

    useEffect(() => {
        if (projectDetails?.data?.project?.esic_is_enable) {
            setShowField(projectDetails?.data?.project?.esic_is_enable)
        }

    },[projectDetails])
    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(ProjectActions.updateProjectSetting.type));
            dispatch(ProjectActions.getProjectById(id));
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeError(ProjectActions.updateProjectSetting.type));
        }
    }, [error]);


    const validationSchema = yup.object({
        projectLabourCode: yup.string().required('This field is required'),
        // pEpf: yup.string().required('This field is required'),
        // pEps: yup.string().required('This field is required'),
        // pEdli: yup.string().required('This field is required'),
        // pEsicEnable: yup.string().required('This field is required'),
        esic_percentage: yup.string().when('esic_is_enable', {is: (esic_is_enable) => esic_is_enable=== true, 
            then: yup.string().required('This Field is required'),
            otherwise: yup.string(),}),
        esic_maximum_cutoff: yup.string().when('esic_is_enable', {is: (esic_is_enable) => esic_is_enable=== true, 
                then: yup.string().required(' This Field is required'),
                otherwise: yup.string(),})
    });
    const formik = useFormik({
        initialValues: {
            projectLabourCode: projectDetails.data?.project?.projectLabourCode?? "",
            pEpf: projectDetails.data?.project?.pEpf ?? "",
            pEps: projectDetails.data?.project?.pEps ?? "",
            pEdli: projectDetails.data?.project?.pEdli ?? "",
            esic_is_enable: showField,
            esic_percentage: projectDetails.data?.project?.esic_percentage ?? "",
            esic_maximum_cutoff: projectDetails.data?.project.esic_maximum_cutoff ?? "",
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
          handleSubmit(values);
        },
    });
    const handleSubmit = (values) => {
        const params ={
            id: id,
            ...values
        }
        dispatch(ProjectActions.updateProjectSetting(params))
    };
    
    const handleChangeESIC = (e) => {
        const value = e.target.checked
        setShowField(value)
        // console.log("value",value)
        // formik.setFieldValue("esic_is_enable",value)
        // console.log(formik.values.esic_is_enable)
    }
    const handleChangeEsicValue = (e) => {
        formik.setFieldValue("esic_percentage",e.target.value)
        const esicMaxCutOff = 21000*(e.target.value/100)
        formik.setFieldValue("esic_maximum_cutoff",esicMaxCutOff)

    }

    return (
        <Card className="Paymentduration position-relative" sx={{ mb: 3}}>
            <Typography variant='h5' sx={{ mb: 4 }}>Project Information</Typography>
            <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                <Grid item lg={12} xs={12}>
                    <Input
                        fullWidth
                        label="Project Labour Code *"
                        type="text"
                        name="projectLabourCode"
                        placeholder="Ex:- ADF-0001"
                        value={formik.values.projectLabourCode}
                        onChange={formik.handleChange}
                        error={formik.touched.projectLabourCode && Boolean(formik.errors.projectLabourCode)}
                        helperText={formik.touched.projectLabourCode && formik.errors.projectLabourCode}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        fullWidth
                        label="EPF *"
                        type="text"
                        name="pEpf"
                        value={formik.values.pEpf}
                        onChange={formik.handleChange}
                        error={formik.touched.pEpf && Boolean(formik.errors.pEpf)}
                        helperText={formik.touched.pEpf && formik.errors.pEpf}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        fullWidth
                        label="EPS *"
                        type="text"
                        name="pEps"
                        value={formik.values.pEps}
                        onChange={formik.handleChange}
                        error={formik.touched.pEps && Boolean(formik.errors.pEps)}
                        helperText={formik.touched.pEps && formik.errors.pEps}
                    />
                </Grid>   
                <Grid item lg={6} xs={12}>
                <Input
                    label='EDLI *'
                    name='pEdli'
                    type='text'                        
                    value={formik.values.pEdli}
                    onChange={formik.handleChange}
                    error={formik.touched.pEdli && Boolean(formik.errors.pEdli)}
                    helperText={formik.touched.pEdli && formik.errors.pEdli}
                    
                    />
                </Grid>  

                <Grid item lg={6} xs={12}>
                    <FormControl>
                        <FormGroup sx={{mt: 3}}>
                        <FormControlLabel
                            control={
                                <Checkbox 
                                checked={showField}
                                onChange={(e) => handleChangeESIC(e)}
                                />
                                }
                                label="Is ESIC Enable?"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
            {showField?(<>
                           <Grid item lg={6} xs={12}>
                           <Input
                               label='ESIC Percentage *'
                               name='esic_percentage'
                               type='number'                        
                               value={formik.values.esic_percentage} 
                               onChange={handleChangeEsicValue}
                               error={formik.touched.esic_percentage && Boolean(formik.errors.esic_percentage)}
                               helperText={formik.touched.esic_percentage && formik.errors.esic_percentage}
                               
                               />
                           
                           </Grid>
                           <Grid item lg={6} xs={12}>
                           <Input
                               label='ESIC Maximum Cutoff *'
                               name='esic_maximum_cutoff'
                               type='text'                        
                               value={formik.values.esic_maximum_cutoff}
                            //    onChange={formik.handleChange}
                               error={formik.touched.esic_maximum_cutoff && Boolean(formik.errors.esic_maximum_cutoff)}
                               helperText={formik.touched.esic_maximum_cutoff && formik.errors.esic_maximum_cutoff}
                               
                               />
                           </Grid>
                </>
):null}
     
                <Grid container justifyContent="flex-end" sx={{mt: 2}}>
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        type="submit"
                    >
                        {loading ? (
                        <CircularProgress color="inherit" size={20} />
                        ) : (
                        <>Submit</>
                        )}
                    </Button>
                </Grid>
            </Grid>
            </form>
        </Card>
    )
}