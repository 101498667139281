import {all, call, put, takeLatest} from 'redux-saga/effects'
import { LabourService } from 'services';
import { LabourActions,GeneralActions } from 'slices/actions';

function *createLabour({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(LabourService.CreateLabour, payload);
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}
function *getLabourList({type, payload}) {
    try {
            yield put(GeneralActions.removeError(type));
            yield put(GeneralActions.startLoading(type));
            const result = yield call(LabourService.LabourList, payload);
            yield put(LabourActions.getLabourSuccess(result.data));
            yield put(GeneralActions.stopLoading(type))
            yield put(GeneralActions.addSuccess({
                action: type,
                message: result.data.message
            }));
        } catch (err) {
            yield put(GeneralActions.stopLoading(type));
            yield put(GeneralActions.addError({
                action: type,
                message: err.response?.data?.message
            }));
        }
    }

    function *getLabourFormList({type, payload}) {
        try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));
                const result = yield call(LabourService.LabourHealthFormList, payload);
                yield put(LabourActions.getLabourFormListSuccess(result.data));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.messge
                }));
            }
        }

        function *createLabourHealth({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));
                const params = {
                    approvedBy: payload?.approvedBy??"",
                    bloodGroup: payload?.bloodGroup??"",
                    createdBy: payload?.createdBy??"",
                    expireDate: payload?.expireDate??"",
                    height: payload?.height??0,
                    labourId: payload?.labourId??"",
                    projectId: payload?.projectId??"",
                    reportdata: payload?.reportdata??"",
                    weight: payload?.weight??"",
                    pr: payload?.pr??"",
                    bp: payload?.bp??"",
                    skin: payload?.skin??"",
                    cvs: payload?.cvs??"",
                    chest: payload?.chest??"",
                    abdomen: payload?.abdomen??"",
                    musculoskeletal: payload?.musculoskeletal??"",
                }
                const result = yield call(LabourService.CreateLabourHealth, params);
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data.message
                }));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.messge
                }));
            }
        }
        function *getLabourById({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));
                const result = yield call(LabourService.GetLabourById, payload);
                yield put(LabourActions.getLabourByIdSuccess(result));
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data.message
                }));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.message
                }));
            }
        }
    function *updateLabourBasicInfo({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));
                const result = yield call(LabourService.UpdateLabourBasicInfo, payload.id, payload);
        
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data
                }));
                yield put(LabourActions.updateLabourBasicInfoSuccess(result))
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.message
                }));
            }
        }
        function *updateLabourEmergencyContact({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));
                
                 payload.status = payload.status ? 2 : 1;
                
                const result = yield call(LabourService.UpdateLabourEmergencyContact, payload.id, payload);
        
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data.message
                }));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.message
                }));
            }
        }

        function *updateLabourEducation({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));
                
               const result = yield call(LabourService.UpdateLabourEducation,payload.id,payload);
        
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data.message
                }));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.message
                }));
            }
        }

        function *updateLabourBankDetails({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));
                
                 payload.status = payload.status ? 2 : 1;
                
                const result = yield call(LabourService.UpdateLabourBankDetails, payload.id, payload);
        
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data.message
                }));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.message
                }));
            }
        }

        function *updateLabourPhoto({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));
                
                 payload.status = payload.status ? 2 : 1;
                 
                 console.log("payload123",payload)
                
                const result = yield call(LabourService.UpdateLabourPhoto, payload.id, payload);
        
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data.message
                }));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.message
                }));
            }
        }

        function *updateLabourAdharCard({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));
                
                 payload.status = payload.status ? 2 : 1;
                
                const result = yield call(LabourService.UpdateLabourAdharCard, payload.id, payload);
        
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data.message
                }));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.message
                }));
            }
        }

        function *updateLabourRationCard({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));
                
                 payload.status = payload.status ? 2 : 1;
                
                const result = yield call(LabourService.UpdateLabourRationCard, payload.id, payload);
        
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data.message
                }));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.message
                }));
            }
        }

        function *updateLabourPanCard({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));
                
                 payload.status = payload.status ? 2 : 1;
                
                const result = yield call(LabourService.UpdateLabourPanCard, payload.id, payload);
        
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data.message
                }));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.message
                }));
            }
        }

        function *updateLabourPassport({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));
                
                 payload.status = payload.status ? 2 : 1;
                
                const result = yield call(LabourService.UpdateLabourPassport, payload.id, payload);
        
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data.message
                }));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.message
                }));
            }
        }

        function *updateLabourHealthDocument({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));
                
                const result = yield call(LabourService.UpdateHealthDocument, payload.id, payload);
        
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data.message
                }));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.message
                }));
            }
        }
        function *labourBulkUpload({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));
        
                const result = yield call(LabourService.LabourBulkUpload,payload);
        
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data.message
                }));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.error
                }));
            }
        }

        function *deleteLabour({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));
                
                const result = yield call(LabourService.DeleteLabour, payload);
        
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data.message
                }));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.message
                }));
            }
        }

        function *getLabourByAdharCard({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));
                const result = yield call(LabourService.GetLabourByAdharCard, payload);
                yield put(LabourActions.getLabourByAdharCardSuccess(result));
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data.message
                }));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.message
                }));
            }
        }


        function *uploadLabourDocument({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));

                const result = yield call(LabourService.UploadLabourDocument, payload);
                yield put(LabourActions.uploadLabourDocumentSucess(result))
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data.message
                }));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.message
                }));
            }
        }
        function *uploadLabourCompilance({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));

                const result = yield call(LabourService.UploadLabourCompilance, payload);
        
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data.message
                }));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.message
                }));
            }
        }

        function *getLabourCompilanceList({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));

                const result = yield call(LabourService.FetchLabourCompilanceList, payload);
                yield put(LabourActions.getLabourCompilanceListSucess(result));
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data.message
                }));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.message
                }));
            }
        }

        function *updateLabourPFBasicInfo({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));
                const result = yield call(LabourService.UpdateLabourPFBasicInfo, payload.id, payload);
        
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data.message
                }));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.message
                }));
            }
        }
        function *getSafetyTrainingList({type, payload}) {
            try {
                    yield put(GeneralActions.removeError(type));
                    yield put(GeneralActions.startLoading(type));
                    const result = yield call(LabourService.SafetyTrainingList, payload);
                    yield put(LabourActions.getSafetyTrainingListSuccess(result));
                    yield put(GeneralActions.stopLoading(type))
                } catch (err) {
                    yield put(GeneralActions.stopLoading(type));
                    yield put(GeneralActions.addError({
                        action: type,
                        message: err.response?.data?.error
                    }));
                }
            }
        

        function *updateLabourDailyWages({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));
                
                
                const result = yield call(LabourService.UpdateLabourDailyWages, payload.id, payload);
        
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data.message
                }));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.message
                }));
            }
        }

        function *updateLabourMonthlyWages({type, payload}) {
            try {
                yield put(GeneralActions.removeError(type));
                yield put(GeneralActions.startLoading(type));
                
                
                const result = yield call(LabourService.UpdateLabourMonthlyWages, payload.id, payload);
        
                yield put(GeneralActions.addSuccess({
                    action: type,
                    message: result.data.message
                }));
                yield put(GeneralActions.stopLoading(type))
            } catch (err) {
                yield put(GeneralActions.stopLoading(type));
                yield put(GeneralActions.addError({
                    action: type,
                    message: err.response?.data?.message
                }));
            }
        }

        function *fetchLabourTrainingType({type, payload}) {
            try {
                    yield put(GeneralActions.removeError(type));
                    yield put(GeneralActions.startLoading(type));
                    const result = yield call(LabourService.FetchLabourTrainingType, payload);
                    yield put(LabourActions.fetchLabourTrainingTypeSucess(result));
                    yield put(GeneralActions.stopLoading(type))
                } catch (err) {
                    yield put(GeneralActions.stopLoading(type));
                    yield put(GeneralActions.addError({
                        action: type,
                        message: err.response?.data?.messge
                    }));
                }
            }

            function *createLabourSafetyTraining({type, payload}) {
                try {
                    yield put(GeneralActions.removeError(type));
                    yield put(GeneralActions.startLoading(type));
                    
                    const result = yield call(LabourService.CreateLabourSafetyTraining, payload);
            
                    yield put(GeneralActions.addSuccess({
                        action: type,
                        message: result.data.message
                    }));
                    yield put(GeneralActions.stopLoading(type))
                } catch (err) {
                    yield put(GeneralActions.stopLoading(type));
                    yield put(GeneralActions.addError({
                        action: type,
                        message: err.response?.data?.message
                    }));
                }
            }

            function *exitLabour({type, payload}) {
                try {
                    yield put(GeneralActions.removeError(type));
                    yield put(GeneralActions.startLoading(type));
                    
                    const result = yield call(LabourService.ExitLabour, payload.id, payload);
            
                    yield put(GeneralActions.addSuccess({
                        action: type,
                        message: result.data.message
                    }));
                    yield put(GeneralActions.stopLoading(type))
                } catch (err) {
                    yield put(GeneralActions.stopLoading(type));
                    yield put(GeneralActions.addError({
                        action: type,
                        message: err.response?.data?.message
                    }));
                }
            }
            function *getAllSafetyTrainingList({type, payload}) {
                try {
                        yield put(GeneralActions.removeError(type));
                        yield put(GeneralActions.startLoading(type));
                        const result = yield call(LabourService.AllSafetyTrainingList, payload);
                        yield put(LabourActions.getAllSafetyTrainingListSuccess(result.data));
                        yield put(GeneralActions.stopLoading(type))
                        yield put(GeneralActions.addSuccess({
                            action: type,
                            message: result.data.message
                        }));
                    } catch (err) {
                        yield put(GeneralActions.stopLoading(type));
                        yield put(GeneralActions.addError({
                            action: type,
                            message: err.response?.data?.messge
                        }));
                    }
                }
                function *getLabourEducationListById({type, payload}) {
                    try {
                        yield put(GeneralActions.removeError(type));
                        yield put(GeneralActions.startLoading(type));
                        const result = yield call(LabourService.GetEducationListById, payload);
                        yield put(LabourActions.getEducationListByIdSuccess(result));
                        yield put(GeneralActions.addSuccess({
                            action: type,
                            message: result.data.message
                        }));
                        yield put(GeneralActions.stopLoading(type))
                    } catch (err) {
                        yield put(GeneralActions.stopLoading(type));
                        yield put(GeneralActions.addError({
                            action: type,
                            message: err.response?.data?.message
                        }));
                    }
                }  
                function *getLabourEducationList({type, payload}) {
                    try {
                        yield put(GeneralActions.removeError(type));
                        yield put(GeneralActions.startLoading(type));
                        const result = yield call(LabourService.GetEducationList, payload);
                        yield put(LabourActions.getEducationListSuccess(result));
                        yield put(GeneralActions.addSuccess({
                            action: type,
                            message: result.data.message
                        }));
                        yield put(GeneralActions.stopLoading(type))
                    } catch (err) {
                        yield put(GeneralActions.stopLoading(type));
                        yield put(GeneralActions.addError({
                            action: type,
                            message: err.response?.data?.message
                        }));
                    }
                }  
                function *deleteEducationById({type, payload}) {
                    try {
                        yield put(GeneralActions.removeError(type));
                        yield put(GeneralActions.startLoading(type));
                        
                        const result = yield call(LabourService.DeleteEducationById, payload.id,payload);
                
                        yield put(GeneralActions.addSuccess({
                            action: type,
                            message: result.data.message
                        }));
                        yield put(GeneralActions.stopLoading(type))
                    } catch (err) {
                        yield put(GeneralActions.stopLoading(type));
                        yield put(GeneralActions.addError({
                            action: type,
                            message: err.response?.data?.message
                        }));
                    }
                } 
                
                function *getSkillCategory({type, payload}) {
                    try {
                        yield put(GeneralActions.removeError(type));
                        yield put(GeneralActions.startLoading(type));
                        const result = yield call(LabourService.GetSkillCategory, payload);
                        yield put(LabourActions.getSkillCategorySuccess(result));
                        yield put(GeneralActions.addSuccess({
                            action: type,
                            message: result.data.message
                        }));
                        yield put(GeneralActions.stopLoading(type))
                    } catch (err) {
                        yield put(GeneralActions.stopLoading(type));
                        yield put(GeneralActions.addError({
                            action: type,
                            message: err.response?.data?.message
                        }));
                    }
                }  
                function *getSafetyTraingDetails({type, payload}) {
                    try {
                        yield put(GeneralActions.removeError(type));
                        yield put(GeneralActions.startLoading(type));
                        const result = yield call(LabourService.GetSafetyTrainingDetails, payload);
                        yield put(LabourActions.getSafetytraingDetailsSuccess(result));
                        yield put(GeneralActions.addSuccess({
                            action: type,
                            message: result.data.message
                        }));
                        yield put(GeneralActions.stopLoading(type))
                    } catch (err) {
                        yield put(GeneralActions.stopLoading(type));
                        yield put(GeneralActions.addError({
                            action: type,
                            message: err.response?.data?.message
                        }));
                    }
                }  
                function *updateSafetyTrianing({type, payload}) {
                    try {
                        yield put(GeneralActions.removeError(type));
                        yield put(GeneralActions.startLoading(type));
                        const result = yield call(LabourService.UpdateSafetyTraing,payload.id,payload);
                        yield put(GeneralActions.addSuccess({
                            action: type,
                            message: result.data.message
                        }));
                        yield put(GeneralActions.stopLoading(type))
                    } catch (err) {
                        yield put(GeneralActions.stopLoading(type));
                        yield put(GeneralActions.addError({
                            action: type,
                            message: err.response?.data?.message
                        }));
                    }
                }  
                function *deleteSafetyTraing({type, payload}) {
                    try {
                        yield put(GeneralActions.removeError(type));
                        yield put(GeneralActions.startLoading(type));
                        const result = yield call(LabourService.DeleteSafetyTraing, payload);
                        yield put(GeneralActions.addSuccess({
                            action: type,
                            message: result.data.message
                        }));
                        yield put(GeneralActions.stopLoading(type))
                    } catch (err) {
                        yield put(GeneralActions.stopLoading(type));
                        yield put(GeneralActions.addError({
                            action: type,
                            message: err.response?.data?.message
                        }));
                    }
                }  

                function *updateSkillInfo({type, payload}) {
                    try {
                        yield put(GeneralActions.removeError(type));
                        yield put(GeneralActions.startLoading(type));
                        const result = yield call(LabourService.UpdateSkillInfo,payload.id,payload);
                        yield put(GeneralActions.addSuccess({
                            action: type,
                            message: result.data.message
                        }));
                        yield put(GeneralActions.stopLoading(type))
                    } catch (err) {
                        yield put(GeneralActions.stopLoading(type));
                        yield put(GeneralActions.addError({
                            action: type,
                            message: err.response?.data?.message
                        }));
                    }
                }  
                function *getLabourEducationDetails({type, payload}) {
                    try {
                        yield put(GeneralActions.removeError(type));
                        yield put(GeneralActions.startLoading(type));
                        const result = yield call(LabourService.GetLabourEducationDeatils, payload);
                        yield put(LabourActions.getLabourEducationDetailsSuccess(result));
                        yield put(GeneralActions.addSuccess({
                            action: type,
                            message: result.data.message
                        }));
                        yield put(GeneralActions.stopLoading(type))
                    } catch (err) {
                        yield put(GeneralActions.stopLoading(type));
                        yield put(GeneralActions.addError({
                            action: type,
                            message: err.response?.data?.message
                        }));
                    }
                }
                function *addLabourEducation({type, payload}) {
                    try {
                        yield put(GeneralActions.removeError(type));
                        yield put(GeneralActions.startLoading(type));
                        const result = yield call(LabourService.AddLabourEducation,payload);
                        yield put(GeneralActions.addSuccess({
                            action: type,
                            message: result.data.message
                        }));
                        yield put(GeneralActions.stopLoading(type))
                    } catch (err) {
                        yield put(GeneralActions.stopLoading(type));
                        yield put(GeneralActions.addError({
                            action: type,
                            message: err.response?.data?.message
                        }));
                    }
                } 
                function *getLabourHistory({type, payload}) {
                    try {
                        yield put(GeneralActions.removeError(type));
                        yield put(GeneralActions.startLoading(type));
                        const result = yield call(LabourService.GetLabourHistory, payload);
                        yield put(LabourActions.getLabourHistotySuccess(result));
                        yield put(GeneralActions.addSuccess({
                            action: type,
                            message: result.data.message
                        }));
                        yield put(GeneralActions.stopLoading(type))
                    } catch (err) {
                        yield put(GeneralActions.stopLoading(type));
                        yield put(GeneralActions.addError({
                            action: type,
                            message: err.response?.data?.message
                        }));
                    }
                }
                function *getDisabilityMasterData({type, payload}) {
                    try {
                        yield put(GeneralActions.removeError(type));
                        yield put(GeneralActions.startLoading(type));
                        const result = yield call(LabourService.GetDisabilityMasterdata, payload);
                        yield put(LabourActions.getDisabilityMasterDataSuccess(result));
                        yield put(GeneralActions.addSuccess({
                            action: type,
                            message: result.data.message
                        }));
                        yield put(GeneralActions.stopLoading(type))
                    } catch (err) {
                        yield put(GeneralActions.stopLoading(type));
                        yield put(GeneralActions.addError({
                            action: type,
                            message: err.response?.data?.message
                        }));
                    }
                }
                function *createLabourDiseaseReason({type, payload}) {
                    try {
                        yield put(GeneralActions.removeError(type));
                        yield put(GeneralActions.startLoading(type));
                        
                       const result = yield call(LabourService.CreateLabourDiseaseReason,payload);
                
                        yield put(GeneralActions.addSuccess({
                            action: type,
                            message: result.data.message
                        }));
                        yield put(GeneralActions.stopLoading(type))
                    } catch (err) {
                        yield put(GeneralActions.stopLoading(type));
                        yield put(GeneralActions.addError({
                            action: type,
                            message: err.response?.data?.message
                        }));
                    }
                }
                function *getLabourDiseaseListing({type, payload}) {
                    try {
                        yield put(GeneralActions.removeError(type));
                        yield put(GeneralActions.startLoading(type));
                        const result = yield call(LabourService.LabourDiseaseListing, payload);
                        yield put(LabourActions.getLabourDiseaseListingSuccess(result));
                        yield put(GeneralActions.addSuccess({
                            action: type,
                            message: result.data.message
                        }));
                        yield put(GeneralActions.stopLoading(type))
                    } catch (err) {
                        yield put(GeneralActions.stopLoading(type));
                        yield put(GeneralActions.addError({
                            action: type,
                            message: err.response?.data?.message
                        }));
                    }
                }

                function *getLabourDiseaseDetails({type, payload}) {
                    try {
                        yield put(GeneralActions.removeError(type));
                        yield put(GeneralActions.startLoading(type));
                        const result = yield call(LabourService.GetDiseaseDetails, payload);
                        yield put(LabourActions.getDiseaseDetailsSuccess(result));
                        yield put(GeneralActions.addSuccess({
                            action: type,
                            message: result.data.message
                        }));
                        yield put(GeneralActions.stopLoading(type))
                    } catch (err) {
                        yield put(GeneralActions.stopLoading(type));
                        yield put(GeneralActions.addError({
                            action: type,
                            message: err.response?.data?.message
                        }));
                    }
                }

                function *updateDisease({type, payload}) {
                    try {
                        yield put(GeneralActions.removeError(type));
                        yield put(GeneralActions.startLoading(type));
                        const result = yield call(LabourService.UpdateDisease, payload.id,payload);
                        yield put(GeneralActions.addSuccess({
                            action: type,
                            message: result.data.message
                        }));
                        yield put(GeneralActions.stopLoading(type))
                    } catch (err) {
                        yield put(GeneralActions.stopLoading(type));
                        yield put(GeneralActions.addError({
                            action: type,
                            message: err.response?.data?.message
                        }));
                    }
                }

                function *deleteDisease({type, payload}) {
                    try {
                        yield put(GeneralActions.removeError(type));
                        yield put(GeneralActions.startLoading(type));
                        const result = yield call(LabourService.DeleteDisease, payload.id);
                        yield put(GeneralActions.addSuccess({
                            action: type,
                            message: result.data.message
                        }));
                        yield put(GeneralActions.stopLoading(type))
                    } catch (err) {
                        yield put(GeneralActions.stopLoading(type));
                        yield put(GeneralActions.addError({
                            action: type,
                            message: err.response?.data?.message
                        }));
                    }
                }

                function *labourUanCheckListDocumentUpload({type, payload}) {
                    try {
                            yield put(GeneralActions.removeError(type));
                            yield put(GeneralActions.startLoading(type));
                            const result = yield call(LabourService.LabourUanCheckListDocumentUpload, payload);
                            yield put(GeneralActions.stopLoading(type))
                            yield put(GeneralActions.addSuccess({
                                action: type,
                                message: result.data.message
                            }));

                        } catch (err) {
                            yield put(GeneralActions.stopLoading(type));
                            yield put(GeneralActions.addError({
                                action: type,
                                message: err.response?.data?.message
                            }));
                        }
                    } 

                    function *updateUanList({type, payload}) {
                        try {
                            yield put(GeneralActions.removeError(type));
                            yield put(GeneralActions.startLoading(type));
                            const result = yield call(LabourService.UpdateUanList,payload);
                            yield put(GeneralActions.addSuccess({
                                action: type,
                                message: result.data.message
                            }));
                            yield put(GeneralActions.stopLoading(type))
                        } catch (err) {
                            yield put(GeneralActions.stopLoading(type));
                            yield put(GeneralActions.addError({
                                action: type,
                                message: err.response?.data?.message
                            }));
                        }
                    }
                    function *labourComplain({type, payload}) {
                        try {
                            yield put(GeneralActions.removeError(type));
                            yield put(GeneralActions.startLoading(type));
                            
                           const result = yield call(LabourService.LabourComplain,payload);
                    
                            yield put(GeneralActions.addSuccess({
                                action: type,
                                message: result.data.message
                            }));
                            yield put(GeneralActions.stopLoading(type))
                        } catch (err) {
                            yield put(GeneralActions.stopLoading(type));
                            yield put(GeneralActions.addError({
                                action: type,
                                message: err.response?.data?.message
                            }));
                        }
                    }
                    function *getLabourComplainList({type, payload}) {
                        try {
                                yield put(GeneralActions.removeError(type));
                                yield put(GeneralActions.startLoading(type));
                                
                                const result = yield call(LabourService.LabourComplainListing, payload);
                                yield put(LabourActions.getLabourComplainListSuccess(result.data));
                                yield put(GeneralActions.stopLoading(type))
                                yield put(GeneralActions.addSuccess({
                                    action: type,
                                    message: result.data.message
                                }));
                            } catch (err) {
                                yield put(GeneralActions.stopLoading(type));
                                yield put(GeneralActions.addError({
                                    action: type,
                                    message: err.response?.data?.messge
                                }));
                            }
                        } 
                        
                        function *deleteLabourComplain({type, payload}) {
                            try {
                                yield put(GeneralActions.removeError(type));
                                yield put(GeneralActions.startLoading(type));
                                const result = yield call(LabourService.DeleteLabourComplain, payload);
                                yield put(GeneralActions.addSuccess({
                                    action: type,
                                    message: result.data.message
                                }));
                                yield put(GeneralActions.stopLoading(type))
                            } catch (err) {
                                yield put(GeneralActions.stopLoading(type));
                                yield put(GeneralActions.addError({
                                    action: type,
                                    message: err.response?.data?.message
                                }));
                            }
                        }

                        function *getLabourComplinDetails({type, payload}) {
                            try {
                                yield put(GeneralActions.removeError(type));
                                yield put(GeneralActions.startLoading(type));
                                const result = yield call(LabourService.GetLabourComplainDetails, payload);
                                yield put(LabourActions.getLabourComplainDetailsSuccess(result));
                                yield put(GeneralActions.addSuccess({
                                    action: type,
                                    message: result.data.message
                                }));
                                yield put(GeneralActions.stopLoading(type))
                            } catch (err) {
                                yield put(GeneralActions.stopLoading(type));
                                yield put(GeneralActions.addError({
                                    action: type,
                                    message: err.response?.data?.message
                                }));
                            }
                        }
                        function *updateLabourComplain({type, payload}) {
                            try {
                                yield put(GeneralActions.removeError(type));
                                yield put(GeneralActions.startLoading(type));
                                const result = yield call(LabourService.UpdateLabourComplain,payload.id,payload);
                                yield put(GeneralActions.addSuccess({
                                    action: type,
                                    message: result.data.message
                                }));
                                yield put(GeneralActions.stopLoading(type))
                            } catch (err) {
                                yield put(GeneralActions.stopLoading(type));
                                yield put(GeneralActions.addError({
                                    action: type,
                                    message: err.response?.data?.message
                                }));
                            }
                        }

                        function *updateIdCardValidationDate({type, payload}) {
                            try {
                                yield put(GeneralActions.removeError(type));
                                yield put(GeneralActions.startLoading(type));
                                const result = yield call(LabourService.UpdateIdCardlidationDate,payload);
                                yield put(GeneralActions.addSuccess({
                                    action: type,
                                    message: result.data.message
                                }));
                                yield put(GeneralActions.stopLoading(type))
                            } catch (err) {
                                yield put(GeneralActions.stopLoading(type));
                                yield put(GeneralActions.addError({
                                    action: type,
                                    message: err.response?.data?.message
                                }));
                            }
                        }

                        function *getUANFromAadhaarSaga({ type, payload }) {
                            try {
                              yield put(GeneralActions.removeError(type));
                              yield put(GeneralActions.startLoading(type));
                              const { index, aadhaarNumber } = payload;
                              const response = yield call(LabourService.getUANFromAadhaar, { index, aadhaarNumber });
                              const pf_uan = response.data.uan;
                              yield put(LabourActions.setUANNumber(pf_uan));
                              yield put(GeneralActions.addSuccess({
                                action: type,
                                message: response.data.uan
                              }));
                              yield put(GeneralActions.stopLoading(type));
                            } catch (error) {
                              yield put(GeneralActions.stopLoading(type));
                              yield put(GeneralActions.addError({
                                action: type,
                                message: error.response?.data?.message
                              }));
                            }
                          }
        
export function *LabourWatcher() {
    yield all([
        yield takeLatest(LabourActions.createLabour.type, createLabour),
        yield takeLatest(LabourActions.getLabourList.type, getLabourList),
        yield takeLatest(LabourActions.getLabourFormList.type, getLabourFormList),
        yield takeLatest(LabourActions.createLabourHealth.type, createLabourHealth),
        yield takeLatest(LabourActions.getLabourById.type, getLabourById),
        yield takeLatest(LabourActions.updateLabourBasicInfo.type, updateLabourBasicInfo),
        yield takeLatest(LabourActions.updateLabourEmergencyContact.type, updateLabourEmergencyContact),
        yield takeLatest(LabourActions.updateLabourEducation.type, updateLabourEducation),
        yield takeLatest(LabourActions.updateLabourBankDetails.type, updateLabourBankDetails),
        yield takeLatest(LabourActions.updateLabourPhoto.type, updateLabourPhoto),
        yield takeLatest(LabourActions.updateLabourAdharCard.type, updateLabourAdharCard),
        yield takeLatest(LabourActions.updateLabourRationCard.type, updateLabourRationCard),
        yield takeLatest(LabourActions.updateLabourPanCard.type, updateLabourPanCard),
        yield takeLatest(LabourActions.updateLabourPassport.type, updateLabourPassport),
        yield takeLatest(LabourActions.updateHealthDocument.type, updateLabourHealthDocument),
        yield takeLatest(LabourActions.labourBulkUpload.type, labourBulkUpload),
        yield takeLatest(LabourActions.getLabourByAdharCard.type, getLabourByAdharCard),
        yield takeLatest(LabourActions.deleteLabour.type, deleteLabour),
        yield takeLatest(LabourActions.uploadLabourDocument.type, uploadLabourDocument),
        yield takeLatest(LabourActions.uploadLabourCompilance.type, uploadLabourCompilance),
        yield takeLatest(LabourActions.getLabourCompilanceList.type, getLabourCompilanceList),
        yield takeLatest(LabourActions.updateLabourPFBasicInfo.type, updateLabourPFBasicInfo),
        yield takeLatest(LabourActions.updateLabourDailyWages.type, updateLabourDailyWages),
        yield takeLatest(LabourActions.updateLabourMonthlyWages.type, updateLabourMonthlyWages),
        yield takeLatest(LabourActions.getSafetyTrainingList.type, getSafetyTrainingList),
        yield takeLatest(LabourActions.fetchLabourTrainingType.type, fetchLabourTrainingType),
        yield takeLatest(LabourActions.createLabourSafetyTraining.type, createLabourSafetyTraining),
        yield takeLatest(LabourActions.exitLabour.type, exitLabour),
        yield takeLatest(LabourActions.getAllSafetyTrainingList.type, getAllSafetyTrainingList),
        yield takeLatest(LabourActions.getEducationListById.type, getLabourEducationListById),
        yield takeLatest(LabourActions.getEducationList.type, getLabourEducationList),
        yield takeLatest(LabourActions.deleteEducationById.type, deleteEducationById),
        yield takeLatest(LabourActions.getSkillCategory.type, getSkillCategory),
        yield takeLatest(LabourActions.getSafetytraingDetails.type, getSafetyTraingDetails),
        yield takeLatest(LabourActions.deleteSafetyTraing.type, deleteSafetyTraing),
        yield takeLatest(LabourActions.updateSafetyTraing.type, updateSafetyTrianing),
        yield takeLatest(LabourActions.updateSkillInfo.type, updateSkillInfo),
        yield takeLatest(LabourActions.getLabourEducationDetails.type, getLabourEducationDetails),
        yield takeLatest(LabourActions.addLabourEducation.type, addLabourEducation),
        yield takeLatest(LabourActions.getLabourHistory.type, getLabourHistory),
        yield takeLatest(LabourActions.getDisabilityMasterData.type, getDisabilityMasterData),
        yield takeLatest(LabourActions.createLabourDiseaseReason.type, createLabourDiseaseReason),
        yield takeLatest(LabourActions.getLabourDiseaseListing.type, getLabourDiseaseListing),
        yield takeLatest(LabourActions.deleteDisease.type,deleteDisease),
        yield takeLatest(LabourActions.updateDisease.type, updateDisease),
        yield takeLatest(LabourActions.getDiseaseDetails.type, getLabourDiseaseDetails),
        yield takeLatest(LabourActions.labourUanCheckListDocumentUpload.type, labourUanCheckListDocumentUpload),
        yield takeLatest(LabourActions.updateUanList.type, updateUanList),
        yield takeLatest(LabourActions.labourComplain.type, labourComplain),
        yield takeLatest(LabourActions.getLabourComplainList.type, getLabourComplainList),
        yield takeLatest(LabourActions.deleteLabourComplain.type, deleteLabourComplain),
        yield takeLatest(LabourActions.getLabourComplainDetails.type, getLabourComplinDetails),
        yield takeLatest(LabourActions.updateLabourComplain.type, updateLabourComplain),
        yield takeLatest(LabourActions.updateIdCardValidationDate.type, updateIdCardValidationDate),
        yield takeLatest(LabourActions.getUANFromAadhaar.type, getUANFromAadhaarSaga)
]);
}