import React, {useEffect, useState} from "react";
import {
    Box, Card, Chip, Table, TableBody, TableCell,Grid,
    //  Pagination,
      TableHead, TableRow,
    Avatar, MenuItem, 
    // ListItemIcon,
     Hidden,
    IconButton,
    Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import FloatingButton from "components/FloatingButton";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Delete, Edit, Visibility} from "@mui/icons-material";
// import {NameSort} from "constants/sort";
// import CustomMenu from "components/CustomMenu";
import {GeneralSelector} from "selectors";
import {
    CompanyActions, GeneralActions,
    //  GeneralActions, UserActions
} from "slices/actions";
import DialogConfirm from "components/DialogConfirm";
import {toast} from "react-toastify";
import ListSkeleton from "../../components/Skeleton/ListSkeleton";
import {DefaultSort} from "constants/sort";
import { CompanySelector } from "selectors/CompanySelector";
import SelectField from "components/SelectField";
import Input from "components/Input";
import styled from "@emotion/styled";
import { format } from 'date-fns';

const FilterBox = styled(Box)(() => ({
    width: "100%",
    marginTop: 30,
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-between"
}));

function Company() {
     const history = useHistory()
     const dispatch = useDispatch();

    const companyListData = useSelector(CompanySelector.getCompanyList());

    const loading = useSelector(GeneralSelector.loader(CompanyActions.getCompanySuccess.type));
   
    const success = useSelector(GeneralSelector.success(CompanyActions.deleteCompany.type));

    const [filter, setFilter] = useState({
        sort: DefaultSort.newest.value,
        page: 1
    });
  const [selected, setSelected] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    dispatch(CompanyActions.getCompanyList(filter));
}, [filter]);

// window.scrollTo({
//     top: 0,
//     behavior: 'smooth',
// });
// useEffect(() => {
//     if (Can(actions.readAll, features.user)) {
//         fetchUsers(filter);
//     }

//     if (Can(actions.readSome, features.user)) {
//         setFilter({ ...filter, department: profile.department._id });
//         fetchUsers({
//             ...filter,
//             department: profile.department._id
//         });
//     }
// }, [profile]);
useEffect(() => {
  if (success) {
      setConfirmDelete(false);
      setSelected(null);

      toast.success(`${success?.message ?? "Success"}`, {
              position: "top-right",
              autoClose: 3000,
              closeOnClick: true
          });

      dispatch(GeneralActions.removeSuccess(CompanyActions.deleteCompany.type));
      dispatch(CompanyActions.getCompanyList(filter));
    }
}, [success]);

const handleChangeFilter = ({ target }) => {
   const {name, value} = target;

    setFilter({
        ...filter,
        [name]: value
    });
}

// const handleChangePagination = (e, val) => {
//     setFilter({
//         ...filter,
//         page: val
//     });
// };

// const fetchUsers = (params) => {
//   dispatch(UserActions.getUsers(params));
// }

// const handleChangeFilter = ({ target }) => {
//   const { name, value } = target;
//   const params = {
//       ...filter,
//       [name]: value
//   };

//   if (value === -1) {
//       delete params[name];
//   }

//   setFilter(params);
//   fetchUsers(params);
// };

// const handleChangePagination = (e, val) => {
//   setFilter({
//       ...filter,
//       page: val
//   });
// };

const handleDelete = () => {
    dispatch(CompanyActions.deleteCompany(selected));
}
return (
      
  <Card>
      <Typography variant="h5" sx={{ fontWeight: 600 }}>All Company</Typography>
      <FilterBox>
        <Grid container spacing={3} justifyContent="space-between">
            <Grid item lg={6} sm={12} xs={12}>
                <Input
                    label="Search"
                    name='keyword'
                    placeholder='Search by name'
                    value={filter.keyword ?? ''}
                    onChange={handleChangeFilter}/>
            </Grid>
            <Grid item lg={2} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Sort by</Typography>
                <SelectField
                    value={filter.sort}
                    name="sort"
                    onChange={handleChangeFilter}>
                    {Object.keys(DefaultSort).map((key) => (
                        <MenuItem key={key} value={DefaultSort[key].value}>
                            {DefaultSort[key].name}
                        </MenuItem>
                    ))}
                </SelectField> 
            </Grid>
        </Grid>
    </FilterBox>


      {loading ? (
          <ListSkeleton/>
      ) : (
          <Box>
              <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Contacts</TableCell>
                          <TableCell align="center">Status</TableCell>
                          <TableCell align="right" width="18%">Options</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {companyListData.length === 0 && (
                          <TableRow>
                              <TableCell align="center" colSpan={7}>
                                  No Data
                              </TableCell>
                          </TableRow>
                      )}
                      {companyListData.length !== 0 && companyListData.map((item, i) => (
                          <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                              <TableCell>
                                  <Box sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      '& img': {
                                          borderRadius: 50,
                                      }
                                  }}>
                                      {item.logo ? (
                                          <img alt='profile' src={item.logo} width='60' height='60' />
                                      ) : (
                                          <Avatar sx={{ width: 60, height: 60 }}>
                                              {item.name.toString().substring(0, 2).
                                              toUpperCase()}
                                          </Avatar>
                                      )}
                                      <Typography sx={{ ml: 2 }} variant='subtitle2'>
                                        {item.name.toUpperCase()}<br/>
                                        {format(new Date(item.createdAt), 'dd/MM/yyyy')}
                                      </Typography>
                                  </Box>
                              </TableCell>
                              <TableCell> {item.email}</TableCell>
                              <TableCell>
                                 <div> {item.users[0] && item.users[0].name.toUpperCase()} </div> 
                                 <div> {item.users[0] && item.users[0].phone} </div>
                                 <div> {item.users[0] && item.users[0].email}</div>
                                  
                            </TableCell>
                           
                              <Hidden smDown>
                                  <TableCell align="center">
                                      <Chip
                                          label={item.status === 1 ? 'Active' : 'InActive'}
                                          color={item.status === 1 ? 'primary' : 'default'} size='small'/>
                                  </TableCell>
                              </Hidden>
                              <TableCell align="right" width="18%">
                                    <Tooltip title="Preview">
                                        <IconButton onClick={() => history.push(`/company/view/viewcompany/${item._id}`)}>
                                            <Visibility className="text-primary" />
                                        </IconButton>
                                    </Tooltip>
                                    <Hidden smDown>
                                        <Tooltip title="Edit">
                                            <IconButton onClick={() => history.push(`/app/company/update/${item._id}`)}>
                                                <Edit className="text-primary" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <IconButton onClick={() => {
                                                setConfirmDelete(true);
                                                setSelected(item._id);
                                            }}>
                                                <Delete className="text-default" />
                                            </IconButton>
                                        </Tooltip>
                                    </Hidden>

                                </TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>

              {/* <Pagination
                  sx={{ mt: 1 }}
                  page={filter.page}
                  count={pagination.pages}
                  onChange={handleChangePagination}/> */}
          </Box>
      )}

          <FloatingButton
              onClick={() => history.push("/app/company/create")}/>
    
      <DialogConfirm
          title="Delete Company"
          content="Are you sure want to delete this company?"
          open={confirmDelete}
          onClose={() => setConfirmDelete(false)}
          onSubmit={handleDelete}
        />
  </Card>
)
}

export default Company;