import React,{useEffect} from "react";
import {
    Card,
    Grid,
    Typography, Button
} from "@mui/material";
import Input from "components/Input";
import { useFormik } from "formik";
import { GeneralActions, ProjectActions } from "slices/actions";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { GeneralSelector } from "selectors";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import { useParams } from "react-router-dom";
import * as yup from "yup";


PfData.propTypes = {
    projectDetails: PropTypes.object,
};

export default function PfData(props) {
    const {projectDetails} = props
    const dispatch = useDispatch();
    const {id} = useParams();
    const success = useSelector(GeneralSelector.success(ProjectActions.updateProject.type));
    const error = useSelector(GeneralSelector.error(ProjectActions.updateProject.type));
    const loading = useSelector(GeneralSelector.loader(ProjectActions.updateProject.type));

    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(ProjectActions.updateProject.type));
            dispatch(ProjectActions.getProjectById(id));
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeError(ProjectActions.updateProject.type));
        }
    }, [error]);

    const validationSchema = yup.object({
        scopeOfPfRegistration: yup.string().required('This field is required'),
        pfRegistrationNo: yup.string().required('This field is required'),
    });  
    const formik = useFormik({
        initialValues: {
            scopeOfPfRegistration: projectDetails.data?.project?.scopeOfPfRegistration ?? "",
            pfRegistrationNo: projectDetails.data?.project?.pfRegistrationNo ?? "",
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        validateOnChange: true,
        onSubmit: (values) => {
          handleSubmit(values);
        },
    });
    const handleSubmit = (values) => {
        const params ={
            id: id,
            ...values
        }
        dispatch(ProjectActions.updateProject(params))
    };
    return (
        <Card sx={{ mb: 3 }}>
            <Typography variant='h5' sx={{ mb: 4 }}>PF Details</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Scope of PF Registration"
                            name='scopeOfPfRegistration'
                            value={formik.values.scopeOfPfRegistration}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.scopeOfPfRegistration) && Boolean(formik.errors.scopeOfPfRegistration)}
                            helperText={formik.touched.scopeOfPfRegistration && formik.errors.scopeOfPfRegistration}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="PF Registration No"
                            name='pfRegistrationNo'
                            value={formik.values.pfRegistrationNo}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.pfRegistrationNo) && Boolean(formik.errors.pfRegistrationNo)}
                            helperText={formik.touched.pfRegistrationNo && formik.errors.pfRegistrationNo}/>
                    </Grid>
                    <Grid container justifyContent="flex-end" sx={{mt: 2}}>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {loading ? (
                            <CircularProgress color="inherit" size={20} />
                            ) : (
                            <>Submit</>
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Card>
    )
}