import React, {useState} from "react";
import PageTitle from "components/PageTitle";
import {Box, Card, Typography} from "@mui/material";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {useHistory} from "react-router-dom";
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';

export default function LabourInduction() {
    
    const history = useHistory();
    const [value, setValue] = useState('1');
    const handleChange = (event,newValue) => {
        setValue(newValue);
    };

    return (
        <Box>
            <PageTitle isBack={true} title="Labour Induction" />
            <Card>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="tabsNum" style={{fontWeight: "bold"}}>
                            <Tab label="Medical" value="1" sx={{fontWeight: "bold", textTransform: "uppercase"}} />
                            <Tab label="Screening" value="2" sx={{fontWeight: "bold", textTransform: "uppercase"}} />
                            <Tab label="Proforma" value="3" sx={{fontWeight: "bold", textTransform: "uppercase"}} />
                        </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Typography sx={{cursor: 'pointer', fontSize: 20}} onClick={() => history.push(`/app/labourinduction/medical`)}>
                                Certificate of Medical Examination 
                                <ArrowRightAltOutlinedIcon color='primary' fontSize='large' />
                            </Typography>
                            <Typography sx={{cursor: 'pointer', fontSize: 20}} onClick={() => history.push(`/app/labourinduction/fitness`)}>
                                Fitness Certificate
                                <ArrowRightAltOutlinedIcon color='primary' fontSize='large' />
                            </Typography>
                        </TabPanel>
                        <TabPanel value="2">
                        </TabPanel>
                        <TabPanel value="3">
                            <Typography sx={{cursor: 'pointer', fontSize: 20}} onClick={() => history.push(`/app/labourinduction/proforma`)}>
                                Proforma for screening
                                <ArrowRightAltOutlinedIcon color='primary' fontSize='large' />
                            </Typography>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Card>
        </Box>
    );

}