import React, { useEffect } from "react";
import { Grid} from "@mui/material";
 import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { UserSelector } from "selectors";
import { LeaveActions, SettingActions } from "slices/actions";
import Widget from "./Widget";

Widgets.propTypes = {
data: PropTypes.object,
};

export default function Widgets(props) {
  const { data } = props;
  const dispatch = useDispatch();
  const profile = useSelector(UserSelector.profile());
 

  useEffect(() => {
    dispatch(SettingActions.getSetting());
  }, []);

  useEffect(() => {
    if (profile) {
      dispatch(LeaveActions.getLeaves({
          user: profile._id,
        }));
    }
  }, [profile]);

  return (
    <Grid container spacing={3} sx={{ mb: 3 }}>
     {data && data.result && data.result.dashboard && data.result.dashboard.map((item) => <Grid item lg={4} sm={12} xs={12} key={item.id}>
     <Widget
          count={item.count}
          image = {"/img/corner-3.png"}
          title={item.title}
          color={item.color}
          navigto={item.navigation}
        />
    
        </Grid>)}
       </Grid>
  );
}
