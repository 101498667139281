import React from "react";
import {
    // Button,
    // Card,
    Grid,
    Typography,
} from "@mui/material";
import * as yup from "yup";
import {useFormik} from "formik";
import Input from "components/Input";
import PropTypes from "prop-types";

DailyWages.propTypes = {
    labourDetails: PropTypes.object
};

export default function DailyWages(props) {
    const {labourDetails} = props
    const validationSchema = yup.object({
        minimumWages: yup.string().required('This field is required'),
        // epfee: yup.string().required('This field is required'),
        // eps: yup.string().required('This field is required'),
        // epf: yup.string().required('This field is required'),

    });
    const formik = useFormik({
        initialValues: {
            minimumWages: labourDetails?.wages??"",
            // epfee: "",
            // epf: "",
            // eps: "",
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = () => {
    }

    return (
        <>
            <Typography variant='h5' sx={{ mb: 4, fontWeight: '500' }}>Daily Wages</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Wages *"
                            name='minimumWages'
                            value={formik.values.minimumWages}
                            // onChange={formik.handleChange}
                            error={formik.touched.minimumWages && Boolean(formik.errors.minimumWages)}
                            helperText={formik.touched.minimumWages && formik.errors.minimumWages}
                           />
                    </Grid>
                    {/* <Grid item lg={6} xs={12}>
                        <Input
                            label="EPF employee contributrion *"
                            name='epfee'
                            placeholder='Wage for PF * 12%'
                            value={formik.values.epfee}
                            onChange={formik.handleChange}
                            error={formik.touched.epfee && Boolean(formik.errors.epfee)}
                            helperText={formik.touched.epfee && formik.errors.epfee}
                            />
                    </Grid> */}
                    {/* <Grid item lg={12} xs={12}>
                        <Typography variant='h6' sx={{mt: 2}}>Employer Contribution</Typography>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="EPS *"
                            name='eps'
                            placeholder='Wage for PF * 8.33%'
                            value={formik.values.eps}
                            onChange={formik.handleChange}
                            error={formik.touched.eps && Boolean(formik.errors.eps)}
                            helperText={formik.touched.eps && formik.errors.eps}
                            />
                    </Grid>  
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="EPF *"
                            name='epf'
                            placeholder='Wage for PF * 12% - EPS'
                            value={formik.values.epf}
                            onChange={formik.handleChange}
                            error={formik.touched.epf && Boolean(formik.errors.epf)}
                            helperText={formik.touched.epf && formik.errors.epf}
                            />
                    </Grid> */}
                                      
                        {/* <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained">
                               Update
                            </Button>
                        </Grid> */}
                </Grid>
            </form>
        </>
    )
}