import {all, call, put, takeLatest} from 'redux-saga/effects'
import { ProjectService } from 'services/ProjectService';
import { GeneralActions, ProjectActions} from "../slices/actions";

function *getProjectList({type, payload}) {
try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(ProjectService.ProjectList, payload);
        yield put(ProjectActions.getProjectSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}

function *getPaymentAndDuration({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(ProjectService.PaymentAndDuration, payload);
        yield put(ProjectActions.getPaymentAndDurationSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}

function *createProject({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(ProjectService.CreateProject, payload);
         yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}
function *getProjectById({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(ProjectService.GetProjectById, payload);
        yield put(ProjectActions.getProjectByIdSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}
function *getProjectViewData({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(ProjectService.GetProjectViewByID, payload);
        yield put(ProjectActions.getProjectVIewDataSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}
function *deleteProject({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(ProjectService.DeleteProject, payload);
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}
function *updateProject({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(ProjectService.UpdateProject,payload.id, payload);
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}
function *updateDailyWages({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(ProjectService.UpdateDailyWages,payload.id, payload);
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}
function *createDailyWages({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(ProjectService.CreateDailyWages,payload);
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}
function *dailyWagesListing({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(ProjectService.DailyWagesListing,payload);
        yield put(ProjectActions.dailyWagesListingSuccess(result.data));
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *deleteDailyWages({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(ProjectService.DeleteDailyWages,payload);
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *getDailyWagesDetails({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(ProjectService.DailyWagesDetails,payload);
        yield put(ProjectActions.getDailyWagesDetailsSuccess(result.data));
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *updateProjectSetting({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(ProjectService.UpdateProjectSetting,payload.id, payload);
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *getClientNameList({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(ProjectService.ClientNameList,payload);
        yield put(ProjectActions.getClientNameListSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}

export function *ProjectWatcher() {
    yield all([
        yield takeLatest(ProjectActions.createProject.type, createProject),
        yield takeLatest(ProjectActions.getPaymentAndDuration.type, getPaymentAndDuration),
        yield takeLatest(ProjectActions.getProjectList.type, getProjectList),
        yield takeLatest(ProjectActions.getProjectById.type, getProjectById),
        yield takeLatest(ProjectActions.deleteProject.type, deleteProject),
        yield takeLatest(ProjectActions.updateProject.type, updateProject),
        yield takeLatest(ProjectActions.getProjectVIewData.type, getProjectViewData),
        yield takeLatest(ProjectActions.updateDailyWages.type, updateDailyWages),
        yield takeLatest(ProjectActions.createDailyWages.type, createDailyWages),
        yield takeLatest(ProjectActions.dailyWagesListing.type, dailyWagesListing),
        yield takeLatest(ProjectActions.deleteDailyWages.type, deleteDailyWages),
        yield takeLatest(ProjectActions.getDailyWagesDetails.type, getDailyWagesDetails),
        yield takeLatest(ProjectActions.updateProjectSetting.type, updateProjectSetting),
        yield takeLatest(ProjectActions.getClientNameList.type, getClientNameList),

    ]);
}