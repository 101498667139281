import { createSlice } from "@reduxjs/toolkit";

export const StateSlice = createSlice({
    name: "state",
    initialState: {
        stateList: [],
       pagination: {},
        stateData: {},
    },
    reducers: {
        // getStateList: () => {},
        getStateList1: () => {},

        getStateSuccess: (state, action) => {
             state.stateData = {};
             state.stateList = action.payload;
            state.pagination = action.payload.pagination;
        },
        getStateById: () => {},
        getSateByIdSuccess: (state, action) => {
            state.stateData = action.payload
        },
      
        createState: () => {},
        updateState: () => {},
        
        deleteState: () => {},

        getCompanyList: () => {},

        GetAllState: () => {},
            
    }
});

export default StateSlice;