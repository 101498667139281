import { createSlice } from "@reduxjs/toolkit";

export const CompanySlice = createSlice({
    name: "company",
    initialState: {
        companies: [],
        pagination: {},
        company: {},
        pinCodeData: {},
        role: {},
        companyViewData: {}
    },
    reducers: {
        getCompanyList: () => {},
        getCompanySuccess: (state, action) => {
            state.company = {};
            state.companies = action.payload.data;
            state.pagination = action.payload.pagination;
            state.role = action.payload.role
        },
        getCompanyById: () => {},
        getCompanyByIdSuccess: (state, action) => {
            state.company = action.payload;
        },
        getCompanyVIewData: () => {},
        getCompanyVIewDataSuccess: (state, action) => {
            state.companyViewData = action.payload;
        },

        createCompany: () => {},
        updateCompany: () => {},
        updateCompanyUser: () => {},
        deleteCompany: () => {},
        fetchDataByPincode: () => {},
        fetchDataByPincodeSuccess: (state,action) => {
            state.pinCodeData = action.payload
        },
        uploadImage: () => {},
      }
});

export default CompanySlice;