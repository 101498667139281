import { get,post,del, patch} from "../utils/api";

const API_URL = process.env.REACT_APP_API_URL 

const CreateProject = async (params) => post(`${API_URL}/project`,params);

const ProjectList = async (params) => get(`${API_URL}/project`,params);

const PaymentAndDuration = async () => get(`${API_URL}/project/masterdata`);

const GetProjectById = async (id) => get(`${API_URL}/project/${id}`)

const FetchDataByPincode = async (id) => get(`${API_URL}/location/${id}`);

const DeleteProject = async (id) => del(`${API_URL}/project/${id}`);

const UpdateProject = async (id,params) => patch(`${API_URL}/project/${id}`,params);

const GetProjectListById = async (id) => get(`${API_URL}/user/${id}`);

const GetProjectViewByID = async (id) => get(`${API_URL}/project/projectViewById/${id}`);

const UpdateDailyWages = async (id,params) => patch(`${API_URL}/projectDailyWages/${id}`,params);

const CreateDailyWages = async (params) => post(`${API_URL}/projectDailyWages`,params);

const DailyWagesListing = async (params) => get(`${API_URL}/projectDailyWages`,params);

const DeleteDailyWages = async (id) => del(`${API_URL}/projectDailyWages/${id}`);

const DailyWagesDetails = async (id) => get(`${API_URL}/projectDailyWages/${id}`);

const UpdateProjectSetting = async (id, params) => patch(`${API_URL}/project/addProjectSettings/${id}`,params);

const ClientNameList = async () => get(`${API_URL}/project/clientList`);

export const ProjectService = {
    CreateProject,
    ProjectList,
    PaymentAndDuration,
    FetchDataByPincode,
    DeleteProject,
    GetProjectById,
    UpdateProject,
    GetProjectViewByID,
    GetProjectListById,
    UpdateDailyWages,
    CreateDailyWages,
    DailyWagesListing,
    DeleteDailyWages,
    DailyWagesDetails,
    UpdateProjectSetting,
    ClientNameList
  };