import React, {useEffect,useState} from "react";
import {Box, Button, Card, CircularProgress, Grid, MenuItem, Typography} from "@mui/material";
import PageTitle from "components/PageTitle";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import { GeneralActions, CompanyActions, ProjectActions, SubContractorActions, UserActions, MasterDataActions} from "slices/actions";
import { GeneralSelector, MasterDataSelector, ProjectSelector, SubContractorSelector } from "selectors";
import SelectField from "components/SelectField";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Constants } from "utils/constants";
import { CompanySelector } from "selectors/CompanySelector";
import { useHistory } from "react-router-dom";

export default function AddGstCompliance() {
    const history = useHistory();
    const dispatch = useDispatch();
    const loading = useSelector(GeneralSelector.loader(UserActions.uploadGstCompliance.type));
    const error = useSelector(GeneralSelector.error(UserActions.uploadGstCompliance.type));
    const success = useSelector(GeneralSelector.success(UserActions.uploadGstCompliance.type));
    const yearList = useSelector(MasterDataSelector.getYearList());
    const monthList = useSelector(MasterDataSelector.getMonthList());
    const userType = localStorage.getItem('userType');
    const companyId = localStorage.getItem('companyID');
    const projectId = localStorage.getItem('projectID');
    const subcontractorId = localStorage.getItem('subcontractorID');
    const userId = localStorage.getItem('userID');
    const companyList = useSelector(CompanySelector.getCompanyList());
    const projectList = useSelector(ProjectSelector.getProjectList());
    const [errorObj,setErrorObj] = useState({});
    const [assignedProject,setAssignedProject] = useState("");
    const assignedProjectToSubcontractorList = useSelector(SubContractorSelector.getAssignedSubcontractor());
    const [gstComplianceForm,setGstComplianceForm] = useState({
        company_id: companyId?companyId:"",
        project_id: projectId?projectId:"",
        subcontractor_id: subcontractorId?subcontractorId:"",
        gstCompliance_document: "",
        note: "",
        Compliance_type: "",
        month: "",
        year: ""
      })
     useEffect(() => {
        dispatch(MasterDataActions.getMonthList());
        dispatch(MasterDataActions.getYearList());
        if (!companyId) {
            dispatch(CompanyActions.getCompanyList());
        } else if (companyId && !projectId) {
            const params = {
                company_id: companyId,
            }
           dispatch(ProjectActions.getProjectList(params));
        } else if (projectId && !subcontractorId) {
            const params = {
                company_id: companyId,
                project_id: projectId
            }
            dispatch(SubContractorActions.getAssignedSubContractor(params));
        } else if (subcontractorId) {
            const params = {
                subcontractorId: subcontractorId
            }
             dispatch(SubContractorActions.getAssignedSubContractor(params));
        }
    },[])
  
    useEffect(() => {
        if (gstComplianceForm.company_id || companyId) {
            const params = {
                company_id: gstComplianceForm.company_id ? gstComplianceForm.company_id:companyId
            }
            dispatch(ProjectActions.getProjectList(params))
        }
    },[gstComplianceForm.company_id])
  
    useEffect(() => {
      if (gstComplianceForm.project_id || projectId) {

          const params = {
            company_id: gstComplianceForm.company_id ? gstComplianceForm.company_id: companyId,
            project_id: gstComplianceForm.project_id ? gstComplianceForm.project_id: projectId,
           }
          
           dispatch(SubContractorActions.getAssignedSubContractor(params));
      }
  },[gstComplianceForm.project_id])
  
     const handleChangeCompany = (e) => {
        delete errorObj.company_id;
        setErrorObj({...errorObj})
        const cId = e.target.value;
        gstComplianceForm.company_id = cId;
        gstComplianceForm.project_id = "";
        gstComplianceForm.subcontractor_id = "";
        setGstComplianceForm({...gstComplianceForm})
     } 
     
     const handleChangeProject = (e) => {
        delete errorObj.project_id;
        setErrorObj({...errorObj})
        if (gstComplianceForm.company_id) {
        const pId = e.target.value
        gstComplianceForm.project_id = pId;
        gstComplianceForm.subcontractor_id = "";
        setGstComplianceForm({...gstComplianceForm})
        } 
      }

    const handleChangeSubcontractor = (e) => {
        delete errorObj.subcontractor_id;
        setErrorObj({...errorObj})
        if (gstComplianceForm.project_id) {
          const sId = e.target.value
          gstComplianceForm.subcontractor_id = sId;
          setGstComplianceForm({...gstComplianceForm})
          } 
    
       }

    const handleChangeDocument = (e) => {
        delete errorObj.gstCompliance_document;
        setErrorObj({...errorObj})
        const file = e.target.files[0];
        gstComplianceForm.gstCompliance_document = file
        setGstComplianceForm({...gstComplianceForm})
    };
    const handleChangeComplianceType = (e) => {
        delete errorObj.Compliance_type;
        setErrorObj({...errorObj})
        gstComplianceForm.Compliance_type = e.target.value
        setGstComplianceForm({...gstComplianceForm})
    }
    const handleSubmit = () => {
    if (validForm()) {
        const params = {
          ...gstComplianceForm,
          type: "subcontractor",
          user_id: userId

        }
      dispatch(UserActions.uploadGstCompliance(params))
    }
};

    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true
                });
      
            dispatch(GeneralActions.removeSuccess(UserActions.uploadGstCompliance.type));
            history.goBack();
          }
      }, [success]);

      useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true
                });
      
            dispatch(GeneralActions.removeError(UserActions.uploadGstCompliance.type));
          }
      }, [error]);
      const handleChangeMonth = (e) => {
        delete errorObj.month;
        setErrorObj({...errorObj})
        gstComplianceForm.month = e.target.value
        setGstComplianceForm({...gstComplianceForm})
      }
      const handleChangeYear = (e) => {
        delete errorObj.year;
        setErrorObj({...errorObj})
        gstComplianceForm.year = e.target.value
        setGstComplianceForm({...gstComplianceForm})
      }
      const validForm = () => {
        let valid = true
        if (!gstComplianceForm.company_id) {
            errorObj.company_id = "This field is Required"
            valid = false;
        }
        if (!gstComplianceForm.project_id) {
            errorObj.project_id = "This field is Required"
            valid = false;
        }
        if (!gstComplianceForm.subcontractor_id) {
            errorObj.subcontractor_id = "This field is Required"
            valid = false;
        }
        if (!gstComplianceForm.Compliance_type) {
            errorObj.Compliance_type = "This field is Required"
            valid = false;
        }
        if (!gstComplianceForm.gstCompliance_document) {
            errorObj.pfCompliance_document = "This field is Required"
            valid = false;
        }
        if (!gstComplianceForm.note) {
            errorObj.note = "This field is Required"
            valid = false;
        }
        if (!gstComplianceForm.month) {
            errorObj.month = "This field is Required"
            valid = false;
        }
        if (!gstComplianceForm.year) {
            errorObj.year = "This field is Required"
            valid = false;
        }
        setErrorObj({...errorObj})
        return valid
    }
    const handleChangeProjectBySubcontractor = (e) => {
        delete errorObj.company_id;
        delete errorObj.project_id;
        setErrorObj({...errorObj})
        setAssignedProject(e.target.value)
        for (const i in assignedProjectToSubcontractorList.data) {
            if (assignedProjectToSubcontractorList.data[i].projectId._id === e.target.value) {
                gstComplianceForm.company_id = assignedProjectToSubcontractorList.data[i].companyId._id;
                gstComplianceForm.project_id = assignedProjectToSubcontractorList.data[i].projectId._id;
                setGstComplianceForm({...gstComplianceForm})
              }
        }
    }

    return (
        <Box>
         <PageTitle isBack={true} title="GST Compliance" />
                <Card>
                    <Grid container spacing={3}>
                    {userType === Constants.SUPERADMIN?(
                        <Grid item lg={4} sm={12} xs={12}>
                            <Typography varient='h6' sx={{fontSize: '15px'}}>Company</Typography>
                             <SelectField
                                name="companyName"
                                value={gstComplianceForm.company_id}
                                onChange={handleChangeCompany}
                                >
                               {companyList && companyList.map((item,index) => (
                                    <MenuItem value={item._id} key={index}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </SelectField>
                            {errorObj.company_id ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}
                         </Grid>
                          ):null} 
                        {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER?(
                         <Grid item lg={4} sm={12} xs={12}>
                            <Typography varient='h6' sx={{fontSize: '15px'}}>Project</Typography>
                             <SelectField
                                name="projectName"
                                value={gstComplianceForm.project_id}
                                onChange={handleChangeProject}
                                >
                                  {(gstComplianceForm.company_id && projectList) && projectList.map((item,index) => (
                                    <MenuItem value={item._id} key={index}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </SelectField>
                            {errorObj.project_id ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}
                         </Grid>
                          ):null} 
                        {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER || userType === Constants.PROJECT_USER?(

                        <Grid item lg={4} sm={12} xs={12}>
                            <Typography varient='h6' sx={{fontSize: '15px'}}>SubContractor</Typography>
                             <SelectField
                                name="subcontractorName"
                                value={gstComplianceForm.subcontractor_id}
                                onChange={handleChangeSubcontractor}
                                >
                               {(gstComplianceForm.project_id && assignedProjectToSubcontractorList) && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                                <MenuItem value={item?.subcontractorId?._id} key={index}>
                                    {item?.subcontractorId?.subContractorName}
                                </MenuItem>
                              ))}
                            </SelectField>
                            {errorObj.subcontractor_id ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}
                        </Grid>
                         ):null} 
                    {userType === Constants.SUB_CONTRACTOR_USER ?(
                        <Grid item lg={4} sm={12} xs={12}>
                        <Typography sx={{fontSize: 15}}>Project</Typography>
                        <SelectField
                            value={assignedProject}
                            onChange={handleChangeProjectBySubcontractor}
                            >
                            {assignedProjectToSubcontractorList && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                            <MenuItem key={index} value={item?.projectId?._id}>
                                    {item?.projectId?.name}({item?.companyId?.name})
                            </MenuItem>
                            ))}
                        </SelectField> 
                        {(errorObj.project_id && errorObj.company_id) && <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>}
                        </Grid>
                    ):null
                    }
                         
                        <Grid item lg={6} sm={12} xs={12}>
                            <Typography varient='h6' sx={{fontSize: '15px'}}>Upload Document</Typography>
                            <label style={{backgroundColor: '#f1f1f1', padding: '6px', borderRadius: '5px'}} className="cursor-pointer w-100">
                                <input
                                    type="file"
                                    id="profile2"
                                    onChange={handleChangeDocument}
                                />
                            </label>
                            {errorObj.gstCompliance_document ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}

                        </Grid>
                        <Grid item lg={6} xs={12}>
                        <Typography variant='h5' sx={{color: '#000', fontSize: 15, opacity: 'rgba(0, 0, 0, 0.87)'}}>Compliance Type</Typography>
                          <SelectField
                            value={gstComplianceForm.Compliance_type}
                            onChange={handleChangeComplianceType}
                            >
                                <MenuItem value={"GSTR 1"}>
                                    {"GSTR 1"}
                                </MenuItem>
                                <MenuItem value={"GSTR 3"}>
                                    {"GSTR 3"}
                                </MenuItem>
                         </SelectField>
                         {errorObj.Compliance_type ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}

                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Typography variant='h5' sx={{color: '#000', fontSize: 15, opacity: 'rgba(0, 0, 0, 0.87)'}}>Month</Typography>
                          <SelectField
                            value={gstComplianceForm.month}
                            onChange={handleChangeMonth}
                            >
                        {monthList && monthList.masterMonthTable && monthList.masterMonthTable.map((item,index) => (
                              <MenuItem value={item.mth} key={index}>
                                    {item.mth}
                              </MenuItem> 
                        ))}
                         </SelectField>
                         {errorObj.month ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}

                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Typography variant='h5' sx={{color: '#000', fontSize: 15, opacity: 'rgba(0, 0, 0, 0.87)'}}>Year</Typography>
                          <SelectField
                            value={gstComplianceForm.year}
                            onChange={handleChangeYear}
                            >
                             {yearList && yearList.masterYearTable && yearList.masterYearTable.map((item,index) => (
                              <MenuItem value={item.yr} key={index}>
                                    {item.yr}
                              </MenuItem> 
                            ))}
                         </SelectField>
                         {errorObj.year ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}

                    </Grid>
                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography variant='' sx={{fontSize: '15px'}}>Note</Typography>
                            <TextareaAutosize
                                style={{
                                textAlign: "left",
                                width: "100%",
                                height: "80px",
                                background: '#f1f1f1',
                                borderRadius: '8px',
                                border: '1px solid #f1f1f1',
                                padding: '5px'
                                }}
                                name="note"
                                maxRows={4}
                                aria-label="maximum height"
                                placeholder="Note Here.."
                                onChange={(e) => {
                                    delete errorObj.note
                                    setErrorObj({...errorObj})
                                    gstComplianceForm.note = e.target.value 
                                    setGstComplianceForm({...gstComplianceForm})
                                }}
                               />
                             {errorObj.note ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}
                               
                        </Grid>
                        <Grid container justifyContent="flex-end">
                            <Button sx={{mt: 2}}
                                color="primary"
                                variant="contained"
                                type="submit"
                                onClick={handleSubmit}
                                >
                             {loading?<CircularProgress color="inherit" size={20}/>:<>Submit</>}
                            </Button>
                        </Grid>
                        
                    </Grid>
            </Card>
       
    </Box>
    )
}