import {all, call, put, takeLatest} from 'redux-saga/effects'
import {TransactionService} from "../services";
import {GeneralActions, TransactionActions} from "../slices/actions";


function *paymentStatus({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        console.log('payload',JSON.stringify(payload))
        const result = yield call(TransactionService.PaymentStatus, payload);
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: "Error"
        }));
    }
}

function *getTransactionById({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(TransactionService.TransactionById, payload);
        yield put(TransactionActions.getTransactionByIdSuccess(result.data))
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: "Error"
        }));
    }
}
function *getAllTransactionList({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(TransactionService.AllTransactionList, payload);
        yield put(TransactionActions.getAllTranscationListSuccess(result.data))
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: "Error"
        }));
    }
}
export function *TransactionWatcher() {
    yield all([
        yield takeLatest(TransactionActions.paymentStatus.type,paymentStatus),
        yield takeLatest(TransactionActions.getTransactionById.type,getTransactionById),
        yield takeLatest(TransactionActions.getAllTransactionList.type,getAllTransactionList),

    ]);
}