import {post,get,del,patch} from "../utils/api";

const API_URL = process.env.REACT_APP_API_URL

const CreateSubContractor = async (params) => post(`${API_URL}/user`, params);

const GetSubContractorById = async(project_id) => get(`${API_URL}/user/?project_id=${project_id}&is_subcontractor_user=true`);

const GetSubContractorList = async(params) => get(`${API_URL}/user/`,params);

const DeleteSubContractor = async (id) => del(`${API_URL}/user/${id}`);

const UploadSubcontractorDocument = async (id,params) => patch(`${API_URL}/user/documentUpload/${id}`,params);

const UpdteSubcontractor = async (id,params) => patch(`${API_URL}/user/${id}`,params);

const AssignSubContractor = async (params) => post(`${API_URL}/project/assignProjectSubcontractor`,params);

const GetDataByPanCard = async (params) => post(`${API_URL}/user/checkPanCardExist`,params);

const GetAssignedSubcontractor = async (params) => get(`${API_URL}/projectSubcontractor`,params);

export const SubContractorService = {
    CreateSubContractor,
    GetSubContractorById,
    DeleteSubContractor,
    GetSubContractorList,
    UploadSubcontractorDocument,
    UpdteSubcontractor,
    AssignSubContractor,
    GetDataByPanCard,
    GetAssignedSubcontractor
};