import {del, get, patch, post} from "../utils/api";

const API_URL = process.env.REACT_APP_API_URL 

const GetUsers = async (params) => get(`${API_URL}/user`, params);

const GetUserById = async (id) => get(`${API_URL}/user/${id}`);

const CreateUser = async (params) => post(`${API_URL}/user`, params);

const UpdateUser = async (id, params) => patch(`${API_URL}/user/${id}`, params);

const DeleteUser = async (id) => del(`${API_URL}/user/${id}`);

const dashboardUrl = async () => get(`${API_URL}/dashboard`);

const Profile = async () => get(`${API_URL}/user/profile`);

const getUserListById = async(project_id) => get(`${API_URL}/user/?project_id=${project_id}&is_project_user=true`);

const UploadGstCompliance = async(params) => post(`${API_URL}/gstCompliance`, params);

const FetchGstComplianceListing = async(params) => get(`${API_URL}/gstCompliance`,params);

const UploadPfCompliance = async(params) => post(`${API_URL}/pfCompliance `, params);

const FetchPfComplianceListing = async(params) => get(`${API_URL}/pfCompliance`,params);

const ResetUserPassword = async(params) => post(`${API_URL}/user/resetPassword`,params);

export const UserService = {
    GetUsers,
    GetUserById,
    CreateUser,
    UpdateUser,
    DeleteUser,
    Profile,
    getUserListById,
    dashboardUrl,
    UploadGstCompliance,
    FetchGstComplianceListing,
    UploadPfCompliance,
    FetchPfComplianceListing,
    ResetUserPassword
};