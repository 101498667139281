import {all, call, put, takeLatest} from 'redux-saga/effects'
import { ReportService } from 'services/ReportService';
import {GeneralActions, ReportActions} from "../slices/actions";


function *formDData({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const flag =payload.flag === "mainAttendance" ? "mainAttendance" : "subAttendance"
        const result = yield call(ReportService.FormDURL, payload,flag);
        yield put(ReportActions.getReportDataSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *formAData({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(ReportService.FormAURL, payload);
        yield put(ReportActions.getReportADataSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *formBData({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(ReportService.FormBURL, payload);
        yield put(ReportActions.getReportBDataSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}


export function *ReportWatcher() {
    yield all([
        yield takeLatest(ReportActions.getReportData.type,formDData),
        yield takeLatest(ReportActions.getReportAData.type,formAData),
        yield takeLatest(ReportActions.getReportBData.type,formBData),
    ]);
}