import React ,{useEffect} from "react";
import {
    Button,
    CircularProgress,
    // Card,
    Grid,
    Typography,
} from "@mui/material";
import * as yup from "yup";
import {useFormik} from "formik";
import Input from "components/Input";
import PropTypes from "prop-types";
import { GeneralActions, LabourActions } from "slices/actions";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GeneralSelector } from "selectors";
import {toast} from "react-toastify";


MonthlyWages.propTypes = {
    labourDetails: PropTypes.object
};

export default function MonthlyWages(props) {
    const { labourDetails } = props;
    const {id} = useParams();
    const dispatch = useDispatch();
    const success = useSelector(GeneralSelector.success(LabourActions.updateLabourMonthlyWages.type));
    const loading = useSelector(GeneralSelector.loader(LabourActions.updateLabourMonthlyWages.type));
    const error = useSelector(GeneralSelector.error(LabourActions.updateLabourMonthlyWages.type));
    const validationSchema = yup.object({
        m_fixedSalary: yup.string().required('This field is required'),
        m_da: yup.string().required('This field is required'),
        m_hra: yup.string().required('This field is required'),
        m_conveyance: yup.string().required('This field is required'),
        m_basicSalary: yup.string().required('This field is required'),
        m_ta: yup.string().required('This field is required'),
        m_EPF_employeeContributrion: yup.string().required('This field is required'),
        m_EPS: yup.string().required('This field is required'),
        m_EPF: yup.string().required('This field is required'),

    });
    const formik = useFormik({
        initialValues: {
            m_fixedSalary: labourDetails?.result?.m_fixedSalary??"",
            m_da: labourDetails?.result?.m_da??"",
            m_hra: labourDetails?.result?.m_hra??"",
            m_conveyance: labourDetails?.result?.m_conveyance??"",
            m_basicSalary: labourDetails?.result?.m_basicSalary??"",
            m_ta: labourDetails?.result?.m_ta??"",
            m_EPF_employeeContributrion: labourDetails?.result?.m_EPF_employeeContributrion??"",
            m_EPS: labourDetails?.result?.m_EPS??"",
            m_EPF: labourDetails?.result?.m_EPF??"",
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = (values) => {
        const params = {
            id: id,
            ...values
        }
        dispatch(LabourActions.updateLabourMonthlyWages(params))
    }

    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });
    
            dispatch(GeneralActions.removeSuccess(LabourActions.updateLabourMonthlyWages.type));
            dispatch(LabourActions.getLabourById(id));

        }
      }, [success]);
    
    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });
    
            dispatch(GeneralActions.removeError(LabourActions.updateLabourMonthlyWages.type));
        }
    }, [error]);
    const handleChangeBasiSalary = (e) => {
        const basicSalary = e.target.value 
        formik.setFieldValue('m_basicSalary', basicSalary);
        const eps = (basicSalary/100)*8.33
        const epf = (basicSalary/100)*12
        formik.setFieldValue('m_EPS', eps);
        formik.setFieldValue('m_EPF', epf);
    }

    return (
        <>
            <Typography variant='h5' sx={{ mb: 4, fontWeight: '500' }}>Monthly Wages</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Fixed Salary *"
                            type="number"
                            name='m_fixedSalary'
                            value={formik.values.m_fixedSalary}
                            onChange={formik.handleChange}
                            error={formik.touched.m_fixedSalary && Boolean(formik.errors.m_fixedSalary)}
                            helperText={formik.touched.m_fixedSalary && formik.errors.m_fixedSalary}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="DA *"
                            type="number"
                            name='m_da'
                            value={formik.values.m_da}
                            onChange={formik.handleChange}
                            error={formik.touched.m_da && Boolean(formik.errors.m_da)}
                            helperText={formik.touched.m_da && formik.errors.m_da}
                           />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="HRA *"
                            type="number"
                            name='m_hra'
                            value={formik.values.m_hra}
                            onChange={formik.handleChange}
                            error={formik.touched.m_hra && Boolean(formik.errors.m_hra)}
                            helperText={formik.touched.m_hra && formik.errors.m_hra}
                            />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Conveyance *"
                            type="number"
                            name='m_conveyance'
                            value={formik.values.m_conveyance}
                            onChange={formik.handleChange}
                            error={formik.touched.m_conveyance && Boolean(formik.errors.m_conveyance)}
                            helperText={formik.touched.m_conveyance && formik.errors.m_conveyance}
                            />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Basic Salary *"
                            type="number"
                            name='m_basicSalary'
                            value={formik.values.m_basicSalary}
                            onChange={handleChangeBasiSalary}
                            error={formik.touched.m_basicSalary && Boolean(formik.errors.m_basicSalary)}
                            helperText={formik.touched.m_basicSalary && formik.errors.m_basicSalary}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="TA *"
                            type="number"
                            name='m_ta'
                            value={formik.values.m_ta}
                            onChange={formik.handleChange}
                            error={formik.touched.m_ta && Boolean(formik.errors.m_ta)}
                            helperText={formik.touched.m_ta && formik.errors.m_ta}
                            />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="EPF employee contributrion *"
                            type="number"
                            name='m_EPF_employeeContributrion'
                            placeholder='Wage for PF * 12%'
                            value={formik.values.m_EPF_employeeContributrion}
                            onChange={formik.handleChange}
                            error={formik.touched.m_EPF_employeeContributrion && Boolean(formik.errors.m_EPF_employeeContributrion)}
                            helperText={formik.touched.m_EPF_employeeContributrion && formik.errors.m_EPF_employeeContributrion}
                            />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Typography variant='h6' sx={{mt: 2}}>Employer Contribution</Typography>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="EPS *"
                            name='m_EPS'
                            type="number"
                            placeholder='Wage for PF * 8.33%'
                            value={formik.values.m_EPS}
                            // onChange={formik.handleChange}
                            error={formik.touched.m_EPS && Boolean(formik.errors.m_EPS)}
                            helperText={formik.touched.m_EPS && formik.errors.m_EPS}
                            />
                    </Grid>  
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="EPF *"
                            name='m_EPF'
                            type="number"
                            placeholder='Wage for PF * 12% - EPS'
                            value={formik.values.m_EPF}
                            // onChange={formik.handleChange}
                            error={formik.touched.m_EPF && Boolean(formik.errors.m_EPF)}
                            helperText={formik.touched.m_EPF && formik.errors.m_EPF}
                            />
                    </Grid>               
                        <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained">
                               {loading?(
                                   <CircularProgress color="inherit" size={20}/>
                                ):(
                                  <>Update</>  
                                )}
                            </Button>
                        </Grid>
                </Grid>
            </form>
        </>
    )
}