import React from "react";
import {
    Card,
    Grid,
    Typography,
} from "@mui/material";

import Input from "components/Input";
import PropTypes from "prop-types";


TransactionInformation.propTypes = {
    formik: PropTypes.object
};

export default function TransactionInformation(props) {
    const { formik } = props;

    // useEffect(() => {
    //     console.log("transaction", localStorage.getItem("userType"))
    // }, [])

    return (
        <Card sx={{ mb: 3 }}>
            <Typography variant='h5' sx={{ mb: 4 }}>Transaction Information</Typography>
            <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="GST Number"
                        name='gstnumber'
                        value={formik.values.gstnumber}
                        onChange={formik.handleChange}
                        error={formik.touched.gstnumber && Boolean(formik.errors.gstnumber)}
                        helperText={formik.touched.gstnumber && formik.errors.gstnumber} />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="HSN Code"
                        name='hsn'
                        value={formik.values.hsn}
                        onChange={formik.handleChange}
                        error={formik.touched.hsn && Boolean(formik.errors.hsn)}
                        helperText={formik.touched.hsn && formik.errors.hsn} />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="IGST%"
                        name='igst'
                        value={formik.values.igst}
                        onChange={formik.handleChange}
                        error={formik.touched.igst && Boolean(formik.errors.igst)}
                        helperText={formik.touched.igst && formik.errors.igst} />
                </Grid>
                <Grid item lg={3} xs={12}>
                    <Input
                        label="CGST%"
                        name='cgst'
                        value={formik.values.cgst}
                        onChange={formik.handleChange}
                        error={formik.touched.cgst && Boolean(formik.errors.cgst)}
                        helperText={formik.touched.cgst && formik.errors.cgst} />
                </Grid>
                <Grid item lg={3} xs={12}>
                    <Input
                        label="SGST%"
                        name='sgst'
                        value={formik.values.sgst}
                        onChange={formik.handleChange}
                        error={formik.touched.sgst && Boolean(formik.errors.sgst)}
                        helperText={formik.touched.sgst && formik.errors.sgst} />
                </Grid>
            </Grid>
        </Card>
    )
}