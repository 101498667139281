import {all, call, put, takeLatest} from 'redux-saga/effects';
import { SubContractorService } from 'services';
import { GeneralActions, SubContractorActions } from 'slices/actions';

function *createSubContractor({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(SubContractorService.CreateSubContractor, payload);
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}
function *getSubContractorById({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(SubContractorService.GetSubContractorById ,payload);
        yield put(SubContractorActions.getSubContractorByIdSucess(result.data));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}
function *getSubContractorList({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(SubContractorService.GetSubContractorList ,payload);
        yield put(SubContractorActions.getSubContractorListSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}
function *deleteSubcontractor({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(SubContractorService.DeleteSubContractor, payload);
         yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}
function *uploadSubContractorDocument({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const id = payload.id
        const result = yield call(SubContractorService.UploadSubcontractorDocument,id, payload);

        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}
function *updateSubContractor({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const id = payload.id
        const result = yield call(SubContractorService.UpdteSubcontractor,id, payload);
         yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *assignSubContractor({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(SubContractorService.AssignSubContractor, payload);
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *getDataByPancard({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(SubContractorService.GetDataByPanCard, payload);
        yield put(SubContractorActions.getDataByPancardSuccess(result.data));
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *getAssignedSubContractor({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(SubContractorService.GetAssignedSubcontractor, payload);
        yield put(SubContractorActions.getAssignedSubContractorSuccess(result.data));
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

export function *SubContractorWatcher() {
    yield all([
       yield takeLatest(SubContractorActions.createSubContractor.type,createSubContractor),
       yield takeLatest(SubContractorActions.getSubContractorById.type,getSubContractorById),
       yield takeLatest(SubContractorActions.deleteSubContractor.type,deleteSubcontractor),
       yield takeLatest(SubContractorActions.getSubContractorList.type,getSubContractorList),
       yield takeLatest(SubContractorActions.uploadSubcontractorDocument.type,uploadSubContractorDocument),
       yield takeLatest(SubContractorActions.updateSubContractor.type,updateSubContractor),
       yield takeLatest(SubContractorActions.assignSubContractor.type,assignSubContractor),
       yield takeLatest(SubContractorActions.getDataByPancard.type,getDataByPancard),
       yield takeLatest(SubContractorActions.getAssignedSubContractor.type,getAssignedSubContractor),
       
    ]);
}
