import { createSlice } from "@reduxjs/toolkit";

export const SubContractorSlice = createSlice({
    name: "subContractor",
    initialState: {
        getSubContractorByIdListing: [],
        getSubContractorListData: [],
        dataByPancard: {},
        assignedSubContractor: [],
        allSubcontractorListpagination: {},
        assignedSubcontractorListPagination: {},
        role: {}
    },
    reducers: {
        createSubContractor: () => {},
        deleteSubContractor: () => {},
        getSubContractorById: () => {},
        getSubContractorList: () => {},
        updateSubContractor: () => {},
        getSubContractorListSuccess: (state,action) => {
            state.getSubContractorListData = action.payload.data
            state.allSubcontractorListpagination = action.payload.pagination
            state.role = action.payload.role
         },
        getSubContractorByIdSucess: (state,action) => {
            state.getSubContractorByIdListing = action.payload
         },
        uploadSubcontractorDocument: () => {},
        assignSubContractor: () => {},
        getDataByPancard: () => {},
        getDataByPancardSuccess: (state,action) => {
            state.dataByPancard = action.payload
        },
        getAssignedSubContractor: () => {},
        getAssignedSubContractorSuccess: (state,action) => {
            state.assignedSubContractor = action.payload
            state.assignedSubcontractorListPagination = action.payload.pagination
        }

    }
});

export default SubContractorSlice;