import React, { useState,useEffect } from 'react'
import {Add,Delete, Edit} from '@mui/icons-material';
import { Typography,Card,Box, Table, TableHead, TableRow, TableCell,TableBody, IconButton, Hidden} from '@mui/material';
import PropTypes from "prop-types";
import DialogConfirm from 'components/DialogConfirm';
import { useHistory, useParams } from 'react-router-dom';
import { GeneralActions, LabourActions } from 'slices/actions';
import { GeneralSelector, LabourSelector } from 'selectors';
import {toast} from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import AddDiseaseForm from './AddDiseaseDialog';
import { format } from 'date-fns';

EducationListing.propTypes = {
  labourDetails: PropTypes.object,
};
function EducationListing (props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {labourDetails} = props
  const [openDiseaseForm,setOpenDiseaseForm] = useState(false);
  const [confirmDelete,setConfirmDelete] = useState(false);
  const [selected, setSelected] = useState(null);
  const {id} = useParams();
  const success = useSelector(GeneralSelector.success(LabourActions.createLabourDiseaseReason.type));
  const error = useSelector(GeneralSelector.error(LabourActions.createLabourDiseaseReason.type));
  const deleteSuccess = useSelector(GeneralSelector.success(LabourActions.deleteDisease.type));
  const deleteError = useSelector(GeneralSelector.error(LabourActions.deleteDisease.type));
  const diseaseList = useSelector(LabourSelector.getLabourDiseaseListing())
  useEffect(() => {
    if (id) {
      const params = {
        companyId: labourDetails?.result?.companyId,
        projectId: labourDetails?.result?.projectId,
        subContractorId: labourDetails?.result?.subContractorId ,
        labourId: id
      }
      dispatch(LabourActions.getLabourDiseaseListing(params))
    }
  },[id])

useEffect(() => {
  if (success) {
      setSelected(null);
      setOpenDiseaseForm(false)
      toast.success(`${success?.message ?? "Success"}`, {
              position: "top-right",
              autoClose: 3000,
              closeOnClick: true,
          });

      dispatch(GeneralActions.removeSuccess(LabourActions.createLabourDiseaseReason.type));
      const params = {
        companyId: labourDetails?.result?.companyId,
        projectId: labourDetails?.result?.projectId,
        subContractorId: labourDetails?.result?.subContractorId ,
        labourId: id
      }
      dispatch(LabourActions.getLabourDiseaseListing(params))
     
  }
}, [success]);

useEffect(() => {
  if (error) {
    setSelected(null);
    setOpenDiseaseForm(false)
    toast.error(`${error?.message ?? "Error"}`, {
              position: "top-right",
              autoClose: 3000,
              closeOnClick: true,
          });

      dispatch(GeneralActions.removeError(LabourActions.createLabourDiseaseReason.type));
  }
}, [error]);

useEffect(() => {
  if (deleteSuccess) {
      setSelected(null);
      setConfirmDelete(false)
      toast.success(`${deleteSuccess?.message ?? "Success"}`, {
              position: "top-right",
              autoClose: 3000,
              closeOnClick: true,
          });

      dispatch(GeneralActions.removeSuccess(LabourActions.deleteDisease.type));
      const params = {
        companyId: labourDetails?.result?.companyId,
        projectId: labourDetails?.result?.projectId,
        subContractorId: labourDetails?.result?.subContractorId ,
        labourId: id
      }
      dispatch(LabourActions.getLabourDiseaseListing(params))
     
  }
}, [deleteSuccess]);

useEffect(() => {
  if (deleteError) {
    setSelected(null);
    setConfirmDelete(false)
    toast.error(`${deleteError?.message ?? "Error"}`, {
              position: "top-right",
              autoClose: 3000,
              closeOnClick: true,
          });

      dispatch(GeneralActions.removeError(LabourActions.createLabourDiseaseReason.type));
  }
}, [deleteError]);

const handleDelete = () => {
   const params = {
      id: selected,
    };
    dispatch(LabourActions.deleteDisease(params));
  };

return (
    <Card sx={{ position: "relative" }}>
      <Typography variant="h5" sx={{ mb: 4, fontWeight: '500' }}>
        Disease Listing
      </Typography>
      {labourDetails?.result?.status !== 99?(
        <>
         <Add
        onClick={ () => setOpenDiseaseForm(true) }
        fontSize="large"
        sx={{
          position: "absolute",  
          cursor: "pointer",
          right: "15px",
          top: "20px",
          color: "#3f7ed2",
        }}
      />
        </>
      ):null}
       <Box>
        <Table>
          {diseaseList && diseaseList.length !==0?(
              <>
              <TableHead>
              <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Disease Name</TableCell>
              <TableCell>Options</TableCell>
              </TableRow>
            </TableHead>
            </>
          ):(
           <Typography>No Data</Typography> 
          ) 
         } 
              
           <TableBody>
            {diseaseList && diseaseList.data && diseaseList.data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{item.date && format(new Date(item.date), 'dd/MM/yyyy')}</TableCell>
                  <TableCell align="left">{item.diseaseName}</TableCell>
                  <TableCell align="left">
                  <Hidden smDown>
                    <IconButton
                        onClick={() => {
                            setConfirmDelete(true);
                            setSelected(item._id);
                        }}
                      >
                          <Delete fontSize="small" />
                   
                      </IconButton>
                        <IconButton onClick={() => history.push(`/app/labour/diseaseDetails/${item._id}`)}>
                            <Edit className="text-primary" />
                            
                        </IconButton>
                      </Hidden>
                  </TableCell>         
                </TableRow>
                
              ))}
          </TableBody>
        </Table>
      </Box>
    
      <AddDiseaseForm
      onOpen={openDiseaseForm}
      onClose={() => setOpenDiseaseForm(false)}
      labourDetails={labourDetails} />

      <DialogConfirm
        title="Delete Data"
        content="Are you sure want to delete this data?"
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onSubmit={handleDelete}/>
    </Card>
)
}
export default EducationListing