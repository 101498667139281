import React, { useEffect } from "react";
import { Button, Dialog, DialogActions, Typography, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import Input from "components/Input";
import * as yup from "yup";
import SelectField from "components/SelectField";
import { ProjectActions, GeneralActions } from "slices/actions";
import { ProjectSelector } from "selectors/ProjectSelector";
import { useDispatch, useSelector } from "react-redux";
import { PropaneRounded } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GeneralSelector } from "selectors";


export default function ProjectExtensionDialog(props) {
    const { open, onClose, projectDetails, listingrefresh } = props;

    const { id } = useParams();
    const success = useSelector(GeneralSelector.success(ProjectActions.updateProject.type));
    // const [transactionapi, setTransactionapi] = useState(false)

    // useEffect(() => {
    //     if (transactionapi) {
    //         if (listingrefresh) {
    //             listingrefresh();
    //         }
    //     }
    // }, [transactionapi])


    useEffect(() => {
        if (success) {
            console.log("success", success)
            toast.success(`${success?.message}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });

            dispatch(GeneralActions.removeSuccess(ProjectActions.updateProject.type));
            dispatch(ProjectActions.getProjectById(id));
        }
    }, [success]);

    const validationSchema = yup.object({
        end_date: yup.string().required('End Date is required!'),
        payment_type: yup.string().required('Payment type is required!'),
        amount:
            yup.number().
                required('Amount is required!').
                test(
                    'Is positive?',
                    'The number must be greater than 0!',
                    (value) => value > 0
                )
    });
    const dispatch = useDispatch();
    const paymentCollectiontypes = useSelector(ProjectSelector.getPaymentAndDuration());

    useEffect(() => {
        dispatch(ProjectActions.getPaymentAndDuration())
    }, []);

    const formik = useFormik({
        initialValues: {
            // start_date: projectDetails.data?.project?.start_date ?? "",
            end_date: projectDetails.data?.project?.end_date ?? "",
            payment_type: "",
            amount: "",
            flag: "extension",
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = (values) => {
        const params = {
            id: projectDetails.data?.project?._id,
            ...values,
        };

        dispatch(ProjectActions.updateProject(params));
        listingrefresh();
        // setTransactionapi(true)
        formik.resetForm();
        onClose();

    }


    // const handleSubmit = (values) => {
    //     // const params = {
    //     //     id: projectDetails.data?.project?._id,
    //     //     ...values,
    //     // };

    //     // dispatch(ProjectActions.updateProject(params));
    //     console.log(values)
    //     formik.resetForm();
    //     onClose();
    // }

    ProjectExtensionDialog.propTypes = {
        open: PropTypes.bool,
        onSubmit: PropTypes.func,
        onClose: PropTypes.func,
        projectDetails: PropaneRounded.object,
        listingrefresh: PropTypes.func
    };

    const closeDialog = () => {
        onClose();
        formik.resetForm();
    }

    return (
        <Dialog open={open} >
            <form>
                <DialogTitle>
                    {/* Project Extend */}

                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container spacing={2}>
                            <Grid item lg={12} xs={12}>
                                <Input
                                    label="Extended Date"
                                    name='end_date'
                                    type='date'
                                    inputProps={{
                                        min: projectDetails.data?.project?.end_date.slice(0, 10)
                                    }}
                                    value={formik.values.end_date.slice(0, 10)}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.end_date) && Boolean(formik.errors.end_date)}
                                    helperText={formik.touched.end_date && formik.errors.end_date}
                                />
                            </Grid>
                            <Grid item lg={12} xs={12} >
                                <SelectField
                                    label="Invoice Generate Interval"
                                    name='payment_type'
                                    value={formik.values.payment_type}
                                    onChange={formik.handleChange}
                                    error={formik.touched.payment_type && Boolean(formik.errors.payment_type)}
                                    helperText={formik.touched.payment_type && formik.errors.payment_type}>

                                    {paymentCollectiontypes.paymenetcollection && paymentCollectiontypes.paymenetcollection.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.level}
                                        </MenuItem>
                                    ))}
                                </SelectField>
                            </Grid>
                            <Grid item lg={12} xs={12} className="forPricesymbol position-relative">
                                <Typography sx={{ fontSize: '15' }}>Amount</Typography>
                                <span className="rateSpan">₹</span>
                                <Input style={{ paddingLeft: '30px' }}
                                    // label="Amount"
                                    name='amount'
                                    value={formik.values.amount}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.amount) && Boolean(formik.errors.amount)}
                                    helperText={formik.touched.amount && formik.errors.amount}
                                />
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button onClick={formik.handleSubmit} sx={{ marginRight: '13px' }} autoFocus variant="contained" color="primary">
                        Update
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}