import {createSelector} from "@reduxjs/toolkit";

const subContractorSelector = (state) => state.subContractor;

const getSubContractorById = () => createSelector(
    subContractorSelector,
    subContractor => subContractor.getSubContractorByIdListing
);

const getSubContractorList = () => createSelector(
    subContractorSelector,
    subContractor => subContractor.getSubContractorListData
);
const getDataByPancard = () => createSelector(
    subContractorSelector,
    subContractor => subContractor.dataByPancard
);
const getAssignedSubcontractor = () => createSelector(
    subContractorSelector,
    subContractor => subContractor.assignedSubContractor
);
const assingSubcontractorpagination = () => createSelector(
    subContractorSelector,
    subContractor => subContractor.assignedSubcontractorListPagination
);
const allSubcontractorpagination = () => createSelector(
    subContractorSelector,
    subContractor => subContractor.allSubcontractorListpagination
);
const role = () => createSelector(
    subContractorSelector,
    subContractor => subContractor.role
);

export const SubContractorSelector = {
    getSubContractorById,
    getSubContractorList,
    getDataByPancard,
    getAssignedSubcontractor,
    assingSubcontractorpagination,
    allSubcontractorpagination,
    role
};