import React, {useState,useEffect} from "react";
import {
  Card,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Chip,
  TableBody,
 } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PageTitle from "components/PageTitle";
import { useParams } from "react-router-dom";
import SubContractorAssignDialog from "./SubContractorAssignDialog";
import { GeneralActions, SubContractorActions, UserActions} from "slices/actions";
import { GeneralSelector, UserSelector } from "selectors";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

function SubContractorAssign() {
   const { id } = useParams();
   const dispatch = useDispatch();
   const projectListing = useSelector(UserSelector.getUserById());
   const [openIdDialog,setOpenIdDialog] = useState(false);
   const success = useSelector(GeneralSelector.success(SubContractorActions.assignSubContractor.type));

useEffect(() => {
    if (id) {
        dispatch(UserActions.getUserById(id));
    }
  }, [id]);

  useEffect(() => {
    if (success) {
       toast.success(`${success?.message ?? "Success"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true
            });
  
        dispatch(GeneralActions.removeSuccess(SubContractorActions.assignSubContractor.type));
        dispatch(UserActions.getUserById(id));
       setOpenIdDialog(false);
      }
  }, [success]);

  const handleIdDialog = () => {
  // setOpenIdDialog(true)
}

  return (
    <>
    <PageTitle isBack={true} title="Assigned" />
    <Card sx={{ position: "relative" }}>
    
      <AddCircleOutlineIcon onClick={() => setOpenIdDialog(true)}
        fontSize="large"
        sx={{
          position: "absolute",
          cursor: "pointer",
          right: "15px",
          top: "20px",
          color: "#3f7ed2",
        }}
      />
     
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Project Name</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
                     {projectListing && projectListing.project_id && projectListing.project_id.map((item, i) => (
                        <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                           <TableCell component="th" scope="row">
                                {item.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                            <Chip
                                label={item.status === 2 ? 'Inactive' : 'Active'}
                                color={item.status === 2 ? 'default' : 'primary'} size='small'/>
                            </TableCell>
                          
                        </TableRow>
                    ))}  
                </TableBody>
        
        </Table>
      </Box>
      <SubContractorAssignDialog
        open={openIdDialog}
        onClose={() => setOpenIdDialog(false)}
        onSubmit={handleIdDialog}
      /> 
        
    </Card>
 </>
  );
}

export default SubContractorAssign;
