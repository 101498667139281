import React, {useEffect, useState} from "react";
import {
    Box, Card, Table, TableBody, TableCell, Grid, 
     Pagination,
     TableHead, TableRow,
     MenuItem,
     
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {GeneralSelector, ProjectSelector, SubContractorSelector} from "selectors";
import {CompanyActions,LabourActions, ProjectActions, SubContractorActions} from "slices/actions";
import {DefaultSort} from "constants/sort";
import ListSkeleton from "../../components/Skeleton/ListSkeleton";
import { LabourSelector } from "selectors/LabourSelector";
import SelectField from "components/SelectField";
import Input from "components/Input";
import { Constants } from "utils/constants";
import { CompanySelector } from "selectors/CompanySelector";
import styled from "@emotion/styled";


const FilterBox = styled(Box)(() => ({
  width: "100%",
  marginTop: 30,
  marginBottom: 20,
  display: "flex",
  justifyContent: "space-between"
}));
export default function AllEducationList() {
  const dispatch = useDispatch();
  const educationListData = useSelector(LabourSelector.getEducationList());
  const companyList = useSelector(CompanySelector.getCompanyList());
  const projectList = useSelector(ProjectSelector.getProjectList());
  const subContractorList = useSelector(SubContractorSelector.getSubContractorList());
  const loading = useSelector(GeneralSelector.loader(LabourActions.getAllSafetyTrainingList.type))
  const pagination = useSelector(LabourSelector.getPagination());
  const [filter, setFilter] = useState({
     sort: DefaultSort.newest.value,
     page: 1,
  });
const userType = localStorage.getItem('userType')
 const companyId = localStorage.getItem('companyID')
 const projectId = localStorage.getItem('projectID')
 const subcontractorId = localStorage.getItem('subcontractorID')
 const [company,setCompany] = useState();
 const [project,setProject] = useState();
 const [subcontractor,setSubcontractor] = useState();

 useEffect(() => {
     if (!companyId) {
     dispatch(CompanyActions.getCompanyList())
     }
 },[])
useEffect(() => {
 const params = {
     ...filter,
     company_id: companyId?companyId:"",
     project_id: project?project:"",
     subcontractor_id: subcontractorId?subcontractorId:"" 
 }
 dispatch(LabourActions.getEducationList(params));
}, [filter]);

const handleChangeFilter = ({ target }) => {
 const {name, value} = target;

 setFilter({
     ...filter,
     [name]: value
 });
}

const handleChangePagination = (e, val) => {
 setFilter({
     ...filter,
     page: val
 });
};


const handleChangeCompany = (e) => {
 const cId = e.target.value
 setCompany(cId)
 const subContractorparams = {
     data: null
 }
 dispatch(SubContractorActions.getSubContractorListSuccess(subContractorparams))
 if (cId) {
   const params = {
     company_id: cId,
     status: 1
   }
   dispatch(ProjectActions.getProjectList(params))
   const searchByCompany = {
        company_id: cId,
     }
     dispatch(LabourActions.getEducationList(searchByCompany));
 }
}
const handleChangeProject = (e) => {
 const pId = e.target.value
 setProject(pId)
 if (pId) {
 const params = {
     companyId: company || companyId,
     projectId: pId,
     is_subcontractor_user: true
 }
 dispatch(SubContractorActions.getSubContractorList(params))
 const searchByProject = {
     company_id: company || companyId,
     project_id: pId
  }
  dispatch(LabourActions.getLabourList(searchByProject));
 }
}

const handleChangeSubcontractor = (e) => { 
 const sId = e.target.value
 if (sId) {
 setSubcontractor(sId)
 const searchBySubcontractor = {
     company_id: company || companyId,
     project_id: project || projectId ,
     subcontractor_id: sId
  }
  dispatch(LabourActions.getEducationList(searchBySubcontractor));
 }
}


  return (
    <Card>
    <Typography variant="h5" sx={{ fontWeight: 600, position: 'relative' }}>Labour Education List</Typography>

   <FilterBox>
      <Grid container spacing={3} justifyContent="space-between">
          <Grid item lg={4} sm={12} xs={12}>
              <Input
                  label="Search"
                  name='keyword'
                  placeholder='Search by name'
                  value={filter.keyword ?? ''}
                  onChange={handleChangeFilter}/>
          </Grid>
          {userType === Constants.SUPERADMIN ?(
            <Grid item lg={4} sm={12} xs={12}>
              <Typography sx={{fontSize: 15}}>Company</Typography>
              <SelectField
                  value={company}
                  onChange={handleChangeCompany}
                  >
                  {companyList && companyList.map((item,index) => (
                  <MenuItem key={index} value={item._id}>
                          {item.name}
                  </MenuItem>
                  ))}
              </SelectField> 
            </Grid>
          ):null
          }
          {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER ?(
            <Grid item lg={4} sm={12} xs={12}>
              <Typography sx={{fontSize: 15}}>Project</Typography>
              <SelectField
                  value={project}
                  onChange={handleChangeProject}
                  >
                  {projectList && projectList.map((item,index) => (
                  <MenuItem key={index} value={item._id}>
                          {item.name}
                  </MenuItem>
                  ))}
              </SelectField> 
            </Grid>
          ):null
          }
          {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER || userType === Constants.PROJECT_USER?(
            <Grid item lg={4} sm={12} xs={12}>
              <Typography sx={{fontSize: 15}}>Subcontractor</Typography>
              <SelectField
                  value={subcontractor}
                  onChange={handleChangeSubcontractor}
                  >
                  {subContractorList && subContractorList.map((item,index) => (
                  <MenuItem key={index} value={item._id}>
                          {item.subContractorName}
                  </MenuItem>
                  ))}
              </SelectField> 
            </Grid>
          ):null
          }
          <Grid item lg={2} sm={12} xs={12}>
              <Typography sx={{fontSize: 15}}>Sort by</Typography>
              <SelectField
                  value={filter.sort}
                  name="sort"
                  onChange={handleChangeFilter}>
                  {Object.keys(DefaultSort).map((key) => (
                      <MenuItem key={key} value={DefaultSort[key].value}>
                          {DefaultSort[key].name}
                      </MenuItem>
                  ))}
              </SelectField> 
          </Grid>
      </Grid>
     
  </FilterBox>
 

    {loading ? (
        <ListSkeleton/>
    ) : (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                            <TableCell>Passing Year</TableCell>
                            <TableCell>Institute</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {educationListData && educationListData.data && educationListData.data.length === 0 && (
                        <TableRow>
                            <TableCell align="center" colSpan={7}>
                                No Data
                            </TableCell>
                        </TableRow> 
                    )}
                    {educationListData && educationListData.data && educationListData.data.map((item, i) => (
                        <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                                <TableCell>{item.higherEducation}</TableCell>
                                <TableCell>{item.passingyear}</TableCell>
                                <TableCell>{item.institute}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Pagination
                sx={{ mt: 1 }}
                page={filter.page}
                count={pagination.pages}
                onChange={handleChangePagination}/>
        </Box>
    )}

      
</Card>
  )
}
