import React, {useEffect,useState} from "react";
import {
    Box, Button, Card, Grid,
    Table,TableHead, TableRow,TableCell,TableBody,Checkbox,Typography, MenuItem
} 
from "@mui/material";
import PageTitle from "components/PageTitle";
import {useParams,useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import { DesignationSelector, GeneralSelector, ProjectSelector} from "selectors";
import {CompanyActions, DesignationActions, GeneralActions, ProjectActions} from "slices/actions";
import Input from "components/Input";
import FormSkeleton from "../../components/Skeleton/FormSkeleton";
import { CompanySelector } from "selectors/CompanySelector";
import { Constants } from "utils/constants";
import SelectField from "components/SelectField";


export default function FormDesignation() {
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [errObj,setErrObj] = useState({});  
    const designationFormList = useSelector(DesignationSelector.getDesignationsFormList());
    const [designation,setDesignation] = useState();
    const loading = useSelector(GeneralSelector.loader(DesignationActions.getDesignationFormList.type));
    const userType = localStorage.getItem('userType')
    const companyId = localStorage.getItem('companyID')
    const projectId = localStorage.getItem('projectID')
    const companyList = useSelector(CompanySelector.getCompanyList());
    const projectList = useSelector(ProjectSelector.getProjectList());
    // const actions = [
    //     DesignationActions.createDesignation.type,
    //     DesignationActions.updateDesignation.type
    // ];
    const success = useSelector(GeneralSelector.success(DesignationActions.createDesignation.type,));
    const error = useSelector(GeneralSelector.error(DesignationActions.createDesignation.type,));
    const updateSuccess = useSelector(GeneralSelector.success(DesignationActions.updateDesignation.type,));
    const updateError = useSelector(GeneralSelector.error(DesignationActions.updateDesignation.type,));
    const [designationCreateForm,setDesignationCreateForm] = useState({
        companyId: companyId?companyId:"",
        projectId: projectId?projectId:"",
        name: ""
      })
     useEffect(() => {
        if (!companyId) {
            dispatch(CompanyActions.getCompanyList());
         }
    },[])  
   useEffect(() => {
        if (designationCreateForm.companyId || companyId) {
            const params = {
                company_id: designationCreateForm.companyId ? designationCreateForm.companyId:companyId,
                status: 1
            }
            dispatch(ProjectActions.getProjectList(params))
        }
    },[designationCreateForm.companyId])

   const handleChangeCompany = (e) => {
    delete errObj.companyId;
    setErrObj({...errObj});
    designationCreateForm.companyId = e.target.value;
    designationCreateForm.projectId = "";
    setDesignationCreateForm({...designationCreateForm})
   
 }   
  const handleChangeProject = (e) => {
    delete errObj.projectId;
    setErrObj({...errObj});
    if (designationCreateForm.companyId) {
    const pId = e.target.value
    designationCreateForm.projectId = pId;
    setDesignationCreateForm({...designationCreateForm})
    } 
  }  
    useEffect(() => {
        if (designationFormList) {
            if (id) {
            setDesignation(designationFormList.role) 
            designationCreateForm.name = designationFormList.name
            designationCreateForm.companyId = designationFormList.companyId
            designationCreateForm.projectId = designationFormList.projectId
            setDesignationCreateForm({...designationCreateForm})

            } else {
            setDesignation(designationFormList) 
         }
        } 

    },[designationFormList]);

    useEffect(() => {
        if (id) {
         dispatch(DesignationActions.getDesignationFormById(id))
        } else {
         dispatch(DesignationActions.getDesignationFormList())
        }
    }, []);

    useEffect(() => {
        if (updateError) {
             toast.error(`${updateError?.message ?? "Error"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: false,
                pauseOnFocusLoss: false
                });

            dispatch(GeneralActions.removeError(DesignationActions.updateDesignation.type));
         }
    }, [updateError]);

    useEffect(() => {
        if (error) {
             toast.error(`${error?.message ?? "Error"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: false,
                pauseOnFocusLoss: false
                });

            dispatch(GeneralActions.removeError(DesignationActions.createDesignation.type));
         }
    }, [error]);

    useEffect(() => {
        if (updateSuccess) {
             toast.success(`${updateSuccess?.message ?? "Success"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: false,
                pauseOnFocusLoss: false
                });

            dispatch(GeneralActions.removeSuccess(DesignationActions.updateDesignation.type));
         }
    }, [updateSuccess]);

    useEffect(() => {
        if (success) {
             toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                    pauseOnHover: false,
                    pauseOnFocusLoss: false
                });

            dispatch(GeneralActions.removeSuccess(DesignationActions.createDesignation.type));
            history.push('/app/designation')

        }
    }, [success]);

  const handleChangeCheck = (i,e,index) => {
    const newFormList = [...designation];
    const newItem = [...newFormList[index].role];
    const newObject = {
        "lebel": newItem[i].lebel,
         "isAllow": e.target.checked,
         "id": newItem[i].id,
    }
    newItem[i] = newObject;
    const newData = {
        name: newFormList[index].name,
        role: newItem
    }
    newFormList[index] = newData;
    setDesignation(newFormList)
}
const validateForm = () => {
    let valid = true
        if (!designationCreateForm.companyId) {
           errObj.companyId = 'This field is Required';
           valid = false;
        }
        if (!designationCreateForm.projectId) {
            errObj.projectId = 'This field is Required';
            valid = false;
        }
        if (!designationCreateForm.name) {
            errObj.name = 'This field is Required';
            valid = false;
        }
    setErrObj({...errObj});
    return valid; 
  }
const createRole = () => {
    if (validateForm()) {
    const params ={
        role: JSON.stringify(designation),
        ...designationCreateForm
    }
    dispatch(DesignationActions.createDesignation(params))
  } 
}
const updateRole = () => {
    const params ={
        id: id,
        role: JSON.stringify(designation),
        ...designationCreateForm
    }
    dispatch(DesignationActions.updateDesignation(params))
}

// window.scrollTo({
//     top: 0,
//     behavior: 'smooth',
// });
return (
        <Box>
            <PageTitle isBack={true} title={`${id ? 'Update' : 'Create'} Designation`}/>

            <Card>
                {loading ? (
                    <FormSkeleton/>
                ) : (
                        <Grid container spacing={3}>
                            <Grid item lg={12} xs={12}>
                                <Input
                                    label="Name"
                                    placeholder='Enter Role Name'
                                    value = {designationCreateForm.name}
                                    onChange = {(e) => {
                                        delete errObj.name;
                                        setErrObj({...errObj})
                                        designationCreateForm.name = e.target.value
                                        setDesignationCreateForm({...designationCreateForm})
                                    }}
                                  />
                                    {errObj.name && <Typography sx={{color: 'red',fontSize: 12}}>{errObj.name}</Typography>}
                            </Grid>
                            {userType !== Constants.COMPANY_USER && userType !== Constants.PROJECT_USER?(
                                <Grid item lg={6} sm={12} xs={12}>
                                        <Typography sx={{fontSize: 15}}>Company Name</Typography>
                                        <SelectField
                                            value={designationCreateForm.companyId}
                                            onChange={handleChangeCompany}
                                            >
                                            {companyList && companyList.map((item,index) => (
                                            <MenuItem key={index} value={item._id}>
                                                    {item.name}
                                            </MenuItem>
                                            ))}
                                        </SelectField> 
                                        {errObj.companyId ? <Typography sx={{color: 'red',fontSize: 12}}>{errObj.companyId}</Typography>:null}
                                     </Grid>
                            ):""}
                   {userType !== Constants.PROJECT_USER?(
                        <Grid item lg={6} sm={12} xs={12}>
                            <Typography sx={{fontSize: 15}}>Project Name</Typography>
                            <SelectField
                                value={designationCreateForm.projectId}
                                onChange={handleChangeProject}
                                >
                                {(designationCreateForm.companyId && projectList) && projectList.map((item,index) => (
                                <MenuItem key={index} value={item._id}>
                                        {item.name}
                                </MenuItem>
                                ))}
                            </SelectField> 
                            {errObj.projectId && <Typography sx={{color: 'red',fontSize: 12}}>{errObj.projectId}</Typography>}
                        </Grid> 
             ):""}      
                           
                            <Grid item lg={12} xs={12}>
                                <Table sx={{mb: 2}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width='30%'>Module</TableCell>
                                            <TableCell>Permission</TableCell> 
                                        </TableRow>
                                    </TableHead>
                                   
                                    {designation && designation.map((item,index) => (
                                    <TableBody className="text-600 ForCheckbox" sx={{backgroundColor: '#f5f5f5', color: '#a6b0bb'}} key={index}>
                                      
                                        <TableCell width='30%'>
                                          {item.name}
                                        </TableCell>
                                        <TableCell width='70%'>
                                            {item.role && item.role.map((subItem, i) => (
                                                <span key={i} className="chipchkspan">
                                                    {subItem.lebel}
                                                    <Checkbox
                                                        checked= {subItem.isAllow}
                                                        onChange={(e) => handleChangeCheck(i,e,index)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                </span>
                                            ))}
                                        </TableCell>
                                    </TableBody>
                                   ))}
                                </Table>
                            </Grid>
                          
                            <Grid item container justifyContent="flex-end">
                               { id ? (
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    onClick = {updateRole}
                                    >
                                    Update
                                </Button>):(
                                <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                onClick = {createRole}
                                >
                                Submit
                            </Button>)
                                }
                            </Grid>
                        </Grid>
                )}
            </Card>
        </Box>
    )
}