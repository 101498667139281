import React, { useEffect, useState } from "react";
import {
  // Button,
  //  Grid, IconButton, InputAdornment,
  Card,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Box,
  Typography,
  // ListItemIcon,
  // MenuItem
     Hidden,
     IconButton,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useHistory, useParams } from "react-router-dom";
import DialogConfirm from "components/DialogConfirm";
import { GeneralActions, SiteActions } from "slices/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GeneralSelector, SiteSelector } from "selectors";
import {Delete, Edit} from "@mui/icons-material";
function ProjectSiteListing() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const success = useSelector(GeneralSelector.success(SiteActions.deleteSite.type));
  const siteListing = useSelector(SiteSelector.getSiteById());

  const handleDelete = () => {
    dispatch(SiteActions.deleteSite(selected));
  };
  useEffect(() => {
    dispatch(SiteActions.getSiteById(id));
  }, []);

  useEffect(() => {
    if (success) {
      setConfirmDelete(false);
      setSelected(null);

      toast.success(`${success?.message ?? "Success"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });

      dispatch(GeneralActions.removeSuccess(SiteActions.deleteSite.type));
      dispatch(SiteActions.getSiteById(id));
    }
  }, [success]);
  return (
    <Card sx={{ position: "relative" }}>
      <Typography variant="h5" sx={{ mb: 4 }}>
        Site Listing
      </Typography>
      
        <AddCircleOutlineIcon
        onClick={() => history.push(`/app/site/create/`)}
        fontSize="large"
        sx={{
          position: "absolute",
          cursor: "pointer",
          right: "15px",
          top: "20px",
          color: "#3f7ed2",
        }}
      />
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Options</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {siteListing &&
              siteListing.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{item.name}</TableCell>
                  <TableCell align="left">{item.address}</TableCell>
                  <TableCell align="left">
                    <Hidden smDown>
                        <IconButton onClick={() => history.push(`/app/site/detail/${item._id}`)}>
                            <Edit className="text-primary" />
                        </IconButton>
                        <IconButton onClick={() => {
                            setConfirmDelete(true);
                            setSelected(item._id);
                        }}>
                            <Delete className="text-default" />
                        </IconButton>
                    </Hidden>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      <DialogConfirm
        title="Delete Site"
        content="Are you sure want to delete this site?"
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onSubmit={handleDelete}
      />
    </Card>
  );
}

export default ProjectSiteListing;
