import React ,{useEffect} from "react";
import {
    Card,
    Grid,
    Typography, Button
} from "@mui/material";
import Input from "components/Input";
import { useFormik } from "formik";
import { GeneralActions, ProjectActions } from "slices/actions";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { GeneralSelector } from "selectors";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import { useParams } from "react-router-dom";
import * as yup from "yup";

LabourLicenceDetails.propTypes = {
    projectDetails: PropTypes.object,
};

export default function LabourLicenceDetails(props) {
    const { projectDetails } = props;
    const dispatch = useDispatch();
    const {id} = useParams();
    const success = useSelector(GeneralSelector.success(ProjectActions.updateProject.type));
    const error = useSelector(GeneralSelector.error(ProjectActions.updateProject.type));
    const loading = useSelector(GeneralSelector.loader(ProjectActions.updateProject.type));

    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(ProjectActions.updateProject.type));
            dispatch(ProjectActions.getProjectById(id));
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeError(ProjectActions.updateProject.type));
        }
    }, [error]);

    const validationSchema = yup.object({
        scopeOfLabourLicence: yup.string().required('This field is required'),
        labourLicenceNo: yup.string().required('This field is required'),
        labourLicenceValidity: yup.string().required('This field is required'),
    });  


    const formik = useFormik({
        initialValues: {
            scopeOfLabourLicence: projectDetails.data?.project?.scopeOfLabourLicence ?? "",
            labourLicenceNo: projectDetails.data?.project?.labourLicenceNo ?? "",
            labourLicenceValidity: projectDetails.data?.project?.labourLicenceValidity ?? "",
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        validateOnChange: true,
        onSubmit: (values) => {
          handleSubmit(values);
        },
    });
    const handleSubmit = (values) => {
     const params ={
            id: id,
            flag: "laboureLicenceDetails",
            ...values
        }
        dispatch(ProjectActions.updateProject(params))
    };

    return (
        <Card sx={{ mb: 3 }}>
            <Typography variant='h5' sx={{ mb: 4 }}>Labour Licence Details</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Scope of Labour Licence"
                            name='scopeOfLabourLicence'
                            value={formik.values.scopeOfLabourLicence}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.scopeOfLabourLicence) && Boolean(formik.errors.scopeOfLabourLicence)}
                            helperText={formik.touched.scopeOfLabourLicence && formik.errors.scopeOfLabourLicence}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Labour Licence No"
                            name='labourLicenceNo'
                            value={formik.values.labourLicenceNo}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.labourLicenceNo) && Boolean(formik.errors.labourLicenceNo)}
                            helperText={formik.touched.labourLicenceNo && formik.errors.labourLicenceNo}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label='Labour Licence Validity'
                            name='labourLicenceValidity'
                            type='date'
                            value={formik.values.labourLicenceValidity}
                            onChange={formik.handleChange}
                            error={formik.touched.labourLicenceValidity && Boolean(formik.errors.labourLicenceValidity)}
                            helperText={formik.touched.labourLicenceValidity && formik.errors.labourLicenceValidity}
                        />
                    </Grid>
                    <Grid container justifyContent="flex-end" sx={{mt: 2}}>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {loading ? (
                            <CircularProgress color="inherit" size={20} />
                            ) : (
                            <>Submit</>
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Card>
    )
}