import React,{useState,useEffect} from "react";
import {
    Grid, Button, Typography,MenuItem,CircularProgress
} from "@mui/material";
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import SelectField from "components/SelectField";
import PropTypes from 'prop-types';
import { useDispatch, useSelector} from "react-redux";
import { Constants } from "utils/constants";
import { CompanyActions, GeneralActions, ProjectActions, SubContractorActions } from "slices/actions";
import { CompanySelector } from "selectors/CompanySelector";
import { GeneralSelector, ProjectSelector, SubContractorSelector } from "selectors";
import { toast } from "react-toastify";


SubcontractorAndProjectAssign.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
 
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
  },
}));


function SubcontractorAndProjectAssign (props) {
  const { open, onClose } = props
  const dispatch = useDispatch();
  const userType = localStorage.getItem('userType');
  const companyId = localStorage.getItem('companyID');
  const projectId = localStorage.getItem('projectID')
  const companyList = useSelector(CompanySelector.getCompanyList());
  const projectList = useSelector(ProjectSelector.getProjectList());
  const subContractorList = useSelector(SubContractorSelector.getSubContractorList());
  const success = useSelector(GeneralSelector.success(SubContractorActions.assignSubContractor.type));
  const error = useSelector(GeneralSelector.error(SubContractorActions.assignSubContractor.type));
  const [subcontractorAssign,setSubcontractorAssign] = useState({
      company: companyId?companyId:"",
      project: projectId?projectId:"",
      subContractor: ""
    })
 const [errObj,setErrObj] = useState({});  
 const loading = useSelector(GeneralSelector.loader(SubContractorActions.assignSubContractor.type));
 useEffect(() => {
  if (!companyId) {
      dispatch(CompanyActions.getCompanyList());
  } else if (companyId && !projectId) {
    const params = {
      company_id: subcontractorAssign.company ? subcontractorAssign.company: companyId,
      status: 1
   }
    dispatch(ProjectActions.getProjectList(params))
  } else if (projectId) {
    const params = {
      is_subcontractor_user: true
    }  
      dispatch(SubContractorActions.getSubContractorList(params))
  }
  },[])

  useEffect(() => {
      if (subcontractorAssign.company || companyId) {
          const params = {
              company_id: subcontractorAssign.company ? subcontractorAssign.company: companyId,
              status: 1
         }
          
          dispatch(ProjectActions.getProjectList(params))
      }
  },[subcontractorAssign.company])

  useEffect(() => {
    if (subcontractorAssign.project) {
        const params = {
              is_subcontractor_user: true
         }
        
        dispatch(SubContractorActions.getSubContractorList(params))
    }
},[subcontractorAssign.project])

  const handleChangeCompany = (e) => {
      delete errObj.company;
      setErrObj({...errObj});
      const cId = e.target.value;
      subcontractorAssign.company = cId;
      setSubcontractorAssign({...subcontractorAssign})
   }   
    const handleChangeProject = (e) => {
      delete errObj.project;
      setErrObj({...errObj});
      if (subcontractorAssign.company) {
      const pId = e.target.value
      subcontractorAssign.project = pId;
      setSubcontractorAssign({...subcontractorAssign})
      } 
    }
   const handleChangeSubcontractor = (e) => { 
     delete errObj.subContractor;
     setErrObj({...errObj});
    if (subcontractorAssign.project) {
      const sId = e.target.value
      subcontractorAssign.subContractor = sId;
      setSubcontractorAssign({...subcontractorAssign})
      } 

   }
   const onSubmit = () => {
      if (validateForm()) {
      const params = {
          companyId: subcontractorAssign.company || companyId,
          projectId: subcontractorAssign.project || projectId,
          subContractorId: subcontractorAssign.subContractor
      }
      dispatch(SubContractorActions.assignSubContractor(params))
      }
  }

  useEffect(() => {
    if (success) {
     toast.success(`${success?.message ?? "Success"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });

      dispatch(GeneralActions.removeSuccess(SubContractorActions.assignSubContractor.type));
      subcontractorAssign.company = ""
      subcontractorAssign.project = ""
      subcontractorAssign.subContractor = ""
      setSubcontractorAssign({...subcontractorAssign})
      onClose();
    }
  }, [success]);

  useEffect(() => {
    if (error) {
     toast.error(`${error?.message ?? "Error"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });

      dispatch(GeneralActions.removeError(SubContractorActions.assignSubContractor.type));
      subcontractorAssign.company = ""
      subcontractorAssign.project = ""
      subcontractorAssign.subContractor = ""
      setSubcontractorAssign({...subcontractorAssign})
      onClose();
    }
  }, [error]);

  const validateForm = () => {
    let valid = true
    if (!subcontractorAssign.company) {
      errObj.company = 'This field is Required';
      valid = false;
    }
    if (!subcontractorAssign.project) {
        errObj.project = 'This field is Required';
        valid = false;
      }
      if (!subcontractorAssign.subContractor) {
        errObj.subContractor = 'This field is Required';
        valid = false;
      } 
    setErrObj({...errObj});
    return valid; 
  }
  // const onCloseDialog = () => {
  //   subcontractorAssign.company = ""
  //   subcontractorAssign.project = ""
  //   subcontractorAssign.subContractor = ""
  //   setSubcontractorAssign({...subcontractorAssign})
  //   onClose();
  // }

  return (
    <div className="IdcardDialog">
    <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
    >
        <DialogTitle id="customized-dialog-title" onClose={onClose} sx={{ borderBottom: '1px solid #ccc' }}>
           Assign Subcontractor
        </DialogTitle>
        <DialogContent>
                <Grid container spacing={2} sx={{ pt: 4 }}>
                    {userType === Constants.SUPERADMIN?(
                    <Grid item lg={6} sm={12} xs={12}>
                        <Typography variant='h6' sx={{fontSize: 15}}>Company</Typography>
                        <SelectField
                            value={subcontractorAssign.company}
                            onChange={handleChangeCompany}
                            >
                              {companyList && companyList.map((item,index) => (
                                <MenuItem value={item._id} key={index}>
                                    {item.name}
                                </MenuItem>
                              ))}
                        </SelectField>
                        {errObj.company && <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>}
                    </Grid>
                     ):null} 
                  {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER?(
                    <Grid item lg={6} sm={12} xs={12}>
                        <Typography variant='h6' sx={{fontSize: 15}}>Project</Typography>
                        <SelectField
                            value={subcontractorAssign.project}
                            onChange={handleChangeProject}
                            >
                           {(subcontractorAssign.company && projectList) && projectList.map((item,index) => (
                                <MenuItem value={item._id} key={index}>
                                    {item.name}
                                </MenuItem>
                              ))}
                        </SelectField>
                        {errObj.project && <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>}
                     </Grid>
                      ):null} 
                    {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER || userType === Constants.PROJECT_USER?(
                        <Grid item lg={6} sm={12} xs={12}>
                        <Typography variant='h6' sx={{fontSize: 15}}>SubContractor</Typography>
                        <SelectField
                            value={subcontractorAssign.subContractor}
                            onChange={handleChangeSubcontractor}
                            >
                           {(subcontractorAssign.project && subContractorList) && subContractorList.map((item,index) => (
                                <MenuItem value={item._id} key={index}>
                                    {item.subContractorName}
                                </MenuItem>
                              ))}
                        </SelectField>
                        {errObj.subContractor && <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>}
                     </Grid>
                      ):null} 
                </Grid>
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={onClose} variant="contained" color="primary">
                Close
            </Button>
            <Button autoFocus onClick={onSubmit} variant="contained" color="primary">
                    {loading?<CircularProgress color="inherit" size={20}/>:<>Submit</>}
            </Button>
        </DialogActions>
    </BootstrapDialog>
</div>
  )
}

export default SubcontractorAndProjectAssign;