import React ,{useEffect} from "react";
import {
    Card,
    Grid,
    Typography, Button
} from "@mui/material";
import Input from "components/Input";
import { useFormik } from "formik";
import { GeneralActions, SubContractorActions } from "slices/actions";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { GeneralSelector } from "selectors";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import { useParams } from "react-router-dom";
import * as yup from "yup";

BocwDetails.propTypes = {
    subcontractorDetails: PropTypes.object,
};

export default function BocwDetails(props) {
    const { subcontractorDetails } = props;
    const dispatch = useDispatch();
    const {id} = useParams();
    const success = useSelector(GeneralSelector.success(SubContractorActions.updateSubContractor.type));
    const error = useSelector(GeneralSelector.error(SubContractorActions.updateSubContractor.type));
    const loading = useSelector(GeneralSelector.loader(SubContractorActions.updateSubContractor.type));

    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(SubContractorActions.updateSubContractor.type));
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(SubContractorActions.updateSubContractor.type));
        }
    }, [error]);
    const validationSchema = yup.object({
        scopeOfBocw: yup.string().required("This field is required"),
        bocwValidUpto: yup.string().required("This field is required"),
    })

  const formik = useFormik({
        initialValues: {
            scopeOfBocw: subcontractorDetails?.scopeOfBocw??"",
            bocwValidUpto: subcontractorDetails?.bocwValidUpto??"",
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
          handleSubmit(values);
        },
    });
    const handleSubmit = (values) => {
        const params ={
            id: id,
            ...values
        }
        dispatch(SubContractorActions.updateSubContractor(params))
    };

    return (
        <Card sx={{ mb: 3 }}>
            <Typography variant='h5' sx={{ mb: 4 }}>BOCW</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Scope Of BOCW"
                            name='scopeOfBocw'
                            value={formik.values.scopeOfBocw}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.scopeOfBocw) && Boolean(formik.errors.scopeOfBocw)}
                            helperText={formik.touched.scopeOfBocw && formik.errors.scopeOfBocw}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label='BOCW Valid Upto'
                            name='bocwValidUpto'
                            type='date'
                            value={formik.values.bocwValidUpto}
                            onChange={formik.handleChange}
                            error={formik.touched.bocwValidUpto && Boolean(formik.errors.bocwValidUpto)}
                            helperText={formik.touched.bocwValidUpto && formik.errors.bocwValidUpto}
                        />
                    </Grid>
                    <Grid container justifyContent="flex-end" sx={{mt: 2}}>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {loading ? (
                            <CircularProgress color="inherit" size={20} />
                            ) : (
                            <>Submit</>
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Card>
    )
}