import {get, post,patch,del} from "../utils/api";

const API_URL = process.env.REACT_APP_API_URL 


const CreateLabour = async (params) => post(`${API_URL}/labour`,params);

const LabourList = async (params) => get(`${API_URL}/labour`,params);

const LabourHealthFormList = async (id) => get(`${API_URL}/medical/medicalInfo/${id}`);

const CreateLabourHealth = async (params) => post(`${API_URL}/medical`,params);

const GetLabourById = async (id) => get(`${API_URL}/labour/${id}`);

const UpdateLabourBasicInfo = async (id,params) => patch(`${API_URL}/labour/${id}`, params);

const UpdateLabourEmergencyContact = async (id,params) => patch(`${API_URL}/labour/emergencyContact/${id}`, params);

const UpdateLabourEducation = async (id,params) => patch(`${API_URL}/labourEducation/${id}`, params);

const UpdateLabourBankDetails = async (id,params) => patch(`${API_URL}/labour/bank/${id}`, params);

const UpdateLabourPhoto = async (id,params) => patch(`${API_URL}/labour/logoUpload/${id}`, params);

const UpdateLabourAdharCard = async (id,params) => patch(`${API_URL}/labour/adharCardUpload/${id}`, params);

const UpdateLabourRationCard = async (id,params) => patch(`${API_URL}/labour/rationCardUpload/${id}`, params);

const UpdateLabourPanCard = async (id,params) => patch(`${API_URL}/labour/panCardUpload/${id}`, params);

const UpdateLabourPassport = async (id,params) => patch(`${API_URL}/labour/passportUpload/${id}`, params);

const UpdateHealthDocument = async (id,params) => patch(`${API_URL}/medical/medicaldocsUpload/${id}`, params);

const LabourBulkUpload = async (params) => patch(`${API_URL}/labour/bulkUpload/`, params);

const GetLabourByAdharCard = async (params) => post(`${API_URL}/labour/checkAadharNumberExist/`, params);

const DeleteLabour = async (id) => del(`${API_URL}/labour/${id}`);

const UploadLabourDocument = async (params) => post(`${API_URL}/labour/bulkUpload`, params);

const UploadLabourCompilance = async (params) => post(`${API_URL}/labourCompliance/`, params);

const FetchLabourCompilanceList = async (params) => get(`${API_URL}/labourCompliance/`,params)

const UpdateLabourPFBasicInfo = async (id,params) => patch(`${API_URL}/labour/PFInformation/${id}`, params)

const UpdateLabourDailyWages = async (id,params) => patch(`${API_URL}/labour/PFInformation/${id}`, params)

const UpdateLabourMonthlyWages = async (id,params) => patch(`${API_URL}/labour/monthlySalaryUpdate/${id}`, params)

const SafetyTrainingList = async (id) => get(`${API_URL}/labourSafetyTraining/${id}`);

const FetchLabourTrainingType = async () => get(`${API_URL}/labourSafetyTraining/masterTrainingSkillType`);

const CreateLabourSafetyTraining = async (params) => post(`${API_URL}/labourSafetyTraining`,params);

const ExitLabour = async (id,params) => patch(`${API_URL}/labour/labourExit/${id}`,params);

const AllSafetyTrainingList = async (params) => get(`${API_URL}/labourSafetyTraining`,params);

const GetEducationListById = async (id,params) => get(`${API_URL}/labourEducation/${id}`,params);

const GetEducationList = async (params) => get(`${API_URL}/labourEducation`,params);

const DeleteEducationById = async (id,params) => del(`${API_URL}/labourEducation/${id}`,params);

const GetSkillCategory = async () => get(`${API_URL}/labourSafetyTraining/masterSkillInfo`);

const GetSafetyTrainingDetails = async (id) => get(`${API_URL}/labourSafetyTraining/LabourSafetyTraining/${id}`);

const DeleteSafetyTraing = async (id) => del(`${API_URL}/labourSafetyTraining/${id}`);

const UpdateSafetyTraing = async (id,params) => patch(`${API_URL}/labourSafetyTraining/${id}`,params);

const UpdateSkillInfo = async(id,params) => patch(`${API_URL}/labour/labourSkillType/${id}`,params);

const GetLabourEducationDeatils = async(id) => get(`${API_URL}/labourEducation/educationDetails/${id}`);

const AddLabourEducation = async(params) => post(`${API_URL}/labourEducation`,params);

const GetLabourHistory = async(id) => get(`${API_URL}/labour/labourHistory/${id}`);

const GetDisabilityMasterdata = async() => get(`${API_URL}/labour/disabilityMasterdata`);

const CreateLabourDiseaseReason = async(params) => post(`${API_URL}/labourDisease`,params);

const LabourDiseaseListing = async(params) => get(`${API_URL}/labourDisease`,params);

const GetDiseaseDetails = async(id) => get(`${API_URL}/labourDisease/${id}`);

const UpdateDisease = async(id,params) => patch(`${API_URL}/labourDisease/${id}`,params);

const DeleteDisease = async(id) => del(`${API_URL}/labourDisease/${id}`);

const LabourUanCheckListDocumentUpload = async(params) => patch(`${API_URL}/labour/labourUanCheckListUpload`,params);

const UpdateUanList = async(params) => patch(`${API_URL}/labour/updateUAN`,params);

const LabourComplain = async (params) => post(`${API_URL}/labourComplainReport`, params);

const LabourComplainListing = async (params) => get(`${API_URL}/labourComplainReport`,params);

const DeleteLabourComplain = async(id) => del(`${API_URL}/labourComplainReport/${id}`);

const GetLabourComplainDetails = async(id) => get(`${API_URL}/labourComplainReport/${id}`);

const UpdateLabourComplain = async(id,params) => patch(`${API_URL}/labourComplainReport/${id}`,params);

const UpdateIdCardlidationDate = async(params) => post(`${API_URL}/labour/extendIdValidation`,params);

const getUANFromAadhaar = async(params) => post(`${API_URL}/labour/getUANFromAadhaar`,params);


export const LabourService = {
    CreateLabour,
    LabourList,
    LabourHealthFormList,
    CreateLabourHealth,
    GetLabourById,
    UpdateLabourBasicInfo,
    UpdateLabourEmergencyContact,
    UpdateLabourEducation,
    UpdateLabourBankDetails,
    UpdateLabourPhoto,
    UpdateLabourAdharCard,
    UpdateLabourRationCard,
    UpdateLabourPanCard,
    UpdateLabourPassport,
    UpdateHealthDocument,
    LabourBulkUpload,
    GetLabourByAdharCard,
    DeleteLabour,
    UploadLabourDocument,
    UploadLabourCompilance,
    FetchLabourCompilanceList,
    UpdateLabourPFBasicInfo,
    UpdateLabourDailyWages,
    UpdateLabourMonthlyWages,
    SafetyTrainingList,
    FetchLabourTrainingType,
    CreateLabourSafetyTraining,
    ExitLabour,
    AllSafetyTrainingList,
    GetEducationListById,
    GetEducationList,
    DeleteEducationById,
    GetSkillCategory,
    DeleteSafetyTraing,
    GetSafetyTrainingDetails,
    UpdateSafetyTraing,
    UpdateSkillInfo,
    GetLabourEducationDeatils,
    AddLabourEducation,
    GetLabourHistory,
    GetDisabilityMasterdata,
    LabourDiseaseListing,
    CreateLabourDiseaseReason,
    GetDiseaseDetails,
    UpdateDisease,
    DeleteDisease,
    LabourUanCheckListDocumentUpload,
    UpdateUanList,
    LabourComplain,
    LabourComplainListing,
    DeleteLabourComplain,
    GetLabourComplainDetails,
    UpdateLabourComplain,
    UpdateIdCardlidationDate,
    getUANFromAadhaar
};