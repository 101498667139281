import { createSlice } from "@reduxjs/toolkit";

export const TransactionSlice = createSlice({
    name: "transaction",
    initialState: {
        transactionListById: [],
        allTransactionList: {},
        pagination: {}
    },
    reducers: {
        paymentStatus: () => {},
        getTransactionById: () => {},
        getTransactionByIdSuccess: (state,action) => {
            state.transactionListById = action.payload
        },
        getAllTransactionList: () => {},
        getAllTranscationListSuccess: (state,action) => {
            state.allTransactionList = action.payload
            state.pagination = action.payload.pagination
        }
    }
});

export default TransactionSlice;