import {all, call, put, takeLatest} from 'redux-saga/effects'
import {HolidayService} from "../services";
import {GeneralActions, HolidayActions} from "../slices/actions";

function *createHoliday({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(HolidayService.CreateHoliday, payload);
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}
function *deleteHoliday({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(HolidayService.DeleteHoliday,payload);

        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}

function *updateHoliday({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(HolidayService.UpdateHoliday, payload.id, payload);
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *getHolidayById({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(HolidayService.GetHolidayById, payload);
        yield put(HolidayActions.getHolidayByIdSucess(result));
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}
function *getHolidayDetaills({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(HolidayService.HolidayDeatils, payload);
        yield put(HolidayActions.getHolidayDetailsSuccess(result));
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}


export function *HolidayWatcher() {
    yield all([
        yield takeLatest(HolidayActions.createHoliday.type, createHoliday),
        yield takeLatest(HolidayActions.deleteHoliday.type, deleteHoliday),
        yield takeLatest(HolidayActions.getHolidayById.type, getHolidayById),
        yield takeLatest(HolidayActions.updateHoliday.type, updateHoliday),
        yield takeLatest(HolidayActions.getHolidayDetails.type,getHolidayDetaills),
     ]);
}