import React ,{useEffect, useState} from "react";
import {
  Card,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Box, Typography,
  Chip,
  TableBody,
  Pagination,
  Grid, MenuItem
 } from "@mui/material";
//  import Input from "components/Input";
 import SelectField from "components/SelectField";
 import {DefaultSort} from "constants/sort";
import PageTitle from "components/PageTitle";
import SubContractorAssign from "./SubcontractorAndProjectAssignDialog";
import { CompanyActions, ProjectActions, SubContractorActions } from "slices/actions";
import { useSelector ,useDispatch} from "react-redux";
import { GeneralSelector, ProjectSelector, SubContractorSelector } from "selectors";
import styled from "@emotion/styled";
import ListSkeleton from "../../components/Skeleton/ListSkeleton";
import FloatingButton from "components/FloatingButton";
import { CompanySelector } from "selectors/CompanySelector";
import { Constants } from "utils/constants";

const FilterBox = styled(Box)(() => ({
  width: "100%",
  marginTop: 30,
  marginBottom: 20,
  display: "flex",
  justifyContent: "space-between"
}));

function SubContractorAndProjectListing() {
   const dispatch = useDispatch();
   const pagination = useSelector(SubContractorSelector.assingSubcontractorpagination());
   const loading = useSelector(GeneralSelector.loader(SubContractorActions.getAssignedSubContractor));
   const assignedSubcontractorListing = useSelector(SubContractorSelector.getAssignedSubcontractor());
   const companyList = useSelector(CompanySelector.getCompanyList());
   const projectList = useSelector(ProjectSelector.getProjectList());
   const [openAssignDialog,setOpenAssignDialog] = useState(false);
   const userType = localStorage.getItem('userType')
   const companyId = localStorage.getItem('companyID')
   const projectId = localStorage.getItem('projectID')
   const subcontractorId = localStorage.getItem('subcontractorID')
   const [company,setCompany] = useState(companyId?companyId:"");
   const [project,setProject] = useState(projectId?projectId:"");
   const [filter, setFilter] = useState({
    sort: DefaultSort.newest.value,
    page: 1
  });

  useEffect(() => {
  const params = {
    ...filter,
    company_id: company?company:"",
    project_id: project?project:"",
   }
  dispatch(SubContractorActions.getAssignedSubContractor(params));
  }, [filter]);

  useEffect(() => {
  if (!companyId) {
  dispatch(CompanyActions.getCompanyList());
  } 
  if (companyId && !projectId) {
    const params = {
        company_id: companyId,
        status: 1
     }
    dispatch(ProjectActions.getProjectList(params))
} 
if (companyId && projectId) {
    const params = {
        company_id: companyId,
        project_id: projectId,
     }
     dispatch(SubContractorActions.getAssignedSubContractor(params)) 
} 
 if (subcontractorId) {
    const params = {
        subcontractorId: subcontractorId
    }
     dispatch(SubContractorActions.getAssignedSubContractor(params));
  }
  
  },[])

  const handleChangePagination = (e, val) => {
    setFilter({
        ...filter,
        page: val
    });
};

const handleChangeFilter = ({ target }) => {
    const {name, value} = target;

    setFilter({
        ...filter,
        [name]: value
    });
  }
  const handleChangeCompany = (e) => {
    const cId = e.target.value
    setProject("");
    setCompany(cId);
    if (cId) {
      const params = {
        company_id: cId,
        status: 1
      }
      dispatch(ProjectActions.getProjectList(params))
    }
    const params = {
      company_id: cId,
     }
    dispatch(SubContractorActions.getAssignedSubContractor(params));
  }
  const handleChangeProject = (e) => {
    setProject(e.target.value)
    const params = {
      company_id: companyId?companyId:company,
      project_id: e.target.value 
     }
    dispatch(SubContractorActions.getAssignedSubContractor(params));
  }
  return (
    <>
    <PageTitle isBack={true} title="Assigned" />
    <Card sx={{ position: "relative" }}>
      {/* <AddCircleOutlineIcon onClick={() => setOpenAssignDialog(true) }
        fontSize="large"
        sx={{
          position: "absolute",
          cursor: "pointer",
          right: "15px",
          top: "20px",
          color: "#3f7ed2",
        }}
      /> */}
      <FilterBox>
          <Grid container spacing={3} justifyContent="space-between">
              {/* <Grid item lg={4} sm={12} xs={12}>
                  <Input
                      label="Search"
                      name='keyword'filter
                      placeholder='Search by name'
                      value={filter.keyword ?? ''}
                      onChange={handleChangeFilter}
                  />
              </Grid> */}
            {userType === Constants.SUPERADMIN ?(
              <Grid item lg={3} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Company</Typography>
                <SelectField
                    value={company}
                    onChange={handleChangeCompany}
                    >
                    {companyList && companyList.map((item,index) => (
                    <MenuItem key={index} value={item._id}>
                            {item.name}
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
            {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER ?(
              <Grid item lg={3} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Project</Typography>
                <SelectField
                    value={project}
                    onChange={handleChangeProject}
                    >
                    {(company && projectList) && projectList.map((item,index) => (
                    <MenuItem key={index} value={item._id}>
                            {item.name}
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
            <Grid item lg={2} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Sort by</Typography>
                <SelectField
                    value={filter.sort}
                    name="sort"
                    onChange={handleChangeFilter}>
                    {Object.keys(DefaultSort).map((key) => (
                        <MenuItem key={key} value={DefaultSort[key].value}>
                            {DefaultSort[key].name}
                        </MenuItem>
                    ))}
                </SelectField> 
            </Grid>
          </Grid>
      </FilterBox>
      {loading ? (
          <ListSkeleton/>
      ) : (
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>SubContractor Name</TableCell>
                <TableCell>Company Name</TableCell>
                <TableCell>Project Name</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                      {assignedSubcontractorListing && assignedSubcontractorListing.data && assignedSubcontractorListing.data.map((item, i) => (
                          <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                                  {item.subcontractorId?item.subcontractorId.subContractorName:""}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                  {item.companyId?item.companyId.name:""}
                              </TableCell>
                            <TableCell component="th" scope="row">
                                  {item.projectId?item.projectId.name:""}
                              </TableCell>
                              <TableCell component="th" scope="row">
                              <Chip
                                  label={item.status === 2 ? 'Inactive' : 'Active'}
                                  color={item.status === 2 ? 'default' : 'primary'} size='small'/>
                              </TableCell>
                            
                          </TableRow>
                      ))}  
                  </TableBody>
          
          </Table>
        </Box>
    )}

    {!subcontractorId?(
    <FloatingButton
      onClick={() => setOpenAssignDialog(true) }
    />):null}

      <SubContractorAssign
        open={openAssignDialog}
        onClose={() => setOpenAssignDialog(false)}
      /> 
        <Pagination
          page={filter.page}
          count={pagination && pagination.pages}
          onChange={handleChangePagination}/>
        
    </Card>
 </>
  );
}

export default SubContractorAndProjectListing;
