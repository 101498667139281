import React, { useEffect ,useState} from "react";
import {
    Box,
    Card, Table, TableCell, TableHead, TableRow,TableBody,
    Typography, IconButton
} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; 
import {useHistory,useParams} from "react-router-dom";
import { GeneralActions, UserActions } from "slices/actions";
import { useDispatch, useSelector } from "react-redux";
import { GeneralSelector, UserSelector } from "selectors";
import DialogConfirm from "components/DialogConfirm";
import { toast } from "react-toastify";

export default function ProjectDetailListing() {
    const dispatch = useDispatch();
    const history = useHistory();
    const {id} = useParams();
    const [selected, setSelected] = useState(null);
    const projectUserListing = useSelector(UserSelector.getUserListByID())
    const [confirmDelete, setConfirmDelete] = useState(false);
    const success = useSelector(GeneralSelector.success(UserActions.deleteUser.type));
    const error = useSelector(GeneralSelector.error(UserActions.deleteUser.type))

    useEffect(() => {
        if (id) {
        dispatch(UserActions.getUserListById(id))
        }
    },[id])

    useEffect(() => {
        if (success) {
          setConfirmDelete(false);
          setSelected(null);
    
          toast.success(`${success?.message ?? "Success"}`, {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
          });
    
          dispatch(GeneralActions.removeSuccess(UserActions.deleteUser.type));
          dispatch(UserActions.getUserListById(id));
        }
      }, [success]);

    const handleDelete = () => {
        dispatch(UserActions.deleteUser(selected));
      };

      useEffect(() => {
        if (error) {
          setConfirmDelete(false);
          setSelected(null);
    
          toast.error(`${error?.message ?? "Error"}`, {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
          });
        }
      }, [error]);
    
    return (
        <Card sx={{position: "relative"}}>
            <Typography variant='h5' sx={{ mb: 4 }}>User Listing</Typography>
           
            <AddCircleOutlineIcon
              onClick={() => history.push(`/app/project/details/addUser/${id}`)}
                fontSize= "large" sx={{position: "absolute", cursor: "pointer", right: "15px", top: "20px", color: "#3f7ed2"}}
             />
            <Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Phone number</TableCell>
                            <TableCell>email id</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {projectUserListing.data && projectUserListing.data.map((item,index) => (
                        <TableRow key={index}>
                            <TableCell align="left">
                                {item.name}<br />
                                {item.is_project_admin?(
                               <Typography style={{color: 'blue',fontSize: 15}}>Project Admin</Typography>):null} 
                            </TableCell>
                           <TableCell align="left">
                                {item.phone}
                            </TableCell>
                            <TableCell align="left">
                               {item.email}
                            </TableCell>
                            <TableCell align="left">
                         

                                <IconButton 
                                    onClick={() => history.push(`/project/update/${item._id}`)}
                                >
                                    <Edit className="text-primary" />
                                </IconButton>
                                  {!item.is_project_admin?(
                                <div style={{display: 'inline-block'}}> 
                                    <IconButton 
                                        onClick={() => {
                                            setConfirmDelete(true);
                                            setSelected(item._id);
                                      }}
                                    >
                                        <Delete className="text-default" />
                                    </IconButton>
                                </ div>
                                ):null}
                             
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </Box>    
            <DialogConfirm
                 title="Delete Site"
                 content="Are you sure want to delete this user?"
                 open={confirmDelete}
                 onClose={() => setConfirmDelete(false)}
                 onSubmit={handleDelete}
                />
        </Card>
    )
}