import React, { useEffect, useRef} from "react";
import {
  Box,
  Grid,
  Card,
  Typography, IconButton
} from "@mui/material";
import PageTitle from "components/PageTitle";
import { ProjectSelector } from 'selectors';
import { ProjectActions } from "slices/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {CableOutlined, FmdGoodOutlined, LocalPrintshopOutlined} from '@mui/icons-material';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
import ReactToPrint from 'react-to-print';
import { format } from 'date-fns';


// function printIdCard () {
//   html2canvas(document.querySelector("#capture")).then(canvas => {
//       document.body.appendChild(canvas);
//       const imgData = canvas.toDataURL('image/png');
//       // const pdf = new jsPDF();
//       const pdf = new jsPDF('p', 'mm', [1080, 999]);
//       pdf.addImage(imgData, 'svg', 0, 0);
//       pdf.save("formb.pdf"); 
//       pdf.close();
//   });
// }
export default function ViewProject() {

  let componentRef = useRef(null);
  const pageStyle = `{ size: 297 mm 420 mm }`;

  const { id } = useParams();
  const dispatch = useDispatch();
  const projectDetails = useSelector(ProjectSelector.getProjectView());
  // const loading = useSelector(GeneralSelector.loader(ProjectActions.getProjectVIewDataSuccess.type));
  const start_date = new Date(projectDetails?.result?.start_date).getTime();
  const end_date = new Date(projectDetails?.result?.end_date).getTime();
  const diff = Math.floor((end_date - start_date) / (1000 * 60 * 60 * 24));
  const months = Math.floor(diff/30)
  console.log('diff',months)
  useEffect(() => {
    if (id) {
      dispatch(ProjectActions.getProjectVIewData(id));
    }
  }, [id]);
  return (
    <Box sx={{position: 'relative'}}>
      <PageTitle isBack={true} title="About Project" sx={{mb: 0}} />
      <ReactToPrint
          pageStyle={pageStyle}
          trigger = {() => (
          <IconButton sx={{position: 'absolute', top: '0', right: '0'}}>
          <LocalPrintshopOutlined color='primary' sx={{cursor: 'pointer'}} />
          </IconButton>
          )}
          content={() => componentRef}
      />
      <div style={{clear: 'both'}}></div>
        <Card sx={{ position: "relative" }} ref={el => (componentRef = el)}>
        
          <Grid container spacing={3}>
            <Grid item lg={12} sm={12} xs={12}>
              <Typography
                variant="h6"
                sx={{borderBottom: "1px solid #3f7ed2", position: 'relative' }}
              >
                {projectDetails && projectDetails.result && projectDetails.result.name}
                {/* <LocalPrintshopOutlined className="printicon" color='primary' onClick={printIdCard} /> */}
              </Typography>
            </Grid>
            <Grid item lg={2} sm={12} xs={12}>
              <div
                style={{
                  width: "120px",
                  innerHeight: "150px",
                  borderRadius: "50%",
                }}
              >
                <img
                  src={projectDetails && projectDetails.result && projectDetails.result.company_id.logo}
                  alt=""
                  style={{ borderRadius: "50%", width: "100%", objectFit: "cover" }}
                />
              </div>
            </Grid>
            <Grid item lg={5} sm={12} xs={12}>
              <Typography>{projectDetails && projectDetails.result && projectDetails.result.code}</Typography>
              <Typography>
                Start Date : {projectDetails?.result?.start_date && format(new Date(projectDetails && projectDetails.result && projectDetails.result.start_date), 'dd/MM/yyyy')}
              </Typography>
              <Typography>Duration : {months && months} Months</Typography>
              <Typography>State : {projectDetails && projectDetails.result && projectDetails.result.state}</Typography>
              <Typography>Address : {projectDetails && projectDetails.result && projectDetails.result.location}</Typography>
            </Grid>
            <Grid item lg={5} sm={12} xs={12}>
            <Typography>GST : {projectDetails && projectDetails.result && projectDetails.result.gst_number}</Typography>
              <Typography>
                End Date : {projectDetails?.result?.end_date && format(new Date(projectDetails.result.end_date), 'dd/MM/yyyy')}
              </Typography>
              <Typography>Pincode : {projectDetails && projectDetails.result && projectDetails.result.pincode}</Typography>
              <Typography>City : {projectDetails && projectDetails.result && projectDetails.result.city}</Typography>
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
              <Typography
                variant="h6"
                sx={{ borderBottom: "1px solid #3f7ed2" }}
              >
                Company Details
              </Typography>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <Card className="viewwGrid">
                  <Typography>Name : {projectDetails && projectDetails.result && projectDetails.result.company_id.name}</Typography>
                  <Typography>Email : {projectDetails && projectDetails.result && projectDetails.result.company_id.email}</Typography>
                  <Typography>Client Name : {projectDetails && projectDetails.result && projectDetails.result.clientName}</Typography>
                  <Typography>Contact Person : {projectDetails && projectDetails.result && projectDetails.result.clientContactPerson}</Typography>
                  <Typography>Phone Number : {projectDetails && projectDetails.result && projectDetails.result.clientContactNumber}</Typography>
                </Card>
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
              <Typography
                variant="h6"
                sx={{ borderBottom: "1px solid #3f7ed2" }}
              >
                SubContractor Listing
              </Typography>
            </Grid>
            {projectDetails.result && projectDetails.result.subContractorId.map((item, i) => (
                <Grid key={i} item lg={4} sm={12} xs={12}>
                    <Card className="viewwGrid">
                      <Typography sx={{fontSize: 14}}>Name : {item.subContractorName}</Typography>
                      <Typography sx={{fontSize: 14}}>Phone : {item.subContractorPhone}</Typography>
                      <Typography sx={{fontSize: 14}}>Supervisor : {item.supervisorName}, {item.supervisorMobileNo}</Typography>
                      <Typography>
                        <IconButton sx={{float: 'left', padding: 0}} title="Nature Of Work">
                          <CableOutlined color = 'primary' sx={{fontSize: 20}} />
                        </IconButton>
                        <Typography sx={{width: '90%', float: 'right', fontSize: 14}}>{item.natureOfWork}</Typography>
                      </Typography>
                      <div style={{clear: 'both'}}></div>
                      <Typography>
                        <IconButton sx={{float: 'left', padding: 0}} title="Work Location">
                          <FmdGoodOutlined color = 'primary' sx={{fontSize: 20}} />
                        </IconButton>
                        <Typography sx={{width: '90%', float: 'right', fontSize: 14}}>{item.workLocation}</Typography>
                      </Typography>
                      <div style={{clear: 'both'}}></div>
                    </Card>
                </Grid>
            ))}
            <Grid item lg={12} sm={12} xs={12}>
              <Typography
                variant="h6"
                sx={{ borderBottom: "1px solid #3f7ed2" }}
              >
                Site Details
              </Typography>
            </Grid>
            {projectDetails.result && projectDetails.result.sites.map((item, i) => (
              <Grid key={i} item lg={4} sm={12} xs={12}>
                  <Card className="viewwGrid">
                    <Typography>Name : {item.name}</Typography>
                    <Typography>
                      <IconButton sx={{float: 'left', padding: 0}} title="Work Location">
                        <FmdGoodOutlined color = 'primary' sx={{fontSize: 20}} />
                      </IconButton>
                      <Typography sx={{width: '90%', float: 'right', fontSize: 14}}>{item.address}</Typography>
                    </Typography>
                    <div style={{clear: 'both'}}></div>
                      {/* <Typography>Address : {item.address}</Typography> */}
                  </Card>
              </Grid>
            ))}
            {/* <Grid item lg={12} sm={12} xs={12}>
           
           {projectDetails && projectDetails.result && projectDetails.result.users}?(
            <Typography >User : {projectDetails.result.users.length} </Typography>

           ):<p></p>
            </Grid> */}
          </Grid>
        </Card>
    
      
    </Box>
  );
}
