import React, {useEffect, useState} from "react";
import {
     Box, Card, Table, TableBody, TableCell, Grid,
     Pagination,
     TableHead, TableRow,
     MenuItem,
     Hidden,
     Checkbox,
     Button,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {GeneralSelector, ProjectSelector,SubContractorSelector} from "selectors";
import {CompanyActions, GeneralActions, LabourActions, MasterDataActions, ProjectActions, SubContractorActions} from "slices/actions";
import {toast} from "react-toastify";
import {DefaultSort} from "constants/sort";
import ListSkeleton from "../../components/Skeleton/ListSkeleton";
import { LabourSelector } from "selectors/LabourSelector";
import styled from "@emotion/styled";
import SelectField from "components/SelectField";
import Input from "components/Input";
import { Constants } from "utils/constants";
import { CompanySelector } from "selectors/CompanySelector";
import { format } from 'date-fns';
import ChangeDateDialog from "components/ChangeDateDialog";

const FilterBox = styled(Box)(() => ({
    width: "100%",
    marginTop: 30,
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-between"
}));

function IdCardValidationList() {
     const dispatch = useDispatch();
     const labourListData = useSelector(LabourSelector.getLabourList());
     const companyList = useSelector(CompanySelector.getCompanyList());
     const projectList = useSelector(ProjectSelector.getProjectList());
     const pagination = useSelector(LabourSelector.getPagination());
     const loading = useSelector(GeneralSelector.loader(LabourActions.getLabourSuccess.type));
     const success = useSelector(GeneralSelector.success(LabourActions.deleteLabour.type));
     const error = useSelector(GeneralSelector.error(LabourActions.deleteLabour.type));
     const changeStatusSuccess = useSelector(GeneralSelector.success(MasterDataActions.changeStatus.type));
     const changeStatusError = useSelector(GeneralSelector.error(MasterDataActions.changeStatus.type));
     const [filter, setFilter] = useState({
        sort: DefaultSort.newest.value,
        page: 1,
     });
    const userType = localStorage.getItem('userType')
    const companyId = localStorage.getItem('companyID')
    const projectId = localStorage.getItem('projectID')
    const subcontractorId = localStorage.getItem('subcontractorID')
    const [company,setCompany] = useState(companyId?companyId:"");
    const [project,setProject] = useState(projectId?projectId:"");
    const [subcontractor,setSubcontractor] = useState(subcontractorId?subcontractorId:"");
    const [assignedProject,setAssignedProject] = useState("");
    const [openExtendValidation,setOpenExtendValidation] = useState(false);
    const assignedProjectToSubcontractorList = useSelector(SubContractorSelector.getAssignedSubcontractor());
    const [extendIdCardList,setExtendIdCardList] = useState([]);
    const successIdCardValidation = useSelector(GeneralSelector.success(LabourActions.updateIdCardValidationDate.type));
    const errorIdCardValidation = useSelector(GeneralSelector.error(LabourActions.updateIdCardValidationDate.type));

    useEffect(() => {
        if (successIdCardValidation) {
          setOpenExtendValidation(false);
          toast.success(`${successIdCardValidation?.message ?? "Success"}`, {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
          });
         dispatch(GeneralActions.removeSuccess(LabourActions.updateIdCardValidationDate.type));
          const params = {
            ...filter,
            company_id: company?company:"",
            project_id: project?project:"",
            subcontractor_id: subcontractor?subcontractor:"" ,
            is_ID: true,
        }
        dispatch(LabourActions.getLabourList(params));
        }
      }, [successIdCardValidation]);
    
      useEffect(() => {
        if (errorIdCardValidation) {
        setOpenExtendValidation(false);
        toast.error(`${errorIdCardValidation?.message ?? "Error"}`, {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
          });
         
          dispatch(GeneralActions.removeError(LabourActions.updateIdCardValidationDate.type));
        }
      }, [errorIdCardValidation]);

    useEffect(() => {
        if (!companyId) {
           dispatch(CompanyActions.getCompanyList())
        } 
        if (companyId && !projectId) {
            const params = {
                company_id: companyId,
                status: 1
             }
            dispatch(ProjectActions.getProjectList(params))
        } 
        if (companyId && projectId) {
            const params = {
                company_id: companyId,
                project_id: projectId,
             }
             dispatch(SubContractorActions.getAssignedSubContractor(params)) 
        } 
         if (subcontractorId) {
            const params = {
                subcontractorId: subcontractorId
            }
             dispatch(SubContractorActions.getAssignedSubContractor(params));
        }
    },[])

  useEffect(() => {
    const params = {
        ...filter,
        company_id: company?company:"",
        project_id: project?project:"",
        subcontractor_id: subcontractor?subcontractor:"" ,
        is_ID: true,
    }
    dispatch(LabourActions.getLabourList(params));
}, [filter]);

useEffect(() => {
    if (changeStatusSuccess) {
        toast.success(`${changeStatusSuccess?.message ?? "Success"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true
            });
  
        dispatch(GeneralActions.removeSuccess(MasterDataActions.changeStatus.type));
        const params = {
            ...filter,
            company_id: company?company:"",
            project_id: project?project:"",
            subcontractor_id: subcontractor?subcontractor:"" ,
            is_ID: true,
        }
        dispatch(LabourActions.getLabourList(params));
      }
  }, [changeStatusSuccess]);

  useEffect(() => {
    if (changeStatusError) {
       toast.error(`${changeStatusError?.message ?? "Error"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true
            });
  
        dispatch(GeneralActions.removeError(MasterDataActions.changeStatus.type));

      }
  }, [changeStatusError]);


useEffect(() => {
  if (success) {

      toast.success(`${success?.message ?? "Success"}`, {
              position: "top-right",
              autoClose: 3000,
              closeOnClick: true
          });

      dispatch(GeneralActions.removeSuccess(LabourActions.deleteLabour.type));
      const params = {
        ...filter,
        company_id: company?company:"",
        project_id: project?project:"",
        subcontractor_id: subcontractor?subcontractor:"",
        is_ID: true, 
      }
     dispatch(LabourActions.getLabourList(params));
    }
}, [success]);

useEffect(() => {
    if (error) {
        toast.error(`${error?.message ?? "Error"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true
            });
  
        dispatch(GeneralActions.removeError(LabourActions.deleteLabour.type));
    }
  }, [error]);

const handleChangeFilter = ({ target }) => {
    const {name, value} = target;

    setFilter({
        ...filter,
        [name]: value
    });
}

const handleChangePagination = (e, val) => {
    setFilter({
        ...filter,
        page: val
    });
};

const handleChangeCompany = (e) => {
    const cId = e.target.value
    setCompany(cId)
    setProject("")
    setSubcontractor("")
   
    if (cId) {
      const params = {
        company_id: cId,
        status: 1
      }
      dispatch(ProjectActions.getProjectList(params))
      const searchByCompany = {
           company_id: cId,
           is_ID: true,
           ...filter,
    }
        dispatch(LabourActions.getLabourList(searchByCompany));
    }
  }
  const handleChangeProject = (e) => {
    const pId = e.target.value
    setProject(pId)
    setSubcontractor("")
    if (pId) {
    const params = {
        company_id: company || companyId,
        project_id: pId,
        
    }
    dispatch(SubContractorActions.getAssignedSubContractor(params))
    const searchByProject = {
        company_id: company || companyId,
        project_id: pId,
        is_ID: true,
        ...filter,

     }
     dispatch(LabourActions.getLabourList(searchByProject));
    }
}
  
  const handleChangeSubcontractor = (e) => { 
    const sId = e.target.value
    if (sId) {
    setSubcontractor(sId)
    const searchBySubcontractor = {
        company_id: company || companyId,
        project_id: project || projectId ,
        subcontractor_id: sId,
        is_ID: true,
        ...filter,

     }
     dispatch(LabourActions.getLabourList(searchBySubcontractor));
    }
}

const handleChangeProjectBySubcontractor = (e) => {
    setAssignedProject(e.target.value)
    for (const i in assignedProjectToSubcontractorList.data) {
        if (assignedProjectToSubcontractorList.data[i].projectId._id === e.target.value) {
            const searchByProject = {
                    project_id: e.target.value,
                    company_id: assignedProjectToSubcontractorList.data[i].companyId._id,
                    subcontractor_id: subcontractorId,
                    is_ID: true,
                    ...filter,

                }
            dispatch(LabourActions.getLabourList(searchByProject));
        }
    }
}

const handleChangeAddIdCard = (e,index) => {
    if (e.target.checked === true && Boolean(extendIdCardList && extendIdCardList.map((item) => item !== labourListData[index]._id))) {
        setExtendIdCardList([...extendIdCardList,labourListData[index]._id])
   } else {
       const newArray = extendIdCardList.filter(e => e !== labourListData[index]._id)
       setExtendIdCardList(newArray)

     }
   }
const reNew = () => {
    if (extendIdCardList.length !==0) {
        setOpenExtendValidation(true)
    } else {
        toast.error("Please select a labour first", {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
        });

    }
}

return (
      
  <Card>
      <Typography variant="h5" sx={{ fontWeight: 600, position: 'relative' }}>All Labour
      </Typography>
     <FilterBox>
        <Grid container spacing={3} justifyContent="space-between">
            <Grid item lg={4} sm={12} xs={12}>
                <Input
                    label="Search"
                    name='keyword'
                    placeholder='Search by name'
                    value={filter.keyword ?? ''}
                    onChange={handleChangeFilter}/>
            </Grid>
            {userType === Constants.SUPERADMIN ?(
              <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Company</Typography>
                <SelectField
                    value={company}
                    onChange={handleChangeCompany}
                    >
                    {companyList && companyList.map((item,index) => (
                    <MenuItem key={index} value={item._id}>
                            {item.name}
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
            {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER ?(
              <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Project</Typography>
                <SelectField
                    value={project}
                    onChange={handleChangeProject}
                    >
                    {(company && projectList) && projectList.map((item,index) => (
                    <MenuItem key={index} value={item._id}>
                            {item.name}
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
            {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER || userType === Constants.PROJECT_USER?(
              <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Subcontractor</Typography>
                <SelectField
                    value={subcontractor}
                    onChange={handleChangeSubcontractor}
                    >
                    {(project && assignedProjectToSubcontractorList.data) && assignedProjectToSubcontractorList.data.map((item,index) => (
                    <MenuItem key={index} value={item?.subcontractorId?._id}>
                             {item?.subcontractorId?.subContractorName}
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
             {userType === Constants.SUB_CONTRACTOR_USER ?(
              <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Project</Typography>
                <SelectField
                    value={assignedProject}
                    onChange={handleChangeProjectBySubcontractor}
                    >
                    {assignedProjectToSubcontractorList && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                    <MenuItem key={index} value={item?.projectId?._id}>
                            {item?.projectId?.name}({item?.companyId?.name})
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
           
            <Grid item lg={2} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Sort by</Typography>
                <SelectField
                    value={filter.sort}
                    name="sort"
                    onChange={handleChangeFilter}>
                    {Object.keys(DefaultSort).map((key) => (
                        <MenuItem key={key} value={DefaultSort[key].value}>
                            {DefaultSort[key].name}
                        </MenuItem>
                    ))}
                </SelectField> 
            </Grid>
        </Grid>
       
    </FilterBox>
    

      {loading ? (
          <ListSkeleton/>
      ) : (
          <Box>
              <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell>Name</TableCell>
                          <Hidden smDown>
                              <TableCell>AadharNo</TableCell>
                              <TableCell>Phone</TableCell>
                              <TableCell>DOJ</TableCell>
                              <TableCell>Project Labour Code</TableCell>
                              <TableCell>ID Card Valid Upto</TableCell>
                              <TableCell></TableCell>
                         </Hidden>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {labourListData && labourListData.length === 0 && (
                          <TableRow>
                              <TableCell align="center" colSpan={7}>
                                  No Data
                              </TableCell>
                          </TableRow>
                      )}
                      {labourListData && labourListData.map((item, i) => (
                          <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                              <TableCell>
                                  <Box sx={{
                                      display: 'flex',
                                      alignItems: 'center',

                                      '& img': {
                                          borderRadius: 50,
                                      }
                                  }}>
                                    <Typography sx={{ ml: 2 }} variant='subtitle2'>
                                        {`${item.firstName} ${"  "} ${item.lastName}`} <br />
                                        <span style={{ fontSize: 12, color: "#3c5a99" }}>
                                         {item.projectname} (P)
                                        </span>{" "}
                                         <br />
                                        <span style={{ fontSize: 12, color: "#0077b5" }}>
                                            {item.companyName} (C)
                                        </span>{" "}
                                        <br />
                                        <span style={{ fontSize: 12, color: "#0077b5" }}>
                                            {item.subcontractorName} (S)
                                        </span>
                                    </Typography>
                                  </Box>
                              </TableCell>
                              <Hidden smDown>
                                  <TableCell>{item.aadharNo}</TableCell>
                                  <TableCell>{item.mobileNumber}</TableCell>
                                  <TableCell>{item.doj && format(new Date(item.doj), 'dd/MM/yyyy')}</TableCell>
                                  <TableCell>{item.projectLabourCode}</TableCell>
                                  <TableCell>{item.IDValidDate && format(new Date(item.IDValidDate), 'dd/MM/yyyy')}</TableCell>
                                  <TableCell align="right" width='20%'>
                                   <Checkbox
                                        onChange={(e) => handleChangeAddIdCard(e,i)}
                                    />
                                  </TableCell>
                                  
                              </Hidden>
                                  
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>

              <Pagination
                  sx={{ mt: 1 }}
                  page={filter.page}
                  count={pagination && pagination.pages}
                  onChange={handleChangePagination}/>

                <Typography style={{ textAlign: "right" }}>
                <Button variant="contained" color="primary" onClick={reNew}>
                    {/* {createAttendanceLoading?<CircularProgress color="inherit" size={20}/>:<>Update</>} */}
                    Renew
                </Button>
                </Typography>
                <ChangeDateDialog
                    open={openExtendValidation}
                    onClose={() => setOpenExtendValidation(false)}
                    extendIdCardList={extendIdCardList}
                />

          </Box>
      )}
    </Card>
)
}

export default IdCardValidationList;