import React,{useEffect,useState} from "react";
import {
    Box,
    Button,
    Card,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    Typography,
    useTheme
} from "@mui/material";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {GeneralSelector, MasterDataSelector} from "selectors";
import { GeneralActions, LabourActions, MasterDataActions} from "slices/actions";
import {useFormik} from "formik";
import Input from "components/Input";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import TextareaAutosize from '@mui/material/TextareaAutosize';


EmergencyContactDetails.propTypes = {
    labourDetails: PropTypes.object,
};

export default function EmergencyContactDetails(props) {
    const { labourDetails } = props;
    const {id} = useParams();
    const dispatch = useDispatch();
    const theme = useTheme();
    const success = useSelector(GeneralSelector.success(LabourActions.updateLabourEmergencyContact.type));
    const error = useSelector(GeneralSelector.error(LabourActions.updateLabourEmergencyContact.type));
    const loading = useSelector(GeneralSelector.loader(LabourActions.updateLabourEmergencyContact.type));
    const locationData = useSelector(MasterDataSelector.getLocationListByPincode());
    const [pincode,setPincode]=useState();

   useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(LabourActions.updateLabourEmergencyContact.type));
            dispatch(LabourActions.getLabourById(id));

        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeError(LabourActions.updateLabourEmergencyContact.type));
        }
    }, [error]);

    const validationSchema = yup.object({
        emergencyContactPersonName: yup.string().required('Name is required'),
        emergencyContactPersonPhone: yup.string().required('Phone is required'),
        emergencyContactRelation: yup.string().required('Relation is required'),
        emergencyContactPincode: yup.string().matches(/^[0-9]+$/, "Must be only digits").
        length(6, 'Pincode must be exactly 6 characters!').
        required('Pincode is required'),
        emergencyContactState: yup.string().required('State is required'),
        emergencyContactAddress: yup.string().required('Address is required'),
        emergencyContactCity: yup.string().required('City is required'),
        
       
    });
    const formik = useFormik({
      initialValues: {
        emergencyContactPersonName: labourDetails?.result?.emergencyContactPersonName??"",
        emergencyContactPersonPhone: labourDetails?.result?.emergencyContactPersonPhone??"",
        emergencyContactRelation: labourDetails?.result?.emergencyContactRelation??"", 
        emergencyContactPincode: labourDetails?.result?.emergencyContactPincode??"",
        emergencyContactState: labourDetails?.result?.emergencyContactState??"",
        emergencyContactAddress: labourDetails?.result?.emergencyContactAddress??"",
        emergencyContactCity: labourDetails?.result?.emergencyContactCity??"",
      },
      enableReinitialize: true,
      validateOnChange: true,
      validationSchema: validationSchema,
      onSubmit: (values) => {
        handleSubmit(values);
      },
    });
   
     const handleSubmit = (values) => {
        const params = {
              id: labourDetails.result._id,
            ...values,
        };
   
    dispatch(LabourActions.updateLabourEmergencyContact(params));
    }
    const handleChangePinCode= (e) => {
        const pincodeData = e.target.value
        formik.setFieldValue('emergencyContactPincode',pincodeData)
        setPincode(pincodeData);
    }
    useEffect(() => {
        if (pincode && pincode.length === 6) {
            dispatch(MasterDataActions.fetchDataByPincode(pincode));
        }
    }, [pincode]);
    useEffect(() => {
       if (pincode && locationData) { 
        formik.setFieldValue('emergencyContactAddress', locationData.PlaceName);
        formik.setFieldValue('emergencyContactState', locationData?.StateName);
        formik.setFieldValue('emergencyContactCity', locationData?.District);
        }
    }, [locationData]);

    // window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth',
    // });
    return (
        <Card>
            <Typography variant='h5' sx={{ mb: 4, fontWeight: '500' }}>Emergency Contact Details</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Person Name *"
                            name='emergencyContactPersonName'
                            value={formik.values.emergencyContactPersonName}
                            onChange={formik.handleChange}
                            error={formik.touched.emergencyContactPersonName && Boolean(formik.errors.emergencyContactPersonName)}
                            helperText={formik.touched.emergencyContactPersonName && formik.errors.emergencyContactPersonName}
                            />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Relation Name *"
                            name='emergencyContactRelation'
                            value={formik.values.emergencyContactRelation}
                            onChange={formik.handleChange}
                            error={formik.touched.emergencyContactRelation && Boolean(formik.errors.emergencyContactRelation)}
                            helperText={formik.touched.emergencyContactRelation && formik.errors.emergencyContactRelation}
                            />
                    </Grid> <Grid item lg={6} xs={12}>
                       <FormControl fullWidth>
                           <Typography variant='caption' sx={{fontSize: "15px"}}>Phone Number *</Typography>
                           <Box sx={{
                               display: 'flex',
                               gap: 1.5
                           }}>
                               <Box sx={{ width: 80 }}>
                                   <Input
                                       sx={{
                                           padding: "10px 8px",
                                           textAlign: 'center',
                                           '& .Mui-disabled': {
                                               fillColor: theme.palette.common.black
                                           }
                                       }}
                                       autoComplete='new-password'
                                       name='phoneCode'
                                       startAdornment='+'
                                       value="91"
                                      />
                               </Box>
                               <Input
                                   name='emergencyContactPersonPhone'
                                   value={formik.values.emergencyContactPersonPhone}
                                   onChange={formik.handleChange}
                                   error={formik.touched.emergencyContactPersonPhone && Boolean(formik.errors.emergencyContactPersonPhone)}
                                   helperText={formik.touched.emergencyContactPersonPhone && formik.errors.emergencyContactPersonPhone}/>
                           </Box>
                       </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Pincode *"
                            name='emergencyContactPincode'
                            value={formik.values.emergencyContactPincode}
                            onChange={handleChangePinCode}
                            error={formik.touched.emergencyContactPincode && Boolean(formik.errors.emergencyContactPincode)}
                            helperText={formik.touched.emergencyContactPincode && formik.errors.emergencyContactPincode}
                            />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="State *"
                            name='emergencyContactState'
                            value={formik.values.emergencyContactState}
                            error={formik.touched.emergencyContactState && Boolean(formik.errors.emergencyContactState)}
                            helperText={formik.touched.emergencyContactState && formik.errors.emergencyContactState}
                            disabled
                            />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="City *" 
                            name='emergencyContactCity'
                            value={formik.values.emergencyContactCity}
                            error={formik.touched.emergencyContactCity && Boolean(formik.errors.emergencyContactCity)}
                            helperText={formik.touched.emergencyContactCity && formik.errors.emergencyContactCity}
                            disabled
                            />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Typography variant='' sx={{fontSize: '15px'}}>Address *</Typography>
                        <TextareaAutosize
                            style={{
                            textAlign: "left",
                            width: "100%",
                            height: "80px",
                            background: '#f1f1f1',
                            borderRadius: '8px',
                            border: '1px solid #f1f1f1',
                            padding: '5px'
                            }}
                            name="emergencyContactAddress"
                            maxRows={4}
                            aria-label="maximum height"
                            value={formik.values.emergencyContactAddress}
                            onChange={formik.handleChange}
                            // error={formik.touched.emergencyContactAddress && Boolean(formik.errors.emergencyContactAddress)}
                            // helperText={formik.touched.emergencyContactAddress && formik.errors.emergencyContactAddress}
                        />
                         {(formik.touched.emergencyContactAddress && formik.errors.emergencyContactAddress) && (
                                 <FormHelperText error={formik.touched.emergencyContactAddress && Boolean(formik.errors.emergencyContactAddress)}>{formik.touched.emergencyContactAddress && formik.errors.emergencyContactAddress}</FormHelperText>
                                )}
                    </Grid>  
                     {labourDetails?.result?.status !== 99?(                  
                        <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained">
                                {loading?(
                                   <CircularProgress color="inherit" size={20}/>
                                ):(
                                  <>Submit</>  
                                )}
                            </Button>
                        </Grid>):null}
                </Grid>
            </form>
        </Card>
    )
}