import React from "react";
import {
    Card,
    Grid,
    Button,
    Link
} from "@mui/material";
import Input from "components/Input";
import * as yup from "yup";
import { useFormik } from "formik";
import { SubContractorActions } from "slices/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { UserSelector } from "selectors";

export default function PanCard() {

    const {id} = useParams()
    const dispatch = useDispatch();
    const subcontractorDetails = useSelector(UserSelector.getUserById());
    const validationSchema = yup.object({
        pan_card: yup.string().matches("[A-Z]{5}[0-9]{4}[A-Z]{1}","InValid PAN No").
        required("This field is required"),
        file: yup.string().required("This field is required"),
        // pan_card: yup.string().
        // matches(/[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}/,"Enter valid pan card").
        // required("This field is required"),
    });

    const formik = useFormik({
        initialValues: {
            type: "pan_card_docs",
            pan_card: subcontractorDetails?.pan_card??"",
            file: subcontractorDetails?.pan_card_docs??"",
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
          handleSubmit(values);
        },
      });
      const handleSubmit = (values) => {
        const params = {
            id: id,
            ...values
        }
        
         dispatch(SubContractorActions.uploadSubcontractorDocument(params));
      };

    const handleUploadImage = (e) => {
        const file = e.target.files[0];
        formik.setFieldValue("file", file);
     }
    
    return (
        <Card className="projectcard text-center position-relative">
            <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                <Grid item lg={12} xs={12}>
                    <Input
                        label="Pancard Number"
                        name='pan_card'
                        value={formik.values.pan_card}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.pan_card) && Boolean(formik.errors.pan_card)}
                        helperText={formik.touched.pan_card && formik.errors.pan_card}
                        />

                </Grid> 
                <Grid item lg={12} xs={12}>
                    <Input className="uploadinput"
                        label="Upload Pancard photo"
                        type="file"
                        name='uploadPhoto'
                        onChange={handleUploadImage}
                        error={Boolean(formik.touched.file) && Boolean(formik.errors.file)}
                        helperText={formik.touched.file && formik.errors.file}
                    />
                </Grid> 
            </Grid>
            {subcontractorDetails.pan_card_docs?(
                                <Link href={subcontractorDetails.pan_card_docs} target='_blank' >Pancard</Link>
                            ):(
                                null)
            }
            <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    >
                    Submit
                </Button> 
            </Grid>
            </form>
        </Card>
    )
}