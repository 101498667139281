import {createSelector} from "@reduxjs/toolkit";

const holidaySelector = (state) => state.holiday;

const getHolidayById = () => createSelector(
  holidaySelector,
  holiday => holiday.holidayList
);
const getHolidayDeatils = () => createSelector(
  holidaySelector,
  holiday => holiday.holidayDetails
);

export const HolidaySelector = {
    getHolidayById,
    getHolidayDeatils
  }