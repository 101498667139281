import React from "react";
import {
    Card,
} from "@mui/material";
import AadharCard from "./AadharCard";
// import RationCard from "./RationCard";
import PanCard from "./PanCard";
import Passport from "./Passport";

export default function KYC() {
    // window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth',
    // });
    return (
        <Card>
            <AadharCard />
            {/* <RationCard /> */}
            <PanCard />
            <Passport />
        </Card>
    )
}