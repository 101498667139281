import {createSelector} from "@reduxjs/toolkit";

const citySelector = (state) => state.city;

const getCityList = () => createSelector(
    citySelector,
    city => city.cityList
);

const getPagination = () => createSelector(
    citySelector,
    city => city.pagination
);

const getCityById = () => createSelector(
    citySelector,
    city => city.cityData
);

const submitCity = () => createSelector(
    citySelector,
    city => {
        if (city.submit.success) {
            return {
                success: true,
                message: city.submit.success
            }
        }

        return {
            success: false,
            message: city.submit.error
        }
    }
)

const deleteCity = () => createSelector(
    citySelector,
    city => {
        if (city.delete.success) {
            return {
                success: true,
                message: city.delete.success
            }
        }

        return {
            success: false,
            message: city.delete.error
        }
    }
)

export const CitySelector = {
    getCityList,
    getPagination,
    getCityById,
    submitCity,
    deleteCity
}