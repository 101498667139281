import React, {useState} from "react";
import {
    Card, Box,
    // Chip, FormControl,
    Grid, IconButton, InputAdornment,
    // MenuItem,
    Typography, FormControl
} from "@mui/material";
// import ROLES from "constants/role";
// import Box from "@mui/material/Box";
import Input from "components/Input";
// import SelectField from "components/SelectField";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import PropTypes from "prop-types";

AccountSetting.propTypes = {
    formik: PropTypes.object
};

export default function AccountSetting(props) {
    const { formik } = props;

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Card sx={{ mb: 3 }}>
                <Typography variant='h5' sx={{ mb: 4 }}>Admin Details</Typography>
            <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Email"
                        type="email"
                        name='userEmail'
                        value={formik.values.userEmail}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.userEmail) && Boolean(formik.errors.userEmail)}
                        helperText={formik.touched.userEmail && formik.errors.userEmail}/>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Password"
                        inputProps={{
                            autoComplete: "new-password"
                        }}
                        placeholder='●●●●●●●●●●'
                        type={showPassword ? 'text' : 'password'}
                        name='password'
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {!showPassword ? <VisibilityOff/> : <Visibility/> }
                                </IconButton>
                            </InputAdornment>
                        }
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}/>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Name"
                        type="text"
                        name='userName'
                        value={formik.values.userName}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.userName) && Boolean(formik.errors.userName)}
                        helperText={formik.touched.userName && formik.errors.userName}/>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <FormControl fullWidth>
                        <Typography variant='' sx={{fontSize: "15px"}}>Phone Number</Typography>
                        <Box sx={{
                            display: 'flex',
                            gap: 1.5
                        }}>
                            <Box sx={{ width: 90 }}>
                                <Input style={{appearence: 'none'}}
                                    sx={{
                                        padding: "10px 8px",
                                        textAlign: 'center',
                                        // '& .Mui-disabled': {
                                        //     fillColor: theme.palette.common.black
                                        // }
                                    }}
                                    autoComplete='new-password'
                                    name='phoneCode'
                                    startAdornment='+'
                                    value="91"
                                    />
                            </Box>
                            <Input
                                name='phone'
                                type="number"    
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}/>
                        </Box>
                    </FormControl>
                   
                </Grid>
               
            </Grid>
        </Card>
    )
}