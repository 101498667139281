import React, {useEffect} from "react";
import styled from "@emotion/styled";
import {Box} from "@mui/material";
// Hidden
// import {ReactComponent as Logo} from "assets/ll.svg";
// import {ReactComponent as Illustration} from 'assets/login-illustration.svg';
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {UserActions} from "../slices/actions";

const Root = styled(Box)(() => ({
    // width: '100%',
    // height: "100vh",
    // margin: "5% auto",
    // display: "flex",
    justifyContent: "center",
    alignItems: "center"
}));

// const Content = styled(Box)(({ theme }) => ({
//     width: '50%',

//     [theme.breakpoints.down('sm')]: {
//         width: '100%',
//         padding: 20
//     }
// }));

AuthLayout.propTypes = {
    children: PropTypes.any
};

export default function AuthLayout({children}) {
    const dispatch = useDispatch();

    useEffect(() => {
        const token = localStorage.getItem("merakihr-token");
        if (token) {
            console.log('token',token)
            dispatch(UserActions.profileUser());
        }
    }, [dispatch]);

    return (
        <Root>
            <section className="loginSection py-0 w-100">
                <div className="container-fluid py-0">
                    <div className="g-0 min-vh-100 flex-center row" style={{alignItems: "center"}}>
                        <div className="py-3 position-relative col-xxl-5 col-lg-8 box-shadow">
                            <img className="bg-auth-circle-shape" src={`${process.env.REACT_APP_URL}/img/bg-shape.png`} alt="" width="250" />
                            <img className="bg-auth-circle-shape-2" src={`${process.env.REACT_APP_URL}/img/shape-1.png`} alt="" width="150" />
                            <div className="overflow-hidden z-index-1 card" style={{borderRadius: "20px", backgroundColor: "#f2f6f7"}}>
                                <div className="p-0 card-body">
                                    <div className="h-100 g-0 row" style={{alignItems: "center"}}>
                                        <div className="text-white text-center col-md-6 border-right">
                                            <div className="position-relative px-4 pt-md-5 pb-md-7">
                                                {/* <div className="bg-holder bg-auth-card-shape" style={{backgroundImage: `url("${process.env.REACT_APP_URL}/img/half-circle.png")`}}> */}
                                                <div className="">
                                                    <img src={`${process.env.REACT_APP_URL}/img/construction.svg`} width="100%" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center col-md-6">
                                            <div className="p-4 p-md-5 flex-grow-1">
                                                <div className="text-center">
                                                    <img src={`${process.env.REACT_APP_URL}/logo.png`} className="d-block mx-auto mb-4" alt="logo" width="70%" height= "" />
                                                    {/* <h3>Welcome Login</h3> */}
                                                    {children}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {/* <Hidden smDown>
                                <Box>
                                    <Illustration width={500} height={500}/>
                                </Box>
                            </Hidden>
                            <Content>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Logo width={200} height={100}/>
                                </Box>

                                {children}
                            </Content> */}
                        </div>
                    </div>
                </div>
            </section>
        </Root>
    )
}