import {get, post, patch, del} from "../utils/api";

const API_URL = process.env.REACT_APP_API_URL 

const GetStateList1 = async (params) => get(`${API_URL}/state`,params);

const CreateState = async (params) => post(`${API_URL}/state`, params);

const GetStateById = async(id) => get(`${API_URL}/state/${id}`);

const UpdateState = async (id,params) => patch(`${API_URL}/state/${id}`, params);

const DeleteState = async (id) => del(`${API_URL}/state/${id}`);

const GetAllState = async () => get(`${API_URL}/state/allState`)

export const StateService = {
    GetStateList1,
    DeleteState,
    CreateState,
    GetStateById,
    UpdateState,
    GetAllState
};