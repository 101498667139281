import React, {useEffect, useState} from "react";
import {
     Box, Card, Table, TableBody, TableCell, Grid, Tooltip,
     Pagination,
     TableHead, TableRow,
     MenuItem,
     IconButton,
     Hidden,Chip
} from "@mui/material";
import Typography from "@mui/material/Typography";
import FloatingButton from "components/FloatingButton";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Delete, Edit} from "@mui/icons-material";
import {GeneralSelector, ProjectSelector,SubContractorSelector} from "selectors";
import {CompanyActions, GeneralActions, LabourActions, MasterDataActions, ProjectActions, SubContractorActions} from "slices/actions";
import DialogConfirm from "components/DialogConfirm";
import {toast} from "react-toastify";
import {DefaultSort} from "constants/sort";
import ListSkeleton from "../../components/Skeleton/ListSkeleton";
import { LabourSelector } from "selectors/LabourSelector";
import styled from "@emotion/styled";
import SelectField from "components/SelectField";
import Input from "components/Input";
import UploadFileSharpIcon from '@mui/icons-material/UploadFileSharp';
import LabourUploadDialog from "./LabourUploadDialog";
import { Constants } from "utils/constants";
import { CompanySelector } from "selectors/CompanySelector";
import { format } from 'date-fns';
import StatusChange from "components/StatusChangeDialog";

const FilterBox = styled(Box)(() => ({
    width: "100%",
    marginTop: 30,
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-between"
}));

function LabourLIsting() {
     const history = useHistory()
     const dispatch = useDispatch();
     const labourListData = useSelector(LabourSelector.getLabourList());
     const companyList = useSelector(CompanySelector.getCompanyList());
     const projectList = useSelector(ProjectSelector.getProjectList());
     const pagination = useSelector(LabourSelector.getPagination());
     const loading = useSelector(GeneralSelector.loader(LabourActions.getLabourList.type));
     const success = useSelector(GeneralSelector.success(LabourActions.deleteLabour.type));
     const error = useSelector(GeneralSelector.error(LabourActions.deleteLabour.type));
    //  const date_status=useSelector(MasterDataActions.changeStatus())
     const changeStatusSuccess = useSelector(GeneralSelector.success(MasterDataActions.changeStatus.type));
     const changeStatusError = useSelector(GeneralSelector.error(MasterDataActions.changeStatus.type));
     const [filter, setFilter] = useState({
        sort: DefaultSort.newest.value,
        page: 1,
     });
    const [openIdDialog,setOpenIdDialog] = useState(false);
    const [selected, setSelected] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selectedProjectId,setSelectedProjectId] = useState();
    const userType = localStorage.getItem('userType')
    const companyId = localStorage.getItem('companyID')
    const projectId = localStorage.getItem('projectID')
    const subcontractorId = localStorage.getItem('subcontractorID')
    const [company,setCompany] = useState(companyId?companyId:"");
    const [project,setProject] = useState(projectId?projectId:"");
    const [subcontractor,setSubcontractor] = useState(subcontractorId?subcontractorId:"");
    const [labourStatus,setLabourStatus] = useState("");
    const [assignedProject,setAssignedProject] = useState("");
    const [changeStatus,setChangeStatus] = useState(false);
    const [status,setStatus] = useState();
    const role = useSelector(LabourSelector.role());
    const assignedProjectToSubcontractorList = useSelector(SubContractorSelector.getAssignedSubcontractor());

    useEffect(() => {
        if (!companyId) {
           dispatch(CompanyActions.getCompanyList())
        } 
        if (companyId && !projectId) {
            const params = {
                company_id: companyId,
                status: 1
             }
            dispatch(ProjectActions.getProjectList(params))
        } 
        if (companyId && projectId) {
            const params = {
                company_id: companyId,
                project_id: projectId,
             }
             dispatch(SubContractorActions.getAssignedSubContractor(params)) 
        } 
         if (subcontractorId) {
            const params = {
                subcontractorId: subcontractorId
            }
             dispatch(SubContractorActions.getAssignedSubContractor(params));
        }
        console.log(labourListData)
    },[])

  useEffect(() => {
    const params = {
        ...filter,
        company_id: company?company:"",
        project_id: project?project:"",
        subcontractor_id: subcontractor?subcontractor:"" 
    }
    dispatch(LabourActions.getLabourList(params));
}, [filter]);

useEffect(() => {
    if (changeStatusSuccess) {
        setLabourStatus(null);
        setSelected(null);
        toast.success(`${changeStatusSuccess?.message ?? "Success"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true
            });
  
        dispatch(GeneralActions.removeSuccess(MasterDataActions.changeStatus.type));
        const params = {
            ...filter,
            company_id: company?company:"",
            project_id: project?project:"",
            subcontractor_id: subcontractor?subcontractor:"" 
        }
        dispatch(LabourActions.getLabourList(params));
        setChangeStatus(false);
      }
  }, [changeStatusSuccess]);

  useEffect(() => {
    if (changeStatusError) {
       toast.error(`${changeStatusError?.message ?? "Error"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true
            });
  
        dispatch(GeneralActions.removeSuccess(MasterDataActions.changeStatus.type));
        setChangeStatus(false);
      }
  }, [changeStatusError]);


useEffect(() => {
  if (success) {
      setConfirmDelete(false);
      setSelected(null);

      toast.success(`${success?.message ?? "Success"}`, {
              position: "top-right",
              autoClose: 3000,
              closeOnClick: true
          });

      dispatch(GeneralActions.removeSuccess(LabourActions.deleteLabour.type));
      const params = {
        ...filter,
        company_id: company?company:"",
        project_id: project?project:"",
        subcontractor_id: subcontractor?subcontractor:"" 
      }
     dispatch(LabourActions.getLabourList(params));
    }
}, [success]);

useEffect(() => {
    if (error) {
        setConfirmDelete(false);
        setSelected(null);
  
        toast.error(`${error?.message ?? "Error"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true
            });
  
        dispatch(GeneralActions.removeError(LabourActions.deleteLabour.type));
    }
  }, [error]);

const handleChangeFilter = ({ target }) => {
    const {name, value} = target;

    setFilter({
        ...filter,
        [name]: value
    });
}

const handleChangePagination = (e, val) => {
    setFilter({
        ...filter,
        page: val
    });
};

const handleDelete = () => {
   dispatch(LabourActions.deleteLabour(selected));
}

const handleChangeCompany = (e) => {
    const cId = e.target.value
    setCompany(cId)
    setProject("")
    setSubcontractor("")
   
    if (cId) {
      const params = {
        company_id: cId,
        status: 1
      }
      dispatch(ProjectActions.getProjectList(params))
      const searchByCompany = {
           company_id: cId,
           ...filter,
    }
        dispatch(LabourActions.getLabourList(searchByCompany));
    }
  }
  const handleChangeProject = (e) => {
    const pId = e.target.value
    setProject(pId)
    setSubcontractor("")
    if (pId) {
    const params = {
        company_id: company || companyId,
        project_id: pId,
        
    }
    dispatch(SubContractorActions.getAssignedSubContractor(params))
    const searchByProject = {
        company_id: company || companyId,
        project_id: pId,
        ...filter,

     }
     dispatch(LabourActions.getLabourList(searchByProject));
    }
}
  
  const handleChangeSubcontractor = (e) => { 
    const sId = e.target.value
    if (sId) {
    setSubcontractor(sId)
    const searchBySubcontractor = {
        company_id: company || companyId,
        project_id: project || projectId ,
        subcontractor_id: sId,
        ...filter,

     }
     dispatch(LabourActions.getLabourList(searchBySubcontractor));
    }
}
const handleChangeStatus = (e) => {
    setLabourStatus(e.target.value) 
    const searchByStatus = {
        company_id: company || companyId,
        project_id: project || projectId ,
        subcontractor_id: subcontractor || subcontractorId,
        status: e.target.value ,
        ...filter,

    }
     dispatch(LabourActions.getLabourList(searchByStatus));  
}
const handleChangeProjectBySubcontractor = (e) => {
    setAssignedProject(e.target.value)
    for (const i in assignedProjectToSubcontractorList.data) {
        if (assignedProjectToSubcontractorList.data[i].projectId._id === e.target.value) {
            const searchByProject = {
                    project_id: e.target.value,
                    company_id: assignedProjectToSubcontractorList.data[i].companyId._id,
                    subcontractor_id: subcontractorId,
                    ...filter,

                }
            dispatch(LabourActions.getLabourList(searchByProject));
        }
    }
}

return (
      
  <Card>
      <Typography variant="h5" sx={{ fontWeight: 600, position: 'relative' }}>All Labour
        <UploadFileSharpIcon className="Badgeico cursor-pointer" onClick={() => setOpenIdDialog(true)} color='primary' />
      </Typography>
     <FilterBox>
        <Grid container spacing={3} justifyContent="space-between">
            <Grid item lg={4} sm={12} xs={12}>
                <Input
                    label="Search"
                    name='keyword'
                    placeholder='Search by name'
                    value={filter.keyword ?? ''}
                    onChange={handleChangeFilter}/>
            </Grid>
            {userType === Constants.SUPERADMIN ?(
              <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Company</Typography>
                <SelectField
                    value={company}
                    onChange={handleChangeCompany}
                    >
                    {companyList && companyList.map((item,index) => (
                    <MenuItem key={index} value={item._id}>
                            {item.name}
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
            {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER ?(
              <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Project</Typography>
                <SelectField
                    value={project}
                    onChange={handleChangeProject}
                    >
                    {(company && projectList) && projectList.map((item,index) => (
                    <MenuItem key={index} value={item._id}>
                            {item.name}
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
            {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER || userType === Constants.PROJECT_USER?(
              <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Subcontractor</Typography>
                <SelectField
                    value={subcontractor}
                    onChange={handleChangeSubcontractor}
                    >
                    {(project && assignedProjectToSubcontractorList.data) && assignedProjectToSubcontractorList.data.map((item,index) => (
                    <MenuItem key={index} value={item?.subcontractorId?._id}>
                             {item?.subcontractorId?.subContractorName}
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
             {userType === Constants.SUB_CONTRACTOR_USER ?(
              <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Project</Typography>
                <SelectField
                    value={assignedProject}
                    onChange={handleChangeProjectBySubcontractor}
                    >
                    {assignedProjectToSubcontractorList && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                    <MenuItem key={index} value={item?.projectId?._id}>
                            {item?.projectId?.name}({item?.companyId?.name})
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
            <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Status</Typography>
                <SelectField
                    value={labourStatus}
                    onChange={handleChangeStatus}
                    >
                    <MenuItem value={1}>
                        Approved
                    </MenuItem>
                    <MenuItem value={2}>
                        Rejected
                    </MenuItem>
                    <MenuItem value={0}>
                        Pending
                    </MenuItem>
                </SelectField> 
              </Grid>
            <Grid item lg={2} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Sort by</Typography>
                <SelectField
                    value={filter.sort}
                    name="sort"
                    onChange={handleChangeFilter}>
                    {Object.keys(DefaultSort).map((key) => (
                        <MenuItem key={key} value={DefaultSort[key].value}>
                            {DefaultSort[key].name}
                        </MenuItem>
                    ))}
                </SelectField> 
            </Grid>
        </Grid>
       
    </FilterBox>
    

      {loading ? (
          <ListSkeleton/>
      ) : (
          <Box>
              <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell>Name</TableCell>
                          <Hidden smDown>
                              <TableCell>AadharNo</TableCell>
                              <TableCell>Phone</TableCell>
                              <TableCell>DOJ</TableCell>
                              <TableCell>Project Labour Code</TableCell>
                              <TableCell align="center">Status</TableCell>
                          </Hidden>
                          <TableCell align="right">Options</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {labourListData && labourListData.length === 0 && (
                          <TableRow>
                              <TableCell align="center" colSpan={7}>
                                  No Data
                              </TableCell>
                          </TableRow>
                      )}
                      {labourListData && labourListData.map((item, i) => (
                          <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                              <TableCell>
                                  <Box sx={{
                                      display: 'flex',
                                      alignItems: 'center',

                                      '& img': {
                                          borderRadius: 50,
                                      }
                                  }}>
                                    <Typography sx={{ ml: 2 }} variant='subtitle2'>
                                        {`${item.firstName} ${"  "} ${item.lastName}`} <br />
                                        <span style={{ fontSize: 12, color: "#3c5a99" }}>
                                         {item.projectname} (P)
                                        </span>{" "}
                                         <br />
                                        <span style={{ fontSize: 12, color: "#0077b5" }}>
                                            {item.companyName} (C)
                                        </span>{" "}
                                        <br />
                                        <span style={{ fontSize: 12, color: "#0077b5" }}>
                                            {item.subcontractorName} (S)
                                        </span>
                                    </Typography>
                                  </Box>
                              </TableCell>
                              <Hidden smDown>
                                  <TableCell>{item.aadharNo}</TableCell>
                                  <TableCell>{item.mobileNumber}</TableCell>
                                  <TableCell>{item.doj && format(new Date(item.doj), 'dd/MM/yyyy')}</TableCell>
                                  <TableCell>{item.projectLabourCode}</TableCell>
                                  <TableCell align="center">
                                    
                                    {item.status === 1 && <Chip label="Approve" color="primary" disabled onClick={() => {
                                            setChangeStatus(true);
                                            setStatus(item.status);
                                            setSelected(item._id);
                                            setSelectedProjectId(item.projectId)

                                        }}/>}
                                    {item.status === 2 && <Chip label="Reject" disabled onClick={() => {
                                            setChangeStatus(true);
                                            setStatus(item.status);
                                            setSelected(item._id);
                                            setSelectedProjectId(item.projectId)
                                     }}/>}
                                    {item.status === 0 && <Chip label="Pending" color="warning" onClick={() => {
                                            setChangeStatus(true);
                                            setStatus(item.status);
                                            setSelected(item._id);
                                            setSelectedProjectId(item.projectId)
                                 }}/>}
                                  </TableCell>
                              </Hidden>
                              <TableCell align="right" width='20%'>
                              <Hidden smDown>
                                {role && Object.keys(role).map((item1,index1) => (
                                <div key={index1} style={{display: 'inline-block'}}>
                                   {item1 === "Details"?(
                                    <Tooltip title="Edit">
                                        <IconButton onClick={() => history.push(`/app/labour/update/${item._id}`)}>
                                            <Edit className="text-primary" />
                                        </IconButton>
                                    </Tooltip>):null}
                                    {item1 === "Delete"?(
                                    <Tooltip title="Delete">
                                        <IconButton onClick={() => {
                                            setConfirmDelete(true);
                                            setSelected(item._id);
                                        }}>
                                            <Delete className="text-default" />
                                        </IconButton>
                                    </Tooltip>):null}
                                    </div>
                                    ))}
                                  
                                   </Hidden>
                                 </TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>
              <Pagination
                  sx={{ mt: 1 }}
                  page={filter.page}
                  count={pagination && pagination.pages}
                  onChange={handleChangePagination}/>
          </Box>
      )}
      {role && Object.keys(role).map((item2,index) => (
      <div key={index}>
       {item2 === "Add"?(
          <FloatingButton
              onClick={() => history.push("/app/labour/create")}/>
              ):null}
              </div>
         ))}

      <DialogConfirm
          title="Delete Labour"
          content="Are you sure want to delete this labour data?"
          open={confirmDelete}
          onClose={() => setConfirmDelete(false)}
          onSubmit={handleDelete}
        />
        <LabourUploadDialog
            open={openIdDialog}
            onClose={() => setOpenIdDialog(false)}
        /> 
        <StatusChange
            title="Change Status"
            content="Are you sure to change this status?"
            open={changeStatus}
            onClose={() => setChangeStatus(false)}
            status={status}
            labourId={selected}
            projectId={selectedProjectId}
        />
  </Card>
)
}

export default LabourLIsting;