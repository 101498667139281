import React, { useEffect } from "react";
import { Button, Grid, Box, Card, Typography } from "@mui/material";
import PageTitle from "components/PageTitle";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Input from "components/Input";
import { GeneralActions, SiteActions } from "slices/actions";
import { GeneralSelector, SiteSelector } from "selectors";
import CircularProgress from '@mui/material/CircularProgress';
import ListSkeleton from "components/Skeleton/ListSkeleton";
import TextareaAutosize from '@mui/material/TextareaAutosize';

export default function SiteDetail () {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const loadingData = useSelector(GeneralSelector.loader(SiteActions.getSiteDetailsById.type));
  const siteDetails = useSelector(SiteSelector.getSiteDetailsById());
  const loading = useSelector(GeneralSelector.loader(SiteActions.updateSite.type));
  const success = useSelector(GeneralSelector.success(SiteActions.updateSite.type));
  const error = useSelector(GeneralSelector.error(SiteActions.updateSite.type));

  useEffect(() => {
    dispatch(SiteActions.getSiteDetailsById(id))
  },[])

  useEffect(() => {
    if (success) {
        toast.success(`${success?.message ?? "Success"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });

        dispatch(GeneralActions.removeSuccess(SiteActions.updateSite.type));
        history.goBack()
    }
  }, [success]);

  useEffect(() => {
    if (error) {
        toast.success(`${error?.message ?? "Error"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });

        dispatch(GeneralActions.removeError(SiteActions.updateSite.type));
    }
  }, [success]);

  const validationSchema = yup.object({
    name: yup.string().required("Site name is required"),
    address: yup.string().required("Adress is required"),
  });
  const formik = useFormik({
    initialValues: {
      name: siteDetails?.name??"",
      address: siteDetails?.address??"",
      id: siteDetails?._id??"",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const handleSubmit = (values) => {
    dispatch(SiteActions.updateSite(values));
  };

  return (
    <Box className="position-relative">
      <PageTitle isBack={true} title="Site Details" />
        <Grid container justifyContent="center" sx={{ mt: 3 }}>
           {loadingData?(
            <ListSkeleton/>
            ):(
              <Card sx={{maxWidth: '500px', width: '100%', margin: '0 auto'}}>
                  <form onSubmit={formik.handleSubmit}>
                    <Grid item lg={12} sm={12} xs={12}>
                      <Input sx={{ mb: 2 }}
                      label="Site Name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={
                          Boolean(formik.touched.name) && Boolean(formik.errors.name)
                      }
                      helperText={formik.touched.name && formik.errors.name}
                      />
                    </Grid>
                    <Grid item lg={12} sm={12} xs={12} sx={{ my: 2 }}>
                      <Typography variant='' sx={{fontSize: '15px'}}>Address</Typography>
                      <TextareaAutosize
                        style={{
                            textAlign: "left",
                            width: "100%",
                            height: "80px",
                            background: '#f1f1f1',
                            borderRadius: '8px',
                            border: '1px solid #f1f1f1',
                            padding: '5px'
                        }}
                        name="address"
                        maxRows={4}
                        aria-label="maximum height"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        error={formik.touched.address && Boolean(formik.errors.address)}
                        helperText={formik.touched.address && formik.errors.address}
                      />
                    </Grid>   
                    <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {loading?<CircularProgress color="inherit" size={20}/>:<>Update</> 
                        }
                      </Button>
                    </Grid>
                  </form>
              </Card>
            )}
        </Grid>
    </Box>
  );
}
