import React ,{useEffect,useState} from 'react';
import {
//   Card,
  FormControl,
  Grid,
  Typography,
 } from "@mui/material";
import PropTypes from 'prop-types';
import Input from "components/Input";
import { useSelector,useDispatch } from 'react-redux';
import { MasterDataSelector } from 'selectors';
import { MasterDataActions } from 'slices/actions';


LabourAddress.propTypes = {
  formik: PropTypes.object,
}

function LabourAddress (props) {
  const {formik} = props
  const dispatch = useDispatch();
  const permanetLoactionData = useSelector(MasterDataSelector.getLocationListByPincode());
  const presentLocationData = useSelector(MasterDataSelector.getLocationListByPincode());
  const [permanetPincode,setPermanetPincode]= useState();
  const [presentPincode,setPresentPincode]= useState(); 

  const handleChangePermanetPinCode= (e) => {        
    const pincodeData = e.target.value
    formik.setFieldValue('permanentAddressPincode',pincodeData)
    setPermanetPincode(pincodeData);
  }
  const handleChangePresentPinCode= (e) => {        
    const pincodeData = e.target.value
    formik.setFieldValue('currentPincode',pincodeData)
    setPresentPincode(pincodeData);
 }
 useEffect(() => {
    if (permanetPincode && permanetPincode.length === 6) {
        dispatch(MasterDataActions.fetchDataByPincode(permanetPincode));
    }
}, [permanetPincode]); 

useEffect(() => {
    if (presentPincode && presentPincode.length === 6) {
        dispatch(MasterDataActions.fetchDataByPincode(presentPincode));
    }
}, [presentPincode]); 

useEffect(() => {
    if (permanetLoactionData && permanetPincode) {
    formik.setFieldValue('permanentAddressState', permanetLoactionData.StateName?permanetLoactionData.StateName :'');
    formik.setFieldValue('permanentAddressCity', permanetLoactionData.District?permanetLoactionData.District :'');
    formik.setFieldValue('permanentHomeAddress', permanetLoactionData.PlaceName?permanetLoactionData.PlaceName :'');
 }
 setPermanetPincode('')
}, [permanetLoactionData]);

useEffect(() => {
    if (presentLocationData && presentPincode) {
    formik.setFieldValue('currentState', presentLocationData.StateName?presentLocationData.StateName :'');
    formik.setFieldValue('currentCity', presentLocationData.District?presentLocationData.District :'');
    formik.setFieldValue('currentAddress', presentLocationData.District?presentLocationData.PlaceName :'');
}
setPresentPincode('')
}, [presentLocationData]);


return (
    <>
    <Typography variant='h5' sx={{ my: 4, textAlign: "left", borderBottom: "1px solid #ddd", fontWeight: "normal" }}>Permanent Address</Typography>
    <Grid container spacing={3}>
          <Grid item lg={6} xs={12}>
               <FormControl fullWidth>
                    <Input
                          label='Pincode *'
                           name='permanentAddressPincode'
                           value={formik.values.permanentAddressPincode}
                           onChange={handleChangePermanetPinCode}
                           error={formik.touched.permanentAddressPincode && Boolean(formik.errors.permanentAddressPincode)}
                           helperText={formik.touched.permanentAddressPincode && formik.errors.permanentAddressPincode}/>
               </FormControl>
           </Grid>
            <Grid item lg={6} xs={12}>
                <Input
                    label="State *"
                    name='permanentAddressState'
                    value={formik.values.permanentAddressState}
                    error={formik.touched.permanentAddressState && Boolean(formik.errors.permanentAddressState)}
                    helperText={formik.touched.permanentAddressState && formik.errors.permanentAddressState}
                    />
            </Grid>
            <Grid item lg={6} xs={12}>
                <Input
                    label="City *"
                    name='permanentAddressCity'
                    value={formik.values.permanentAddressCity}
                    error={formik.touched.permanentAddressCity && Boolean(formik.errors.permanentAddressCity)}
                    helperText={formik.touched.permanentAddressCity && formik.errors.permanentAddressCity}
                    />
            </Grid>
            <Grid item lg={6} xs={12}>
                <Input
                    label="Address *"
                    name='permanentHomeAddress'
                    value={formik.values.permanentHomeAddress}
                    // onChange={formik.handleChange}
                    error={formik.touched.permanentHomeAddress && Boolean(formik.errors.permanentHomeAddress)}
                    helperText={formik.touched.permanentHomeAddress && formik.errors.permanentHomeAddress}
                    // disabled={Can(actions.readSome, features.user)}
                    />
            </Grid>
            <Grid item lg={12} xs={12} className="my-3">
                <Typography variant="h5" sx={{textAlign: "left", borderBottom: "1px solid #ddd", m: "0", fontWeight: "normal"}}>Current Address</Typography>
            </Grid>
            <Grid item lg={6} xs={12}>
                <Input
                    label="Pincode *"
                    name='currentPincode'
                    value={formik.values.currentPincode}
                    onChange={handleChangePresentPinCode}
                    error={formik.touched.currentPincode && Boolean(formik.errors.currentPincode)}
                    helperText={formik.touched.currentPincode && formik.errors.currentPincode}
                    // disabled={Can(actions.readSome, features.user)}
                    />
            </Grid>
            <Grid item lg={6} xs={12}>
                <Input
                    label="State *"
                    name='currentState'
                    value={formik.values.currentState}
                    // onChange={formik.handleChange}
                    error={formik.touched.currentState && Boolean(formik.errors.currentState)}
                    helperText={formik.touched.currentState && formik.errors.currentState}
                    // disabled={Can(actions.readSome, features.user)}
                    />
            </Grid>
            <Grid item lg={6} xs={12}>
                <Input
                    label="City *"
                    name='currentCity'
                    value={formik.values.currentCity}
                    // onChange={formik.handleChange}
                    error={formik.touched.currentCity && Boolean(formik.errors.currentCity)}
                    helperText={formik.touched.currentCity && formik.errors.currentCity}
                    // disabled={Can(actions.readSome, features.user)}
                    />
            </Grid>
            <Grid item lg={6} xs={12}>
                <Input
                    label="Address *"
                    name='currentAddress'
                    value={formik.values.currentAddress}
                    // onChange={formik.handleChange}
                    error={formik.touched.currentAddress && Boolean(formik.errors.currentAddress)}
                    helperText={formik.touched.currentAddress && formik.errors.currentAddress}
                    // disabled={Can(actions.readSome, features.user)}
                    />
            </Grid>
      </Grid>
   
</>
  
  )
}

export default LabourAddress