import React, { useEffect } from "react";
import {
    Box,
    Card, Table, TableBody, TableCell, TableHead, TableRow,
    Typography
 } from "@mui/material";
import {useParams} from "react-router-dom";
import { LabourActions } from "slices/actions";
import { useDispatch, useSelector } from "react-redux";
import { LabourSelector } from "selectors";
import { format } from 'date-fns';

export default function LabourHistory() {
    const dispatch = useDispatch();
    const {id} = useParams();
    const labourHistoryListing = useSelector(LabourSelector.getLabourHistory())
   
    useEffect(() => {
        if (id) {
        dispatch(LabourActions.getLabourHistory(id))
        }
        
    },[id])
    return (
        <Card sx={{position: "relative"}}>
            <Typography variant='h5' sx={{ mb: 4 }}>History</Typography>
          <Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Last Company</TableCell>
                            <TableCell>Last Project</TableCell>
                            <TableCell>Last Subcontractor</TableCell>
                            <TableCell>DOJ</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {labourHistoryListing && labourHistoryListing.map((item,index) => (
                        <TableRow key={index}>
                            <TableCell align="left">
                                {item.companyName}
                            </TableCell>
                            <TableCell align="left">
                                {item.projectname}
                            </TableCell>
                            <TableCell align="left">
                                {item.subContractorName}
                            </TableCell>
                            <TableCell align="left">
                                {format(new Date(item.doj), 'dd/MM/yyyy')}
                            </TableCell>
                          
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </Box>    
           
        </Card>
    )
}