import React, { useState,useEffect } from "react";
import {
    Box, MenuItem,
    Grid, Typography,
} from "@mui/material";
import Input from "components/Input";
import SelectField from "components/SelectField";
import PropTypes from "prop-types";
import { MasterDataSelector } from "selectors";
import { useDispatch, useSelector } from "react-redux";
import { MasterDataActions } from "slices/actions";

NomineeInfo.propTypes = {
    formik: PropTypes.object
};
export default function NomineeInfo(props) {
    const { formik } = props;
    const dispatch = useDispatch();
    const locationData = useSelector(MasterDataSelector.getLocationListByPincode());
    const [pincode,setpincode]= useState();

    const handleChangePinCode= (e) => {        
        const pincodeData = e.target.value
        formik.setFieldValue('pfNomineInfo_Pincode',pincodeData)
        setpincode(pincodeData);
     }
    useEffect(() => {
        if (pincode && pincode.length === 6) {
            dispatch(MasterDataActions.fetchDataByPincode(pincode));
        }
    }, [pincode]); 
    
    useEffect(() => {
        if (locationData && pincode) {
        formik.setFieldValue('pfNomineInfo_District', locationData.District?locationData.District :'');
        formik.setFieldValue('pfNomineInfo_State', locationData.StateName?locationData.StateName :'');

     }
     setpincode('')
    }, [locationData]);
    

   return (
        <Box>
            <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Employee No. *"
                            name='pfNomineInfo_EmployeeNo'
                            value={formik.values.pfNomineInfo_EmployeeNo}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfo_EmployeeNo && Boolean(formik.errors.pfNomineInfo_EmployeeNo)}
                            helperText={formik.touched.pfNomineInfo_EmployeeNo && formik.errors.pfNomineInfo_EmployeeNo}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="PF Nominee Name *"
                            name='pfNomineInfo_PFNomineeName'
                            value={formik.values.pfNomineInfo_PFNomineeName}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfo_PFNomineeName && Boolean(formik.errors.pfNomineInfo_PFNomineeName)}
                            helperText={formik.touched.pfNomineInfo_PFNomineeName && formik.errors.pfNomineInfo_PFNomineeName}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Typography sx={{fontSize: 15}}>Gender *</Typography>
                        <SelectField
                            className="text-left"
                            name="pfNomineInfo_Gender"
                            value={formik.values.pfNomineInfo_Gender}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfo_Gender && Boolean(formik.errors.pfNomineInfo_Gender)}
                            helperText={formik.touched.pfNomineInfo_Gender && formik.errors.pfNomineInfo_Gender}
                            >
                            <MenuItem value="Male">
                                Male
                            </MenuItem>
                            <MenuItem value="Female">
                                Female
                            </MenuItem>
                        </SelectField>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Relation *"
                            name='pfNomineInfo_Relation'
                            value={formik.values.pfNomineInfo_Relation}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfo_Relation && Boolean(formik.errors.pfNomineInfo_Relation)}
                            helperText={formik.touched.pfNomineInfo_Relation && formik.errors.pfNomineInfo_Relation}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="DOB *"
                            name='pfNomineInfo_DOB'
                            type='date'
                            value={formik.values.pfNomineInfo_DOB}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfo_DOB && Boolean(formik.errors.pfNomineInfo_DOB)}
                            helperText={formik.touched.pfNomineInfo_DOB && formik.errors.pfNomineInfo_DOB}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Pincode *"
                            name='pfNomineInfo_Pincode'
                            value={formik.values.pfNomineInfo_Pincode}
                            onChange={handleChangePinCode}
                            error={formik.touched.pfNomineInfo_Pincode && Boolean(formik.errors.pfNomineInfo_Pincode)}
                            helperText={formik.touched.pfNomineInfo_Pincode && formik.errors.pfNomineInfo_Pincode}
                            />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="District *"
                            name='pfNomineInfo_District'
                            value={formik.values.pfNomineInfo_District}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfo_District && Boolean(formik.errors.pfNomineInfo_District)}
                            helperText={formik.touched.pfNomineInfo_District && formik.errors.pfNomineInfo_District}
                            />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="State *"
                            name='pfNomineInfo_State'
                            value={formik.values.pfNomineInfo_State}
                            error={formik.touched.pfNomineInfo_State && Boolean(formik.errors.pfNomineInfo_State)}
                            helperText={formik.touched.pfNomineInfo_State && formik.errors.pfNomineInfo_State}
                            />
                    </Grid>  
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Share *"
                            name='pfNomineInfo_Share'
                            value={formik.values.pfNomineInfo_Share}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfo_Share && Boolean(formik.errors.pfNomineInfo_Share)}
                            helperText={formik.touched.pfNomineInfo_Share && formik.errors.pfNomineInfo_Share}
                            />
                    </Grid>  
                    <Grid item lg={6} xs={12}>
                        <Typography sx={{fontSize: 15}}>Minor *</Typography>
                        <SelectField
                            className="text-left"
                            name="pfNomineInfo_Minor"
                            value={formik.values.pfNomineInfo_Minor}
                            onChange={formik.handleChange} 
                            error={formik.touched.pfNomineInfo_Minor && Boolean(formik.errors.pfNomineInfo_Minor)}
                            helperText={formik.touched.pfNomineInfo_Minor && formik.errors.pfNomineInfo_Minor}
                            >
                            <MenuItem value="Y">
                                Y
                            </MenuItem>
                            <MenuItem value="N">
                                N
                            </MenuItem>
                        </SelectField>
                    </Grid> 
                    <Grid item lg={6} xs={12}>
                        <Typography sx={{fontSize: 15}}>Lunatic *</Typography>
                        <SelectField
                            className="text-left"
                            name="pfNomineInfo_Lunatic"
                            value={formik.values.pfNomineInfo_Lunatic}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfo_Lunatic && Boolean(formik.errors.pfNomineInfo_Lunatic)}
                            helperText={formik.touched.pfNomineInfo_Lunatic && formik.errors.pfNomineInfo_Lunatic}
                            >
                            <MenuItem value="Y">
                                Y
                            </MenuItem>
                            <MenuItem value="N">
                                N
                            </MenuItem>
                        </SelectField>
                    </Grid>
                    
                </Grid>
        </Box>
    )
}