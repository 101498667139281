import { createSlice } from "@reduxjs/toolkit";

export const ProjectSlice = createSlice({
    name: "project",
    initialState: {
        PaymentcollectionMode: {},
        pagination: {},
        projectlist: [],
        pinCodeData: {},
        projectDetails: {},
        projectViewData: {},
        projectListByCompanyId: [],
        role: {},
        dailyWagesListing: [],
        dailyWagesDetails: {},
        clientNameList: []
    },
    reducers: {
        getProjectList: () => {},

        getProjectSuccess: (state, action) => {
            state.project = {};
            state.projectlist = action.payload.data.results;
            state.pagination = action.payload.pagination; 
            state.role = action.payload.role           
        },

        getProjectById: () => {},

        getProjectByIdSuccess: (state, action) => {
            state.projectDetails = action.payload;
        },
       

        getProjectVIewData: () => {
            
        },
        getProjectVIewDataSuccess: (state, action) => {
            state.projectViewData = action.payload;
        },

        getPaymentAndDuration: () => {},
        getPaymentAndDurationSuccess: (state,action) => {
          state.PaymentcollectionMode = action.payload
        },

        createProject: () => {},

        fetchDataByPincode: () => {},

        fetchDataByPincodeSuccess: (state,action) => {
            state.pinCodeData = action.payload
        },
        deleteProject: () => {},
        updateProject: () => {},
        updateDailyWages: () => {},
        createDailyWages: () => {},
        dailyWagesListing: () => {},
        dailyWagesListingSuccess: (state,action) => {
            state.dailyWagesListing = action.payload
        },
        deleteDailyWages: () => {},
        getDailyWagesDetails: () => {},
        getDailyWagesDetailsSuccess: (state,action) => {
            state.dailyWagesDetails = action.payload
        },
        updateProjectSetting: () => {},
        getClientNameList: () => {},
        getClientNameListSuccess: (state,action) => {
            state.clientNameList = action.payload
        },
     }
});

export default ProjectSlice;