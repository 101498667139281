import React,{useEffect,useState} from "react";
import {
    Box,
    Button,
    CircularProgress,
    Grid
} from "@mui/material";
import Input from "components/Input";
import PropTypes from "prop-types";
import { GeneralActions, LabourActions, MasterDataActions } from "slices/actions";
import { GeneralSelector, MasterDataSelector } from "selectors";
import * as yup from "yup";
import {useFormik} from "formik";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {toast} from "react-toastify";

PfBankDetails.propTypes = {
    labourDetails: PropTypes.object
};

export default function PfBankDetails(props) {
    const { labourDetails } = props;
    const {id} = useParams();
    const dispatch = useDispatch();
    const success = useSelector(GeneralSelector.success(LabourActions.updateLabourPFBasicInfo.type));
    const loading = useSelector(GeneralSelector.loader(LabourActions.updateLabourPFBasicInfo.type));
    const error = useSelector(GeneralSelector.error(LabourActions.updateLabourPFBasicInfo.type));
    const ifscCodeData = useSelector(MasterDataSelector.getIfscData());
    const [ifsccode,setIfscCode]=useState();

   const handleChangeIfsc = (e) => {
        const code = e.target.value
        formik.setFieldValue('pfBankDetails_Bank_IFSC',code)
        setIfscCode(code);
    }
    useEffect(() => {
        if (ifsccode && ifsccode.length === 11) {
            dispatch(MasterDataActions.getIfscData(ifsccode));
        }
    }, [ifsccode]);

    useEffect(() => {
        if (ifscCodeData && ifsccode) {
        formik.setFieldValue('pfBankDetails_Bank_Address', ifscCodeData?.ADDRESS ??"");
        formik.setFieldValue('pfBankDetails_Bank_City', ifscCodeData.CITY?ifscCodeData.CITY :'');
        formik.setFieldValue('pfBankDetails_Bank_BranchName', ifscCodeData.BRANCH?ifscCodeData.BRANCH :'');
        formik.setFieldValue('pfBankDetails_Bank_State', ifscCodeData.STATE?ifscCodeData.STATE :'');
        formik.setFieldValue('pfBankName', ifscCodeData.BANK?ifscCodeData.BANK :'');
     }
    }, [ifscCodeData]);

     const validationSchema = yup.object({
        pfBankDetails_BankAccountNumber: yup.string().required('This field is required'),
        pfBankDetails_Bank_IFSC: yup.string().required('This field is required'),
        pfBankDetails_NameAsPerBankDetails: yup.string().required('This field is required'),
    });
    const formik = useFormik({
        initialValues: {
            flag: "PFBankDetails",
            pfBankDetails_BankAccountNumber: labourDetails?.result?.pfBankDetails_BankAccountNumber??"",
            pfBankDetails_Bank_IFSC: labourDetails?.result?.pfBankDetails_Bank_IFSC??"",
            pfBankDetails_NameAsPerBankDetails: labourDetails?.result?.pfBankDetails_NameAsPerBankDetails??"",
            pfBankDetails_Bank_Address: labourDetails?.result?.pfBankDetails_Bank_Address??"",
            pfBankDetails_Bank_BranchName: labourDetails?.result?.pfBankDetails_Bank_BranchName??"",
            pfBankDetails_Bank_State: labourDetails?.result?.pfBankDetails_Bank_State??"",
            pfBankDetails_Bank_City: labourDetails?.result?.pfBankDetails_Bank_City??"",
            pfBankName: labourDetails?.result?.pfBankName??"",

     },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
           handleSubmit(values);
        }
    });
    const handleSubmit = (values) => {
        const params = {
            id: id,
          ...values,
      };
      dispatch(LabourActions.updateLabourPFBasicInfo(params));
   }


   useEffect(() => {
    if (success) {
        toast.success(`${success?.message ?? "Success"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });

        dispatch(GeneralActions.removeSuccess(LabourActions.updateLabourPFBasicInfo.type));
    }
  }, [success]);

useEffect(() => {
    if (error) {
        toast.error(`${error?.message ?? "Error"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });
    dispatch(GeneralActions.removeError(LabourActions.updateLabourPFBasicInfo.type));
    }
}, [error]);

return (
        <Box>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Bank Account Number *"
                            name='pfBankDetails_BankAccountNumber'
                            type='number'
                            maxLength = "20"
                            value={formik.values.pfBankDetails_BankAccountNumber}
                            onChange={formik.handleChange}
                            error={formik.touched.pfBankDetails_BankAccountNumber && Boolean(formik.errors.pfBankDetails_BankAccountNumber)}
                            helperText={formik.touched.pfBankDetails_BankAccountNumber && formik.errors.pfBankDetails_BankAccountNumber}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Bank IFSC *"
                            name='pfBankDetails_Bank_IFSC'
                            value={formik.values.pfBankDetails_Bank_IFSC}
                            onChange={handleChangeIfsc}
                            error={formik.touched.pfBankDetails_Bank_IFSC && Boolean(formik.errors.pfBankDetails_Bank_IFSC)}
                            helperText={formik.touched.pfBankDetails_Bank_IFSC && formik.errors.pfBankDetails_Bank_IFSC}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Name as per Bank Details *"
                            name='pfBankDetails_NameAsPerBankDetails'
                            value={formik.values.pfBankDetails_NameAsPerBankDetails}
                            onChange={formik.handleChange}
                            error={formik.touched.pfBankDetails_NameAsPerBankDetails && Boolean(formik.errors.pfBankDetails_NameAsPerBankDetails)}
                            helperText={formik.touched.pfBankDetails_NameAsPerBankDetails && formik.errors.pfBankDetails_NameAsPerBankDetails}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                    <Input
                            label="Branch Name"
                            name='pfBankDetails_Bank_BranchName'
                            value={formik.values.pfBankDetails_Bank_BranchName}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.pfBankDetails_Bank_BranchName) && Boolean(formik.errors.pfBankDetails_Bank_BranchName)}
                            helperText={formik.touched.pfBankDetails_Bank_BranchName && formik.errors.pfBankDetails_Bank_BranchName}/>
                    </Grid>
                   
                    
                    <Grid item lg={6} xs={12}>
                    <Input
                            label="City Name"
                            name='pfBankDetails_Bank_City'
                            value={formik.values.pfBankDetails_Bank_City}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.pfBankDetails_Bank_City) && Boolean(formik.errors.pfBankDetails_Bank_City)}
                            helperText={formik.touched.pfBankDetails_Bank_City && formik.errors.pfBankDetails_Bank_City}/>
                    </Grid>
                 
                    <Grid item lg={6} xs={12}>
                    <Input
                            label="State Name"
                            name='pfBankDetails_Bank_State'
                            value={formik.values.pfBankDetails_Bank_State}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.pfBankDetails_Bank_State) && Boolean(formik.errors.pfBankDetails_Bank_State)}
                            helperText={formik.touched.pfBankDetails_Bank_State && formik.errors.pfBankDetails_Bank_State}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                    <Input
                            label="Address"
                            name='pfBankDetails_Bank_Address'
                            value={formik.values.pfBankDetails_Bank_Address}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.pfBankDetails_Bank_Address) && Boolean(formik.errors.pfBankDetails_Bank_Address)}
                            helperText={formik.touched.pfBankDetails_Bank_Address && formik.errors.pfBankDetails_Bank_Address}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                    <Input
                            label="Bank Name"
                            name='pfBankName'
                            value={formik.values.pfBankName}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.pfBankName) && Boolean(formik.errors.pfBankName)}
                            helperText={formik.touched.pfBankName && formik.errors.pfBankName}/>
                    </Grid>
                    {labourDetails?.result?.status !== 99?(
                    <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained">
                          {loading?(
                                   <CircularProgress color="inherit" size={20}/>
                                ):(
                                  <>Update</>  
                                )}
                        </Button>
                    </Grid>):null}
                </Grid>
            </form>
        </Box>
    )
}