import React from "react";
import {Route, Switch} from "react-router-dom";
import User from "screens/User";
import MainLayout from "./layouts/MainLayout";
import Department from "screens/Department";
import FormUser from "./screens/User/Form";
import FormDepartment from "./screens/Department/Form";
import Designation from "./screens/Designation";
import FormDesignation from "./screens/Designation/Form";
import AuthLayout from "./layouts/AuthLayout";
import Login from "./screens/Auth/Login";
import Dashboard from "./screens/Dashboard";
import FormAttendance from "./screens/Attendance/SingleDayAttendance/Form";
import Expenses from "./screens/Expenses";
import FormExpenses from "./screens/Expenses/Form";
import Report from "./screens/Report";
import CreateUser from "./screens/User/Create";
import Leaves from "./screens/Leave";
import FormLeave from "./screens/Leave/Form";
import Setting from "./screens/Setting";
import Profile from "./screens/Profile";
import State from "screens/State/Index";
import ManageState from "screens/State/ManageState";
import Company from "screens/Company/Index";
import SubContractor from "screens/SubContractor/Index";
import Project from "screens/Project/Index";
import Site from "screens/Site/Index";
import City from "screens/City/Index";
import AddCity from "screens/City/AddCity";
import CreateCompany from "screens/Company/CreateCompany";
import AddProject from "screens/Project/AddProject";
import CompanyDetail from "screens/Company/CompanyDetail";
import ProjectDetail from "screens/Project/ProjectDetail";
import ProjectDetailAccountSetting from "screens/Project/Component/AboutProjectDetails/form/ProjectUserAdd";
import CreateSite from "screens/Site/CreateSite";
import LabourLIsting from "screens/Labour/Index";
import CreateLabour from "screens/Labour/CreateLabour";
import LabourDetail from "screens/Labour/LabourDetail";
import CreateSubcontractor from "screens/SubContractor/CreateSubContractor";
import ProjectUserDetails from "screens/Project/Component/AboutProjectDetails/form/ProjectUserDetails";
import HealthPdf from "screens/Labour/HealthPdf";
import SiteDetail from "screens/Site/SiteDetails";
import SubContractorDetails from "screens/SubContractor/SubContractorDetails";
import SubContractorAssign from "screens/SubContractor/SubContractorAssign/SubContractorAssign";
import ViewProject from "screens/Project/Views/ViewProject";
import ViewCompany from "screens/Company/Views/ViewCompany";
import SubContractorAndProjectListing from "screens/ProjectAndSubContractorAssign/Index";
import GstCompliance from "screens/GstCompliance/Index";
import AddGstCompliance from "screens/GstCompliance/AddGstCompliance";
import LabourCompliance from "screens/LabourCompliance/Index";
import AddLabourCompliance from "screens/LabourCompliance/AddLabourCompliance";
import SafetyTraining from "screens/Labour/Component/AboutBasicInfoDetails/form/SafetyTraining/SafetyTraining";
import LabourInduction from "screens/Labour/LabourInduction/LabourInduction";
import MedicalCertificate from "screens/Labour/LabourInduction/MedicalCertificate";
import FitnessCertificate from "screens/Labour/LabourInduction/FitnessCertificate";
import LabourSafetyTrainingList from "screens/LabourSafetyTraining/Index";
import PfComplianceListing from "screens/PfCompliance/Index";
import CreatePfCompliance from "screens/PfCompliance/CreatePfCompliance";
import AllEducationList from "screens/LabourEducationListing/Index";
import FormA from "screens/FormReport/FormA";
import FormB from "screens/FormReport/FormB";
import FormD from "screens/FormReport/FormD";
import FormDResult from "screens/FormReport/FormD/FormDResult";
import FormAResult from "screens/FormReport/FormA/FormAResult";
import FormBResult from "screens/FormReport/FormB/FormBResult";
import ProjectWagesAdd from "screens/Project/Component/AboutProjectDetails/form/AllWages/ProjectWagesForm";
import HolidayList from "screens/HolidayList/Index";
import HolidayForm from "screens/HolidayList/Form";
import FormCResult from "screens/FormReport/FormC/FormCResult";
import FormC from "screens/FormReport/FormC";
import EducationDetails from "screens/Labour/Component/AboutBasicInfoDetails/form/Education/EducationDetails";
import DiseaseDetails from "screens/Labour/Component/AboutBasicInfoDetails/form/Health/DiseaseDetails";
import DocumentLabourList from "screens/Labour/DocumentLabourList";
import PfResult from "screens/FormReport/EcrChalan/PfResult";
import PfCalculation from "screens/FormReport/EcrChalan";
import LinkUan from "screens/LinkUan";
import ExitLaborList from "screens/FormReport/ExitLabourList";
import ExitLaborListResult from "screens/FormReport/ExitLabourList/ExitLaborListResult";
import MonthAttendance from "screens/Attendance/MonthAttendance/Index";
import MonthMyAttendance from "screens/Attendance/MonthMyAttendance/Index";
import DayAttendance from "./screens/Attendance/SingleDayAttendance";
import SingleDayMyAttendance from "screens/Attendance/SingleDayMyAttendance";
import AttendanceListing from "screens/Attendance/MonthAttendance/AttendanceListing"
import MyAttendanceListing from "screens/Attendance/MonthMyAttendance/AttendanceListing";
import ProjectList from "screens/Report/components/ProjectList";
import LabourList from "screens/Report/components/LabourList";
import SubcontractorList from "screens/Report/components/SubcontractorList";
import SafetyTrainingList from "screens/Report/components/SafetyTrainingList";
import LabourComplain from "screens/Labour/LabourComplain/LabourComplain";
import LabourComplainListing from "screens/Labour/LabourComplain/LabourComplainListing";
import IdCardValidationListing from "screens/Labour/IdCardValidationList";
import LabourComplainDetails from "screens/Labour/LabourComplain/LabourComplainDeatils";
import TransactionList from "screens/Transaction/Index";

const PrivateRoutes = [
    { path: "/app/user", component: User },
    { path: "/app/user/create", component: CreateUser },
    { path: "/app/user/update/:id", component: FormUser },

    { path: "/app/department", component: Department },
    { path: "/app/department/create", component: FormDepartment },
    { path: "/app/department/update/:id", component: FormDepartment },

    { path: "/app/state", component: State },
    { path: "/app/state/create", component: ManageState },
    { path: "/app/state/update/:id", component: ManageState },

    { path: "/app/company", component: Company},
    { path: "/app/company/create", component: CreateCompany },
    { path: "/app/company/update/:id", component: CompanyDetail },
    { path: "/company/view/viewcompany/:id", component: ViewCompany },

    { path: "/app/subcontractor", component: SubContractor},
    { path: "/app/subcontractor/Create" ,component: CreateSubcontractor},
    { path: "/app/subcontractor/details/:id", component: SubContractorDetails },
    { path: "/app/subcontractor/assign/:id", component: SubContractorAssign},

    { path: "/app/project/", component: Project},
    { path: "/app/project/:status", component: Project},
    { path: "/app/project/Create/New", component: AddProject },
    { path: "/app/project/update/:id", component: ProjectDetail },
    { path: "/app/project/details/addUser/:id", component: ProjectDetailAccountSetting},
    { path: "/project/update/:id", component: ProjectUserDetails },
    { path: "/project/view/viewproject/:id", component: ViewProject },
    { path: "/app/project/wages/projectwageAdd", component: ProjectWagesAdd },
    { path: "/app/project/projectwageUpdate/:id", component: ProjectWagesAdd },

   
    { path: "/app/site", component: Site},
    { path: "/app/site/create/", component: CreateSite},
    { path: "/app/site/detail/:id", component: SiteDetail},


    { path: "/app/city", component: City},
    { path: "/app/city/create", component: AddCity },
    { path: "/app/city/update/:id", component: AddCity },


    { path: "/app/designation", component: Designation },
    { path: "/app/designation/create", component: FormDesignation },
    { path: "/app/designation/update/:id", component: FormDesignation },

     {path: "/app/labour", component: LabourLIsting},
    { path: "/app/labour/create", component: CreateLabour },
    { path: "/app/labour/update/:id", component: LabourDetail },
    { path: "/app/labour/healthpdf" , component: HealthPdf},
    { path: "/app/labour/labourinduction", component: LabourInduction},
    { path: "/labourSafetyTraingList", component: LabourSafetyTrainingList},
    { path: "/app/labour/educationDetails/:id", component: EducationDetails},
    { path: "/app/labour/diseaseDetails/:id", component: DiseaseDetails},
    { path: "/app/labour/DocumentList",component: DocumentLabourList},
    { path: "/app/LabourcomplainList",component: LabourComplainListing},
    { path: "/app/validateIdCardList",component: IdCardValidationListing},
    { path: "/app/dayAttendance", component: DayAttendance },
    { path: "/app/monthAttendance", component: MonthAttendance},
    { path: "/app/dayMyattendance", component: SingleDayMyAttendance },
    { path: "/app/monthMyattendance", component: MonthMyAttendance},
    { path: "/app/monthAttendanceListing", component: AttendanceListing},
    { path: "/app/monthMyAttendanceList", component: MyAttendanceListing},
    { path: "/app/attendance/create", component: FormAttendance},
    { path: "/app/attendance/update/:id", component: FormAttendance },
    { path: "/app/forma", component: FormA },
    { path: "/app/formb", component: FormB },
    { path: "/app/formc", component: FormC },
    { path: "/app/formd", component: FormD },
    { path: "/app/formdresult", component: FormDResult },
    { path: "/app/formbresult", component: FormBResult },
    { path: "/app/formaresult", component: FormAResult },
    { path: "/app/formcresult", component: FormCResult },
    { path: "/app/ECRchalan", component: PfCalculation },
    { path: "/app/ECRchalan/pfresult", component: PfResult },
    { path: "/app/ExitLabourList", component: ExitLaborList },
    { path: "/app/ExitLaborListResult", component: ExitLaborListResult },

    { path: "/app/labourComplain", component: LabourComplain },
    { path: "/app/LabourcomplainList/details/:id", component: LabourComplainDetails},

    { path: "/app/gstcompliance", component: GstCompliance},
    { path: "/app/gstcompliance/create", component: AddGstCompliance },

    { path: "/labourcompliance", component: LabourCompliance},
    { path: "/labourcompliance/create", component: AddLabourCompliance },
    
    { path: "/app/pfcompliance", component: PfComplianceListing},
    { path: "/app/pfcompliance/create", component: CreatePfCompliance },

    { path: "/app/expenses", component: Expenses },
    { path: "/app/expenses/create", component: FormExpenses },
    { path: "/app/expenses/update/:id", component: FormExpenses },

    { path: "/app/leave", component: Leaves },
    { path: "/app/leave/create", component: FormLeave },
    { path: "/app/leave/update/:id", component: FormLeave },

    { path: "/app/report", component: Report},

    { path: "/app/setting", component: Setting},

    { path: "/app/profile", component: Profile},

    { path: "/app/dashboard", component: Dashboard },

    { path: "/app/assignSubcontractorAndProject", component: SubContractorAndProjectListing },

    { path: "/app/safetytraining", component: SafetyTraining },
    { path: "/app/safetytraining/:id", component: SafetyTraining },
    { path: "/app/educationList", component: AllEducationList },


    { path: "/app/labourinduction/medical/:id", component: MedicalCertificate },
    { path: "/app/labourinduction/fitness/:id", component: FitnessCertificate },

    { path: "/app/holidaylist", component: HolidayList },
    { path: "/app/holidaylist/holidayDetail/:id", component: HolidayForm },
    { path: "/app/holidaylist/addholiday", component: HolidayForm },
    { path: "/app/linkUAN",component: LinkUan },

    { path: "/report/project", component: ProjectList},
    { path: "/report/Labour", component: LabourList},
    { path: "/report/Subcontractor", component: SubcontractorList },
    { path: "/report/SafetyTraing", component: SafetyTrainingList },
    { path: "/transaction/Listing", component: TransactionList }

]

export default function Routes() {
    return (
        <Switch>
            <Route exact={true} path={PrivateRoutes.map(item => item.path)}>
                <MainLayout>
                    <Switch>
                        {PrivateRoutes.map((item, i) => (
                                <Route key={i} exact path={item.path} component={item.component}/>
                            ))}
                    </Switch>
                </MainLayout>
            </Route>

            <Route exact={true} path={["/"]}>
                <AuthLayout>
                    <Switch>
                        <Route exact path="/" component={Login}/>
                    </Switch>
                </AuthLayout>
            </Route>
        </Switch>
    )
}