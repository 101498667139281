import React from "react";
import {
     Card
} from "@mui/material";
import DailyWorker from "./DailyWages"
import MonthlyWorker from "./MonthlyWages";
import PropTypes from "prop-types";


Wages.propTypes = {
    labourDetails: PropTypes.object,
};

export default function Wages(props) {
const { labourDetails } = props;

    // window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth',
    // });
    return (
        <Card>
            {labourDetails && labourDetails.result && labourDetails.result.employeementType === "Daily Wages" ?(
            <DailyWorker labourDetails={labourDetails} />):(
            <MonthlyWorker labourDetails={labourDetails} />
            )}
        
        </Card>
    )
}