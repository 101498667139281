import React,{useEffect} from "react";
import {
     Button,
    Card,
    Grid,
    Typography, CircularProgress,
} from "@mui/material";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import { GeneralActions, LabourActions} from "slices/actions";
import {useFormik} from "formik";
import Input from "components/Input";
import { GeneralSelector, LabourSelector } from "selectors";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function DiseaseDetails() {
    const history = useHistory();
    const dispatch = useDispatch();
    const {id} = useParams()
    const diseaseDetails = useSelector(LabourSelector.getDiseaseDetails());
    const success = useSelector(GeneralSelector.success(LabourActions.updateDisease.type));
    const error = useSelector(GeneralSelector.error(LabourActions.updateDisease.type));
    const loading = useSelector(GeneralSelector.loader(LabourActions.updateDisease.type));

    useEffect(() => {
    if (id) {
      dispatch(LabourActions.getDiseaseDetails(id))
    }
    },[id]);

     useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(LabourActions.updateDisease.type));
            history.goBack();
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeError(LabourActions.updateDisease.type));
        }
    }, [error]);

    const validationSchema = yup.object({
        date: yup.string().required('This field is required'),
        diseaseName: yup.string().required('This field is required'),
        description: yup.string().required('This field is required'),
    });
    const formik = useFormik({
      initialValues: {
        date: diseaseDetails?.date??"",
        diseaseName: diseaseDetails?.diseaseName??"",
        description: diseaseDetails?.description??"",
      },
      enableReinitialize: true,
      validateOnChange: true,
      validationSchema: validationSchema,
      onSubmit: (values) => {
        handleSubmit(values);
      },
    });
   
    const handleSubmit = (values) => {
        const params = {
            companyId: diseaseDetails.companyId,
            projectId: diseaseDetails.projectId,
            subContractorId: diseaseDetails.subContractorId,
            labourId: diseaseDetails.labourId,
            id: diseaseDetails._id,
              ...values,

        };
       dispatch(LabourActions.updateDisease(params));
    }

    return (
        <Card>
            <Typography variant='h5' sx={{ mb: 4 }}>Disease Details</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                   <Grid item lg={12} xs={12}>
                        <Input
                            label="Date"
                            name='date'
                            type="date"
                            value={formik.values.date.slice(0,10)}
                            onChange={formik.handleChange}
                            error={formik.touched.date && Boolean(formik.errors.date)}
                            helperText={formik.touched.date && formik.errors.date}
                        />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Input
                            label="Disease Name"
                            name='diseaseName'
                            value={formik.values.diseaseName}
                            onChange={formik.handleChange}
                            error={formik.touched.diseaseName && Boolean(formik.errors.diseaseName)}
                            helperText={formik.touched.diseaseName && formik.errors.diseaseName}
                            />
                    </Grid> 
                    <Grid item lg={12} xs={12}>
                        <Input
                            label="Description"
                            name='description'
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                        />
                    </Grid>                  
                        <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained">
                                {loading?(
                                   <CircularProgress color="inherit" size={20}/>
                                ):(
                                  <>Submit</>  
                                )}
                            </Button>
                        </Grid>
                </Grid>
            </form>
        </Card>
    )
}