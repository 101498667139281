import { createSlice } from "@reduxjs/toolkit";

export const DesignationSlice = createSlice({
    name: "Designation",
    initialState: {
        designations: [],
        pagination: {},
        deginationFormList: {},
        designation: {},
        // designationFormListById: {}
    },
    reducers: {
        getDesignations: () => {},
        getDesignationsSuccess: (state, action) => {
            state.designation = {};
            state.designations = action.payload.data;
            state.pagination = action.payload.pagination;
        },
        getDesignationById: () => {},
        getDesignationByIdSuccess: (state, action) => {
            state.designation = action.payload
        },
        createDesignation: () => {},
        updateDesignation: () => {},
        deleteDesignation: () => {},
        getDesignationFormList: () => {},
        getDesignationFormListSuccess: (state, action) => {
            state.deginationFormList = action.payload
        },
        getDesignationFormById: () => {},
        // getDesignationFormByIdSuccess: (state, action) => {
        //     state.designationFormListById = action.payload
        // },
        
    }
});

export default DesignationSlice;