import React, {useEffect, useState} from "react";
import {
     Box, Card, Table, TableBody, TableCell, Grid,
     Pagination,
     TableHead, TableRow,
     MenuItem,
     Tooltip,
     IconButton,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {GeneralSelector, ProjectSelector,SubContractorSelector} from "selectors";
import {CompanyActions, GeneralActions, LabourActions, ProjectActions, SubContractorActions} from "slices/actions";
import {DefaultSort} from "constants/sort";
import { LabourSelector } from "selectors/LabourSelector";
import styled from "@emotion/styled";
import SelectField from "components/SelectField";
import { Constants } from "utils/constants";
import { CompanySelector } from "selectors/CompanySelector";
import ListSkeleton from "components/Skeleton/ListSkeleton";
import { format } from 'date-fns';
import { Delete, Edit } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import DialogConfirm from "components/DialogConfirm";
import {toast} from "react-toastify";


const FilterBox = styled(Box)(() => ({
    width: "100%",
    marginTop: 30,
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-between"
}));

function LabourComplainListing() {
     const dispatch = useDispatch();
     const history = useHistory();
     const labourComplainList = useSelector(LabourSelector.getLabourComplainList());
     const companyList = useSelector(CompanySelector.getCompanyList());
     const projectList = useSelector(ProjectSelector.getProjectList());
     const pagination = useSelector(LabourSelector.getComplainListPagination());
     const loading = useSelector(GeneralSelector.loader(LabourActions.getLabourComplainList.type));
     const [filter, setFilter] = useState({
        sort: DefaultSort.newest.value,
        page: 1,
     });
    const userType = localStorage.getItem('userType')
    const companyId = localStorage.getItem('companyID')
    const projectId = localStorage.getItem('projectID')
    const subcontractorId = localStorage.getItem('subcontractorID')
    const [company,setCompany] = useState(companyId?companyId:"");
    const [project,setProject] = useState(projectId?projectId:"");
    const [subcontractor,setSubcontractor] = useState(subcontractorId?subcontractorId:"");
    const [assignedProject,setAssignedProject] = useState("");
    const assignedProjectToSubcontractorList = useSelector(SubContractorSelector.getAssignedSubcontractor());
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selected, setSelected] = useState(null);
    const success = useSelector(GeneralSelector.success(LabourActions.deleteLabourComplain.type));
    const error = useSelector(GeneralSelector.error(LabourActions.deleteLabourComplain.type));

    useEffect(() => {
        if (!companyId) {
           dispatch(CompanyActions.getCompanyList())
        } 
        if (companyId && !projectId) {
            const params = {
                company_id: companyId,
                status: 1
             }
            dispatch(ProjectActions.getProjectList(params))
        } 
        if (companyId && projectId) {
            const params = {
                company_id: companyId,
                project_id: projectId,
             }
             dispatch(SubContractorActions.getAssignedSubContractor(params)) 
        } 
         if (subcontractorId) {
            const params = {
                subcontractorId: subcontractorId
            }
             dispatch(SubContractorActions.getAssignedSubContractor(params));
        }
    },[])


  useEffect(() => {
    const params = {
        ...filter,
        companyId: company?company:"",
        projectId: project?project:"",
        subContractorId: subcontractor?subcontractor:"" 
    }
    dispatch(LabourActions.getLabourComplainList(params));
}, [filter]);


const handleChangePagination = (e, val) => {
    setFilter({
        ...filter,
        page: val
    });
};

const handleChangeCompany = (e) => {
    const cId = e.target.value
    setCompany(cId)
    setProject("")
    setSubcontractor("")
   
    if (cId) {
      const params = {
        company_id: cId,
        status: 1
      }
      dispatch(ProjectActions.getProjectList(params))
      const searchByCompany = {
        companyId: cId,
           ...filter,
    }
        dispatch(LabourActions.getLabourComplainList(searchByCompany));
    }
  }
  const handleChangeProject = (e) => {
    const pId = e.target.value
    setProject(pId)
    setSubcontractor("")
    if (pId) {
    const params = {
        company_id: company || companyId,
        project_id: pId,
        
    }
    dispatch(SubContractorActions.getAssignedSubContractor(params))
    const searchByProject = {
        companyId: company || companyId,
        projectId: pId,
        ...filter,

     }
     dispatch(LabourActions.getLabourComplainList(searchByProject));
    }
}
  
  const handleChangeSubcontractor = (e) => { 
    const sId = e.target.value
    if (sId) {
    setSubcontractor(sId)
    const searchBySubcontractor = {
        companyId: company || companyId,
        projectId: project || projectId ,
        subContractorId: sId,
        ...filter,

     }
     dispatch(LabourActions.getLabourComplainList(searchBySubcontractor));
    }
}

const handleChangeProjectBySubcontractor = (e) => {
    setAssignedProject(e.target.value)
    for (const i in assignedProjectToSubcontractorList.data) {
        if (assignedProjectToSubcontractorList.data[i].projectId._id === e.target.value) {
            const searchByProject = {
                projectId: e.target.value,
                companyId: assignedProjectToSubcontractorList.data[i].companyId._id,
                subContractorId: subcontractorId,
                    ...filter,

                }
            dispatch(LabourActions.getLabourComplainList(searchByProject));
        }
    }
}

useEffect(() => {
    if (error) {
        setSelected(false);
        setConfirmDelete(false)
       toast.error(`${error?.message ?? "Error"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true
            });
  
        dispatch(GeneralActions.removeError(LabourActions.deleteLabourComplain.type));
     }
  }, [error]);

  useEffect(() => {
    if (success) {
        setSelected(false);
        setConfirmDelete(false)
        toast.success(`${success?.message ?? "Success"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true
            });
  
            dispatch(GeneralActions.removeSuccess(LabourActions.deleteLabourComplain.type));
            const params = {
                ...filter,
                company_id: company?company:"",
                project_id: project?project:"",
                subcontractor_id: subcontractor?subcontractor:"" 
            }
            dispatch(LabourActions.getLabourComplainList(params));
        }
  }, [success]);

const handleDelete = () => {
   dispatch(LabourActions.deleteLabourComplain(selected));
}

return (
      
  <Card>
      <Typography variant="h5" sx={{ fontWeight: 600, position: 'relative' }}>All Labour Complain
      </Typography>
     <FilterBox>
        <Grid container spacing={3} justifyContent="space-between">
            
            {userType === Constants.SUPERADMIN ?(
              <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Company</Typography>
                <SelectField
                    value={company}
                    onChange={handleChangeCompany}
                    >
                    {companyList && companyList.map((item,index) => (
                    <MenuItem key={index} value={item._id}>
                            {item.name}
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
            {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER ?(
              <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Project</Typography>
                <SelectField
                    value={project}
                    onChange={handleChangeProject}
                    >
                    {(company && projectList) && projectList.map((item,index) => (
                    <MenuItem key={index} value={item._id}>
                            {item.name}
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
            {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER || userType === Constants.PROJECT_USER?(
              <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Subcontractor</Typography>
                <SelectField
                    value={subcontractor}
                    onChange={handleChangeSubcontractor}
                    >
                    {(project && assignedProjectToSubcontractorList.data) && assignedProjectToSubcontractorList.data.map((item,index) => (
                    <MenuItem key={index} value={item?.subcontractorId?._id}>
                             {item?.subcontractorId?.subContractorName}
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
             {userType === Constants.SUB_CONTRACTOR_USER ?(
              <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Project</Typography>
                <SelectField
                    value={assignedProject}
                    onChange={handleChangeProjectBySubcontractor}
                    >
                    {assignedProjectToSubcontractorList && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                    <MenuItem key={index} value={item?.projectId?._id}>
                            {item?.projectId?.name}({item?.companyId?.name})
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
            
            
        </Grid>
       
    </FilterBox>
    

      {loading ? (
          <ListSkeleton/>
      ) : (
          <Box>
              <Table>
                  <TableHead>
                      <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell>Reason</TableCell>
                              <TableCell>Complain Raise Date</TableCell>
                              <TableCell></TableCell>
                       </TableRow>
                  </TableHead>
                  <TableBody>
                      {labourComplainList && labourComplainList.length === 0 && (
                          <TableRow>
                              <TableCell align="center" colSpan={7}>
                                  No Data
                              </TableCell>
                          </TableRow>
                      )}
                      {labourComplainList && labourComplainList.map((item, i) => (
                          <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell>{item?.labourId?.firstName} {item?.labourId?.lastName}</TableCell>
                              <TableCell>{item?.title}</TableCell>
                              <TableCell>{item?.raiseDate && format(new Date(item?.raiseDate), 'dd/MM/yyyy')}</TableCell>
                              <TableCell align="right" width='20%'>
                                    <Tooltip title="Edit">
                                      <IconButton onClick={() => history.push(`/app/LabourcomplainList/details/${item._id}`)}>
                                            <Edit className="text-primary" />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                    <IconButton onClick={() => {
                                            setConfirmDelete(true);
                                            setSelected(item._id);
                                        }}>
                                            <Delete className="text-default" />
                                    </IconButton>
                                    </Tooltip>
                                 </TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>

              <Pagination
                  sx={{ mt: 1 }}
                  page={filter.page}
                  count={pagination && pagination.pages}
                  onChange={handleChangePagination}/>
          </Box>
      )}
        <DialogConfirm
          title="Delete Labour"
          content="Are you sure want to delete this labour data?"
          open={confirmDelete}
          onClose={() => setConfirmDelete(false)}
          onSubmit={handleDelete}
        />
 </Card>
)
}

export default LabourComplainListing;