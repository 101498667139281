import React, {useEffect,useState} from "react";
import {Box, Button, Card, CircularProgress, Grid, MenuItem, Typography} from "@mui/material";
import PageTitle from "components/PageTitle";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import { GeneralActions, CompanyActions, ProjectActions, SubContractorActions, UserActions, MasterDataActions} from "slices/actions";
import { GeneralSelector, MasterDataSelector, ProjectSelector, SubContractorSelector } from "selectors";
import SelectField from "components/SelectField";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Constants } from "utils/constants";
import { CompanySelector } from "selectors/CompanySelector";
import { useHistory } from "react-router-dom";

export default function CreatePfCompliance() {
    const history = useHistory();
    const dispatch = useDispatch();
    const loading = useSelector(GeneralSelector.loader(UserActions.uploadPfCompliance.type));
    const error = useSelector(GeneralSelector.error(UserActions.uploadPfCompliance.type));
    const success = useSelector(GeneralSelector.success(UserActions.uploadPfCompliance.type));
    const yearList = useSelector(MasterDataSelector.getYearList());
    const monthList = useSelector(MasterDataSelector.getMonthList());
    const userType = localStorage.getItem('userType');
    const companyId = localStorage.getItem('companyID');
    const projectId = localStorage.getItem('projectID');
    const subcontractorId = localStorage.getItem('subcontractorID');
    const userId = localStorage.getItem('userID');
    const companyList = useSelector(CompanySelector.getCompanyList());
    const projectList = useSelector(ProjectSelector.getProjectList());
    const [assignedProject,setAssignedProject] = useState("");
    const assignedProjectToSubcontractorList = useSelector(SubContractorSelector.getAssignedSubcontractor());
    const [pfComplianceForm,setPfComplianceForm] = useState({
        company_id: companyId?companyId:"",
        project_id: projectId?projectId:"",
        subcontractor_id: subcontractorId?subcontractorId:"",
        pfCompliance_document: "",
        note: "",
        Compliance_type: "",
        month: "",
        year: ""
      })
    const [errorObj,setErrorObj] = useState({});
     useEffect(() => {
        dispatch(MasterDataActions.getMonthList());
        dispatch(MasterDataActions.getYearList());
        if (!companyId) {
        dispatch(CompanyActions.getCompanyList());
        }
        if (subcontractorId) {
            const params = {
                subcontractorId: subcontractorId
            }
             dispatch(SubContractorActions.getAssignedSubContractor(params));
        }
    },[])

  
    useEffect(() => {
        if (pfComplianceForm.company_id || companyId) {
            const params = {
                company_id: pfComplianceForm.company_id ? pfComplianceForm.company_id:companyId
            }
            
            dispatch(ProjectActions.getProjectList(params))
        }
    },[pfComplianceForm.company_id])
  
    useEffect(() => {
      if (pfComplianceForm.project_id || projectId) {
          const params = {
            company_id: pfComplianceForm.company_id ? pfComplianceForm.company_id: companyId,
            project_id: pfComplianceForm.project_id ? pfComplianceForm.project_id: projectId,
           }
          
           dispatch(SubContractorActions.getAssignedSubContractor(params));
      }
  },[pfComplianceForm.project_id])
  
     const handleChangeCompany = (e) => {
        delete errorObj.company_id;
        setErrorObj({...errorObj})
        const cId = e.target.value;
        pfComplianceForm.company_id = cId;
        pfComplianceForm.project_id = "";
        pfComplianceForm.subcontractor_id = "";
        setPfComplianceForm({...pfComplianceForm})
     } 
     
     const handleChangeProject = (e) => {
        delete errorObj.project_id;
        setErrorObj({...errorObj})
        if (pfComplianceForm.company_id) {
        const pId = e.target.value
        pfComplianceForm.project_id = pId;
        pfComplianceForm.subcontractor_id = "";
        setPfComplianceForm({...pfComplianceForm})
        } 
      }

    const handleChangeSubcontractor = (e) => {
        delete errorObj.subcontractor_id;
        setErrorObj({...errorObj})
        if (pfComplianceForm.project_id) {
          const sId = e.target.value
          pfComplianceForm.subcontractor_id = sId;
          setPfComplianceForm({...pfComplianceForm})
          } 
     }
    const handleChangeDocument = (e) => {
        delete errorObj.pfCompliance_document;
        setErrorObj({...errorObj})
        const file = e.target.files[0];
        pfComplianceForm.pfCompliance_document = file
        setPfComplianceForm({...pfComplianceForm})
    };
    const handleChangeComplianceType = (e) => {
        delete errorObj.Compliance_type;
        setErrorObj({...errorObj})
        pfComplianceForm.Compliance_type = e.target.value
        setPfComplianceForm({...pfComplianceForm})
    }
    const handleSubmit = () => {
        if (validForm()) {
        const params = {
          ...pfComplianceForm,
          type: "subcontractor",
          user_id: userId

        }
      dispatch(UserActions.uploadPfCompliance(params))
    }
    };
    const validForm = () => {
        let valid = true
        if (!pfComplianceForm.company_id) {
            errorObj.company_id = "This field is Required"
            valid = false;
        }
        if (!pfComplianceForm.project_id) {
            errorObj.project_id = "This field is Required"
            valid = false;
        }
        if (!pfComplianceForm.subcontractor_id) {
            errorObj.subcontractor_id = "This field is Required"
            valid = false;
        }
        if (!pfComplianceForm.Compliance_type) {
            errorObj.Compliance_type = "This field is Required"
            valid = false;
        }
        if (!pfComplianceForm.pfCompliance_document) {
            errorObj.pfCompliance_document = "This field is Required"
            valid = false;
        }
        if (!pfComplianceForm.note) {
            errorObj.note = "This field is Required"
            valid = false;
        }
        if (!pfComplianceForm.month) {
            errorObj.month = "This field is Required"
            valid = false;
        }
        if (!pfComplianceForm.year) {
            errorObj.year = "This field is Required"
            valid = false;
        }
        setErrorObj({...errorObj})
        return valid
    }

    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true
                });
      
            dispatch(GeneralActions.removeSuccess(UserActions.uploadPfCompliance.type));
            history.goBack();
          }
      }, [success]);

      useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true
                });
      
            dispatch(GeneralActions.removeError(UserActions.uploadPfCompliance.type));
          }
      }, [error]);
      const handleChangeMonth = (e) => {
        delete errorObj.month;
        setErrorObj({...errorObj})
        pfComplianceForm.month = e.target.value
        setPfComplianceForm({...pfComplianceForm})
      }
      const handleChangeYear = (e) => {
        delete errorObj.year;
        setErrorObj({...errorObj})
        pfComplianceForm.year = e.target.value
        setPfComplianceForm({...pfComplianceForm})
      }
      const handleChangeProjectBySubcontractor = (e) => {
        delete errorObj.company_id;
        delete errorObj.project_id;
        setErrorObj({...errorObj})
        setAssignedProject(e.target.value)
        for (const i in assignedProjectToSubcontractorList.data) {
            if (assignedProjectToSubcontractorList.data[i].projectId._id === e.target.value) {
                pfComplianceForm.company_id = assignedProjectToSubcontractorList.data[i].companyId._id;
                pfComplianceForm.project_id = assignedProjectToSubcontractorList.data[i].projectId._id;
                setPfComplianceForm({...pfComplianceForm})
              }
        }
    }
    return (
        <Box>
         
        <PageTitle isBack={true} title="PF Compliance" />
            <Card>
                    <Grid container spacing={3}>
                    {userType === Constants.SUPERADMIN?(
                        <Grid item lg={4} sm={12} xs={12}>
                            <Typography varient='h6' sx={{fontSize: '15px'}}>Company</Typography>
                             <SelectField
                                name="companyName"
                                value={pfComplianceForm.company_id}
                                onChange={handleChangeCompany}
                                >
                               {companyList && companyList.map((item,index) => (
                                    <MenuItem value={item._id} key={index}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </SelectField>
                            {errorObj.company_id ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}
                            </Grid>
                          ):null} 
                        {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER?(
                         <Grid item lg={4} sm={12} xs={12}>
                            <Typography varient='h6' sx={{fontSize: '15px'}}>Project</Typography>
                             <SelectField
                                name="projectName"
                                value={pfComplianceForm.project_id}
                                onChange={handleChangeProject}
                                >
                                  {projectList && projectList.map((item,index) => (
                                    <MenuItem value={item._id} key={index}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </SelectField>
                            {errorObj.project_id ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}
                        </Grid>
                          ):null} 
                        {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER || userType === Constants.PROJECT_USER?(

                        <Grid item lg={4} sm={12} xs={12}>
                            <Typography varient='h6' sx={{fontSize: '15px'}}>SubContractor</Typography>
                             <SelectField
                                name="subcontractorName"
                                value={pfComplianceForm.subcontractor_id}
                                onChange={handleChangeSubcontractor}
                                >
                               {(pfComplianceForm.project_id && assignedProjectToSubcontractorList) && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                                <MenuItem value={item?.subcontractorId?._id} key={index}>
                                    {item?.subcontractorId?.subContractorName}
                                </MenuItem>
                              ))}
                            </SelectField>
                            {errorObj.subcontractor_id ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}

                        </Grid>
                         ):null} 

                        {userType === Constants.SUB_CONTRACTOR_USER ?(
                        <Grid item lg={4} sm={12} xs={12}>
                        <Typography sx={{fontSize: 15}}>Project</Typography>
                        <SelectField
                            value={assignedProject}
                            onChange={handleChangeProjectBySubcontractor}
                            >
                            {assignedProjectToSubcontractorList && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                            <MenuItem key={index} value={item?.projectId?._id}>
                                    {item?.projectId?.name}({item?.companyId?.name})
                            </MenuItem>
                            ))}
                        </SelectField> 
                        {(errorObj.project_id && errorObj.company_id) && <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>}
                        </Grid>
                    ):null
                    }
                        <Grid item lg={6} sm={12} xs={12}>
                            <Typography varient='h6' sx={{fontSize: '15px'}}>Upload Document</Typography>
                            <label style={{backgroundColor: '#f1f1f1', padding: '6px', borderRadius: '5px'}} className="cursor-pointer w-100">
                                <input
                                    type="file"
                                    id="profile2"
                                    onChange={handleChangeDocument}
                                />
                            </label>
                            {errorObj.pfCompliance_document ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}

                        </Grid>
                        <Grid item lg={6} xs={12}>
                        <Typography variant='h5' sx={{color: '#000', fontSize: 15, opacity: 'rgba(0, 0, 0, 0.87)'}}>Compliance Type</Typography>
                          <SelectField
                            value={pfComplianceForm.Compliance_type}
                            onChange={handleChangeComplianceType}
                            >
                                <MenuItem value={"ECR cum Return Statement"}>
                                    {"ECR cum Return Statement"}
                                </MenuItem>
                                <MenuItem value={"ECR Challan Receipt"}>
                                    {"ECR Challan Receipt"}
                                </MenuItem>
                                <MenuItem value={"PF Payment Receipt"}>
                                    {"PF Payment Receipt"}
                                </MenuItem>
                         </SelectField>
                         {errorObj.Compliance_type ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}

                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Typography variant='h5' sx={{color: '#000', fontSize: 15, opacity: 'rgba(0, 0, 0, 0.87)'}}>Month</Typography>
                          <SelectField
                            value={pfComplianceForm.month}
                            onChange={handleChangeMonth}
                            >
                        {monthList && monthList.masterMonthTable && monthList.masterMonthTable.map((item,index) => (
                              <MenuItem value={item.mth} key={index}>
                                    {item.mth}
                              </MenuItem> 
                        ))}
                         </SelectField>
                         {errorObj.month ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}

                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Typography variant='h5' sx={{color: '#000', fontSize: 15, opacity: 'rgba(0, 0, 0, 0.87)'}}>Year</Typography>
                          <SelectField
                            value={pfComplianceForm.year}
                            onChange={handleChangeYear}
                            >
                             {yearList && yearList.masterYearTable && yearList.masterYearTable.map((item,index) => (
                              <MenuItem value={item.yr} key={index}>
                                    {item.yr}
                              </MenuItem> 
                            ))}
                         </SelectField>
                         {errorObj.year ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}

                    </Grid>
                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography variant='' sx={{fontSize: '15px'}}>Note</Typography>
                            <TextareaAutosize
                                style={{
                                textAlign: "left",
                                width: "100%",
                                height: "80px",
                                background: '#f1f1f1',
                                borderRadius: '8px',
                                border: '1px solid #f1f1f1',
                                padding: '5px'
                                }}
                                name="note"
                                maxRows={4}
                                aria-label="maximum height"
                                placeholder="Note Here.."
                                onChange={(e) => {
                                    delete errorObj.company_id;
                                    setErrorObj({...errorObj})
                                    pfComplianceForm.note = e.target.value 
                                    setPfComplianceForm({...pfComplianceForm})
                                }}
                               />
                         {errorObj.note ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}

                        </Grid>
                        <Grid container justifyContent="flex-end">
                            <Button sx={{mt: 2}}
                                color="primary"
                                variant="contained"
                                type="submit"
                                onClick={handleSubmit}
                                >
                             {loading?<CircularProgress color="inherit" size={20}/>:<>Submit</>}
                            </Button>
                        </Grid>
                        
                    </Grid>
            </Card>
       
    </Box>
    )
}