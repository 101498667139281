import React, { useState,useEffect } from "react";
import {
    Grid, Typography, Button, CircularProgress, MenuItem
} from "@mui/material";
import PropTypes from 'prop-types';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Input from "components/Input";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { GeneralActions, LabourActions, MasterDataActions } from "slices/actions";
import { useHistory, useParams } from "react-router-dom";
import { GeneralSelector, MasterDataSelector } from "selectors";
import {toast} from "react-toastify";
import SelectField from "components/SelectField";
// import { format } from 'date-fns';


LabourExitDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    doj: PropTypes.instanceOf(Date)
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


export default function LabourExitDialog(props) {
    const { open, onClose,doj } = props
    const history = useHistory()
    const userId = localStorage.getItem("userID");
    const success = useSelector(GeneralSelector.success(LabourActions.exitLabour.type));
    const error = useSelector(GeneralSelector.error(LabourActions.exitLabour.type));
    const loading = useSelector(GeneralSelector.loader(LabourActions.exitLabour.type));
    const employmentTypeList = useSelector(MasterDataSelector.getEmploymentType())
    const {id} = useParams()
    const [labourExitForm,setLabourExitForm] = useState({
        exitDate: "",
        exitComment: "",
        employementType: ""
    })
    const dispatch = useDispatch();
    
    const onSubmit = () => {
        const params = {
            id: id,
            exitBy: userId,
            ...labourExitForm
        }
        dispatch(LabourActions.exitLabour(params))
    }
    useEffect(() => {
        dispatch(MasterDataActions.getEmploymentType());
    },[])

    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(LabourActions.exitLabour.type));
            onClose();
            history.goBack();
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeError(LabourActions.exitLabour.type));
        }
    }, [error]);

    return (
        <div>
            <BootstrapDialog style={{maxWidth: '800px', margin: 'auto'}}
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={onClose} sx={{ borderBottom: '1px solid #ccc' }}>
                    Employee Exit
                    <CloseOutlinedIcon onClick={onClose} sx={{float: 'right'}} />
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ pt: 2 }}>
                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography varient='h6' sx={{fontSize: '15px', color: '#333'}}>Exit Type</Typography>
                            <SelectField
                                value={labourExitForm.employementType}
                                onChange={(e) => {
                                    labourExitForm.employementType = e.target.value
                                    setLabourExitForm({...labourExitForm}) 
                                }}
                                >
                                {employmentTypeList && employmentTypeList.masterLabourExitReason && employmentTypeList.masterLabourExitReason.map((item,index) => (
                                <MenuItem value={item.id} key={index} sx={{fontSize: 14}}>
                                    {item.reason}
                                 </MenuItem>

                                ))}
                            </SelectField>
                        </Grid>
                        <Grid item lg={12} sm={12} xs={12}>
                            <Input
                                label="Date"
                                name='date'
                                type='date'
                                inputProps={{
                                    min: doj && doj.slice(0,10) 
                                  }}
                                value={labourExitForm.exitDate }
                                onChange={(e) => {
                                    labourExitForm.exitDate = e.target.value
                                    setLabourExitForm({...labourExitForm})
                                }}
                            />
                        </Grid>
                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography variant='' sx={{fontSize: '15px'}}>Comments</Typography>
                            <TextareaAutosize
                                style={{
                                    textAlign: "left",
                                    width: "100%",
                                    height: "80px",
                                    background: '#f1f1f1',
                                    borderRadius: '8px',
                                    border: '1px solid #f1f1f1',
                                    padding: '5px'
                                }}
                                name="comments"
                                maxRows={4}
                                aria-label="maximum height"
                                placeholder="Note Here.."
                                value={labourExitForm.exitComment }
                                onChange={(e) => {
                                    labourExitForm.exitComment = e.target.value
                                    setLabourExitForm({...labourExitForm})
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={onClose} variant="contained" color="primary">
                        Cancel
                    </Button>
                    <Button autoFocus onClick={onSubmit} variant="contained" color="primary">
                        {loading?<CircularProgress color="inherit" size={20}/>:<>Exit</>}

                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )

}