import React,{useState, useEffect} from 'react';
import {
     Card, Grid, MenuItem, Typography, Button
} from "@mui/material";
import SelectField from 'components/SelectField';
import { Constants } from "utils/constants";
import { CompanySelector } from "selectors/CompanySelector";
import { useSelector, useDispatch} from "react-redux";
import { ProjectSelector } from "selectors";
import { CompanyActions, MasterDataActions, ProjectActions, SubContractorActions, TransactionActions } from "slices/actions";
import Input from 'components/Input';
import Listing from './Listing';

export default function TransactionList() {
    const dispatch = useDispatch();
    const userType = localStorage.getItem('userType');
    const companyId = localStorage.getItem('companyID');
    const projectId = localStorage.getItem('projectID');
    const companyList = useSelector(CompanySelector.getCompanyList());
    const projectList = useSelector(ProjectSelector.getProjectList());
    const [showData,setShowData] = useState(false);
    const [formAdd,setFormAdd] = useState({
        company_id: companyId?companyId:"",
        project_id: projectId?projectId:"",
        start_date: "",
        end_date: "",
      })
    const [errObj,setErrObj] = useState({});  

    useEffect(() => {
        dispatch(MasterDataActions.getMonthList());
        dispatch(MasterDataActions.getYearList());
        if (!companyId) {
        dispatch(CompanyActions.getCompanyList());
        }  
        if (companyId && !projectId) {
            const params = {
                company_id: companyId
            }
            
            dispatch(ProjectActions.getProjectList(params))
        } 
        
        
    },[])

    useEffect(() => {
        if (formAdd.company_id || companyId) {
            const params = {
                company_id: formAdd.company_id ? formAdd.company_id: companyId,
                status: 1
           }
            
            dispatch(ProjectActions.getProjectList(params))
        }
    },[formAdd.company_id])
  
    useEffect(() => {
      if (formAdd.project_id || projectId) {
          const params = {
            company_id: formAdd.company_id || companyId,
            project_id: formAdd.project_id || projectId,
           }
          
           dispatch(SubContractorActions.getAssignedSubContractor(params));

      }
  },[formAdd.project_id])

    const handleChangeCompany = (e) => {
        delete errObj.company_id;
        setErrObj({...errObj});
        const cId = e.target.value;
        formAdd.company_id = cId;
        formAdd.project_id = "";
        setFormAdd({...formAdd})
    }   
    const handleChangeProject = (e) => {
        delete errObj.project_id;
        setErrObj({...errObj});
        if (formAdd.company_id) {
        const pId = e.target.value
        formAdd.project_id = pId;
        setFormAdd({...formAdd})
        } 
    }
   
    const handleChangeStartDate = (e) => {
        delete errObj.start_date;
        setErrObj({...errObj});
        formAdd.start_date = e.target.value;
        setFormAdd({...formAdd})
    }
    const handleChangeEndDate = (e) => {
        delete errObj.end_date;
        setErrObj({...errObj});
        formAdd.end_date = e.target.value;
        setFormAdd({...formAdd})
     }
   
    const validateForm = () => {
        let valid = true
        // if (!formAdd.company_id) {
        //   errObj.company_id = 'This field is Required';
        //   valid = false;
        // }
        // if (!formAdd.project_id) {
        //     errObj.project_id = 'This field is Required';
        //     valid = false;
        // }
         if (!formAdd.start_date) {
            errObj.start_date = 'This field is Required';
            valid = false;
          } 
          if (!formAdd.end_date) {
            errObj.end_date = 'This field is Required';
            valid = false;
          } 
        setErrObj({...errObj});
        return valid; 
    }
    
   const onNext = () => {
        if (validateForm()) {
            setShowData(true)
            dispatch(TransactionActions.getAllTransactionList(formAdd))
        }
    }
    return (
        <>
        <Card>
            <Typography variant="h5" sx={{ fontWeight: 600, position: 'relative', mb: 3 }}>Transaction List</Typography>
                <Grid container spacing={3}>
                {userType === Constants.SUPERADMIN?(
                    <Grid item lg={6} sm={12} xs={12}>
                        <Typography variant='h6' sx={{fontSize: 15}}>Company</Typography>
                        <SelectField
                            value={formAdd.company_id}
                            onChange={handleChangeCompany}
                            >
                              {companyList && companyList.map((item,index) => (
                                <MenuItem value={item._id} key={index}>
                                    {item.name}
                                </MenuItem>
                              ))}
                        </SelectField>
                        {errObj.company_id ? <Typography sx={{color: 'red',fontSize: 12}}>{errObj.company_id }</Typography>:null}
                    </Grid>
                     ):null} 
                  {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER?(
                    <Grid item lg={6} sm={12} xs={12}>
                        <Typography variant='h6' sx={{fontSize: 15}}>Project</Typography>
                        <SelectField
                            value={formAdd.project_id}
                            onChange={handleChangeProject}
                            >
                           {(formAdd.company_id && projectList) && projectList.map((item,index) => (
                                <MenuItem value={item._id} key={index}>
                                    {item.name}
                                </MenuItem>
                              ))}
                        </SelectField>
                        {errObj.project_id && <Typography sx={{color: 'red',fontSize: 12}}>{errObj.project_id}</Typography>}
                     </Grid>
                      ):null} 
                    
                     <Grid item lg={6} sm={12} xs={12}>
                        <Typography sx={{fontSize: 15}}>Start Date</Typography>
                        <Input
                        type="date"
                        value={formAdd.start_date}
                        onChange={handleChangeStartDate}
                        />
                         {errObj.start_date ? <Typography sx={{color: 'red',fontSize: 12}}>{errObj.start_date}</Typography>:null}
                    </Grid>
                    <Grid item lg={6} sm={12} xs={12}>
                        <Typography sx={{fontSize: 15}}>End Date</Typography>
                        <Input
                         type="date"
                         minDate={formAdd.start_date}
                         value={formAdd.end_date}
                         onChange={handleChangeEndDate}
                        />
                         {errObj.end_date ? <Typography sx={{color: 'red',fontSize: 12}}>{errObj.end_date}</Typography>:null}
                     </Grid>
                    <Grid item lg={12} sm={12} xs={12}>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            sx={{mt: 1, float: 'right'}}
                            onClick={onNext}
                            >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
        </Card>

         {showData?<Listing formData={formAdd}/>:""}

        </>
    )
}