import React,{useEffect} from "react";
import {
    Box,
    Button,
    CircularProgress,
    Grid
} from "@mui/material";
import Input from "components/Input";
import PropTypes from "prop-types";
import { GeneralActions, LabourActions } from "slices/actions";
import * as yup from "yup";
import {useFormik} from "formik";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GeneralSelector } from "selectors";
import {toast} from "react-toastify";


IdentityProof.propTypes = {
    labourDetails: PropTypes.object
};

export default function IdentityProof(props) {
    const { labourDetails } = props;
    const {id} = useParams();
    const dispatch = useDispatch();
    const success = useSelector(GeneralSelector.success(LabourActions.updateLabourPFBasicInfo.type));
    const loading = useSelector(GeneralSelector.loader(LabourActions.updateLabourPFBasicInfo.type));
    const error = useSelector(GeneralSelector.error(LabourActions.updateLabourPFBasicInfo.type));


   useEffect(() => {
    if (success) {
        toast.success(`${success?.message ?? "Success"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });

        dispatch(GeneralActions.removeSuccess(LabourActions.updateLabourPFBasicInfo.type));
    }
    }, [success]);

  useEffect(() => {
    if (error) {
        toast.error(`${error?.message ?? "Error"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });

        dispatch(GeneralActions.removeError(LabourActions.updateLabourPFBasicInfo.type));
    }
   }, [error]);


    const validationSchema = yup.object({
        panCard: yup.string().required('This field is required'),
        pfIdentityProof_NameAsPerPAN: yup.string().required('This field is required'),
        aadharNo: yup.string().min(12).
        max(12).
        required("This field is required"),
        pfIdentityProof_NameAsPerAadhar: yup.string().required('This field is required'),
    });

    const formik = useFormik({
        initialValues: {
            flag: "PFIdentityProof",
            panCard: labourDetails?.result?.panCard??"",
            pfIdentityProof_NameAsPerPAN: labourDetails?.result?.pfIdentityProof_NameAsPerPAN??"",
            aadharNo: labourDetails?.result?.aadharNo??"",
            pfIdentityProof_NameAsPerAadhar: labourDetails?.result?.pfIdentityProof_NameAsPerAadhar??"",
            pfIdentityProof_PassportNumber: labourDetails?.result?.passport??"",
            pfIdentityProof_ValidTillDate: labourDetails?.result?.pfIdentityProof_ValidTillDate??"",
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
           handleSubmit(values);
        }
    });
    const handleSubmit = (values) => {
        const params = {
            id: id,
          ...values,
      };
      dispatch(LabourActions.updateLabourPFBasicInfo(params));
   }


    return (
        <Box>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="PAN *"
                            name='panCard'
                            value={formik.values.panCard}
                            onChange={formik.handleChange}
                            error={formik.touched.panCard && Boolean(formik.errors.panCard)}
                            helperText={formik.touched.panCard && formik.errors.panCard}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Name as per PAN *"
                            name='pfIdentityProof_NameAsPerPAN'
                            maxLength = "85"
                            value={formik.values.pfIdentityProof_NameAsPerPAN}
                            onChange={formik.handleChange}
                            error={formik.touched.pfIdentityProof_NameAsPerPAN && Boolean(formik.errors.pfIdentityProof_NameAsPerPAN)}
                            helperText={formik.touched.pfIdentityProof_NameAsPerPAN && formik.errors.pfIdentityProof_NameAsPerPAN}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="AADHAAR Number *"
                            name='aadharNo'
                            type='number'
                            value={formik.values.aadharNo}
                            onChange={formik.handleChange}
                            error={formik.touched.aadharNo && Boolean(formik.errors.aadharNo)}
                            helperText={formik.touched.aadharNo && formik.errors.aadharNo}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Name as per AADHAAR *"
                            name='pfIdentityProof_NameAsPerAadhar'
                            maxLength = "5"
                            value={formik.values.pfIdentityProof_NameAsPerAadhar}
                            onChange={formik.handleChange}
                            error={formik.touched.pfIdentityProof_NameAsPerAadhar && Boolean(formik.errors.pfIdentityProof_NameAsPerAadhar)}
                            helperText={formik.touched.pfIdentityProof_NameAsPerAadhar && formik.errors.pfIdentityProof_NameAsPerAadhar}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Passport Number "
                            name='pfIdentityProof_PassportNumber'
                            value={formik.values.pfIdentityProof_PassportNumber}
                            onChange={formik.handleChange}
                            error={formik.touched.pfIdentityProof_PassportNumber && Boolean(formik.errors.pfIdentityProof_PassportNumber)}
                            helperText={formik.touched.pfIdentityProof_PassportNumber && formik.errors.pfIdentityProof_PassportNumber}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Valid Till Date "
                            name='pfIdentityProof_ValidTillDate'
                            type='date'
                            value={formik.values.pfIdentityProof_ValidTillDate}
                            onChange={formik.handleChange}
                            error={formik.touched.pfIdentityProof_ValidTillDate && Boolean(formik.errors.pfIdentityProof_ValidTillDate)}
                            helperText={formik.touched.pfIdentityProof_ValidTillDate && formik.errors.pfIdentityProof_ValidTillDate}/>
                    </Grid>
                 {labourDetails?.result?.status !== 99?(
                    <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained">
                            {loading?(
                                   <CircularProgress color="inherit" size={20}/>
                                ):(
                                  <>Update</>  
                                )}
                        </Button>
                    </Grid>):null}
                </Grid>
            </form>
        </Box>
    )
}