import {AccountCircleOutlined, InsertDriveFileOutlined, 
        BusinessOutlined,ReceiptLongOutlined, 
        AttractionsOutlined,CastConnectedOutlined, CastForEducationOutlined,
        DeveloperBoardOutlined, SavingsOutlined, PsychologyOutlined} from "@mui/icons-material";
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import BasicInformation from "../Component/AboutProjectDetails/form/ProjectDetailBasicInformation";
import ProjectDetailListing from "../Component/AboutProjectDetails/Listing/ProjectUserListing";
import ProjectSiteListing from "../Component/AboutProjectDetails/Listing/ProjectSiteListing";
import ProjectSubContractorListing from "../Component/AboutProjectDetails/Listing/ProjectSubcontractorListing";
import ProjectTransactionListing from "../Component/AboutProjectDetails/Listing/ProjectTransactionListing";
import Bocw from "../Component/AboutProjectDetails/form/Bocw";
import GpaWc from "../Component/AboutProjectDetails/form/GpaWc";
import LabourLicence from "../Component/AboutProjectDetails/form/LabourLicence";
import PfDetails from "../Component/AboutProjectDetails/form/PfData";
import ClientInfo from "../Component/AboutProjectDetails/form/ClientInfo";
// import DailyWages from "../Component/AboutProjectDetails/form/AllWages/DailyWages";
import ProjectWagesListing from "../Component/AboutProjectDetails/form/AllWages/ProjectWagesListing";
import ProjectSetting from "../Component/AboutProjectDetails/form/ProjectSetting";
import ProjectExtension from "../Component/AboutProjectDetails/form/ProjectExtension";
const menus = [
    {
        id: 'basic',
        name: 'Basic Information',
        icon: InsertDriveFileOutlined,
        component: BasicInformation,
        code: "Details"
    },
    {
        id: 'account',
        name: 'Account Setting',
        icon: AccountCircleOutlined,
        component: ProjectDetailListing,
        code: "Accountsetting"

    },
    {
        id: 'project',
        name: 'Project Setting',
        icon: BusinessOutlined,
        component: ProjectSetting,
        code: "Projectsetting"
    },
    {
        id: 'extension',
        name: 'Project Extension',
        icon: ExtensionOutlinedIcon,
        component: ProjectExtension,
        code: "ProjectExtension"

    },
    {
        id: 'clientinfo',
        name: 'Client Information',
        icon: AccountCircleOutlined,
        component: ClientInfo,
        code: "clientPermission"

    },
    {
        id: 'bocw',
        name: 'Bocw',
        icon: CastConnectedOutlined,
        component: Bocw,
        code: "bocw"

    },
    {
        id: 'gpawc',
        name: 'GpaWc',
        icon: CastForEducationOutlined,
        component: GpaWc,
        code: "gpaWc"
    },
    {
        id: 'labourlicence',
        name: 'Labour Licence Details',
        icon: DeveloperBoardOutlined,
        component: LabourLicence,
        code: 'labourLicence',
    },
    {
        id: 'pfdetails',
        name: 'PF Details',
        icon: SavingsOutlined,
        component: PfDetails,
        code: 'pfDetails',

    },
    {
        id: 'site',
        name: 'Project site Listing',
        icon: BusinessOutlined,
        component: ProjectSiteListing,
        code: "Projectsitelisting"
    },
    {
        id: 'transaction',
        name: 'Transaction Listing',
        icon: ReceiptLongOutlined,
        component: ProjectTransactionListing,
        code: "Transactionlisting"
    },
    {
        id: 'subcontractor',
        name: 'Subcontractor Listing',
        icon: AttractionsOutlined,
        component: ProjectSubContractorListing ,
        code: "subcontractorlisting"
    },
    {
        id: 'dailywages',
        name: 'Daily Wages',
        icon: PsychologyOutlined,
        // component: DailyWages ,
        component: ProjectWagesListing,
        code: "wages"
    },

];

export default menus;