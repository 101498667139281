import React, {useEffect} from "react";
import {
    // Box,
    Card,
    // FormControl,
    Grid,
    MenuItem,
    // InputBase,
    // MenuItem,
    Typography,
    // useTheme
} from "@mui/material";
// import Autocomplete from '@mui/material/Autocomplete';
// import COUNTRIES from "constants/countries";
// import {useDispatch, useSelector} from "react-redux";
// import {DesignationSelector} from "selectors";
// DepartmentSelector, 
// import {DepartmentActions, DesignationActions} from "slices/actions";
import Input from "components/Input";
import SelectField from "components/SelectField";
import PropTypes from "prop-types";
import { ProjectActions } from "slices/actions";
import { ProjectSelector } from "selectors/ProjectSelector";
import { useDispatch, useSelector } from "react-redux";


// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import {useFormik} from "formik";
// import EditIcon from '@mui/icons-material/Edit';


PaymentDuration.propTypes = {
    formik: PropTypes.object
};

export default function PaymentDuration(props) {
    const { formik } = props;
    const dispatch = useDispatch();
    const paymentCollectiontypes = useSelector(ProjectSelector.getPaymentAndDuration());

    // const dispatch = useDispatch();
    // const theme = useTheme();
    // const departments = useSelector(DepartmentSelector.getDepartments());
    // const designations = useSelector(DesignationSelector.getDesignations());
    // const countries = COUNTRIES.map(item => ({
    //     id: item.id,
    //     name: item.name,
    //     phoneCode: item.phoneCode,
    //     flag: item.flag
    // }));
   
     useEffect(() => {
        dispatch(ProjectActions.getPaymentAndDuration())
     }, []);

    useEffect(() => {
        const code = formik.values.country?.phoneCode;
        const phone = formik.values.phone;
        formik.setFieldValue('phoneCode', code ?? '');
        formik.setFieldValue('phone', phone);
    }, [formik.values.country]);

    return (
        <Card className="Paymentduration position-relative" sx={{ mb: 3}}>
            <Typography variant='h5' sx={{ mb: 4 }}>Payment Duration</Typography>
            {/* <EditIcon fontSize= "small" sx={{position: "absolute", right: "15px", top: "20px", color: "#3f7ed2"}} /> */}
            <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                <SelectField
                        label="Invoice Generate Interval"
                        name='payment_collection_mode'
                        value={formik.values.payment_collection_mode}
                        onChange={formik.handleChange}
                        error={formik.touched.payment_collection_mode && Boolean(formik.errors.payment_collection_mode)}
                        helperText={formik.touched.payment_collection_mode && formik.errors.payment_collection_mode}>
                    
                        {paymentCollectiontypes.paymenetcollection && paymentCollectiontypes.paymenetcollection.map((item, index) => (
                            <MenuItem key={index} value={item.id}>
                                {item.level}
                            </MenuItem>
                        ))}
                    </SelectField>

                </Grid>
                <Grid item lg={6} xs={12} className="forPricesymbol position-relative">
                    <Typography sx={{fontSize: '15'}}>Amount</Typography>
                    <span className="rateSpan">₹</span>
                    <Input style={{paddingLeft: '30px'}}
                        // label="Amount"
                        name='collection_amount'
                        value={formik.values.collection_amount}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.collection_amount) && Boolean(formik.errors.collection_amount)}
                        helperText={formik.touched.collection_amount && formik.errors.collection_amount}
                    />
                </Grid>
            </Grid>
        </Card>
    )
}