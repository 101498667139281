import React,{useState, useEffect} from 'react';
import {
     Card, Grid, MenuItem, Typography, Button, FormHelperText,CircularProgress
} from "@mui/material";
import SelectField from 'components/SelectField';
import { Constants } from "utils/constants";
import { CompanySelector } from "selectors/CompanySelector";
import { useSelector, useDispatch} from "react-redux";
import { GeneralSelector, LabourSelector, ProjectSelector, SubContractorSelector } from "selectors";
import { CompanyActions, GeneralActions, LabourActions, ProjectActions, SubContractorActions } from "slices/actions";
import { toast } from "react-toastify";
import Input from "components/Input";
import * as yup from "yup";
import {useFormik} from "formik";
import TextareaAutosize from '@mui/material/TextareaAutosize';
export default function LabourComplain() {
    const dispatch = useDispatch();
    const userType = localStorage.getItem('userType');
    const companyId = localStorage.getItem('companyID');
    const projectId = localStorage.getItem('projectID');
    const userId = localStorage.getItem('userID');
    const subContractorId = localStorage.getItem('subcontractorID');
    const companyList = useSelector(CompanySelector.getCompanyList());
    const projectList = useSelector(ProjectSelector.getProjectList());
    const [assignedProject,setAssignedProject] = useState("");
    const assignedProjectToSubcontractorList = useSelector(SubContractorSelector.getAssignedSubcontractor());
    const labourListData = useSelector(LabourSelector.getLabourList());
    const success = useSelector(GeneralSelector.success(LabourActions.labourComplain.type));
    const error = useSelector(GeneralSelector.error(LabourActions.labourComplain.type));
    const loading = useSelector(GeneralSelector.loader(LabourActions.labourComplain.type));
    
    useEffect(() => {
        if (!companyId) {
        dispatch(CompanyActions.getCompanyList());
        }  
        if (companyId && !projectId) {
            const params = {
                company_id: companyId
            }
            
            dispatch(ProjectActions.getProjectList(params))
        } 
        if (companyId && projectId) {
            const params = {
                company_id: companyId,
                project_id: projectId
            }
            dispatch(SubContractorActions.getAssignedSubContractor(params))
        } 
         if (subContractorId) {
            const params = {
                subcontractorId: subContractorId
            }
            dispatch(SubContractorActions.getAssignedSubContractor(params));
        }
    },[])

    useEffect(() => {
        if (success) {
          toast.success(`${success?.message ?? "Success"}`, {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
          });
    
          dispatch(GeneralActions.removeSuccess(LabourActions.labourComplain.type));
        }
      }, [success]);
    
      useEffect(() => {
        if (error) {
          toast.error(`${error?.message ?? "Error"}`, {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
          });
    
          dispatch(GeneralActions.removeError(LabourActions.labourComplain.type));
        }
      }, [error]);
    
    const validationSchema = yup.object({
      companyId: yup.string().required('This field is required'),
      projectId: yup.string().required('This field is required'),
      subContractorId: yup.string().required('This field is required'),
      raiseDate: yup.string().required('This field is required'),
      labourId: yup.string().required("This field is required"),
      title: yup.string().required("This field is required"),
      description: yup.string().required("This field is required"),
    });
    const formik = useFormik({
      initialValues: {
          companyId: companyId?companyId:"",
          projectId: projectId?projectId:"",
          subContractorId: subContractorId?subContractorId:"",
          raiseDate: "",
          title: "",
          description: "",
          labourId: "",
          addedBy: userId,
        //   resolveDate: "",
        //   resolvedBy: ""
      },
      enableReinitialize: true,
      valiraiseDateOnChange: true,
      validationSchema: validationSchema,
      onSubmit: (values) => {
          handleSubmit(values);
      }
  });
  const handleSubmit = (values) => {
    dispatch(LabourActions.labourComplain(values))
  }

    const handleChangeCompany = (e) => {
        formik.setFieldValue("companyId",e.target.value)
        const params = {
            company_id: e.target.value
        }
        dispatch(ProjectActions.getProjectList(params))
    }   
    const handleChangeProject = (e) => {
        formik.setFieldValue("projectId",e.target.value)
        const params = {
            company_id: formik.values.companyId,
            project_id: e.target.value
        }
        dispatch(SubContractorActions.getAssignedSubContractor(params))
    }
    const handleChangeSubcontractor = (e) => { 
        formik.setFieldValue("subContractorId",e.target.value)
        const params = {
            company_id: companyId?companyId:formik.values.companyId,
            project_id: projectId?projectId:formik.values.projectId,
            subcontractor_id: e.target.value 
        }
        dispatch(LabourActions.getLabourList(params));
    }

    const handleChangeProjectBySubcontractor = (e) => {
        setAssignedProject(e.target.value)
        for (const i in assignedProjectToSubcontractorList.data) {
            if (assignedProjectToSubcontractorList.data[i].projectId._id === e.target.value) {
                formik.setFieldValue("companyId",e.target.value)
                formik.setFieldValue("projectId",assignedProjectToSubcontractorList.data[i].companyId._id)
                const params = {
                    company_id: formik.values.companyId,
                    project_id: formik.values.projectId,
                    subcontractor_id: formik.values.subContractorId
                }
                dispatch(LabourActions.getLabourList(params));
                
            }
               
        }
     }

    const handleChangeLabour = (e) => {
        formik.setFieldValue("labourId",e.target.value)
    }

    return (
        <Card>
            <Typography variant="h5" sx={{ fontWeight: 600, position: 'relative', mb: 3 }}>Labour Complain</Typography>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                {userType === Constants.SUPERADMIN?(
                    <Grid item lg={4} sm={12} xs={12}>
                        <Typography variant='h6' sx={{fontSize: 15}}>Company</Typography>
                        <SelectField
                            value={formik.values.companyId}
                            name="companyId"
                            onChange={handleChangeCompany}
                            error={formik.touched.companyId && Boolean(formik.errors.companyId)}
                            helperText={formik.touched.companyId && formik.errors.companyId}
                            >
                              {companyList && companyList.map((item,index) => (
                                <MenuItem value={item._id} key={index}>
                                    {item.name}
                                </MenuItem>
                              ))}
                        </SelectField>
                        
                    </Grid>
                     ):null} 
                  {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER?(
                    <Grid item lg={4} sm={12} xs={12}>
                        <Typography variant='h6' sx={{fontSize: 15}}>Project</Typography>
                        <SelectField
                            value={formik.values.projectId}
                            name="projectId"
                            onChange={handleChangeProject}
                            error={formik.touched.projectId && Boolean(formik.errors.projectId)}
                            helperText={formik.touched.projectId && formik.errors.projectId}
                            >
                           { (formik.values.companyId && projectList) && projectList.map((item,index) => (
                                <MenuItem value={item._id} key={index}>
                                    {item.name}
                                </MenuItem>
                              ))}
                        </SelectField>
                        
                     </Grid>
                      ):null} 
                    {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER || userType === Constants.PROJECT_USER?(
                        <Grid item lg={4} sm={12} xs={12}>
                        <Typography variant='h6' sx={{fontSize: 15}}>SubContractor</Typography>
                        <SelectField
                            value={formik.values.subContractorId}
                            name="subContractorId"
                            onChange={handleChangeSubcontractor}
                            error={formik.touched.subContractorId && Boolean(formik.errors.subContractorId)}
                            helperText={formik.touched.subContractorId && formik.errors.subContractorId}
                            >
                           {(formik.values.projectId && assignedProjectToSubcontractorList) && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                                <MenuItem value={item?.subcontractorId?._id} key={index}>
                                    {item?.subcontractorId?.subContractorName}
                                </MenuItem>
                              ))}
                        </SelectField>
                        
                     </Grid>
                      ):null} 

                     {userType === Constants.SUB_CONTRACTOR_USER ?(
                    <Grid item lg={4} sm={12} xs={12}>
                        <Typography sx={{fontSize: 15}}>Project</Typography>
                        <SelectField
                            value={assignedProject}
                            onChange={handleChangeProjectBySubcontractor}
                            error={formik.touched.projectId && Boolean(formik.errors.projectId)}
                            helperText={formik.touched.projectId && formik.errors.projectId}
                            >
                            {assignedProjectToSubcontractorList && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                            <MenuItem key={index} value={item.projectId._id}>
                                    {item?.projectId?.name}({item?.companyId?.name})
                            </MenuItem>
                            ))}
                        </SelectField> 
                    </Grid>
                    ):null
                    }
                    

                    <Grid item lg={4} sm={12} xs={12}>
                        <Typography sx={{fontSize: 15}}>Labour Name</Typography>
                        <SelectField
                            value={formik.values.labourId}
                            onChange={handleChangeLabour}
                            error={formik.touched.labourId && Boolean(formik.errors.labourId)}
                            helperText={formik.touched.labourId && formik.errors.labourId}
                            >
                            {(formik.values.subContractorId && labourListData) && labourListData.map((item,index) => (
                            <MenuItem key={index} value={item._id}>
                                    {`${item.firstName} ${"  "} ${item.lastName}`} 
                            </MenuItem>
                            ))}
                        </SelectField> 
                    </Grid>
                    
                    <Grid item lg={4} sm={12} xs={12}>
                        <Input
                            fullWidth
                            label="raiseDate"
                            type="date"
                            name="raiseDate"
                            defaultValue={formik.values.raiseDate}
                            onChange={formik.handleChange}
                            error={formik.touched.raiseDate && Boolean(formik.errors.raiseDate)}
                            helperText={formik.touched.raiseDate && formik.errors.raiseDate}
                            />
                    </Grid>
                   
                    <Grid item lg={4} sm={12} xs={12}>
                        <Input
                            fullWidth
                            label="Reason Title"
                            type="text"
                            name="title"
                            defaultValue={formik.values.title}
                            onChange={formik.handleChange}
                            error={formik.touched.title && Boolean(formik.errors.title)}
                            helperText={formik.touched.title && formik.errors.title}
                            />
                        
                    </Grid>
                    <Grid item lg={8} sm={12} xs={12}>
                        <Typography variant='' sx={{fontSize: '15px'}}>description</Typography>
                        <TextareaAutosize
                            style={{
                            textAlign: "left",
                            width: "100%",
                            height: "80px",
                            background: '#f1f1f1',
                            borderRadius: '8px',
                            border: '1px solid #f1f1f1',
                            padding: '5px'
                            }}
                            maxRows={4}
                            aria-label="maximum height"
                            name="description"
                            placeholder="Note Here.."
                            value={formik.values.description}
                            onChange={formik.handleChange}
                          />
                          {(formik.touched.description && formik.errors.description) && (
                            <FormHelperText error={formik.touched.description && Boolean(formik.errors.description)}>{formik.touched.description && formik.errors.description}</FormHelperText>
                          )}
                    </Grid>
                    <Grid item lg={12} sm={12} xs={12} justifyContent="flex-end" sx={{mt: 2, textAlign: 'right'}}>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            sx={{mt: 1}}
                            // onClick={onNext}
                            type="submit"
                            >
                            {loading?<CircularProgress color="inherit" size={20}/>:<>Submit</>}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Card>
    )
}