import React ,{useEffect, useState} from "react";
import {
    Card,
    Grid,
    Typography,
     Button,
    Box,
    useTheme,
    FormControl,
} from "@mui/material";
import Input from "components/Input";
import { useFormik } from "formik";
import { GeneralActions, MasterDataActions, ProjectActions} from "slices/actions";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { GeneralSelector, MasterDataSelector } from "selectors";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import {toast} from "react-toastify";
import * as yup from "yup";

ClientInfo.propTypes = {
    projectDetails: PropTypes.object,
};

export default function ClientInfo(props) {
    const { projectDetails } = props;
    const {id} = useParams();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [pincode, setPincode]= useState();
    const success = useSelector(GeneralSelector.success(ProjectActions.updateProject.type));
    const error = useSelector(GeneralSelector.error(ProjectActions.updateProject.type));
    const loading = useSelector(GeneralSelector.loader(ProjectActions.updateProject.type));
    const locationData = useSelector(MasterDataSelector.getLocationListByPincode());
    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(ProjectActions.updateProject.type));
            dispatch(ProjectActions.getProjectById(id));
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeError(ProjectActions.updateProject.type));
        }
    }, [error]);

    useEffect(() => {
        if (pincode && pincode.length === 6) {
            dispatch(ProjectActions.fetchDataByPincode(pincode));
        }
    }, [pincode]);

    const validationSchema = yup.object({
        clientName: yup.string().required('This field is required'),
        clientEmail: yup.string().email().
        required("Email is required"),
        clientContactState: yup.string().required('This field is required'),
        clientAddressPincode: yup.string().matches(/^[0-9]+$/, "Must be only digits").
        length(6, 'Pincode must be exactly 6 characters!').
        required('Pincode is required'),
        clientContactCity: yup.string().required("This field is required"),
        clientLocation: yup.string().required('This field is required'),
        clientContactPerson: yup.string().required('This field is required'),
        clientContactNumber: yup.string().min(10).
        max(10).
        matches(/^[0-9\b]+$/,"Invalid Phone Number").
        required('Phone Number is required'),

    });
    const formik = useFormik({
        initialValues: {
            clientName: projectDetails.data?.project?.clientName ?? "",
            clientEmail: projectDetails.data?.project?.clientEmail ?? "",
            clientAddressPincode: projectDetails.data?.project?.clientAddressPincode ?? "",
            clientContactState: projectDetails.data?.project?.clientContactState ?? "",
            clientContactCity: projectDetails.data?.project?.clientContactCity ?? "",
            clientLocation: projectDetails.data?.project?.clientLocation ?? "",
            clientContactNumber: projectDetails.data?.project?.clientContactNumber ?? "",
            clientContactPerson: projectDetails.data?.project?.clientContactPerson ?? "",
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
          handleSubmit(values);
        },
    });
    const handleSubmit = (values) => {
        const params ={
            id: id,
            flag: "client",
            ...values
        }
        dispatch(ProjectActions.updateProject(params))
    };
    function handleMobileChange(e) {
        const val = e.target.value.replace(/[^0-9]/g, "");        
        formik.handleChange(e);
        formik.setFieldValue('clientContactNumber',val);
    }
    const handleChangePinCode= (e) => {        
        const pincodeData = e.target.value
        formik.setFieldValue('clientAddressPincode',pincodeData)
        setPincode(pincodeData);
    }

    useEffect(() => {
        if (pincode && pincode.length === 6) {
            dispatch(MasterDataActions.fetchDataByPincode(pincode));
        }
    }, [pincode]);
    useEffect(() => {
        if (pincode && locationData) { 
         const addressData = locationData.PlaceName
         formik.setFieldValue('clientLocation', addressData);
         formik.setFieldValue('clientContactState', locationData?.StateName);
         formik.setFieldValue('clientContactCity', locationData?.District);
         }
     }, [locationData]);

     useEffect(() => {
        if (projectDetails) {
        console.log('projectDetails',JSON.stringify(projectDetails))
        }
     },[])

    return (
        <Card className="Paymentduration position-relative" sx={{ mb: 3}}>
            <Typography variant='h5' sx={{ mb: 4 }}>Client Information</Typography>
            <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                    <Input
                        fullWidth
                        label="Name *"
                        type="text"
                        name="clientName"
                        value={formik.values.clientName}
                        onChange={formik.handleChange}
                        error={formik.touched.clientName && Boolean(formik.errors.clientName)}
                        helperText={formik.touched.clientName && formik.errors.clientName}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        fullWidth
                        label="Email *"
                        type="email"
                        name="clientEmail"
                        value={formik.values.clientEmail}
                        onChange={formik.handleChange}
                        error={formik.touched.clientEmail && Boolean(formik.errors.clientEmail)}
                        helperText={formik.touched.clientEmail && formik.errors.clientEmail}
                    />
                </Grid>   
                <Grid item lg={6} xs={12}>
                <Input
                    label='Pincode *'
                    name='clientAddressPincode'
                    type='number'                        
                    value={formik.values.clientAddressPincode}
                    onChange={ (e) => handleChangePinCode(e)}
                    error={formik.touched.clientAddressPincode && Boolean(formik.errors.clientAddressPincode)}
                    helperText={formik.touched.clientAddressPincode && formik.errors.clientAddressPincode}
                    
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="State *"
                        name='clientContactState'
                        value={formik.values.clientContactState}
                        error={formik.touched.clientContactState && Boolean(formik.errors.clientContactState)}
                        helperText={formik.touched.clientContactState && formik.errors.clientContactState}
                        disabled
                        />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="City *"
                        name='clientContactCity'
                        value={formik.values.clientContactCity}
                        error={formik.touched.clientContactCity && Boolean(formik.errors.clientContactCity)}
                        helperText={formik.touched.clientContactCity && formik.errors.clientContactCity}
                        disabled
                        />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input style={{Height: "80px"}}
                        label="Address *"
                        name='clientLocation'
                        fullHeight
                        value={formik.values.clientLocation}
                        onChange={formik.handleChange}
                        error={formik.touched.clientLocation && Boolean(formik.errors.clientLocation)}
                        helperText={formik.touched.clientLocation && formik.errors.clientLocation}/>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        fullWidth
                        label="Contact Person Name *"
                        name="clientContactPerson"
                        value={formik.values.clientContactPerson}
                        onChange={formik.handleChange}
                        error={formik.touched.clientContactPerson && Boolean(formik.errors.clientContactPerson)}
                        helperText={formik.touched.clientContactPerson && formik.errors.clientContactPerson}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                <FormControl fullWidth>
                        <Typography variant='caption' sx={{fontSize: "15px"}}>Phone Number *</Typography>
                        <Box sx={{
                            display: 'flex',
                            gap: 1.5
                        }}>
                            <Box sx={{ width: 80 }}>
                                <Input
                                    sx={{
                                        padding: "10px 8px",
                                        textAlign: 'center',
                                        '& .Mui-disabled': {
                                            fillColor: theme.palette.common.black
                                        }
                                    }}
                                    autoComplete='new-password'
                                    name='phoneCode'
                                    startAdornment='+'
                                    value="91"
                                    />
                            </Box>
                            <Input
                                name='clientContactNumber'
                                value={formik.values.clientContactNumber}
                                onChange={handleMobileChange}
                                error={formik.touched.clientContactNumber && Boolean(formik.errors.clientContactNumber)}
                                helperText={formik.touched.clientContactNumber && formik.errors.clientContactNumber}/>
                        </Box>
                    </FormControl>
                </Grid>
                <Grid container justifyContent="flex-end" sx={{mt: 2}}>
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        type="submit"
                    >
                        {loading ? (
                        <CircularProgress color="inherit" size={20} />
                        ) : (
                        <>Submit</>
                        )}
                    </Button>
                </Grid>
            </Grid>
            </form>
        </Card>
    )
}