import React ,{useEffect, useState} from "react";
import {
    Box,
    Card,
    FormControl,
    MenuItem,
    Grid,
    Typography,
    Button,
    CircularProgress
} from "@mui/material";
import SelectField from "components/SelectField";
import Input from "components/Input";
import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import {GeneralActions, UserActions, MasterDataActions, CompanyActions, SubContractorActions } from "slices/actions";
import {useDispatch,useSelector} from "react-redux";
import { GeneralSelector, MasterDataSelector } from "selectors";
import {toast} from "react-toastify";
import { useParams } from "react-router-dom";
import PasswordChange from "components/PasswordChangeDialog";

BasicInfoDetails.propTypes = {
    subcontractorDetails: PropTypes.object,
    // form: PropTypes.object
};

export default function BasicInfoDetails(props) {
    const { subcontractorDetails } = props;
    const {id} = useParams();
    const [changePwd,setChangePwd] = useState(false);
    const [pincode,setPincode]=useState();
    const dispatch = useDispatch();
    const success = useSelector(GeneralSelector.success(SubContractorActions.updateSubContractor.type));
    const error = useSelector(GeneralSelector.error(SubContractorActions.updateSubContractor.type));
    const loading = useSelector(GeneralSelector.loader(SubContractorActions.updateSubContractor.type));
    const natureOfWork = useSelector(MasterDataSelector.getNatureOfWork());

    useEffect(() => {
        dispatch(MasterDataActions.getNatureOfWork());
    }, []);
    const handleChangePinCode= (e) => {
         const pincodeData = e.target.value
         formik.setFieldValue('pincode',pincodeData)
         setPincode(pincodeData);
     }
     useEffect(() => {
         if (pincode && pincode.length === 6) {
             dispatch(CompanyActions.fetchDataByPincode(pincode));
         }
     }, [pincode]);
     

useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(UserActions.updateUser.type));
            dispatch(UserActions.getUserById(id));


        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(UserActions.updateUser.type));
        }
    }, [error]);
 const validationSchema = yup.object({
    subContractorCode: yup.string().required("This field is required"),
    subContractorName: yup.string().required("This field is required"),
    subContractorEmail: yup.string().email().
    required("Email is required"),
    subContractorPhone: yup.string().min(10).
    max(10).
    matches(/^[0-9\b]+$/,"Invalid Phone Number").
    required("This field is required"),
    pan: yup.string().matches("[A-Z]{5}[0-9]{4}[A-Z]{1}","InValid PAN No").
     required("This field is required"),
    doj: yup.string().required("This field is required"),
    gst: yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}Z[a-zA-Z0-9]{1}$/,"InValid GST No").
    required("Gst is required"),
    natureOfWork: yup.string().required("This field is required"),
    workLocation: yup.string().required("This field is required"),
    venderCode: yup.string().required("This field is required"),
    pincode: yup.string().matches(/^[0-9]+$/, "Must be only digits").
    length(6, 'Pincode must be exactly 6 characters').
    required('Pincode is required'),
    // state: yup.string().required("This field is required"),
    // city: yup.string().required("This field is required"),
    address: yup.string().required("This field is required"),
    propriterName: yup.string().required("This field is required"),
    supervisorName: yup.string().required("This field is required"),
    supervisorMobileNo: yup.string().min(10).
    max(10).
    matches(/^[0-9\b]+$/,"Invalid Phone Number").
    required("This field is required"),

    })
    const formik = useFormik({
        initialValues: {
          subContractorCode: subcontractorDetails?.subContractorCode ?? "", 
          subContractorName: subcontractorDetails?.subContractorName ?? "",
          subContractorEmail: subcontractorDetails?.subContractorEmail ?? "",
          subContractorPhone: subcontractorDetails?.subContractorPhone ?? "",
          pan: subcontractorDetails?.pan ?? "",
          gst: subcontractorDetails?.gst ?? "",
          supervisorName: subcontractorDetails?.supervisorName ?? "",
          natureOfWork: subcontractorDetails?.natureOfWork ?? "",
          doj: subcontractorDetails?.doj ?? "",
          pincode: subcontractorDetails?.pincode ?? "",
          state: subcontractorDetails?.state ?? "",
          city: subcontractorDetails?.city ?? "",
          address: subcontractorDetails?.address ?? "",
          propriterName: subcontractorDetails?.propriterName ?? "",
          workLocation: subcontractorDetails?.workLocation ?? "",
          venderCode: subcontractorDetails?.venderCode ?? "",
          supervisorMobileNo: subcontractorDetails?.supervisorMobileNo ?? "",
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
        })
        const handleSubmit = (values) => {
            const params ={
                id: id,
                ...values
            }
            dispatch(SubContractorActions.updateSubContractor(params))
        }

    return (
        <Card sx={{ mb: 3 }}>
            <Typography variant='h5' sx={{ mb: 4 }}>Basic Information</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Code *"
                            name='subContractorCode'
                            value={formik.values.subContractorCode}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.subContractorCode) && Boolean(formik.errors.subContractorCode)}
                            helperText={formik.touched.subContractorCode && formik.errors.subContractorCode}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Name *"
                            name='subContractorName'
                            value={formik.values.subContractorName}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.subContractorName) && Boolean(formik.errors.subContractorName)}
                            helperText={formik.touched.subContractorName && formik.errors.subContractorName}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Email Id *"
                            name='subContractorEmail'
                            value={formik.values.subContractorEmail}
                            onChange={formik.handleChange}
                            error={formik.touched.subContractorEmail && Boolean(formik.errors.subContractorEmail)}
                            helperText={formik.touched.subContractorEmail && formik.errors.subContractorEmail}/>
                    </Grid>
                
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                            <Typography variant='' sx={{fontSize: "15px"}}>Phone Number </Typography>
                            <Box sx={{
                                display: 'flex',
                                gap: 1.5
                            }}>
                                <Box sx={{ width: 90 }}>
                                    <Input style={{appearence: 'none'}}
                                        sx={{
                                            padding: "10px 8px",
                                            textAlign: 'center',
                                            // '& .Mui-disabled': {
                                            //     fillColor: theme.palette.common.black
                                            // }
                                        }}
                                        autoComplete='new-password'
                                        name='phoneCode'
                                        startAdornment='+'
                                        value="91"
                                        />
                                </Box>
                                <Input
                                    name='subContractorPhone'
                                    value={formik.values.subContractorPhone}
                                    onChange={formik.handleChange}
                                    error={formik.touched.subContractorPhone && Boolean(formik.errors.subContractorPhone)}
                                    helperText={formik.touched.subContractorPhone && formik.errors.subContractorPhone}/>
                            </Box>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="PAN"
                            name='pan'
                            value={formik.values.pan}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.pan) && Boolean(formik.errors.pan)}
                            helperText={formik.touched.pan && formik.errors.pan}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Date of Joining"
                            name='doj'
                            type= 'date'
                            value={formik.values.doj.slice(0,10)}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.doj) && Boolean(formik.errors.doj)}
                            helperText={formik.touched.doj && formik.errors.doj}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="GST"
                            name='gst'
                            value={formik.values.gst}
                            onChange={formik.handleChange}
                            error={formik.touched.gst && Boolean(formik.errors.gst)}
                            helperText={formik.touched.gst && formik.errors.gst}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Typography variant='h5' sx={{color: '#000', fontSize: 15, opacity: 'rgba(0, 0, 0, 0.87)'}}>Nature Of Work</Typography>
                        <SelectField
                            label= ""
                            name= "natureOfWork"
                            value={formik.values.natureOfWork}
                            onChange={formik.handleChange}
                            error={formik.touched.natureOfWork && Boolean(formik.errors.natureOfWork)}
                            helperText={formik.touched.natureOfWork && formik.errors.natureOfWork}
                            >
                            {natureOfWork.subcontractorScopesofWork && natureOfWork.subcontractorScopesofWork.map((item,index) => (
                                <MenuItem key={index} value={item.id}>
                                    {item.level}
                                </MenuItem>
                            ))}
                        
                        
                        </SelectField>
                    </Grid>
                    
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Office Address"
                            name='workLocation'
                            value={formik.values.workLocation}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.workLocation) && Boolean(formik.errors.workLocation)}
                            helperText={formik.touched.workLocation && formik.errors.workLocation}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Vender Code(VO)"
                            name='venderCode'
                            value={formik.values.venderCode}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.venderCode) && Boolean(formik.errors.venderCode)}
                            helperText={formik.touched.venderCode && formik.errors.venderCode}/>
                    </Grid>
                    
                    <Grid item lg={6} xs={12}>
                     
                        <Input
                            label='Pincode'
                            name='pincode'
                            type='number'
                            value={formik.values.pincode}
                            onChange={ (e) => handleChangePinCode(e)}
                            error={formik.touched.pincode && Boolean(formik.errors.pincode)}
                            helperText={formik.touched.pincode && formik.errors.pincode}
                        />
                    </Grid>
                
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="State"
                            name='state'
                            value={formik.values.state}
                            error={formik.touched.state && Boolean(formik.errors.state)}
                            helperText={formik.touched.state && formik.errors.state}
                            disabled
                            />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                    <Input
                            label="City"
                            name='city'
                            value={formik.values.city}
                            error={formik.touched.city && Boolean(formik.errors.city)}
                            helperText={formik.touched.city && formik.errors.city}
                            disabled
                            />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Address"
                            name='address'
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Proprietor Name"
                            name='propriterName'
                            value={formik.values.propriterName}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.propriterName) && Boolean(formik.errors.propriterName)}
                            helperText={formik.touched.propriterName && formik.errors.propriterName}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Supervisor Name"
                            name='supervisorName'
                            value={formik.values.supervisorName}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.supervisorName) && Boolean(formik.errors.supervisorName)}
                            helperText={formik.touched.supervisorName && formik.errors.supervisorName}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                            <Typography variant='' sx={{fontSize: "15px"}}>Supervisor Phone No</Typography>
                            <Box sx={{
                                display: 'flex',
                                gap: 1.5
                            }}>
                                <Box sx={{ width: 90 }}>
                                    <Input style={{appearence: 'none'}}
                                        sx={{
                                            padding: "10px 8px",
                                            textAlign: 'center',
                                            // '& .Mui-disabled': {
                                            //     fillColor: theme.palette.common.black
                                            // }
                                        }}
                                        autoComplete='new-password'
                                        name='phoneCode'
                                        startAdornment='+'
                                        value="91"
                                        />
                                </Box>
                                <Input
                                    name='supervisorMobileNo'    
                                    value={formik.values.supervisorMobileNo}
                                    onChange={formik.handleChange}
                                    error={formik.touched.supervisorMobileNo && Boolean(formik.errors.supervisorMobileNo)}
                                    helperText={formik.touched.supervisorMobileNo && formik.errors.supervisorMobileNo}/>
                            </Box>
                        </FormControl>
                            
                    </Grid>
                    <Grid sx={{ mt: 3 }} item lg={6} xs={12}>
                               <Typography onClick = {() => {
                                 setChangePwd(true)
                                }} sx={{color: "#2c7be5",fontSize: 14, fontWeight: 'bold', cursor: 'pointer'}}>Change Password</Typography>
                    </Grid>
                    <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained">
                            {loading ? (
                            <CircularProgress color="inherit" size={20} />
                                ) : (
                            <>Update</>
                        )}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <PasswordChange 
                 title="Change Password"
                 open={changePwd}
                 onClose={() => setChangePwd(false)}
                 id={subcontractorDetails?._id}
                />
        </Card>
    )

}