import React ,{useState,useEffect} from "react";
import {
    Button, Card, Box,
    Grid,
    Typography, FormControl
} from "@mui/material";
import {useFormik} from "formik";
import * as yup from "yup";
import {useDispatch,useSelector} from "react-redux";
import Input from "components/Input";
import {CompanyActions, GeneralActions} from "slices/actions";
import PropTypes from "prop-types";
import PasswordChange from "components/PasswordChangeDialog";
import { GeneralSelector } from "selectors";
import {toast} from "react-toastify";
import { useParams } from "react-router-dom";

// import Can from "../../../../utils/can";
// import {actions, features} from "../../../../constants/permission";

AccountSetting.propTypes = {
    companyDetails: PropTypes.object,
    form: PropTypes.object
};

export default function AccountSetting(props) {
    const { companyDetails } = props;
    const {id} = useParams();
    const dispatch = useDispatch();
    const [changePwd,setChangePwd] = useState(false);
    const success = useSelector(GeneralSelector.success(CompanyActions.updateCompanyUser.type));
    const error = useSelector(GeneralSelector.error(CompanyActions.updateCompanyUser.type));

    // const [showPassword, setShowPassword] = useState(false);
    // window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth',
    // });
    const validationSchema = yup.object({
        name: yup.string().required("Name is Required"),
        email: yup.string().email().
        required('Email is required'),
        phone: yup.string().min(10).
        max(10).
        matches(/^[0-9\b]+$/,"Invalid Phone Number").
        required('Phone number is required')
       
    });

    const formik = useFormik({
        initialValues: {
            name: companyDetails.users[0]?.name ?? "",
            email: companyDetails.users[0]?.email ?? "",
            phone: companyDetails.users[0]?.phone ?? "",
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                position: "top-right",
                autoClose: 3000,
                color: "white",
                closeOnClick: true,
            });

            dispatch(GeneralActions.removeError(CompanyActions.updateCompanyUser.type));
            
        }
    }, [error]);

    function handleMobileChange(e) {
         const val = e.target.value.replace(/[^0-9]/g, "");        
         formik.handleChange(e);
         formik.setFieldValue('phone',val);
       }
 
    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(CompanyActions.updateCompanyUser.type));
            dispatch(CompanyActions.getCompanyById(id))
            changePassword();
        }
    }, [success]);
    // const handleClickShowPassword = () => {
    //     setShowPassword(!showPassword);
    // };

    // const handleMouseDownPassword = (event) => {
    //     event.preventDefault();
    // };

    // const handleRole = ({ target }) => {
    //     formik.setFieldValue('role', target.value);
    // }

    const handleSubmit = (values) => {
        const params = {
            id: companyDetails.users[0]._id,
            ...values,
        };

        dispatch(CompanyActions.updateCompanyUser(params));
    }
 
    const changePassword = () => {
        setChangePwd(false)
    }
     return (
        <Card>
           
            <Typography variant='h5' sx={{ mb: 4 }}>Account Setting</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                        <Input
                            label="Email"
                            type="email"
                            name='email'
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.email) && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            />
                    </Grid>
                   
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="User Name"
                            type="text"
                            name='name'
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.name) && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                            <Typography variant='' sx={{fontSize: "15px"}}>Phone Number</Typography>
                            <Box sx={{
                                display: 'flex',
                                gap: 1.5
                            }}>
                                <Box sx={{ width: 90 }}>
                                    <Input style={{appearence: 'none'}}
                                        sx={{
                                            padding: "10px 8px",
                                            textAlign: 'center',
                                            // '& .Mui-disabled': {
                                            //     fillColor: theme.palette.common.black
                                            // }
                                        }}
                                        autoComplete='new-password'
                                        name='phoneCode'
                                        startAdornment='+'
                                        value="91"
                                        />
                                </Box>
                                <Input
                                    name='phone'
                                    maxlength="10" 
                                    type="number"    
                                    value={formik.values.phone}
                                    onChange={handleMobileChange}
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    helperText={formik.touched.phone && formik.errors.phone}/>
                            </Box>
                        </FormControl>
                       
                    </Grid>
                   
                     <Grid sx={{ mt: 3 }} item lg={6} xs={12}>
                       <Typography onClick = {() => {
                           setChangePwd(true)
                       }} sx={{color: "#2c7be5",fontSize: 14, fontWeight: 'bold', cursor: 'pointer'}}>Change Password</Typography>
                    </Grid>
                    <Grid sx={{ mt: 3, textAlign: 'right'}} item lg={6} xs={12} >
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained">
                            Update
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <PasswordChange 
                 title="Change Password"
                 open={changePwd}
                 onClose={changePassword}
                 id={companyDetails.users[0]?._id}
                />
              
        </Card>
    )
}