import React, {useEffect, useState} from "react";
    import {
        Box, Card, Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Pagination, Tooltip
    } from "@mui/material";
    import Typography from "@mui/material/Typography";
    import styled from "@emotion/styled";
    import FloatingButton from "components/FloatingButton";
    import {useHistory} from "react-router-dom";
    import {useDispatch, useSelector} from "react-redux";
    import {Delete, Edit} from "@mui/icons-material";
    import DialogConfirm from "components/DialogConfirm";
    import {toast} from "react-toastify";
    import {CitySelector,GeneralSelector} from "selectors";
    import {CityActions,GeneralActions} from "slices/actions";
    import {DefaultSort} from "constants/sort";
    import Input from "components/Input";
    import SelectField from "components/SelectField";
    import ListSkeleton from "../../components/Skeleton/ListSkeleton";
    
    const FilterBox = styled(Box)(() => ({
        width: "100%",
        marginTop: 30,
        marginBottom: 20,
        display: "flex",
        justifyContent: "space-between"
    }));
    
function City() {
  const history = useHistory();
  const dispatch = useDispatch();
  const CityListData = useSelector(CitySelector.getCityList());
  const loading = useSelector(GeneralSelector.loader(CityActions.getCityList.type));
  const success = useSelector(GeneralSelector.success(CityActions.deleteCity.type));
  const pagination = useSelector(CitySelector.getPagination());

  const [filter, setFilter] = useState({
      sort: DefaultSort.newest.value,
      page: 1
  });
  const [selected, setSelected] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);    
  useEffect(() => {
      dispatch(CityActions.getCityList(filter));
  }, [filter]);
  useEffect(() => {
      if (success) {
          setConfirmDelete(false);
          setSelected(null);

          toast.success(`${success?.message ?? "Success"}`, {
                  position: "top-right",
                  autoClose: 4000,
                  closeOnClick: true
              });

          dispatch(GeneralActions.removeSuccess(CityActions.deleteCity.type));
          dispatch(CityActions.getCityList(filter));
      }
  }, [success]);
  const handleChangeFilter = ({ target }) => {
      console.log('filter',filter)
      const {name, value} = target;

      setFilter({
          ...filter,
          [name]: value
      });
  }

  const handleChangePagination = (e, val) => {
      setFilter({
          ...filter,
          page: val
      });
  };
  const handleDelete = () => {
    dispatch(CityActions.deleteCity(selected));
  };

// window.scrollTo({
//     top: 0,
//     behavior: 'smooth',
// });
   return (
    <Card>
   
    <Typography variant="h5" sx={{ fontWeight: 600 }}>City</Typography>
    <FilterBox>
        <Grid container spacing={3} justifyContent="space-between">
            <Grid item lg={6} sm={12} xs={12}>
                <Input
                    label="Search"
                    name='keyword'
                    placeholder='Search by name'
                    value={filter.keyword ?? ''}
                    onChange={handleChangeFilter}/>
            </Grid>
            <Grid item lg={2} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Sort by</Typography>
                <SelectField
                    value={filter.sort}
                    name="sort"
                    onChange={handleChangeFilter}>
                    {Object.keys(DefaultSort).map((key) => (
                        <MenuItem key={key} value={DefaultSort[key].value}>
                            {DefaultSort[key].name}
                        </MenuItem>
                    ))}
                </SelectField> 
            </Grid>
        </Grid>
    </FilterBox>

    {loading ? (
        <ListSkeleton/>
    ) : (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell> City Name</TableCell>
                        <TableCell> State Name</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    
                      {CityListData && CityListData.map((item, i) => (
                        <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {item.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
      {item.stateName.map(itemk => (
        <span key={item._id}>{itemk.name}</span>
      ))}
    

                            </TableCell>
                            <TableCell align="right">
                                <Tooltip title="Edit">
                                    <IconButton onClick={() => history.push(`/app/city/update/${item._id}`)}>
                                        <Edit className="text-primary" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <IconButton onClick={() => {
                                        setConfirmDelete(true);
                                        setSelected(item._id);
                                    }}>
                                        <Delete className="text-default" />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}  
                </TableBody>
            </Table>

            <Pagination
                page={filter.page}
                count={pagination.pages}
                onChange={handleChangePagination}/>
        </Box>
    )}

    <DialogConfirm
        title="Delete Data"
        content="Are you sure want to delete this data?"
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onSubmit={handleDelete}/>
 
    <FloatingButton
        onClick={() => history.push("/app/city/create")}/>
    </Card>
      )
    }
  export default City