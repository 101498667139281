import React, {useEffect, useState,useRef} from "react";
import {
    Box,
    Card,
    CircularProgress,
    Grid,
    Switch,
    Typography,
    Button
} from "@mui/material";
import PageTitle from "components/PageTitle";
import Webcam from "react-webcam";
import styled from "@emotion/styled";
import Avatar from "assets/avatar.svg";
import {useDispatch, useSelector} from "react-redux";
import {GeneralSelector, LabourSelector} from "selectors";
import {useParams} from "react-router-dom";
import { GeneralActions, LabourActions, MasterDataActions } from "slices/actions";
import menus from "./constants/menus";
import LabourMenuForm from "./Component/AboutBasicInfoDetails/form/LabourMenuForm";
import FormSkeleton from "components/Skeleton/FormSkeleton";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import {toast} from "react-toastify";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import LabourExitDialog from "screens/Labour/LabourExitDialog";
import StatusChange from "components/StatusChangeDialog";
import { CloseOutlined, LinkedCameraOutlined } from "@mui/icons-material";

const Picker = styled(Box)(() => ({
    width: 120,
    height: 120,
    margin: "40px auto 10px auto",

    "& input": {
        display: "none"
    },

    "& img": {
        width: "100%",
        borderRadius: 100
    }
}));

const SwitchBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
}));

const videoConstraints = {
    width: 640,
    height: 480,
    facingMode: "user"
 }
export default function LabourDetail() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const labourDetails = useSelector(LabourSelector.getLabourById());
    const loading = useSelector(GeneralSelector.loader(LabourActions.getLabourById.type));
    const [selected, setSelected] = useState();
    // const [openDialog,setOpenDialog] = useState(false);
    const [statusCheck,setStatusCheck] = useState(false);
    const [statusValue,setStatusValue] = useState(false);
    const [openExitDialog,setOpenExitDialog] = useState(false);
    const [labourPhoto,setLabourPhoto] = useState("");
    const success = useSelector(GeneralSelector.success(LabourActions.updateLabourPhoto.type));
    const error = useSelector(GeneralSelector.error(LabourActions.updateLabourPhoto.type));
    const loadImage = useSelector(GeneralSelector.loader(LabourActions.updateLabourPhoto.type));
    const LabourName = `${labourDetails?.result?.firstName}  ${labourDetails?.result?.lastName}`;
    const [changeStatus,setChangeStatus] = useState(false);
    const statusSuccess = useSelector(GeneralSelector.success(MasterDataActions.changeStatus.type))
    const statusError = useSelector(GeneralSelector.error(MasterDataActions.changeStatus.type))
    const [isShowVideo, setIsShowVideo] = useState(false);
    const webRef = useRef(null);

    useEffect(() => {
      if (id) {
            dispatch(LabourActions.getLabourById(id));
        }
    }, [id]);

    useEffect(() => {
        const subMenuValue = localStorage.getItem('selectedLabourSubMenuId') 
        if (subMenuValue) {
            setSelected(subMenuValue)   
        } else {
            setSelected(menus[0].id)  
        }  
        },[selected])

    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(LabourActions.updateLabourPhoto.type));
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeError(LabourActions.updateLabourPhoto.type));
        }
    }, [error]);

    useEffect(() => {
        if (statusSuccess) {
            setStatusValue(statusCheck)
            toast.success(`${statusSuccess?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(MasterDataActions.changeStatus.type));
            dispatch(LabourActions.getLabourById(id));
            setChangeStatus(false)
         }
    }, [statusSuccess]);

    useEffect(() => {
        if (statusError) {
            toast.error(`${statusError?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeError(MasterDataActions.changeStatus.type));
            dispatch(LabourActions.getLabourById(id));
            setChangeStatus(false)
         }
    }, [statusError]);

    useEffect(() => {
        if (labourDetails.result) {
            setLabourPhoto(labourDetails.result.logo)
        }

        if (labourDetails.result && labourDetails.result.status === 2) {
               setStatusValue(false)
            } else {
               setStatusValue(true)
            }
            
        }, [labourDetails]);

        const handleChangeImage = (e) => {
            const file = e.target.files[0];
            const objectUrl = URL.createObjectURL(file);
            setLabourPhoto(objectUrl)
            const params = {
              id: labourDetails.result._id,
              logo: file
            }
            dispatch(LabourActions.updateLabourPhoto(params));
        };

        const clickPhoto = () => {
            const screenshotLogo = webRef.current.getScreenshot()
            setLabourPhoto(screenshotLogo);
            const params = {
                id: labourDetails.result._id,
                logobase64: screenshotLogo
              }
            dispatch(LabourActions.updateLabourPhoto(params));
            setIsShowVideo(false);
          }

        // const handleToggleStatus = () => {
        //     setStatusValue(statusCheck)
        //     const params = {
        //         id: id,
        //         projectId: labourDetails?.result?.projectId, 
        //         status: statusCheck?1:2,
        //         flag: "Labour"
        //     }
        //     dispatch(MasterDataActions.changeStatus(params))
        //     dispatch(LabourActions.getLabourById(id));
        // };
        const changeSubLabourStatus = (e) => {
            const { checked } = e.target;
            setChangeStatus(true);
            setStatusCheck(checked)
        }
         const selectItemFromSubMenu = (id) => {
         setSelected(id)
         localStorage.setItem('selectedLabourSubMenuId',id)
        } 
    
   
return (
        <Box>
            <PageTitle isBack={true} title={LabourName}/>

            {loading ? (
                 <FormSkeleton/>
            ) : (
                <Grid container spacing={3}>
                    <Grid item lg={4} sm={12} xs={12}>
                        <Card>
                        {labourDetails?.result?.status !== 99?(
                        <>
                            <Typography variant="h5" sx={{ position: 'relative' }}>
                                <LogoutOutlinedIcon className="Badgeico cursor-pointer" onClick={() => setOpenExitDialog(true)} color='primary' />
                            </Typography>
                            
                            {loadImage?(
                            <CircularProgress color="inherit" size={20}/>
                            ):(
                            <>
                             <Picker>
                                <input
                                    type="file"
                                    id="profile"
                                    onChange={handleChangeImage}
                                />
                                <label htmlFor="profile" className="cursor-pointer">
                                    <img className="uploadimgfitsize"
                                        alt="profile"
                                        src={labourPhoto || Avatar}
                                    />
                                    <span>
                                        <CameraAltOutlinedIcon sx={{fontSize: '25'}} />
                                        Upload Photo
                                    </span>
                                </label>
                            </Picker>
                            <div className="position-relative">
                                {isShowVideo &&
                                <Webcam audio={false} ref={webRef}
                                   screenshotFormat="image/jpeg" 
                                   videoConstraints={videoConstraints}
                                   style={{width: "100%", marginTop: '30px'}}
                                />
                                }
                                <div className="text-center mt-4">
                                    {!isShowVideo?(
                                    <Button onClick={() => setIsShowVideo(true)} 
                                        style={{boxShadow: "0px 8px 10px #ccc", background: "#f1f1f1", border: "1px solid #ccc;"}}
                                    >Take Photo
                                    </Button>):null}
                                    <Button onClick={() => setIsShowVideo(false)}
                                     style={{color: '#fff', boxShadow: 'none', position: 'absolute', top: "30px", right: 0}}>
                                        <CloseOutlined />
                                        </Button>
                                    <Button onClick={clickPhoto} 
                                        style={{boxShadow: 'none',color: '#fff', position: 'absolute', bottom: "76px", right: '37%'}}
                                    >
                                        {isShowVideo?<LinkedCameraOutlined />:null}
                                       
                                    </Button> 
                                </div> 
                            </div>
                            </>
                            )}
                            {labourDetails && labourDetails.role && Object.keys(labourDetails.role).map((item1,index) => (
                            <div key={index}>
                            {item1 === "Status"?(
                           <Box sx={{ mt: 7 }}>
                                <SwitchBox>
                                    {labourDetails.result && labourDetails.result.status === 1 && <Typography>Approved</Typography>}
                                    {labourDetails.result && labourDetails.result.status === 2 && <Typography>Rejected</Typography>}
                                    {labourDetails.result && labourDetails.result.status === 0 && <Typography>Pending</Typography>}

                                    <Switch
                                        checked={statusValue}
                                        name="status"
                                        onClick={changeSubLabourStatus}/>
                                </SwitchBox>
                            </Box>):null}
                            </div>
                            ))}
                            </>):(<Typography variant="h6">THIS LABOUR NO LONGER BELONGS TO THIS PROJECT</Typography>)} 
                            <Box sx={{ mt: 4 }}>
                                <LabourMenuForm
                                    selected= {selected}
                                    onSelect={(id) => selectItemFromSubMenu(id)}
                                    role={labourDetails.role}    
                                    />
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item lg={8} sm={12} xs={12}>
                        {menus.map(({id, component: Component}, i) => {
                            if (selected === id) {
                                return <Component
                                    key={i}
                                    labourDetails={labourDetails}
                                    />
                            }
                            return <div key={i}/>
                        })}
                    </Grid>
                </Grid>
            )}

         <StatusChange
            title="Change Status"
            content="Are you sure to change this status?"
            open={changeStatus}
            onClose={() => setChangeStatus(false)}
            status={labourDetails?.result?.status}
            labourId={labourDetails?.result?._id}
            projectId={labourDetails?.result?.projectId}
        />

        {/* <DialogConfirm
            title="Change Status"
            content={statusValue?"Are you sure want to reject Labour?":"Are you sure want to approve Labour"}
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            onSubmit={handleToggleStatus}/>  */}
       

        <LabourExitDialog
            open={openExitDialog}
            onClose={() => setOpenExitDialog(false)}
            doj={labourDetails?.result?.doj}
        /> 
        </Box>
    )
}