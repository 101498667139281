import {createSelector} from "@reduxjs/toolkit";

const labourSelector = (state) => state.labour;

const getLabourList = () => createSelector(
  labourSelector,
  labour => labour.labourlist
);

const getPagination = () => createSelector(
  labourSelector,
  labour => labour.pagination
);
const getLabourFormList = () => createSelector(
  labourSelector,
  labour => labour.labourFormList
);
const getLabourById = () => createSelector(
  labourSelector,
  labour => labour.labourDetails
);
const getLabourByAdharCard = () => createSelector(
  labourSelector,
  labour => labour.labourDetailsByAdharCard
);
const getLabourCompilanceList = () => createSelector(
  labourSelector,
  labour => labour.labourCompilanceList
);
const getSafetyTrainingList = () => createSelector(
  labourSelector,
  labour => labour.safetyTrainingListing
);
const getSafetyTrainingType = () => createSelector(
  labourSelector,
  labour => labour.trainingType
);
const getAllSafetyTrainingList = () => createSelector(
  labourSelector,
  labour => labour.allSafetyTrainingListing
);
const getEducationListById = () => createSelector(
  labourSelector,
  labour => labour.educationListById
);
const getEducationList = () => createSelector(
  labourSelector,
  labour => labour.educationList
);
const getAllEducationListingPagination = () => createSelector(
  labourSelector,
  labour => labour.allEducationPagination
);
const getSkillCategory = () => createSelector(
  labourSelector,
  labour => labour.skillCategoryList
);
const getSafetyTraingDetails = () => createSelector(
  labourSelector,
  labour => labour.safetyTraingDetails
);
const getLabourEducationDetails = () => createSelector(
  labourSelector,
  labour => labour.labourEducationDetails
);
const getLabourHistory = () => createSelector(
  labourSelector,
  labour => labour.labourHistory
);
const role = () => createSelector(
  labourSelector,
  labour => labour.role
);
const getLabourCmpilanceRole = () => createSelector(
  labourSelector,
  labour => labour.labourCompilanceRole
);
const getDisabilityMasterData = () => createSelector(
  labourSelector,
  labour => labour.disabilityMasterDataListig
);

const getLabourDiseaseListing = () => createSelector(
  labourSelector,
  labour => labour.labourDiseaseList
);
const getDiseaseDetails = () => createSelector(
  labourSelector,
  labour => labour.diseaseDetails
);
const documentLabourData = () => createSelector(
  labourSelector,
  labour => labour.labourDocumentUploadData
);
const getSafetyTraingPagination = () => createSelector(
  labourSelector,
  labour => labour.safetyTrainingPagination
);
const getLabourComplainList = () => createSelector(
  labourSelector,
  labour => labour.labourComplainList
);
const getComplainListPagination = () => createSelector(
  labourSelector,
  labour => labour.complainListPagination
);
const labourComplainDetails = () => createSelector(
  labourSelector,
  labour => labour.labourComplainDetails
)

const selectUANNumber = (state) => state.labour.UANNumber;

export const LabourSelector = {
  getLabourList,
  getPagination,
  getLabourFormList,
  getLabourById,
  getLabourByAdharCard,
  getLabourCompilanceList,
  getSafetyTrainingList,
  getSafetyTrainingType,
  getAllSafetyTrainingList,
  getEducationListById,
  getEducationList,
  getAllEducationListingPagination,
  getSkillCategory,
  getSafetyTraingDetails,
  getLabourEducationDetails,
  getLabourHistory,
  role,
  getLabourCmpilanceRole,
  getDisabilityMasterData,
  getLabourDiseaseListing,
  getDiseaseDetails,
  documentLabourData,
  getSafetyTraingPagination,
  getLabourComplainList,
  getComplainListPagination,
  labourComplainDetails,
  selectUANNumber
}