import GeneralSlice from "./slice/GeneralSlice";
import AuthSlice from "./slice/AuthSlice";
import UserSlice from "./slice/UserSlice";
import DepartmentSlice from "./slice/DepartmentSlice";
import AttendanceSlice from "./slice/AttendanceSlice";
import ExpensesSlice from "./slice/ExpensesSlice";
import LeaveSlice from "./slice/LeaveSlice";
import DesignationSlice from "./slice/DesignationSlice";
import SettingSlice from "./slice/SettingSlice";
import StateSlice from "./slice/StateSlice";
import CitySlice from "./slice/CitySlice";
import CompanySlice from "./slice/CompanySlice";
import ProjectSlice from "./slice/ProjectSlice";
import SiteSlice from "./slice/SiteSlice";
import TransactionSlice from "./slice/TransactionSlice";
import LabourSlice from "./slice/LabourSlice";
import MasterDataSlice from "./slice/MasterDataSlice";
import SubContractorSlice from "./slice/SubContractorSlice";
import ContractorSlice from "./slice/ContractorSlice";
import ReportSlice from "./slice/ReportSlice";
import HolidaySlice from "./slice/HolidaySlice";

export const GeneralActions = GeneralSlice.actions;
export const AuthActions = AuthSlice.actions;
export const UserActions = UserSlice.actions;
export const DepartmentActions = DepartmentSlice.actions;
export const DesignationActions = DesignationSlice.actions;
export const AttendanceActions = AttendanceSlice.actions;
export const ExpensesActions = ExpensesSlice.actions;
export const LeaveActions = LeaveSlice.actions;
export const SettingActions = SettingSlice.actions;
export const StateActions = StateSlice.actions;
export const CityActions = CitySlice.actions;
export const CompanyActions = CompanySlice.actions;
export const ProjectActions = ProjectSlice.actions;
export const SiteActions = SiteSlice.actions;
export const TransactionActions = TransactionSlice.actions;
export const LabourActions = LabourSlice.actions;
export const MasterDataActions = MasterDataSlice.actions;
export const SubContractorActions = SubContractorSlice.actions;
export const ContractorActions = ContractorSlice.actions;
export const ReportActions = ReportSlice.actions;
export const HolidayActions = HolidaySlice.actions;

