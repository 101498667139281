import {createSelector} from "@reduxjs/toolkit";

const transactionSelector = (state) => state.transaction;

const getTransactionById = () => createSelector(
transactionSelector,
transaction => transaction.transactionListById
);
const allTransactionList = () => createSelector(
    transactionSelector,
    transaction => transaction.allTransactionList
 );
 const getPage = () => createSelector(
    transactionSelector,
    transaction => transaction.pagination
 );
    

export const TransactionSelector = {
    getTransactionById,
    allTransactionList,
    getPage
}