import { createSlice } from "@reduxjs/toolkit";

export const MasterDataSlice = createSlice({
    name: "masterData",
    initialState: {
        masterData: {},
        natureOfWork: {},
        motherTounge: {},
        subcontractorData: {},
        ifscData: {},
        monthList: {},
        yearList: {},
        employementType: []
     },
    reducers: {
        getMasterData: () => {},
        getMasterDataSuccess: (state,action) => {
            state.masterData = action.payload
        },
        getSubContractorData: () => {},
        getSubContractorDataSuccess: (state,action) => {
           state.subcontractorData = action.payload
        },
        getNatureOfWork: () => {},
        getNatureOfWorkSuccess: (state,action) => {
            state.natureOfWork = action.payload
        },
        fetchDataByPincode: () => {},
        fetchDataByPincodeSuccess: (state,action) => {
            state.pinCodeData = action.payload
        },
        fetchMotherTounge: () => {},
        fetchMotherToungeSuccess: (state,action) => {
            state.motherTounge = action.payload
        },
        getIfscData: () => {},
        getIfscDataSuccess: (state, action) => {
            state.ifscData = action.payload;
        },
        getMonthList: () => {},
        getMonthListSuccess: (state,action) => {
            state.monthList = action.payload;
        },
        getYearList: () => {},
        getYearListSuccess: (state,action) => {
            state.yearList = action.payload;
        },
        changeStatus: () => {},
        getEmploymentType: () => {},
        getEmploymentTypeSuccess: (state,action) => {
            state.employementType = action.payload;
        },
      
    }
});

export default MasterDataSlice;