import React from "react";
import {
    Card,
    Grid,
    Button,
    Link
} from "@mui/material";
import Input from "components/Input";
import { SubContractorActions } from "slices/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { UserSelector } from "selectors";

export default function Gst() {
    const {id} = useParams()
    const dispatch = useDispatch();
    const subcontractorDetails = useSelector(UserSelector.getUserById());
    
    const validationSchema = yup.object({
        gst: yup.string().required("This field is required"),
        file: yup.string().required("This field is required"),
    });

    const formik = useFormik({
        initialValues: {
            type: "gst_docs",
            gst: subcontractorDetails?.gst??"",
            file: subcontractorDetails.gst_docs??""
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
          handleSubmit(values);
        },
      });
      const handleSubmit = (values) => {
        const params = {
            id: id,
            ...values
        }
         dispatch(SubContractorActions.uploadSubcontractorDocument(params));
      };

     const handleUploadImage = (e) => {
        const file = e.target.files[0];
        formik.setFieldValue("file", file);
     }
    
    return (
        <Card className="projectcard text-center position-relative">
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                <Grid item lg={12} xs={12}>
                    <Input
                        label="GST Number"
                        type="text"
                        name='gst'
                        value={formik.values.gst}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.gst) && Boolean(formik.errors.gst)}
                        helperText={formik.touched.gst && formik.errors.gst}
                        />

                </Grid> 
                <Grid item lg={12} xs={12}>
                    <Input className="uploadinput"
                        label="Upload GST Document"
                        type="file"
                        name='file'
                        onChange ={handleUploadImage}
                        error={Boolean(formik.touched.file) && Boolean(formik.errors.file)}
                        helperText={formik.touched.file && formik.errors.file}
                      />
                </Grid> 
            </Grid>
            {subcontractorDetails.gst_docs?(
                                <Link href={subcontractorDetails.gst_docs} target='_blank' >GST Document</Link>
                            ):(
                                null)
            }
            <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                     >
                    Submit
                </Button> 
            </Grid>
            </form>
        </Card>
    )
}