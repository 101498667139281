import React,{useState,useEffect} from "react";
import {
    Box,
    Typography,
    Grid
} from "@mui/material";
import Input from "components/Input";
import PropTypes from "prop-types";
import { MasterDataSelector } from "selectors";
import { MasterDataActions } from "slices/actions";
import { useSelector,useDispatch } from "react-redux";

GuardianDetails.propTypes = {
    formik: PropTypes.object
};

export default function GuardianDetails(props) {
    const { formik } = props;
    const dispatch = useDispatch();
    const locationData = useSelector(MasterDataSelector.getLocationListByPincode());
    const [pincode,setpincode]= useState();

    const handleChangePinCode= (e) => {        
        const pincodeData = e.target.value
        formik.setFieldValue('pfNomineInfoGuardianDetails_pincode',pincodeData)
        setpincode(pincodeData);
     }
    useEffect(() => {
        if (pincode && pincode.length === 6) {
            dispatch(MasterDataActions.fetchDataByPincode(pincode));
        }
    }, [pincode]); 
    
    useEffect(() => {
        if (locationData && pincode) {
        formik.setFieldValue('pfNomineInfoGuardianDetails_District', locationData.District?locationData.District :'');
        formik.setFieldValue('pfNomineInfoGuardianDetails_State', locationData.StateName?locationData.StateName :'');
        formik.setFieldValue('pfNomineInfoGuardianDetails_Address', locationData.PlaceName?locationData.PlaceName :'');
    }
     setpincode('')
    }, [locationData]);
    return (
        <Box>
            <Typography variant= "h6" sx={{my: 3, borderBottom: "1px solid #ccc"}}>Guardian Details</Typography>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Name *"
                            name='pfNomineInfoGuardianDetails_name'
                            value={formik.values.pfNomineInfoGuardianDetails_name}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfoGuardianDetails_name && Boolean(formik.errors.pfNomineInfoGuardianDetails_name)}
                            helperText={formik.touched.pfNomineInfoGuardianDetails_name && formik.errors.pfNomineInfoGuardianDetails_name}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Relation *"
                            name='pfNomineInfoGuardianDetails_Relation'
                            value={formik.values.pfNomineInfoGuardianDetails_Relation}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfoGuardianDetails_Relation && Boolean(formik.errors.pfNomineInfoGuardianDetails_Relation)}
                            helperText={formik.touched.pfNomineInfoGuardianDetails_Relation && formik.errors.pfNomineInfoGuardianDetails_Relation}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Pincode *"
                            name='pfNomineInfoGuardianDetails_pincode'
                            value={formik.values.pfNomineInfoGuardianDetails_pincode}
                            onChange={handleChangePinCode}
                            error={formik.touched.pfNomineInfoGuardianDetails_pincode && Boolean(formik.errors.pfNomineInfoGuardianDetails_pincode)}
                            helperText={formik.touched.pfNomineInfoGuardianDetails_pincode && formik.errors.pfNomineInfoGuardianDetails_pincode}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="State *"
                            name='pfNomineInfoGuardianDetails_State'
                            value={formik.values.pfNomineInfoGuardianDetails_State}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfoGuardianDetails_State && Boolean(formik.errors.pfNomineInfoGuardianDetails_State)}
                            helperText={formik.touched.pfNomineInfoGuardianDetails_State && formik.errors.pfNomineInfoGuardianDetails_State}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="District *"
                            name='pfNomineInfoGuardianDetails_District'
                            value={formik.values.pfNomineInfoGuardianDetails_District}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfoGuardianDetails_District && Boolean(formik.errors.pfNomineInfoGuardianDetails_District)}
                            helperText={formik.touched.pfNomineInfoGuardianDetails_District && formik.errors.pfNomineInfoGuardianDetails_District}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Address *"
                            name='pfNomineInfoGuardianDetails_Address'
                            value={formik.values.pfNomineInfoGuardianDetails_Address}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfoGuardianDetails_Address && Boolean(formik.errors.pfNomineInfoGuardianDetails_Address)}
                            helperText={formik.touched.pfNomineInfoGuardianDetails_Address && formik.errors.pfNomineInfoGuardianDetails_Address}/>
                    </Grid>
                </Grid>
        </Box>
    )
}