import React ,{useEffect} from 'react';
import {
    Box, Button,
    CircularProgress,
     Grid,
} from "@mui/material";
import PageTitle from "components/PageTitle";
import * as yup from "yup";
import {useFormik} from "formik";
import {useHistory} from "react-router-dom";
import BasicInformation from './Component/Create/BasicInformation';
import AccountSetting from './Component/Create/AccountSetting';
import { useDispatch ,useSelector} from 'react-redux';
import { GeneralActions, ProjectActions } from 'slices/actions';
import { GeneralSelector } from 'selectors';
import {toast} from "react-toastify";
import { Constants } from 'utils/constants';
import { CompanySelector } from 'selectors/CompanySelector';
import PaymentDuration from './Component/Create/PaymentDuration';

function AddProject () {
    const history = useHistory();
    const dispatch = useDispatch();
    const userType = localStorage.getItem('userType');
    const success = useSelector(GeneralSelector.success(ProjectActions.createProject.type));
    const error = useSelector(GeneralSelector.error(ProjectActions.createProject.type))
    const loading = useSelector(GeneralSelector.loader(ProjectActions.createProject.type));
    const companyDetails = useSelector(CompanySelector.getCompanyById());
    const companyId = userType === Constants.SUPERADMIN ?"" : localStorage.getItem('companyID')
    // const [cId,setCId] = useState();

    // useEffect(() => {
    //     if (companyDetails) {
    //         setCId(companyDetails._id)
    //     }
    // },[])

     useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(ProjectActions.createProject.type));
            history.goBack();
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                position: "top-right",
                autoClose: 3000,
                color: "white",
                closeOnClick: true,
            });

            dispatch(GeneralActions.removeError(ProjectActions.createProject.type));
            
        }
    }, [error]);

    const validationSchema = yup.object({
        projectName: yup.string().required('Project name is required'),
        gst: yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}Z[a-zA-Z0-9]{1}$/,"Invalid GST No").
        required('GST is required'),
        // state: yup.string().required("State is required"),
        // city: yup.string().required("City is required"),
        company_id: yup.string().required("Company is required"),

        location: yup.string().required("Location is required"),
        pincode: yup.string().matches(/^[0-9]+$/, "Must be only digits").
         length(6, 'Pincode must be exactly 6 characters').
         required('Pincode is required'),
        userEmail: yup.string().email().
        required("Email is required"),
        start_date: yup.string().required("Start Date is required"),
        end_date: yup.string().required("End Date is required"),
        userName: yup.string().required('Contractor name is required'),
        password: yup.string().min(8, 'Password should be of minimum 8 characters length').
        required('Password is required'),
        phone: yup.string().min(10).
        max(10).
        matches(/^[0-9\b]+$/,"Invalid Phone Number").
        required('Phone Number is required'),
        payment_collection_mode: yup.string().required("Duration is required"),
        collection_amount: yup.string().required("Duration is required"),
        projectLabourCode: yup.string().required("Project Labour Code is required"),
        state: yup.string().required('State is required'),
     });

    const formik = useFormik({
        initialValues: {
            projectName: "",
            company_id: companyDetails?._id ? companyDetails._id : companyId,
            gst: "",
            state: "",
            city: "",
            location: "",
            pincode: "",
            userEmail: "",
            start_date: "",
            payment_collection_mode: "",
            collection_amount: "",
            end_date: "",
            password: "",
            userName: "",
            phone: "",
            is_project_user: true, 
            status: false,
            clientName: "",
            clientEmail: "",
            clientAddressPincode: "",
            clientContactState: "",
            clientContactCity: "",
            clientLocation: "",
            clientContactPerson: "",
            clientContactNumber: "",
            scopeOfPfRegistration: "",
            labourLicenceValidity: "",
            labourLicenceNo: "",
            scopeOfLabourLicence: "",
            gpa_WcValidityTo: "",
            gpa_WcValidityFrom: "",
            gpa_WcNo: "",
            scopeOfGPA_WCPolicy: "",
            bocwValidUpto: "",
            scopeOfBocw: "",
            projectLabourCode: ""
            // subContractorId: "",
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = (params) => {
       dispatch(ProjectActions.createProject(params))
    }

    
    return (
        <>
           <Box>
            <PageTitle isBack={true} title='Create Project'/>

            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item lg={12} sm={12} xs={12}>
                            <BasicInformation
                            formik={formik}/>
                            <PaymentDuration
                            formik={formik}/> 
                            <AccountSetting
                            formik={formik}/>                            
                            <Grid container justifyContent='flex-end'>
                                <Button
                                size="large"
                                variant="contained"
                                color="primary"
                                type="submit"
                                >
                                {loading?<CircularProgress color="inherit" size={20}/>:<>Submit</> 
                                }
                                </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Box>
      
        
        </>
    );
}
export default AddProject;