import React from "react";
import {
    MapsHomeWork,
    AccountTree,
    CalendarToday,
    Dashboard,
    People, 
    Assignment,
    AddToQueueOutlined,
    AddchartOutlined,
    HealthAndSafety,
    SavingsOutlined,
    CalendarMonthOutlined,
    ListAlt,
    ListAltOutlined,
    BugReportOutlined,
    ExitToAppOutlined,
    ReceiptLongOutlined,
    ReceiptLong
} from "@mui/icons-material";
import AttractionsIcon from '@mui/icons-material/Attractions';
// import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import BusinessIcon from '@mui/icons-material/Business';
// import FortIcon from '@mui/icons-material/Fort';
import {features} from "../../constants/permission";
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import DynamicFormOutlinedIcon from '@mui/icons-material/DynamicFormOutlined';

const Menus = [
    { name: "Dashboard", icon: <Dashboard/>, path: "/app/dashboard", feat: features.Dashboard },
    { name: "Company", icon: <MapsHomeWork/>, path: "/app/company", feat: features.company },
    { name: "Project", icon: <BusinessIcon/>, path: "/app/project", feat: features.project },
    { name: "Subcontractor", icon: <AttractionsIcon/>, path: "/app/subcontractor", feat: features.subcontractor },
    { name: "Assign List", icon: <Assignment/>, path: "/app/assignSubcontractorAndProject", feat: features.Assign},
    { name: "Site", icon: <GolfCourseIcon/>, path: "/app/site", feat: features.Site},
    { name: "Labour", icon: <People/>, feat: features.labour,statusCode: "labour"},
    { name: "Safety Training",icon: <HealthAndSafety/>, path: "/labourSafetyTraingList", feat: features.safetyTrainingList},
    { name: "Form",icon: <ListAlt/>,feat: features.FormA,statusCode: "form"},
    { name: "Designation", icon: <AccountTree/>, path: "/app/designation", feat: features.designation },
    { name: "Attendance", icon: <CalendarToday/>,feat: features.Attendance ,statusCode: "attendance"},
    { name: "My Attendance", icon: <CalendarToday/>,feat: features.myAttendance ,statusCode: "myAttendance"},

 
    { name: "ECR Chalan", icon: <ReceiptLongOutlined/>, path: "/app/ECRchalan", feat: features.ecrChalan },

    { name: "Link UAN", icon: <DynamicFormOutlinedIcon/>, path: "/app/linkUAN", feat: features.linkUan},

    { name: "Gst Compliance", icon: <AddToQueueOutlined/>, path: "/app/gstcompliance", feat: features.GstCompliance },
    { name: "Labour Compliance", icon: <AddchartOutlined/>, path: "/labourcompliance", feat: features.LabourCompliance },
    { name: "PF Compliance", icon: <SavingsOutlined/>, path: "/app/pfcompliance", feat: features.pfCompliance },
    { name: "Holiday List", icon: <CalendarMonthOutlined/>, path: "/app/holidaylist", feat: features.holidayList },
    // { name: "Leave", icon: <AdminPanelSettingsIcon/>, path: "/app/leave", act: actions.readAll, feat: 'All' },
    { name: "Report", icon: <Assignment/>,feat: features.Report,statusCode: "report"},
    // { name: "Setting", icon: <Settings/>, path: "/app/setting", act: actions.update, feat: features.setting },
    { name: "Transaction List", icon: <ReceiptLong/>,path: "/transaction/Listing",feat: features.TransactionList},
];
const formSubMenus = [
    { name: "FormA", icon: <DynamicFormOutlinedIcon/>, path: "/app/forma", feat: features.FormA },
    { name: "FormB", icon: <DynamicFormOutlinedIcon/>, path: "/app/formb", feat: features.FormB },
    { name: "FormC", icon: <DynamicFormOutlinedIcon/>, path: "/app/formc", feat: features.FormC },
    { name: "FormD", icon: <DynamicFormOutlinedIcon/>, path: "/app/formd", feat: features.FormD }, 
]
const attendanceSubMenus = [
    { name: "Day", icon: <DynamicFormOutlinedIcon/>, path: "/app/dayAttendance", feat: features.FormA },
    { name: "Month", icon: <DynamicFormOutlinedIcon/>, path: "/app/monthAttendance", feat: features.FormA },
]
const myattendanceSubMenus = [
    { name: "Day", icon: <DynamicFormOutlinedIcon/>, path: "/app/dayMyattendance", feat: features.FormA },
    { name: "Month", icon: <DynamicFormOutlinedIcon/>, path: "/app/monthMyattendance", feat: features.FormA },
    
]
const labourSubMenus = [
    { name: "Listing", icon: <ListAltOutlined/>, path: "/app/labour", feat: features.labour},
    { name: "Exit List", icon: <ExitToAppOutlined/>, path: "/app/ExitLabourList", feat: features.LabourExit },
    { name: "Complain", icon: <BugReportOutlined/>, path: "/app/LabourComplain", feat: features.labour},
    { name: "Complain List", icon: <BugReportOutlined/>, path: "/app/LabourcomplainList", feat: features.labour},
    { name: "Validate Id Card List", icon: <BugReportOutlined/>, path: "/app/validateIdCardList", feat: features.labour}

]
const reportSubmenu = [
    { name: "Project", icon: <People/>, path: "/report/project", feat: features.Report},
    { name: "Subcontractor", icon: <DynamicFormOutlinedIcon/>, path: "/report/Subcontractor", feat: features.Report },
    { name: "Labour", icon: <DynamicFormOutlinedIcon/>, path: "/report/Labour", feat: features.Report },
    { name: "SafetyTraining", icon: <DynamicFormOutlinedIcon/>, path: "/report/SafetyTraing", feat: features.Report },
]
export default Menus;
export {formSubMenus,attendanceSubMenus,labourSubMenus,reportSubmenu,myattendanceSubMenus};

