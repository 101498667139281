import React, {useEffect, useState} from "react";
import {
    Box,
    Card,
    Grid,
    Switch,
    Typography
} from "@mui/material";
import PageTitle from "components/PageTitle";
import styled from "@emotion/styled";
import Avatar from "assets/avatar.svg";
import {useDispatch, useSelector} from "react-redux";
import {GeneralSelector, SubContractorSelector, UserSelector} from "selectors";
import {useParams} from "react-router-dom";
import { MasterDataActions, UserActions } from "slices/actions";
import menus from "./constants/menus";
import SubContractorMenuForm from "./Component/AboutSubContractorDetails/Form/SubContractorMenuForm";
import FormSkeleton from "components/Skeleton/FormSkeleton";
import DialogConfirm from "components/DialogConfirm";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

const Picker = styled(Box)(() => ({
    width: 120,
    height: 120,
    margin: "40px auto 10px auto",

    "& input": {
        display: "none"
    },

    "& img": {
        width: "100%",
        borderRadius: 100
    }
}));

const SwitchBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
}));

export default function SubContractorDetails() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const subcontractorDetails = useSelector(UserSelector.getUserById());
    const loading = useSelector(GeneralSelector.loader(UserSelector.getUserById.type));
    // const [preview, setPreview] = useState(null);
    const [selected, setSelected] = useState(menus[0].id);
    const [openDialog,setOpenDialog] = useState(false);
    const [statusCheck,setStatusCheck] = useState(false);
    const [statusValue,setStatusValue] = useState(false);
    const [logo, setLogo] = useState();
    const role = useSelector(SubContractorSelector.role());
    
   useEffect(() => {
    if (id) {
            dispatch(UserActions.getUserById(id));
        }
    }, [id]);

  useEffect(() => {
    if (subcontractorDetails) {
       console.log("subcontractorDetails.avatar",subcontractorDetails.avatar)
       setLogo(subcontractorDetails.avatar)
    }
   if (subcontractorDetails.status === 1) {
        setStatusValue(true)
        } else {
        setStatusValue(false)
        }
        
    }, [subcontractorDetails]);

    const handleChangeImage = (e) => {
        const file = e.target.files[0];
        const objectUrl = URL.createObjectURL(file);
        setLogo(objectUrl)
        // setPreview(objectUrl);
    };

    const handleToggleStatus = () => {
        setStatusValue(statusCheck)
        const params = {
            id: id,
            status: statusCheck?1:2,
            flag: "Users"
        }
        dispatch(MasterDataActions.changeStatus(params))
        setOpenDialog(false)
    };
    const changeSubContractorStatus = (e) => {
        const { checked } = e.target;
        setOpenDialog(true);
        setStatusCheck(checked)
    }
  
    return (
        <Box>
         <PageTitle isBack={true} title={subcontractorDetails.subContractorName}/> 

            {loading ? (
                 <FormSkeleton/>
            ) : (
                <Grid container spacing={3}>
                    <Grid item lg={4} sm={12} xs={12}>
                        <Card>
                            <Picker>
                                <input
                                    type="file"
                                    id="profile"
                                    onChange={handleChangeImage}
                                    />
                                <label htmlFor="profile">
                                    <img className="cursor-pointer uploadimgfitsize"
                                        alt="profile"
                                        src={logo?logo:Avatar}
                                    />
                                    <span style={{display: 'block'}}>
                                        <CameraAltOutlinedIcon sx={{fontSize: '25', mr: '2'}} />
                                        Upload Photo
                                    </span>
                                </label>
                            </Picker>
                 {role && Object.keys(role).map((item1,index) => (
                      <div key={index}>
                          {item1 === "Status"?(
                            <Box sx={{ mt: 7 }}>
                                <SwitchBox>
                                    <Typography>{statusValue?"Disable Subcontractor":"Enable SubContractor"}</Typography>
                                    <Switch
                                        checked={statusValue}
                                        name="status"
                                        onClick={changeSubContractorStatus}/>
                                </SwitchBox>
                            </Box>):null}
                            </div>
                    ))}
                            <Box sx={{ mt: 2 }}>
                                <SubContractorMenuForm
                                    selected={selected}
                                    onSelect={(id) => setSelected(id)}
                                    role = {role}
                                />
                             </Box>
                        </Card>
                    </Grid>
                    <Grid item lg={8} sm={12} xs={12}>
                        {menus.map(({id, component: Component}, i) => {
                            if (selected === id) {
                                return <Component
                                    key={i}
                                    subcontractorDetails={subcontractorDetails}
                                    form={logo}
                                    />
                            }
                            return <div key={i}/>
                        })}
                    </Grid>
                </Grid>
            )}
            <DialogConfirm
            title="Change Status"
            content={statusValue?"Are you sure want to disable subcontractor?":"Are you sure want to Enable subcontractor?"}
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            onSubmit={handleToggleStatus}/>
            </Box>
    )
}