import React ,{useState,useEffect} from "react";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
    Box, Card,Table, TableHead, TableRow,TableCell, TableBody
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import { GeneralActions, LabourActions } from "slices/actions";
import { GeneralSelector, LabourSelector } from "selectors";
import ListSkeleton from "components/Skeleton/ListSkeleton";
import FormIndex from "../FormIndex";
import { format } from 'date-fns';

const Content = styled(Card)(() => ({
  marginBottom: 20
}));

function LabourList () {
    const dispatch = useDispatch();
    const labourListData = useSelector(LabourSelector.getLabourList());
    const [showLabourList,setShowLabourList] = useState(false);
    const loading = useSelector(GeneralSelector.loader(LabourActions.getLabourList.type));
    const success = useSelector(GeneralSelector.success(LabourActions.getLabourList.type));

  useEffect(() => {
    if (success) {
       dispatch(GeneralActions.removeSuccess(LabourActions.getLabourList.type));
       setShowLabourList(true)
    }
  }, [success]);

return (
    <Box>
            <FormIndex repotType="Labour" title="Labour Report"/>
            {loading ? (
                <ListSkeleton />
            ) : (
                <Content>
                    {showLabourList && labourListData && labourListData.length !== 0 ?(
                     <>
                    <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn btn-primary mb-3"
                    table="table-to-xls"
                    filename={"Labour Report"}
                    sheet="tablexls"
                    buttonText="Export"
                     /></>):null}
                    <Table className="table" id="table-to-xls">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Project Name</TableCell>
                                <TableCell>Company Name</TableCell>
                                <TableCell>Subcontractor Name</TableCell>
                                <TableCell>AadharNo</TableCell>
                                 <TableCell>Phone</TableCell>
                                 <TableCell>DOJ</TableCell>
                                 <TableCell>Project Labour Code</TableCell>
                                 <TableCell>Skill Category</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                          {(!showLabourList || (labourListData && labourListData.length === 0)) && (
                                <TableRow>
                                    <TableCell align="center" colSpan={8}>
                                        No Data
                                    </TableCell>
                                </TableRow>
                            )}
                            {showLabourList && labourListData && labourListData.map((item, i) => (
                                <TableRow
                                    key={i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell>{`${item.firstName} ${"  "} ${item.lastName}`}</TableCell>
                                  <TableCell>{item.projectname}</TableCell>
                                  <TableCell>{item.companyName}</TableCell>
                                  <TableCell>{item.subcontractorName}</TableCell>
                                  <TableCell>{item.aadharNo}</TableCell>
                                  <TableCell>{item.mobileNumber}</TableCell>
                                  <TableCell>{item.doj && format(new Date(item.doj), 'dd/MM/yyyy')}</TableCell>
                                  <TableCell>{item.projectLabourCode}</TableCell>
                                  <TableCell>{item.skill_catagory}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Content>
           )} 
        </Box>
  )
}

export default LabourList