import React, { useEffect, useState } from "react";
import {
    Box,
    FormControl,
    Grid,
    Typography,
    useTheme,
    MenuItem,
    InputBase,
    FormHelperText
} from "@mui/material";
import Input from "components/Input";
import SelectField from "components/SelectField";
import PropTypes from "prop-types";
import { LabourSelector, MasterDataSelector, ProjectSelector, SubContractorSelector } from "selectors";
import {useSelector,useDispatch} from "react-redux";
import { CompanyActions, LabourActions, MasterDataActions, ProjectActions, SubContractorActions } from "slices/actions";
import { CompanySelector } from "selectors/CompanySelector";
import { Constants } from "utils/constants";

LabourBasicInformation.propTypes = {
    formik: PropTypes.object,
};

export default function LabourBasicInformation(props) {
    const { formik } = props;
    const theme = useTheme();
    const dispatch = useDispatch();
    const getMotherTounge = useSelector(MasterDataSelector.getMotherTounge());
    const assignedProjectToSubcontractorList = useSelector(SubContractorSelector.getAssignedSubcontractor());
    const labourDetails = useSelector(LabourSelector.getLabourByAdharCard());
    const companyList = useSelector(CompanySelector.getCompanyList());
    const projectList = useSelector(ProjectSelector.getProjectList());
    const [adharCardNo,setAdharCardNo] = useState();
    const userType = localStorage.getItem('userType');
    const comapanyId = localStorage.getItem('companyID');
    const projectId = localStorage.getItem('projectID');
    const subContractorId = localStorage.getItem('subcontractorID')
    const [todayDate,setTodayDate] = useState();
    const [selectedCompanyId,setSelectedCompanyId] = useState();
    const [assignedProject,setAssignedProject] = useState(formik.values.projectId?formik.values.projectId:"");

    useEffect(() => {
        dispatch(MasterDataActions.fetchMotherTounge());
        if (!comapanyId) {
        dispatch(CompanyActions.getCompanyList());
        } 
         if (comapanyId && !projectId) {
            const params = {
                company_id: comapanyId,
                status: 1
             }
              dispatch(ProjectActions.getProjectList(params))
        } 
        // if (comapanyId && projectId) {
        //     const params = {
        //         company_id: comapanyId,
        //         project_id: projectId,
        //      }
        //      dispatch(SubContractorActions.getAssignedSubContractor(params)) 
        // } 
        if (subContractorId) {
            const params = {
                subcontractorId: subContractorId
            }
             dispatch(SubContractorActions.getAssignedSubContractor(params));
        }

     },[])

    //  useEffect(() => {
            
    //         const params1 = {
    //             company_id: formik.values.companyId,
    //             status: 1
    //          }
    //           dispatch(ProjectActions.getProjectList(params1))
        
    //         const params2 = {
    //             company_id: formik.values.companyId,
    //             project_id: formik.values.projectId,
    //          }
    //          dispatch(SubContractorActions.getAssignedSubContractor(params2)) 
        
    //     // if (formik.values.subContractorId) {
    //     //     const params = {
    //     //         subcontractorId: formik.values.subContractorId
    //     //     }
    //     //      dispatch(SubContractorActions.getAssignedSubContractor(params));
    //     // }
    //  },[formik.values])

     const handleChangeAdharCard = (e) => {
            const adharCardData = e.target.value
            formik.setFieldValue('aadharNo',adharCardData)
            if (adharCardData.length === 12) {
                setAdharCardNo(adharCardData)
                 const params = {
                    aadharNo: adharCardData
                }
                dispatch(LabourActions.getLabourByAdharCard(params))
            }
     }
     useEffect(() => {
        if (labourDetails && labourDetails.labourData && adharCardNo) {
        formik.setFieldValue('firstName',labourDetails.labourData.firstName)
        formik.setFieldValue('lastName',labourDetails.labourData.lastName)
        formik.setFieldValue('labourCodeNo',labourDetails.labourData.labourCodeNo)
        formik.setFieldValue('aadharNo',labourDetails.labourData.aadharNo)
        formik.setFieldValue('mobileNumber',labourDetails.labourData.mobileNumber)
        formik.setFieldValue('gender',labourDetails.labourData.gender)
        formik.setFieldValue('dateOfBirth',labourDetails.labourData.dateOfBirth)
        formik.setFieldValue('maritalStatus',labourDetails.labourData.maritalStatus)
        formik.setFieldValue('motherTongue',labourDetails.labourData.motherTongue)
        formik.setFieldValue('gurdianName',labourDetails.labourData.gurdianName)
        formik.setFieldValue('employeementType',labourDetails.labourData.employeementType)
        formik.setFieldValue('skillType',labourDetails.labourData.skillType)
        formik.setFieldValue('doj',labourDetails.labourData.doj)
        formik.setFieldValue('subContractorId',labourDetails.data.subContractorId)
        // formik.setFieldValue('companyId',labourDetails.data.companyId)
        formik.setFieldValue('projectId',labourDetails.data.projectId)
        formik.setFieldValue('permanentHomeAddress',labourDetails.labourData.permanentHomeAddress)
        formik.setFieldValue('permanentAddressState',labourDetails.labourData.permanentAddressState)
        formik.setFieldValue('permanentAddressCity',labourDetails.labourData.permanentAddressCity)
        formik.setFieldValue('permanentAddressPincode',labourDetails.labourData.permanentAddressPincode)
        formik.setFieldValue('currentAddress',labourDetails.labourData.currentAddress)
        formik.setFieldValue('currentState',labourDetails.labourData.currentState)
        formik.setFieldValue('currentCity',labourDetails.labourData.currentCity)
        formik.setFieldValue('currentPincode',labourDetails.labourData.currentPincode)
        formik.setFieldValue('skill_catagory',labourDetails.labourData.skill_catagory)
        const params = {
            company_id: labourDetails.labourData.companyId,
            status: 1
         }
          dispatch(ProjectActions.getProjectList(params))

        // const params1 = {
        //     company_id: labourDetails.labourData.companyId,
        //     project_id: labourDetails.labourData.projectId,
        //  }
        //  dispatch(SubContractorActions.getAssignedSubContractor(params1)) 

     }
        },[labourDetails])

      const handleChangeCompany = (e) => {
         const cId = e.target.value;
         formik.setFieldValue('companyId',cId)
         formik.setFieldValue('projectId',"")
         formik.setFieldValue('subContractorId',"")
         setSelectedCompanyId(cId);
         const params = {
            company_id: cId,
            status: 1
         }
          dispatch(ProjectActions.getProjectList(params))
        } 
         const handleChangeProject = (e) => {
             const pId = e.target.value;
             formik.setFieldValue('projectId',pId)
            //  formik.setFieldValue('subContractorId',"")
             const params = {
                company_id: comapanyId?comapanyId:selectedCompanyId,
                project_id: pId,
             }
             dispatch(SubContractorActions.getAssignedSubContractor(params)) 
         }
         const handleChangeProjectBySubcontractor = (e) => {
           setAssignedProject(e.target.value)
           for (const i in assignedProjectToSubcontractorList.data) {
                if (assignedProjectToSubcontractorList.data[i].projectId._id === e.target.value) {
                     formik.setFieldValue('companyId',assignedProjectToSubcontractorList.data[i].companyId._id)
                     formik.setFieldValue('projectId',e.target.value)
                }
            }
        }
       useEffect(() => {
        const tempDate = new Date();
        let day = tempDate.getDate();
        if (day < 10) {
            day = `0${day}`   
         }
        let month = tempDate.getMonth() + 1;
         if (month < 10) {
            month = `0${month}`   
         }
        const year = tempDate.getFullYear()  
        const date =`${year}-${month}-${day}`
         setTodayDate(date)
       },[])  
       
   return (
        <>
            <Typography variant='h5' sx={{ mb: 4, position: 'relative', textAlign: "left", borderBottom: "1px solid #ddd", fontWeight: "normal" }}>Basic Information</Typography>
           
            <Grid container spacing={2}>

            <Grid item lg={6} xs={12}>
                       <FormControl fullWidth>
                            <Input
                                   label='Aadhaar Number *'
                                   name='aadharNo'
                                   value={formik.values.aadharNo}
                                   onChange={handleChangeAdharCard}
                                   error={formik.touched.aadharNo && Boolean(formik.errors.aadharNo)}
                                   helperText={formik.touched.aadharNo && formik.errors.aadharNo}
                                   />
                       </FormControl>
                   </Grid>
                    <Grid item lg={6} xs={12}>
                    <Typography sx={{fontSize: '15'}}>Labour Code No *
                        {formik.values.projectLabourCode?`(${formik.values.projectLabourCode})`:null}
                    </Typography>
                         <Input 
                            name='labourCodeNo'
                            value={formik.values.labourCodeNo}
                            onChange={formik.handleChange}
                            error={formik.touched.labourCodeNo && Boolean(formik.errors.labourCodeNo)}
                            helperText={formik.touched.labourCodeNo && formik.errors.labourCodeNo}
                            />
                    </Grid>
                {userType === Constants.COMPANY_USER || userType === Constants.PROJECT_USER || userType === Constants.SUB_CONTRACTOR_USER ? null : (  
                <Grid item lg={6} xs={12}>
                    <Typography sx={{fontSize: '15'}}>Company Name *</Typography>
                 <SelectField
                    name='companyId'
                    value={formik.values.companyId}
                    onChange={handleChangeCompany}
                    error={formik.touched.companyId && Boolean(formik.errors.companyId)}
                    helperText={formik.touched.companyId && formik.errors.companyId}
                    >
                  {companyList && companyList.map((item,index) => (
                   <MenuItem key={index} value={item._id}>
                            {item.name}
                   </MenuItem>
                    ))}
                </SelectField> 
                </Grid>)}
                {userType === Constants.PROJECT_USER || userType === Constants.SUB_CONTRACTOR_USER ? null : (  

                <Grid item lg={6} xs={12}>
                    <Typography sx={{fontSize: '15'}}>Project Name *</Typography>
                    <SelectField
                        name='projectId'
                        value={formik.values.projectId}
                        onChange={handleChangeProject}
                        error={formik.touched.projectId && Boolean(formik.errors.projectId)}
                        helperText={formik.touched.projectId && formik.errors.projectId}
                        >
                    {projectList && projectList.map((item,index) => (
                    <MenuItem key={index} value={item._id}>
                                {item.name}
                    </MenuItem>
                        ))}
                           {/* {(formik.values.companyId && projectList) && projectList.map((item,index) => (
                    <MenuItem key={index} value={item._id}>
                                {item.name}
                    </MenuItem>
                        ))} */}
                    </SelectField> 
                </Grid>)}
             {userType === Constants.SUB_CONTRACTOR_USER ?(
              <Grid item lg={6} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Project</Typography>
                <SelectField
                     name='assignedProject'
                     value={assignedProject}
                     onChange={handleChangeProjectBySubcontractor}
                    //  error={formik.touched.subContractorId && Boolean(formik.errors.subContractorId)}
                    //  helperText={formik.touched.subContractorId && formik.errors.subContractorId}
                    >
                    {assignedProjectToSubcontractorList && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                    <MenuItem key={index} value={item?.projectId?._id}>
                            {item?.projectId?.name}({item?.companyId?.name})
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
                   <Grid item lg={6} xs={12}>
                        <Input
                            label="First Name *"
                            name='firstName'
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            helperText={formik.touched.firstName && formik.errors.firstName}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Last Name *"
                            name='lastName'
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                            helperText={formik.touched.lastName && formik.errors.lastName}/>
                    </Grid>
                   
                    <Grid item lg={6} xs={12}>
                       <FormControl fullWidth>
                           <Typography variant='caption' sx={{fontSize: "15px"}}>Phone Number *</Typography>
                           <Box sx={{
                               display: 'flex',
                               gap: 1.5
                           }}>
                               <Box sx={{ width: 80 }}>
                                   <Input
                                       sx={{
                                           padding: "10px 8px",
                                           textAlign: 'center',
                                           '& .Mui-disabled': {
                                               fillColor: theme.palette.common.black
                                           }
                                       }}
                                       autoComplete='new-password'
                                       name='phoneCode'
                                       startAdornment='+'
                                       value="91"
                                      />
                               </Box>
                               <Input
                                   name='mobileNumber'
                                   value={formik.values.mobileNumber}
                                   onChange={formik.handleChange}
                                   error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                                   helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}/>
                           </Box>
                       </FormControl>
                    </Grid>
                {userType === Constants.SUB_CONTRACTOR_USER ? null : (  
                    <Grid item lg={6} xs={12}>
                        <Typography varient='h6' sx={{fontSize: '15px', color: '#222'}}>Sub-Contractor *</Typography>
                    <SelectField
                            className="text-left"
                            name="subContractorId"
                            value={formik.values.subContractorId}
                            onChange={formik.handleChange}
                            error={formik.touched.subContractorId && Boolean(formik.errors.subContractorId)}
                            helperText={formik.touched.subContractorId && formik.errors.subContractorId}
                            >
                           
                            {(formik.values.projectId && assignedProjectToSubcontractorList) && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                                <MenuItem value={item?.subcontractorId?._id} key={index}>
                                    {item?.subcontractorId?.subContractorName}
                                </MenuItem>
                              ))}
                        </SelectField>
                    
                    </Grid>)}
                    <Grid item lg={6} xs={12}>
                        <label style={{ display: "block" }}>Gender *</label>
                        <SelectField
                            name="gender"
                            value={formik.values.gender}
                            onChange={formik.handleChange}
                            error={formik.touched.gender && Boolean(formik.errors.gender)}
                            helperText={formik.touched.gender && formik.errors.gender}
                            style={{ display: "block", width: "100%" }} >
                            <MenuItem value={'Male'}>Male</MenuItem>
                            <MenuItem value={'Female'}>Female</MenuItem>
                            <MenuItem value={'Transgender'}>Transgender</MenuItem>
                        </SelectField>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                    <FormControl fullWidth>
                    <Typography variant='caption' sx={{ textAlign: 'left', fontSize: '15px' }}>Date Of Birth *</Typography>
                        <InputBase
                            fullWidth
                            label="Date of Birth *"
                            className="text-primary"
                            name='dateOfBirth'
                            type="date"
                            inputProps={{
                                max: todayDate 
                              }}
                            value={formik.values.dateOfBirth}
                            onChange={formik.handleChange}
                           />
                             {(formik.touched.dateOfBirth && formik.errors.dateOfBirth) && (
                                 <FormHelperText error={formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)}>{formik.touched.dateOfBirth && formik.errors.dateOfBirth}</FormHelperText>
                                )}
                         </FormControl>
                    </Grid>
                  
                    <Grid item lg={6} xs={12}>
                        <Typography varient='h6' sx={{fontSize: '15px', color: '#222'}}>Marital Status *</Typography>
                    <SelectField
                            className="text-left"
                            name="maritalStatus"
                            value={formik.values.maritalStatus}
                            onChange={formik.handleChange}
                            error={formik.touched.maritalStatus && Boolean(formik.errors.maritalStatus)}
                            helperText={formik.touched.maritalStatus && formik.errors.maritalStatus}
                            >
                            <MenuItem value="Married">Married</MenuItem>
                            <MenuItem value="Un-Married">Un-Married</MenuItem>
                            <MenuItem value="Widow/Widower">Widow/Widower</MenuItem>
                            <MenuItem value="Divorced">Divorcee</MenuItem>
                        </SelectField>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Number of Children"
                            name='numberChildren'
                            value={formik.values.numberChildren}
                            onChange={formik.handleChange}
                          />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Typography varient='h6' sx={{fontSize: '15px', color: '#222'}}>Mother Tongue *</Typography>
                        <SelectField
                                className="text-left"
                                name="motherTongue"
                                value={formik.values.motherTongue}
                                onChange={formik.handleChange}
                                error={formik.touched.motherTongue && Boolean(formik.errors.motherTongue)}
                                helperText={formik.touched.motherTongue && formik.errors.motherTongue}
                                >
                                {getMotherTounge.language && getMotherTounge.language.map((item,index) => (
                                <MenuItem key={index} value={item.id}>
                                    {item.level} 
                                </MenuItem>
                                ))}
                        </SelectField>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Father/Husbands Name *"
                            name='gurdianName'
                            value={formik.values.gurdianName}
                            onChange={formik.handleChange}
                            error={formik.touched.gurdianName && Boolean(formik.errors.gurdianName)}
                            helperText={formik.touched.gurdianName && formik.errors.gurdianName}
                            />
                    </Grid>
                
                    <Grid item lg={6} xs={12}>
                        <Input
                            fullWidth
                            label="Identification Mark"
                            type="text"
                            name="identificationMark"
                            defaultValue={formik.values.identificationMark}
                            onChange={formik.handleChange}
                        />
                    </Grid>
             </Grid> 
        </>
    )
}