import React,{useEffect} from "react";
import { Box, Button, Card, Grid, MenuItem } from "@mui/material";
import FormSkeleton from "../../components/Skeleton/FormSkeleton";
import PageTitle from "components/PageTitle";
import * as yup from "yup";
import { useFormik } from "formik";
import { CitySelector, GeneralSelector, StateSelector } from "selectors";
import { useDispatch, useSelector } from "react-redux";
import SelectField from "components/SelectField";
import { CityActions, StateActions,GeneralActions } from "slices/actions";
import Input from "components/Input";
import {useParams,useHistory} from "react-router-dom";
import {toast} from "react-toastify";

function AddCity() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const stateListData = useSelector(StateSelector.getStateList1());
  const cityData = useSelector(CitySelector.getCityById());
  const loading = useSelector(GeneralSelector.loader(StateActions.getStateList1.type));
  // const error = useSelector(GeneralSelector.error(StateActions.createState.type));
  const actions = [
    CityActions.createCity.type,
    CityActions.updateCity.type
];
const success = useSelector(GeneralSelector.success(actions));
 
  useEffect(() => {
    dispatch(StateActions.getStateList1());
  }, []);

 useEffect(() => {
      if (id) {
          dispatch(CityActions.getCityById(id));
      }
  }, []);
  useEffect(() => {
    if (cityData) {
    console.log('idCity',cityData.state_id)
    console.log('cityData',JSON.stringify(cityData))
    }
  },[])

  useEffect(() => {
    if (success.length > 0) {
        const action = success.find(item => actions.includes(item.action));

        toast.success(`${action?.message ?? "Success"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: false,
                pauseOnFocusLoss: false
            });

        dispatch(GeneralActions.removeSuccess(actions));
        history.push('/app/city')
    } 
}, [success]);

  const validationSchema = yup.object({
    state_id: yup.string().required("State name is required"),
    name: yup.string().
    required("City name is required"),
  });
  
  const formik = useFormik({
    initialValues: {
      state_id: cityData?.state_id ?? "",
      name: cityData?.name ?? "",
     },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  
    const handleSubmit = (params) => {
   if (id) {
        dispatch(CityActions.updateCity({
            ...params,
            id
        }));
    } else {
        dispatch(CityActions.createCity(params));
    }
  };
  return (
    <Box>
      <PageTitle isBack={true} title={`${id ? "Update" : "Add"} State`}/>
      
       {loading ? (
            <FormSkeleton/>
        ) : (
      <Grid container justifyContent="center">
        <Grid item lg={6} sm={12} xs={12}>
          <Card>
            <form onSubmit={formik.handleSubmit}>
              <Grid item>
                <SelectField
                  className="text-left"
                  name="state_id"
                  label="Select state"
                  value={formik.values.state_id}
                  onChange={formik.handleChange}
                 >
                  {stateListData.map((item, index) => (
                    <MenuItem key={index} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                 
                </SelectField>
              </Grid>
              
              <p>{cityData.state_id}</p>
              <Grid item>
              <Input
                name="name"
                label="City Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid container justifyContent="flex-end">
                <Button color="primary" variant="contained" type="submit">
                  Submit
                </Button>
              </Grid>
            </form>
          </Card>
        </Grid>
      </Grid>
    )} 
    </Box>
  );
}

export default AddCity;
