import React from 'react';
import FormIndex from '../FormIndex';


export default function FormA() {
  return (
    <>
    <FormIndex 
      formData={"formA"}
      title = {"Employee Register (Form A)"}
    />
</>
    )
}