import React, { useState,useEffect } from "react";
import {
    Grid, Typography, Button,MenuItem, CircularProgress
} from "@mui/material";
import PropTypes from 'prop-types';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CompanyActions, GeneralActions, LabourActions, ProjectActions, SubContractorActions } from "slices/actions";
import { toast } from "react-toastify";
import { GeneralSelector, ProjectSelector, SubContractorSelector } from "selectors";
import { CompanySelector } from "selectors/CompanySelector";
import { Constants } from "utils/constants";
import SelectField from "components/SelectField";

LabourUploadDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
//   const { inputRef } = useBarcode({
//     value: '12345 6789',
//     options: {
//       background: '#ccffff',
//     }
//   });

export default function LabourUploadDialog(props) {
    const { open, onClose } = props
    const history = useHistory();
    const dispatch = useDispatch();
    const userType = localStorage.getItem('userType');
    const companyId = localStorage.getItem('companyID');
    const projectId = localStorage.getItem('projectID');
    const subContractorId = localStorage.getItem('subcontractorID')
    const companyList = useSelector(CompanySelector.getCompanyList());
    const projectList = useSelector(ProjectSelector.getProjectList());
    const assignedProjectToSubcontractorList = useSelector(SubContractorSelector.getAssignedSubcontractor());
    const success = useSelector(GeneralSelector.success(LabourActions.uploadLabourDocument.type));
    const loading = useSelector(GeneralSelector.loader(LabourActions.uploadLabourDocument.type));
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [assignedProject,setAssignedProject] = useState("");
    const [labourDocumentForm,setLabourDocumentForm] = useState({
        companyId: companyId?companyId:"",
        projectId: projectId?projectId:"",
        subContractorId: subContractorId?subContractorId:"",
        document: ""
      })
    const [errObj,setErrObj] = useState({});  
    useEffect(() => {
        if (!companyId) {
           dispatch(CompanyActions.getCompanyList());
        } else if (companyId && !projectId) {
            const params = {
                company_id: companyId
            }
            
            dispatch(ProjectActions.getProjectList(params))
        } else if (companyId && projectId) {
            const params = {
                company_id: companyId,
                project_id: projectId
            }
            dispatch(SubContractorActions.getAssignedSubContractor(params))
        } else if (subContractorId) {
            const params = {
                subcontractorId: subContractorId
            }
            dispatch(SubContractorActions.getAssignedSubContractor(params))
        }
      
    },[])
    useEffect(() => {
        if (success) {
            onClose();
           toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true
                });
      
            dispatch(GeneralActions.removeSuccess(LabourActions.uploadLabourDocument.type));
            history.push("/app/labour/DocumentList")
          }
      }, [success]);

     useEffect(() => {
        if (labourDocumentForm.companyId) {
            const params = {
                company_id: labourDocumentForm.companyId ? labourDocumentForm.companyId: companyId
            }
            
            dispatch(ProjectActions.getProjectList(params))
        }
    },[labourDocumentForm.companyId])
  
    useEffect(() => {
      if (labourDocumentForm.projectId) {
          const params = {
              company_id: labourDocumentForm.companyId ? labourDocumentForm.companyId: companyId,
              project_id: labourDocumentForm.projectId ? labourDocumentForm.projectId: projectId,
          }
          
          dispatch(SubContractorActions.getAssignedSubContractor(params))
      }
  },[labourDocumentForm.projectId])
  
    const handleChangeCompany = (e) => {
        delete errObj.companyId
        setErrObj({...errObj})
        labourDocumentForm.projectId = ""
        labourDocumentForm.subContractorId = ""
        labourDocumentForm.companyId = e.target.value;
        setLabourDocumentForm({...labourDocumentForm})
     }   
      const handleChangeProject = (e) => {
        delete errObj.projectId
        setErrObj({...errObj}) 
        labourDocumentForm.subContractorId = ""
        labourDocumentForm.projectId = e.target.value;
        setLabourDocumentForm({...labourDocumentForm})
        
      }
     const handleChangeSubcontractor = (e) => {
        delete errObj.subContractorId
        setErrObj({...errObj})  
        labourDocumentForm.subContractorId = e.target.value;
        setLabourDocumentForm({...labourDocumentForm})
    }
     const handleChangeAttendanceDocument = (e) => {
        delete errObj.document
        setErrObj({...errObj})  
        setIsFilePicked(true);
        const file1 = e.target.files[0];
        labourDocumentForm.document = file1;
        setLabourDocumentForm({...labourDocumentForm})
     }
    
    const onSubmit = () => {
        if (validateForm()) {
            dispatch(LabourActions.uploadLabourDocument(labourDocumentForm))
        }
       
    }
    const handleChangeProjectBySubcontractor = (e) => {
        setAssignedProject(e.target.value)
        for (const i in assignedProjectToSubcontractorList.data) {
            if (assignedProjectToSubcontractorList.data[i].projectId._id === e.target.value) {
                labourDocumentForm.companyId = assignedProjectToSubcontractorList.data[i].companyId._id
                labourDocumentForm.projectId = e.target.value
               setLabourDocumentForm({...labourDocumentForm})
               const params = {
                    company_id: companyId,
                    project_id: projectId
               }
               dispatch(LabourActions.getLabourCompilanceList(params));
             }
        }
    }
    const validateForm = () => {
        let valid = true
            if (!labourDocumentForm.companyId) {
               errObj.companyId = 'This field is Required';
               valid = false;
            }
            if (!labourDocumentForm.projectId) {
                errObj.projectId = 'This field is Required';
                valid = false;
            }
            if (!labourDocumentForm.subContractorId) {
                errObj.subContractorId = 'This field is Required';
                valid = false;
            }
            if (!labourDocumentForm.document) {
                errObj.document = 'This field is Required';
                valid = false;
            }
        setErrObj({...errObj});
        return valid; 
      }
       const closeDialouge = () => {
        onClose();
        labourDocumentForm.companyId = ""
        labourDocumentForm.projectId = ""
        labourDocumentForm.subContractorId = ""
        labourDocumentForm.document = ""
        setIsFilePicked(false)
        setLabourDocumentForm({...labourDocumentForm})
        }
    return (
        <div className="IdcardDialog">
            <BootstrapDialog
                onClose={closeDialouge}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={closeDialouge} sx={{ borderBottom: '1px solid #ccc' }}>
                   Bulk Upload Labour Data
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ pt: 4 }}>
                        {userType === Constants.SUPERADMIN?(
                        <Grid item lg={6} sm={12} xs={12}>
                            <Typography variant='h6' sx={{fontSize: 15}}>Company</Typography>
                            <SelectField
                                value={labourDocumentForm.companyId}
                                onChange={handleChangeCompany}
                                >
                                {companyList && companyList.map((item,index) => (
                                    <MenuItem value={item._id} key={index}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </SelectField>
                            {errObj.companyId && <Typography sx={{color: 'red',fontSize: 12}}>{errObj.companyId}</Typography>}

                        </Grid>
                        ):null} 
                        {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER?(
                        <Grid item lg={6} sm={12} xs={12}>
                            <Typography variant='h6' sx={{fontSize: 15}}>Project</Typography>
                            <SelectField
                                value={labourDocumentForm.projectId}
                                onChange={handleChangeProject}
                            >
                            {(labourDocumentForm.companyId && projectList) && projectList.map((item,index) => (
                                    <MenuItem value={item._id} key={index}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </SelectField>
                            {errObj.projectId && <Typography sx={{color: 'red',fontSize: 12}}>{errObj.projectId}</Typography>}
                        </Grid>
                        ):null} 
                        {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER || userType === Constants.PROJECT_USER?(
                        <Grid item lg={6} sm={12} xs={12}>
                            <Typography variant='h6' sx={{fontSize: 15}}>SubContractor</Typography>
                            <SelectField
                                value={labourDocumentForm.subContractorId}
                                onChange={handleChangeSubcontractor}
                            >
                             {(labourDocumentForm.projectId && assignedProjectToSubcontractorList.data) && assignedProjectToSubcontractorList.data.map((item,index) => (
                                  <MenuItem key={index} value={item?.subcontractorId?._id}>
                                   {item?.subcontractorId?.subContractorName}
                                 </MenuItem>
                               ))}
                            </SelectField>
                            {errObj.subContractorId && <Typography sx={{color: 'red',fontSize: 12}}>{errObj.subContractorId}</Typography>}
                        </Grid>
                        ):null} 

                   {userType === Constants.SUB_CONTRACTOR_USER ?(
                        <Grid item lg={12} sm={12} xs={12}>
                        <Typography sx={{fontSize: 15}}>Project</Typography>
                        <SelectField
                            value={assignedProject}
                            onChange={handleChangeProjectBySubcontractor}
                            >
                            {assignedProjectToSubcontractorList && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                            <MenuItem key={index} value={item?.projectId?._id}>
                                    {item?.projectId?.name}({item?.companyId?.name})
                            </MenuItem>
                            ))}
                        </SelectField> 
                        {errObj.projectId && <Typography sx={{color: 'red',fontSize: 12}}>{errObj.projectId}</Typography>}

                        </Grid>
                    ):null
                    }
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12} sx={{mt: 3}}>
                        <label htmlFor="profile2" className="cursor-pointer w-100">
                            <input style={{ display: 'none' }}
                                type="file"
                                id="profile2"
                                accept=".xlsx, .xls"
                                onChange={handleChangeAttendanceDocument}
                            />
                            <Typography className="uploadimglabel">
                                <AddIcon />
                                <span style={{ display: 'block' }}>Upload Document</span>
                            </Typography>
                        </label>
                        {errObj.document && <Typography sx={{color: 'red',fontSize: 12}}>{errObj.document}</Typography>}
                    </Grid>
                    <Grid item lg={12} sm={12} xs={12}>
                        {isFilePicked ? (
                            <div>
                                <Typography sx={{m: 0, fontSize: '15px'}}>Filename: {labourDocumentForm.document.name}</Typography>
                                
                                <Typography sx={{m: 0, fontSize: '15px'}}>Size in bytes: {labourDocumentForm.document.size}</Typography>
                               
                            </div>
                        ) : (
                            <Typography className="text-center" sx={{m: 0, fontSize: '15px'}}>Upload labour data file</Typography>
                        )}
                        <Grid item lg={12} sm={12} xs={12} sx={{ py: 2, textAlign: 'center' }}>
                            <Link to="/labour_document_YYYY_MM_DD .xlsx" target="_blank">Download Sample Format</Link>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={closeDialouge} variant="contained" color="primary">
                        Close
                    </Button>
                    <Button autoFocus onClick={onSubmit} variant="contained" color="primary">
                       {loading?<CircularProgress color="inherit" size={20}/>:<>Submit</>}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )

}