import React, { useEffect,useState } from "react";
import {
 Card, Table, TableCell, TableHead, TableBody, TableRow, Box, Typography,Chip, Link 
} from "@mui/material";
import { useParams } from 'react-router-dom';
 import DialogConfirm from "components/DialogConfirm";
import { GeneralActions, TransactionActions } from "slices/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GeneralSelector, TransactionSelector } from "selectors";
import { format } from 'date-fns';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';


function ProjectTransactionListing() {
 const { id } = useParams();
 const dispatch = useDispatch();
 const [selected, setSelected] = useState(null);
 const [confirmStatusChng, setConfirmStatusChng] = useState(false);
 const [status,setStatus] = useState();
 const success = useSelector(GeneralSelector.success(TransactionActions.paymentStatus.type));
 const transactionList = useSelector(TransactionSelector.getTransactionById());

  const handleStatusChange = () => {
    if (status === 0) {
      const params = {
        transaction_id: selected,
        status: 2
      }
     dispatch(TransactionActions.paymentStatus(params));
    } else if (status === 2) {
      const params = {
        transaction_id: selected,
        status: 1
      }
     dispatch(TransactionActions.paymentStatus(params));
    }
  }

  useEffect(() => {
  dispatch(TransactionActions.getTransactionById(id))
  },[])
useEffect(() => {
  if (success) {
      setConfirmStatusChng(false);
      setSelected(null);

      toast.success(`${success?.message ?? "Success"}`, {
              position: "top-right",
              autoClose: 3000,
              closeOnClick: true
          });

      dispatch(GeneralActions.removeSuccess(TransactionActions.paymentStatus.type));
      dispatch(TransactionActions.getTransactionById(id))
    }
  }, [success]);

  return (
    <Card sx={{ position: "relative" }}>
      <Typography variant="h5" sx={{ mb: 4 }}>
       Transaction Listing
      </Typography>
     <Box>
     {transactionList.data && transactionList.data.results.length>0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Billing Date</TableCell>
              {/* <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell> */}
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
          </TableRow>
          </TableHead>
          <TableBody>
            {transactionList.data.results.map((item, index) => (
              <TableRow key={index}>
                <TableCell align="left">
                  {/* {format(new Date(item.bill_date), 'dd/MM/yyyy')} */}
                  {item.bill_date ? format(new Date(item.bill_date), 'dd/MM/yyyy') : "N/A"}

                </TableCell>
                {/* <TableCell align="left">
                  {format(new Date(item.duration_start_date), 'dd/MM/yyyy')}
                </TableCell>
                <TableCell align="left">
                  {format(new Date(item.duration_end_date), 'dd/MM/yyyy')}
                </TableCell> */}
                <TableCell align="left">{item.amount}</TableCell>
                <TableCell align="left">
               {item.status === 0 &&
                <Chip
                    label="Pending"
                    color="default"
                    size='small'
                    onClick={() => {
                      setStatus(item.status)
                      setConfirmStatusChng(true);
                      setSelected(item._id);
                    }}
                  />}
                  {item.status === 2 &&
                   <Chip
                    label="Invoice Generated"
                    color="default"
                    size='small'
                    onClick={() => {
                      setStatus(item.status)
                      setConfirmStatusChng(true);
                      setSelected(item._id);
                     }}
                  />}
                  {item.status === 1 &&
                   <Chip
                    label="Paid"
                    color="primary"
                    size='small'
                    />}
                  </TableCell>
                 <TableCell>
                  {item?.invoice? (
                    <Link href={transactionList?.data?.invoiceURL+item?.invoice} target='_blank' className="cursor-pointer" >
                    <ArrowDownwardIcon />            
                  </Link>):null}
                </TableCell>
               
              </TableRow>
            ))}
          </TableBody>
        </Table>
        ):(
          <Typography>No Transaction List</Typography>
        )}
      </Box>
      <DialogConfirm
          title={status===0?"Generate Invoice":"Proceed To Payment"}
          content="Are you sure?"
          status={status}
          open={confirmStatusChng}
          onClose={() => setConfirmStatusChng(false)}
          onSubmit={handleStatusChange}
        />
    </Card>
  )
}

export default ProjectTransactionListing