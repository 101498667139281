import { createSlice } from "@reduxjs/toolkit"

export const SiteSlice = createSlice({
    name: "site",
    initialState: {
       siteListByProject: [],
       siteDetailsById: {},
       allSiteList: [],
       role: {}
    },
    reducers: {
        createSite: () => {},
        deleteSite: () => {},
        getSiteById: () => {},
        updateSite: () => {},
        getSiteByIdSucess: (state,action) => {
            state.siteListByProject = action.payload
        },
        getSiteDetailsById: () => {},
        getSiteDetailsSuccessByID: (state,action) => {
            state.siteDetailsById = action.payload
        },
        getAllSiteList: () => {},
        getAllSiteListSuccess: (state,action) => {
            state.allSiteList = action.payload
            state.role = action.payload.role
        }
    }
});

export default SiteSlice;