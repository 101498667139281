import React ,{useRef} from "react";
import {
    Card, Typography,IconButton
} from "@mui/material";
import IdCardBack from "./IdCardBack";
import IdCardFront from "./IdCardFront";
import {LocalPrintshopOutlined} from '@mui/icons-material';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
import {useSelector} from "react-redux";
import {LabourSelector} from "selectors";
import ReactToPrint from 'react-to-print';

// function printIdCard () {
//     html2canvas(document.querySelector("#capture")).then(canvas => {
//         document.body.appendChild(canvas);
//         const imgData = canvas.toDataURL('image/png');
//         const pdf = new jsPDF();
//         pdf.addImage(imgData, 'PNG', 0, 0);
//         pdf.save("Idcard.pdf"); 
//     });
// }
// const getBase64Image = (url) => {
//     const img = new Image();
//     img.setAttribute('crossOrigin', 'anonymous');
//     img.onload = () => {
//       const canvas = document.createElement("canvas");
//       canvas.width = img.width;
//       canvas.height = img.height;
//       const ctx = canvas.getContext("2d");
//       ctx.drawImage(img, 0, 0);
//       const dataURL = canvas.toDataURL("image/png");
//       console.log(dataURL)
//     }
//     img.src = url
// }

export default function IdCard() {
    const labourDetails = useSelector(LabourSelector.getLabourById());
    let componentRef=useRef(null);
    const pageStyle = `{ size: 297 mm 420 mm }`;

    // window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth',
    // });
    return (
             
        <Card>
            <Typography variant='h5' sx={{ mb: 4, fontWeight: '500', position: 'relative' }}>ID Card
                {/* <LocalPrintshopOutlined className="printicon" onClick={printIdCard} /> */}
                {/* <FileDownloadOutlined className="downloadicon" /> */}
                <ReactToPrint
                pageStyle={pageStyle}
                trigger = {() => (
                <IconButton sx={{float: 'right'}}>
                <LocalPrintshopOutlined color='primary' sx={{cursor: 'pointer', float: 'right'}} />
                </IconButton>
                )}
                content={() => componentRef}
                />
            </Typography>
            <div id="capture" ref={el => (componentRef = el)}>
                <div id="divToPrint" className="mt4">
                    <IdCardFront labourDetails = {labourDetails}/>
                    <IdCardBack labourDetails = {labourDetails}/>
                </div>
            </div>
            
        </Card>
    )
}