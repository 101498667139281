import {all, call, put, takeLatest} from 'redux-saga/effects'
import {SiteService} from "../services";
import {SiteActions, GeneralActions} from "../slices/actions";

function *createSite({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(SiteService.CreateSite, payload);
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}
function *deleteSite({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(SiteService.DeleteSite,payload);

        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}
function *getSiteById({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(SiteService.GetSiteById, payload);
        yield put(SiteActions.getSiteByIdSucess(result.data));
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *getSiteDetailsById({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(SiteService.GetSiteDetailsById, payload);
        yield put(SiteActions.getSiteDetailsSuccessByID(result.data));
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}
function *updateSite({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(SiteService.UpdateSite, payload.id, payload);
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *getAllSiteList({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const cId = payload.company?payload.company:"";
        const pId = payload.project?payload.project:""
        const result = yield call(SiteService.GetAllSiteList,pId,cId);
        yield put(SiteActions.getAllSiteListSuccess(result.data));
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}
export function *SiteWatcher() {
    yield all([
        yield takeLatest(SiteActions.createSite.type, createSite),
        yield takeLatest(SiteActions.deleteSite.type, deleteSite),
        yield takeLatest(SiteActions.getSiteById.type, getSiteById),
        yield takeLatest(SiteActions.getSiteDetailsById.type, getSiteDetailsById),
        yield takeLatest(SiteActions.updateSite.type, updateSite),
        yield takeLatest(SiteActions.getAllSiteList.type, getAllSiteList)
     ]);
}