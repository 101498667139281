import React, { useEffect, useState } from "react";
import {
    Card,
    FormControl,
    FormHelperText,
    Grid,
    InputBase,
    MenuItem,
    Typography,
} from "@mui/material";
import Input from "components/Input";
import SelectField from "components/SelectField";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { CompanyActions, MasterDataActions, StateActions } from "slices/actions";
import { MasterDataSelector, StateSelector } from "selectors";
import { CompanySelector } from "selectors/CompanySelector";
import { Constants } from "utils/constants";
// import axios from "axios";

BasicInformation.propTypes = {
    formik: PropTypes.object
};

export default function BasicInformation(props) {
    const { formik } = props;
    const dispatch = useDispatch();
    const locationData = useSelector(MasterDataSelector.getLocationListByPincode());
    const companyList = useSelector(CompanySelector.getCompanyList());
    const allStateList = useSelector(StateSelector.GetAllState());
    const userType = localStorage.getItem('userType')
    const [pincode, setPincode] = useState();
    const [startDate, setStartDate] = useState();
    // const [state,setState]= useState();
    // const [allstate,setAllstate]=useState([])
    const handleChangePinCode = (e) => {
        const pincodeData = e.target.value
        formik.setFieldValue('pincode', pincodeData)
        setPincode(pincodeData);
    }
    const handleChangeState = (e) => {
        const state = e.target.value
        formik.setFieldValue('state',state)
        console.log("state", state)
    }
    useEffect(() => {
        if (pincode && pincode.length === 6) {
            dispatch(MasterDataActions.fetchDataByPincode(pincode));
        }
    }, [pincode]);

    useEffect(() => {
        dispatch(CompanyActions.getCompanyList())
        dispatch(StateActions.GetAllState())
        // const data = dispatch(StateActions.GetAllState())
        // const data2 = dispatch(CompanyActions.getCompanyList())
        // console.log("GetAllState", data)
        // console.log("getCompanyList", data2)
        // console.log("allStateList", allStateList)
        // axios.get("http://103.112.26.98:5000/api/state/allState").then((data) => {
        //     console.log("allstate", data)
        // })


        // const token = localStorage.getItem("merakihr-token");

        // const API_URL = process.env.REACT_APP_API_URL
        // const url = `${API_URL}/state/allState`

        // axios.get(url, {
        //     headers: { Authorization: `Bearer ${token}` }
        // }).then((data) => {
        //     console.log("GetAllState", data.data)
        //     setAllstate(data.data)
        // })

        // if (allStateList) {
        //     console.log("GetAllState", allStateList)  
        // }
    }, []);

    useEffect(() => {
        if (locationData && pincode) {
            // formik.setFieldValue('state', locationData.StateName ? locationData.StateName : '');
            formik.setFieldValue('city', locationData.District ? locationData.District : '');
            formik.setFieldValue('location', locationData.District ? locationData.PlaceName : '');
        }
    }, [locationData]);

    const handleChangeStartDate = (e) => {
        const date = e.target.value
        formik.setFieldValue('start_date', date);
        setStartDate(date)
    }

    return (
        <Card className="position-relative" sx={{ mb: 3 }}>
            <Typography variant='h5' sx={{ mb: 4 }}>Basic Information</Typography>

            <Grid container spacing={2}>

                <Grid item lg={6} xs={12}>
                    <Input
                        label="Project Name"
                        name='projectName'
                        value={formik.values.projectName}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.projectName) && Boolean(formik.errors.projectName)}
                        helperText={formik.touched.projectName && formik.errors.projectName} />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Project Labour Code"
                        name='projectLabourCode'
                        value={formik.values.projectLabourCode}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.projectLabourCode) && Boolean(formik.errors.projectLabourCode)}
                        helperText={formik.touched.projectLabourCode && formik.errors.projectLabourCode} />
                </Grid>

                <Grid item lg={6} xs={12}>
                    <Input
                        label="GST"
                        name='gst'
                        value={formik.values.gst}
                        onChange={formik.handleChange}
                        error={formik.touched.gst && Boolean(formik.errors.gst)}
                        helperText={formik.touched.gst && formik.errors.gst} />
                </Grid>

                <Grid item lg={6} xs={12}>
                    <Input
                        label="Start Date"
                        name='start_date'
                        type='date'
                        value={formik.values.start_date}
                        onChange={handleChangeStartDate}
                        error={Boolean(formik.touched.start_date) && Boolean(formik.errors.start_date)}
                        helperText={formik.touched.start_date && formik.errors.start_date}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <FormControl fullWidth>
                        <Typography variant='caption' sx={{ textAlign: 'left', fontSize: '15px' }}>End Date *</Typography>
                        <InputBase
                            fullWidth
                            className="text-primary"
                            name='end_date'
                            type="date"
                            inputProps={{
                                min: startDate
                            }}
                            value={formik.values.end_date}
                            onChange={formik.handleChange}
                        />
                        {(formik.touched.end_date && formik.errors.end_date) && (
                            <FormHelperText error={formik.touched.end_date && Boolean(formik.errors.end_date)}>{formik.touched.end_date && formik.errors.end_date}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                {userType === Constants.SUPERADMIN ? (
                    <Grid item lg={6} xs={12}>
                        <Typography variant='h6' sx={{ fontSize: 15 }}>Company</Typography>
                        <SelectField
                            value={formik.values.company_id}
                            name='company_id'
                            onChange={formik.handleChange}
                            error={formik.touched.company_id && Boolean(formik.errors.company_id)}
                            helperText={formik.touched.company_id && formik.errors.company_id}
                        >
                            {companyList && companyList.map((item, index) => (
                                <MenuItem value={item._id} key={index}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </SelectField>
                    </Grid>) : null}
                <Grid item lg={6} xs={12}>
                    <Input
                        label='Pincode'
                        name='pincode'
                        type='number'
                        value={formik.values.pincode}
                        onChange={(e) => handleChangePinCode(e)}
                        error={formik.touched.pincode && Boolean(formik.errors.pincode)}
                        helperText={formik.touched.pincode && formik.errors.pincode}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    {/* <Input
                        label="State"
                        name='state'
                        value={formik.values.state}
                        error={formik.touched.state && Boolean(formik.errors.state)}
                        helperText={formik.touched.state && formik.errors.state}
                        readonly
                        /> */}
                    <Typography variant='h6' sx={{ fontSize: 15 }}>State</Typography>
                    <SelectField
                        value={formik.values.state}
                        onChange={(e) => handleChangeState(e)}
                        error={formik.touched.state && Boolean(formik.errors.state)}
                        helperText={formik.touched.state && formik.errors.state}
                    >
                        {/* {allstate && allstate.map((item, index) => (
                            <MenuItem value={item.code} key={index}>
                                {item.name}
                            </MenuItem>
                        ))} */}

                        {allStateList && allStateList.map((item, index) => (
                            <MenuItem value={item.name} key={index}>
                                {item.name}
                            </MenuItem>
                        ))}

                    </SelectField>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="City"
                        name='city'
                        value={formik.values.city}
                        error={formik.touched.city && Boolean(formik.errors.city)}
                        helperText={formik.touched.city && formik.errors.city}
                        readonly
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input style={{ Height: "80px" }}
                        label="Address"
                        name='location'
                        fullHeight
                        value={formik.values.location}
                        onChange={formik.handleChange}
                        error={formik.touched.location && Boolean(formik.errors.location)}
                        helperText={formik.touched.location && formik.errors.location} />
                </Grid>


            </Grid>
        </Card>
    )
}