import React from 'react';
import FormIndex from '../FormIndex';

export default function FormD() {
    return (
        <>
            <FormIndex 
              formData={"formD"}
              title = {"View Attendance (Form D)"}
            />
        </>
    )
}