import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  // Switch,
  // Typography
} from "@mui/material";
import Webcam from "react-webcam";
import PageTitle from "components/PageTitle";
import styled from "@emotion/styled";
import Avatar from "assets/avatar.svg";
import * as yup from "yup";
import { useFormik } from "formik";
// import {useParams} from "react-router-dom";
// import menus from "./constants/menus";
import { useHistory } from "react-router-dom";
import BasicInformation from "./Component/BasicInfoCreateAndDetailForm/LabourBasicInformation";
import { GeneralActions, LabourActions } from "slices/actions";
import { useDispatch, useSelector } from "react-redux";
import { GeneralSelector } from "selectors";
import { toast } from "react-toastify";
import PermanateAddress from "./Component/BasicInfoCreateAndDetailForm/LabourAddress";
import SkillInformation from "./Component/BasicInfoCreateAndDetailForm/SkillInformation";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import DialogConfirm from "components/DialogConfirm";
import { sub } from "date-fns/fp"
import { CloseOutlined, LinkedCameraOutlined } from "@mui/icons-material";
// import { Constants } from "utils/constants";


const Picker = styled(Box)(() => ({
  width: 120,
  height: 120,
  margin: "40px auto 10px auto",

  "& input": {
    display: "none",
  },

  "& img": {
    width: "100%",
    borderRadius: 100,
  },
}));

// const SwitchBox = styled(Box)(() => ({
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between"
// }));

function CreateLabour() {
  // const { id } = useParams();
  const [isShowVideo, setIsShowVideo] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [preview, setPreview] = useState(null);
  const success = useSelector(GeneralSelector.success(LabourActions.createLabour.type));
  const error = useSelector(GeneralSelector.error(LabourActions.createLabour.type));
  const loading = useSelector(GeneralSelector.loader(LabourActions.createLabour.type));
  // const userType = localStorage.getItem('userType');

  // const subContractorId = localStorage.getItem('subcontractorID')
  // const comapanyId = localStorage.getItem('companyID');
  // const projectId = localStorage.getItem('projectID');
  // const userType = localStorage.getItem('userType')
  // const labourDetails = useSelector(LabourSelector.getLabourByAdharCard());
  const [confirmDialog, setConfirmDialog] = useState(false);


  const videoConstraints = {
    width: 640,
    height: 480,
    facingMode: "user"
  }
  const webRef = useRef(null);
  // const userType = localStorage.getItem('userType');


  const validationSchema = yup.object({
    firstName: yup.string().required("This field is required"),
    lastName: yup.string().required("This field is required"),
    labourCodeNo: yup.string().required("This field is required"),
    aadharNo: yup.string().min(12).
      max(12).
      required("This field is required"),
    mobileNumber: yup.string().min(10).
      max(10).
      matches(/^[0-9\b]+$/, "Invalid Phone Number").
      required('This field is required'),
    gender: yup.string().required("This field is required"),
    dateOfBirth: yup.date().required("This field is required").
      max(sub({ years: 18 }, new Date()), "Labour must be over 18 years old"),
    maritalStatus: yup.string().required("This field is required"),
    motherTongue: yup.string().required("This field is required"),
    gurdianName: yup.string().required("This field is required"),
    permanentHomeAddress: yup.string().required("This field is required"),
    permanentAddressState: yup.string().required("This field is required"),
    permanentAddressCity: yup.string().required("This field is required"),
    permanentAddressPincode: yup.string().matches(/^[0-9]+$/, "Must be only digits").
      length(6, 'Pincode must be exactly 6 characters!').
      required('Pincode is required'),
    currentAddress: yup.string().required("This field is required"),
    currentState: yup.string().required("This field is required"),
    currentCity: yup.string().required("This field is required"),
    currentPincode: yup.string().matches(/^[0-9]+$/, "Must be only digits").
      length(6, 'Pincode must be exactly 6 characters!').
      required('Pincode is required'),
    subContractorId: yup.string().required("This field is required"),
    employeementType: yup.string().required("This field is required"),
    skillType: yup.string().required("This field is required"),
    doj: yup.string().required("This field is required"),
    // projectId: yup.string().when(userType, {
    //   is: (value) => value === Constants.COMPANY_USER || value === Constants.PROJECT_USER || value === Constants.SUB_CONTRACTOR_USER,
    //   then: yup.string().required("This field is required"),
    //   otherwise: yup.string()
    // }),

    projectId: yup.string().required("This field is required"),


    // companyId: yup.string().when(userType, {
    //   is: (value) => value === Constants.COMPANY_USER || userType === Constants.PROJECT_USER || userType === Constants.SUB_CONTRACTOR_USER,
    //   then: yup.string().required("This field is required"),
    //   otherwise: yup.string()
    // }),
    // companyId: yup.string().required("This field is required"),
    skill_catagory: yup.string().required("This field is required"),
  });

  // useEffect(() => {
  //   if (confirmDialog) {
  //     dispatch(LabourActions.createLabour(params));
  //   }
  // }, [])

  useEffect(() => {
    if (success) {
      setConfirmDialog(false)
      toast.success(`${success?.message ?? "Success"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });

      dispatch(GeneralActions.removeSuccess(LabourActions.createLabour.type));
      history.goBack();
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      toast.error(`${error?.message ?? "Error"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });

      dispatch(GeneralActions.removeError(LabourActions.createLabour.type));
    }
  }, [error]);

  const formik = useFormik({
    initialValues: {
      logo: "",
      logobase64: "",
      firstName: "",
      lastName: "",
      labourCodeNo: "",
      aadharNo: "",
      contractorName: "",
      // subContractorId: subContractorId ? subContractorId : "",
      subContractorId: "",
      dateOfBirth: "",
      age: "",
      subContractorName: "",
      permanentHomeAddress: "",
      permanentAddressState: "",
      permanentAddressCity: "",
      permanentAddressPincode: "",
      currentAddress: "",
      currentState: "",
      currentCity: "",
      currentPincode: "",
      gender: "",
      mobileNumber: "",
      maritalStatus: "",
      numberChildren: "",
      motherTongue: "",
      gurdianName: "",
      // projectId: projectId ? projectId : "",
      // companyId: comapanyId ? comapanyId : "",
      projectId: "",
      // ...(userType === Constants.COMPANY_USER ? {} : { companyId: "", }),
      siteId: "",
      projectName: "",
      date: "",
      status: 1,
      height: "",
      weight: "",
      bloodGroup: "",
      identificationMark: "",
      employeementType: "",
      skillType: "",
      doj: "",
      skill_catagory: ""
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    const objectUrl = URL.createObjectURL(file);
    formik.setFieldValue("logo", file);
    setPreview(objectUrl);
  };
  const handleSubmit = (params) => {
    dispatch(LabourActions.createLabour(params))
  }

  // const handleSubmit = (params) => {
  //   dispatch(LabourActions.createLabour(params));
  //   // if (labourDetails && labourDetails.labourData) {
  //   //   if (labourDetails.labourData.projectId !== params.projectId) {
  //   //     //  setConfirmDialog(true)
  //   //     dispatch(LabourActions.createLabour(params));
  //   //   } else {
  //   //     // setConfirmDialog(true)
  //   //     // if (confirmDialog) {
  //   //     //   dispatch(LabourActions.createLabour(params));
  //   //     // }
  //   //     dispatch(LabourActions.createLabour(params));
  //   //   }
  //   //   //     else {
  //   //   //       dispatch(LabourActions.createLabour(params));
  //   //   //     }
  //   //   // } else {
  //   //   //      dispatch(LabourActions.createLabour(params));
  //   //   // }
  //   // }
  // };
  const clickPhoto = () => {
    const screenshotLogo = webRef.current.getScreenshot()
    formik.setFieldValue("logobase64", screenshotLogo);
    setPreview(screenshotLogo);
    setIsShowVideo(false);
  }
  const handleChangeProject = () => {
    dispatch(LabourActions.createLabour(formik.values));
  }

  return (
    <>
      <Box>
        <PageTitle title="Create Labour" isBack='true' />

        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item lg={4} sm={12} xs={12}>
              <Card>
                <Picker className="uploadpicker">
                  <input
                    type="file"
                    id="profile"
                    name="logo"
                    onChange={handleChangeImage}
                  />
                  <label htmlFor="profile" className="cursor-pointer">
                    <img className="uploadimgfitsize" alt="profile" src={preview || Avatar} />
                    <span>
                      <CameraAltOutlinedIcon sx={{ fontSize: '25', mr: '2' }} />
                      Upload Photo
                    </span>
                  </label>
                </Picker>
                <div className="position-relative">
                  {isShowVideo &&
                    <Webcam audio={false} ref={webRef}
                      screenshotFormat="image/jpeg"
                      videoConstraints={videoConstraints}
                      style={{ width: "100%" }}
                    />
                  }
                  <div className="text-center mt-4">
                    {!isShowVideo ? (
                      <Button onClick={() => setIsShowVideo(true)}
                        style={{ boxShadow: "0px 8px 10px #ccc", background: "#f1f1f1", border: "1px solid #ccc;" }}
                      >
                        Take Photo
                      </Button>) : null}
                    <Button onClick={() => setIsShowVideo(false)}
                      style={{ color: '#fff', boxShadow: 'none', position: 'absolute', top: "0px", right: 0 }}
                    >
                      <CloseOutlined />
                    </Button>
                    {isShowVideo ? (
                      <Button onClick={clickPhoto}
                        style={{ boxShadow: 'none', color: '#fff', position: 'absolute', bottom: "76px", right: '37%' }}
                      >
                        <LinkedCameraOutlined />
                      </Button>) : null}
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item lg={8} sm={12} xs={12}>
              <Card>
                <BasicInformation formik={formik} />
                <SkillInformation formik={formik} />
                <PermanateAddress formik={formik} />
                <Grid container justifyContent="flex-end">
                  <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{ mt: 3 }}
                  >
                    {loading ? <CircularProgress color="inherit" size={20} /> : <>Submit</>}
                  </Button>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </form>
      </Box>
      <DialogConfirm
        title="Exit Project"
        content="Are you sure want to exit this project?"
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onSubmit={handleChangeProject}
      />
    </>
  );
}
export default CreateLabour;
