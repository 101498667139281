import React, { useEffect } from "react";
import {
    Grid, Button, CircularProgress
} from "@mui/material";
import PropTypes from 'prop-types';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useDispatch, useSelector} from "react-redux";
import { GeneralSelector } from "selectors";
import Input from "components/Input";
import {useFormik} from "formik";
import * as yup from "yup";
import { GeneralActions, UserActions } from "slices/actions";
import { toast } from "react-toastify";

ResetPasswordDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
   
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


export default function ResetPasswordDialog(props) {
    const { open, onClose } = props
    const success = useSelector(GeneralSelector.success(UserActions.resetUserPassword.type));
    const error = useSelector(GeneralSelector.error(UserActions.resetUserPassword.type));
    const loading = useSelector(GeneralSelector.loader(UserActions.resetUserPassword.type));
    const dispatch = useDispatch();

    useEffect(() => {
        if (success) {
               onClose()
               toast.success(`${success?.message ?? "Success"}`, {
                       position: "top-right",
                       autoClose: 3000,
                       closeOnClick: true,
                   });
   
               dispatch(GeneralActions.removeSuccess(UserActions.resetUserPassword.type));
           }
       }, [success]);
   
       useEffect(() => {
           if (error) {
               toast.error(`${error?.message ?? "Error"}`, {
                   position: "top-right",
                   autoClose: 3000,
                   color: "white",
                   closeOnClick: true,
               });
              dispatch(GeneralActions.removeError(UserActions.resetUserPassword.type));
               
           }
       }, [error]);

     const validationSchema = yup.object({
        oldPassword: yup.string().min(8, 'Password should be of minimum 8 characters length').
        required('Please fill this field'),
        newPassword: yup.string().min(8, 'Password should be of minimum 8 characters length').
        required('Please fill this field'),
        confirmPassword: yup.string().min(8, 'Password should be of minimum 8 characters length').
        oneOf([yup.ref('newPassword'), null], 'Passwords must match').
        required('Please fill this field'),
    });

    const formik = useFormik({
        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = (values) => {
       dispatch(UserActions.resetUserPassword(values))
    }
   const closeDialouge = () => {
    onClose();
    formik.setFieldValue("oldPassword","");
    formik.setFieldValue("newPassword","");
    formik.setFieldValue("confirmPassword","");
    }
     return (
        <div>
            <BootstrapDialog style={{maxWidth: '500px', margin: 'auto'}}
                onClose={closeDialouge}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={closeDialouge} sx={{ borderBottom: '1px solid #ccc' }}>
                    Reset Password
                    <CloseOutlinedIcon onClick={closeDialouge} sx={{float: 'right'}} />
                </DialogTitle>
                <form>
                     <DialogContent>
                        <Grid container spacing={2} sx={{ pt: 4 }}>
                            <Grid item lg={12} sm={12} xs={12}>
                                <Input
                                    label="Current Password"
                                    name='oldPassword'
                                    value={formik.values.oldPassword}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.oldPassword) && Boolean(formik.errors.oldPassword)}
                                    helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                                />
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12}>
                                <Input
                                    label="New password"
                                    name='newPassword'
                                    value={formik.values.newPassword}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.newPassword) && Boolean(formik.errors.newPassword)}
                                    helperText={formik.touched.newPassword && formik.errors.newPassword}
                                />
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12}>
                                <Input
                                    label="Confirm password"
                                    name='confirmPassword'
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.confirmPassword) && Boolean(formik.errors.confirmPassword)}
                                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                />
                            </Grid>
                        </Grid>
                </DialogContent>
                 <DialogActions>
                    <Button autoFocus onClick={closeDialouge} variant="contained" color="primary">
                        Close
                    </Button>
                    <Button autoFocus onClick={formik.handleSubmit} variant="contained" color="primary">
                    {loading ? (
                    <CircularProgress color="inherit" size={20} />
                    ) : (
                    <>Submit</>
                    )}
                    </Button>
                </DialogActions>
            </form>
        </BootstrapDialog>
        </div>
    )

}