import React, {useEffect, useState} from "react";
import {
    Box, Button, Card,Table, TableBody, TableCell, TableHead, TableRow,Grid,MenuItem, IconButton
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {GeneralSelector, HolidaySelector, ProjectSelector, SubContractorSelector} from "selectors";
import ListSkeleton from "../../components/Skeleton/ListSkeleton";
import { CompanyActions, ProjectActions, HolidayActions, GeneralActions, SubContractorActions } from "slices/actions";
import SelectField from "components/SelectField";
import { CompanySelector } from "selectors/CompanySelector";
import { Constants } from "utils/constants";
import FloatingButton from "components/FloatingButton";
import {useHistory} from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { Delete, Edit } from "@mui/icons-material";
import { format } from 'date-fns';
import DialogConfirm from "components/DialogConfirm";
import { toast } from "react-toastify";

function HolidayList() {
  const history = useHistory()
  const dispatch = useDispatch();
  const loading = useSelector(GeneralSelector.loader(HolidayActions.getHolidayByIdSucess.type));
  const companyList = useSelector(CompanySelector.getCompanyList());
  const projectList = useSelector(ProjectSelector.getProjectList());
  const allHolidayList = useSelector(HolidaySelector.getHolidayById());
  const [projectId,setProjectId] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selected, setSelected] = useState(null);
  const success = useSelector(GeneralSelector.success(HolidayActions.deleteHoliday.type));
  const error = useSelector(GeneralSelector.error(HolidayActions.deleteHoliday.type));
  const userType = localStorage.getItem('userType')
  const companyId = localStorage.getItem('companyID')
  const projectID = localStorage.getItem('projectID')
  const subContractorId = localStorage.getItem('subcontractorID');
  const assignedProjectToSubcontractorList = useSelector(SubContractorSelector.getAssignedSubcontractor());
  const [assignedProject,setAssignedProject] = useState("");
  
  useEffect(() => {
    if (!companyId) {
     dispatch(CompanyActions.getCompanyList());
    }
    if (companyId && !projectId) {
        const params = {
            company_id: companyId
        }
        
        dispatch(ProjectActions.getProjectList(params))
    } 
    if (subContractorId) {
        const params = {
            subcontractorId: subContractorId
        }
        dispatch(SubContractorActions.getAssignedSubContractor(params));
    }
    if (userType === Constants.PROJECT_USER) {
        const params = {
            companyId: companyId,
            projectId: projectID
        }
       dispatch(HolidayActions.getHolidayById(params))
    }
 },[])

 useEffect(() => {
    if (success) {
        setConfirmDelete(false);
        setSelected(null);

        toast.success(`${success?.message ?? "Success"}`, {
                position: "top-right",
                autoClose: 4000,
                closeOnClick: true
            });

        dispatch(GeneralActions.removeSuccess(HolidayActions.deleteHoliday.type));
        const params = {
            projectId: projectId
        }
        dispatch(HolidayActions.getHolidayById(params))
    }
}, [success]);

useEffect(() => {
  if (error) {
      setConfirmDelete(false);
      setSelected(null);

      toast.error(`${error?.message ?? "Error"}`, {
              position: "top-right",
              autoClose: 4000,
              closeOnClick: true
          });

      dispatch(GeneralActions.removeError(HolidayActions.deleteHoliday.type));
  }
}, [error]);

    useEffect(() => {
        if (companyId) {
            const params = {
                company_id: companyId,
                status: 1
            }
            dispatch(ProjectActions.getProjectList(params))
        }
    },[companyId])

 
  const validationSchema = yup.object({
    companyId: yup.string().required("This field is required"),
    projectId: yup.string().required("This field is required"),
});
  
  const formik = useFormik({
    initialValues: {
      companyId: companyId?companyId:"",
      projectId: projectID?projectID:"",
  },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: (values) => {
       dispatch(HolidayActions.getHolidayById(values))
    },
  });

  const handleChangeCompany = (e) => {
     const cId = e.target.value;
      formik.setFieldValue('companyId',cId)
      formik.setFieldValue('projectId',"")
         const params = {
        company_id: cId,
        status: 1
        }
      dispatch(ProjectActions.getProjectList(params))
  }  
  const handleChangeProject = (e) => {
    const cId = e.target.value;
    setProjectId(cId);
    formik.setFieldValue('projectId',cId)
  }

  const handleDelete = () => {
    dispatch(HolidayActions.deleteHoliday(selected));
  };
 useEffect(() => {
    if (!formik.values.companyId || !formik.values.projectId) {
      dispatch(HolidayActions.getHolidayByIdSucess(""))
    }
 },[])
 const handleChangeProjectBySubcontractor = (e) => {
    setAssignedProject(e.target.value)
    for (const i in assignedProjectToSubcontractorList.data) {
        if (assignedProjectToSubcontractorList.data[i].projectId._id === e.target.value) {
            formik.setFieldValue('companyId',assignedProjectToSubcontractorList.data[i].companyId._id)
            formik.setFieldValue('projectId',e.target.value)
         }
    }
 }

  return (
    <Card>
   
    <Typography variant="h5" sx={{ fontWeight: 600, mb: 4 }}>Holiday List</Typography>

    <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
            {userType === Constants.SUPERADMIN?(
            <Grid item lg={5} sm={12} xs={12}>
                <Typography variant='h6' sx={{fontSize: 15}}>Company</Typography>
                <SelectField
                    onChange={handleChangeCompany}
                    value={formik.values.companyId}
                    error={formik.touched.companyId && Boolean(formik.errors.companyId)}
                    helperText={formik.touched.companyId && formik.errors.companyId}
                >
                        {companyList && companyList.map((item,index) => (
                        <MenuItem value={item._id} key={index}>
                            {item.name}
                        </MenuItem>
                        ))}
                </SelectField>
            </Grid>
            ):null} 
            {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER?(
            <Grid item lg={5} sm={12} xs={12}>
                <Typography variant='h6' sx={{fontSize: 15}}>Project</Typography>
                <SelectField
                    onChange={handleChangeProject}
                    value={formik.values.projectId}
                    error={formik.touched.projectId && Boolean(formik.errors.projectId)}
                    helperText={formik.touched.projectId && formik.errors.projectId}
                    >
                    {(formik.values.companyId && projectList) && projectList.map((item,index) => (
                        <MenuItem value={item._id} key={index}>
                            {item.name}
                        </MenuItem>
                        ))}
                </SelectField>
            </Grid>
            ):null} 
            {userType === Constants.SUB_CONTRACTOR_USER ?(
                    <Grid item lg={4} sm={12} xs={12}>
                        <Typography sx={{fontSize: 15}}>Project</Typography>
                        <SelectField
                            value={assignedProject}
                            onChange={handleChangeProjectBySubcontractor}
                            >
                            {assignedProjectToSubcontractorList && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                            <MenuItem key={index} value={item.projectId._id}>
                                    {item?.projectId?.name}({item?.companyId?.name})
                            </MenuItem>
                            ))}
                        </SelectField> 
                    </Grid>
                    ):null
            }
             {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER || userType === Constants.SUB_CONTRACTOR_USER ?(
             <Grid item lg={2} sm={12} xs={12} sx={{textAlign: 'right'}}>
                <Button color="primary" 
                    variant="contained" type="submit"
                    sx={{my: 4}} 
                  >
                    Submit
                </Button>
            </Grid>
            ):null}
              
        </Grid>
    </form>

    {loading ? (
        <ListSkeleton/>
    ) : (
        <Box>
          {allHolidayList && allHolidayList.data ? null: <Typography color='GrayText' sx={{fontSize: 15, textAlign: 'center'}}>No Holiday List Found
            </Typography>}
            <Table>
            {allHolidayList && allHolidayList.data &&<TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>   
                        <TableCell>Occasion</TableCell> 
                        <TableCell>Project Name</TableCell> 
                        <TableCell align="right">Options</TableCell>
                    </TableRow>
                </TableHead>}
                <TableBody>
                     {allHolidayList && allHolidayList.data && allHolidayList.data.map((item, i) => (
                        <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {format(new Date(item.date), 'dd/MM/yyyy')}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.holidayName}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.projectName}
                            </TableCell>
                            <TableCell component="th" scope="row" align='right'>
                                <IconButton onClick={() => history.push(`/app/holidaylist/holidayDetail/${item._id}`)}>
                                    <Edit className="text-primary" />
                                </IconButton>
                                <IconButton 
                                    onClick={() => {
                                        setConfirmDelete(true);
                                        setSelected(item._id);
                                    }}
                                >
                                    <Delete className="text-default" />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}  
                </TableBody>
            </Table>
        </Box>
    )}

    <DialogConfirm
        title="Delete Data"
        content="Are you sure want to delete this project?"
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onSubmit={handleDelete}
    />

   <FloatingButton
        onClick={() => history.push("/app/holidaylist/addholiday")} />
</Card>
  )
}

export default HolidayList