import React, {useEffect, useState} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
     Card, 
      Table, TableHead, TableRow, 
       TableCell,
       TableBody
} from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { ReportSelector } from 'selectors/ReportSelector';
import PageTitle from "components/PageTitle";
import { GeneralSelector } from 'selectors';
import { ReportActions } from 'slices/actions';
import ListSkeleton from 'components/Skeleton/ListSkeleton';

export default function PfResult() {
    const dispatch = useDispatch();
    const reportBData = useSelector(ReportSelector.getFormBData());
    const formData = useSelector(ReportSelector.getFormData());
    const loading = useSelector(GeneralSelector.loader(ReportActions.getReportData.type))
    const [formDate,setFormDate] = useState({
        date: "",
     });
    useEffect(() => {
        if (formData) {
           dispatch(ReportActions.getReportBData(formData)); 
        }
    },[formData])
   

    useEffect(() => {
        const tempDate = new Date();
        let month = tempDate.getMonth() + 1;
        if (month < 10) {
            month = `0${month}`   
        }
        const year = tempDate.getFullYear()  
        const todayDate =`${year}-${month}`
        formDate.date = `"ECR Chalan" ${todayDate}`
        setFormDate({...formDate})
    }, []);

    return (
        <>
            <PageTitle isBack={true} title="ECR Chalan" />
            {loading? (
                <ListSkeleton/>
            ):(
            <Card>
                <div style={{maxWidth: '98%', width: '100%', overflowX: 'auto'}}>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button btn btn-primary mb-3"
                        table="table-to-xls"
                        filename={formDate.date}
                        sheet="tablexls"
                        buttonText="Export"
                    />
                    <div className=''>
                        <Table className="table Tableform FormTable" id="table-to-xls">
                            <TableHead className="thead-dark bg-light">
                                <TableRow className='tableform'>
                                    <TableCell>Sl.No</TableCell>
                                    <TableCell>UAN</TableCell>
                                    <TableCell>Member Name</TableCell> 
                                    <TableCell>Gross Wages</TableCell> 
                                    <TableCell>EPF Wages</TableCell> 
                                    <TableCell>EPS Wages</TableCell> 
                                    <TableCell>EDLI Wages</TableCell> 
                                    <TableCell>EPF Contribution remitted</TableCell> 
                                    <TableCell>EPS Contribution remitted</TableCell> 
                                    <TableCell>EPF and EPS Diff remitted</TableCell> 
                                    <TableCell>NCP Days</TableCell>
                                    <TableCell>Refund of Advances</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reportBData && reportBData.data && reportBData.data.map((res,index) => <TableRow 
                                    key={index} className="tableform">
                                    <TableCell align='center'>{index+1}</TableCell>
                                    <TableCell align='left'>{res?.ECRChalan?.uan_number}</TableCell>
                                    <TableCell width="40%" align='left'>
                                        {res.firstName} {res.lastName}
                                    </TableCell>
                                    <TableCell>{res?.ECRChalan?.grossWages}</TableCell>
                                    <TableCell>{res?.ECRChalan?.EPFWages}</TableCell>
                                    <TableCell>{res?.ECRChalan?.EPSWages}</TableCell>
                                    <TableCell>{res?.ECRChalan?.EDLIWages}</TableCell>
                                    <TableCell>{res?.ECRChalan?.EPFContributionRemitted}</TableCell>
                                    <TableCell>{res?.ECRChalan?.EPSContributionRemitted}</TableCell>
                                    <TableCell>{res?.ECRChalan?.EPFAndEPSDiffRemitted}</TableCell>
                                    <TableCell>{res?.ECRChalan?.NCP_days}</TableCell>
                                    <TableCell>{res?.ECRChalan?.refund}</TableCell>
                                </TableRow>)}
                            </TableBody>   
                        </Table>
                    </div>
                </div>
            </Card>)}
        </>
    )
}