import React, { useState } from "react";
import { CircularProgress, Grid, Typography, Dialog, DialogActions, DialogTitle } from "@mui/material";
import PropTypes from "prop-types";
import { MasterDataActions } from "slices/actions";
import { useDispatch, useSelector } from "react-redux";
import { GeneralSelector } from "selectors";
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Input from "../components/Input";

StatusChange.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    content: PropTypes.any,
    onClose: PropTypes.func,
    status: PropTypes.any,
    labourId: PropTypes.any,
    projectId: PropTypes.any
};


export default function StatusChange(props) {
    const { open, title, onClose, status, labourId, projectId } = props;
    const loading = useSelector(GeneralSelector.loader(MasterDataActions.changeStatus.type))
    const dispatch = useDispatch();
    const onSubmit = () => {
        if (validateForm()) {
            const params = {
                id: labourId,
                projectId: projectId,
                status: statusData.status,
                flag: "Labour",
                date: statusData.date,
                reason: statusData.text,
            }
            clearStatusData()
            dispatch(MasterDataActions.changeStatus(params))
        }
    }

    const [statusData, setStatusData] = useState({
        status: "",
        date: "",
        text: "Hii"
    })

    const [errObj, setErrObj] = useState({});

    const validateForm = () => {
        let valid = true
        if (!statusData.status) {
            errObj.status = 'Please choose a status';
            valid = false;
        }
        if (!statusData.date) {
            errObj.date = 'Date field is Required';
            valid = false;
        }
        if (!statusData.text) {
            errObj.text = 'Status field is Required';
            valid = false;
        }
        setErrObj({ ...errObj });
        return valid;
    }

    const clearForm = () => {
        setErrObj({})
    }

    const handleText = (e) => {
        delete errObj.text;
        setErrObj({ ...errObj });
        e.preventDefault();
        if (e.target.value !== "") {
            setStatusData({ ...statusData, text: e.target.value })
        } else if ((e.target.value === "")) {
            setStatusData({ ...statusData, text: "" })
        }
    }

    const handleDate = (e) => {
        delete errObj.date;
        setErrObj({ ...errObj });
        e.preventDefault();
        if (e.target.value !== "") {
            setStatusData({ ...statusData, date: e.target.value })
        }
    }

    const handleChangeStatus = (e) => {
        delete errObj.status;
        setErrObj({ ...errObj });
        e.preventDefault();
        if (e.target.value === '1') {
            setStatusData({ ...statusData, status: e.target.value })
        } else if (e.target.value === '2') {
            setStatusData({ ...statusData, status: e.target.value })
        }
    }


    function clearStatusData() {
        setStatusData({ date: "", text: "", status: "" });
        clearForm();
    }

    function CloseIconDialog() {
        onClose();
        clearStatusData();

    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle className="dialogtitle">
                {title}
                <CloseIcon onClick={CloseIconDialog} />
            </DialogTitle>
            <DialogActions>
                <Grid container spacing={3}>
                    <Grid item lg={6} sm={6} xs={12}>
                        <FormControl component="fieldset" sx={{ marginLeft: '15px', marginTop: '15px' }} >
                            <RadioGroup row aria-label="gender" name="gender1" value={statusData.status} onChange={handleChangeStatus} >
                                {status === 0 || status === 2 ? (
                                    <>
                                        <FormControlLabel value='1' checked={statusData.status === "1"} control={<Radio />} label="Approve" />
                                    </>
                                ) : null}
                                {status === 0 || status === 1 ? (
                                    <>
                                        <FormControlLabel value="2" checked={statusData.status === "2"} control={<Radio />} label="Reject" />
                                    </>
                                ) : null}
                            </RadioGroup>
                        </FormControl>
                        {errObj.status ? <Typography sx={{ color: 'red', fontSize: 12, marginLeft: '15px', marginTop: '5px' }}>{errObj.status}</Typography> : null}
                    </Grid>
                    <Grid item lg={6} sm={6} xs={12} >
                        <Input
                            sx={{ marginRight: '5px' }}
                            label="Date"
                            name='date'
                            type='date'
                            onChange={handleDate}
                            inputProps={{
                                min: new Date().toISOString().
                                    slice(0, 10)
                            }}
                            value={statusData.date} />
                        {errObj.date ? <Typography sx={{ color: 'red', fontSize: 12, marginTop: '15px' }}>{errObj.date}</Typography> : null}


                    </Grid>
                    <Grid item lg={12} sm={12} xs={12}>
                        <Input
                            multiline={true}
                            rows={5}
                            placeholder="Note here"
                            label="Reason"
                            name='reason'
                            onChange={handleText}
                            value={statusData.text}
                        />
                    </Grid>
                    {errObj.text ? <Typography sx={{ color: 'red', fontSize: 12, marginLeft: '24px', marginTop: '5px' }}>{errObj.text}</Typography> : null}
                </Grid>
            </DialogActions>
            <Button className="submitBtn" variant="contained" size="small" onClick={onSubmit}>
                {
                    loading ? (
                        <CircularProgress sx={{ justifyContent: 'flex-end' }} color="inherit" size={20} />
                    ) : "Submit"
                }
            </Button>
        </Dialog>
    )
}