import React ,{useEffect,useState} from "react";
import {
    Box,
    Button,
    Card,
    FormControl,
    Grid,
    Typography,
    useTheme
} from "@mui/material";
import * as yup from "yup";
import {useDispatch,useSelector} from "react-redux";
import {GeneralSelector, MasterDataSelector} from "selectors";
import { CompanyActions, GeneralActions, MasterDataActions} from "slices/actions";
import {useFormik} from "formik";
import Input from "components/Input";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import {useParams} from "react-router-dom";


BasicInformation.propTypes = {
    companyDetails: PropTypes.object,
};

export default function BasicInformation(props) {
    const {id} = useParams();
    const { companyDetails } = props;
    const dispatch = useDispatch();
    const theme = useTheme();
    const success = useSelector(GeneralSelector.success(CompanyActions.updateCompany.type));
    const error = useSelector(GeneralSelector.error(CompanyActions.updateCompany.type));
    const locationData = useSelector(MasterDataSelector.getLocationListByPincode());
    const [pincode,setPincode]=useState();

    // window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth',
    // });
    // const countries = COUNTRIES.map(item => ({
    //     id: item.id,
    //     name: item.name,
    //     phoneCode: item.phoneCode,
    //     flag: item.flag
    // }));

useEffect(() => {
     if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(CompanyActions.updateCompany.type));
            dispatch(CompanyActions.getCompanyById(id))
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                position: "top-right",
                autoClose: 3000,
                color: "white",
                closeOnClick: true,
            });

            dispatch(GeneralActions.removeError(CompanyActions.updateCompany.type));
            
        }
    }, [error]);


    const validationSchema = yup.object({
        name: yup.string().required('Name is required'),
        email: yup.string().email().
        required('Email is required'),
        contactNumber: yup.string().min(10).
        max(10).
        matches(/^[0-9\b]+$/,"Invalid Phone Number").
        required('Phone number is required'),
        gst: yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}Z[a-zA-Z0-9]{1}$/,"Invalid GST No").
        required('GST is required'),
        // state: yup.string().required('State is required'),
        // city: yup.string().required('City is required'),
        address: yup.string().required('Address is required'),
        pincode: yup.string().matches(/^[0-9]+$/, "Must be only digits").
        length(6, 'Pincode must be exactly 6 characters!').
        required('Pincode is required')
    });

    function handleMobileChange(e) {
         const val = e.target.value.replace(/[^0-9]/g, "");        
         formik.handleChange(e);
         formik.setFieldValue('contactNumber',val);
       }
 

    const formik = useFormik({
        initialValues: {
            name: companyDetails?.name ?? "",
            email: companyDetails?.email ?? "", 
            contactNumber: companyDetails?.contactNumber ?? "",
            city: companyDetails?.city ?? "",
            address: companyDetails?.address ?? "",
            pincode: companyDetails?.pincode ?? "",
            gst: companyDetails?.gst ?? "",
            state: companyDetails?.state ?? "",
        },
        enableReinitialize: true,
        validateOnChange: true,
         validationSchema: validationSchema,
        onSubmit: (values) => {
             handleSubmit(values);
        }
    });
   
     const handleSubmit = (values) => {
        const params = {
              id: companyDetails._id,
            ...values,
            
        };
       
         dispatch(CompanyActions.updateCompany(params));
    }
    const handleChangePinCode= (e) => {
        const pincodeData = e.target.value
        formik.setFieldValue('pincode',pincodeData)
        setPincode(pincodeData);
    }
    useEffect(() => {
        if (pincode && pincode.length === 6) {
            dispatch(MasterDataActions.fetchDataByPincode(pincode));
        }
    }, [pincode]);
    useEffect(() => {
       if (pincode && locationData) { 
        const addressData = locationData.PlaceName
        formik.setFieldValue('address', addressData);
        formik.setFieldValue('state', locationData?.StateName);
        formik.setFieldValue('city', locationData?.District);
        }
    }, [locationData]);


    return (
        <Card>
            <Typography variant='h5' sx={{ mb: 4 }}>Basic Information</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Company Name"
                            name='name'
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Email"
                            name='email'
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                           />
                    </Grid>
                   
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                            <Typography variant='caption' sx={{fontSize: "15px"}}>Company Phone Number</Typography>
                            <Box sx={{
                                display: 'flex',
                                gap: 1.5
                            }}>
                                <Box sx={{ width: 80 }}>
                                    <Input
                                        sx={{
                                            padding: "10px 8px",
                                            textAlign: 'center',
                                            '& .Mui-disabled': {
                                                fillColor: theme.palette.common.black
                                            }
                                        }}
                                        autoComplete='new-password'
                                        name='phoneCode'
                                        startAdornment='+'
                                        value="91"
                                       />
                                </Box>
                                <Input
                                    name='contactNumber'
                                    value={formik.values.contactNumber}
                                    onChange={handleMobileChange}
                                    error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
                                    helperText={formik.touched.contactNumber && formik.errors.contactNumber}/>
                            </Box>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="GST"
                            name='gst'
                            value={formik.values.gst}
                            onChange={formik.handleChange}
                            error={formik.touched.gst && Boolean(formik.errors.gst)}
                            helperText={formik.touched.gst && formik.errors.gst}
                            />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Pincode"
                            name='pincode'
                            value={formik.values.pincode}
                            onChange={ (e) => handleChangePinCode(e)}
                            error={formik.touched.pincode && Boolean(formik.errors.pincode)}
                            helperText={formik.touched.pincode && formik.errors.pincode}
                            />
                    </Grid>  
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="City"
                            name='city'
                            readonly
                            value={formik.values.city}
                            error={formik.touched.city && Boolean(formik.errors.city)}
                            helperText={formik.touched.city && formik.errors.city}
                            />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Address"
                            name='address'
                            readonly
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}
                            />
                    </Grid>
                  
                   
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="State"
                            name='state'
                            disabled
                            value={formik.values.state}
                            error={formik.touched.state && Boolean(formik.errors.state)}
                            helperText={formik.touched.state && formik.errors.state}
                            />
                    </Grid>                     
                        <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained">
                               Update
                            </Button>
                        </Grid>
                </Grid>
            </form>
        </Card>
    )
}