import React,{useState} from "react";
import {
    Card,
    Grid,
    Typography,
    IconButton,
    InputAdornment
} from "@mui/material";
import Input from "components/Input";
import PropTypes from "prop-types";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import { SubContractorSelector } from "selectors";
import { useSelector } from "react-redux";


AccountSetting.propTypes = {
    formik: PropTypes.object
};

export default function AccountSetting(props) {
    const { formik } = props;
    const subcontractorDetails = useSelector(SubContractorSelector.getDataByPancard());
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
     const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    return (
        <Card sx={{ mb: 3 }}>
              <Typography variant='h5' sx={{ mb: 4 }}>Admin Details</Typography>
              <Grid container spacing={3}>
                  <Grid item lg={6} xs={12}>
                      <Input
                          label="Email"
                          type="email"
                          name='subContractorEmail'
                          value={formik.values.subContractorEmail}
                          onChange={formik.handleChange}
                          error={Boolean(formik.touched.subContractorEmail) && Boolean(formik.errors.subContractorEmail)}
                          helperText={formik.touched.subContractorEmail && formik.errors.subContractorEmail}
                          disabled={subcontractorDetails && subcontractorDetails.userData && subcontractorDetails.userData.email }
                        />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                      <Input
                          label="Password"
                          inputProps={{
                              autoComplete: "new-password"
                          }}
                          placeholder='●●●●●●●●●●'
                          type={showPassword ? 'text' : 'password'}
                          name='password'
                          endAdornment={
                              <InputAdornment position="end">
                                  <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                  >
                                      {!showPassword ? <VisibilityOff/> : <Visibility/> }
                                  </IconButton>
                              </InputAdornment>
                          }
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          error={formik.touched.password && Boolean(formik.errors.password)}
                          helperText={formik.touched.password && formik.errors.password}/>
                  </Grid>
              </Grid>
          </Card>
    )
}