import React, {useEffect, useState} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
      Card, 
       Table, TableHead, TableRow, 
       TableCell,
       TableBody
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { ReportSelector } from 'selectors/ReportSelector';
import { format } from 'date-fns';
import PageTitle from "components/PageTitle";
import { GeneralSelector } from 'selectors';
import { ReportActions } from 'slices/actions';
import ListSkeleton from 'components/Skeleton/ListSkeleton';

export default function FormAResult() {
    const dispatch = useDispatch();
    const reportAData = useSelector(ReportSelector.getFormAData());
    const formData = useSelector(ReportSelector.getFormData());
    const loading = useSelector(GeneralSelector.loader(ReportActions.getReportData.type))
    const [formDate,setFormDate] = useState({
        date: "",
     });
    useEffect(() => {
        if (formData) {
           dispatch(ReportActions.getReportAData(formData)); 
        }
    },[formData])
   

    useEffect(() => {
        const tempDate = new Date();
        // let date = tempDate.getDate();
        // if (date < 10) {
        //     date = `0${date}`   
        // }
        let month = tempDate.getMonth() + 1;
        if (month < 10) {
            month = `0${month}`   
        }
        const year = tempDate.getFullYear()  
        const todayDate =`${year}-${month}`
        // formDate.date = todayDate
        formDate.date = `"Form A" ${todayDate}`
        setFormDate({...formDate})
    }, []);
    return (
        <>
        <PageTitle isBack={true} title="Form A" />
        {loading? (
            <ListSkeleton/>
        ):(
        <Card>
            <div style={{maxWidth: '46%', width: '100%', overflowX: 'auto'}}>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn btn-primary mb-3"
                    table="table-to-xls"
                    filename={formDate.date}
                    sheet="tablexls"
                    buttonText="Export"
                />
                <Table className="table Tableform" id="table-to-xls">
                    <TableHead className="thead-dark">
                        <TableRow className="Row_border_none">
                            <TableCell colSpan='40' align='center'>FORM A <br/>
                                FORMAT OF EMPLOYEE REGISTER<br />
                                Part-A: For All Establishments
                            </TableCell>
                        </TableRow>
                        <TableRow className="Row_border_none">
                            <TableCell colSpan='20' sx={{fontSize: 12}}>
                                Name of the Establishment: {reportAData.companyname}< br/>
                            </TableCell>
                            <TableCell colSpan='20' align="right" sx={{fontSize: 12}}>
                                Name of Owner: {reportAData.subContractorname}
                            </TableCell>
                        </TableRow>
                        <TableRow className="Row_border_none">
                            <TableCell colSpan='20' sx={{fontSize: 12}}>
                                Name and address of establishment in/under which contract is carried on:- {reportAData.projectname}
                            </TableCell>
                            <TableCell colSpan='20' align="right" sx={{fontSize: 12}}>< br/>
                                LIN: ........< br/>
                            </TableCell>
                        </TableRow>
                        <TableRow className="Row_border_none">
                            <TableCell colSpan='40' sx={{fontSize: 12}}>
                                Wage period From {''}  
                                {reportAData.startDate && format(new Date(reportAData.startDate), 'dd/MM/yyyy')} to {''}  
                                {reportAData.endDate && format(new Date(reportAData.endDate), 'dd/MM/yyyy')}
                            </TableCell>
                        </TableRow>
                        <TableRow className="tableform">
                            <TableCell className="rotatetd">Sl. No</TableCell>
                            <TableCell className="rotatetd">Employee Code</TableCell> 
                            <TableCell>Name</TableCell> 
                            <TableCell className="rotatetd">Surname</TableCell> 
                            <TableCell className="rotatetd">Gender</TableCell> 
                            <TableCell className="rotatetd">Fathers/ Spouse Name</TableCell> 
                            <TableCell className="rotatetd">Date of Birth</TableCell> 
                            <TableCell className="rotatetd">Nationality</TableCell> 
                            <TableCell className="rotatetd">Education Level</TableCell> 
                            <TableCell className="rotatetd">Date of Joining</TableCell>
                            <TableCell className="rotatetd">Designation</TableCell> 
                            <TableCell className="rotatetd">Category Address</TableCell> 
                            <TableCell className="rotatetd">Type of Employment</TableCell> 
                            <TableCell className="rotatetd">Mobile</TableCell> 
                            <TableCell className="rotatetd">UAN</TableCell> 
                            <TableCell className="rotatetd">PAN</TableCell>
                            <TableCell className="rotatetd">ESIC IP</TableCell>
                            <TableCell className="rotatetd">LWF</TableCell>
                            <TableCell className="rotatetd">Aadhar</TableCell>
                            <TableCell className="rotatetd">Bank A/c Number</TableCell>
                            <TableCell className="rotatetd">Bank</TableCell>
                            <TableCell className="rotatetd">Branch IFSC</TableCell>
                            <TableCell>Present Address</TableCell>
                            <TableCell>Permanent Address</TableCell>
                            <TableCell className="rotatetd">Service Book Number</TableCell>
                            <TableCell className="rotatetd">Date of Exit</TableCell>
                            <TableCell className="rotatetd">Reason for Exit</TableCell>
                            <TableCell className="rotatetd">Mark of Identification</TableCell>
                            <TableCell className="rotatetd">Photo</TableCell>
                            <TableCell className="rotatetd">Specimen Signature/ Thumb</TableCell>
                            <TableCell className="rotatetd">Remarks</TableCell>
                        </TableRow>
                        <TableRow className="tableform">
                            <TableCell>1</TableCell>
                            <TableCell>2</TableCell>
                            <TableCell>3</TableCell>
                            <TableCell>4</TableCell>
                            <TableCell>5</TableCell>
                            <TableCell>6</TableCell>
                            <TableCell>7</TableCell>
                            <TableCell>8</TableCell>
                            <TableCell>9</TableCell>
                            <TableCell>10</TableCell>
                            <TableCell>11</TableCell>
                            <TableCell>12</TableCell>
                            <TableCell>13</TableCell>
                            <TableCell>14</TableCell>
                            <TableCell>15</TableCell>
                            <TableCell>16</TableCell>
                            <TableCell>17</TableCell>
                            <TableCell>18</TableCell>
                            <TableCell>19</TableCell>
                            <TableCell>20</TableCell>
                            <TableCell>21</TableCell>
                            <TableCell>22</TableCell>
                            <TableCell>23</TableCell>
                            <TableCell>24</TableCell>
                            <TableCell>25</TableCell>
                            <TableCell>26</TableCell>
                            <TableCell>27</TableCell>
                            <TableCell>28</TableCell>
                            <TableCell>29</TableCell>
                            <TableCell>30</TableCell>
                            <TableCell>31</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportAData && reportAData.data && reportAData.data.map((res,index) => <TableRow 
                        key={index} className="tableform">
                            <TableCell align='center'>{index+1}</TableCell>
                            <TableCell>{res.labourCode} </TableCell>
                            <TableCell>{res.firstName}</TableCell>
                            <TableCell>{res.lastName}</TableCell>
                            <TableCell>{res.gender}</TableCell>
                            <TableCell>{res.father_spouseName}</TableCell>
                            <TableCell>
                                {res.dateOfBirth && format(new Date(res.dateOfBirth), 'dd/MM/yyyy')}
                            </TableCell>
                            <TableCell>{res.nationality}</TableCell>
                            <TableCell>{res.education}</TableCell>
                            <TableCell>
                                {res.doj && format(new Date(res.doj), 'dd/MM/yyyy')}
                            </TableCell>
                            <TableCell>{res.designation}</TableCell>
                            <TableCell>{res.catagoryAddress}</TableCell>
                            <TableCell>{res.employeementType}</TableCell>
                            <TableCell>{res.mobileNumber}</TableCell>
                            <TableCell>{res.uanNumber}</TableCell>
                            <TableCell>{res.panNumber}</TableCell>
                            <TableCell>{res.ESICIP}</TableCell>
                            <TableCell>{res.LWF}</TableCell>
                            <TableCell>{res.aadharNo}</TableCell>
                            <TableCell>{res.bankAccountNumber}</TableCell>
                            <TableCell>{res.bankName}</TableCell>
                            <TableCell>{res.bankIfsc}</TableCell>
                            <TableCell>{res.presentAddress}</TableCell>
                            <TableCell>{res.permanentAddressCity}</TableCell>
                            <TableCell>{res.serviceBookNo}</TableCell>
                            <TableCell>
                                {res.exitDate && format(new Date(res.exitDate), 'dd/MM/yyyy')}
                            </TableCell>
                            <TableCell>{res.exitReason}</TableCell>
                            <TableCell>{res.identificationMark}</TableCell>
                            <TableCell>{!res.photo && 'N/A'} {res.photo === 'N/A' && 'N/A'} {res.photo && res.photo !== 'N/A' && <img src={res.photo} width='100' height='80'></img>} </TableCell>
                            <TableCell>{res.signature}</TableCell>
                            <TableCell>{res.remark}</TableCell>
                        </TableRow>)}
                    </TableBody>   
                </Table>
            </div>
        </Card>)}
        </>
    )
}