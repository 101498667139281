import {all, call, put, takeLatest} from 'redux-saga/effects'
import {AuthService} from "../services";
import {AuthActions, GeneralActions, UserActions} from "../slices/actions";
import {push} from "connected-react-router";

function *signIn({type, payload}) {
    try {
        yield put(GeneralActions.startLoading(type));
        delete payload.role;
        const result = yield call(AuthService.Login, payload);
        localStorage.setItem("merakihr-token", result.data.token);
        localStorage.setItem("userType", result.data.usertype);
        localStorage.setItem("companyID", result.data.companyID);
        localStorage.setItem("projectID", result.data.projectID);
        localStorage.setItem("subcontractorID", result.data.subcontractorID);
        localStorage.setItem("userID", result.data.userID);
        localStorage.setItem("companyactive",result.data.companyactive)
        localStorage.setItem("projectactive",result.data.projectactive)
        localStorage.setItem("permissionMenuList",JSON.stringify(result.data.mainmenu))
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data
        }));
        yield put(GeneralActions.stopLoading(type));
        yield put(push('/app/dashboard'));
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *signOut({type}) {
    try {
        yield put(GeneralActions.startLoading(type));
        localStorage.removeItem("merakihr-token");
        localStorage.removeItem("userType");
        localStorage.removeItem("companyID");
        localStorage.removeItem("projectID");
        localStorage.removeItem("subcontractorID");
        localStorage.removeItem("userID");
        localStorage.removeItem("companyactive")
        localStorage.removeItem("projectactive")
        localStorage.removeItem("permissionMenuList")
        yield put(UserActions.resetProfile());
        yield put(GeneralActions.stopLoading(type));
        yield put(push('/'));
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
    }
}

export function *AuthWatcher() {
    yield all([
        yield takeLatest(AuthActions.login.type, signIn),
        yield takeLatest(AuthActions.logout.type, signOut)
    ]);
}