import {InsertDriveFileOutlined,UploadFile, CastConnectedOutlined, CastForEducationOutlined, AccountBalanceOutlined, DeveloperBoardOutlined, SavingsOutlined} from "@mui/icons-material";
import BankAccountInformation from "../Component/AboutSubContractorDetails/Form/BankAccountInformationDetails";
import BasicInfo from "../Component/AboutSubContractorDetails/Form/BasicInfo";
import BocwDetails from "../Component/AboutSubContractorDetails/Form/BocwDetails";
import GpawsDetails from "../Component/AboutSubContractorDetails/Form/GpaWcDetails";
import LabourLicenceDetails from "../Component/AboutSubContractorDetails/Form/LabourLicenceDetails";
import PFDetails from "../Component/AboutSubContractorDetails/Form/PFDetails";
import UploadKycDoc from "../Component/AboutSubContractorDetails/Form/UploadKycDoc";
// import Compliance from "../../SubContractor/Component/AboutSubContractorDetails/Form/Compliance/Compliance";

const menus = [
    {
        id: 'basic',
        name: 'Basic Information',
        icon: InsertDriveFileOutlined,
        component: BasicInfo,
        code: "Basic"
    },
    {
        id: 'bankaccount',
        name: 'Bank Account Details',
        icon: AccountBalanceOutlined,
        component: BankAccountInformation,
        code: "BankAccount"
    },
    {
        id: 'kycdoc',
        name: 'Upload KYC Document',
        icon: UploadFile,
        component: UploadKycDoc,
        code: "KycDoc"
    },
    {
        id: 'bocw',
        name: 'Bocw',
        icon: CastConnectedOutlined,
        component: BocwDetails,
        code: "Bocw"
    },
    {
        id: 'gpawc',
        name: 'GpaWc',
        icon: CastForEducationOutlined,
        component: GpawsDetails,
        code: "Gpawc"
    },
    {
        id: 'labourlicence',
        name: 'Labour Licence Details',
        icon: DeveloperBoardOutlined,
        component: LabourLicenceDetails,
        code: "Labourlicence"
    },
    {
        id: 'pfdetails',
        name: 'PF Details',
        icon: SavingsOutlined,
        component: PFDetails,
        code: "PFDetails"
    },
    // {
    //     id: 'compliance',
    //     name: 'Compliance',
    //     icon: SafetyCheckOutlined,
    //     component: Compliance,
    //     code: "Compliance"
    // },
];

export default menus;