import { createSlice } from "@reduxjs/toolkit";

export const AttendanceSlice = createSlice({
    name: "Attendance",
    initialState: {
        attendances: [],
        pagination: {},
        attendance: {},
        bulkUplaodInformation: {}
    },
    reducers: {
        getAttendances: () => {},
        getAttendancesSuccess: (state, action) => {
            state.attendance = {};
            state.attendances = action.payload.data;
            state.pagination = action.payload.pagination;
        },
        getAttendanceById: () => {},
        getAttendanceByIdSuccess: (state, action) => {
            state.attendance = action.payload
        },
        createAttendance: () => {},
        createMyAttendance: () => {},
        updateAttendance: () => {},
        deleteAttendance: () => {},
        attendanceBulkUpload: () => {},
        attendanceBulkUploadSucess: (state, action) => {
            state.bulkUplaodInformation = action.payload
        },
        monthlyAttendanceUpdate: () => {},
        monthlyMyAttendanceUpdate: () => {},
        }
});

export default AttendanceSlice;