import React from "react";
import {
    Card,
    Grid,
    Button,
    Link
} from "@mui/material";
import Input from "components/Input";
import { SubContractorActions } from "slices/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { UserSelector } from "selectors";

export default function CancelledCheck() {
    const {id} = useParams()
    const dispatch = useDispatch();
    const subcontractorDetails = useSelector(UserSelector.getUserById());
    const validationSchema = yup.object({
        cancell_check: yup.string().required("This field is required"),
        file: yup.string().required("This field is required"),
    });

    const formik = useFormik({
        initialValues: {
            type: "cancell_check_docs",
            cancell_check: subcontractorDetails?.cancell_check?? "",
            file: subcontractorDetails.cancell_check_docs??""
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
          handleSubmit(values);
        },
      });
      const handleSubmit = (values) => {
        const params = {
            id: id,
            ...values
        }
         dispatch(SubContractorActions.uploadSubcontractorDocument(params));
      };

     const handleUploadImage = (e) => {
        const file = e.target.files[0];
        formik.setFieldValue("file", file);
     }
    
    return (
        <Card className="projectcard text-center position-relative">
            <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                <Grid item lg={12} xs={12}>
                    <Input
                        label="Cancelled Cheque Number"
                        type="text"
                        name='cancell_check'
                        value={formik.values.cancell_check}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.cancell_check) && Boolean(formik.errors.cancell_check)}
                        helperText={formik.touched.cancell_check && formik.errors.cancell_check}
                        />
                </Grid> 
                <Grid item lg={12} xs={12}>
                    <Input className="uploadinput"
                        label="Upload Cheque photo"
                        type="file"
                        onChange={handleUploadImage}
                        name='file'
                        error={Boolean(formik.touched.file) && Boolean(formik.errors.file)}
                        helperText={formik.touched.file && formik.errors.file}
                        />
                </Grid> 
            </Grid>
            {subcontractorDetails.cancell_check_docs?(
                                <Link href={subcontractorDetails.cancell_check_docs} target='_blank' >Cancelled cheque</Link>
                            ):(
                                null)
            }
            <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    
                    >
                    Submit
                </Button> 
            </Grid>
            </form>
        </Card>
    )
}