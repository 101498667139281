import React from "react";
// import styled from "@emotion/styled";
import {Box} from "@mui/material";
import PropTypes from "prop-types";
import CountUp from 'react-countup';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {useHistory} from "react-router-dom";

// const WidgetBox = styled(Card)(() => ({
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     padding: 20,
//     borderRadius: 20
// }));
const imagePath = process.env.REACT_APP_URL
Widget.propTypes = {
    // title: PropTypes.string,
    // content: PropTypes.any,
    // icon: PropTypes.any
    count: PropTypes.any,
    image: PropTypes.string,
    title: PropTypes.string,
    color: PropTypes.string,
    navigto: PropTypes.string
};

export default function Widget(props) {
     const { count, image,title,color,navigto} = props;
     const history = useHistory()

        const handleNavigate = () => {
            if (navigto === 'contractor') {
                history.push("/app/project");
            } else if (navigto === 'subcontractor') {
                history.push("/app/subcontractor");            
            } else if (navigto === 'activeprojects') {
                history.push(`/app/project/${1}`);
            } else if (navigto === 'totallabour') {
                history.push("/app/labour");
            } else if (navigto === 'labourattendance') {
                history.push("/app/dayAttendance");
            } else if (navigto === 'company') {
                history.push("/app/company");
            } else if (navigto === 'totalprojects') {
                history.push("/app/project");
            } else if (navigto === 'site') {
                history.push("/app/site");
            }
        }
     
     return (
            <div className="overflow-hidden card position-relative">
              
                <img src={imagePath+image} style={{position: "absolute", right: "0", top: "0", width: "", height: "100%", borderTopRightRadius: ".375rem", borderBottomRightRadius: ".375rem", objectFit: "cover"}} />
                <div className="bg-holder bg-card">
                </div>
                <div className="position-relative card-body">
                    <h6 className="text-800"><b>{title}</b></h6>
                    
                    <div className="display-4 fs-4 mb-2 fw-normal font-sans-serif">
                        <span className={color}>
                        <CountUp
                            start={0}
                            end={count}
                            duration={6}
                        />    
                         
                            </span>
                    </div>
                    <a className="fw-semi-bold fs--1 text-nowrap cursor-pointer text-decoration-none"
                        onClick={() => handleNavigate()}
                    >
                        See All
                        <ChevronRightIcon className={color} />
                    </a>
                </div>
                <Box style= {{display: "inline-block"}}>
                </Box>
            </div>
    )
}