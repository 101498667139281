import React, { useState,useEffect } from "react";
import {
    Grid, Typography, Button, MenuItem, CircularProgress
} from "@mui/material";
import PropTypes from 'prop-types';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";
import { useDispatch, useSelector} from "react-redux";
import { AttendanceActions, GeneralActions, ProjectActions, SubContractorActions } from "slices/actions";
import { toast } from "react-toastify";
import { GeneralSelector, ProjectSelector, SubContractorSelector } from "selectors";
import { Constants } from "utils/constants";
import { CompanySelector } from "selectors/CompanySelector";
import SelectField from "components/SelectField";

AttendenceDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
  };

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


export default function AttendenceDialog(props) {
    const { open, onClose } = props
    const dispatch = useDispatch();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const success = useSelector(GeneralSelector.success(AttendanceActions.attendanceBulkUpload.type));
    const error = useSelector(GeneralSelector.error(AttendanceActions.attendanceBulkUpload.type));
    const loading = useSelector(GeneralSelector.loader(AttendanceActions.attendanceBulkUpload.type));
    const userType = localStorage.getItem("userType");
    const companyId = localStorage.getItem("companyID");
    const projectId = localStorage.getItem("projectID");
    const subcontractorId = localStorage.getItem("subcontractorID");
    const companyList = useSelector(CompanySelector.getCompanyList());
    const projectList = useSelector(ProjectSelector.getProjectList());
    const [errObj,setErrObj] = useState({});  
    const [assignedProject, setAssignedProject] = useState("");
    const assignedProjectToSubcontractorList = useSelector(SubContractorSelector.getAssignedSubcontractor());
    const [attendanceFormData, setAttendanceFormData] = useState({
        companyName: companyId ? companyId : "",
        projectName: projectId ? projectId : "",
        document: ""
      });

    useEffect(() => {
         if (companyId && !projectId) {
            const params = {
            company_id: companyId,
            status: 1,
           }
           dispatch(ProjectActions.getProjectList(params));
          } 
          if (projectId && !subcontractorId) {
            const params = {
                company_id: companyId,
                project_id: projectId,
              };
              dispatch(SubContractorActions.getAssignedSubContractor(params));
           }
           if (subcontractorId) {
            const params = {
              subcontractorId: subcontractorId,
            };
            dispatch(SubContractorActions.getAssignedSubContractor(params));
          }
    },[])

    const handleChangeAttendanceDocument = (e) => {
        delete errObj.document;
         setErrObj({...errObj});
        setIsFilePicked(true);
        const file1 = e.target.files[0];
        attendanceFormData.document = file1
        setAttendanceFormData({...attendanceFormData})
     }
     useEffect(() => {
     if (success) {
           toast.success(`${success?.message?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true
                });
      
            dispatch(GeneralActions.removeSuccess(AttendanceActions.attendanceBulkUpload.type));
            closeDialouge()
          }
      }, [success]);

      useEffect(() => {
        if (error) {
           toast.error(`${error?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true
                });
      
            dispatch(GeneralActions.removeError(AttendanceActions.attendanceBulkUpload.type));
            closeDialouge()
          }
      }, [error]);

      const validateForm = () => {
        let valid = true
            if (!attendanceFormData.companyName) {
               errObj.companyName = 'This field is Required';
               valid = false;
            }
            if (!attendanceFormData.projectName) {
                errObj.projectName = 'This field is Required';
                valid = false;
            }
            if (!attendanceFormData.document) {
                errObj.document = 'This field is Required';
                valid = false;
            }
        setErrObj({...errObj});
        return valid; 
      }

     const onSubmit = () => {
        if (validateForm()) {
            dispatch(AttendanceActions.attendanceBulkUpload(attendanceFormData))
        } 
    }
    const handleChangeCompany = (e) => {
        delete errObj.companyName;
        setErrObj({...errObj});
        attendanceFormData.companyName = e.target.value;
        attendanceFormData.projectName = "";
        setAttendanceFormData({ ...attendanceFormData});
       if (attendanceFormData.companyName) {
          const params = {
            company_id: attendanceFormData.companyName,
            status: 1,
          };
          dispatch(ProjectActions.getProjectList(params));
        }
      };
      const handleChangeProject = (e) => {
        delete errObj.projectName;
        setErrObj({...errObj});
        attendanceFormData.projectName = e.target.value;
        setAttendanceFormData({ ...attendanceFormData });
        };
    const closeDialouge = () => {
        attendanceFormData.companyName = ""
        attendanceFormData.projectName = ""
        attendanceFormData.document = ""
        setIsFilePicked(false)
        setAttendanceFormData({...attendanceFormData})
        onClose();

    }
    const handleChangeProjectBySubcontractor = (e) => {
        setAssignedProject(e.target.value);
        for (const i in assignedProjectToSubcontractorList.data) {
          if (
            assignedProjectToSubcontractorList.data[i].projectId._id ===
            e.target.value
          ) {
            attendanceFormData.projectName = e.target.value;
            attendanceFormData.companyName =
            assignedProjectToSubcontractorList.data[i].companyId._id;
            setAttendanceFormData({ ...attendanceFormData });
          }
        }
      };
    return (
        <div className="IdcardDialog">
            <BootstrapDialog
                onClose={closeDialouge}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={closeDialouge} sx={{ borderBottom: '1px solid #ccc' }} />
                          
             <DialogContent>
             <form>
             <Grid container spacing={2} sx={{ pt: 4 }}>
               {userType === Constants.SUPERADMIN ? (
                <Grid item lg={6} sm={12} xs={12}>
                <Typography sx={{ fontSize: 15 }}>Company Name</Typography>
                <SelectField
                  value={attendanceFormData.companyName}
                  onChange={handleChangeCompany}
                >
                  {companyList &&
                    companyList.map((item, index) => (
                      <MenuItem key={index} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </SelectField>
                {errObj.companyName ? <Typography sx={{color: 'red',fontSize: 12}}>{errObj.companyName}</Typography>:null}
            </Grid>
            ) : null}
            {userType === Constants.SUPERADMIN ||
            userType === Constants.COMPANY_USER ? (
              <Grid item lg={6} sm={12} xs={12}>
                <Typography sx={{ fontSize: 15 }}>Project Name</Typography>
                <SelectField
                  value={attendanceFormData.projectName}
                  onChange={handleChangeProject}
                >
                  {(attendanceFormData.companyName && projectList) &&
                    projectList.map((item, index) => (
                      <MenuItem key={index} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </SelectField>
                {errObj.projectName ? <Typography sx={{color: 'red',fontSize: 12}}>{errObj.projectName}</Typography>:null}
                </Grid>
              ) : null}
            {userType === Constants.SUB_CONTRACTOR_USER ? (
              <Grid item lg={6} sm={12} xs={12}>
                <Typography sx={{ fontSize: 15 }}>Project</Typography>
                <SelectField
                  value={assignedProject}
                  onChange={handleChangeProjectBySubcontractor}
                >
                  {assignedProjectToSubcontractorList && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item, index) => (
                        <MenuItem key={index} value={item.projectId._id}>
                          {item?.projectId?.name}({item?.companyId?.name})
                        </MenuItem>
                      ))}
                </SelectField>
                {errObj.projectName ? <Typography sx={{color: 'red',fontSize: 12}}>{errObj.projectName}</Typography>:null}
                </Grid>
            ) : null}
                            <Grid item lg={12} sm={12} xs={12}>
                                <label htmlFor="profile2" className="cursor-pointer w-100">
                                    <input style={{ display: 'none' }}
                                        type="file"
                                        id="profile2"
                                        accept=".xlsx, .xls"
                                        onChange={handleChangeAttendanceDocument}
                                    />
                                     <Typography className="uploadimglabel">
                                        <AddIcon />
                                        <span style={{ display: 'block' }}>Upload Document</span>
                                    </Typography>
                                </label>
                                {errObj.document ? <Typography sx={{color: 'red',fontSize: 12}}>{errObj.document}</Typography>:null}

                           </Grid>
                            <Grid item lg={12} sm={12} xs={12}>
                                {isFilePicked ? (
                                        <div>
                                            <Typography sx={{m: 0, fontSize: '15px'}}>Filename: {attendanceFormData.document.name}</Typography>
                                           
                                            <Typography sx={{m: 0, fontSize: '15px'}}>Size in bytes: {attendanceFormData.document.size}</Typography>
                                          
                                        </div>
                                    ) : (
                                        <Typography className="text-center" sx={{m: 0, fontSize: '15px'}}>Upload attendance data file</Typography>
                                    )}
                                  
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12} sx={{ py: 2, textAlign: 'center' }}>
                                <Link to="/labour_sample_attendance_yyyy_mm_dd.xlsx" target="_blank">Download Sample Format</Link>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={closeDialouge} variant="contained" color="primary">
                        Close
                    </Button>
                    <Button autoFocus onClick={onSubmit} variant="contained" color="primary">
                    {loading?<CircularProgress color="inherit" size={20}/>:<>Submit</>}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )

}