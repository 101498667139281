import React,{useState,useEffect} from 'react';
import PropTypes from "prop-types";
import { Button, Dialog, DialogContent,DialogTitle,Grid,Typography, CircularProgress} from '@mui/material';
import {useFormik} from "formik";
import Input from "components/Input";
import * as yup from "yup";
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import { LabourActions } from 'slices/actions';
import { useDispatch, useSelector } from 'react-redux';
import { GeneralSelector } from 'selectors';

AddDiseaseForm.propTypes = {
   onOpen: PropTypes.bool,
   onClose: PropTypes.func,
   labourDetails: PropTypes.object
};

function AddDiseaseForm(props) {
    const {onOpen,onClose,labourDetails} = props
    const dispatch = useDispatch()
    const {id} = useParams();
    const loading = useSelector(GeneralSelector.loader(LabourActions.createLabourDiseaseReason.type));
    const [todayDate,setTodayDate] = useState();

    const validationSchema = yup.object({
        date: yup.string().required('This field is required'),
        diseaseName: yup.string().required('This field is required'),
        description: yup.string().required('This field is required'),
    });
    const formik = useFormik({
      initialValues: {
        date: "", 
        diseaseName: "",
        description: "",
      },
      enableReinitialize: true,
      validateOnChange: true,
      validationSchema: validationSchema,
      onSubmit: (values) => {
        handleSubmit(values);
      },
    });
    const handleSubmit = (values) => {
      const params = {
                ...values,
                companyId: labourDetails?.result?.companyId,
                projectId: labourDetails?.result?.projectId ,
                subContractorId: labourDetails?.result?.subContractorId ,
                labourId: id,
        };
      dispatch(LabourActions.createLabourDiseaseReason(params));
  }

  useEffect(() => {
    const tempDate = new Date();
    let day = tempDate.getDate();
    if (day < 10) {
        day = `0${day}`   
     }
    let month = tempDate.getMonth() + 1;
     if (month < 10) {
        month = `0${month}`   
     }
    const year = tempDate.getFullYear()  
    const date =`${year}-${month}-${day}`
     setTodayDate(date)
   },[])  

   const closeDialouge = () => {
    onClose();
    formik.setFieldValue("date","");
    formik.setFieldValue("diseaseName","");
    formik.setFieldValue("description","");
    }

return (                         
     <>
    <Dialog open={onOpen} close={closeDialouge}>
    <DialogTitle>
          <Typography> Health</Typography> 
          <CloseIcon sx={{float: 'right'}} onClick={closeDialouge}/>
    </DialogTitle>
    <DialogContent>
    
         <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  
                    <Grid item lg={12} xs={12}>
                        <Input
                            label="Date *"
                            type="date"
                            name='date'
                            inputProps={{
                              max: todayDate 
                            }}
                            value={formik.values.date}
                            onChange={formik.handleChange}
                            error={formik.touched.date && Boolean(formik.errors.date)}
                            helperText={formik.touched.date && formik.errors.date}
                        />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Input
                            label="Disease Name *"
                            name='diseaseName'
                            value={formik.values.diseaseName}
                            onChange={formik.handleChange}
                            error={formik.touched.diseaseName && Boolean(formik.errors.diseaseName)}
                            helperText={formik.touched.diseaseName && formik.errors.diseaseName}
                            />
                    </Grid>  
                    <Grid item lg={12} xs={12}>
                        <Input
                            label="Description *"
                            name='description'
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                            />
                    </Grid>                   
                        <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained">
                                {loading?(
                                   <CircularProgress color="inherit" size={20}/>
                                ):(
                                  <>Submit</>  
                                )}
                            </Button>
                        </Grid>
                </Grid>
            </form>
       
  
    </DialogContent>
 
</Dialog>

</>
  )
}

export default AddDiseaseForm