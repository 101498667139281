import axios from "axios";

// const API_URL ="http://103.112.26.98:5000/api";
// const API_URL ="http://103.112.26.98:5000/api";
const API_URL = process.env.REACT_APP_API_URL 
// const API_URL = "http://192.168.0.192:5000/api";


const Login = async (params) => axios.post(`${API_URL}/login`, params);

export const AuthService = {
    Login
};
