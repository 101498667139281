import React, { useState, useEffect } from 'react'
import SelectEducationType from './SelectEducationType';
import { Add, Delete, Edit } from '@mui/icons-material';
import { Typography, Card, Box, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Hidden } from '@mui/material';
import PropTypes from "prop-types";
import DialogConfirm from 'components/DialogConfirm';
import { useHistory, useParams } from 'react-router-dom';
import { GeneralActions, LabourActions } from 'slices/actions';
import { GeneralSelector, LabourSelector } from 'selectors';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import EducationForm from './EducationForm';

EducationListing.propTypes = {
  labourDetails: PropTypes.object,
};
function EducationListing(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { labourDetails } = props
  const [showEducationType, setShowEducationType] = useState(false);
  const [openEducationForm, setOpenEducationForm] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [educationtyperadio, setEducationtyperadio] = useState(false);
  const [selected, setSelected] = useState(null);
  const { id } = useParams();
  const success = useSelector(GeneralSelector.success(LabourActions.addLabourEducation.type));
  const error = useSelector(GeneralSelector.error(LabourActions.addLabourEducation.type));
  const deleteSuccess = useSelector(GeneralSelector.success(LabourActions.deleteEducationById.type));
  const deleteError = useSelector(GeneralSelector.error(LabourActions.deleteEducationById.type));
  const educationList = useSelector(LabourSelector.getEducationListById())
  useEffect(() => {
    if (id) {
      dispatch(LabourActions.getEducationListById(id))
    }
  }, [])

  const handleDelete = () => {
    const params = {
      id: selected,
      literacy: 1
    };
    dispatch(LabourActions.deleteEducationById(params));
  };

  useEffect(() => {
    if (deleteSuccess) {
      setConfirmDelete(false);
      setSelected(null);
      toast.success(`${deleteSuccess?.message ?? "Success"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });

      dispatch(GeneralActions.removeSuccess(LabourActions.deleteEducationById.type));
      dispatch(LabourActions.getEducationListById(id))

    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (deleteError) {
      toast.error(`${deleteError?.message ?? "Error"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });

      dispatch(GeneralActions.removeError(LabourActions.deleteEducationById.type));
    }
  }, [deleteError]);

  useEffect(() => {
    if (success) {
      setSelected(null);
      setOpenEducationForm(false);
      toast.success(`${success?.message ?? "Success"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });

      dispatch(GeneralActions.removeSuccess(LabourActions.addLabourEducation.type));
      dispatch(LabourActions.getEducationListById(id))

    }
  }, [success]);

  useEffect(() => {
    if (error) {
      setSelected(null);
      setOpenEducationForm(false)
      toast.error(`${error?.message ?? "Error"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });

      dispatch(GeneralActions.removeError(LabourActions.addLabourEducation.type));
    }
  }, [error]);

  const openDialog = () => {
    if (educationList.length !== 0) {
      setOpenEducationForm(true)
    } else {
      setShowEducationType(true)
      setEducationtyperadio(true)
    }
  }
  const closeEducationDialog = () => {
    setOpenEducationForm(false)
  }
  return (
    <Card sx={{ position: "relative" }}>
      <Typography variant="h5" sx={{ mb: 4, fontWeight: '500' }}>
        Education Listing
      </Typography>
      {labourDetails?.result?.status !== 99 ? (
        <Add
          onClick={openDialog}
          fontSize="large"
          sx={{
            position: "absolute",
            cursor: "pointer",
            right: "15px",
            top: "20px",
            color: "#3f7ed2",
          }}
        />) : null}
      <Box>
        <Table>
          {educationList && educationList.length !== 0 ? (
            <>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Passing Year</TableCell>
                  <TableCell>Institute</TableCell>
                  <TableCell>Options</TableCell>
                </TableRow>
              </TableHead>
            </>
          ) : (
            <>
              <Typography>Illiterate</Typography>
            </>
          )
          }
          <TableBody>
            {educationList && educationList.map((item, index) => (
              <TableRow key={index}>
                <TableCell align="left">{item.higherEducation}</TableCell>
                <TableCell align="left">{item.passingyear}</TableCell>
                <TableCell align="left">{item.institute}</TableCell>
                <TableCell align="left">
                  <Hidden smDown>
                    <IconButton
                      onClick={() => {
                        setConfirmDelete(true);
                        setSelected(item._id);
                      }}
                    >
                      <Delete fontSize="small" />

                    </IconButton>
                    <IconButton onClick={() => history.push(`/app/labour/educationDetails/${item._id}`)}>
                      <Edit className="text-primary" />
                    </IconButton>
                  </Hidden>
                </TableCell>
              </TableRow>

            ))}
          </TableBody>
        </Table>
      </Box>
      <SelectEducationType
        onOpen={showEducationType}
        onClose={() => setShowEducationType(false)}
        labourDetails={labourDetails}
        radiobutton={educationtyperadio}
      />
      <EducationForm
        onOpen={openEducationForm}
        onClose={closeEducationDialog}
        labourDetails={labourDetails} />

      <DialogConfirm
        title="Delete Data"
        content="Are you sure want to delete this data?"
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onSubmit={handleDelete} />
    </Card>
  )
}
export default EducationListing