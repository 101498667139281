import React,{useEffect,useState} from "react";
import {
    Card,
    Typography, Grid, MenuItem, Button,
    CircularProgress,
    Link,
} from "@mui/material";
import PageTitle from "components/PageTitle";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import { GeneralActions, LabourActions} from "slices/actions";
import {useFormik} from "formik";
import { GeneralSelector, LabourSelector } from "selectors";
import * as yup from "yup";
import Input from "components/Input";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import SelectField from "components/SelectField";
import { useHistory, useParams } from "react-router-dom";

export default function SafetyTraining() {
    const dispatch = useDispatch();
    const history = useHistory();
    const userId = localStorage.getItem("userID");
    const {id} = useParams();
    const [safetyTrainingStatus,setSafetyTrainingStatus] = useState();
    const labourTrainingType = useSelector(LabourSelector.getSafetyTrainingType());
    const labourDetails = useSelector(LabourSelector.getLabourById());
    const loading = useSelector(GeneralSelector.loader(LabourActions.createLabourSafetyTraining.type));
    const success = useSelector(GeneralSelector.success(LabourActions.createLabourSafetyTraining.type));
    const error = useSelector(GeneralSelector.error(LabourActions.createLabourSafetyTraining.type));
    const loadingUpdate = useSelector(GeneralSelector.loader(LabourActions.updateSafetyTraing.type));
    const successUpdate = useSelector(GeneralSelector.success(LabourActions.updateSafetyTraing.type));
    const errorUpdate = useSelector(GeneralSelector.error(LabourActions.updateSafetyTraing.type));
    const safetyTrainingDetails = useSelector(LabourSelector.getSafetyTraingDetails());
    const [startDateValue,setStartDateValue] = useState();

    useEffect(() => {
        dispatch(LabourActions.fetchLabourTrainingType())
        if (id) {
          dispatch(LabourActions.getSafetytraingDetails(id))
        } else {
          dispatch(LabourActions.getSafetytraingDetailsSuccess({}))
        }
    },[id])
    useEffect(() => {
        if (safetyTrainingDetails) {
            setSafetyTrainingStatus(safetyTrainingDetails?.status)
        }
    },[safetyTrainingDetails])
    const validationSchema = yup.object({
        trainingType: yup.string().required('This field is required'),
        trainingName: yup.string().required('This field is required'),
        startDate: yup.string().required('This field is required'),
        status: yup.string().required('This field is required'),
        
    });
    const formik = useFormik({
        initialValues: {
            labourID: labourDetails?.result?._id?? "", 
            trainingType: safetyTrainingDetails?.trainingType??"",
            trainingName: safetyTrainingDetails?.trainingName??"",
            startDate: safetyTrainingDetails?.startDate??"",
            endDate: safetyTrainingDetails?.endDate??"",
            status: safetyTrainingDetails?.status??"",
            validUpto: safetyTrainingDetails?.validUpto??"",
            added_by: userId,
            projectID: labourDetails?.result?.projectId ?? "",
            companyID: labourDetails?.result?.companyId ?? "",
            subcontractorID: labourDetails?.result?.subContractorId ?? "",
            document: safetyTrainingDetails?.document??"",
            remark: safetyTrainingDetails?.remark??"",
     },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });
    const handleSubmit = (values) => {
        if (id) {
            dispatch(LabourActions.updateSafetyTraing({
                ...values,
                id: id
            }));
        } else {
        dispatch(LabourActions.createLabourSafetyTraining(values));
        }
      }

    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(LabourActions.createLabourSafetyTraining.type));
            history.goBack();

        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeError(LabourActions.createLabourSafetyTraining.type));
        }
    }, [error]);
    useEffect(() => {
        if (successUpdate) {
            toast.success(`${successUpdate?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(LabourActions.updateSafetyTraing.type));
            history.goBack();

        }
    }, [successUpdate]);

    useEffect(() => {
        if (errorUpdate) {
            toast.error(`${errorUpdate?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeError(LabourActions.updateSafetyTraing.type));
        }
    }, [errorUpdate]);

   const handleChangeDocument = (e) => {
      const file = e.target.files[0];
      formik.setFieldValue('document', file);
    }
    const handleChangeStatus = (e) => {
      formik.setFieldValue('status', e.target.value);
      setSafetyTrainingStatus(e.target.value)
    }
    const handleChangeStartDate = (e) => {
      formik.setFieldValue('startDate', e.target.value);
      setStartDateValue(e.target.value)
     }
  
return (
        <>
            <PageTitle isBack={true} title="Employee Safety Training" />
            <Card>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item lg={6} xs={12}>
                            <Typography sx={{fontSize: 15}}>Training Type</Typography>
                            <SelectField
                                name='trainingType'
                                value={formik.values.trainingType}
                                onChange={formik.handleChange}
                                disabled={safetyTrainingDetails && safetyTrainingDetails.trainingType}
                                error={Boolean(formik.touched.trainingType) && Boolean(formik.errors.trainingType)}
                                helperText={formik.touched.trainingType && formik.errors.trainingType}
                            >
                                {labourTrainingType && labourTrainingType.masterTrainingSkillType && labourTrainingType.masterTrainingSkillType.map((item,index) => (
                                <MenuItem key={index} value={item.type}>
                                    {item.type}
                                </MenuItem>
                                ))}
                            </SelectField> 
                    </Grid>
                        <Grid item lg={6} xs={12}>
                            <Input
                                label="Training Name"
                                name='trainingName'
                                value={formik.values.trainingName}
                                onChange={formik.handleChange}
                                disabled={safetyTrainingDetails && safetyTrainingDetails.trainingName}
                                error={Boolean(formik.touched.trainingName) && Boolean(formik.errors.trainingName)}
                                helperText={formik.touched.trainingName && formik.errors.trainingName}
                                />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <Typography varient='h6' sx={{fontSize: '15px', color: '#333'}}>Status</Typography>
                            <SelectField 
                                name="status"
                                value={formik.values.status}
                                onChange={handleChangeStatus}
                                error={formik.touched.status && Boolean(formik.errors.status)}
                                helperText={formik.touched.status && formik.errors.status}
                            >
                                <MenuItem value={1}>Completed</MenuItem>
                                <MenuItem value={2}>Rejected</MenuItem>
                                <MenuItem value={0}>On Going</MenuItem>
                            </SelectField>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <Input
                                label="Start Date"
                                name='startDate'
                                type='date'
                                value={formik.values.startDate.slice(0,10)}
                                onChange={handleChangeStartDate}
                                error={Boolean(formik.touched.startDate) && Boolean(formik.errors.startDate)}
                                helperText={formik.touched.startDate && formik.errors.startDate}
                                />
                        </Grid>
                        {safetyTrainingStatus === 1 || safetyTrainingStatus === 0 ?(
                        <Grid item lg={6} xs={12}>
                            <Input
                                label="End Date"
                                name='endDate'
                                type='date'
                                inputProps={{
                                    min: startDateValue 
                                  }}
                                value={formik.values.endDate.slice(0,10)}
                                onChange={formik.handleChange}
                                error={Boolean(formik.touched.endDate) && Boolean(formik.errors.endDate)}
                                helperText={formik.touched.endDate && formik.errors.endDate}
                                />
                            </Grid>):null}
                        
                        <Grid item lg={6} xs={12}>
                            <Typography variant='' sx={{fontSize: '15px'}}>Remark</Typography>
                            <TextareaAutosize
                                style={{
                                textAlign: "left",
                                width: "100%",
                                height: "50px",
                                background: '#f1f1f1',
                                borderRadius: '8px',
                                border: '1px solid #f1f1f1',
                                padding: '5px'
                                }}
                                name="remark"
                                maxRows={4}
                                aria-label="maximum height"
                                placeholder="Remark Here.."
                                value={formik.values.remark}
                                onChange={formik.handleChange}
                                error={Boolean(formik.touched.remark) && Boolean(formik.errors.remark)}
                                helperText={formik.touched.remark && formik.errors.remark}
                            />
                        </Grid>
                        {safetyTrainingStatus === 1?(
                      
                        <Grid item lg={6} xs={12}>
                            <Input
                                label="Training Valid Up to"
                                name='validUpto'
                                type='date'
                                value={formik.values.validUpto.slice(0,10)}
                                onChange={formik.handleChange}
                                error={Boolean(formik.touched.validUpto) && Boolean(formik.errors.validUpto)}
                                helperText={formik.touched.validUpto && formik.errors.validUpto}
                                />
                        </Grid>):null}
                       
                        {safetyTrainingStatus === 1 ?(

                         <Grid item lg={6} xs={12}>
                            <Typography varient='h6' sx={{fontSize: '15px', color: '#333'}}>Upload Document</Typography>
                            <div className="cursor-pointer w-100 position-relative"
                                style={{background: '#f1f1f1', padding: '5px 10px', borderRadius: '5px'}}>
                                <Input
                                    type="file"
                                    id= "uploaddoc"
                                    onChange={handleChangeDocument}
                                    error={Boolean(formik.touched.uploaddoc) && Boolean(formik.errors.uploaddoc)}
                                    helperText={formik.touched.uploaddoc && formik.errors.uploaddoc}
                                />
                             </div>
                             {safetyTrainingDetails?.document?<Link href={safetyTrainingDetails.document} target='_blank'>View Document</Link>:null}
                          </Grid>):null}
                        <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                            {id?(
                               <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                >
                                {loadingUpdate?(
                                    <CircularProgress color="inherit" size={20}/>
                                ):( 
                                    <>Update</> 
                                )} 
                            </Button>
                            ):(
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                >
                                {loading?(
                                    <CircularProgress color="inherit" size={20}/>
                                ):( 
                                    <>Submit</> 
                                )} 
                            </Button>)}
                        </Grid>
                    </Grid>
                </form>
            </Card>
        </>
    )
}