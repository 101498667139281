import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menus, { formSubMenus, attendanceSubMenus, myattendanceSubMenus, labourSubMenus, reportSubmenu } from "./config/Menus";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "assets/ll.svg";
import { AccountCircle, Logout } from "@mui/icons-material";
import { Avatar, Menu, MenuItem, Tooltip, useMediaQuery, useTheme, Typography, ListItemButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { UserSelector } from "../selectors";
import { AuthActions, UserActions } from "../slices/actions";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import Can from "../utils/can";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}),);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    background: theme.palette.common.light,
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),

    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    })
}));

const LogoBox = styled(Box)(() => ({
    width: drawerWidth,
    paddingTop: 30,
    paddingBottom: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}));

const NavItem = styled(ListItem, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, active }) => ({
    // width: 220,
    // margin: "10px 0",
    // borderTopRightRadius: 40,
    // borderBottomRightRadius: 40,

    '& .MuiTypography-root': {
        color: theme.palette.common.grey
    },

    ...(active === 'true' && {
        background: theme.palette.primary.light,

        '& .MuiTypography-root': {
            fontWeight: 400,
            color: theme.palette.primary.main
        },

        '& svg': {
            color: theme.palette.primary.main,
        }
    })
}))

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'center',
}));

MainLayout.propTypes = {
    children: PropTypes.any
};

export default function MainLayout({ children }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const { pathname } = useLocation();
    const profile = useSelector(UserSelector.profile());
    const [open, setOpen] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const companyactive = localStorage.getItem('companyactive');
    const projectactive = localStorage.getItem('projectactive');
    const [openFormSubMenu, setOpenFormSubMenu] = useState(false);
    const [openAttendanceSubmenu, setOpenAttendanceSubmenu] = useState(false);
    const [openLabourSubmenu, setOpenLabourSubmenu] = useState(false);
    const [openReportSubmenu, setOpenReportSubmenu] = useState(false);
    const [openmyattendanceSubMenus, setOpenmyattendanceSubMenus] = useState(false);

    const openSubMenu = (index) => {
        if (Menus[index].name === "Form") {
            setOpenFormSubMenu(!openFormSubMenu)
        } else if (Menus[index].name === "Attendance") {
            setOpenAttendanceSubmenu(!openAttendanceSubmenu)
        } else if (Menus[index].name === "Labour") {
            setOpenLabourSubmenu(!openLabourSubmenu)
        } else if (Menus[index].name === "Report") {
            setOpenReportSubmenu(!openReportSubmenu)
        } else if (Menus[index].name === "My Attendance") {
            setOpenmyattendanceSubMenus(!openmyattendanceSubMenus)
        }
    };

    useEffect(() => {
        dispatch(UserActions.profileUser());
    }, [dispatch]);

    useEffect(() => {
        if (mobile) {
            setOpen(false);
        }
    }, [mobile]);

    useEffect(() => {
        if (profile !== null && !profile._id) {
            dispatch(push('/'));
        }
    }, [profile, dispatch]);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const handleMenuToggle = (e) => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget)
        }
    };

    const handleLogout = () => {
        dispatch(AuthActions.logout());
    };

    const goToForm = (index1) => {
        history.push(formSubMenus[index1].path)
    }
    const goToAttendance = (index2) => {
        history.push(attendanceSubMenus[index2].path)
    }
    const goToMyAttendance = (index2) => {
        history.push(myattendanceSubMenus[index2].path)
    }

    const goToLabour = (index3) => {
        history.push(labourSubMenus[index3].path)
    }
    const goToReport = (index4) => {
        history.push(reportSubmenu[index4].path)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                elevation={4}
                open={open}>
                <Toolbar sx={{ justifyContent: "space-between", position: 'relative' }}>
                    <IconButton
                        onClick={handleDrawerToggle}
                        edge="start">
                        <MenuIcon />
                    </IconButton>
                    <div style={{ position: 'absolute', right: '10%', color: '#737373' }}>
                        <Typography>{profile?.name}</Typography>
                    </div>
                    <Tooltip title="Account settings">
                        <IconButton onClick={handleMenuToggle} size="small" sx={{ ml: 2 }}>
                            {companyactive === "true" && projectactive === "true" ? (
                                <Avatar sx={{ width: 40, height: 40, bgcolor: "#46990f" }}>
                                    {profile?.name ? profile?.name?.toString().substring(0, 2).
                                        toUpperCase() : 'D'}
                                </Avatar>
                            ) : (
                                <Avatar sx={{ width: 40, height: 40, bgcolor: "#b02215" }}>
                                    {profile?.name ? profile?.name?.toString().substring(0, 2).
                                        toUpperCase() : 'D'}
                                </Avatar>)}
                        </IconButton>
                    </Tooltip>
                </Toolbar>

            </AppBar>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuToggle}
                onClick={handleMenuToggle}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 8px 24px rgba(149, 157, 165, 0.2))',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                <MenuItem
                    onClick={() => dispatch(push('/app/profile'))}
                >
                    <ListItemIcon>
                        <AccountCircle fontSize="small" />
                    </ListItemIcon>
                    Profile
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>

            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', borderRight: 'none' },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <LogoBox className="cursor-pointer">
                    {/* onClick={() => history.push("/app/dashboard")} */}
                    <Logo height={65} />
                </LogoBox>
                <List style={{ height: '99%', overflowY: 'auto' }}>
                    {Menus.map((item, index) => {
                        if (Can(item.feat)) {
                            return (
                                <NavItem className="MainMenu"
                                    key={index}
                                    active={pathname.includes(Menus[index].path).toString()}
                                >
                                    {item?.path ? (
                                        <ListItemButton onClick={() => history.push(Menus[index].path)}>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} sx={{ fontSize: '0.9rem' }} />
                                        </ListItemButton>
                                    ) : (
                                        <ListItemButton onClick={() => openSubMenu(index)}>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} sx={{ fontSize: '0.9rem' }} />
                                            {item.statusCode === "form" ? (<>
                                                {openFormSubMenu ? <ExpandLess /> : <ExpandMore />}</>) : null}
                                            {item.statusCode === "attendance" ? (<>
                                                {openAttendanceSubmenu ? <ExpandLess /> : <ExpandMore />}</>) : null}
                                            {item.statusCode === "labour" ? (<>
                                                {openAttendanceSubmenu ? <ExpandLess /> : <ExpandMore />}</>) : null}
                                            {item.statusCode === "report" ? (<>
                                                {openReportSubmenu ? <ExpandLess /> : <ExpandMore />}</>) : null}
                                            {item.statusCode === "myAttendance" ? (<>
                                                {openmyattendanceSubMenus ? <ExpandLess /> : <ExpandMore />}</>) : null}
                                        </ ListItemButton>
                                    )}

                                    {item.name === "Form" && openFormSubMenu && (
                                        <List component="div" disablePadding className="DropdownMenu">
                                            {formSubMenus.map((item1, index1) => (
                                                <NavItem
                                                    button
                                                    active={pathname.includes(formSubMenus[index1].path).toString()}
                                                    key={index1}
                                                >
                                                    <ListItemButton onClick={() => goToForm(index1)} >
                                                        <ListItemIcon>
                                                            {item1.icon}
                                                        </ListItemIcon>
                                                        <ListItemText primary={item1.name} sx={{ fontSize: '0.9rem' }} />
                                                    </ ListItemButton>

                                                </NavItem>
                                            ))}
                                        </List>
                                    )}

                                    {item.name === "Attendance" && openAttendanceSubmenu && (
                                        <List component="div" disablePadding className="DropdownMenu">
                                            {attendanceSubMenus.map((item2, index2) => (
                                                <NavItem
                                                    button
                                                    active={pathname.includes(attendanceSubMenus[index2].path).toString()}
                                                    key={index2}
                                                >
                                                    <ListItemButton onClick={() => goToAttendance(index2)} >
                                                        <ListItemIcon>
                                                            {item2.icon}
                                                        </ListItemIcon>
                                                        <ListItemText primary={item2.name} sx={{ fontSize: '0.9rem' }} />
                                                    </ ListItemButton>

                                                </NavItem>
                                            ))}
                                        </List>
                                    )}

                                    {item.name === "My Attendance" && openmyattendanceSubMenus && (
                                        <List component="div" disablePadding className="DropdownMenu">
                                            {myattendanceSubMenus.map((item2, index2) => (
                                                <NavItem
                                                    button
                                                    active={pathname.includes(myattendanceSubMenus[index2].path).toString()}
                                                    key={index2}
                                                >
                                                    <ListItemButton onClick={() => goToMyAttendance(index2)} >
                                                        <ListItemIcon>
                                                            {item2.icon}
                                                        </ListItemIcon>
                                                        <ListItemText primary={item2.name} sx={{ fontSize: '0.9rem' }} />
                                                    </ ListItemButton>

                                                </NavItem>
                                            ))}
                                        </List>
                                    )}

                                    {item.name === "Labour" && openLabourSubmenu && (
                                        <List component="div" disablePadding className="DropdownMenu">
                                            {labourSubMenus.map((item3, index3) => (
                                                <NavItem
                                                    button
                                                    active={pathname.includes(labourSubMenus[index3].path).toString()}
                                                    key={index3}
                                                >
                                                    <ListItemButton onClick={() => goToLabour(index3)} >
                                                        <ListItemIcon>
                                                            {item3.icon}
                                                        </ListItemIcon>
                                                        <ListItemText primary={item3.name} sx={{ fontSize: '0.9rem' }} />
                                                    </ ListItemButton>

                                                </NavItem>
                                            ))}
                                        </List>
                                    )}
                                    {item.name === "Report" && openReportSubmenu && (
                                        <List component="div" disablePadding className="DropdownMenu">
                                            {reportSubmenu.map((item4, index4) => (
                                                <NavItem
                                                    button
                                                    active={pathname.includes(reportSubmenu[index4].path).toString()}
                                                    key={index4}
                                                >
                                                    <ListItemButton onClick={() => goToReport(index4)} >
                                                        <ListItemIcon>
                                                            {item4.icon}
                                                        </ListItemIcon>
                                                        <ListItemText primary={item4.name} sx={{ fontSize: '0.9rem' }} />
                                                    </ ListItemButton>

                                                </NavItem>
                                            ))}
                                        </List>
                                    )}


                                </NavItem>
                            )
                        }

                        return <div key={index} />
                    })}
                </List>
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
                {children}
            </Main>
        </Box>
    );
}
