import React from 'react';
import FormIndex from '../FormIndex';


export default function ExitLaborList() {
  return (
    <>
    <FormIndex 
      formData={"exitLaborList"}
      title = {"Exit Labor List"}
    />
</>
    )
}