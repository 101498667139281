import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Chip,
  Table,
  TableBody,
  TableCell,
  Grid,
  TableHead,
  TableRow,
  MenuItem,
  IconButton,
  Pagination,
  
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ListSkeleton from "../../components/Skeleton/ListSkeleton";
import FloatingButton from "components/FloatingButton";
import { Delete, Edit } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DialogConfirm from "components/DialogConfirm";
import {
  GeneralActions,
  MasterDataActions,
  SubContractorActions,
} from "slices/actions";
import { GeneralSelector, MasterDataSelector, SubContractorSelector } from "selectors";
import { useHistory } from "react-router-dom";
import SelectField from "components/SelectField";
import Input from "components/Input";
import styled from "@emotion/styled";
import { DefaultSort } from "constants/sort";
// import UploadFileSharpIcon from "@mui/icons-material/UploadFileSharp";
import SubContractorUploadDialog from "./SubContractorUploadDialog";
// import { Constants } from "utils/constants";

const FilterBox = styled(Box)(() => ({
  width: "100%",
  marginTop: 30,
  marginBottom: 20,
  display: "flex",
  justifyContent: "space-between",
}));

function SubContractor() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const projectId = localStorage.getItem('projectID');
  const comapanyId = localStorage.getItem('companyID');
  const natureOfWorkList = useSelector(MasterDataSelector.getNatureOfWork());
  const success = useSelector(GeneralSelector.success(SubContractorActions.deleteSubContractor.type));
  const [filter, setFilter] = useState({
    sort: DefaultSort.newest.value,
    page: 1,
  });
  const [openIdDialog, setOpenIdDialog] = useState(false);
  const pagination = useSelector(SubContractorSelector.assingSubcontractorpagination());
  const subcontractorList = useSelector(SubContractorSelector.getSubContractorList());
  const loading = useSelector(GeneralSelector.loader(SubContractorActions.getSubContractorList.type));
  const role = useSelector(SubContractorSelector.role());
  const [natureOfWorkData,setNatureOfWorkData] = useState();
  
  const handleChangeFilter = ({ target }) => {
    const { name, value } = target;
    setFilter({
      ...filter,
      [name]: value,
    });
  };

const handleChangePagination = (e, val) => {
    setFilter({
        ...filter,
        page: val
    });
};
useEffect(() => {
  dispatch(MasterDataActions.getNatureOfWork());
},[])
useEffect(() => {
    const params = {
      ...filter,
      company_id: comapanyId?comapanyId:"",
      project_id: projectId?projectId:"",
      is_subcontractor_user: true
    }
    dispatch(SubContractorActions.getSubContractorList(params));
  }, [filter]);

  useEffect(() => {
    if (success) {
      setConfirmDelete(false);
      setSelected(null);

      toast.success(`${success?.message ?? "Success"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });

      dispatch(GeneralActions.removeSuccess(SubContractorActions.deleteSubContractor.type));
      dispatch(SubContractorActions.getSubContractorList(filter)); 
      const params = {
        ...filter,
           is_subcontractor_user: true
      }
      dispatch(SubContractorActions.getSubContractorList(params));
    }
  }, [success]);

  const handleDelete = () => {
    dispatch(SubContractorActions.deleteSubContractor(selected));
  };
  const changeNatureOfWork = (e) => {
    setNatureOfWorkData(e.target.value)
    const params = {
    ...filter,
    company_id: comapanyId?comapanyId:"",
    project_id: projectId?projectId:"",
    is_subcontractor_user: true,
    natureOfWork: e.target.value
  }
  dispatch(SubContractorActions.getSubContractorList(params));
  }

return (
  <>
    <Card>
      <Typography variant="h5" sx={{ fontWeight: 600, position: "relative" }}>
        Subcontractor
        {/* {userType === Constants.SUPERADMIN?(
        <UploadFileSharpIcon
          className="Badgeico cursor-pointer"
          onClick={() => setOpenIdDialog(true)}
          color="primary"
        />):null} */}
      </Typography>
      <FilterBox>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item lg={6} sm={12} xs={12}>
            <Input
              label="Search"
              name="keyword"
              placeholder="Search by name"
              value={filter.keyword ?? ""}
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item lg={2} sm={12} xs={12}>
            <Typography sx={{fontSize: 15}}>Sort by</Typography>
            <SelectField
              value={filter.sort}
              name="sort"
              onChange={handleChangeFilter}
            >
              {Object.keys(DefaultSort).map((key) => (
                <MenuItem key={key} value={DefaultSort[key].value}>
                  {DefaultSort[key].name}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Typography variant='h5' sx={{color: '#000', fontSize: 15, opacity: 'rgba(0, 0, 0, 0.87)'}}>Nature Of Work</Typography>
                    <SelectField
                        value={natureOfWorkData}
                        onChange={changeNatureOfWork}
                       >
                        {natureOfWorkList.subcontractorScopesofWork && natureOfWorkList.subcontractorScopesofWork.map((item,index) => (
                            <MenuItem key={index} value={item.id}>
                                {item.level}
                            </MenuItem>
                        ))}
                    </SelectField>
            </Grid>
        </Grid>
      </FilterBox>

      {loading ? (
        <ListSkeleton />
      ) : (
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Nature of work</TableCell>
                  <TableCell align="center">Status</TableCell>
                <TableCell align="right">Options</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {subcontractorList && subcontractorList.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    No SubContractor Found
                  </TableCell>
                </TableRow>
              )} */}
              {subcontractorList && subcontractorList.map((item, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",

                        "& img": {
                          borderRadius: 50,
                        },
                      }}
                    >
                      <Typography sx={{ ml: 2 }} variant="subtitle2">
                        {item.subContractorName}
                      </Typography>
                    </Box>
                  </TableCell>
                    <TableCell>{item.subContractorPhone}</TableCell>
                    <TableCell>{item.natureOfWork}</TableCell>

                    <TableCell align="center">
                      <Chip
                        label={item.status === 2 ? "Inactive" : "Active"}
                        color={item.status === 2 ? "default" : "primary"}
                        size="small"
                      />
                    </TableCell>
                  <TableCell align="right" width='18%'>
                     {role && Object.keys(role).map((item1,index) => (
                      <div key={index} style={{display: 'inline-block'}}>
                      {item1 === "Details"?(
                      <IconButton
                        onClick={() => history.push(`/app/subcontractor/details/${item._id}`)}
                      >
                        <Edit className="text-primary" />
                      </IconButton>):null}
                      {item1 === "Delete"?(
                      <IconButton
                        onClick={() => {
                          setConfirmDelete(true);
                          setSelected(item._id);
                        }}
                      >
                        <Delete className="text-default" />
                      </IconButton>
                      ):null}
                      </div>
                      ))}
                     
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Pagination
                  sx={{ mt: 1 }}
                  page={filter.page}
                  count={pagination && pagination.pages}
                  onChange={handleChangePagination}/>
        </Box>
      )}
      {role && Object.keys(role).map((item2,index) => (
      <div key={index}>
       {item2 === "Add"?(
      <FloatingButton
        onClick={() => history.push("/app/subcontractor/Create")}
      />):null}
      </div>
      ))}

      <DialogConfirm
        title="Delete Subcontractor"
        content="Are you sure want to delete this subcontractor data?"
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onSubmit={handleDelete}
      />
      <SubContractorUploadDialog
        open={openIdDialog}
        onClose={() => setOpenIdDialog(false)}
      />
    </Card>
  </>
  );
}

export default SubContractor;

