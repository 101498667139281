import { get, post } from "../utils/api";

const API_URL = process.env.REACT_APP_API_URL 

const PaymentStatus = async (params) => post(`${API_URL}/project/updateTransactionStatus`,params);

const TransactionById = async (project_id) => get(`${API_URL}/project/transactionByProjectID/${project_id}`);

const AllTransactionList = async (params) => get(`${API_URL}/project/getTransactions`,params)

export const TransactionService = {
    PaymentStatus,
    TransactionById,
    AllTransactionList
 };