import React from "react";
import {
    Card,
    Grid,
    Typography,
} from "@mui/material";
import Input from "components/Input";
import PropTypes from "prop-types";
import { SubContractorSelector } from "selectors";
import { useSelector } from "react-redux";

PFDetails.propTypes = {
    formik: PropTypes.object
};

export default function PFDetails(props) {
    const { formik } = props;
    const subcontractorDetails = useSelector(SubContractorSelector.getDataByPancard());

    return (
        <Card sx={{ mb: 3 }}>
            <Typography variant='h5' sx={{ mb: 4 }}>PF Details</Typography>
            <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Scope of PF Registration"
                        name='scopeOfPfRegistration'
                        value={formik.values.scopeOfPfRegistration}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.scopeOfPfRegistration) && Boolean(formik.errors.scopeOfPfRegistration)}
                        helperText={formik.touched.scopeOfPfRegistration && formik.errors.scopeOfPfRegistration}
                        disabled={subcontractorDetails && subcontractorDetails.userData && subcontractorDetails.userData.scopeOfPfRegistration }
                        />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="PF Registration No"
                        name='pfRegistrationNo'
                        value={formik.values.pfRegistrationNo}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.pfRegistrationNo) && Boolean(formik.errors.pfRegistrationNo)}
                        helperText={formik.touched.pfRegistrationNo && formik.errors.pfRegistrationNo}
                        disabled={subcontractorDetails && subcontractorDetails.userData && subcontractorDetails.userData.pfRegistrationNo }
                    />
                </Grid>
                
            </Grid>
        </Card>
    )
}