import React, {useEffect} from "react";
import {Box, Button, Card, Grid} from "@mui/material";
import PageTitle from "components/PageTitle";
import * as yup from "yup";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {useParams,useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import Input from "components/Input";
import FormSkeleton from "../../components/Skeleton/FormSkeleton";
import { StateActions ,GeneralActions} from "slices/actions";
import { StateSelector,GeneralSelector } from "selectors";

export default function ManageState() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const state = useSelector(StateSelector.getStateById());
    const loading = useSelector(GeneralSelector.loader(StateActions.getStateById.type));
    const error = useSelector(GeneralSelector.error(StateActions.createState.type));

    const actions = [
        StateActions.createState.type,
        StateActions.updateState.type
    ];
    const success = useSelector(GeneralSelector.success(actions));
    useEffect(() => {
        if (id) {
            dispatch(StateActions.getStateById(id));
        }
    }, []);
   
    useEffect(() => {
        if (success.length > 0) {
            const action = success.find(item => actions.includes(item.action));

            toast.success(`${action?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                    pauseOnHover: false,
                    pauseOnFocusLoss: false
                });

            dispatch(GeneralActions.removeSuccess(actions));
            history.push('/app/state')
        } 
    }, [success]);
    useEffect(() => {
    if (error) {
         toast.error(`${error?.message ?? "Error"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: false,
                pauseOnFocusLoss: false,
            });
        } 

    },[error])
    
    const validationSchema = yup.object({
        name: yup.
            string().
            required('State name is required')
    });
    const formik = useFormik({
        initialValues: {
             name: state?.name ?? "",
             country_id: "6278e8348e89d175a843ae9d"
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });
    const handleSubmit = (params) => {
        if (id) {
            dispatch(StateActions.updateState({
                ...params,
                id
            }));
        } else {
            dispatch(StateActions.createState(params));
        }
    };
    return (
        <Box>
         
        <PageTitle isBack={true} title={`${id ? "Update" : "Add"} State`}/>

        {loading ? (
            <FormSkeleton/>
        ) : (
            <Grid container justifyContent="center">
                <Grid item lg={6} sm={12} xs={12}>
                    <Card>
                        <form onSubmit={formik.handleSubmit}>
                            <Input
                                fullWidth
                                label="Name"
                                sx={{
                                    mb: 2
                                }}
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}/>
                             <Grid container justifyContent="flex-end">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit">
                                    Submit
                                </Button>
                            </Grid>
                        </form>
                    </Card>
                </Grid>
            </Grid>
        )}
    </Box>
    )
}