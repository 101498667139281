import React ,{useState,useEffect} from "react";
import {
    Box, Card, Grid, IconButton, Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useHistory} from "react-router-dom";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PropTypes from "prop-types";
import PersonIcon from '@mui/icons-material/Person';
import AttractionsIcon from '@mui/icons-material/Attractions';
import BusinessIcon from '@mui/icons-material/Business';
import { GeneralActions, ProjectActions } from "slices/actions";
import DialogConfirm from "components/DialogConfirm";
import { useDispatch,useSelector } from "react-redux";
import { GeneralSelector } from "selectors";
import {toast} from "react-toastify";
// import { Delete } from "@mui/icons-material";


CompanyProjectListing.propTypes = {
    companyDetails: PropTypes.object,
};
function CompanyProjectListing(props) {
    const { companyDetails } = props;
    const history = useHistory();
    const [selected, setSelected] = useState(null);
    const [confirmDelete,setConfirmDelete] = useState(false); 
    const success = useSelector(GeneralSelector.success(ProjectActions.deleteProject.type));
    const dispatch = useDispatch(); 

    const goToProjectUser = (id) => {
        localStorage.setItem('selectedProjectSubMenuId','account')
        history.push(`/app/project/update/${id}`)
    }
    const goToProjectSubcontractor = (id) => {
        localStorage.setItem('selectedProjectSubMenuId','subcontractor')
        history.push(`/app/project/update/${id}`)
    }
    const goToProjectSites = (id) => {
        localStorage.setItem('selectedProjectSubMenuId','site')
        history.push(`/app/project/update/${id}`)
    }
    useEffect(() => {
        if (success) {
            setConfirmDelete(false);
            setSelected(null);

            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 4000,
                    closeOnClick: true
                });

            dispatch(GeneralActions.removeSuccess(ProjectActions.deleteProject.type));
            dispatch(ProjectActions.getProjectList());
        }
    }, [success]);

    const handleDelete = () => {
        dispatch(ProjectActions.deleteProject(selected));
      };
    // const confirmDeleteProject = (id) => {
    //    setConfirmDelete(true);
    //    setSelected(id); 
    // }  

return (
  <Card>
    <div className="position-relative">
        
        <Typography variant='h5' sx={{display: 'inline-block', fontWeight: 'bold'}}>Projects</Typography>
        <AddCircleOutlineIcon  
        onClick={() => history.push("/app/project/create/New")}
        fontSize= "large" sx={{color: "#3f7ed2", float: "right", cursor: "pointer"}}
        />
    </div>
      <Box>
        <Grid container spacing={3} sx={{ my: 3 }}>
                {companyDetails.projects.map((item,index) => (
                <Grid item lg={6} sm={12} xs={12} key={index}>
                <Card className="projectcard text-center position-relative" onClick={() => history.push(`/app/project/update/${item._id}`)}>
                    <img src={`${process.env.REACT_APP_URL}/img/corner-2.png`} style={{position: "absolute", right: "0", top: "0", width: "", height: "100%", borderTopRightRadius: ".375rem", borderBottomRightRadius: ".375rem", objectFit: "cover"}} />
                    {/* <p className="userDelete"> */}
                    {/* <Typography className="userDelete">
                     <IconButton onClick={() => confirmDeleteProject(item._id)}>
                     <Delete />
                    </IconButton>
                    </Typography> */}
                    {/* </p> */}
                  <p className="userstatus bg-success"></p>
             
                    <Typography variant="h6" style={{fontSize: "18px", marginTop: "10px"}}>{item.code}</Typography>

                    <Typography variant="h6" style={{fontSize: "18px", marginTop: "1px"}}>{item.name}</Typography>
                    <p style={{fontSize: "14px", margin: "0"}}>{item.city},{item.state}</p>
                    <Typography sx={{mt: 2}}>
                        <Tooltip title="User">
                            <IconButton onClick={() => goToProjectUser(item._id)}>
                                <PersonIcon />
                                <span className="ForCounter">{item.users.length}</span>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Sub-Contractor">
                            <IconButton onClick={() => goToProjectSubcontractor(item._id)}>
                                <AttractionsIcon />
                                <span className="ForCounter">{item.subContractorId.length}</span>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Sites">
                            <IconButton onClick={() => goToProjectSites(item._id)}>
                                <BusinessIcon />
                                <span className="ForCounter">{item.sites.length}</span>
                            </IconButton>
                        </Tooltip>
                    </Typography>
                </Card>
            </Grid>
              ))}
        </Grid>
      </Box>
      <DialogConfirm
        title="Delete Data"
        content="Are you sure want to delete this project?"
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onSubmit={handleDelete}/>
    
  </Card>
);
}

export default CompanyProjectListing;