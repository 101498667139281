import React,{useEffect,useState} from "react";
import {
    Box,
    Button, Typography,
    MenuItem,
    Grid,
    CircularProgress,
    FormControlLabel
} from "@mui/material";
import SelectField from "components/SelectField";
import Checkbox from '@mui/material/Checkbox';
import PropTypes from "prop-types";
import { GeneralSelector, LabourSelector } from "selectors";
import { GeneralActions, LabourActions } from "slices/actions";
import * as yup from "yup";
import {useFormik} from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {toast} from "react-toastify";
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';

DisabilityDetails.propTypes = {
    labourDetails: PropTypes.object
};
// const disabilityDetailsData = []
export default function DisabilityDetails(props) {
    const {id} = useParams();
    const { labourDetails } = props;
    const dispatch = useDispatch();
    const success = useSelector(GeneralSelector.success(LabourActions.updateLabourPFBasicInfo.type));
    const loading = useSelector(GeneralSelector.loader(LabourActions.updateLabourPFBasicInfo.type));
    const error = useSelector(GeneralSelector.error(LabourActions.updateLabourPFBasicInfo.type));
    const [showDisabilityDetailsField,setShowDisabilityDetailsField] = useState(false);
    const [disabilityDetailsData,setDisabilityDetailsData] = useState([]);
    const disabilityListing = useSelector(LabourSelector.getDisabilityMasterData())
    const validationSchema = yup.object({
        pfDifferentlyable: yup.string().required('This field is required'),
     });
    useEffect(() => {
        // if (labourDetails && labourDetails.result) {
        //     setDisabilityDetailsData(labourDetails.result.pfDifferentlyableList)
            if (labourDetails?.result?.pfDifferentlyable) {
                setShowDisabilityDetailsField(true)
                dispatch(LabourActions.getDisabilityMasterData())
            }
        //  }
     },[labourDetails])
    const formik = useFormik({
        initialValues: {
            flag: 'PFDifferentlyabled',
            pfDifferentlyable: labourDetails?.result?.pfDifferentlyable??"",
          },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
           handleSubmit(values);
        }
    });
    const handleSubmit = (values) => {
        const params = {
            id: id,
            pfDifferentlyableList: disabilityDetailsData,
          ...values,
      };
      dispatch(LabourActions.updateLabourPFBasicInfo(params));
   }
     useEffect(() => {
    if (success) {
        toast.success(`${success?.message ?? "Success"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });

        dispatch(GeneralActions.removeSuccess(LabourActions.updateLabourPFBasicInfo.type));
        dispatch(LabourActions.getLabourById(id))
    }
  }, [success]);

useEffect(() => {
    if (error) {
        toast.error(`${error?.message ?? "Error"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });

        dispatch(GeneralActions.removeError(LabourActions.updateLabourPFBasicInfo.type));
    }
}, [error]);

const handleChangeValue = (e) => {
    const value = e.target.value
    if (value === "N") {
        formik.setFieldValue("pfDifferentlyable",value)
        setShowDisabilityDetailsField(false)
    } else if (value === "Y") {
        formik.setFieldValue("pfDifferentlyable",value)
        dispatch(LabourActions.getDisabilityMasterData())
        setShowDisabilityDetailsField(true)
    }
}
const handleChangeDisabilityValue = (e,index) => {
     if (e.target.checked === true && Boolean(disabilityDetailsData && disabilityDetailsData.map((item) => item !== disabilityListing.masterLabourDisability[index].Value))) {
        setDisabilityDetailsData([...disabilityDetailsData,disabilityListing.masterLabourDisability[index].Value])
    } else {
        const newArray = disabilityDetailsData.filter(e => e !== disabilityListing.masterLabourDisability[index].Value)
        setDisabilityDetailsData(newArray)

      }
    }

    return (
        <Box>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                    <Typography variant="h6" sx={{fontSize: "15px", fontWeight: "normal"}}>Differently Abled</Typography>
                        <SelectField
                            className="text-left"
                            name="pfDifferentlyable"
                            value={formik.values.pfDifferentlyable}
                            onChange={handleChangeValue} 
                            error={formik.touched.pfDifferentlyable && Boolean(formik.errors.pfDifferentlyable)}
                            helperText={formik.touched.pfDifferentlyable && formik.errors.pfDifferentlyable}
                            >
                             <MenuItem value={"Y"}>
                                 Y
                             </MenuItem>
                             <MenuItem value={"N"}>
                                 N
                             </MenuItem>
                          
                        </SelectField>
                    </Grid>
                    {labourDetails?.result?.pfDifferentlyableList.map((item,index) => (
                       <div key={index}>
                        <Typography>{item}</Typography><br/> 
                       </div> 
                    ))}
                    {showDisabilityDetailsField ?(
                     <Grid item lg={6} xs={12}>
                        <FormControl>
                            <FormLabel sx={{fontWeight: "bold", color: "#000"}}>If Yes *</FormLabel>
                            <FormGroup>
                            {disabilityListing && disabilityListing.masterLabourDisability && disabilityListing.masterLabourDisability.map((item,index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                    <Checkbox onChange={(e) => handleChangeDisabilityValue(e,index)}/>
                                    }
                                    label={item.Value}
                                />
                            ))}
                            </FormGroup>
                        </FormControl>
                    </Grid>):null}
                  {labourDetails?.result?.status !== 99?(
                    <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained">
                          {loading?(
                                   <CircularProgress color="inherit" size={20}/>
                                ):(
                                  <>Update</>  
                                )}
                        </Button>
                    </Grid>):null}
                </Grid>
            </form>
        </Box>
    )
}