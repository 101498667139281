import React, {useEffect,useState} from "react";
import {
    Button, Card, Box,
    FormControl, 
    Grid,
    Typography, 
    Switch,
    MenuItem,
    // MenuItem,
} from "@mui/material";
import styled from "@emotion/styled";
import {useFormik} from "formik";
import * as yup from "yup";
import {toast} from "react-toastify";
import {useDispatch,useSelector} from "react-redux";
import Input from "components/Input";
import {DesignationActions, GeneralActions, MasterDataActions, ProjectActions, UserActions} from "slices/actions";
import { UserSelector,GeneralSelector, ProjectSelector, DesignationSelector } from "selectors";
import { useParams,useHistory } from "react-router-dom";
import PageTitle from "components/PageTitle";
import PasswordChange from "components/PasswordChangeDialog";
import DialogConfirm from "components/DialogConfirm";
import SelectField from "components/SelectField";

const SwitchBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
}));

export default function ProjectUserDetails() {
    const history = useHistory();
    const dispatch = useDispatch();
    const {id} = useParams();
    const projectUserDetails = useSelector(UserSelector.getUserById());
    const success = useSelector(GeneralSelector.success(UserActions.updateUser.type));
    const error = useSelector(GeneralSelector.error(UserActions.updateUser.type));
    const [changePwd,setChangePwd] = useState(false);
    const [statusValue,setStatusValue] = useState(false);
    const [statusCheck,setStatusCheck] = useState(false);
    const [openDialog,setOpenDialog] = useState(false);
    const projectDetails = useSelector(ProjectSelector.getProjectById());
    const designations = useSelector(DesignationSelector.getDesignations());

    useEffect(() => {
    if (projectDetails) {
        const params = {
            companyId: projectDetails.data?.project?._id ,
            projectId: projectDetails.data?.project?.company_id
        }
        dispatch(DesignationActions.getDesignations(params)); 
    }
    },[projectDetails])

    useEffect(() => {
        if (projectUserDetails && projectUserDetails.projectId && projectUserDetails.projectId.status === 1) {
            setStatusValue(true)  
        }
        dispatch(UserActions.getUserById(id));
        dispatch(DesignationActions.getDesignations());
    },[])

    const validationSchema = yup.object({
        name: yup.string().required('userName is required'),
        email: yup.string().email().
        required("Email is required"),
        // role: yup.array().required("Role is required"),
        role: yup.string().required("Role is required"),
        phone: yup.string().min(10).
        max(10).
        matches(/^[0-9\b]+$/,"Invalid Phone Number").
        required('Phone Number is required'),

    });

    const formik = useFormik({
        initialValues: {
            name: projectUserDetails?.name ?? "",
            email: projectUserDetails?.email ?? "",
            // role: projectUserDetails?.role ?? [],
            role: projectUserDetails?.designation?._id ?? "",
            phone: projectUserDetails?.phone??"",
            id: id ? id:" "
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });
    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(UserActions.updateUser.type));
            dispatch(ProjectActions.getProjectById(id));
            history.goBack();
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeError(UserActions.updateUser.type));
          }
    }, [error]);


    const handleSubmit = (values) => {
         dispatch(UserActions.updateUser(values));
        }
    const changePassword = () => {
        setChangePwd(false)
    }
 
    const changeProjectStatus = (e) => {
        const { checked } = e.target;
        setOpenDialog(true);
        setStatusCheck(checked);
    }
    const handleToggleStatus = () => {
        setStatusValue(statusCheck)
        const params = {
            id: id,
            status: statusCheck,
            flag: "Users"
        }
        dispatch(MasterDataActions.changeStatus(params))
        setOpenDialog(false)
    }; 

    return (
        <Card>
            <PageTitle isBack={true} title={projectUserDetails.name?projectUserDetails.name:""} />
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="User Name"
                            name='name'
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.name) && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}/>
                    </Grid>
                 
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Email Id"
                            type="email"
                            name='email'
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.email) && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                            <Typography variant='' sx={{fontSize: "15px"}}>Phone Number</Typography>
                            <Box sx={{
                                display: 'flex',
                                gap: 1.5
                            }}>
                                <Box sx={{ width: 90 }}>
                                    <Input style={{appearence: 'none'}}
                                        sx={{
                                            padding: "10px 8px",
                                            textAlign: 'center',
                                            // '& .Mui-disabled': {
                                            //     fillColor: theme.palette.common.black
                                            // }
                                        }}
                                        autoComplete='new-password'
                                        name='phoneCode'
                                        startAdornment='+'
                                        value="91"
                                        />
                                </Box>
                                <Input
                                    name='phone'    
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    helperText={formik.touched.phone && formik.errors.phone}/>
                            </Box>
                        </FormControl>
                       
                    </Grid>
                    {!projectUserDetails.is_project_admin?(
                    <Grid item lg={6} xs={12}>
                    <Typography variant='h6' sx={{fontSize: 15}}>Role</Typography>
                        <SelectField
                        value={formik.values.role}
                        name='role'
                        onChange={formik.handleChange}
                        error={formik.touched.role && Boolean(formik.errors.role)}
                        helperText={formik.touched.role && formik.errors.role}
                        >
                          {designations && designations.map((item,index) => (
                            <MenuItem key={index} value={item._id}>
                                {item.name}
                            </MenuItem>
                            ))}
                        </SelectField>
                    </Grid>):null}
                    <Grid item lg={4} xs={12}>
                        <SwitchBox sx={{pt: 3, px: 2}}>
                            <Typography>{statusValue?"Disable User":"Enable User"}</Typography>
                            <Switch
                                checked={statusValue}
                                name="status"
                                onChange={changeProjectStatus}
                            />
                        </SwitchBox>
                  
                </Grid>
                    <Grid sx={{ mt: 3 }} item lg={8} xs={12} style={{textAlign: 'left'}}>
                       <Typography onClick = {() => {
                           setChangePwd(true)
                       }} sx={{color: "#2c7be5",fontSize: 14, mt: 2, cursor: 'pointer', fontWeight: 'bold'}}>Change Password</Typography>
                    </Grid>
                    <Grid sx={{ mt: 3, textAlign: 'right'}} item lg={12} xs={12} >
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained">
                            Update
                        </Button>
                    </Grid>
                 
                </Grid>
            </form>
            <PasswordChange
                 title="Change Password"
                 open={changePwd}
                 onClose={changePassword}
                 id={projectUserDetails?._id}
                />
            
            <DialogConfirm
               title="Change Status"
               content={statusValue?"Are you sure want disable User?":"Are you sure want Enable User?"}
               open={openDialog}
               onClose={() => setOpenDialog(false)}
               onSubmit={handleToggleStatus} 
            />
        </Card>
    )
}