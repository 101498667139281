export const actions = {
  readAll: "read_all",
  readSome: "read_some",
  readSelf: "read_self",
  read: "read",
  create: "create",
  update: "update",
  delete: "delete",
};

export const features = {
  user: "user",
  Attendance: "Attendance",
  myAttendance: "myAttendance",
  Department: "Department",
  state: "state",
  city: "city",
  designation: "designation",
  company: "company",
  project: "project",
  subcontractor: "subcontractor",
  Site: "Site",
  labour: "labour",
  Assign: "Assign",
  Dashboard: "Dashboard",
  GstCompliance: "GstCompliance",
  LabourCompliance: "LabourCompliance",
  FormA: "FormA",
  FormB: "FormB",
  FormmA: "FormmA",
  FormmB: "FormmB",
  FormC: "FormC",
  FormD: "FormD",
  PfCalculation: "PfCalculation",
  safetyTrainingList: "safetyTrainingList",
  pfCompliance: "pfCompliance",
  holidayList: "holidayList",
  ecrChalan: "ecrChalan",
  LabourExit: "LabourExit",
  linkUan: "linkUan",
  Report: "Report",
  TransactionList: "TransactionList"
};
