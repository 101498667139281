import React, {useEffect, useState} from "react";
import {
    Box, Card, Chip, Table, TableBody, TableCell, Grid, 
     Pagination,
     TableHead, TableRow,
     MenuItem,
     Link,
     IconButton
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {GeneralSelector, ProjectSelector, SubContractorSelector} from "selectors";
import {CompanyActions,LabourActions, ProjectActions, SubContractorActions} from "slices/actions";
import {DefaultSort} from "constants/sort";
import ListSkeleton from "../../components/Skeleton/ListSkeleton";
import { LabourSelector } from "selectors/LabourSelector";
import SelectField from "components/SelectField";
import Input from "components/Input";
import { Constants } from "utils/constants";
import { CompanySelector } from "selectors/CompanySelector";
import styled from "@emotion/styled";
import { AttachmentOutlined } from "@mui/icons-material";
import { useHistory } from "react-router-dom";


const FilterBox = styled(Box)(() => ({
  width: "100%",
  marginTop: 30,
  marginBottom: 20,
  display: "flex",
  justifyContent: "space-between"
}));
export default function LabourSafetyTrainingList() {
  const history = useHistory()
  const dispatch = useDispatch();
  const labourListData = useSelector(LabourSelector.getAllSafetyTrainingList());
  const companyList = useSelector(CompanySelector.getCompanyList());
  const projectList = useSelector(ProjectSelector.getProjectList());
  const loading = useSelector(GeneralSelector.loader(LabourActions.getAllSafetyTrainingList.type))
  const pagination = useSelector(LabourSelector.getSafetyTraingPagination());
  const assignedProjectToSubcontractorList = useSelector(SubContractorSelector.getAssignedSubcontractor());
  const [filter, setFilter] = useState({
     sort: DefaultSort.newest.value,
     page: 1,
  });
const userType = localStorage.getItem('userType')
 const companyId = localStorage.getItem('companyID')
 const projectId = localStorage.getItem('projectID')
 const subcontractorId = localStorage.getItem('subcontractorID')
 const [assignedProject,setAssignedProject] = useState("");
 const labourTrainingType = useSelector(LabourSelector.getSafetyTrainingType());
 const [safetyTraingForm,setSafetyTraningForm] = useState({
    company_id: companyId?companyId:"",
    project_id: projectId?projectId:"",
    subcontractor_id: subcontractorId?subcontractorId:"",
    status: "",
    trainingType: ""
});
 useEffect(() => {
     dispatch(LabourActions.fetchLabourTrainingType())
     if (!companyId) {
        dispatch(CompanyActions.getCompanyList());
      } else if (companyId && !projectId) {
        const params = {
        company_id: companyId,
        status: 1,
       }
       dispatch(ProjectActions.getProjectList(params));
      } else if (projectId && !subcontractorId) {
        const params = {
            company_id: companyId,
            project_id: projectId,
          };
          dispatch(SubContractorActions.getAssignedSubContractor(params));
       }
  
      if (subcontractorId) {
        const params = {
          subcontractorId: subcontractorId,
        };
        dispatch(SubContractorActions.getAssignedSubContractor(params));
      } 
     dispatch(LabourActions.fetchLabourTrainingType(safetyTraingForm))
    },[])

useEffect(() => {
 const params = {
     ...filter,
     ...safetyTraingForm
 }
 dispatch(LabourActions.getAllSafetyTrainingList(params));
}, [filter]);

const handleChangeFilter = ({ target }) => {
 const {name, value} = target;

 setFilter({
     ...filter,
     [name]: value
 });
}

const handleChangePagination = (e, val) => {
 setFilter({
     ...filter,
     page: val
 });
};


const handleChangeCompany = (e) => {
 const cId = e.target.value
 if (cId) {
   const params = {
     company_id: cId,
     status: 1
   }
   dispatch(ProjectActions.getProjectList(params))
   safetyTraingForm.company_id = cId;
   safetyTraingForm.project_id = "";
   safetyTraingForm.subcontractor_id = "";
   setSafetyTraningForm({...safetyTraingForm})
   const params2 = {
    ...filter,
    ...safetyTraingForm
  }
   dispatch(LabourActions.getAllSafetyTrainingList(params2));
 }
}
const handleChangeProject = (e) => {
 const pId = e.target.value
 if (pId) {
  const params = {
    company_id: safetyTraingForm.company_id || companyId,
    project_id: pId,
   }
   dispatch(SubContractorActions.getAssignedSubContractor(params));
  safetyTraingForm.project_id = pId;
  safetyTraingForm.subcontractor_id = "";
  setSafetyTraningForm({...safetyTraingForm})
  const params2 = {
    ...filter,
    ...safetyTraingForm
  }
  dispatch(LabourActions.getAllSafetyTrainingList(params2));
 }
}

const handleChangeSubcontractor = (e) => { 
 const sId = e.target.value
 if (sId) {
    safetyTraingForm.subcontractor_id = sId;
    setSafetyTraningForm({...safetyTraingForm})
    const params2 = {
      ...filter,
      ...safetyTraingForm
    }
    dispatch(LabourActions.getAllSafetyTrainingList(params2));
 }
}
const handleChangeProjectBySubcontractor = (e) => {
    setAssignedProject(e.target.value)
    for (const i in assignedProjectToSubcontractorList.data) {
        if (assignedProjectToSubcontractorList.data[i].projectId._id === e.target.value) {
            safetyTraingForm.project_id = e.target.value
            safetyTraingForm.company_id = assignedProjectToSubcontractorList.data[i].companyId._id
            setSafetyTraningForm({...safetyTraingForm})

        }
    }
 }
const handleChangeStatus = (e) => {
  safetyTraingForm.status = e.target.value;
  setSafetyTraningForm({...safetyTraingForm})
  dispatch(LabourActions.getAllSafetyTrainingList(safetyTraingForm));  
}
const handleChangeTrainingType = (e) => {
     safetyTraingForm.trainingType = e.target.value;
     setSafetyTraningForm({...safetyTraingForm})
     const params = {
      ...filter,
      ...safetyTraingForm
    }
    dispatch(LabourActions.getAllSafetyTrainingList(params));
}

  return (
    <Card>
    <Typography variant="h5" sx={{ fontWeight: 600, position: 'relative' }}>Labour Safety Training List</Typography>

   <FilterBox>
      <Grid container spacing={3} justifyContent="space-between">
          <Grid item lg={4} sm={12} xs={12}>
              <Input
                  label="Search"
                  name='keyword'
                  placeholder='Search by name'
                  value={filter.keyword ?? ''}
                  onChange={handleChangeFilter}/>
          </Grid>
          {userType === Constants.SUPERADMIN ?(
            <Grid item lg={4} sm={12} xs={12}>
              <Typography sx={{fontSize: 15}}>Company</Typography>
              <SelectField
                  value={safetyTraingForm.company_id}
                  onChange={handleChangeCompany}
                  >
                  {companyList && companyList.map((item,index) => (
                  <MenuItem key={index} value={item._id}>
                          {item.name}
                  </MenuItem>
                  ))}
              </SelectField> 
            </Grid>
          ):null
          }
          {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER ?(
            <Grid item lg={4} sm={12} xs={12}>
              <Typography sx={{fontSize: 15}}>Project</Typography>
              <SelectField
                  value={safetyTraingForm.project_id}
                  onChange={handleChangeProject}
                  >
                  {(safetyTraingForm.company_id && projectList) && projectList.map((item,index) => (
                  <MenuItem key={index} value={item._id}>
                          {item.name}
                  </MenuItem>
                  ))}
              </SelectField> 
            </Grid>
          ):null
          }
          {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER || userType === Constants.PROJECT_USER?(
            <Grid item lg={4} sm={12} xs={12}>
              <Typography sx={{fontSize: 15}}>Subcontractor</Typography>
              <SelectField
                  value={safetyTraingForm.subcontractor_id}
                  onChange={handleChangeSubcontractor}
                  >
                 {(safetyTraingForm.project_id&& assignedProjectToSubcontractorList) && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                                <MenuItem value={item?.subcontractorId?._id} key={index}>
                                    {item?.subcontractorId?.subContractorName}
                                </MenuItem>
                    ))}
              </SelectField> 
            </Grid>
          ):null
          }
           {userType === Constants.SUB_CONTRACTOR_USER ?(
                    <Grid item lg={4} sm={12} xs={12}>
                        <Typography sx={{fontSize: 15}}>Project</Typography>
                        <SelectField
                            value={assignedProject}
                            onChange={handleChangeProjectBySubcontractor}
                            >
                            {assignedProjectToSubcontractorList && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                            <MenuItem key={index} value={item.projectId._id}>
                                    {item?.projectId?.name}({item?.companyId?.name})
                            </MenuItem>
                            ))}
                        </SelectField> 
                    </Grid>
                    ):null
                    }
           <Grid item lg={4} sm={12} xs={12}>
              <Typography sx={{fontSize: 15}}>Training Type</Typography>
              <SelectField
                  value={safetyTraingForm.trainingType}
                  onChange={handleChangeTrainingType}
                  >
                 {labourTrainingType && labourTrainingType.masterTrainingSkillType && labourTrainingType.masterTrainingSkillType.map((item,index) => (
                                <MenuItem key={index} value={item.type}>
                                    {item.type}
                                </MenuItem>
                 ))}
              </SelectField> 
            </Grid>
            <Grid item lg={4} sm={12} xs={12}>
              <Typography sx={{fontSize: 15}}>Status</Typography>
              <SelectField
                  value={safetyTraingForm.status}
                  onChange={handleChangeStatus}
                  >
                  <MenuItem value={1}>
                      Approve
                  </MenuItem>
                  <MenuItem value={2}>
                      Reject
                  </MenuItem>
                  <MenuItem value={0}>
                      Pending
                  </MenuItem>
              </SelectField> 
            </Grid>
            
            <Grid container justifyContent="flex-end" sx={{mt: 2}}>
                <Grid item lg={2} sm={12} xs={12}>
                    <Typography sx={{fontSize: 15}}>Sort by</Typography>
                    <SelectField sx={{width: 'auto'}}
                        value={filter.sort}
                        name="sort"
                        onChange={handleChangeFilter}>
                        {Object.keys(DefaultSort).map((key) => (
                            <MenuItem key={key} value={DefaultSort[key].value}>
                                {DefaultSort[key].name}
                            </MenuItem>
                        ))}
                    </SelectField> 
                </Grid>
            </Grid>
        </Grid>
          
     
  </FilterBox>
 

    {loading ? (
        <ListSkeleton/>
    ) : (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                            <TableCell>Labour Name</TableCell>
                            <TableCell>Training Type</TableCell>
                            <TableCell>Training Name</TableCell>
                            <TableCell>Project Name</TableCell>
                            <TableCell>Subcontractor Name</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Docs</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {labourListData.length === 0 && (
                        <TableRow>
                            <TableCell align="center" colSpan={7}>
                                No Data
                            </TableCell>
                        </TableRow>
                    )}
                    {labourListData && labourListData.map((item, i) => (
                        <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                                <TableCell>{`${item?.labourWithSafetyTraining?.firstName} ${' '} ${item?.labourWithSafetyTraining?.lastName}`}</TableCell>
                                <TableCell>{item.trainingType}</TableCell>
                                <TableCell>{item.trainingName}</TableCell>
                                <TableCell>{item.projectName}</TableCell>
                                <TableCell>{item.subcontractorName}</TableCell>
                                <TableCell align="center">
                                 {userType === Constants.SUB_CONTRACTOR_USER?(
                                    <IconButton>
                                    {item.status === 1 && <Chip label="Approve" color="primary"/>}
                                    {item.status === 2 && <Chip label="Reject" />}
                                    {item.status === 0 && <Chip label="Pending" color="success" />}
                                   </IconButton>):(
                                     <IconButton onClick={() => history.push(`/app/safetytraining/${item._id}`)}>
                                     {item.status === 1 && <Chip label="Approve" color="primary"/>}
                                     {item.status === 2 && <Chip label="Reject" />}
                                     {item.status === 0 && <Chip label="Pending" color="success" />}
                                   </IconButton>
                                   )
                                }
                               
                                </TableCell>
                            <TableCell component="th" scope="row">
                             {item.document?<Link href={item.document} target='_blank'>
                                <AttachmentOutlined/>
                            </Link>: "N/A"}
                             </TableCell>
                            
                          
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Pagination
                sx={{ mt: 1 }}
                page={filter.page}
                count={pagination.pages}
                onChange={handleChangePagination}/>
        </Box>
    )}

        {/* <FloatingButton
            onClick={() => history.push("/app/labour/create")}/> */}
    

    {/* <DialogConfirm
        title="Delete Labour"
        content="Are you sure want to delete this labour data?"
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onSubmit={handleDelete}
      /> */}

{/* 
      <LabourUploadDialog
          open={openIdDialog}
          onClose={() => setOpenIdDialog(false)}
          onSubmit={handleIdDialog}
      />  */}
</Card>
  )
}
