import { createSlice } from "@reduxjs/toolkit";

export const LabourSlice = createSlice({
    name: "labour",
    initialState: {
        labourlist: [],
        pagination: {},
        labourFormList: [],
        labourDetails: {},
        labourDetailsByAdharCard: {},
        labourCompilanceList: [],
        safetyTrainingListing: [],
        trainingType: [],
        allSafetyTrainingListing: [],
        educationListById: [],
        educationList: [],
        allEducationPagination: {},
        skillCategoryList: [],
        safetyTraingDetails: {},
        labourEducationDetails: {},
        labourHistory: [],
        role: {},
        labourCompilanceRole: {},
        disabilityMasterDataListig: [],
        labourDiseaseList: [],
        diseaseDetails: {},
        labourDocumentUploadData: {},
        safetyTrainingPagination: {},
        newLabourId: {},
        labourComplainList: [],
        complainListPagination: {},
        labourComplainDetails: {},
        UANFromAadhaar: {},
        UANNumber: null,
    },
    reducers: {
       createLabour: () => {},
       getLabourList: () => {},
       getLabourSuccess: (state, action) => {
        state.labourlist = action.payload.data;
        state.pagination = action.payload.pagination;  
        state.role = action.payload.role;            
    },
    getLabourFormList: () => {},
    getLabourFormListSuccess: (state, action) => {
        state.labourFormList = action.payload
    },
    createLabourHealth: () => {},
    getLabourById: () => {},
    getLabourByIdSuccess: (state, action) => {
        state.labourDetails = action.payload.data
    },
    updateLabourBasicInfo: () => {},
    updateLabourBasicInfoSuccess: (state, action) => {
        state.newLabourId = action.payload.data
    },
    updateLabourEmergencyContact: () => {},
    updateLabourEducation: () => {},
    updateLabourBankDetails: () => {},
    updateLabourPhoto: () => {},
    updateLabourAdharCard: () => {},
    updateLabourRationCard: () => {},
    updateLabourPanCard: () => {},
    updateLabourPassport: () => {},
    updateHealthDocument: () => {}, 
    labourBulkUpload: () => {},
    deleteLabour: () => {},
    getLabourByAdharCard: () => {},
    getLabourByAdharCardSuccess: (state, action) => {
        state.labourDetailsByAdharCard = action.payload.data
    },
    uploadLabourDocument: () => {},
    uploadLabourDocumentSucess: (state, action) => {
       state.labourDocumentUploadData = action.payload.data
    },
    uploadLabourCompilance: () => {},
    getLabourCompilanceList: () => {},
    getLabourCompilanceListSucess: (state, action) => {
        state.labourCompilanceList = action.payload.data
        state.labourCompilanceRole = action.payload.data.role
    },
    updateLabourPFBasicInfo: () => {},
    updateLabourDailyWages: () => {},
    updateLabourMonthlyWages: () => {},
    getSafetyTrainingList: () => {},
    getSafetyTrainingListSuccess: (state, action) => {
        state.safetyTrainingListing = action.payload.data
    },
    fetchLabourTrainingType: () => {},
    fetchLabourTrainingTypeSucess: (state, action) => {
        state.trainingType = action.payload.data
    },
    createLabourSafetyTraining: () => {},
    exitLabour: () => {},
    getAllSafetyTrainingList: () => {},
    getAllSafetyTrainingListSuccess: (state, action) => {
        state.allSafetyTrainingListing = action.payload.data
        state.safetyTrainingPagination = action.payload.pagination
    },
    getEducationListById: () => {},
    getEducationListByIdSuccess: (state,action) => {
        state.educationListById = action.payload.data
    },
    getEducationList: () => {},
    getEducationListSuccess: (state,action) => {
        state.educationList = action.payload.data
        state.allEducationPagination = action.payload.pagination
    },
    deleteEducationById: () => {},
    getSkillCategory: () => {},
    getSkillCategorySuccess: (state, action) => {
        state.skillCategoryList = action.payload.data
    },
    getSafetytraingDetails: () => {},
    getSafetytraingDetailsSuccess: (state, action) => {
        state.safetyTraingDetails = action.payload.data
    },
    deleteSafetyTraing: () => {},
    updateSafetyTraing: () => {},
    updateSkillInfo: () => {},
    getLabourEducationDetails: () => {},
    getLabourEducationDetailsSuccess: (state, action) => {
        state.labourEducationDetails = action.payload.data
    },
    addLabourEducation: () => {},
    getLabourHistory: () => {},
    getLabourHistotySuccess: (state, action) => {
        state.labourHistory = action.payload.data
    },
    getDisabilityMasterData: () => {},
    getDisabilityMasterDataSuccess: (state, action) => {
        state.disabilityMasterDataListig = action.payload.data  
    },
    createLabourDiseaseReason: () => {},
    getLabourDiseaseListing: () => {},
    getLabourDiseaseListingSuccess: (state, action) => {
        state.labourDiseaseList = action.payload.data  
    },
    getDiseaseDetails: () => {},
    getDiseaseDetailsSuccess: (state, action) => {
        state.diseaseDetails = action.payload.data
    },
    deleteDisease: () => {},
    updateDisease: () => {},
    labourUanCheckListDocumentUpload: () => {},
    updateUanList: () => {},
    labourComplain: () => {},
    getLabourComplainList: () => {},
    getLabourComplainListSuccess: (state, action) => {
        state.labourComplainList = action.payload.data
        state.complainListPagination = action.payload.pagination;  

    },
    deleteLabourComplain: () => {},
    getLabourComplainDetails: () => {},
    getLabourComplainDetailsSuccess: (state, action) => {
        state.labourComplainDetails = action.payload.data
    },
    updateLabourComplain: () => {},
    updateIdCardValidationDate: () => {},
    getUANFromAadhaar: () => { },
    setUANNumber(state, action) {
        state.UANNumber = action.payload;
      }, 
    resetUANNumber(state) {
        state.UANNumber = null; 
      },
    }
});

export default LabourSlice;