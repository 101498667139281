import { del, post,get,patch } from "../utils/api";

const API_URL = process.env.REACT_APP_API_URL 

const CreateSite = async (params) => post(`${API_URL}/site`,params);

const DeleteSite = async (id) => del(`${API_URL}/site/${id}`);

const GetSiteById = async (id) => get(`${API_URL}/site/siteByProjectId/${id}`);

const GetSiteDetailsById = async (id) => get(`${API_URL}/site/${id}`);
 
const UpdateSite = async (id,params) => patch(`${API_URL}/site/${id}`, params);

const GetAllSiteList = async (pId,cId) => get(`${API_URL}/site/?projectID=${pId}&companyID=${cId}`);

export const SiteService = {
    CreateSite,
    DeleteSite,
    GetSiteById,
    GetSiteDetailsById,
    UpdateSite,
    GetAllSiteList
 };