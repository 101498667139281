import {createSelector} from "@reduxjs/toolkit";

const reportSelector = (state) => state.report;

const getFormDData = () => createSelector(
    reportSelector,
    report => report.reportData
);
const getFormAData = () => createSelector(
    reportSelector,
    report => report.reportAData
);
const getFormBData = () => createSelector(
    reportSelector,
    report => report.reportBData
);
const getFormData = () => createSelector(
    reportSelector,
    report => report.formData
);

export const ReportSelector = {
    getFormData,
    getFormAData,
    getFormBData,
    getFormDData
}