import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  Avatar,
  TableHead,
  TableRow,
  Button,
  Grid,
  MenuItem,
  Switch,
  Hidden,
  Pagination,
  CircularProgress,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
  AttendanceSelector,
  GeneralSelector,
  ProjectSelector,
  SubContractorSelector,
} from "selectors";
import {
  AttendanceActions,
  CompanyActions,
  GeneralActions,
  ProjectActions,
  SubContractorActions,
} from "slices/actions";
import DialogConfirm from "components/DialogConfirm";
import ListSkeleton from "../../../components/Skeleton/ListSkeleton";
import Input from "components/Input";
import styled from "@emotion/styled";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SelectField from "components/SelectField";
import UploadFileSharpIcon from "@mui/icons-material/UploadFileSharp";
import { toast } from "react-toastify";
import AttendenceDialog from "./AttendenceDialog";
import { Constants } from "utils/constants";
import { CompanySelector } from "selectors/CompanySelector";
import BulkUplaodInformation from "./BulkUploadInformation";

const SwitchBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

function SingleDayMyAttendance() {
  const dispatch = useDispatch();
  const attendanceListData = useSelector(AttendanceSelector.getAttendances());
  const loading = useSelector(GeneralSelector.loader(AttendanceActions.getAttendances.type));
  const success = useSelector(GeneralSelector.success(AttendanceActions.createMyAttendance.type));
  const error = useSelector(GeneralSelector.error(AttendanceActions.createMyAttendance.type));
  const createAttendanceLoading = useSelector(GeneralSelector.loader(AttendanceActions.createMyAttendance.type));
  const successBulkUpload = useSelector(GeneralSelector.success(AttendanceActions.attendanceBulkUpload.type));
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openIdDialog, setOpenIdDialog] = useState(false);
  // const [presentList, setpresentList] = useState([]);
  // const [allLabourList, setAllLabourList] = useState([]);
  const [existLabourList, setExistLabourList] = useState();
  const companyId = localStorage.getItem("companyID");
  const projectId = localStorage.getItem("projectID");
  const subcontractorId = localStorage.getItem("subcontractorID");
  const companyList = useSelector(CompanySelector.getCompanyList());
  const projectList = useSelector(ProjectSelector.getProjectList());
  const userType = localStorage.getItem("userType");
  const [assignedProject, setAssignedProject] = useState("");
  const [showData, setShowData] = useState(false);
  const pagination = useSelector(AttendanceSelector.getPagination());
  const assignedProjectToSubcontractorList = useSelector(SubContractorSelector.getAssignedSubcontractor());
  const [openBulkUploadDialog, setOpenBulkUploadDialog] = useState(false)
  const [formData, setFormData] = useState({
    date: "",
    employementType: "",
    employeQ: "",
    companyName: companyId ? companyId : "",
    projectName: projectId ? projectId : "",
    subcontractorName: subcontractorId ? subcontractorId : "",
    page: 1,
    flag: 'subAttendance'
  });
  const [errObj, setErrObj] = useState({});
  const [currentDate, setCurrentDate] = useState();

  useEffect(() => {
    if (attendanceListData) {
      setExistLabourList(attendanceListData);
    }
  }, [attendanceListData]);

  const handleChangePagination = (e, val) => {
    formData.page = val
    setFormData({ ...formData });
    dispatch(AttendanceActions.getAttendances(formData));
  };

  useEffect(() => {
    const tempDate = new Date();
    let date = tempDate.getDate();
    if (date < 10) {
      date = `0${date}`;
    }
    let month = tempDate.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    const year = tempDate.getFullYear();
    const todayDate = `${year}-${month}-${date}`;
    formData.date = todayDate;
    setFormData({ ...formData });
    setCurrentDate(todayDate)
    if (!companyId) {
      dispatch(CompanyActions.getCompanyList());
    }
    if (companyId && !projectId) {
      const params = {
        company_id: companyId,
        status: 1,
      }
      dispatch(ProjectActions.getProjectList(params));
    }
    if (projectId && !subcontractorId) {
      const params = {
        company_id: companyId,
        project_id: projectId,
      };
      setShowData(true)
      dispatch(SubContractorActions.getAssignedSubContractor(params));
    }

    if (subcontractorId) {
      const params = {
        subcontractorId: subcontractorId,
      };
      dispatch(SubContractorActions.getAssignedSubContractor(params));
    }
  }, []);

  // useEffect(() => {
  //   for (const i in attendanceListData) {
  //     if (attendanceListData[i]._id) {
  //       allLabourList.push(attendanceListData[i]._id);
  //       setAllLabourList(allLabourList);
  //       if (attendanceListData[i].labourAttendance) {
  //         setpresentList((prev) => [...prev, attendanceListData[i]._id]);
  //       }
  //     }
  //   }
  // }, [attendanceListData]);

  useEffect(() => {
    if (success) {
      // setpresentList([]);
      // setAllLabourList([]);
      toast.success(`${success?.message ?? "Success"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });

      dispatch(GeneralActions.removeSuccess(AttendanceActions.createMyAttendance.type));
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      toast.error(`${error?.message ?? "Success"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });
      dispatch(GeneralActions.removeError(AttendanceActions.createMyAttendance.type));
    }
  }, [error]);

  useEffect(() => {
    if (successBulkUpload) {
      dispatch(AttendanceActions.getAttendances(formData));
      setOpenBulkUploadDialog(true);
      setOpenIdDialog(false);
    }
  }, [successBulkUpload]);

  const handleChangeCheckBox = (e, index) => {
    const { checked } = e.target;
    const newListData = [...existLabourList];

    const newObject = {
      labourAttendance: checked,
      attendanceInTime: newListData[index].attendanceInTime,
      attendanceOutTime: newListData[index].attendanceOutTime,
      attendanceDate: newListData[index].attendanceDate,
      attendanceVarifiedBy: newListData[index].attendanceVarifiedBy,
      _id: newListData[index]._id,
      firstName: newListData[index].firstName,
      lastName: newListData[index].lastName,
      labourCodeNo: newListData[index].labourCodeNo,
      aadharNo: newListData[index].aadharNo,
      logo: newListData[index].logo,
      projectId: newListData[index].projectId,
      companyId: newListData[index].companyId,
      subcontractorName: newListData[index].subcontractorName,
      companyName: newListData[index].companyName,
      projectname: newListData[index].projectname,
      projectLabourCode: newListData[index].projectLabourCode
    };
    newListData[index] = newObject;
    setExistLabourList(newListData);
    // if (checked) {
    //   setpresentList((prev) => [...prev, value]);
    // } else {
    //   setpresentList((prev) => prev.filter((x) => x !== value));
    // }

    // if (checked) {
    //   setpresentList((prev) => [...prev, newObject]);
    // } else {
    //   setpresentList((prev) => prev.filter((x) => x._id !== newObject._id));
    // }
  };

  const changeDate = (e) => {
    delete errObj.date;
    setErrObj({ ...errObj });
    formData.date = e.target.value;
    setFormData({ ...formData });
  };

  const submitData = () => {
    const params = {
      date: formData.date,
      project_id: formData.projectName,
      // allLabours: JSON.stringify(allLabourList),
      // presentLabours: JSON.stringify(presentList),
      flag: 'subAttendance',
      data: JSON.stringify(existLabourList)
    };
    dispatch(AttendanceActions.createMyAttendance(params));
  };
  const searchToGetAttendance = () => {
    if (validateForm()) {
      setShowData(true)
      dispatch(AttendanceActions.getAttendances(formData));
    }
  };
  const validateForm = () => {
    let valid = true
    if (!formData.companyName) {
      errObj.companyName = 'This field is Required';
      valid = false;
    }
    if (!formData.projectName) {
      errObj.projectName = 'This field is Required';
      valid = false;
    }
    if (!formData.date) {
      errObj.date = 'This field is Required';
      valid = false;
    }
    setErrObj({ ...errObj });
    return valid;
  }
  const handleChangeCompany = (e) => {
    delete errObj.companyName;
    setErrObj({ ...errObj });
    formData.companyName = e.target.value;
    formData.projectName = "";
    formData.subcontractorName = "";
    setFormData({ ...formData });
    if (formData.companyName) {
      const params = {
        company_id: formData.companyName,
        status: 1,
      };
      dispatch(ProjectActions.getProjectList(params));
    }
  };
  const handleChangeProject = (e) => {
    delete errObj.projectName;
    setErrObj({ ...errObj });
    formData.projectName = e.target.value;
    formData.subcontractorName = "";
    setFormData({ ...formData });
    if (formData.projectName) {
      const params = {
        company_id: formData.companyName || companyId,
        project_id: formData.projectName,
      };
      dispatch(SubContractorActions.getAssignedSubContractor(params));
    }
  };
  const handleChangeSubcontractor = (e) => {
    formData.subcontractorName = e.target.value;
    setFormData({ ...formData });

  };

  const handleChangeProjectBySubcontractor = (e) => {
    setAssignedProject(e.target.value);
    for (const i in assignedProjectToSubcontractorList.data) {
      if (
        assignedProjectToSubcontractorList.data[i].projectId._id ===
        e.target.value
      ) {
        formData.projectName = e.target.value;
        formData.companyName =
          assignedProjectToSubcontractorList.data[i].companyId._id;
        setFormData({ ...formData });
      }
    }
  };

  // window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth',
  // });
  return (
    <Card>
      <Typography variant="h5" sx={{ fontWeight: 600, position: "relative" }}>
        Attendance
        <UploadFileSharpIcon
          className="Badgeico cursor-pointer"
          onClick={() => setOpenIdDialog(true)}
          color="primary"
        />
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
        <Grid item lg={12} xs={12}>
          <Grid container spacing={3}>
            <Grid item lg={3} xs={12}>
              <Input
                label="Date"
                name="date"
                type="date"
                inputProps={{
                  max: currentDate
                }}
                value={formData.date}
                onChange={changeDate}
              />
              {errObj.date ? <Typography sx={{ color: 'red', fontSize: 12 }}>This field is Required</Typography> : null}
            </Grid>
            {userType === Constants.SUPERADMIN ? (
              <Grid item lg={3} sm={12} xs={12}>
                <Typography sx={{ fontSize: 15 }}>Company Name</Typography>
                <SelectField
                  value={formData.companyName}
                  onChange={handleChangeCompany}
                >
                  {companyList &&
                    companyList.map((item, index) => (
                      <MenuItem key={index} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </SelectField>
                {errObj.companyName ? <Typography sx={{ color: 'red', fontSize: 12 }}>This field is Required</Typography> : null}
              </Grid>
            ) : null}
            {userType === Constants.SUPERADMIN ||
              userType === Constants.COMPANY_USER ? (
              <Grid item lg={3} sm={12} xs={12}>
                <Typography sx={{ fontSize: 15 }}>Project Name</Typography>
                <SelectField
                  value={formData.projectName}
                  onChange={handleChangeProject}
                >
                  {(formData.companyName && projectList) &&
                    projectList.map((item, index) => (
                      <MenuItem key={index} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </SelectField>
                {errObj.projectName ? <Typography sx={{ color: 'red', fontSize: 12 }}>This field is Required</Typography> : null}
              </Grid>
            ) : null}

            {userType === Constants.SUB_CONTRACTOR_USER ? (
              <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{ fontSize: 15 }}>Project</Typography>
                <SelectField
                  value={assignedProject}
                  onChange={handleChangeProjectBySubcontractor}
                >
                  {assignedProjectToSubcontractorList && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item, index) => (
                    <MenuItem key={index} value={item.projectId._id}>
                      {item?.projectId?.name}({item?.companyId?.name})
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
            ) : null}

            {userType === Constants.SUPERADMIN ||
              userType === Constants.COMPANY_USER ||
              userType === Constants.PROJECT_USER ? (
              <Grid item lg={3} sm={12} xs={12}>
                <Typography sx={{ fontSize: 15 }}>
                  Subcontractor Name
                </Typography>
                <SelectField
                  value={formData.subcontractorName}
                  onChange={handleChangeSubcontractor}
                >
                  {(formData.projectName && assignedProjectToSubcontractorList) && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item, index) => (
                    <MenuItem value={item?.subcontractorId?._id} key={index}>
                      {item?.subcontractorId?.subContractorName}
                    </MenuItem>
                  ))}

                </SelectField>
              </Grid>
            ) : null}
            <Grid item lg={3} xs={12}>
              <Typography varient="h6" sx={{ fontSize: "15px", color: "#333" }}>
                Employment Type
              </Typography>
              <SelectField
                value={formData.employementType}
                onChange={(e) => {
                  formData.employementType = e.target.value;
                  setFormData({ ...formData });
                }}
              >
                <MenuItem value={"Daily Wages"}>Daily Wages</MenuItem>
                <MenuItem value={"Fixed Monthly Salary"}>
                  Fixed Monthly Salary
                </MenuItem>
                <MenuItem value={"All"}>All</MenuItem>
              </SelectField>
            </Grid>

            <Grid item lg={3} xs={12}>
              <Input
                label="Search by Name/Id"
                placeholder="Enter Name/ Id"
                value={formData.employeQ}
                onChange={(e) => {
                  formData.employeQ = e.target.value;
                  setFormData({ ...formData });
                }}
              />
            </Grid>
            <Grid item lg={3} xs={12}>
              <Typography>&nbsp;</Typography>
              <Button
                variant="contained"
                sx={{ width: "100%", py: 1 }}
                color="primary"
                onClick={searchToGetAttendance}
              >
                <SearchOutlinedIcon />
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {loading ? (
        <ListSkeleton />
      ) : (
        <>
          {formData.companyName && formData.projectName && showData && existLabourList && existLabourList.length !== 0 ? (
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Subcontractor Name</TableCell>
                    <TableCell>Project Labour Code</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formData.companyName && formData.projectName && showData && existLabourList &&
                    existLabourList.map((item, index) => (
                      <TableRow
                        key={index}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",

                              "& img": {
                                borderRadius: 50,
                              },
                            }}
                          >
                            {item.logo ? (
                              <img src={item.logo} width="40" />
                            ) : (
                              <Avatar sx={{ width: 40, height: 40 }} />
                            )}
                            <Typography
                              sx={{ ml: 2, display: "block" }}
                              variant="subtitle2"
                            >
                              {item.firstName} {item.lastName} <br />
                              <span style={{ fontSize: 12, color: "#3c5a99" }}>
                                {item.projectname} (P)
                              </span>{" "}
                              <br />
                              <span style={{ fontSize: 12, color: "#0077b5" }}>
                                {item.companyName} (C)
                              </span>
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>{item.subcontractorName}</TableCell>
                        <Hidden smDown>
                          <TableCell>{item.projectLabourCode}</TableCell>
                        </Hidden>
                        <TableCell sx={{ position: "relative" }}>
                          {item.labourAttendance !== "H" ? (
                            <>
                              <SwitchBox>
                                {
                                // item.editable ? <Switch
                                //   checked={item.labourAttendance}
                                //   // defaultChecked={item.labourAttendance}
                                //   onChange={(e) => handleChangeCheckBox(e, index)}
                                //   value={item._id}
                                // /> : <Switch
                                //   checked={item.labourAttendance}
                                //   disabled
                                //   value={item._id}
                                // />
                                <Switch
                                  checked={item.labourAttendance}
                                  // defaultChecked={item.labourAttendance}
                                  onChange={(e) => handleChangeCheckBox(e, index)}
                                  value={item._id}
                                  disabled={item.editable === false}
                                /> 
                                }
                              </SwitchBox>
                             
                              {item.labourAttendance ? (
                                <Typography
                                  sx={{
                                    display: "inline-block",
                                    position: "absolute",
                                    left: "55px",
                                    bottom: "2px",
                                  }}
                                >
                                  P
                                  {
                                item && item.editable
                              }
                                </Typography>
                              ) : (
                                <Typography
                                  sx={{
                                    display: "inline-block",
                                    position: "absolute",
                                    left: "25px",
                                    bottom: "2px",
                                  }}
                                >
                                  A
                                </Typography>
                              )}
                            </>) : <Typography>Holiday</Typography>}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              {formData.companyName && formData.projectName && showData &&
                <Pagination
                  sx={{ mt: 1 }}
                  page={formData.page}
                  count={pagination.pages}
                  onChange={handleChangePagination} />}
            </Box>
          ) : <Typography sx={{ textAlign: "center" }}>No Data</Typography>}
        </>
      )}

      {formData.companyName && formData.projectName && showData && existLabourList && existLabourList.length !== 0 && (
        <Typography style={{ textAlign: "right" }}>
          <Button variant="contained" color="primary" onClick={submitData}>
            {createAttendanceLoading ? <CircularProgress color="inherit" size={20} /> : <>Update</>}
          </Button>
        </Typography>
      )}

      <DialogConfirm
        title="Delete Company"
        content="Are you sure want to delete this labour data?"
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
      />

      <AttendenceDialog
        open={openIdDialog}
        onClose={() => setOpenIdDialog(false)}
      />
      <BulkUplaodInformation
        open={openBulkUploadDialog}
        onClose={() => setOpenBulkUploadDialog(false)}
        data={successBulkUpload}
      />

    </Card>
  );
}

export default SingleDayMyAttendance;
