import React, {useEffect,useState} from "react";
import {
    Box, Card, Grid, Link, MenuItem, Table, TableBody, TableCell, TableHead, TableRow,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import FloatingButton from "components/FloatingButton";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {GeneralSelector, MasterDataSelector, ProjectSelector, SubContractorSelector, UserSelector} from "selectors";
import { CompanyActions, MasterDataActions, ProjectActions, SubContractorActions, UserActions} from "slices/actions";
import ListSkeleton from "../../components/Skeleton/ListSkeleton";
import { Constants } from "utils/constants";
import SelectField from "components/SelectField";
import { CompanySelector } from "selectors/CompanySelector";
import styled from "@emotion/styled";

const FilterBox = styled(Box)(() => ({
    width: "100%",
    marginTop: 30,
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-between"
}));

function PfComplianceListing() {
    const history = useHistory();
    const dispatch = useDispatch();
    const pfCompilanceListData = useSelector(UserSelector.getPfCompilanceList());
    const loading = useSelector(GeneralSelector.loader(UserActions.getPfComplianceListing.type));
    const role = useSelector(UserSelector.getPfCompilanceRole());
    const companyList = useSelector(CompanySelector.getCompanyList());
    const projectList = useSelector(ProjectSelector.getProjectList());
    const monthList = useSelector(MasterDataSelector.getMonthList());
    const yearList = useSelector(MasterDataSelector.getYearList())
    const userType = localStorage.getItem('userType');
    const companyId = localStorage.getItem('companyID');
    const projectId = localStorage.getItem('projectID');
    const subcontractorId = localStorage.getItem('subcontractorID');
    const [assignedProject,setAssignedProject] = useState("");
    const assignedProjectToSubcontractorList = useSelector(SubContractorSelector.getAssignedSubcontractor());
    const [pfComplianceForm,setPfComplianceForm] = useState({
        company_id: companyId?companyId:"",
        project_id: projectId?projectId:"",
        subcontractor_id: subcontractorId?subcontractorId:"",
        month: "",
        year: "",
        Compliance_type: ""
      })
   
      useEffect(() => {
        dispatch(MasterDataActions.getMonthList());
        dispatch(MasterDataActions.getYearList());
        if (!companyId) {
        dispatch(CompanyActions.getCompanyList())
        }
        if (subcontractorId) {
            const params = {
                subcontractorId: subcontractorId
            }
             dispatch(SubContractorActions.getAssignedSubContractor(params));
        }
        dispatch(UserActions.getPfComplianceListing(pfComplianceForm));
    },[])

    const handleChangeCompany = (e) => {
        const cId = e.target.value;
        pfComplianceForm.company_id = cId;
        pfComplianceForm.project_id = "";
        pfComplianceForm.subcontractor_id = "";
        setPfComplianceForm({...pfComplianceForm})
         const params = {
            company_id: cId,
            status: 1
          }
          dispatch(ProjectActions.getProjectList(params))
        dispatch(UserActions.getPfComplianceListing(pfComplianceForm));
    } 
     
     const handleChangeProject = (e) => {
        if (pfComplianceForm.company_id) {
        const pId = e.target.value
        pfComplianceForm.project_id = pId;
        pfComplianceForm.subcontractor_id = "";
        setPfComplianceForm({...pfComplianceForm})
        const params = {
            company_id: pfComplianceForm.company_id || companyId,
            project_id: pId,
        }
        dispatch(SubContractorActions.getAssignedSubContractor(params))
        dispatch(UserActions.getPfComplianceListing(pfComplianceForm));
        } 
      }

    const handleChangeSubcontractor = (e) => {
        if (pfComplianceForm.project_id) {
          const sId = e.target.value
          pfComplianceForm.subcontractor_id = sId;
          setPfComplianceForm({...pfComplianceForm})
          dispatch(UserActions.getPfComplianceListing(pfComplianceForm));
        } 
    }
    const handleChangeMonth = (e) => {
        pfComplianceForm.month = e.target.value
        setPfComplianceForm({...pfComplianceForm})
        dispatch(UserActions.getPfComplianceListing(pfComplianceForm));
    }
    const handleChangeYear = (e) => {
        pfComplianceForm.year = e.target.value
        setPfComplianceForm({...pfComplianceForm})
        dispatch(UserActions.getPfComplianceListing(pfComplianceForm));
    }
    const handleChangeProjectBySubcontractor = (e) => {
        setAssignedProject(e.target.value)
        for (const i in assignedProjectToSubcontractorList.data) {
            if (assignedProjectToSubcontractorList.data[i].projectId._id === e.target.value) {
               pfComplianceForm.company_id = assignedProjectToSubcontractorList.data[i].companyId._id
               pfComplianceForm.project_id = e.target.value
               setPfComplianceForm({...pfComplianceForm})
               dispatch(UserActions.getPfComplianceListing(pfComplianceForm));
             }
        }
    }
    const handleChangeComplianceType = (e) => {
        pfComplianceForm.Compliance_type = e.target.value
        setPfComplianceForm({...pfComplianceForm})
        dispatch(UserActions.getPfComplianceListing(pfComplianceForm));
    }
   
   return (
    <Card>
   
    <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>PF Compliance</Typography>
    <FilterBox>
        <Grid container spacing={3}>
            {userType === Constants.SUPERADMIN ?(
              <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Company</Typography>
                <SelectField
                    value={pfComplianceForm.company_id}
                    onChange={handleChangeCompany}
                    >
                    {companyList && companyList.map((item,index) => (
                    <MenuItem key={index} value={item._id}>
                            {item.name}
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
            {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER ?(
              <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Project</Typography>
                <SelectField
                    value={pfComplianceForm.project_id}
                    onChange={handleChangeProject}
                    >
                    {(pfComplianceForm.company_id && projectList) && projectList.map((item,index) => (
                    <MenuItem key={index} value={item._id}>
                            {item.name}
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
            {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER || userType === Constants.PROJECT_USER?(
              <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Subcontractor</Typography>
                <SelectField
                    value={pfComplianceForm.subcontractor_id}
                    onChange={handleChangeSubcontractor}
                    >
                      {(pfComplianceForm.project_id && assignedProjectToSubcontractorList) && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                            <MenuItem key={index} value={item?.subcontractorId?._id}>
                                    {item?.subcontractorId?.subContractorName}
                            </MenuItem>
                      ))}
                </SelectField> 
              </Grid>
            ):null
            }
             {userType === Constants.SUB_CONTRACTOR_USER ?(
                        <Grid item lg={4} sm={12} xs={12}>
                        <Typography sx={{fontSize: 15}}>Project</Typography>
                        <SelectField
                            value={assignedProject}
                            onChange={handleChangeProjectBySubcontractor}
                            >
                            {assignedProjectToSubcontractorList && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                            <MenuItem key={index} value={item?.projectId?._id}>
                                    {item?.projectId?.name}({item?.companyId?.name})
                            </MenuItem>
                            ))}
                        </SelectField> 
                        </Grid>
                    ):null
                    }
            <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Month</Typography>
                <SelectField
                     value={pfComplianceForm.month}
                     onChange={handleChangeMonth}
                    >
                     {monthList && monthList.masterMonthTable && monthList.masterMonthTable.map((item,index) => (
                              <MenuItem value={item.mth} key={index}>
                                    {item.mth}
                              </MenuItem> 
                    ))}
                </SelectField> 
            </Grid>
            <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Year</Typography>
                <SelectField
                   value={pfComplianceForm.year}
                   onChange={handleChangeYear}
                    >
                     {yearList && yearList.masterYearTable && yearList.masterYearTable.map((item,index) => (
                              <MenuItem value={item.yr} key={index}>
                                    {item.yr}
                              </MenuItem> 
                     ))}
                </SelectField> 
            </Grid>
            <Grid item lg={4} sm={12} xs={12}>
                        <Typography variant='h5' sx={{color: '#000', fontSize: 15, opacity: 'rgba(0, 0, 0, 0.87)'}}>Compliance Type</Typography>
                          <SelectField
                            value={pfComplianceForm.Compliance_type}
                            onChange={handleChangeComplianceType}
                            >
                                <MenuItem value={"ECR cum Return Statement"}>
                                    {"ECR cum Return Statement"}
                                </MenuItem>
                                <MenuItem value={"ECR Challan Receipt"}>
                                    {"ECR Challan Receipt"}
                                </MenuItem>
                                <MenuItem value={"PF Payment Receipt"}>
                                    {"PF Payment Receipt"}
                                </MenuItem>
                         </SelectField>
            </Grid>
        </Grid>
       
    </FilterBox>
    {loading ? (
        <ListSkeleton/>
    ) : (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Project Name</TableCell>
                        <TableCell>SubContractor Name</TableCell>
                        <TableCell>Compliance Type</TableCell>
                        <TableCell>Month</TableCell>
                        <TableCell>Year</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    
                      {pfCompilanceListData && pfCompilanceListData.data && pfCompilanceListData.data.map((item, i) => (
                        <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {item.company_name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.project_name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                               {item.subcontractor_name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                            {item.Compliance_type}
                            </TableCell>
                            <TableCell component="th" scope="row">
                               {item.month}
                            </TableCell>
                            <TableCell component="th" scope="row">
                               {item.year}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {role && Object.keys(role).map((item1,index1) => (
                                <div key={index1}>
                                    {item1 === "View document"?(
                                  <Link href={item.pfCompliance_url} target='_blank' className="cursor-pointer" >View Document</Link>):null}
                                </div>
                                ))}
                            </TableCell>
                            
                        </TableRow>
                    ))}  
                </TableBody>
            </Table>

       
        </Box>
    )}

{role && Object.keys(role).map((item2,index2) => (
    <div key={index2}>
       {item2 === "Add"?( 
    <FloatingButton
        onClick={() => history.push("/app/pfcompliance/create")}/>):null}
    </div>
))}
</Card>
    
  )
}


export default PfComplianceListing;