import React, { useState,useEffect } from "react";
import {Box, Button, Card,CircularProgress,Grid, MenuItem, Typography} from "@mui/material";
import PageTitle from "components/PageTitle";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import { CompanyActions, GeneralActions, LabourActions, MasterDataActions, ProjectActions, SubContractorActions} from "slices/actions";
import { GeneralSelector, MasterDataSelector, ProjectSelector, SubContractorSelector} from "selectors";
import TextareaAutosize from '@mui/material/TextareaAutosize';
// import Input from "components/Input";
import SelectField from "components/SelectField";
import { useHistory } from "react-router-dom";
import { Constants } from "utils/constants";
import { CompanySelector } from "selectors/CompanySelector";

export default function AddLabourCompliance() {
    const history = useHistory();
    const dispatch = useDispatch();
    // const [adharCard,setAdharCard] = useState();
    // const labourDetails = useSelector(LabourSelector.getLabourByAdharCard());
    const userId = localStorage.getItem('userID');
    const loading = useSelector(GeneralSelector.loader(LabourActions.uploadLabourCompilance.type));
    const error = useSelector(GeneralSelector.error(LabourActions.uploadLabourCompilance.type));
    const success = useSelector(GeneralSelector.success(LabourActions.uploadLabourCompilance.type));
    const yearList = useSelector(MasterDataSelector.getYearList());
    const monthList = useSelector(MasterDataSelector.getMonthList());
    const labourExistError = useSelector(GeneralSelector.error(LabourActions.getLabourByAdharCard.type));
    const userType = localStorage.getItem('userType');
    const companyId = localStorage.getItem('companyID');
    const projectId = localStorage.getItem('projectID');
    const subContractorId = localStorage.getItem('subcontractorID');
    const companyList = useSelector(CompanySelector.getCompanyList());
    const projectList = useSelector(ProjectSelector.getProjectList());
    const [assignedProject,setAssignedProject] = useState("");
    const assignedProjectToSubcontractorList = useSelector(SubContractorSelector.getAssignedSubcontractor());
    const [errorObj,setErrorObj] = useState({});
    const [labourCompilanceForm,setlabourCompilanceForm] = useState({
      company_id: companyId?companyId:"",
      project_id: projectId?projectId:"",
      subcontractor_id: subContractorId?subContractorId:"",
      labourCompliance_document: "",
      Compliance_type: "",
      note: "",
      month: "",
      year: ""
  })

    useEffect(() => {
        dispatch(MasterDataActions.getMonthList());
        dispatch(MasterDataActions.getYearList());
        if (!companyId) {
          dispatch(CompanyActions.getCompanyList());
      } else if (companyId && !projectId) {
          const params = {
              company_id: companyId,
          }
         dispatch(ProjectActions.getProjectList(params));
      } else if (projectId && !subContractorId) {
          const params = {
              company_id: companyId,
              project_id: projectId
          }
          dispatch(SubContractorActions.getAssignedSubContractor(params));
      } else if (subContractorId) {
          const params = {
              subcontractorId: subContractorId
          }
           dispatch(SubContractorActions.getAssignedSubContractor(params));
      }
    },[])
    const handleChangeCompany = (e) => {
      delete errorObj.company_id;
      setErrorObj({...errorObj})
      labourCompilanceForm.company_id = e.target.value;
      labourCompilanceForm.project_id = "";
      labourCompilanceForm.subcontractor_id = "";
      setlabourCompilanceForm({...labourCompilanceForm})
      const params = {
        company_id: e.target.value,
      }
       dispatch(ProjectActions.getProjectList(params));
   } 
   
   const handleChangeProject = (e) => {
      delete errorObj.project_id;
      setErrorObj({...errorObj})
      if (labourCompilanceForm.company_id) {
      labourCompilanceForm.project_id = e.target.value;
      labourCompilanceForm.subcontractor_id = "";
      setlabourCompilanceForm({...labourCompilanceForm})
      const params = {
        company_id: companyId || labourCompilanceForm.company_id,
        project_id: e.target.value
      }
      dispatch(SubContractorActions.getAssignedSubContractor(params));
      } 
    }

  const handleChangeSubcontractor = (e) => {
      delete errorObj.subcontractor_id;
      setErrorObj({...errorObj})
      if (labourCompilanceForm.project_id) {
        labourCompilanceForm.subcontractor_id = e.target.value;
        setlabourCompilanceForm({...labourCompilanceForm})
        } 
  
     }

     const handleChangeProjectBySubcontractor = (e) => {
      delete errorObj.company_id;
      delete errorObj.project_id;
      setErrorObj({...errorObj})
      setAssignedProject(e.target.value)
      for (const i in assignedProjectToSubcontractorList.data) {
          if (assignedProjectToSubcontractorList.data[i].projectId._id === e.target.value) {
              labourCompilanceForm.company_id = assignedProjectToSubcontractorList.data[i].companyId._id;
              labourCompilanceForm.project_id = assignedProjectToSubcontractorList.data[i].projectId._id;
              setlabourCompilanceForm({...labourCompilanceForm})
            }
      }
    }
  const handleChangeDocument = (e) => {
        delete errorObj.labourCompliance_document;
         setErrorObj({...errorObj})
        const file = e.target.files[0];
        labourCompilanceForm.labourCompliance_document = file
        setlabourCompilanceForm({...labourCompilanceForm})
    };
    const handleSubmit = () => {
      if (validForm()) {
        const params = {
         ...labourCompilanceForm,
          type: "subcontractor",
          user_id: userId,
          // project_id: labourDetails.data.projectId,
          // company_id: labourDetails.data.companyId,
          // labour_id: labourDetails.labourData._id,
          // subcontractor_id: labourDetails.data.subContractorId

        }
      dispatch(LabourActions.uploadLabourCompilance(params))
      }
    };

    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true
                });
      
            dispatch(GeneralActions.removeSuccess(LabourActions.uploadLabourCompilance.type));
            history.goBack();

          }
      }, [success]);

      useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true
                });
      
            dispatch(GeneralActions.removeError(LabourActions.uploadLabourCompilance.type));
          }
      }, [error]);

      useEffect(() => {
        if (labourExistError) {
            toast.error(`${labourExistError?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true
                });
      
            dispatch(GeneralActions.removeError(LabourActions.getLabourByAdharCard.type));

          }
      }, [labourExistError]);
//     const handleChangeAdharCard = (e) => {
//         const adharCardData = e.target.value
//         setAdharCard(adharCardData)
//       if (adharCardData.length === 12) {
//            const params = {
//                 aadharNo: adharCardData
//             }
//             dispatch(LabourActions.getLabourByAdharCard(params))
//         } else {
//         dispatch(LabourActions.getLabourByAdharCardSuccess(""))
//       }
//  }
 const handleChangeMonth = (e) => {
    labourCompilanceForm.month = e.target.value
    setlabourCompilanceForm({...labourCompilanceForm})
  }
  const handleChangeYear = (e) => {
    labourCompilanceForm.year = e.target.value
    setlabourCompilanceForm({...labourCompilanceForm})
  }
  const handleChangeComplianceType = (e) => {
    delete errorObj.Compliance_type;
    setErrorObj({...errorObj})
    labourCompilanceForm.Compliance_type = e.target.value
    setlabourCompilanceForm({...labourCompilanceForm})
}
  const validForm = () => {
    let valid = true
    if (!labourCompilanceForm.company_id) {
        errorObj.company_id = "This field is Required"
        valid = false;
    }
    if (!labourCompilanceForm.project_id) {
        errorObj.project_id = "This field is Required"
        valid = false;
    }
    if (!labourCompilanceForm.subcontractor_id) {
        errorObj.subcontractor_id = "This field is Required"
        valid = false;
    }
    if (!labourCompilanceForm.Compliance_type) {
        errorObj.Compliance_type = "This field is Required"
        valid = false;
    }
    if (!labourCompilanceForm.labourCompliance_document) {
        errorObj.labourCompliance_document = "This field is Required"
        valid = false;
    }
    if (!labourCompilanceForm.note) {
        errorObj.note = "This field is Required"
        valid = false;
    }
    if (!labourCompilanceForm.month) {
        errorObj.month = "This field is Required"
        valid = false;
    }
    if (!labourCompilanceForm.year) {
        errorObj.year = "This field is Required"
        valid = false;
    }
    setErrorObj({...errorObj})
    return valid
}
  
    return (
        <Box>
          <PageTitle isBack={true} title="Add Labour Compliance" />
            <Card>
                    <Grid container spacing={3}>
                            {/* <Grid item lg={4} sm={12} xs={12}>
                            <Typography varient='h6' sx={{fontSize: '15px'}}>Search Labour By AdharCard</Typography>
                             <Input 
                              value = {adharCard}
                              onChange = {handleChangeAdharCard}
                             />
                            
                               {labourDetails && labourDetails.data && labourDetails.data.projectName ?<Typography>ProjectName:{labourDetails.data.projectName}</Typography>:null}
                               {labourDetails && labourDetails.data && labourDetails.data.companyName ?<Typography>CompanyName:{labourDetails.data.companyName}</Typography>:null}
                               {labourDetails && labourDetails.data && labourDetails.data.subContractorName ?<Typography>SubcontractorName:{labourDetails.data.subContractorName}</Typography>:null}
                               
                        </Grid> */}
                    {userType === Constants.SUPERADMIN?(
                    <Grid item lg={6} sm={12} xs={12}>
                        <Typography variant='h6' sx={{fontSize: 15}}>Company</Typography>
                        <SelectField
                            value={labourCompilanceForm.company_id}
                            onChange={handleChangeCompany}
                            >
                              {companyList && companyList.map((item,index) => (
                                <MenuItem value={item._id} key={index}>
                                    {item.name}
                                </MenuItem>
                              ))}
                        </SelectField>
                        {errorObj.company_id ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}
                    </Grid>
                     ):null} 
                  {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER?(
                    <Grid item lg={6} sm={12} xs={12}>
                        <Typography variant='h6' sx={{fontSize: 15}}>Project</Typography>
                        <SelectField
                            value={labourCompilanceForm.project_id}
                            onChange={handleChangeProject}
                            >
                           { (labourCompilanceForm.company_id && projectList) && projectList.map((item,index) => (
                                <MenuItem value={item._id} key={index}>
                                    {item.name}
                                </MenuItem>
                              ))}
                        </SelectField>
                        {errorObj.project_id && <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>}
                     </Grid>
                      ):null} 
                    {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER || userType === Constants.PROJECT_USER?(
                        <Grid item lg={6} sm={12} xs={12}>
                        <Typography variant='h6' sx={{fontSize: 15}}>SubContractor</Typography>
                        <SelectField
                            value={labourCompilanceForm.subcontractor_id}
                            onChange={handleChangeSubcontractor}
                            >
                           {(labourCompilanceForm.project_id && assignedProjectToSubcontractorList) && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                                <MenuItem value={item?.subcontractorId?._id} key={index}>
                                    {item?.subcontractorId?.subContractorName}
                                </MenuItem>
                              ))}
                        </SelectField>
                        {errorObj.subcontractor_id && <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>}
                     </Grid>
                      ):null} 
                    {userType === Constants.SUB_CONTRACTOR_USER ?(
                    <Grid item lg={4} sm={12} xs={12}>
                        <Typography sx={{fontSize: 15}}>Project</Typography>
                        <SelectField
                            value={assignedProject}
                            onChange={handleChangeProjectBySubcontractor}
                            >
                            {assignedProjectToSubcontractorList && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                            <MenuItem key={index} value={item.projectId._id}>
                                    {item?.projectId?.name}({item?.companyId?.name})
                            </MenuItem>
                            ))}
                        </SelectField> 
                        {errorObj.project_id && <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>}
                    </Grid>
                    ):null
                    }
                        <Grid item lg={6} sm={12} xs={12}>
                            <Typography varient='h6' sx={{fontSize: '15px'}}>Upload Document</Typography>
                            <label style={{backgroundColor: '#f1f1f1', padding: '6px', borderRadius: '5px'}} className="cursor-pointer w-100">
                                <input
                                    type="file"
                                    id="profile2"
                                    onChange={handleChangeDocument}
                                />
                            </label>
                             {errorObj.labourCompliance_document && <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>}

                        </Grid>
                        <Grid item lg={6} xs={12}>
                        <Typography variant='h5' sx={{color: '#000', fontSize: 15, opacity: 'rgba(0, 0, 0, 0.87)'}}>Compliance Type</Typography>
                          <SelectField
                            value={labourCompilanceForm.Compliance_type}
                            onChange={handleChangeComplianceType}
                            >
                                <MenuItem value={"Form A"}>
                                    {"Form A"}
                                </MenuItem>
                                <MenuItem value={"Form B"}>
                                    {"Form B"}
                                </MenuItem>
                                <MenuItem value={"Form C"}>
                                    {"Form C"}
                                </MenuItem>
                                <MenuItem value={"Form D"}>
                                    {"Form D"}
                                </MenuItem>
                         </SelectField>
                         {errorObj.Compliance_type ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}

                    </Grid>
                        <Grid item lg={6} xs={12}>
                        <Typography variant='h5' sx={{color: '#000', fontSize: 15, opacity: 'rgba(0, 0, 0, 0.87)'}}>Month</Typography>
                          <SelectField
                            value={labourCompilanceForm.month}
                            onChange={handleChangeMonth}
                            >
                        {monthList && monthList.masterMonthTable && monthList.masterMonthTable.map((item,index) => (
                              <MenuItem value={item.mth} key={index}>
                                    {item.mth}
                              </MenuItem> 
                        ))}
                         </SelectField>
                         {errorObj.month ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Typography variant='h5' sx={{color: '#000', fontSize: 15, opacity: 'rgba(0, 0, 0, 0.87)'}}>Year</Typography>
                          <SelectField
                            value={labourCompilanceForm.year}
                            onChange={handleChangeYear}
                            >
                             {yearList && yearList.masterYearTable && yearList.masterYearTable.map((item,index) => (
                              <MenuItem value={item.yr} key={index}>
                                    {item.yr}
                              </MenuItem> 
                            ))}
                         </SelectField>
                         {errorObj.year ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}

                    </Grid>
                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography variant='' sx={{fontSize: '15px'}}>Note</Typography>
                            <TextareaAutosize
                                style={{
                                textAlign: "left",
                                width: "100%",
                                height: "80px",
                                background: '#f1f1f1',
                                borderRadius: '8px',
                                border: '1px solid #f1f1f1',
                                padding: '5px'
                                }}
                                name="note"
                                value={labourCompilanceForm.note}
                                maxRows={4}
                                aria-label="maximum height"
                                placeholder="Note Here.."
                                onChange={(e) => {
                                     delete errorObj.note;
                                    setErrorObj({...errorObj})
                                    labourCompilanceForm.note = e.target.value 
                                    setlabourCompilanceForm({...labourCompilanceForm})
                                }}
                               />
                            {errorObj.note ? <Typography sx={{color: 'red',fontSize: 12}}>This field is Required</Typography>:null}

                        </Grid>
                        <Grid container justifyContent="flex-end">
                            <Button sx={{mt: 2}}
                                color="primary"
                                variant="contained"
                                type="submit"
                                onClick={handleSubmit}
                                >
                             {loading?<CircularProgress color="inherit" size={20}/>:<>Submit</>}
                             
                            </Button>
                        </Grid>
                    </Grid>
            </Card>
       
    </Box>
    )
}