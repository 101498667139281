import React, {useEffect, useState} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
     Card, 
      Table, TableHead, TableRow, 
       TableCell,
       TableBody, Typography
} from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { ReportSelector } from 'selectors/ReportSelector';
import { format } from 'date-fns';
import PageTitle from "components/PageTitle";
import { GeneralSelector } from 'selectors';
import { ReportActions } from 'slices/actions';
import ListSkeleton from 'components/Skeleton/ListSkeleton';

export default function FormBResult() {
    const dispatch = useDispatch();
    const reportBData = useSelector(ReportSelector.getFormBData());
    const formData = useSelector(ReportSelector.getFormData());
    const loading = useSelector(GeneralSelector.loader(ReportActions.getReportData.type))
    const [formDate,setFormDate] = useState({
        date: "",
     });
    useEffect(() => {
        if (formData) {
           dispatch(ReportActions.getReportBData(formData)); 
        }
    },[formData])
   

    useEffect(() => {
        const tempDate = new Date();
        // let date = tempDate.getDate();
        // if (date < 10) {
        //     date = `0${date}`   
        // }
        let month = tempDate.getMonth() + 1;
        if (month < 10) {
            month = `0${month}`   
        }
        const year = tempDate.getFullYear()  
        const todayDate =`${year}-${month}`
        // formDate.date = todayDate
        formDate.date = `"Form B" ${todayDate}`
        setFormDate({...formDate})
    }, []);

    return (
        <>
            <PageTitle isBack={true} title="Form B" />
            {loading? (
                <ListSkeleton/>
            ):(
            <Card>
                <div style={{maxWidth: '67%', width: '100%', overflow: 'auto', height: '400px'}}>
                    {/* <p>{JSON.stringify(reportBData)}</p> */}
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button btn btn-primary mb-3"
                        table="table-to-xls"
                        filename={formDate.date}
                        sheet="tablexls"
                        buttonText="Export"
                    />
                    <div className=''>
                        <Table className="table Tableform FormTable" id="table-to-xls">
                            <TableHead className="thead-dark">
                                <TableRow className="Row_border_none">
                                    <TableCell colSpan='25' align='center'>
                                        <Typography variant="h6" sx={{fontSize: '15px', textAlign: 'center'}}>As per Ease of compliance to Maintain Register under various Labour Law Rules, 2017 Form B
                                        </Typography>
                                        FORM B <br/>
                                        FORMAT FOR WAGE REGISTER
                                    </TableCell>
                                </TableRow>
                                <TableRow className="Row_border_none">
                                    <TableCell colSpan='13' sx={{fontSize: 12}}>
                                        Name of the Establishment: {reportBData.companyname}< br/>
                                    </TableCell>
                                    <TableCell colSpan='12' align="right" sx={{fontSize: 12}}>
                                        Name of Owner: {reportBData.subContractorname}
                                    </TableCell>
                                </TableRow>
                                <TableRow className="Row_border_none">
                                    <TableCell colSpan='12' align='left' sx={{fontSize: 12}}>
                                        Nature and location of work: <br/>
                                    </TableCell>
                                    <TableCell colSpan='13' align='right' sx={{fontSize: 12}}>
                                        {/* Name and address of principal employer:- */}
                                        LIN : 
                                    </TableCell>
                                </TableRow>
                                <TableRow className="Row_border_none">
                                    <TableCell colSpan='13' sx={{fontSize: 11}}>
                                        Name and address of establishment in/under which contract is carried on:- {reportBData.projectname}
                                    </TableCell>
                                    <TableCell colSpan='12' align="right" sx={{fontSize: 11}}>
                                        Wage period From {''} 
                                        {reportBData.startDate && format(new Date(reportBData.startDate), 'dd/MM/yyyy')} to {''}   
                                        {reportBData.endDate && format(new Date(reportBData.endDate), 'dd/MM/yyyy')}
                                    </TableCell>
                                </TableRow>
                                <TableRow className='tableform'>
                                    <TableCell className="rotatetd" rowSpan='2'>Sl. No in Employee register</TableCell>
                                    <TableCell rowSpan='2' width='40%'>Name</TableCell> 
                                    <TableCell rowSpan='2' className="rotatetd">Per Day/Month</TableCell> 
                                    <TableCell rowSpan='2' className="rotatetd">No of Days Worked</TableCell> 
                                    <TableCell rowSpan='2' className="rotatetd">Overtime hours Worked</TableCell> 
                                    <TableCell rowSpan='2' className="rotatetd">Basic</TableCell> 
                                    <TableCell rowSpan='2' className="rotatetd">Special Basic</TableCell> 
                                    <TableCell rowSpan='2' className="rotatetd">DA</TableCell> 
                                    <TableCell rowSpan='2' className="rotatetd">Payments Overtime</TableCell> 
                                    <TableCell rowSpan='2' className="rotatetd">HRA</TableCell>
                                    <TableCell rowSpan='2' className="rotatetd">Others</TableCell> 
                                    <TableCell rowSpan='2' className="rotatetd">Total</TableCell> 
                                    <TableCell colSpan='8' align='center'>Deduction</TableCell> 
                                    <TableCell rowSpan='2' className="rotatetd">Net Payment</TableCell> 
                                    <TableCell rowSpan='2' className="rotatetd">Employer Share PF Welfare found</TableCell> 
                                    <TableCell rowSpan='2' className="rotatetd">Receipt By Employee/ Bank transaction ID</TableCell>
                                    <TableCell rowSpan='2' className="rotatetd">Date of Payment</TableCell>
                                    <TableCell rowSpan='2' className="rotatetd">Remark</TableCell>
                                </TableRow>
                                <TableRow className="deductdetails tableform">
                                    <TableCell className="rotatetd">PF</TableCell>
                                    <TableCell className="rotatetd">ESIC</TableCell>
                                    <TableCell className="rotatetd">Society</TableCell>
                                    <TableCell className="rotatetd">Income Tax</TableCell>
                                    <TableCell className="rotatetd">Insurance</TableCell>
                                    <TableCell className="rotatetd">Others</TableCell>
                                    <TableCell className="rotatetd">Recoveries</TableCell>
                                    <TableCell className="rotatetd">Total</TableCell>
                                </TableRow>
                                <TableRow className="tableform">
                                    <TableCell>1</TableCell>
                                    <TableCell>2</TableCell>
                                    <TableCell>3</TableCell>
                                    <TableCell>4</TableCell>
                                    <TableCell>5</TableCell>
                                    <TableCell>6</TableCell>
                                    <TableCell>7</TableCell>
                                    <TableCell>8</TableCell>
                                    <TableCell>9</TableCell>
                                    <TableCell>10</TableCell>
                                    <TableCell>11</TableCell>
                                    <TableCell>12</TableCell>
                                    <TableCell>13</TableCell>
                                    <TableCell>14</TableCell>
                                    <TableCell>15</TableCell>
                                    <TableCell>16</TableCell>
                                    <TableCell>17</TableCell>
                                    <TableCell>18</TableCell>
                                    <TableCell>19</TableCell>
                                    <TableCell>20</TableCell>
                                    <TableCell>21</TableCell>
                                    <TableCell>22</TableCell>
                                    <TableCell>23</TableCell>
                                    <TableCell>24</TableCell>
                                    <TableCell>25</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reportBData && reportBData.data && reportBData.data.map((res,index) => <TableRow 
                                    key={index} className="tableform">
                                    <TableCell align='center'>{index+1}</TableCell>
                                    <TableCell width="40%">
                                        {res.firstName} {res.lastName}
                                    </TableCell>
                                    <TableCell>{res.wagesRate}</TableCell>
                                    <TableCell>{res.noOfDaysWorked}</TableCell>
                                    <TableCell>{res.overtimeWorked}</TableCell>
                                    <TableCell>{res.basic}</TableCell>
                                    <TableCell>{res.specialBasic}</TableCell>
                                    <TableCell>{res.DA}</TableCell>
                                    <TableCell>{res.paymentsOvertime}</TableCell>
                                    <TableCell>{res.HRA}</TableCell>
                                    <TableCell>{res.others}</TableCell>
                                    <TableCell>{res.total}</TableCell>
                                    <TableCell>{res.PF}</TableCell>
                                    <TableCell>{res.ESIC}</TableCell>
                                    <TableCell>{res.society}</TableCell>
                                    <TableCell>{res.incomeTax}</TableCell>
                                    <TableCell>{res.insurance}</TableCell>
                                    <TableCell>{res.deducation_otheres}</TableCell>
                                    <TableCell>{res.recoveries}</TableCell>
                                    <TableCell>{res.deducationTotal}</TableCell>
                                    <TableCell>{res.netPayment}</TableCell>
                                    <TableCell>{res.employementSharePfWalfareFund}</TableCell>
                                    <TableCell>{res.bankTransactionID}</TableCell>
                                    <TableCell>{res.dateofPayment}</TableCell>
                                    <TableCell>{res.remark}</TableCell>
                                </TableRow>)}
                            </TableBody>   
                        </Table>
                    </div>
                </div>
            </Card>)}
        </>
    )
}