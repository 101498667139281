import React, { useEffect } from "react";
import {
    // Card,
    Grid,
    Typography,
    MenuItem,
} from "@mui/material";
import Input from "components/Input";
import SelectField from "components/SelectField";
import PropTypes from "prop-types";
import { LabourSelector } from "selectors";
import {useDispatch, useSelector} from "react-redux";
import { LabourActions } from "slices/actions";

SkillInformation.propTypes = {
    formik: PropTypes.object,
};

export default function SkillInformation(props) {
    const { formik } = props;
    const dispatch = useDispatch();
    const skillType = ["Unskilled", "Skilled", "Semi-Skilled", "Highly Skilled"]
    const skillCategoryList = useSelector(LabourSelector.getSkillCategory());

    useEffect(() => {
        dispatch(LabourActions.getSkillCategory())
    },[])
    
    return (
        <>
            <Typography variant='h5' sx={{ my: 4, position: 'relative', textAlign: "left", borderBottom: "1px solid #ddd", fontWeight: "normal" }}>Skill Info</Typography>
           
            <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                    <Typography varient='h6' sx={{fontSize: '15px', color: '#222'}}>Skill Category *</Typography>
                    <SelectField
                        className="text-left"
                        name="skill_catagory"
                        value={formik.values.skill_catagory}
                        onChange={formik.handleChange}
                        error={formik.touched.skill_catagory && Boolean(formik.errors.skill_catagory)}
                        helperText={formik.touched.skill_catagory && formik.errors.skill_catagory}
                        >
                        {skillCategoryList && skillCategoryList.masterSkillInfo && skillCategoryList.masterSkillInfo.map((item,index) => (
                            <MenuItem key={index} value={item.type} >
                                {item.type}
                            </MenuItem>
                        ))} 
                      </SelectField>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Typography varient='h6' sx={{fontSize: '15px', color: '#222'}}>Skill Type *</Typography>
                    <SelectField
                        className="text-left"
                        name="skillType"
                        value={formik.values.skillType}
                        onChange={formik.handleChange}
                        error={formik.touched.skillType && Boolean(formik.errors.skillType)}
                        helperText={formik.touched.skillType && formik.errors.skillType}
                        >
                        {skillType.map((item,index) => (
                            <MenuItem key={index} value={item} >
                                {item}
                            </MenuItem>
                            ))} 
                        
                    </SelectField>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Typography varient='h6' sx={{fontSize: '15px', color: '#222'}}>Type of Employment *</Typography>
                    <SelectField
                        className="text-left"
                        name="employeementType"
                        value={formik.values.employeementType}
                        onChange={formik.handleChange}
                        error={formik.touched.employeementType && Boolean(formik.errors.employeementType)}
                        helperText={formik.touched.employeementType && formik.errors.employeementType}
                    >
                            <MenuItem value={'Daily Wages'}>Daily Wages</MenuItem>
                            <MenuItem value={'Fixed Monthly Salary'}>Fixed Monthly Salary</MenuItem>
                        
                    </SelectField>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        fullWidth
                        label="Date of Joining *"
                        type="date"
                        name="doj"
                        value={formik.values.doj.slice(0,10)}
                        onChange={formik.handleChange}
                        error={formik.touched.doj && Boolean(formik.errors.doj)}
                        helperText={formik.touched.doj && formik.errors.doj}
                        />
                </Grid>
                    
             </Grid> 
          </>
    )
}