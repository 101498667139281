import {all, fork} from 'redux-saga/effects'
import {AuthWatcher} from "./AuthSaga";
import {UserWatcher} from "./UserSaga";
import {AttendanceWatcher} from "./AttendanceSaga";
import {DepartmentWatcher} from "./DepartmentSaga";
import {DesignationWatcher} from "./DesignationSaga";
import {ExpenseWatcher} from "./ExpenseSaga";
import {LeaveWatcher} from "./LeaveSaga";
import {SettingWatcher} from "./SettingSaga";
import { StateWatcher } from './StateSaga';
import { CityWatcher } from './CitySaga';
import { CompanyWatcher } from './Companysaga';
import { ProjectWatcher } from './ProjectSaga';
import { SiteWatcher } from './SiteSaga';
import { TransactionWatcher } from './TransactionSaga';
import { LabourWatcher } from './LabourSaga';
import { MasterDataWatcher } from './MasterDataSaga';
import { SubContractorWatcher } from './SubContractorSaga';
import { ContractorWatcher } from './ContractorSaga';
import { ReportWatcher } from './ReportSaga';
import { HolidayWatcher } from './HolidaySaga';


export default function *rootSaga() {
    yield all([
        AuthWatcher,
        UserWatcher,
        AttendanceWatcher,
        DepartmentWatcher,
        DesignationWatcher,
        ExpenseWatcher,
        LeaveWatcher,
        StateWatcher,
        SettingWatcher,
        CityWatcher,
        ProjectWatcher,
        CompanyWatcher,
        SiteWatcher,
        TransactionWatcher,
        LabourWatcher,
        MasterDataWatcher,
        SubContractorWatcher,
        ContractorWatcher,
        ReportWatcher,
        HolidayWatcher
        
    ].map(fork));
}