import AuthSlice from "./slice/AuthSlice";
import UserSlice from "./slice/UserSlice";
import DepartmentSlice from "./slice/DepartmentSlice";
import DesignationSlice from "./slice/DesignationSlice";
import AttendanceSlice from "./slice/AttendanceSlice";
import ExpensesSlice from "./slice/ExpensesSlice";
import LeaveSlice from "./slice/LeaveSlice";
import GeneralSlice from "./slice/GeneralSlice";
import SettingSlice from "./slice/SettingSlice";
import StateSlice from "./slice/StateSlice";
import CitySlice from "./slice/CitySlice";
import CompanySlice from "./slice/CompanySlice";
import ProjectSlice from "./slice/ProjectSlice";
import SiteSlice from "./slice/SiteSlice";
import TransactionSlice from "./slice/TransactionSlice";
import LabourSlice from "./slice/LabourSlice";
import MasterDataSlice from "./slice/MasterDataSlice";
import SubContractorSlice from "./slice/SubContractorSlice";
import ContractorSlice from "./slice/ContractorSlice";
import ReportSlice from "./slice/ReportSlice";
import HolidaySlice from "./slice/HolidaySlice";

const reducers = {
    general: GeneralSlice.reducer,
    auth: AuthSlice.reducer,
    user: UserSlice.reducer,
    department: DepartmentSlice.reducer,
    designation: DesignationSlice.reducer,
    attendance: AttendanceSlice.reducer,
    expenses: ExpensesSlice.reducer,
    leave: LeaveSlice.reducer,
    setting: SettingSlice.reducer,
    state: StateSlice.reducer,
    city: CitySlice.reducer,
    company: CompanySlice.reducer,
    project: ProjectSlice.reducer,
    site: SiteSlice.reducer,
    transaction: TransactionSlice.reducer,
    labour: LabourSlice.reducer,
    masterData: MasterDataSlice.reducer,
    subContractor: SubContractorSlice.reducer,
    contractor: ContractorSlice.reducer,
    report: ReportSlice.reducer,
    holiday: HolidaySlice.reducer
};

export default reducers;