import {all, call, put, takeLatest} from 'redux-saga/effects'
import {AttendanceService} from "../services";
import {AttendanceActions, GeneralActions} from "../slices/actions";

function *getAttendances({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const params = {
        date: payload.date?payload.date:"",  
        employementType: !payload.employementType || payload.employementType === "All" ?"":payload.employementType,
        employeQ: payload.employeQ?payload.employeQ :"",
        companyName: payload.companyName?payload.companyName :"",
        projectName: payload.projectName?payload.projectName :"",
        subcontractorName: payload.subcontractorName?payload.subcontractorName:"",
        page: payload.page?payload.page:"",
        limit: 20,
        flag: payload.flag === "mainAttendance" ? 'mainAttendance' : "subAttendance",
        attendance: payload.attendance?payload.attendance:"",
        }
        
        const result = yield call(AttendanceService.GetAttendances,params);

        yield put(AttendanceActions.getAttendancesSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}

function *getAttendanceById({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(AttendanceService.GetAttendanceById, payload);

        yield put(AttendanceActions.getAttendanceByIdSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *createAttendance({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const flag = payload.flag === "mainAttendance" ? 'mainAttendance' : "subAttendance" 

        const result = yield call(AttendanceService.CreateAttendance, payload,flag);

        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *updateAttendance({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(AttendanceService.UpdateAttendance, payload.id, payload);
        console.log('result',)
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}

function *createMyAttendance({ type, payload }) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(AttendanceService.createMyAttendance, payload);

        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *deleteAttendance({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(AttendanceService.DeleteAttendance, payload);

        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}

function *attendanceBulkUpload({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(AttendanceService.UpdateAttendanceDocument,payload);
        yield put(AttendanceActions.attendanceBulkUploadSucess(result.data));
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *monthlyAttendanceUpdate({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(AttendanceService.MonthyAttendanceUpdate,payload);
        const successData = result.data
        yield put(GeneralActions.addSuccess({
            action: type,
            message: successData
        }));

        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *monthlyMyAttendanceUpdate({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const flag =payload.flag === "mainAttendance" ? "mainAttendance" : "subAttendance"
        const result = yield call(AttendanceService.monthlyMyAttendanceUpdate,payload,flag);
        const successData = result.data
        yield put(GeneralActions.addSuccess({
            action: type,
            message: successData
        }));

        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

export function *AttendanceWatcher() {
    yield all([
        yield takeLatest(AttendanceActions.getAttendances.type, getAttendances),
        yield takeLatest(AttendanceActions.getAttendanceById.type, getAttendanceById),
        yield takeLatest(AttendanceActions.createAttendance.type, createAttendance),
        yield takeLatest(AttendanceActions.createMyAttendance.type, createMyAttendance),
        yield takeLatest(AttendanceActions.updateAttendance.type, updateAttendance),
        yield takeLatest(AttendanceActions.deleteAttendance.type, deleteAttendance),
        yield takeLatest(AttendanceActions.attendanceBulkUpload.type, attendanceBulkUpload),
        yield takeLatest(AttendanceActions.monthlyAttendanceUpdate.type, monthlyAttendanceUpdate),
        yield takeLatest(AttendanceActions.monthlyMyAttendanceUpdate.type, monthlyMyAttendanceUpdate)
    ]);
}