import React from 'react';
import FormIndex from '../FormIndex';


export default function FormC() {
  return (
    <>
    <FormIndex 
      formData={"formC"}
      title = {"Register Of Loan/ Recoveries (Form C)"}
    />
</>
    )
}