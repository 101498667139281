import React,{useEffect} from "react";
import {
    Button,
    // Card,
    CircularProgress,
    Grid,
    Typography,
    Link
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import { GeneralActions, LabourActions} from "slices/actions";
import {useFormik} from "formik";
import Input from "components/Input";
import { GeneralSelector, LabourSelector } from "selectors";
import * as yup from "yup";
import { useParams } from "react-router-dom";

export default function PanCard() {
    const {id} = useParams();
    const labourDetails = useSelector(LabourSelector.getLabourById());
    const success = useSelector(GeneralSelector.success(LabourActions.updateLabourPanCard.type));
    const error = useSelector(GeneralSelector.error(LabourActions.updateLabourPanCard.type))
    const loading = useSelector(GeneralSelector.loader(LabourActions.updateLabourPanCard.type))
    const dispatch = useDispatch();
    const validationSchema = yup.object({
        panCard: yup.string().matches("[A-Z]{5}[0-9]{4}[A-Z]{1}","InValid PAN No").
        required('This field is required'),
        pan_Document: yup.string().required('This field is required'),
    });
    const formik = useFormik({
        initialValues: {
            panCard: labourDetails?.result?.panCard??"",
            pan_Document: labourDetails?.result?.pan_Document??"",
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(LabourActions.updateLabourPanCard.type));
            dispatch(LabourActions.getLabourById(id));

        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeError(LabourActions.updateLabourPanCard.type));
        }
    }, [error]);

    const handleSubmit = (values) => {
        const params = {
            id: id,
            ...values
        }
        dispatch(LabourActions.updateLabourPanCard(params));
    }
    const handleChangeImage = (e) => {
        const file = e.target.files[0];
        formik.setFieldValue('pan_Document',file)
    };

    return (
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={12} xs={12}>
                        <Typography variant="h6" sx={{textAlign: "left", borderBottom: "1px solid #ddd", m: "0", fontWeight: "normal"}}>Pan Card</Typography>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Pan Card *"
                            name='panCard'
                            value={formik.values.panCard}
                            onChange={formik.handleChange}
                            error={formik.touched.panCard && Boolean(formik.errors.panCard)}
                            helperText={formik.touched.panCard && formik.errors.panCard}
                            />
                    </Grid>
                    <Grid item lg={6} xs={12} className="kycpicker text-center uploadinputphoto">
                        <Typography sx={{fontSize: '13', color: '#fff'}}>Upload Pan Card</Typography>
                        <Input
                            type="file"
                            id="profile"
                            onChange={handleChangeImage}
                            error={formik.touched.pan_Document && Boolean(formik.errors.pan_Document)}
                            helperText={formik.touched.pan_Document && formik.errors.pan_Document}
                        />
                        {labourDetails?.result?.pan_Document?(
                                <Link href={labourDetails?.result?.pan_Document} target='_blank' >Pan Card Uploaded</Link>
                            ):(
                                null)
                         }
                     </Grid>
                     {labourDetails?.result?.status !== 99 ?(
                      <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained">
                               {loading?(
                                   <CircularProgress color="inherit" size={20}/>
                                ):(
                                  <>Update</>  
                                )}
                            </Button>

                        </Grid>):null}
                        
                </Grid>
            </form>
    )
}