import React, { useEffect,useState } from "react";
import {
    Grid, Button, Typography, MenuItem, CircularProgress
} from "@mui/material";
import PropTypes from 'prop-types';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import SelectField from "components/SelectField";
import { useDispatch, useSelector} from "react-redux";
import { Constants } from "utils/constants";
import { CompanyActions, ProjectActions, SubContractorActions } from "slices/actions";
import { CompanySelector } from "selectors/CompanySelector";
import { GeneralSelector, ProjectSelector } from "selectors";
import { useParams } from "react-router-dom";

SubContractorAssignDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
   
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
export default function SubContractorAssignDialog(props) {
    const { open, onClose } = props
    const {id} = useParams();
    const dispatch = useDispatch();
    const userType = localStorage.getItem('userType');
    const companyId = localStorage.getItem('companyID');
    const companyList = useSelector(CompanySelector.getCompanyList());
    const projectList = useSelector(ProjectSelector.getProjectList());
    const [subcontractorAssign,setSubcontractorAssign] = useState({
        company: companyId?companyId:"",
        project: ""
      })
   const loading = useSelector(GeneralSelector.loader(SubContractorActions.assignSubContractor.type));
   useEffect(() => {
        dispatch(CompanyActions.getCompanyList());
    },[])

    useEffect(() => {
        if (subcontractorAssign.company || companyId) {
            const params = {
                company_id: subcontractorAssign.company ? subcontractorAssign.company: companyId
            }
            
            dispatch(ProjectActions.getProjectList(params))
        }
    },[subcontractorAssign.company])

    const handleChangeCompany = (e) => {
        const cId = e.target.value;
        subcontractorAssign.company = cId;
        setSubcontractorAssign({...subcontractorAssign})
     }   
      const handleChangeProject = (e) => {
        if (subcontractorAssign.company) {
        const pId = e.target.value
        subcontractorAssign.project = pId;
        setSubcontractorAssign({...subcontractorAssign})
        } 
      }
      const onSubmit = () => {
        const params = {
            companyId: subcontractorAssign.company || companyId,
            projectId: subcontractorAssign.project,
            subContractorId: id
        }
        dispatch(SubContractorActions.assignSubContractor(params))
      
    }
    return (
        <div className="IdcardDialog">
            <BootstrapDialog
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={onClose} sx={{ borderBottom: '1px solid #ccc' }}>
                   Assigned SubContractor
                </DialogTitle>
                <DialogContent>
                        <Grid container spacing={2} sx={{ pt: 4 }}>
                            {userType !== Constants.COMPANY_USER && userType !== Constants.PROJECT_USER?(
                            <Grid item lg={6} sm={12} xs={12}>
                                <Typography variant='h6' sx={{fontSize: 15}}>Company</Typography>
                                <SelectField
                                    value={subcontractorAssign.company}
                                    onChange={handleChangeCompany}
                                     >
                                      {companyList && companyList.map((item,index) => (
                                        <MenuItem value={item._id} key={index}>
                                            {item.name}
                                        </MenuItem>
                                      ))}
                                </SelectField>
                            </Grid>
                            ):null}
                          {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER?(
                            <Grid item lg={6} sm={12} xs={12}>
                                <Typography variant='h6' sx={{fontSize: 15}}>Project</Typography>
                                <SelectField
                                    value={subcontractorAssign.project}
                                    onChange={handleChangeProject}
                                  >
                                   {projectList && projectList.map((item,index) => (
                                        <MenuItem value={item._id} key={index}>
                                            {item.name}
                                        </MenuItem>
                                      ))}
                                </SelectField>
                            </Grid>
                              ):null}
                        </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={onClose} variant="contained" color="primary">
                        Close
                    </Button>
                    <Button autoFocus onClick={onSubmit} variant="contained" color="primary">
                    {loading?<CircularProgress color="inherit" size={20}/>:<>Submit</>}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )

}