import React, {useEffect, useState} from "react";
import {
    Box,
    Card,
    FormControl,
    MenuItem,
    Grid,
    Typography,
} from "@mui/material";
import Input from "components/Input";
import {useDispatch,useSelector} from "react-redux";
import PropTypes from "prop-types";
import SelectField from "components/SelectField";
import { CompanyActions, MasterDataActions, ProjectActions, SubContractorActions} from "slices/actions";
import { CompanySelector } from "selectors/CompanySelector";
import { MasterDataSelector, ProjectSelector, SubContractorSelector } from "selectors";
import { Constants } from "utils/constants";

BasicInformation.propTypes = {
    formik: PropTypes.object
};

export default function BasicInformation(props) {
    const { formik } = props;
    const dispatch = useDispatch();
    const locationData = useSelector(CompanySelector.getLocationList());
    const natureOfWork = useSelector(MasterDataSelector.getNatureOfWork());
    const [pincode,setPincode]=useState();
    const companyList = useSelector(CompanySelector.getCompanyList());
    const projectList = useSelector(ProjectSelector.getProjectList());
    const subcontractorDetails = useSelector(SubContractorSelector.getDataByPancard());
    const [pancardId,setPancardId] = useState();
    const userType = localStorage.getItem('userType');
    const companyId = localStorage.getItem('companyID');
    const projectId = localStorage.getItem('projetID');

    useEffect(() => {
        const params = {
            data: null
        }
        dispatch(SubContractorActions.getDataByPancardSuccess(params))
    },[])

    useEffect(() => {
        dispatch(MasterDataActions.getNatureOfWork());
        dispatch(CompanyActions.getCompanyList());
        if (companyId && !projectId) {
            const params = {
                company_id: companyId ? companyId: "",
                status: 1
            }
          dispatch(ProjectActions.getProjectList(params))
        }
    }, []);
  
   const handleChangePinCode= (e) => {
        const pincodeData = e.target.value
        formik.setFieldValue('pincode',pincodeData)
        setPincode(pincodeData);
    }
    useEffect(() => {
        if (pincode && pincode.length === 6) {
            dispatch(CompanyActions.fetchDataByPincode(pincode));
        }
    }, [pincode]);
    useEffect(() => {
        if (locationData && pincode) {
        formik.setFieldValue('state', locationData.StateName?locationData.StateName :'');
        formik.setFieldValue('city', locationData.District?locationData.District :'');
        formik.setFieldValue('address', locationData.PlaceName?locationData.PlaceName :'');
        }
    }, [locationData]);

    const handleChangeCompany = (e) => {
        const cId = e.target.value
        formik.setFieldValue('company', cId);
        const params = {
            company_id: cId
        }
        dispatch(ProjectActions.getProjectList(params))
    }
    const handleChangePanCard = (e) => {
       const panId = e.target.value 
       formik.setFieldValue('pan', panId)
      
       if (panId.length === 10) {
        setPancardId(panId);
        const params = {
            pan: panId
          }
       dispatch(SubContractorActions.getDataByPancard(params))
       }
    }
    useEffect(() => {
        if (subcontractorDetails && subcontractorDetails.userData && pancardId) {
          formik.setFieldValue('subContractorCode', subcontractorDetails.userData.subContractorCode)
          formik.setFieldValue('subContractorName', subcontractorDetails.userData.subContractorName)
          formik.setFieldValue('subContractorEmail', subcontractorDetails.userData.subContractorEmail)
          formik.setFieldValue('subContractorPhone', subcontractorDetails.userData.subContractorPhone)
          formik.setFieldValue('pan', subcontractorDetails.userData.pan)
          formik.setFieldValue('doj', subcontractorDetails.userData.doj)
          formik.setFieldValue('gst', subcontractorDetails.userData.gst)
          formik.setFieldValue('natureOfWork', subcontractorDetails.userData.natureOfWork)
          formik.setFieldValue('workLocation', subcontractorDetails.userData.workLocation)
          formik.setFieldValue('venderCode', subcontractorDetails.userData.venderCode)
          formik.setFieldValue('pincode', subcontractorDetails.userData.pincode)
          formik.setFieldValue('state', subcontractorDetails.userData.state)
          formik.setFieldValue('city', subcontractorDetails.userData.city)
          formik.setFieldValue('address', subcontractorDetails.userData.address)
          formik.setFieldValue('propriterName', subcontractorDetails.userData.propriterName)
          formik.setFieldValue('supervisorName', subcontractorDetails.userData.supervisorName)
          formik.setFieldValue('bankName', subcontractorDetails.userData.bankName)
          formik.setFieldValue('bankAccountNo', subcontractorDetails.userData.bankAccountNo)
          formik.setFieldValue('ifscCode', subcontractorDetails.userData.ifscCode)
          formik.setFieldValue('supervisorMobileNo', subcontractorDetails.userData.supervisorMobileNo)
          formik.setFieldValue('scopeOfBocw', subcontractorDetails.userData.scopeOfBocw)
          formik.setFieldValue('bocwValidUpto', subcontractorDetails.userData.bocwValidUpto)
          formik.setFieldValue('scopeOfGPA_WCPolicy', subcontractorDetails.userData.scopeOfGPA_WCPolicy)
          formik.setFieldValue('gpa_WcNo', subcontractorDetails.userData.gpa_WcNo)
          formik.setFieldValue('gpa_WcValidityFrom', subcontractorDetails.userData.gpa_WcValidityFrom)
          formik.setFieldValue('gpa_WcValidityTo', subcontractorDetails.userData.gpa_WcValidityTo)
          formik.setFieldValue('scopeOfLabourLicence', subcontractorDetails.userData.scopeOfLabourLicence)
          formik.setFieldValue('labourLicenceNo', subcontractorDetails.userData.labourLicenceNo)
          formik.setFieldValue('labourLicenceValidity', subcontractorDetails.userData.labourLicenceValidity)
          formik.setFieldValue('scopeOfPfRegistration', subcontractorDetails.userData.scopeOfPfRegistration)
          formik.setFieldValue('pfRegistrationNo', subcontractorDetails.userData.pfRegistrationNo)
          formik.setFieldValue('company', subcontractorDetails.userData.companyId)
          if (subcontractorDetails.userData.companyId) {
            const params = {
                company_id: subcontractorDetails.userData.companyId ? subcontractorDetails.userData.companyId: ""
            }
            dispatch(ProjectActions.getProjectList(params))
            formik.setFieldValue('project_id', subcontractorDetails.userData.projectId)
         }
        }   
    },[subcontractorDetails])

    return (
        <Card sx={{ mb: 3 }}>
            <Typography variant='h5' sx={{ mb: 4 }}>Basic Information</Typography>
            <Grid container spacing={2}>
               <Grid item lg={6} xs={12}>
                    <Input
                        label="PAN"
                        name='pan'
                        value={formik.values.pan}
                        onChange={handleChangePanCard}
                        error={Boolean(formik.touched.pan) && Boolean(formik.errors.pan)}
                        helperText={formik.touched.pan && formik.errors.pan}
                        />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Code *"
                        name='subContractorCode'
                        value={formik.values.subContractorCode}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.subContractorCode) && Boolean(formik.errors.subContractorCode)}
                        helperText={formik.touched.subContractorCode && formik.errors.subContractorCode}
                        disabled={subcontractorDetails && subcontractorDetails.userData && subcontractorDetails.userData.subContractorCode }
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Name *"
                        name='subContractorName'
                        value={formik.values.subContractorName}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.subContractorName) && Boolean(formik.errors.subContractorName)}
                        helperText={formik.touched.subContractorName && formik.errors.subContractorName}
                        disabled={subcontractorDetails && subcontractorDetails.userData && subcontractorDetails.userData.subContractorName }
                        />
                </Grid>
                
                <Grid item lg={6} xs={12}>
                    <FormControl fullWidth>
                        <Typography variant='' sx={{fontSize: "15px"}}>Phone Number </Typography>
                        <Box sx={{
                            display: 'flex',
                            gap: 1.5
                        }}>
                            <Box sx={{ width: 90 }}>
                                <Input style={{appearence: 'none'}}
                                    sx={{
                                        padding: "10px 8px",
                                        textAlign: 'center',
                                        // '& .Mui-disabled': {
                                        //     fillColor: theme.palette.common.black
                                        // }
                                    }}
                                    autoComplete='new-password'
                                    name='phoneCode'
                                    startAdornment='+'
                                    value="91"
                                    />
                            </Box>
                            <Input
                                name='subContractorPhone'
                                value={formik.values.subContractorPhone}
                                onChange={formik.handleChange}
                                error={formik.touched.subContractorPhone && Boolean(formik.errors.subContractorPhone)}
                                helperText={formik.touched.subContractorPhone && formik.errors.subContractorPhone}
                                disabled={subcontractorDetails && subcontractorDetails.userData && subcontractorDetails.userData.subContractorPhone }
                                />
                        </Box>
                    </FormControl>
                </Grid>

                {userType === Constants.SUPERADMIN ?(
                <Grid item lg={6} xs={12}>
                <Typography variant='h6' sx={{fontSize: 15}}>Company</Typography>
                <SelectField
                    value={formik.values.company}
                    name='company'
                    onChange={handleChangeCompany}
                    error={formik.touched.company && Boolean(formik.errors.company)}
                    helperText={formik.touched.company && formik.errors.company}
                    disabled={subcontractorDetails && subcontractorDetails.userData && subcontractorDetails.userData.companyId }
                     >
                    {companyList && companyList.map((item,index) => (
                    <MenuItem value={item._id} key={index}>
                      {item.name}
                    </MenuItem>
                    ))}
                </SelectField>
                </Grid>):null} 

                {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER ?(
                <Grid item lg={6} xs={12}>
                <Typography variant='h6' sx={{fontSize: 15}}>Project</Typography>
                <SelectField
                    value={formik.values.project_id}
                    name='project_id'
                    onChange={formik.handleChange}
                    error={formik.touched.project_id && Boolean(formik.errors.project_id)}
                    helperText={formik.touched.project_id && formik.errors.project_id}
                    disabled={subcontractorDetails && subcontractorDetails.userData && subcontractorDetails.userData.project_id }
                    >
                    {(formik.values.company && projectList) && projectList.map((item,index) => (
                    <MenuItem value={item._id} key={index}>
                      {item.name}
                    </MenuItem>
                    ))}
                </SelectField>
                </Grid>):null}  

                <Grid item lg={6} xs={12}>
                    <Input
                        label="Date of Joining"
                        name='doj'
                        type= 'date'
                        value={formik.values.doj.slice(0,10)}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.doj) && Boolean(formik.errors.doj)}
                        helperText={formik.touched.doj && formik.errors.doj}
                        disabled={subcontractorDetails && subcontractorDetails.userData && subcontractorDetails.userData.doj }
                        />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="GST"
                        name='gst'
                        value={formik.values.gst}
                        onChange={formik.handleChange}
                        error={formik.touched.gst && Boolean(formik.errors.gst)}
                        helperText={formik.touched.gst && formik.errors.gst}
                        disabled={subcontractorDetails && subcontractorDetails.userData && subcontractorDetails.userData.gst }
                        />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Typography variant='h5' sx={{color: '#000', fontSize: 15, opacity: 'rgba(0, 0, 0, 0.87)'}}>Nature Of Work</Typography>
                    <SelectField
                        label= ""
                        name= "natureOfWork"
                        value={formik.values.natureOfWork}
                        onChange={formik.handleChange}
                        error={formik.touched.natureOfWork && Boolean(formik.errors.natureOfWork)}
                        helperText={formik.touched.natureOfWork && formik.errors.natureOfWork}
                        disabled={subcontractorDetails && subcontractorDetails.userData && subcontractorDetails.userData.natureOfWork }
                        >
                        {natureOfWork.subcontractorScopesofWork && natureOfWork.subcontractorScopesofWork.map((item,index) => (
                            <MenuItem key={index} value={item.id}>
                                {item.level}
                            </MenuItem>
                        ))}
                      
                       
                    </SelectField>
                </Grid>
                
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Office Address"
                        name='workLocation'
                        value={formik.values.workLocation}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.workLocation) && Boolean(formik.errors.workLocation)}
                        helperText={formik.touched.workLocation && formik.errors.workLocation}
                        disabled={subcontractorDetails && subcontractorDetails.userData && subcontractorDetails.userData.natureOfWork }
                        />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Vender Code(VO)"
                        name='venderCode'
                        value={formik.values.venderCode}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.venderCode) && Boolean(formik.errors.venderCode)}
                        helperText={formik.touched.venderCode && formik.errors.venderCode}
                        disabled={subcontractorDetails && subcontractorDetails.userData && subcontractorDetails.userData.natureOfWork }
                     />
                </Grid>
                
                <Grid item lg={6} xs={12}>
                    <Input
                        label='Pincode'
                        name='pincode'
                        type='number'
                        value={formik.values.pincode}
                        onChange={ (e) => handleChangePinCode(e)}
                        error={formik.touched.pincode && Boolean(formik.errors.pincode)}
                        helperText={formik.touched.pincode && formik.errors.pincode}
                        disabled={subcontractorDetails && subcontractorDetails.userData && subcontractorDetails.userData.pincode }
                     />
                </Grid>
            
                <Grid item lg={6} xs={12}>
                     <Input
                        label="State"
                        name='state'
                        value={formik.values.state}
                        error={formik.touched.state && Boolean(formik.errors.state)}
                        helperText={formik.touched.state && formik.errors.state}
                        readonly
                        disabled={subcontractorDetails && subcontractorDetails.userData && subcontractorDetails.userData.state }
                        />
                </Grid>
                <Grid item lg={6} xs={12}>
                   <Input
                        label="City"
                        name='city'
                        value={formik.values.city}
                        error={formik.touched.city && Boolean(formik.errors.city)}
                        helperText={formik.touched.city && formik.errors.city}
                        readonly
                        disabled={subcontractorDetails && subcontractorDetails.userData && subcontractorDetails.userData.city}
                        />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Address"
                        name='address'
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        error={formik.touched.address && Boolean(formik.errors.address)}
                        helperText={formik.touched.address && formik.errors.address}
                        disabled={subcontractorDetails && subcontractorDetails.userData && subcontractorDetails.userData.address}
                        />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Proprietor Name"
                        name='propriterName'
                        value={formik.values.propriterName}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.propriterName) && Boolean(formik.errors.propriterName)}
                        helperText={formik.touched.propriterName && formik.errors.propriterName}
                        disabled={subcontractorDetails && subcontractorDetails.userData && subcontractorDetails.userData.propriterName}
                     />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Supervisor Name"
                        name='supervisorName'
                        value={formik.values.supervisorName}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.supervisorName) && Boolean(formik.errors.supervisorName)}
                        helperText={formik.touched.supervisorName && formik.errors.supervisorName}
                        disabled={subcontractorDetails && subcontractorDetails.userData && subcontractorDetails.userData.propriterName}
                        />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <FormControl fullWidth>
                        <Typography variant='' sx={{fontSize: "15px"}}>Supervisor Phone No</Typography>
                        <Box sx={{
                            display: 'flex',
                            gap: 1.5
                        }}>
                            <Box sx={{ width: 90 }}>
                                <Input style={{appearence: 'none'}}
                                    sx={{
                                        padding: "10px 8px",
                                        textAlign: 'center',
                                        // '& .Mui-disabled': {
                                        //     fillColor: theme.palette.common.black
                                        // }
                                    }}
                                    autoComplete='new-password'
                                    name='phoneCode'
                                    startAdornment='+'
                                    value="91"
                                    />
                            </Box>
                            <Input
                                name='supervisorMobileNo'    
                                value={formik.values.supervisorMobileNo}
                                onChange={formik.handleChange}
                                error={formik.touched.supervisorMobileNo && Boolean(formik.errors.supervisorMobileNo)}
                                helperText={formik.touched.supervisorMobileNo && formik.errors.supervisorMobileNo}
                                disabled={subcontractorDetails && subcontractorDetails.userData && subcontractorDetails.userData.supervisorMobileNo}
                             />
                        </Box>
                    </FormControl>
                    </Grid>
                   
            </Grid>
        </Card>
    )
}