import React,{useEffect,useState} from "react";
import { Box, Button, Card, CircularProgress, Grid, MenuItem, Typography } from "@mui/material";
import FormSkeleton from "../../components/Skeleton/FormSkeleton";
import PageTitle from "components/PageTitle";
import * as yup from "yup";
import { useFormik } from "formik";
import { ProjectSelector, GeneralSelector, HolidaySelector, SubContractorSelector } from "selectors";
import { useDispatch, useSelector } from "react-redux";
import SelectField from "components/SelectField";
import { ProjectActions, CompanyActions, HolidayActions, GeneralActions, SubContractorActions,} from "slices/actions";
import Input from "components/Input";
import {useParams, useHistory} from "react-router-dom";
import { CompanySelector } from "selectors/CompanySelector";
import { Constants } from "utils/constants";
import { toast } from "react-toastify";

function HolidayForm() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const userType = localStorage.getItem('userType');
  const companyList = useSelector(CompanySelector.getCompanyList());
  const projectList = useSelector(ProjectSelector.getProjectList());
  const success = useSelector(GeneralSelector.success(HolidayActions.createHoliday.type));
  const error = useSelector(GeneralSelector.error(HolidayActions.createHoliday.type));
  const successUpdate = useSelector(GeneralSelector.success(HolidayActions.updateHoliday.type));
  const errorUpdate = useSelector(GeneralSelector.error(HolidayActions.updateHoliday.type));
  const loading = useSelector(GeneralSelector.loader(HolidayActions.createHoliday.type));
  const loadingUpdate = useSelector(GeneralSelector.loader(HolidayActions.updateHoliday.type));
  const holidayDetails = useSelector(HolidaySelector.getHolidayDeatils());
  const [assignedProject,setAssignedProject] = useState("");
  const companyId = localStorage.getItem('companyID')
  const projectID = localStorage.getItem('projectID')
  const subContractorId = localStorage.getItem('subcontractorID');
  const assignedProjectToSubcontractorList = useSelector(SubContractorSelector.getAssignedSubcontractor());

  const validationSchema = yup.object({
    companyId: yup.string().required("This field is required"),
    projectId: yup.string().required("This field is required"),
    holidayName: yup.string().required("This field is required"),
    date: yup.string().required("This field is required"),
  });
  
  const formik = useFormik({
    initialValues: {
      companyId: holidayDetails?.companyId ?? "",
      projectId: holidayDetails?.projectId ?? "",
      holidayName: holidayDetails?.holidayName ?? "",
      date: holidayDetails?.date ?? "",
     },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  useEffect(() => {
    if (!companyId) {
      dispatch(CompanyActions.getCompanyList());
     }
     if (companyId && !projectID) {
         const params = {
             company_id: companyId
         }
         
         dispatch(ProjectActions.getProjectList(params))
     } 
     if (subContractorId) {
         const params = {
             subcontractorId: subContractorId
         }
         dispatch(SubContractorActions.getAssignedSubContractor(params));
     }
  },[])

  useEffect(() => {
    if (id) {
     dispatch(HolidayActions.getHolidayDetails(id));
    }
  },[id])

  useEffect(() => {
    if (holidayDetails?.companyId) {
      const params = {
        company_id: holidayDetails.companyId,
        status: 1
      }
       dispatch(ProjectActions.getProjectList(params))
    }
  },[id])

 const handleChangeCompany = (e) => {
  const cId = e.target.value;
    formik.setFieldValue('companyId',cId)
    formik.setFieldValue('projectId',"")
     const params = {
      company_id: cId,
      status: 1
    }
     dispatch(ProjectActions.getProjectList(params))
}  
const handleChangeProject = (e) => {
  const cId = e.target.value;
  formik.setFieldValue('projectId',cId)
}

useEffect(() => {
  if (success) {
      toast.success(`${success?.message ?? "Success"}`, {
              position: "top-right",
              autoClose: 3000,
              closeOnClick: true,
          });

      dispatch(GeneralActions.removeSuccess(HolidayActions.createHoliday.type));
      history.goBack();

  }
}, [success]);

useEffect(() => {
  if (error) {
      toast.error(`${error?.message ?? "Error"}`, {
              position: "top-right",
              autoClose: 3000,
              closeOnClick: true,
          });

      dispatch(GeneralActions.removeError(HolidayActions.createHoliday.type));
  }
}, [error]);

useEffect(() => {
  if (successUpdate) {
      toast.success(`${successUpdate?.message ?? "Success"}`, {
              position: "top-right",
              autoClose: 3000,
              closeOnClick: true,
          });

      dispatch(GeneralActions.removeSuccess(HolidayActions.updateHoliday.type));
      history.goBack();

  }
}, [successUpdate]);

useEffect(() => {
  if (errorUpdate) {
      toast.error(`${errorUpdate?.message ?? "Error"}`, {
              position: "top-right",
              autoClose: 3000,
              closeOnClick: true,
          });

      dispatch(GeneralActions.removeError(HolidayActions.updateHoliday.type));
  }
}, [errorUpdate]);

const handleSubmit = (params) => {
  if (id) {
      dispatch(HolidayActions.updateHoliday({
          ...params,
          id
      }));
  } else {
      dispatch(HolidayActions.createHoliday(params));
  }
};
const handleChangeProjectBySubcontractor = (e) => {
  setAssignedProject(e.target.value)
  for (const i in assignedProjectToSubcontractorList.data) {
      if (assignedProjectToSubcontractorList.data[i].projectId._id === e.target.value) {
          formik.setFieldValue('companyId',assignedProjectToSubcontractorList.data[i].companyId._id)
          formik.setFieldValue('projectId',e.target.value)
       }
  }
}
return (
    <Box>
      <PageTitle isBack={true} title={`${id ? "Update" : "Add"} Holiday`}/>
      
       {loading ? (
            <FormSkeleton/>
        ) : (
        <Card>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              {userType === Constants.SUPERADMIN?(
                <Grid item lg={6} sm={12} xs={12}>
                    <Typography variant='h6' sx={{fontSize: 15}}>Company</Typography>
                    <SelectField
                        onChange={handleChangeCompany}
                        value={formik.values.companyId}
                        error={formik.touched.companyId && Boolean(formik.errors.companyId)}
                        helperText={formik.touched.companyId && formik.errors.companyId}
                    >
                          {companyList && companyList.map((item,index) => (
                            <MenuItem value={item._id} key={index}>
                                {item.name}
                            </MenuItem>
                          ))}
                    </SelectField>
                </Grid>
              ):null} 
              {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER?(
                <Grid item lg={6} sm={12} xs={12}>
                    <Typography variant='h6' sx={{fontSize: 15}}>Project</Typography>
                    <SelectField
                        onChange={handleChangeProject}
                        value={formik.values.projectId}
                        error={formik.touched.projectId && Boolean(formik.errors.projectId)}
                        helperText={formik.touched.projectId && formik.errors.projectId}
                        >
                        {(formik.values.companyId && projectList) && projectList.map((item,index) => (
                            <MenuItem value={item._id} key={index}>
                                {item.name}
                            </MenuItem>
                          ))}
                    </SelectField>
                </Grid>
              ):null} 
              {userType === Constants.SUB_CONTRACTOR_USER ?(
                    <Grid item lg={4} sm={12} xs={12}>
                        <Typography sx={{fontSize: 15}}>Project</Typography>
                        <SelectField
                            value={assignedProject}
                            onChange={handleChangeProjectBySubcontractor}
                            >
                            {assignedProjectToSubcontractorList && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                            <MenuItem key={index} value={item.projectId._id}>
                                    {item?.projectId?.name}({item?.companyId?.name})
                            </MenuItem>
                            ))}
                        </SelectField> 
                    </Grid>
                    ):null
            }

              <Grid item lg={6} sm={12} xs={12}>
                <Input
                  name="date"
                  label="Date"
                  type='date'
                  value={formik.values.date.slice(0,10)}
                  onChange={formik.handleChange}
                  error={formik.touched.date && Boolean(formik.errors.date)}
                  helperText={formik.touched.date && formik.errors.date}
                  />
              </Grid>
              <Grid item lg={6} sm={12} xs={12}>
                <Input
                  name="holidayName"
                  label="Occasion"
                  value={formik.values.holidayName}
                  onChange={formik.handleChange}
                  error={formik.touched.holidayName && Boolean(formik.errors.holidayName)}
                  helperText={formik.touched.holidayName && formik.errors.holidayName}
                  />
              </Grid>
              <Grid container justifyContent="flex-end">
                {id?(
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    sx={{mt: 3}}
                    >
                    {loadingUpdate?(
                        <CircularProgress color="inherit" size={20}/>
                    ):( 
                        <>Update</> 
                    )} 
                  </Button>
                ):(
                  <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      sx={{mt: 3}}
                      >
                      {loading?(
                          <CircularProgress color="inherit" size={20}/>
                      ):( 
                          <>Submit</> 
                      )} 
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </Card>
    )} 
    </Box>
  );
}

export default HolidayForm;
