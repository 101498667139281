import React,{useEffect} from "react";
import {
     Button,
    Card,
    Grid,
    Typography, MenuItem, CircularProgress,
} from "@mui/material";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import { GeneralActions, LabourActions} from "slices/actions";
import {useFormik} from "formik";
import Input from "components/Input";
import SelectField from "components/SelectField";
import { GeneralSelector, LabourSelector } from "selectors";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function EducationDetails() {
    const history = useHistory();
    const dispatch = useDispatch();
    const {id} = useParams()
    const educationDetails = useSelector(LabourSelector.getLabourEducationDetails());
    const success = useSelector(GeneralSelector.success(LabourActions.updateLabourEducation.type));
    const error = useSelector(GeneralSelector.error(LabourActions.updateLabourEducation.type));
    const loading = useSelector(GeneralSelector.loader(LabourActions.updateLabourEducation.type));
    const userID = localStorage.getItem("userID")
    useEffect(() => {
      dispatch(LabourActions.getLabourEducationDetails(id))
    },[]);

     useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(LabourActions.updateLabourEducation.type));
            history.goBack();
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeError(LabourActions.updateLabourEducation.type));
        }
    }, [error]);

    const validationSchema = yup.object({
        higherEducation: yup.string().required('This field is required'),
        passingyear: yup.string().required('This field is required'),
        institute: yup.string().required('This field is required'),
    });
    const formik = useFormik({
      initialValues: {
        higherEducation: educationDetails?.higherEducation??"",
        passingyear: educationDetails?.passingyear??"",
        institute: educationDetails?.institute??"",
      },
      enableReinitialize: true,
      validateOnChange: true,
      validationSchema: validationSchema,
      onSubmit: (values) => {
        handleSubmit(values);
      },
    });
   
    const handleSubmit = (values) => {
        const params = {
              id: id,
              added_by: userID,
              labourId: educationDetails.labourID,
              ...values,

        };
    dispatch(LabourActions.updateLabourEducation(params));
    }

    return (
        <Card>
            <Typography variant='h5' sx={{ mb: 4 }}>Education</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Typography sx={{fontSize: 15}}>Higher Education</Typography>
                        <SelectField
                            name="higherEducation"
                            value={formik.values.higherEducation}
                            onChange={formik.handleChange}
                            error={formik.touched.higherEducation && Boolean(formik.errors.higherEducation)}
                            helperText={formik.touched.higherEducation && formik.errors.higherEducation}
                            style={{ display: "block", width: "100%" }}
                        >
                         
                            <MenuItem value={'Non-Matric'}>Non-Matric</MenuItem>
                            <MenuItem value={'Matric'}>Matric</MenuItem>
                            <MenuItem value={'Senior-Secondary'}>Senior-Secondary</MenuItem>
                            <MenuItem value={'Graduate'}>Graduate</MenuItem>
                            <MenuItem value={'Post-Graduate'}>Post-Graduate</MenuItem>
                            <MenuItem value={'Doctorate'}>Doctorate</MenuItem>
                            <MenuItem value={'Technical (Professional)'}>Technical (Professional)</MenuItem>
                        </SelectField>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Year of Passing"
                            name='passingyear'
                            value={formik.values.passingyear}
                            onChange={formik.handleChange}
                            error={formik.touched.passingyear && Boolean(formik.errors.passingyear)}
                            helperText={formik.touched.passingyear && formik.errors.passingyear}
                        />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Input
                            label="School/Board"
                            name='institute'
                            value={formik.values.institute}
                            onChange={formik.handleChange}
                            error={formik.touched.institute && Boolean(formik.errors.institute)}
                            helperText={formik.touched.institute && formik.errors.institute}
                            />
                    </Grid>                   
                        <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained">
                                {loading?(
                                   <CircularProgress color="inherit" size={20}/>
                                ):(
                                  <>Submit</>  
                                )}
                            </Button>
                        </Grid>
                </Grid>
            </form>
        </Card>
    )
}