import React, { useEffect, useState } from 'react';
import { Button, Checkbox, CircularProgress, Box, Typography, Table, TableBody, TableHead, TableCell, TableRow, TableContainer } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AttendanceActions, GeneralActions } from 'slices/actions';
import { GeneralSelector, ReportSelector } from 'selectors';
import PageTitle from 'components/PageTitle';
import { toast } from "react-toastify";


function AttendanceListing() {
  const dispatch = useDispatch();
  const monthAttendanceList = useSelector(ReportSelector.getFormDData());

  const [monthPresentList, setMonthPresentList] = useState([])
  const success = useSelector(GeneralSelector.success(AttendanceActions.monthlyAttendanceUpdate.type))
  const error = useSelector(GeneralSelector.error(AttendanceActions.monthlyAttendanceUpdate.type))
  const loading = useSelector(GeneralSelector.loader(AttendanceActions.monthlyAttendanceUpdate.type))
  // const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    if (monthAttendanceList) {
      setMonthPresentList(JSON.parse(JSON.stringify(monthAttendanceList)))

    }
  }, [monthAttendanceList])

  useEffect(() => {
    if (success) {
      toast.success(`${success?.success ?? "Success"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });

      dispatch(GeneralActions.removeSuccess(AttendanceActions.monthlyAttendanceUpdate.type));
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      toast.error(`${error?.error ?? "Error"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });

      dispatch(GeneralActions.removeSuccess(AttendanceActions.monthlyAttendanceUpdate.type));
    }
  }, [error]);

  const handleChangeValue = (index, e, item2) => {
    const deepCopyObj = JSON.parse(JSON.stringify(monthPresentList));

    if (e.target.checked === true) {
      deepCopyObj.data[index].attendance[item2] = "P"
      setMonthPresentList({ ...deepCopyObj })
    } else if (e.target.checked === false) {
      deepCopyObj.data[index].attendance[item2] = "A"
      setMonthPresentList({ ...deepCopyObj })
    }
  }
  const updateAttendance = () => {
    const params = {
      startDate: monthPresentList.startDate,
      endDate: monthPresentList.endDate,
      presentDaysList: JSON.stringify(monthPresentList),
      project_id: monthPresentList?.projectId,
      flag: 'mainAttendance'
    }
    dispatch(AttendanceActions.monthlyAttendanceUpdate(params))
  }

  const handleChangeCurrentRowValue = (index, e) => {
    // const item2 = monthPresentList.data[index].attendance
    // console.log(item2)
    if (e.target.checked === true) {
      for (const j in monthPresentList.data[index].attendance) {
        if (e.target.checked === true && monthPresentList.data[index].attendance[j] === "A" && monthPresentList.data[index].attendanceEditable[j]) {
          const deepCopyObj = monthPresentList;
          // console.log(deepCopyObj)
          deepCopyObj.data[index].attendance[j] = "P"
          setMonthPresentList({ ...deepCopyObj })
        }
      }
    } else if (e.target.checked === false) {
      for (const j in monthPresentList.data[index].attendance) {
        if (e.target.checked === false && monthPresentList.data[index].attendance[j] === "P" && monthPresentList.data[index].attendanceEditable[j]) {
          const deepCopyObj = monthPresentList;
          deepCopyObj.data[index].attendance[j] = "A"
          setMonthPresentList({ ...deepCopyObj })
        }
      }
    }
  }
  const handleChangeAllValue = (e) => {
    for (const i in monthPresentList.data) {
      if (e.target.checked === true) {
        for (const j in monthPresentList.data[i].attendance) {
          if (e.target.checked === true && monthPresentList.data[i].attendance[j] === "A" && monthPresentList.data[i].attendanceEditable[j]) {
            const deepCopyObj = monthPresentList;
            deepCopyObj.data[i].attendance[j] = "P"
            setMonthPresentList({ ...deepCopyObj })
            // console.log("sagar",!monthPresentList.data[i].attendanceEditable[j])
          }
        }
      } else if (e.target.checked === false) {
        for (const j in monthPresentList.data[i].attendance) {
          if (e.target.checked === false && monthPresentList.data[i].attendance[j] === "P" && monthPresentList.data[i].attendanceEditable[j]) {
            const deepCopyObj = monthPresentList;
            deepCopyObj.data[i].attendance[j] = "A"
            setMonthPresentList({ ...deepCopyObj })
          }
        }
      }
    }
    console.log(monthPresentList)
  }

  return (
    <Box>
      <PageTitle isBack={true} title='Attendance Listing' />
      <div className="pt-2" style={{ width: "100%", overflowX: "auto" }}>
        {monthPresentList && monthPresentList?.data ? (
          <>
            <Typography sx={{ fontSize: '13', mb: 1 }}>
              Project Name : {monthPresentList && monthPresentList?.projectname}
            </Typography>
            <Typography sx={{ fontSize: '13', mb: 1 }}>
              Company Name : {monthPresentList && monthPresentList?.companyname}
            </Typography>
            <Typography sx={{ fontSize: '13', mb: 1 }}>
              Subcontractor Name : {monthPresentList && monthPresentList?.subContractorname}
            </Typography>
            <Typography sx={{ fontSize: '13', mb: 2 }}>
              Month and Year : {monthPresentList && monthPresentList?.month}{" "}{monthPresentList && monthPresentList?.year}
            </Typography>
            <Checkbox
              inputProps={{ 'aria-label': 'controlled' }}
              onChange={(e) => handleChangeAllValue(e)}
            />
            <label>Check All</label>

            <TableContainer sx={{ maxHeight: 640, minWidth: '100%' }}>
              <Table stickyHeader aria-label="sticky table" className="table Tableform " id="table-to-xls">
                <TableHead sx={{ backgroundColor: '#8ac2dd !important', position: 'sticky', top: 0, zIndex: '1', left: 0 }}>
                  <TableRow className="tableform ">
                    <TableCell sx={{ backgroundColor: '#8ac2dd', position: 'sticky', left: 0, top: 0, zIndex: '1' }} rowSpan="2">Sl.No
                    </TableCell>
                    <TableCell sx={{ backgroundColor: '#8ac2dd', position: 'sticky', left: '44px', top: 0, zIndex: '1' }} rowSpan="2">Name</TableCell>
                    <TableCell sx={{ backgroundColor: '#8ac2dd', position: 'sticky', left: '128px', top: 0, zIndex: '1' }} colspan="31">Date</TableCell>
                  </TableRow>
                  <TableRow className="tableform " sx={{ position: 'sticky', top: '62px' }}>
                    {monthAttendanceList?.days && Array.from(Array(monthAttendanceList?.days), (e, index) => (
                      <>
                        <TableCell sx={{ backgroundColor: '#8ac2dd', position: 'sticky', top: 0, zIndex: '1' }} key={index}>
                          <Typography>{index + 1}</Typography>
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody >
                  {monthPresentList && monthPresentList?.data && monthPresentList.data.map((res, index) => <TableRow
                    key={index} className="tableform">
                    <TableCell align='center' sx={{ backgroundColor: '#8ac2dd !important', position: 'sticky', left: 0, zIndex: '1', top: '132px' }}>{index + 1}</TableCell>
                    <TableCell sx={{ backgroundColor: '#8ac2dd !important', position: 'sticky', left: 41, zIndex: '1', top: '132px' }} width="20%">
                      <Checkbox
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={(e) => handleChangeCurrentRowValue(index, e)}
                      />
                      {res.firstName} {res.lastName}
                    </TableCell>

                    {res && res.attendance && Object.keys(res.attendance).map((item2, index2) => {
                      const isEditable = res && res.attendanceEditable ? res.attendanceEditable[item2] : "";
                      const attendanceValue = res.attendance[item2];
                      const isCheckboxDisabled = (isEditable && (attendanceValue === "P" || attendanceValue === "A"))

                      return (
                        <TableCell key={index2}>
                          {
                            attendanceValue === "NJ" || attendanceValue === "F" || attendanceValue === "H" ? <label>{attendanceValue}</label> : <>
                              <Checkbox className="AttendanceCustomCheckbox"
                                disabled={!isCheckboxDisabled}
                                checked={attendanceValue === "P"}
                                onChange={(e) => handleChangeValue(index, e, item2)}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                              <label>{attendanceValue}</label>
                            </>
                          }
                        </TableCell>
                      );
                    })}

                    {/* {res && res.attendance && Object.keys(res.attendance).map((item2, index2) => (
                      <TableCell key={index2}>
                        <>
                          {!res?.exitDate ? (
                            <>
                              {res.attendance[item2] === "A" ? (
                                <>
                                  <Checkbox className="AttendanceCustomCheckbox"
                                    checked={false}
                                    onChange={(e) => handleChangeValue(index, e, item2)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                  <label>A</label>
                                </>
                              ) : ""}
                              {res.attendance[item2] === "P" ? (
                                <>
                                  <Checkbox className="AttendanceCustomCheckbox"
                                    checked={true}
                                    onChange={(e) => handleChangeValue(index, e, item2)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                  <label>P</label>
                                </>) : ""}
                              <label style={{ display: 'block', marginTop: "18px" }}>{res.attendance[item2] === "H" ? res.attendance[item2] : ""}</label>
                              {res.attendance[item2] === "NJ" ? (
                                <>
                                  <Checkbox className="AttendanceCustomCheckbox"
                                    disabled
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                  <label style={{ color: "#bdbdbd" }}>NJ</label>
                                </>) : ""}
                              {res.attendance[item2] === "F" ? (
                                <>
                                  <Checkbox className="AttendanceCustomCheckbox"
                                    disabled
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                  <label style={{ color: "#bdbdbd" }}>F</label>
                                </>) : ""}
                            </>) : (
                            <>
                              {res.attendance[item2] === "A" ? (
                                <>
                                  <Checkbox className="AttendanceCustomCheckbox"
                                    disabled
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                  <label style={{ color: "#bdbdbd" }}>A</label>
                                </>) : ""}
                              {res.attendance[item2] === "P" ? (
                                <>
                                  <Checkbox className="AttendanceCustomCheckbox"
                                    checked={true}
                                  />
                                  <label style={{ color: "#bdbdbd" }}>P</label>
                                </>) : ""}
                              <label style={{ display: 'block', marginTop: "18px", color: "#bdbdbd" }}>{res.attendance[item2] === "H" ? res.attendance[item2] : ""}</label>
                              {res.attendance[item2] === "NJ" ? (
                                <>
                                  <Checkbox className="AttendanceCustomCheckbox"
                                    disabled
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                  <label style={{ color: "#bdbdbd" }}>NJ</label>
                                </>) : ""}
                              {res.attendance[item2] === "F" ? (
                                <>
                                  <Checkbox className="AttendanceCustomCheckbox"
                                    disabled
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                  <label style={{ color: "#bdbdbd" }}>F</label>
                                </>) : ""}
                            </>)
                          }
                        </>
                      </TableCell>
                    ))} */}

                  </TableRow>)}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ mt: 2, position: 'relative', left: "50%" }}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                sx={{ mt: 1 }}
                onClick={updateAttendance}
              >
                {loading ? <CircularProgress color="inherit" size={20} /> : "Update"}

              </Button>
            </div>
          </>) : <CircularProgress className="CircularLoading" color="primary" size={30} />}
      </div>
    </Box>
  )
}

export default AttendanceListing;