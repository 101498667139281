import { createSlice } from "@reduxjs/toolkit";

export const ReportSlice = createSlice({
    name: "report",
    initialState: {
        reportData: [],
        reportAData: [],
        reportBData: [],
        formData: {},
    },
    reducers: {
       getReportData: () => {},
        getReportDataSuccess: (state,action) => {
            state.reportData = action.payload
        },

       getReportAData: () => {},
       getReportADataSuccess: (state,action) => {
           state.reportAData = action.payload
       },

       getReportBData: () => {},
       getReportBDataSuccess: (state,action) => {
           state.reportBData = action.payload
       },
       // Use to form data in global store
       getFormData: (state,action) => {
            state.formData = action.payload
       }
    }
});

export default ReportSlice;