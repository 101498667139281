import React,{useState,useEffect} from "react";
import {
    Box,
    Typography, MenuItem,
    Grid
} from "@mui/material";
import Input from "components/Input";
import SelectField from "components/SelectField";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { MasterDataSelector } from "selectors";
import { MasterDataActions } from "slices/actions";

PensionnomineeDetails.propTypes = {
    formik: PropTypes.object
};

export default function PensionnomineeDetails(props) {
    const { formik } = props;
    const dispatch = useDispatch();
    const locationData = useSelector(MasterDataSelector.getLocationListByPincode());
    const [pincode,setpincode]= useState();

    const handleChangePinCode= (e) => {        
        const pincodeData = e.target.value
        formik.setFieldValue('pfNomineInfoPersonNomineeDetails_Pincode',pincodeData)
        setpincode(pincodeData);
     }
    useEffect(() => {
        if (pincode && pincode.length === 6) {
            dispatch(MasterDataActions.fetchDataByPincode(pincode));
        }
    }, [pincode]); 
    
    useEffect(() => {
        if (locationData && pincode) {
        formik.setFieldValue('pfNomineInfoPersonNomineeDetails_District', locationData.District?locationData.District :'');
        formik.setFieldValue('pfNomineInfoPersonNomineeDetails_State', locationData.StateName?locationData.StateName :'');
        formik.setFieldValue('pfNomineInfoPersonNomineeDetails_Address', locationData.PlaceName?locationData.PlaceName :'');
    }
     setpincode('')
    }, [locationData]);
    return (
        <Box>
            <Typography variant= "h6" sx={{my: 3, borderBottom: "1px solid #ccc"}}>Pension Nominee Details</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Nominee Name *"
                            name='pfNomineInfoPersonNomineeDetails_NomineeName'
                            value={formik.values.pfNomineInfoPersonNomineeDetails_NomineeName}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfoPersonNomineeDetails_NomineeName && Boolean(formik.errors.pfNomineInfoPersonNomineeDetails_NomineeName)}
                            helperText={formik.touched.pfNomineInfoPersonNomineeDetails_NomineeName && formik.errors.pfNomineInfoPersonNomineeDetails_NomineeName}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Typography sx={{fontSize: 15}}>Gender *</Typography>
                        <SelectField
                            className="text-left"
                            name="pfNomineInfoPersonNomineeDetails_Gender"
                            value={formik.values.pfNomineInfoPersonNomineeDetails_Gender}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfoPersonNomineeDetails_Gender && Boolean(formik.errors.pfNomineInfoPersonNomineeDetails_Gender)}
                            helperText={formik.touched.pfNomineInfoPersonNomineeDetails_Gender && formik.errors.pfNomineInfoPersonNomineeDetails_Gender}
                            >
                            <MenuItem value="Male">
                                Male
                            </MenuItem>
                            <MenuItem value="FeMale">
                                Female
                            </MenuItem>
                        </SelectField>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Relation *"
                            name='pfNomineInfoPersonNomineeDetails_Relation'
                            value={formik.values.pfNomineInfoPersonNomineeDetails_Relation}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfoPersonNomineeDetails_Relation && Boolean(formik.errors.pfNomineInfoPersonNomineeDetails_Relation)}
                            helperText={formik.touched.pfNomineInfoPersonNomineeDetails_Relation && formik.errors.pfNomineInfoPersonNomineeDetails_Relation}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="DOB *"
                            name='pfNomineInfoPersonNomineeDetails_DOB'
                            type='date'
                            value={formik.values.pfNomineInfoPersonNomineeDetails_DOB}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfoPersonNomineeDetails_DOB && Boolean(formik.errors.pfNomineInfoPersonNomineeDetails_DOB)}
                            helperText={formik.touched.pfNomineInfoPersonNomineeDetails_DOB && formik.errors.pfNomineInfoPersonNomineeDetails_DOB}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Pincode *"
                            name='pfNomineInfoPersonNomineeDetails_Pincode'
                            value={formik.values.pfNomineInfoPersonNomineeDetails_Pincode}
                            onChange={handleChangePinCode}
                            error={formik.touched.pfNomineInfoPersonNomineeDetails_Pincode && Boolean(formik.errors.pfNomineInfoPersonNomineeDetails_Pincode)}
                            helperText={formik.touched.pfNomineInfoPersonNomineeDetails_Pincode && formik.errors.pfNomineInfoPersonNomineeDetails_Pincode}
                            />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="State *"
                            name='pfNomineInfoPersonNomineeDetails_State'
                            value={formik.values.pfNomineInfoPersonNomineeDetails_State}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfoPersonNomineeDetails_State && Boolean(formik.errors.pfNomineInfoPersonNomineeDetails_State)}
                            helperText={formik.touched.pfNomineInfoPersonNomineeDetails_State && formik.errors.pfNomineInfoPersonNomineeDetails_State}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="District *"
                            name='pfNomineInfoPersonNomineeDetails_District'
                            value={formik.values.pfNomineInfoPersonNomineeDetails_District}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfoPersonNomineeDetails_District && Boolean(formik.errors.pfNomineInfoPersonNomineeDetails_District)}
                            helperText={formik.touched.pfNomineInfoPersonNomineeDetails_District && formik.errors.pfNomineInfoPersonNomineeDetails_District}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Address *"
                            name='pfNomineInfoPersonNomineeDetails_Address'
                            value={formik.values.pfNomineInfoPersonNomineeDetails_Address}
                            onChange={formik.handleChange}
                            error={formik.touched.pfNomineInfoPersonNomineeDetails_Address && Boolean(formik.errors.pfNomineInfoPersonNomineeDetails_Address)}
                            helperText={formik.touched.pfNomineInfoPersonNomineeDetails_Address && formik.errors.pfNomineInfoPersonNomineeDetails_Address}/>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}