import React, {useEffect, useState} from "react";
import {
    Box,
    Card,
    Grid,
    Hidden,
    IconButton,
    // ListItemIcon,
    MenuItem,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip, 
    // Chip
} from "@mui/material";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import FloatingButton from "components/FloatingButton";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Delete, Edit} from "@mui/icons-material";
import DialogConfirm from "components/DialogConfirm";
import {toast} from "react-toastify";
import {DesignationSelector, GeneralSelector, ProjectSelector} from "selectors";
import {CompanyActions, DesignationActions, GeneralActions, ProjectActions} from "slices/actions";
import {DefaultSort} from "constants/sort";
import Input from "components/Input";
import SelectField from "components/SelectField";
import ListSkeleton from "../../components/Skeleton/ListSkeleton";
import { CompanySelector } from "selectors/CompanySelector";
import { Constants } from "utils/constants";
// import CustomMenu from "../../components/CustomMenu";

const FilterBox = styled(Box)(() => ({
    width: "100%",
    marginTop: 30,
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-between"
}));

export default function Designation() {
    const history = useHistory();
    const dispatch = useDispatch();
    const designations = useSelector(DesignationSelector.getDesignations());
    const loading = useSelector(GeneralSelector.loader(DesignationActions.getDesignations.type));
    const pagination = useSelector(DesignationSelector.getPagination());
    const success = useSelector(GeneralSelector.success(DesignationActions.deleteDesignation.type));
    const error = useSelector(GeneralSelector.error(DesignationActions.deleteDesignation.type));
    const userType = localStorage.getItem('userType')
    const companyId = localStorage.getItem('companyID')
    const projectId = localStorage.getItem('projectID')
    const companyList = useSelector(CompanySelector.getCompanyList());
    const projectList = useSelector(ProjectSelector.getProjectList());
    const [filter, setFilter] = useState({
        sort: DefaultSort.newest.value,
        page: 1
    });
    const [selected, setSelected] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [designationSearchForm,setDesignationSearchForm] = useState({
        companyId: companyId?companyId:"",
        projectId: projectId?projectId:""
      })

    useEffect(() => {
        if (!companyId) {
            dispatch(CompanyActions.getCompanyList());
         }
    },[])  
   useEffect(() => {
        if (designationSearchForm.companyId || companyId) {
            const params = {
                company_id: designationSearchForm.companyId ? designationSearchForm.companyId:companyId,
                status: 1
            }
            dispatch(ProjectActions.getProjectList(params))
        }
    },[designationSearchForm.companyId])

    useEffect(() => {
        const params = {
            ...filter,
            ...designationSearchForm
        }
        dispatch(DesignationActions.getDesignations(params));
    }, [filter]);

    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true
                });

            setSelected(null);
            setConfirmDelete(false);

            dispatch(DesignationActions.getDesignations());
            dispatch(GeneralActions.removeSuccess(DesignationActions.deleteDesignation.type));
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true
                });

            setSelected(null);
            setConfirmDelete(false);
            dispatch(GeneralActions.removeError(DesignationActions.deleteDesignation.type));
        }
    }, [Error]);

    const handleChangeFilter = ({ target }) => {
        const {name, value} = target;

        setFilter({
            ...filter,
            [name]: value
        });
    }

    const handleChangePagination = (e, val) => {
        setFilter({
            ...filter,
            page: val
        });
    };

    const handleDelete = () => {
        dispatch(DesignationActions.deleteDesignation(selected));
    };
    const handleChangeCompany = (e) => {
        const cId = e.target.value;
        designationSearchForm.companyId = cId;
        designationSearchForm.projectId = "";
        setDesignationSearchForm({...designationSearchForm})
        const params = {
            ...filter,
            ...designationSearchForm
        }
        dispatch(DesignationActions.getDesignations(params));
     }   
      const handleChangeProject = (e) => {
        if (designationSearchForm.companyId) {
        const pId = e.target.value
        designationSearchForm.projectId = pId;
        setDesignationSearchForm({...designationSearchForm})
        const params = {
            ...filter,
            ...designationSearchForm
        }
        dispatch(DesignationActions.getDesignations(params));
        } 
      }  

    // window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth',
    // });
    return (
        <Card>
          
            
            <Typography variant="h5" sx={{ fontWeight: 600 }}>Designation</Typography>
            <FilterBox>
                <Grid container spacing={3} justifyContent="space-between">
                    <Grid item lg={6} sm={12} xs={12}>
                        <Input
                            label="Search"
                            placeholder='Search by name'
                            name='keyword'
                            value={filter.keyword ?? ''}
                            onChange={handleChangeFilter}/>
                    </Grid>
                    <Grid item lg={2} sm={6} xs={12}>
                        <Typography sx={{fontSize: 15}}>Sort by</Typography>
                        <SelectField
                            placeholder="Sort by name or email"
                            name='sort'
                            value={filter.sort}
                            onChange={handleChangeFilter}>
                            {Object.keys(DefaultSort).map((key) => (
                                <MenuItem key={key} value={DefaultSort[key].value}>
                                    {DefaultSort[key].name}
                                </MenuItem>
                            ))}
                        </SelectField>
                    </Grid>
                </Grid>
            </FilterBox>
            <Grid container spacing={3} justifyContent="space-between">
                {userType !== Constants.COMPANY_USER && userType !== Constants.PROJECT_USER?(
                        <Grid item lg={6} sm={12} xs={12}>
                                <Typography sx={{fontSize: 15}}>Company Name</Typography>
                                <SelectField
                                    value={designationSearchForm.companyId}
                                    onChange={handleChangeCompany}
                                    >
                                    {companyList && companyList.map((item,index) => (
                                    <MenuItem key={index} value={item._id}>
                                            {item.name}
                                    </MenuItem>
                                    ))}
                                </SelectField> 
                            </Grid>
                        ):""}
                {userType !== Constants.PROJECT_USER?(
                    <Grid item lg={6} sm={6} xs={12}>
                        <Typography sx={{fontSize: 15}}>Project Name</Typography>
                        <SelectField
                            value={designationSearchForm.projectId}
                            onChange={handleChangeProject}
                            >
                            {(designationSearchForm.companyId && projectList) && projectList.map((item,index) => (
                            <MenuItem key={index} value={item._id}>
                                    {item.name}
                            </MenuItem>
                            ))}
                        </SelectField> 
                    </Grid> 
                ):""}      
            </Grid>
            {loading ? (
                <ListSkeleton/>
            ) : (
                <Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell width='30%'>Name</TableCell>
                                <TableCell>Permissions</TableCell>
                                <TableCell>CompanyName</TableCell>
                                <TableCell>ProjectName</TableCell>
                                <TableCell>Status</TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                            {designations.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={3} align="center">No Data</TableCell>
                                </TableRow>
                            )}
                            
                             {designations.map((item, i) => (
                                <TableRow
                                    key={i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell width='30%' component="th" scope="row">
                                        {item.name}
                                    </TableCell>
                                    <TableCell align="left">
                                        {item.role && Object.keys(item.role).map((innerItem,index) => (
                                            <div key={index}> 
                                                <span className="chipbgspan">
                                                    {innerItem} 
                                                </span> 
                                                <div>
                                                    {item.role[innerItem] && Object.keys(item.role[innerItem]).map((innerItemrole,indexrole) => (
                                                        
                                                        <span key={indexrole} className="chipspan">{innerItemrole}</span>

                                                    ))}
                                                </div>
                                            </div>
                                            
                                        ))}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {item.companyName}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {item.projectName}
                                    </TableCell>
                                    <TableCell align="right" width='15%'>
                                        
                                        <Hidden smDown>
                                            <Tooltip title="Edit">
                                                <IconButton onClick={() => history.push(`/app/designation/update/${item._id}`)}>
                                                    <Edit className="text-primary" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <IconButton onClick={() => {
                                                    setConfirmDelete(true);
                                                    setSelected(item._id);
                                                }}>
                                                <Delete className="text-default" />
                                                </IconButton>
                                            </Tooltip>
                                        </Hidden>
                                        {/* <Hidden smUp>
                                            <CustomMenu>
                                                <MenuItem onClick={() => history.push(`/app/designation/update/${item._id}`)}>
                                                    <ListItemIcon>
                                                        <Visibility fontSize="small" />
                                                    </ListItemIcon>
                                                    Detail
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => {
                                                        setConfirmDelete(true);
                                                        setSelected(item._id);
                                                    }}>
                                                    <ListItemIcon>
                                                        <Delete fontSize="small" />
                                                    </ListItemIcon>
                                                    Delete
                                                </MenuItem>
                                            </CustomMenu>
                                        </Hidden> */}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Pagination
                        page={filter.page}
                        count={pagination.pages}
                        onChange={handleChangePagination}/>
                </Box>
            )}

            <DialogConfirm
                title="Delete Data"
                content="Are you sure want to delete this data?"
                open={confirmDelete}
                onClose={() => setConfirmDelete(false)}
                onSubmit={handleDelete}/>

            <FloatingButton
                onClick={() => history.push("/app/designation/create")}/>
        </Card>
    )
}