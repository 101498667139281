import React, { useEffect } from "react";
import {
    Card,
    Grid,
    Typography,
    MenuItem,
    Button,
    CircularProgress
} from "@mui/material";
import Input from "components/Input";
import SelectField from "components/SelectField";
import PropTypes from "prop-types";
import { GeneralSelector, LabourSelector } from "selectors";
import {useDispatch, useSelector} from "react-redux";
import { GeneralActions, LabourActions } from "slices/actions";
import {useFormik} from "formik";
import * as yup from "yup";
import {toast} from "react-toastify";
import { useParams } from "react-router-dom";


SkillInfoDetails.propTypes = {
    labourDetails: PropTypes.object,
};

export default function SkillInfoDetails(props) {
    const { id } = useParams();
    const { labourDetails } = props;
    const dispatch = useDispatch();
    const skillType = ["Unskilled", "Skilled", "Semi-Skilled", "Highly Skilled"]
    const skillCategoryList = useSelector(LabourSelector.getSkillCategory());
    const success = useSelector(GeneralSelector.success(LabourActions.updateSkillInfo.type));
    const error = useSelector(GeneralSelector.error(LabourActions.updateSkillInfo.type));
    const loading = useSelector(GeneralSelector.loader(LabourActions.updateSkillInfo.type));

    useEffect(() => {
        dispatch(LabourActions.getSkillCategory())
    },[])
    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(LabourActions.updateSkillInfo.type));
            dispatch(LabourActions.getLabourById(id));

        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeError(LabourActions.updateSkillInfo.type));
        }
    }, [error]);


    const validationSchema = yup.object({
        employeementType: yup.string().required('This field is required'),
        skillType: yup.string().required('This field is required'),
        skill_catagory: yup.string().required('This field is required'),
        doj: yup.string().required('This field is required'),
     });
    
    const formik = useFormik({
      initialValues: {
        employeementType: labourDetails?.result?.employeementType??"",
        skillType: labourDetails?.result?.skillType??"",
        skill_catagory: labourDetails?.result?.skill_catagory??"",
        doj: labourDetails?.result?.doj??"",
     },
      enableReinitialize: true,
      validateOnChange: true,
      validationSchema: validationSchema,
      onSubmit: (values) => {
        handleSubmit(values);
      },
    });
   const handleSubmit = (values) => {
        const params = {
              id: labourDetails.result._id,
            ...values,
        };
        dispatch(LabourActions.updateSkillInfo(params));
    }
    return (
        <Card sx={{ mb: 3, mt: 2 }}>
            <Typography variant='h5' sx={{ mb: 4, position: 'relative' }}>Skill Info</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                 <Grid item lg={6} xs={12}>
                    <Typography varient='h6' sx={{fontSize: '15px', color: '#222'}}>Skill Category *</Typography>
                    <SelectField
                        className="text-left"
                        name="skill_catagory"
                        value={formik.values.skill_catagory}
                        onChange={formik.handleChange}
                        error={formik.touched.skill_catagory && Boolean(formik.errors.skill_catagory)}
                        helperText={formik.touched.skill_catagory && formik.errors.skill_catagory}
                        >
                        {skillCategoryList && skillCategoryList.masterSkillInfo && skillCategoryList.masterSkillInfo.map((item,index) => (
                            <MenuItem key={index} value={item.type} >
                                {item.type}
                            </MenuItem>
                        ))} 
                      </SelectField>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Typography varient='h6' sx={{fontSize: '15px', color: '#222'}}>Skill Type *</Typography>
                    <SelectField
                        className="text-left"
                        name="skillType"
                        value={formik.values.skillType}
                        onChange={formik.handleChange}
                        error={formik.touched.skillType && Boolean(formik.errors.skillType)}
                        helperText={formik.touched.skillType && formik.errors.skillType}
                        >
                        {skillType.map((item,index) => (
                            <MenuItem key={index} value={item} >
                                {item}
                            </MenuItem>
                        ))} 
                        
                    </SelectField>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Typography varient='h6' sx={{fontSize: '15px', color: '#222'}}>Type of Employment *</Typography>
                    <SelectField
                        className="text-left"
                        name="employeementType"
                        value={formik.values.employeementType}
                        onChange={formik.handleChange}
                        error={formik.touched.employeementType && Boolean(formik.errors.employeementType)}
                        helperText={formik.touched.employeementType && formik.errors.employeementType}
                    >
                            <MenuItem value={'Daily Wages'}>Daily Wages</MenuItem>
                            <MenuItem value={'Fixed Monthly Salary'}>Fixed Monthly Salary</MenuItem>
                        
                    </SelectField>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        fullWidth
                        label="Date of Joining *"
                        type="date"
                        name="doj"
                        value={formik.values.doj.slice(0,10)}
                        onChange={formik.handleChange}
                        error={formik.touched.doj && Boolean(formik.errors.doj)}
                        helperText={formik.touched.doj && formik.errors.doj}
                        />
                </Grid>
                {labourDetails?.result?.status !== 99 ?(
                <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained">
                                {loading?(
                                   <CircularProgress color="inherit" size={20}/>
                                ):(
                                  <>Update</>  
                                )}
                            </Button>
                </Grid>):null}
                    
             </Grid> 
             </form>
          </Card>
    )
}