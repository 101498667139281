import {createSelector} from "@reduxjs/toolkit";

const projectSelector = (state) => state.project;

const getProjectList = () => createSelector(
  projectSelector,
  project => project.projectlist
);
const getLocationList = () => createSelector(
  projectSelector,
  project => project.pinCodeData
);
const getPagination = () => createSelector(
  projectSelector,
  project => project.pagination
);

const getPaymentAndDuration = () => createSelector(
  projectSelector,
  project => project.PaymentcollectionMode
);
const getProjectById = () => createSelector(
  projectSelector,
  project => project.projectDetails
);
const getRole = () => createSelector(
  projectSelector,
  project => project.role
);
const getProjectView = () => createSelector(
  projectSelector,
  project => project.projectViewData
);
const getDailyWagesListing = () => createSelector(
  projectSelector,
  project => project.dailyWagesListing
);
const getDailyWagesDetails = () => createSelector(
  projectSelector,
  project => project.dailyWagesDetails
);
const getClientListData = () => createSelector(
  projectSelector,
  project => project.clientNameList
);
export const ProjectSelector = {
  getProjectList,
  getLocationList,
  getPaymentAndDuration,
  getPagination,
  getProjectById,
  getRole,
  getProjectView,
  getDailyWagesListing,
  getDailyWagesDetails,
  getClientListData
}