import React, { useState,useEffect } from 'react'
import { CompanyActions, LabourActions, MasterDataActions, ProjectActions, SubContractorActions } from 'slices/actions';
import PropTypes from "prop-types";
import { Button, Grid, MenuItem ,Card, Typography} from '@mui/material';
import Input from 'components/Input';
import SelectField from 'components/SelectField';
import { useDispatch, useSelector } from 'react-redux';
import {styled} from "@mui/material/styles";
import { Constants } from 'utils/constants';
import { CompanySelector } from 'selectors/CompanySelector';
import { LabourSelector, MasterDataSelector, ProjectSelector, SubContractorSelector } from 'selectors';


const Content = styled(Card)(() => ({
    marginBottom: 20
}));

FormIndex.propTypes = {
    repotType: PropTypes.string,
    title: PropTypes.string
  }
function FormIndex (props) {
 const dispatch = useDispatch();
   const {repotType,title} = props
   const userType = localStorage.getItem('userType');
   const companyId = localStorage.getItem('companyID');
   const projectId = localStorage.getItem('projectID');
   const subContractorId = localStorage.getItem('subcontractorID');
   const skillCategoryList = useSelector(LabourSelector.getSkillCategory());
   const companyList = useSelector(CompanySelector.getCompanyList());
   const projectList = useSelector(ProjectSelector.getProjectList());
   const [assignedProject,setAssignedProject] = useState("");
   const [clientName,setClientName] = useState("");
   const [errObj,setErrObj] = useState({}); 
   const [natureOfWorkData,setNatureOfWorkData] = useState();
   const [safetyTrainingData,setSafetyTrainingData] = useState();
   const [skillCategoryData,setSkillCategoryData] = useState();
   const labourTrainingType = useSelector(LabourSelector.getSafetyTrainingType());
   const natureOfWorkList = useSelector(MasterDataSelector.getNatureOfWork());
   const assignedProjectToSubcontractorList = useSelector(SubContractorSelector.getAssignedSubcontractor());
   const clientList = useSelector(ProjectSelector.getClientListData())
   const [formData,setFormData] = useState({
        company_id: companyId?companyId:"",
        project_id: projectId?projectId:"",
        subcontractor_id: subContractorId?subContractorId:"", 
        start_date: "",
        end_date: "",
        status: "",
        limit: 100000,
        report: 1
       })

    useEffect(() => {
        if (!companyId) {
        dispatch(CompanyActions.getCompanyList());
        }  
        if (companyId && !projectId) {
            const params = {
                company_id: companyId
            }
            
            dispatch(ProjectActions.getProjectList(params))
        } 
        if (companyId && projectId) {
            const params = {
                company_id: companyId,
                project_id: projectId
            }
            dispatch(SubContractorActions.getAssignedSubContractor(params))
        } 
         if (subContractorId) {
            const params = {
                subcontractorId: subContractorId
            }
            dispatch(SubContractorActions.getAssignedSubContractor(params));
        }
        if (repotType === "Project") {
            dispatch(ProjectActions.getClientNameList(formData));
        }
        if (repotType === "SafetyTraining") {
            dispatch(LabourActions.fetchLabourTrainingType())
        }
        if (repotType === "Labour") {
            dispatch(LabourActions.getSkillCategory())
        }
        if (repotType === "Subcontractor") {
            dispatch(MasterDataActions.getNatureOfWork());
         }

    },[])

    const submit = () => {
        if (validateForm()) {
                if (repotType === "Project") {
                dispatch(ProjectActions.getProjectList(formData));
                } else if (repotType === "Labour") {
                    dispatch(LabourActions.getLabourList(formData));
                } else if (repotType === "Subcontractor") {
                    const params = {
                        ...formData,
                        is_subcontractor_user: true
                    }
                    dispatch(SubContractorActions.getSubContractorList(params));
                } else if (repotType === "SafetyTraining") {
                    dispatch(LabourActions.getAllSafetyTrainingList(formData));
                }
    }
    }

    const handleChangeCompany = (e) => {
        const cId = e.target.value;
        formData.company_id = cId;
        formData.project_id = "";
        formData.subcontractor_id = "";
        setFormData({...formData}) 
        if (repotType === "Labour" || repotType === "Subcontractor" || repotType === "SafetyTraining") {
            const params = {
                company_id: formData.company_id ? formData.company_id: companyId,
                status: 1
           }
            dispatch(ProjectActions.getProjectList(params))
        }
    }   
    const handleChangeProject = (e) => {
        if (formData.company_id) {
        const pId = e.target.value
        formData.subcontractor_id = "";
        formData.project_id = pId;
        setFormData({...formData})
        if (repotType === "Labour" || repotType === "Subcontractor" || repotType === "SafetyTraining") {
            const params = {
                company_id: formData.company_id || companyId,
                project_id: formData.project_id || projectId,
               }
            dispatch(SubContractorActions.getAssignedSubContractor(params));
        }
        } 
    }
    const handleChangeSubcontractor = (e) => { 
       if (formData.project_id) {
        const sId = e.target.value
        formData.subcontractor_id = sId;
        setFormData({...formData})
        } 
     }
    const handleChangeProjectBySubcontractor = (e) => {
            setAssignedProject(e.target.value)
            for (const i in assignedProjectToSubcontractorList.data) {
            if (assignedProjectToSubcontractorList.data[i].projectId._id === e.target.value) {
                formData.project_id = e.target.value
                formData.company_id = assignedProjectToSubcontractorList.data[i].companyId._id
                setFormData({...formData})

            }
        }
     }
     const validateForm = () => {
        let valid = true
        if (!formData.start_date) {
          errObj.start_date = 'This field is Required';
          valid = false;
        }
        if (!formData.end_date) {
            errObj.end_date = 'This field is Required';
            valid = false;
          }
        setErrObj({...errObj});
        return valid; 
    }
    
    const changeNatureOfWork = (e) => {
        setNatureOfWorkData(e.target.value)
        const params = {
        ...formData,
        natureOfWork: e.target.value
      }
       dispatch(SubContractorActions.getSubContractorList(params));
      }
      
      const handleChangeTrainingType = (e) => {
        setSafetyTrainingData(e.target.value)
        const params = {
         ...formData,
         trainingType: e.target.value
       }
       dispatch(LabourActions.getAllSafetyTrainingList(params));
   }

   const changeClientName = (e) => {
        setClientName(e.target.value)
        const params = {
            ...formData,
            clientName: e.target.value,
            status: 1
       }
        dispatch(ProjectActions.getProjectList(params))
   }

   const handleChangeSkillCategory = (e) => {
    setSkillCategoryData(e.target.value)
    const params = {
       ...formData,
       skillCatagory: e.target.value
     }
     dispatch(LabourActions.getLabourList(params));
    }

  return (
    <Content>
    <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>{title}</Typography>

     <Grid container spacing={3} justifyContent="space-between">

        <Grid item lg={3} sm={12} xs={12}>
            <Input
                label="Start Date"
                type="date"
                name="startAt"
                value={formData.start_date}
                onChange={(e) => {
                    delete errObj.start_date
                    setErrObj({...errObj})
                    formData.start_date = e.target.value
                    setFormData({...formData})
                }}
                InputLabelProps={{
                    shrink: true,
                }}/>
            {errObj.start_date && <Typography sx={{color: 'red',fontSize: 12}}>{errObj.start_date}</Typography>}
            </Grid>
        <Grid item lg={3} sm={12} xs={12}>
            <Input
                label="End Date"
                type="date"
                InputLabelProps={{
                    min: formData.start_date 
                }}
                value={formData.end_date}
                onChange={(e) => {
                    delete errObj.end_date
                    setErrObj({...errObj})
                    formData.end_date = e.target.value
                    setFormData({...formData})
                }}
               />
            {errObj.end_date && <Typography sx={{color: 'red',fontSize: 12}}>{errObj.end_date}</Typography>}
        </Grid>
        <Grid item lg={2} sm={12} xs={12}>
            <Typography sx={{fontSize: '15px'}}>Status</Typography>
            <SelectField
                // label="Status"
                value={formData.status}
                name="status"
                onChange={(e) => {
                    formData.status = e.target.value
                    setFormData({...formData})
                }}>
                <MenuItem value={0}>All Status</MenuItem>
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={2}>Inactive</MenuItem>
                
            </SelectField>
        </Grid>
         {(userType === Constants.SUPERADMIN && (repotType === "Project" || repotType === "Labour" || repotType === "Subcontractor" || repotType === "SafetyTraining"))?(
                    <Grid item lg={6} sm={12} xs={12}>
                        <Typography variant='h6' sx={{fontSize: 15}}>Company</Typography>
                        <SelectField
                            value={formData.company_id}
                            onChange={handleChangeCompany}
                            >
                              {companyList && companyList.map((item,index) => (
                                <MenuItem value={item._id} key={index}>
                                    {item.name}
                                </MenuItem>
                              ))}
                        </SelectField>
                    </Grid>
            ):null} 
            {(userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER) && repotType !== "Project" && (repotType === "Labour" || repotType === "Subcontractor" || repotType === "SafetyTraining")?(
                    <Grid item lg={6} sm={12} xs={12}>
                        <Typography variant='h6' sx={{fontSize: 15}}>Project</Typography>
                        <SelectField
                            value={formData.project_id}
                            onChange={handleChangeProject}
                            >
                           { (formData.company_id && projectList) && projectList.map((item,index) => (
                                <MenuItem value={item._id} key={index}>
                                    {item.name}
                                </MenuItem>
                              ))}
                        </SelectField>
                     </Grid>
            ):null} 

            {(userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER || userType === Constants.PROJECT_USER) && repotType !== "Subcontractor" && repotType !== "Project" && (repotType === "Labour" || repotType === "SafetyTraining") ?(
                        <Grid item lg={6} sm={12} xs={12}>
                        <Typography variant='h6' sx={{fontSize: 15}}>SubContractor</Typography>
                        <SelectField
                            value={formData.subcontractor_id}
                            onChange={handleChangeSubcontractor}
                            >
                           {(formData.project_id && assignedProjectToSubcontractorList) && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                                <MenuItem value={item?.subcontractorId?._id} key={index}>
                                    {item?.subcontractorId?.subContractorName}
                                </MenuItem>
                              ))}
                        </SelectField>
                     </Grid>
                 ):null} 

                {userType === Constants.SUB_CONTRACTOR_USER ?(
                    <Grid item lg={4} sm={12} xs={12}>
                        <Typography sx={{fontSize: 15}}>Project</Typography>
                        <SelectField
                            value={assignedProject}
                            onChange={handleChangeProjectBySubcontractor}
                            >
                            {assignedProjectToSubcontractorList && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                            <MenuItem key={index} value={item.projectId._id}>
                                    {item?.projectId?.name}({item?.companyId?.name})
                            </MenuItem>
                            ))}
                        </SelectField> 
                    </Grid>
                    ):null
                 }
        
        {repotType === "Project"?(
         <Grid item lg={6} xs={12}>
                  <Typography variant='h5' sx={{color: '#000', fontSize: 15, opacity: 'rgba(0, 0, 0, 0.87)'}}>Client</Typography>
                    <SelectField
                        value={clientName}
                        onChange={changeClientName}
                       >
                        {clientList?.result && clientList.result.map((item,index) => (
                            <MenuItem key={index} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                   </SelectField>
         </Grid>
          ):null
        }
       {repotType === "Subcontractor"?(
         <Grid item lg={6} xs={12}>
                  <Typography variant='h5' sx={{color: '#000', fontSize: 15, opacity: 'rgba(0, 0, 0, 0.87)'}}>Nature Of Work</Typography>
                    <SelectField
                        value={natureOfWorkData}
                        onChange={changeNatureOfWork}
                       >
                        {natureOfWorkList.subcontractorScopesofWork && natureOfWorkList.subcontractorScopesofWork.map((item,index) => (
                            <MenuItem key={index} value={item.id}>
                                {item.level}
                            </MenuItem>
                        ))}
                   </SelectField>
         </Grid>
          ):null
        }
        {repotType === "SafetyTraining"?(
          <Grid item lg={6} sm={12} xs={12}>
          <Typography sx={{fontSize: 15}}>Training Type</Typography>
          <SelectField
              value={safetyTrainingData}
              onChange={handleChangeTrainingType}
              >
             {labourTrainingType && labourTrainingType.masterTrainingSkillType && labourTrainingType.masterTrainingSkillType.map((item,index) => (
                            <MenuItem key={index} value={item.type}>
                                {item.type}
                            </MenuItem>
             ))}
          </SelectField> 
        </Grid>
          ):null
        }

        {repotType === "Labour"?(
          <Grid item lg={6} sm={12} xs={12}>
          <Typography sx={{fontSize: 15}}>Skill Category</Typography>
          <SelectField
              value={skillCategoryData}
              onChange={handleChangeSkillCategory}
              >
             {skillCategoryList && skillCategoryList.masterSkillInfo && skillCategoryList.masterSkillInfo.map((item,index) => (
                            <MenuItem key={index} value={item.type} >
                                {item.type}
                            </MenuItem>
             ))} 
          </SelectField> 
        </Grid>
          ):null
        }

        <Grid item lg={12} sm={12} xs={12} justifyContent="flex-end" sx={{mt: 2, textAlign: 'right'}}>
            <Button
                size="large"
                variant="contained"
                color="primary"
                sx={{mt: 1}}
                onClick={submit}
                >
                Submit
            </Button>
        </Grid>
    </Grid>
</Content>

  )
}

export default FormIndex