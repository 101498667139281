import React, { useEffect,useState } from 'react';
import ReactECharts from 'echarts-for-react';
import PropTypes from "prop-types";
import { format } from 'date-fns';
import SelectField from 'components/SelectField';
import { MenuItem } from '@mui/material';
import { Constants } from 'utils/constants';


LineCharts.propTypes = {
  dashboardData: PropTypes.object,
};

function LineCharts(props) {
  const {dashboardData} = props;
  const [lineChartDate,setLineChartDate] = useState([]);
  const [lineChartValue,setLineChartValue] = useState([]);
  const [graphValue,setGraphValue] = useState("Labour");
  const userType = localStorage.getItem('userType');


  useEffect(() => {
    if (dashboardData?.labour) {
        const dateArray = []
        const valueArray = []
        for (const i in dashboardData.labour) {
          if (dashboardData.labour[i].date) {
             dateArray.push(format(new Date(dashboardData.labour[i].date),'dd/MM/yyyy'))
             valueArray.push(dashboardData.labour[i].count)
             setLineChartDate(dateArray)
            setLineChartValue(valueArray)

          }
        }
   }  
   
  },[dashboardData])

  const handleChangeGraphValue = (e) => {
    setLineChartDate([]);
    setLineChartValue([]);
    setGraphValue(e.target.value)
    const fieldValue = e.target.value
    if (fieldValue === "Labour") {
      if (dashboardData?.labour) {
        const labourDateArray = []
        const labourValueArray = []
        for (const i in dashboardData.labour) {
          if (dashboardData.labour[i].date) {
            labourDateArray.push(format(new Date(dashboardData.labour[i].date),'dd/MM/yyyy'))
            labourValueArray.push(dashboardData.labour[i].count)
            setLineChartDate(labourDateArray)
            setLineChartValue(labourValueArray)
          }
        }
      }  

    } else if (fieldValue === "Company") {
      if (dashboardData?.company) {
        const companyDateArray = []
        const companyValueArray = []
        for (const i in dashboardData.company) {
          if (dashboardData.company[i].date) {
             companyDateArray.push(format(new Date(dashboardData.company[i].date),'dd/MM/yyyy'))
             companyValueArray.push(dashboardData.company[i].count)
             setLineChartDate(companyDateArray)
             setLineChartValue(companyValueArray)
            }
        }
      }  

    } else if (fieldValue === "Project") {
      if (dashboardData?.project) {
        const projectDateArray = []
        const projectValueArray = []
        for (const i in dashboardData.project) {
          if (dashboardData.project[i].date) {
            projectDateArray.push(format(new Date(dashboardData.project[i].date),'dd/MM/yyyy'))
            projectValueArray.push(dashboardData.project[i].count)
            setLineChartValue(projectValueArray)
            setLineChartDate(projectDateArray)
          }
        }
      }  
    }
  }
 
 const option = {
    xAxis: {
      type: 'category',
      data: lineChartDate,
      boundaryGap: false,
    splitLine: {
      show: true,
      lineStyle: {
        color: '#fff',
        opacity: '0.1'
      },
      interval: 0
    },
    axisLine: {
      lineStyle: {
        color: '#fff',
        opacity: '0.1'
      }
    },
    axisTick: {
      show: true,
      length: 10,
      lineStyle: {
        color: '#fff',
        opacity: '0.1'
      }
    },
    axisLabel: {
      color: '#b6c1d2',
      fontWeight: 600,
      fontSize: 12,
      margin: 15,
      interval: window.innerWidth < 768 ? 'auto' : 0,
      formatter: value => value.substring(0, value.length - 5)
    }
    },
    tooltip: {
      show: true,
      trigger: 'axis',
      axisPointer: {
        type: 'none'
      },
       backgroundColor: '#f9fafd',
       padding: [7, 10],
       borderColor: '#d8e2ef',
       borderWidth: 1,
       transitionDuration: 0,
       textStyle: {
         fontWeight: 500,
         fontSize: 12,
         color: '#0b1727'
       },
       formatter: params => `${params[0].axisValue} - ${params[0].value}`
    },
    yAxis: {
      type: 'value',
    axisPointer: {
      show: false
    },
    splitLine: {
      show: false
    },
    axisLabel: {
      show: false
    },
    axisTick: { show: false },
    axisLine: { show: false }
    },
    series: [
      {
        data: lineChartValue,
        type: 'line',
        smooth: true,
        symbol: 'emptyCircle',
        
        itemStyle: {
          color: '#fff'
        },
        lineStyle: {
          color: '#fff',
          opacity: '0.8'
     
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: '#fff',
                opacity: '0.5' 
              },
              {
                offset: 1,
                color: '#014ba7',
                opacity: '0' 
              }
            ]
          }
        },
        emphasis: {
          lineStyle: {
            width: 2
          }
        }
      }
    ],
    grid: { right: 18, left: 18, bottom: '15%', top: 0 }
  };
  
  return (
    <div className="" style={{marginBottom: "20px"}}>
    <div className="g-3 row">
        <div className="col-xxl-12">
            <div className="rounded-3 overflow-hidden h-100 shadow-none card">
              <div className="d-flex flex-column justify-content-between bg-line-chart-gradient card-body">
                <div className="align-items-center g-0 row">
                  <div className="light col">
                      <h5 className="text-white mb-0">Graphical Report</h5>
                      {/* <p className="fs--1 fw-semi-bold text-white"> Yesterday
                          <span className="opacity-150"> 84 Joined</span>
                      </p> */}
                  </div>
                  <div className="d-none d-sm-block col-auto">
                    <SelectField
                      value={graphValue}
                      onChange={handleChangeGraphValue}>
                      <MenuItem value="Labour">
                      Labour
                      </MenuItem>
                      {userType === Constants.SUPERADMIN ?(
                      <MenuItem value="Company">
                      Company
                      </MenuItem>):null}
                      {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER?(
                      <MenuItem value="Project">
                      Project
                      </MenuItem>):null}
                    </SelectField> 
                  </div>
                </div>
                <ReactECharts
                  option={option}
                  notMerge={true}
                  lazyUpdate={true}
                  theme={"theme_name"}
                  style={{height: '200px'}}
                /> 
              </div>
            </div>
        </div>
    </div>
</div>
   
  );
}
export default LineCharts;

