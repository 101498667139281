import React, { useEffect, useState } from "react";
import {
    Card,
    Grid,
    Typography
} from "@mui/material";
import Input from "components/Input";
import { MasterDataActions } from "slices/actions";
import { useDispatch, useSelector } from "react-redux";
import { MasterDataSelector } from "selectors";
import PropTypes from "prop-types";


Bankaccountdetail.propTypes = {
    formik: PropTypes.object,
};

export default function Bankaccountdetail(props) {
    const { formik } = props;
    const dispatch = useDispatch();
    const ifscCodeData = useSelector(MasterDataSelector.getIfscData());
    const [ifsccode, setIfscCode] = useState();


    const handleChangeIfsc = (e) => {
        const code = e.target.value
        formik.setFieldValue('ifscCode', code)
        setIfscCode(code);
    }
    useEffect(() => {
        if (ifsccode && ifsccode.length === 11) {
            dispatch(MasterDataActions.getIfscData(ifsccode));
        }
    }, [ifsccode]);

    useEffect(() => {
        if (ifscCodeData && ifsccode) {
            formik.setFieldValue('bankAddress', ifscCodeData.ADDRESS ? ifscCodeData.ADDRESS : '');
            formik.setFieldValue('bankCity', ifscCodeData.CITY ? ifscCodeData.CITY : '');
            formik.setFieldValue('bankBranchName', ifscCodeData.BRANCH ? ifscCodeData.BRANCH : '');
            formik.setFieldValue('bankState', ifscCodeData.STATE ? ifscCodeData.STATE : '');
            formik.setFieldValue('bankName', ifscCodeData.BANK ? ifscCodeData.BANK : '');
        }
    }, [ifscCodeData]);


    return (
        <Card sx={{ mb: 3 }}>
            <Typography variant='h5' sx={{ mb: 4 }}>Bank Account Details</Typography>
            <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                    <Input
                        label='Bank Account Number *'
                        name='bankAccountNo'
                        type='number'
                        value={formik.values.bankAccountNo}
                        onChange={formik.handleChange}
                        error={formik.touched.bankAccountNo && Boolean(formik.errors.bankAccountNo)}
                        helperText={formik.touched.bankAccountNo && formik.errors.bankAccountNo}
                    />

                </Grid>
                <Grid item lg={6} xs={12}>

                    <Input
                        label="IFSC Code *"
                        name='ifscCode'
                        value={formik.values.ifscCode}
                        onChange={(e) => handleChangeIfsc(e)}
                        error={Boolean(formik.touched.ifscCode) && Boolean(formik.errors.ifscCode)}
                        helperText={formik.touched.ifscCode && formik.errors.ifscCode} />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Bank Name"
                        name='bankName'
                        readonly
                        value={formik.values.bankName}
                        // onChange={formik.handleChange}
                        error={Boolean(formik.touched.bankName) && Boolean(formik.errors.bankName)}
                        helperText={formik.touched.bankName && formik.errors.bankName} />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Branch Name"
                        name='bankBranchName'
                        readonly
                        value={formik.values.bankBranchName}
                        // onChange={formik.handleChange}
                        error={Boolean(formik.touched.bankBranchName) && Boolean(formik.errors.bankBranchName)}
                        helperText={formik.touched.bankBranchName && formik.errors.bankBranchName} />
                </Grid>


                <Grid item lg={6} xs={12}>
                    <Input
                        label="City Name"
                        name='bankCity'
                        readonly
                        value={formik.values.bankCity}
                        // onChange={formik.handleChange}
                        error={Boolean(formik.touched.bankCity) && Boolean(formik.errors.bankCity)}
                        helperText={formik.touched.bankCity && formik.errors.bankCity} />
                </Grid>

                <Grid item lg={6} xs={12}>
                    <Input
                        label="State Name"
                        name='bankState'
                        readonly
                        value={formik.values.bankState}
                        // onChange={formik.handleChange}
                        error={Boolean(formik.touched.bankState) && Boolean(formik.errors.bankState)}
                        helperText={formik.touched.bankState && formik.errors.bankState} />
                </Grid>
                <Grid item lg={12} xs={12}>
                    <Input
                        label="Address"
                        name='bankAddress'
                        value={formik.values.bankAddress}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.bankAddress) && Boolean(formik.errors.bankAddress)}
                        helperText={formik.touched.bankAddress && formik.errors.bankAddress} />
                </Grid>
            </Grid>
        </Card>
    )
}