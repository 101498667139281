import React, { useEffect,useState } from "react";
import {
  Card,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Box,
  Typography,
  Chip
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useHistory, useParams } from "react-router-dom";
import DialogConfirm from "components/DialogConfirm";
import {
  GeneralActions,
  SubContractorActions,
} from "slices/actions";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GeneralSelector, SubContractorSelector } from "selectors";

function ProjectSubContractorListing() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const success = useSelector(GeneralSelector.success(SubContractorActions.deleteSubContractor.type));
  const error = useSelector(GeneralSelector.error(SubContractorActions.deleteSubContractor.type));
  const subContractorListing = useSelector(SubContractorSelector.getAssignedSubcontractor());

  useEffect(() => {
    const params = {
      project_id: id
    }
    dispatch(SubContractorActions.getAssignedSubContractor(params));
  }, []);

  const handleDelete = () => {
    dispatch(SubContractorActions.deleteSubContractor(selected));
  };
useEffect(() => {
    if (success) {
       setConfirmDelete(false);
       setSelected(null);
      toast.success(`${success?.message ?? "Success"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });

      dispatch(GeneralActions.removeSuccess(SubContractorActions.deleteSubContractor.type));
      dispatch(SubContractorActions.getSubContractorById(id));

    }
  }, [success]);

  useEffect(() => {
    if (error) {
       setConfirmDelete(false);
       setSelected(null);
      toast.success(`${error?.message ?? "Error"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });

      dispatch(GeneralActions.removeError(SubContractorActions.deleteSubContractor.type));
    }
  }, [error]);
  return (
    <Card sx={{ position: "relative" }}>
      <Typography variant="h5" sx={{ mb: 4 }}>
        SubContractor Listing
      </Typography>
         <AddCircleOutlineIcon
        onClick={() => history.push("/app/subcontractor/Create")}
        fontSize="large"
        sx={{
          position: "absolute",
          cursor: "pointer",
          right: "15px",
          top: "20px",
          color: "#3f7ed2",
        }}
      />
      <Box>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell>SubContractor Name</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Project Name</TableCell>
              <TableCell>Status</TableCell>
              {/* <TableCell>Option</TableCell> */}
          </TableRow>
          </TableHead>
          <TableBody>
            {subContractorListing.data &&
              subContractorListing.data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                                {item.subcontractorId?item.subcontractorId.subContractorName:""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.companyId?item.companyId.name:""}
                            </TableCell>
                           <TableCell component="th" scope="row">
                                {item.projectId?item.projectId.name:""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                            <Chip
                                label={item.status === 2 ? 'Inactive' : 'Active'}
                                color={item.status === 2 ? 'default' : 'primary'} size='small'/>
                            </TableCell>
                  {/* <TableCell align="left">
                    <CustomMenu>
                      <MenuItem
                        onClick={() => history.push(`/app/subcontractor/details/${item._id}`)}>
                        <ListItemIcon>
                          <EditIcon fontSize="small" />
                        </ListItemIcon>
                        Details
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setConfirmDelete(true);
                          setSelected(item._id);
                        }}
                      >
                        <ListItemIcon>
                          <Delete fontSize="small" />
                        </ListItemIcon>
                        Delete
                      </MenuItem>
                    </CustomMenu>
                  </TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      <DialogConfirm
          title="Delete Site"
          content="Are you sure want to delete this Subcontractor?"
          open={confirmDelete}
          onClose={() => setConfirmDelete(false)}
          onSubmit={handleDelete}
        />
    </Card>
  );
}

export default ProjectSubContractorListing;
