import React from "react";
import {
    Grid, Typography
} from "@mui/material";
import Barcode from "react-barcode";
import PropTypes from "prop-types";
import { format } from 'date-fns'
import Avatar from "assets/avatar.svg";
// import { useBarcode } from "next-barcode";

//   const { inputRef } = useBarcode({
//     value: '12345 6789',
//     options: {
//       background: '#ccffff',
//     }
//   });

IdCardFront.propTypes = {
    labourDetails: PropTypes.object,
};

// const getBase64Image = (url) => {
//     const img = new Image();
//     img.setAttribute('crossOrigin', 'anonymous');
//     img.onload = () => {
//       const canvas = document.createElement("canvas");
//       canvas.width = img.width;
//       canvas.height = img.height;
//       const ctx = canvas.getContext("2d");
//       ctx.drawImage(img, 0, 0);
//       const dataURL = canvas.toDataURL("image/png");
//       console.log(dataURL)
//     }
//     img.src = url
//    setPreview(url);
// }


export default function IdCardFront(props) {
    const {labourDetails} = props;
    // const [preview,setPreview] = useState(); 
    // const uuty = "https://img.freepik.com/free-vector/cute-swag-polar-bear-with-hat-gold-chain-necklace-cartoon-illustration-flat-cartoon-style_138676-2719.jpg?w=2000";
 
    // const urr = getBase64Image(labourDetails.logo);    
   // console.log(urr)
  

    return (
      <>
        <div className="IdCard" style={{marginBottom: '20px'}}>
            <Grid container spacing={2}>
                <Grid item lg={12} xs={12}>
                    <Typography sx={{ borderBottom: '1px solid #ddd'}}>
                        <img src={`${"data:image/png;base64,"}${labourDetails.base64URL.companyLogo}`} style={{width: '80px', height: '50px', objectFit: 'contain'}} />
                        {labourDetails && labourDetails.base64URL.data.companyName}
                    </Typography>
                </Grid>
                <Grid item lg={3} sm={3} xs={3}>
              <img src={labourDetails.base64URL.logo?`${"data:image/png;base64,"}${labourDetails.base64URL.logo}`:Avatar} />
                    <Barcode value={labourDetails && labourDetails.result?._id}
                      />
                      
                </Grid>
                <Grid item lg={9} sm={9} xs={9} className="fortext">
                    <Grid container spacing={3}>
                        <Grid item lg={5} sm={5} xs={5}>
                            <Typography sx={{fontWeight: '500'}}>Name</Typography>
                        </Grid>
                        <Grid item lg={1} sm={1} xs={1}>
                            <Typography>:</Typography>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={6}>
                            <Typography>{labourDetails && labourDetails?.result?.firstName} {labourDetails && labourDetails?.result?.lastName}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item lg={5} sm={5} xs={5}>
                            <Typography sx={{fontWeight: '500'}}>Designation</Typography>
                        </Grid>
                        <Grid item lg={1} sm={1} xs={1}>
                            <Typography>:</Typography>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={6}>
                            <Typography>{labourDetails && labourDetails?.result?.skill_catagory}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item lg={5} sm={5} xs={5}>
                            <Typography sx={{fontWeight: '500'}}>LabourCode</Typography>
                        </Grid>
                        <Grid item lg={1} sm={1} xs={1}>
                            <Typography>:</Typography>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={6}>
                            <Typography>{labourDetails?.result?.projectLabourCode?labourDetails.result.projectLabourCode:"N/A"}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item lg={5} sm={5} xs={5}>
                            <Typography sx={{fontWeight: '500'}}>Blood Group</Typography>
                        </Grid>
                        <Grid item lg={1} sm={1} xs={1}>
                            <Typography>:</Typography>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={6}>
                            <Typography>{labourDetails && labourDetails?.medicalData?.bloodGroup}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item lg={5} sm={5} xs={5}>
                            <Typography sx={{fontWeight: '500'}}>Issue Date</Typography>
                        </Grid>
                        <Grid item lg={1} sm={1} xs={1}>
                            <Typography>:</Typography>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={6}>
                            <Typography>
                                {format(new Date(labourDetails && labourDetails?.result?.createdAt), 'dd/MM/yyyy')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item lg={5} sm={5} xs={5}>
                            <Typography sx={{fontWeight: '500'}}>Valid Upto</Typography>
                        </Grid>
                        <Grid item lg={1} sm={1} xs={1}>
                            <Typography>:</Typography>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={6}>
                            <Typography>
                               {format(new Date(labourDetails && labourDetails?.validupto), 'dd/MM/yyyy')}
                             </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item lg={5} sm={5} xs={5}>
                            <Typography sx={{fontWeight: '500'}}>Sub Contractor</Typography>
                        </Grid>
                        <Grid item lg={1} sm={1} xs={1}>
                            <Typography>:</Typography>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={6}>
                            <Typography>{labourDetails && labourDetails.base64URL && labourDetails.base64URL.data.SubcontractorName}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item lg={5} sm={5} xs={5}>
                            <Typography sx={{fontWeight: '500'}}>DOB</Typography>
                        </Grid>
                        <Grid item lg={1} sm={1} xs={1}>
                            <Typography>:</Typography>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={6}>
                            <Typography>
                                {format(new Date(labourDetails && labourDetails?.result?.dateOfBirth), 'dd/MM/yyyy')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item lg={5} sm={5} xs={5}>
                            <Typography sx={{fontWeight: '500'}}>DOJ</Typography>
                        </Grid>
                        <Grid item lg={1} sm={1} xs={1}>
                            <Typography>:</Typography>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={6}>
                            <Typography>
                                {format(new Date(labourDetails && labourDetails?.result?.doj), 'dd/MM/yyyy')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
        </div>
      </>
    );
}