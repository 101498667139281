import { get } from "../utils/api";

const API_URL = process.env.REACT_APP_API_URL 

const FormDURL = async (params) => get(`${API_URL}/formReport/formD`,params);

const FormAURL = async (params) => get(`${API_URL}/formReport/formA`,params);

const FormBURL = async (params) => get(`${API_URL}/formReport/formB`,params);

export const ReportService = {
    FormDURL,
    FormAURL,
    FormBURL
 };