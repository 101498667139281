import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
 } from "@mui/material";
import PageTitle from "components/PageTitle";
import styled from "@emotion/styled";
import Avatar from "assets/avatar.svg";
import * as yup from "yup";
import { useFormik } from "formik";
import BasicInformation from "./Component/CreateForm/BasicInformation";
import {GeneralActions, SubContractorActions } from "slices/actions";
import { useDispatch, useSelector } from "react-redux";
import { GeneralSelector, ProjectSelector } from "selectors";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import PFDetails from "./Component/CreateForm/PFDetails";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { CompanySelector } from "selectors/CompanySelector";
import AccountSetting from "./Component/CreateForm/AccountSetting";

const Picker = styled(Box)(() => ({
  width: 120,
  height: 120,
  margin: "40px auto 10px auto",

  "& input": {
    display: "none",
  },

  "& img": {
    width: "100%",
    borderRadius: 100,
  },
}));

function CreateSubcontractor() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [preview, setPreview] = useState(null);
  const success = useSelector(GeneralSelector.success(SubContractorActions.createSubContractor.type));
  const error = useSelector(GeneralSelector.error(SubContractorActions.createSubContractor.type));
  const loading = useSelector(GeneralSelector.loader(SubContractorActions.createSubContractor.type));
  const companyDetails = useSelector(CompanySelector.getCompanyById());
  const projectDetails = useSelector(ProjectSelector.getProjectById());
  const companyId = localStorage.getItem("companyID")
  const projectId = localStorage.getItem("projectID")

// const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  const validationSchema = yup.object({
    subContractorCode: yup.string().required("This field is required"),
    subContractorName: yup.string().required("This field is required"),
    subContractorEmail: yup.string().email().
    required("Email is required"),
    subContractorPhone: yup.string().min(10).
    max(10).
    matches(/^[0-9\b]+$/,"Invalid Phone Number").
    required("This field is required"),
    pan: yup.string().matches("[A-Z]{5}[0-9]{4}[A-Z]{1}","InValid PAN No").
    required("This field is required"),
    doj: yup.string().required("This field is required"),
    gst: yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}Z[a-zA-Z0-9]{1}$/,"InValid GST No").
    required("Gst is required"),
    natureOfWork: yup.string().required("This field is required"),
    workLocation: yup.string().required("This field is required"),
    venderCode: yup.string().required("This field is required"),
    pincode: yup.string().matches(/^[0-9]+$/, "Must be only digits").
    length(6, 'Pincode must be exactly 6 characters').
    required('Pincode is required'),
    // state: yup.string().required("This field is required"),
    // city: yup.string().required("This field is required"),
    address: yup.string().required("This field is required"),
    propriterName: yup.string().required("This field is required"),
    supervisorName: yup.string().required("This field is required"),
    // bankName: yup.string().required("This field is required"),
    // bankAccountNo: yup.string().
    // matches(/^[0-9]{9,18}$/,"Invalid Bank number").
    // required("This field is required"),
    // ifscCode: yup.string().
    // matches(/^[A-Z]{4}0[A-Z0-9]{6}$/,"Enter valid ifsc code").
    // required("This field is required"),
    supervisorMobileNo: yup.string().min(10).
    max(10).
    matches(/^[0-9\b]+$/,"Invalid Phone Number").
    required("This field is required"),
    password: yup.string().min(8, 'Password should be of minimum 8 characters length').
    required('Password is required'),
    company: yup.string().required("This field is required"),
    project_id: yup.string().required("This field is required"),

  });

  useEffect(() => {
    if (success) {
      toast.success(`${success?.message ?? "Success"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });

      dispatch(GeneralActions.removeSuccess(SubContractorActions.createSubContractor.type));
      history.goBack();
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      toast.success(`${error?.message ?? "Error"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });

      dispatch(GeneralActions.removeError(SubContractorActions.createSubContractor.type));
    }
  }, [error]);


  const formik = useFormik({
    initialValues: {
      avatar: "",
      subContractorCode: "",
      subContractorName: "",
      subContractorEmail: "",
      subContractorPhone: "",
      pan: "",
      doj: "",
      gst: "",
      natureOfWork: "",
      workLocation: "",
      venderCode: "",
      pincode: "",
      state: "",
      city: "",
      address: "",
      propriterName: "",
      supervisorName: "",
      bankName: "",
      bankAccountNo: "",
      ifscCode: "",
      scopeOfBocw: "",
      bocwValidUpto: "",
      scopeOfGPA_WCPolicy: "",
      gpa_WcNo: "",
      gpa_WcValidityFrom: "",
      gpa_WcValidityTo: "",
      scopeOfLabourLicence: "",
      labourLicenceNo: "",
      labourLicenceValidity: "",
      scopeOfPfRegistration: "",
      pfRegistrationNo: "",
      designation: "6298542335d00a7dfc0e15b4",
      company: companyDetails._id ? companyDetails._id : companyId,
      project_id: projectDetails._id ? projectDetails._id : projectId,
      status: 1,
      NoofLabour: "",
      SupervisiorMobileNumber: "",
      country: "",
      gender: "",
      birthday: "",
      description: "",
      role: "",
      isContractor: true,
      password: "",
      name: "",
      is_subcontractor_user: true,
      supervisorMobileNo: "",
      user_type: 3,
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    formik.setFieldValue("city", "");
    formik.setFieldValue("state", "");
    formik.setFieldValue("address", "");
}, []);

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    const objectUrl = URL.createObjectURL(file);
    formik.setFieldValue("avatar", file);
    setPreview(objectUrl);
  };

  // const handleToggleStatus = (e) => {
  //     const { checked } = e.target;

  //     formik.setFieldValue("status", checked);
  // }
  const handleSubmit = (params) => {
    console.log('value',params)
    dispatch(SubContractorActions.createSubContractor(params));
  };

  // window.scrollTo({
  //   top: 0,
  //   behavior: 'smooth',
  // });
  return (
    <Box>
      <PageTitle isBack={true} title="Create Sub-Contractor" />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item lg={4} sm={12} xs={12}>
            <Card>
              <Picker className="uploadpicker">
                <input
                  type="file"
                  id="profile"
                  name="logo"
                  onChange={handleChangeImage}
                />
                <label htmlFor="profile" className="cursor-pointer">
                  <img className="uploadimgfitsize" alt="profile" src={preview || Avatar || formik.values.avatar } />
                  <span>
                      <CameraAltOutlinedIcon sx={{fontSize: '25', mr: '2'}} />
                      Upload Photo
                  </span>
                </label>
              </Picker>
          </Card>
          </Grid>
          <Grid item lg={8} sm={12} xs={12}>
            <BasicInformation formik={formik} />
            <AccountSetting formik={formik} />
            {/* <BankAccountDetails formik={formik} />
            <Bocw formik={formik} />
            <GpaWc formik={formik} />
            <LabourLicence formik={formik} /> */}
            <PFDetails formik={formik} />
            <Grid container justifyContent="flex-end">
              <Button
                size="large"
                variant="contained"
                color="primary"
                type="submit"
              >
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <>Submit</>
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default CreateSubcontractor;
