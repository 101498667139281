import React, { useState, useEffect } from 'react';
import {
    Card, Grid, MenuItem, Typography, Button
} from "@mui/material";
import SelectField from 'components/SelectField';
import { useHistory } from "react-router-dom";
import { Constants } from "utils/constants";
import { CompanySelector } from "selectors/CompanySelector";
import { useSelector, useDispatch } from "react-redux";
import { MasterDataSelector, ProjectSelector, SubContractorSelector } from "selectors";
import { CompanyActions, MasterDataActions, ProjectActions, ReportActions, SubContractorActions } from "slices/actions";
import PropTypes from "prop-types";

FormIndex.propTypes = {
    formData: PropTypes.string,
    title: PropTypes.string
}
export default function FormIndex(props) {
    const { formData, title } = props
    const history = useHistory();
    const dispatch = useDispatch();
    const userType = localStorage.getItem('userType');
    const companyId = localStorage.getItem('companyID');
    const projectId = localStorage.getItem('projectID');
    const subContractorId = localStorage.getItem('subcontractorID');
    const companyList = useSelector(CompanySelector.getCompanyList());
    const projectList = useSelector(ProjectSelector.getProjectList());
    const yearList = useSelector(MasterDataSelector.getYearList());
    const monthList = useSelector(MasterDataSelector.getMonthList());
    const [assignedProject, setAssignedProject] = useState("");
    const assignedProjectToSubcontractorList = useSelector(SubContractorSelector.getAssignedSubcontractor());
    const [formAdd, setFormAdd] = useState({
        company_id: companyId ? companyId : "",
        project_id: projectId ? projectId : "",
        subContractor_id: subContractorId ? subContractorId : "",
        month: "",
        year: "",
        ...(formData === "formB" || formData === "formD" || formData === "ecrChalan" ? { attendance: "" } : {}),
        // attendance: ""
    })
    const [errObj, setErrObj] = useState({});

    useEffect(() => {
        dispatch(MasterDataActions.getMonthList());
        dispatch(MasterDataActions.getYearList());
        if (!companyId) {
            dispatch(CompanyActions.getCompanyList());
        }
        if (companyId && !projectId) {
            const params = {
                company_id: companyId
            }

            dispatch(ProjectActions.getProjectList(params))
        }
        if (companyId && projectId) {
            const params = {
                company_id: companyId,
                project_id: projectId
            }
            dispatch(SubContractorActions.getAssignedSubContractor(params))
        }
        if (subContractorId) {
            const params = {
                subcontractorId: subContractorId
            }
            dispatch(SubContractorActions.getAssignedSubContractor(params));
        }
    }, [])

    useEffect(() => {
        if (formAdd.company_id || companyId) {
            const params = {
                company_id: formAdd.company_id ? formAdd.company_id : companyId,
                status: 1
            }

            dispatch(ProjectActions.getProjectList(params))
        }
    }, [formAdd.company_id])

    useEffect(() => {
        if (formAdd.project_id || projectId) {
            const params = {
                company_id: formAdd.company_id || companyId,
                project_id: formAdd.project_id || projectId,
            }

            dispatch(SubContractorActions.getAssignedSubContractor(params));

        }
    }, [formAdd.project_id])

    const handleChangeCompany = (e) => {
        delete errObj.company_id;
        setErrObj({ ...errObj });
        const cId = e.target.value;
        formAdd.company_id = cId;
        formAdd.project_id = "";
        formAdd.subContractor_id = "";
        setFormAdd({ ...formAdd })
    }
    const handleChangeProject = (e) => {
        delete errObj.project_id;
        setErrObj({ ...errObj });
        if (formAdd.company_id) {
            const pId = e.target.value
            formAdd.subContractor_id = "";
            formAdd.project_id = pId;
            setFormAdd({ ...formAdd })
        }
    }
    const handleChangeSubcontractor = (e) => {
        delete errObj.subContractor_id;
        setErrObj({ ...errObj });
        if (formAdd.project_id) {
            const sId = e.target.value
            formAdd.subContractor_id = sId;
            setFormAdd({ ...formAdd })
        }

    }
    const handleChangeMonth = (e) => {
        delete errObj.month;
        setErrObj({ ...errObj });
        formAdd.month = e.target.value;
        setFormAdd({ ...formAdd })
    }
    const handleChangeYear = (e) => {
        delete errObj.year;
        setErrObj({ ...errObj });
        formAdd.year = e.target.value;
        setFormAdd({ ...formAdd })

    }
    const handleChangeProjectBySubcontractor = (e) => {
        delete errObj.project_id;
        setAssignedProject(e.target.value)
        for (const i in assignedProjectToSubcontractorList.data) {
            if (assignedProjectToSubcontractorList.data[i].projectId._id === e.target.value) {
                formAdd.project_id = e.target.value
                formAdd.company_id = assignedProjectToSubcontractorList.data[i].companyId._id
                setFormAdd({ ...formAdd })
            }
        }
    }
    const validateForm = () => {
        let valid = true
        if (!formAdd.company_id) {
            errObj.company = 'This field is Required';
            valid = false;
        }
        if (!formAdd.project_id) {
            errObj.project_id = 'This field is Required';
            valid = false;
        }
        if (!formAdd.subContractor_id) {
            errObj.subContractor_id = 'This field is Required';
            valid = false;
        }
        if (!formAdd.month) {
            errObj.month = 'This field is Required';
            valid = false;
        }
        if (!formAdd.year) {
            errObj.year = 'This field is Required';
            valid = false;
        }
        // if (!formAdd.attendance) {
        //     errObj.attendance = 'This field is required';
        //     valid = false;
        // }
        if ((formData === "formB" || formData === "formD" || formData === "ecrChalan") && !formAdd.attendance) {
            errObj.attendance = 'This field is required';
            valid = false;
        }

        setErrObj({ ...errObj });
        return valid;
    }

    const onNext = () => {
        if (validateForm()) {
            if (formData === "formD") {
                dispatch(ReportActions.getFormData(formAdd))
                history.push("/app/formdresult")
            } else if (formData === "formA") {
                dispatch(ReportActions.getFormData(formAdd));
                history.push("/app/formaresult")
            } else if (formData === "formC") {
                dispatch(ReportActions.getFormData(formAdd));
                history.push("/app/formcresult")
            } else if (formData === "formB") {
                dispatch(ReportActions.getFormData(formAdd));
                history.push("/app/formbresult")
            } else if (formData === "ecrChalan") {
                dispatch(ReportActions.getFormData(formAdd));
                history.push("/app/ECRchalan/pfresult")
            } else if (formData === "exitLaborList") {
                const params = {
                    ...formAdd,
                    exitFlag: 1
                }
                dispatch(ReportActions.getFormData(params));
                history.push("/app/ExitLaborListResult")
            }
        }
    }

    function handleChangeattendance(e) {
        formAdd.attendance = e.target.value;
        setFormAdd({ ...formAdd })
    }

    return (
        <Card>
            <Typography variant="h5" sx={{ fontWeight: 600, position: 'relative', mb: 3 }}>{title}</Typography>
            <Grid container spacing={3}>
                {userType === Constants.SUPERADMIN ? (
                    <Grid item lg={6} sm={12} xs={12}>
                        <Typography variant='h6' sx={{ fontSize: 15 }}>Company</Typography>
                        <SelectField
                            value={formAdd.company_id}
                            onChange={handleChangeCompany}
                        >
                            {companyList && companyList.map((item, index) => (
                                <MenuItem value={item._id} key={index}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </SelectField>
                        {errObj.company_id ? <Typography sx={{ color: 'red', fontSize: 12 }}>This field is Required</Typography> : null}
                    </Grid>
                ) : null}
                {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER ? (
                    <Grid item lg={6} sm={12} xs={12}>
                        <Typography variant='h6' sx={{ fontSize: 15 }}>Project</Typography>
                        <SelectField
                            value={formAdd.project_id}
                            onChange={handleChangeProject}
                        >
                            {(formAdd.company_id && projectList) && projectList.map((item, index) => (
                                <MenuItem value={item._id} key={index}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </SelectField>
                        {errObj.project_id && <Typography sx={{ color: 'red', fontSize: 12 }}>This field is Required</Typography>}
                    </Grid>
                ) : null}
                {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER || userType === Constants.PROJECT_USER ? (
                    <Grid item lg={6} sm={12} xs={12}>
                        <Typography variant='h6' sx={{ fontSize: 15 }}>SubContractor</Typography>
                        <SelectField
                            value={formAdd.subContractor_id}
                            onChange={handleChangeSubcontractor}
                        >
                            {(formAdd.project_id && assignedProjectToSubcontractorList) && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item, index) => (
                                <MenuItem value={item?.subcontractorId?._id} key={index}>
                                    {item?.subcontractorId?.subContractorName}
                                </MenuItem>
                            ))}
                        </SelectField>
                        {errObj.subContractor_id && <Typography sx={{ color: 'red', fontSize: 12 }}>This field is Required</Typography>}
                    </Grid>
                ) : null}
                {userType === Constants.SUB_CONTRACTOR_USER ? (
                    <Grid item lg={4} sm={12} xs={12}>
                        <Typography sx={{ fontSize: 15 }}>Project</Typography>
                        <SelectField
                            value={assignedProject}
                            onChange={handleChangeProjectBySubcontractor}
                        >
                            {assignedProjectToSubcontractorList && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item, index) => (
                                <MenuItem key={index} value={item.projectId._id}>
                                    {item?.projectId?.name}({item?.companyId?.name})
                                </MenuItem>
                            ))}
                        </SelectField>
                        {errObj.project_id && <Typography sx={{ color: 'red', fontSize: 12 }}>This field is Required</Typography>}
                    </Grid>
                ) : null
                }

                <Grid item lg={2} sm={12} xs={12}>
                    <Typography sx={{ fontSize: 15 }}>Month</Typography>
                    <SelectField
                        value={formAdd.month}
                        onChange={handleChangeMonth}
                    >
                        {monthList && monthList.masterMonthTable && monthList.masterMonthTable.map((item, index) => (
                            <MenuItem value={item.key} key={index}>
                                {item.mth}
                            </MenuItem>
                        ))}
                    </SelectField>
                    {errObj.month ? <Typography sx={{ color: 'red', fontSize: 12 }}>This field is Required</Typography> : null}
                </Grid>
                <Grid item lg={2} sm={12} xs={12}>
                    <Typography sx={{ fontSize: 15 }}>Year</Typography>
                    <SelectField
                        value={formAdd.year}
                        onChange={handleChangeYear}
                    >
                        {yearList && yearList.masterYearTable && yearList.masterYearTable.map((item, index) => (
                            <MenuItem value={item.yr} key={index}>
                                {item.yr}
                            </MenuItem>
                        ))}
                    </SelectField>
                    {errObj.year ? <Typography sx={{ color: 'red', fontSize: 12 }}>This field is Required</Typography> : null}
                </Grid>
                {/* <Grid item lg={2} sm={12} xs={12}>
                        <Typography sx={{ fontSize: 15 }}>Attendance Type</Typography>
                        <SelectField
                            value={formAdd.attendance}
                            onChange={handleChangeattendance}
                        >
                            <MenuItem value='mainAttendance'>Attendance</MenuItem>
                            <MenuItem value='subAttendance'>SubContractor(Attendance)</MenuItem>
                        </SelectField>
                        {errObj.attendance ? <Typography sx={{ color: 'red', fontSize: 12 }}>This field is Required</Typography> : null}
                    </Grid> */}
                {
                    userType === Constants.SUPERADMIN && (formData === "formB" || formData === "formD" || formData === "ecrChalan") &&
                    <Grid item lg={2} sm={12} xs={12}>
                        <Typography sx={{ fontSize: 15 }}>Attendance Type</Typography>
                        <SelectField
                            value={formAdd.attendance}
                            onChange={handleChangeattendance}
                        >
                            <MenuItem value='mainAttendance'>Attendance</MenuItem>
                            <MenuItem value='subAttendance'>SubContractor(Attendance)</MenuItem>
                        </SelectField>
                        {errObj.attendance ? <Typography sx={{ color: 'red', fontSize: 12 }}>This field is Required</Typography> : null}
                    </Grid>
                }
                {
                    userType === Constants.SUPERADMIN && (formData === "formB" || formData === "formD" || formData === "ecrChalan") &&
                    (
                        <Grid item lg={2} sm={12} xs={12} justifyContent="flex-end" sx={{ mt: 2, textAlign: 'right' }}>
                            <Button
                                size="large"
                                variant="contained"
                                color="primary"
                                sx={{ mt: 1, display: 'flex', marginLeft: userType === Constants.SUB_CONTRACTOR_USER ? '40px' : '950px', marginTop: userType === Constants.SUB_CONTRACTOR_USER ? '7px' : '-21px' }}
                                onClick={onNext}
                            >
                                Submit
                            </Button>
                        </Grid>
                    )
                }

                {
                    userType === Constants.SUPERADMIN && (formData === "formA" || formData === "formC") &&
                    <Grid item lg={2} sm={12} xs={12} justifyContent="flex-end" sx={{ mt: 2, textAlign: 'right' }}>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 1 }}
                            onClick={onNext}
                        >
                            Submit
                        </Button>
                    </Grid>
                }
                {
                    userType !== Constants.SUPERADMIN &&
                    <Grid item lg={2} sm={12} xs={12} justifyContent="flex-end" sx={{ mt: 2, textAlign: 'right' }}>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 1 }}
                            onClick={onNext}
                        >
                            Submit
                        </Button>
                    </Grid>
                }
            </Grid>
        </Card>
    )
}