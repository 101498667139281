import React from 'react';
import PropTypes from "prop-types";
import { Button, Dialog, DialogContent,DialogTitle, MenuItem,Grid,Typography, CircularProgress} from '@mui/material';
import {useFormik} from "formik";
import Input from "components/Input";
import SelectField from "components/SelectField";
import * as yup from "yup";
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import { LabourActions } from 'slices/actions';
import { useDispatch, useSelector } from 'react-redux';
import { GeneralSelector } from 'selectors';

EducationForm.propTypes = {
   onOpen: PropTypes.bool,
   onClose: PropTypes.func,
};

function EducationForm(props) {
    const {onOpen,onClose} = props
    const dispatch = useDispatch()
    const {id} = useParams();
    const userType = localStorage.getItem("userID");
    const loading = useSelector(GeneralSelector.loader(LabourActions.addLabourEducation.type));

    const validationSchema = yup.object({
        higherEducation: yup.string().required('This field is required'),
        passingyear: yup.string().required('This field is required'),
        institute: yup.string().required('This field is required'),
    });
    const formik = useFormik({
      initialValues: {
        higherEducation: "", 
        passingyear: "",
        institute: "",
      },
      enableReinitialize: true,
      validateOnChange: true,
      validationSchema: validationSchema,
      onSubmit: (values) => {
        handleSubmit(values);
      },
    });
    const handleSubmit = (values) => {
      const params = {
                ...values,
              labourID: id,
              added_by: userType,
              literacy: 1
        };
      dispatch(LabourActions.addLabourEducation(params));
      onClose();
      formik.setFieldValue('higherEducation',"")
      formik.setFieldValue('passingyear',"")
      formik.setFieldValue('institute',"")
    }
    
//     window.scrollTo({
//       top: 0,
//       behavior: 'smooth',
//   });
 const closeDialouge = () => {
  onClose();
  formik.setFieldValue("higherEducation","");
  formik.setFieldValue("passingyear","");
  formik.setFieldValue("institute","");
  }

  return (                         
     <>
    <Dialog open={onOpen} close={closeDialouge}>
    <DialogTitle>
          <Typography> Education</Typography> 
          <CloseIcon sx={{float: 'right'}} onClick={closeDialouge}/>
    </DialogTitle>
    <DialogContent>
    
         <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Typography sx={{fontSize: 15}}>Higher Education *</Typography>
                        <SelectField
                            name="higherEducation"
                            value={formik.values.higherEducation}
                            onChange={formik.handleChange}
                            error={formik.touched.higherEducation && Boolean(formik.errors.higherEducation)}
                            helperText={formik.touched.higherEducation && formik.errors.higherEducation}
                            style={{ display: "block", width: "100%" }}
                        >
                            <MenuItem value={'Non-Matric'}>Non-Matric</MenuItem>
                            <MenuItem value={'Matric'}>Matric</MenuItem>
                            <MenuItem value={'Senior-Secondary'}>Senior-Secondary</MenuItem>
                            <MenuItem value={'Graduate'}>Graduate</MenuItem>
                            <MenuItem value={'Post-Graduate'}>Post-Graduate</MenuItem>
                            <MenuItem value={'Doctorate'}>Doctorate</MenuItem>
                            <MenuItem value={'Technical (Professional)'}>Technical (Professional)</MenuItem>
                        </SelectField>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Year of Passing *"
                            name='passingyear'
                            value={formik.values.passingyear}
                            onChange={formik.handleChange}
                            error={formik.touched.passingyear && Boolean(formik.errors.passingyear)}
                            helperText={formik.touched.passingyear && formik.errors.passingyear}
                        />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Input
                            label="School/Board *"
                            name='institute'
                            value={formik.values.institute}
                            onChange={formik.handleChange}
                            error={formik.touched.institute && Boolean(formik.errors.institute)}
                            helperText={formik.touched.institute && formik.errors.institute}
                            />
                    </Grid>                   
                        <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained">
                                {loading?(
                                   <CircularProgress color="inherit" size={20}/>
                                ):(
                                  <>Submit</>  
                                )}
                            </Button>
                        </Grid>
                </Grid>
            </form>
       
  
    </DialogContent>
 
</Dialog>

</>
  )
}

export default EducationForm