import { createSlice } from "@reduxjs/toolkit";

export const ContractorSlice = createSlice({
    name: "contractor",
    initialState: {
        contractorList: []
    },
    reducers: {
       getContractorList: () => {},
       getContractorListSuccess: (state,action) => {
        state.contractorList = action.payload
       }
    }
});


export default ContractorSlice;