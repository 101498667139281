import React, {useEffect, useState} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
     Card, 
      Table, TableHead, TableRow, 
       TableCell,
       TableBody
} from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { ReportSelector } from 'selectors/ReportSelector';
import PageTitle from "components/PageTitle";
import { GeneralSelector } from 'selectors';
import { ReportActions } from 'slices/actions';
import ListSkeleton from 'components/Skeleton/ListSkeleton';

export default function FormCResult() {
    const dispatch = useDispatch();
    const reportAData = useSelector(ReportSelector.getFormAData());
    const formData = useSelector(ReportSelector.getFormData());
    const loading = useSelector(GeneralSelector.loader(ReportActions.getReportData.type))
    const [formDate,setFormDate] = useState({
        date: "",
     });
    useEffect(() => {
        if (formData) {
           dispatch(ReportActions.getReportAData(formData)); 
        }
    },[formData])
   

    useEffect(() => {
        const tempDate = new Date();
        // let date = tempDate.getDate();
        // if (date < 10) {
        //     date = `0${date}`   
        // }
        let month = tempDate.getMonth() + 1;
        if (month < 10) {
            month = `0${month}`   
        }
        const year = tempDate.getFullYear()  
        const todayDate =`${year}-${month}`
        // formDate.date = todayDate
        formDate.date = `"Form C" ${todayDate}`
        setFormDate({...formDate})
    }, []);

    return (
        <>
        <PageTitle isBack={true} title="Form C" />
            {loading? (
                <ListSkeleton/>
            ):(
        <Card>
            <div className="" style={{maxWidth: '90%', width: '100%', overflowX: 'auto'}}>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn btn-primary mb-3"
                    table="table-to-xls"
                    filename={formDate.date}
                    sheet="tablexls"
                    buttonText="Export"
                />
                <Table className="table Tableform fixed_header" id="table-to-xls">
                    <TableHead className="thead-dark">
                        <TableRow className="Row_border_none">
                            <TableCell colSpan='13' align='center'>FORM C <br/>
                                FORMAT OF REGISTER OF LOAN/ RECOVERIES
                            </TableCell>
                        </TableRow>
                        <TableRow className="Row_border_none">
                            <TableCell colSpan='7' sx={{fontSize: 12}}>
                                Name of the Establishment: {reportAData.companyname}< br/>
                            </TableCell>
                            <TableCell colSpan='6' align="right" sx={{fontSize: 12}}>
                                Name of Owner: {reportAData.subContractorname}< br/>
                            </TableCell>
                        </TableRow>
                        <TableRow className="Row_border_none">
                            <TableCell colSpan='13' sx={{fontSize: 12}}>
                                Nature and location of work: 
                            </TableCell>
                        </TableRow>
                        <TableRow className="Row_border_none">
                            <TableCell colSpan='7' sx={{fontSize: 11}}>
                                Name and address of establishment in/under which contract is carried on:- {reportAData.projectname}
                            </TableCell>
                            <TableCell colSpan='6' align="right" sx={{fontSize: 11}}>
                                {/* Nov-20 Name and address of principal employer:-  */}
                            </TableCell>
                        </TableRow>
                        <TableRow className="tableform">
                            <TableCell>Sl. Number in Employee Register</TableCell>
                            <TableCell width="40%">Name</TableCell>
                            <TableCell>
                                Recovery Type(Damage/loss/fine/advance/loans)
                            </TableCell>
                            <TableCell>Particulars</TableCell>
                            <TableCell>Date of damage/Loss*</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Whether show cause issued*</TableCell>
                            <TableCell>Explanation heard in presence of*</TableCell>
                            <TableCell>Number of Instalments</TableCell>
                            <TableCell>First Month/Year</TableCell>
                            <TableCell>Last Month/Year</TableCell>
                            <TableCell>Date of Complete Recovery</TableCell>
                            <TableCell>Remarks</TableCell>
                        </TableRow>
                        {/* <TableRow className="tableform">
                            <TableCell>1</TableCell>
                            <TableCell>2</TableCell>
                            <TableCell>3</TableCell>
                            <TableCell>4</TableCell>
                            <TableCell>5</TableCell>
                            <TableCell>6</TableCell>
                            <TableCell>7</TableCell>
                            <TableCell>8</TableCell>
                            <TableCell>9</TableCell>
                            <TableCell>10</TableCell>
                            <TableCell>11</TableCell>
                            <TableCell>12</TableCell>
                            <TableCell>13</TableCell>
                        </TableRow> */}
                    </TableHead>
                    <TableBody style={{height: '200px', overflowY: 'auto'}}>
                        {reportAData && reportAData.data && reportAData.data.map((res,index) => <TableRow 
                            key={index} className="tableform">
                            <TableCell align='center'>{index+1}</TableCell>
                            <TableCell width="40%">{res.firstName} {res.lastName}</TableCell>
                            <TableCell>Nil</TableCell>
                            <TableCell>Nil</TableCell>
                            <TableCell>Nil</TableCell>
                            <TableCell>Nil</TableCell>
                            <TableCell>Nil</TableCell>
                            <TableCell>Nil</TableCell>
                            <TableCell>Nil</TableCell>
                            <TableCell>Nil</TableCell>
                            <TableCell>Nil</TableCell>
                            <TableCell>Nil</TableCell>
                            <TableCell>Remarks</TableCell>
                        </TableRow>)}
                    </TableBody>   
                </Table>
            </div>
        </Card>)}
        </>
    )
}