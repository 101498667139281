import React, {useState,useEffect} from "react";
import {
    Button, Card, 
    // Chip,
    FormControl, Typography, Box,
     Grid, IconButton, InputAdornment, MenuItem,
    //    MenuItem,
} from "@mui/material";
// import ROLES from "constants/role";
// import Box from "@mui/material/Box";
import {useFormik} from "formik";
import * as yup from "yup";
import {toast} from "react-toastify";
import {useDispatch,useSelector} from "react-redux";
import Input from "components/Input";
// import SelectField from "components/SelectField";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {DesignationActions, GeneralActions, ProjectActions, UserActions} from "slices/actions";
import { DesignationSelector, GeneralSelector, ProjectSelector } from "selectors";
import { useParams,useHistory } from "react-router-dom";
import PageTitle from "components/PageTitle";
import SelectField from "components/SelectField";

export default function ProjectUserAccount() {
    const history = useHistory();
    const dispatch = useDispatch();
    const {id} = useParams();
    const projectDetails = useSelector(ProjectSelector.getProjectById());
    const success = useSelector(GeneralSelector.success(UserActions.createUser.type));
    const error = useSelector(GeneralSelector.error(UserActions.createUser.type));
    const designations = useSelector(DesignationSelector.getDesignations());
    const [showPassword, setShowPassword] = useState(false);
    // const companyId = localStorage.getItem('companyID')

    useEffect(() => {
        const params = {
            companyId: projectDetails?.data?.project?.company_id ?? "",
            projectId: id ? id:""
        }
        dispatch(DesignationActions.getDesignations(params));
    },[])

    console.log("projectDetails",projectDetails?.data?.project?.company_id)
    const validationSchema = yup.object({
        name: yup.string().required('userName is required'),
        email: yup.string().email().
        required("Email is required"),
        designation: yup.string().required("Role is required"),
        password: yup.string().min(8, 'Password should be of minimum 8 characters length').
        required('Password is required'),
        phone: yup.string().min(10).
        max(10).
        matches(/^[0-9\b]+$/,"Invalid Phone Number").
        required('Phone Number is required'),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            password: "",
            phone: "",
            project_id: id ? id:"",
            is_project_user: true,
            user_type: 2,
            designation: "",
            projectId: id ? id:"",
            status: 1,
            companyId: projectDetails?.data?.project?.company_id ?? ""
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });
    
    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(UserActions.createUser.type));
            dispatch(ProjectActions.getProjectById(id));
            history.goBack();
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeError(UserActions.createUser.type));
        }
    }, [error]);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    
    const handleSubmit = (values) => {
        dispatch(UserActions.createUser(values));
    }

    return (
        <Card>
             <PageTitle isBack={true} title="Add User" />
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="User Name"
                            name='name'
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.name) && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}/>
                    </Grid>
                 
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Email Id"
                            type="email"
                            name='email'
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.email) && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <Input
                            label="Password"
                            inputProps={{
                                autoComplete: "new-password"
                            }}
                            placeholder='●●●●●●●●●●'
                            type={!showPassword?"password":"text"}
                            name='password'
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={(formik.touched.password && formik.errors.password)}
                            />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <FormControl fullWidth>
                            <Typography variant='' sx={{fontSize: "15px"}}>Phone Number</Typography>
                            <Box sx={{
                                display: 'flex',
                                gap: 1.5
                            }}>
                                <Box sx={{ width: 90 }}>
                                    <Input style={{appearence: 'none'}}
                                        sx={{
                                            padding: "10px 8px",
                                            textAlign: 'center',
                                            // '& .Mui-disabled': {
                                            //     fillColor: theme.palette.common.black
                                            // }
                                        }}
                                        autoComplete='new-password'
                                        name='phoneCode'
                                        startAdornment='+'
                                        value="91"
                                        />
                                </Box>
                                <Input
                                    name='phone'    
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    helperText={formik.touched.phone && formik.errors.phone}/>
                            </Box>
                        </FormControl>
                       
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <Typography sx={{fontSize: '15px'}}>Role</Typography>
                        <SelectField
                            name="designation"
                            value={formik.values.designation}
                            onChange={formik.handleChange}
                            error={formik.touched.designation && Boolean(formik.errors.designation)}
                            helperText={formik.touched.designation && formik.errors.designation}
                            >
                            {designations && designations.map((item,index) => (
                            <MenuItem key={index} value={item._id}>
                                {item.name}
                            </MenuItem>
                            ))}
                        </SelectField>
                    </Grid>
                    <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained">
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Card>
    )
}