import React, { useEffect, useState } from "react";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
    Box, Card,Table, TableHead, TableRow,TableCell, TableBody
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import { GeneralSelector, LabourSelector } from "selectors";
import ListSkeleton from "components/Skeleton/ListSkeleton";
import FormIndex from "../FormIndex";
import { GeneralActions, LabourActions } from "slices/actions";
import { format } from 'date-fns';


const Content = styled(Card)(() => ({
    marginBottom: 20
}));

function SafetyTrainingList () {
  const dispatch = useDispatch();
  const safetyTrainingList = useSelector(LabourSelector.getAllSafetyTrainingList());
  const loading = useSelector(GeneralSelector.loader(LabourActions.getAllSafetyTrainingList.type));
  const [showList,setShowList] = useState(false);
  const success = useSelector(GeneralSelector.success(LabourActions.getAllSafetyTrainingList.type));

 useEffect(() => {
    if (success) {
       dispatch(GeneralActions.removeSuccess(LabourActions.getAllSafetyTrainingList.type));
       setShowList(true)
    }
  }, [success]);

  return (
    <Box>
    <FormIndex repotType="SafetyTraining" title="Safety Training Report" />
    {loading ? (
        <ListSkeleton />
    ) : (
        <Content>
          {showList && safetyTrainingList && safetyTrainingList.length !== 0 ?(
            <>
           <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn btn-primary mb-3"
                    table="table-to-xls"
                    filename={"Safetytraining Report"}
                    sheet="tablexls"
                    buttonText="Export"
                     /></>):null}
            <Table className="table" id="table-to-xls">
                <TableHead>
                    <TableRow>
                            <TableCell>Labour Name</TableCell>
                            <TableCell>Aadhar Number</TableCell>
                            <TableCell>Training Type</TableCell>
                            <TableCell>Training Name</TableCell>
                            <TableCell>Completed Date</TableCell>
                            <TableCell>Project Name</TableCell>
                            <TableCell>Subcontractor Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(!showList || (safetyTrainingList && safetyTrainingList.length === 0)) && (
                        <TableRow>
                            <TableCell align="center" colSpan={5}>
                                No Data
                            </TableCell>
                        </TableRow>
                    )}
                    {showList && safetyTrainingList && safetyTrainingList.map((item, i) => (
                        <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                                <TableCell>{`${item?.labourWithSafetyTraining?.firstName} ${' '} ${item?.labourWithSafetyTraining?.lastName}`}</TableCell>
                                <TableCell>{item?.labourWithSafetyTraining?.aadharNo} </TableCell>
                                <TableCell>{item.trainingType}</TableCell>
                                <TableCell>{item.trainingName}</TableCell>
                                <TableCell>{item?.endDate ? format(new Date(item.endDate), 'dd/MM/yyyy'):""}</TableCell>
                                <TableCell>{item.projectName}</TableCell>
                                <TableCell>{item.subcontractorName}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Content>
   )} 
</Box>
  )
}

export default SafetyTrainingList