import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";

AttendanceAnalysis.propTypes = {
  data: PropTypes.object,
};

function AttendanceAnalysis(props) {
  const { data } = props;
  const [totalLabour,setTotalLabour] = useState(); 
  const [presentLabour,setPresentLabour] = useState();

  useEffect(() => {
    for (const i in data) {
      if (data[i].title) {
      if (data[i].title === "Active Labour") {
        setTotalLabour(data[i].count)
      }
      if (data[i].title === "Attendance") {
        setPresentLabour(data[i].count)
      }
    }
  }

  },[data])
  console.log("Math.ceil((presentLabour/totalLabour)*100)",Math.ceil((presentLabour/totalLabour)*100))
  const state = {
    series: [Math.floor((presentLabour/totalLabour)*100)],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
        },
      },
      labels: ["Today"],
    },
  };

  return (
    <>
      <div className="h-100 card">
        <div className="py-2 bg-light card-header">
          <div className="align-items-center row">
            <div className="col">
              <h5 className="text-800">Attendance Analysis</h5>
            </div>
            <div className="text-right col-auto">
              <div className="font-sans-serif btn-reveal-trigger dropdown">
                <button
                  id="react-aria5185573897-11"
                  aria-expanded="false"
                  data-boundary="viewport"
                  type="button"
                  className="text-600 btn-reveal dropdown-toggle btn btn-link btn-sm"
                >
          
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column align-items-between h-100 card-body">
          <div id="chart">
            <ReactApexChart
              options={state.options}
              series={state.series}
              type="radialBar"
              height={250}
            />
          </div>
          <div className="text-center mt-3">
            <h6 className="fs-0 mb-1">
                {presentLabour} Present
            </h6>
            <p className="fs--1 mb-0">{totalLabour} Total Labour</p>
          </div>
        </div>
    
      </div>
    </>
  );
}
export default AttendanceAnalysis;
