import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AttendanceSelector, UserSelector } from "selectors";
import { getTodayData } from "utils/convertion";
import { UserActions } from "slices/actions";
import LineCharts from "./components/LineCharts";
import AttendanceAnalysis from "./components/AttendanceAnalysis";
import Widgets from "./components/Widgets";


export default function Dashboard() {
  const dispatch = useDispatch();
  const attendances = useSelector(AttendanceSelector.getAttendances());
  const dashboard = useSelector(UserSelector.getDashboardData());
  const [widget, setWidget] = useState({
    employee: 0,
    attendance: 0,
    expenses: 0,
  });
 useEffect(() => {
        dispatch(UserActions.getDashboardData());
    }, []);
  
 useEffect(() => {
    const data = attendances?.map((item) => ({ ...item, date: item.checkIn }));
    const todayAttendance = getTodayData(data);

    setWidget({
      ...widget,
      attendance: todayAttendance?.length ?? 0,
    });
  }, [attendances]);

 
  return (
    <Box>
     <LineCharts dashboardData={dashboard?.result}/>
     
      {(dashboard && dashboard.result && dashboard.result.dashboard && dashboard.result.dashboard ? (
          <Widgets data={dashboard}/>
       ) : null)}

      <div className="g-3 mb-3 row">
        <div className="col-xxl-6 col-md">
           <AttendanceAnalysis data={dashboard?.result?.dashboard}/>
        </div>
       
      </div>
     
    </Box>
  );
}
