import React, { useEffect, useState } from "react";
import {
    Box,
    Card,
    Grid,
    Switch,
    Typography
} from "@mui/material";
import PageTitle from "components/PageTitle";
import styled from "@emotion/styled";
import Avatar from "assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { GeneralSelector } from "selectors";
import { useParams } from "react-router-dom";
import menus from "./constants/menus";
import CompanyMenuForm from "./Component/AboutProjectDetails/form/ProjectMenuForm";
import FormSkeleton from "components/Skeleton/FormSkeleton";
import { MasterDataActions, ProjectActions,TransactionActions} from "slices/actions";

import { ProjectSelector } from "selectors/ProjectSelector";
// import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import DialogConfirm from "components/DialogConfirm";

const Picker = styled(Box)(() => ({
    width: 120,
    height: 120,
    margin: "40px auto 10px auto",

    "& input": {
        display: "none"
    },

    "& img": {
        width: "100%",
        borderRadius: 100
    }
}));

const SwitchBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
}));

export default function ProjectDetail() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const projectDetails = useSelector(ProjectSelector.getProjectById());
    const loading = useSelector(GeneralSelector.loader(ProjectActions.getProjectByIdSuccess.type));
    const [selected, setSelected] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [statusCheck, setStatusCheck] = useState(false);
    const [statusValue, setStatusValue] = useState(false);
    const [companyLogo, setCompanyLogo] = useState()

    useEffect(() => {
        if (id) {
            dispatch(ProjectActions.getProjectById(id));
        }
    }, [id]);

    const listingrefresh = () => {
        dispatch(TransactionActions.getTransactionById(id))
    }

    useEffect(() => {
        if (projectDetails && projectDetails.data && projectDetails.data.project && projectDetails.data.project.status === 1) {
            setStatusValue(true);
        } else {
            setStatusValue(false);
        }
        if (projectDetails && projectDetails.data && projectDetails.data.company) {
            setCompanyLogo(projectDetails.data.company.logo)
        }

    }, [projectDetails]);
    useEffect(() => {
        const subMenuValue = localStorage.getItem('selectedProjectSubMenuId')
        if (subMenuValue) {
            setSelected(subMenuValue)
        } else {
            setSelected(menus[0].id)
        }
    }, [selected])

    const selectItemFromSubMenu = (id) => {
        setSelected(id)
        localStorage.setItem('selectedProjectSubMenuId', id)
    }
    const changeProjectStatus = (e) => {
        const { checked } = e.target;
        setOpenDialog(true);
        setStatusCheck(checked);
    }
    const handleToggleStatus = () => {
        setStatusValue(statusCheck)
        const params = {
            id: id,
            status: statusCheck ? 1 : 2,
            flag: "Project"
        }
        dispatch(MasterDataActions.changeStatus(params))
        setOpenDialog(false)
    };

    return (
        <Box>
            <PageTitle isBack={true} title={projectDetails?.data?.project?.name} />

            {loading ? (
                <FormSkeleton />
            ) : (
                <Grid container spacing={3}>
                    <Grid item lg={4} sm={12} xs={12}>
                        <Card>
                            <Picker>
                                <input
                                    type="file"
                                    id="profile"
                                />
                                <label htmlFor="profile">
                                    <img className="cursor-pointer"
                                        alt="profile" style={{ width: '100%' }}
                                        src={companyLogo || Avatar} />
                                </label>
                            </Picker>
                            {statusValue}
                            <Box sx={{ mt: 7 }}>
                                <SwitchBox>
                                    <Typography>{statusValue ? "Disable Project" : "Enable Project"}</Typography>
                                    <Switch
                                        checked={statusValue}
                                        name="status"
                                        onChange={changeProjectStatus}
                                    />
                                </SwitchBox>
                            </Box>

                            <Box sx={{ mt: 2 }}>
                                <CompanyMenuForm
                                    selected={selected}
                                    onSelect={(id) => selectItemFromSubMenu(id)}
                                // role={projectDetails?.data?.role && projectDetails.data.role}
                                />
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item lg={8} sm={12} xs={12}>
                        {menus.map(({ id, component: Component }, i) => {
                            if (selected === id) {
                                return <Component
                                    key={i}
                                    projectDetails={projectDetails}
                                    listingrefresh={listingrefresh}
                                />
                            }
                            return <div key={i} />
                        })}
                    </Grid>
                </Grid>
            )}
            <DialogConfirm
                title="Change Status"
                content={statusValue ? "Are you sure want disable Project?" : "Are you sure want Enable Project?"}
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                onSubmit={handleToggleStatus}
            />
        </Box>
    )
}