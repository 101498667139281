import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    Grid,
} from "@mui/material";
import PageTitle from "components/PageTitle";
import styled from "@emotion/styled";
import Avatar from "assets/avatar.svg";
import * as yup from "yup";
import { useFormik } from "formik";
import BasicInformation from './Component/Create/BasicInformation';
import AccountSetting from './Component/Create/AccountSetting';
import {CompanyActions, GeneralActions } from 'slices/actions';
import {useDispatch, useSelector} from "react-redux";
import { GeneralSelector} from 'selectors';
import {toast} from "react-toastify";
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';


const Picker = styled(Box)(() => ({
    width: 120,
    height: 120,
    margin: "40px auto 10px auto",

    "& input": {
        display: "none"
    },

    "& img": {
        width: "100%",
        borderRadius: 100
    }
}));


function AddCompany() {
    // window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth',
    // });
    const history = useHistory();
    const dispatch = useDispatch();
    const [preview, setPreview] = useState(null);
    const success = useSelector(GeneralSelector.success(CompanyActions.createCompany.type));
    const error = useSelector(GeneralSelector.error(CompanyActions.createCompany.type));
    const loading = useSelector(GeneralSelector.loader(CompanyActions.createCompany.type));
  
    const validationSchema = yup.object({
        companyName: yup.string().required('Name is required'),
        companyEmail: yup.string().email().
            required('Email is required'),
        gst: yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}Z[a-zA-Z0-9]{1}$/,"Invalid GST No").
        required('GST is required'),
        companyContactNumber: yup.string().min(10).
        max(10).
        matches(/^[0-9\b]+$/,"Invalid Phone Number").
        required('Contact Number is required'),
        // state: yup.string().required('State is required'),
        // city: yup.string().required('City is required'),
        address: yup.string().required('Address is required'),
        userEmail: yup.string().email().
        required('Email is required'),
        userName: yup.string().required('Name is required'),
        phone: yup.string().min(10).
        max(10).
        matches(/^[0-9\b]+$/,"Invalid Phone Number").
        required('Phone number is required'),
        password: yup.string().min(8, 'Password should be of minimum 8 characters length').
            required('Password is required'),
        pincode: yup.string().matches(/^[0-9]+$/, "Must be only digits").
        length(6, 'Pincode must be exactly 6 characters!').
        required('Pincode is required')
    });

    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                position: "top-right",
                autoClose: 3000,
                color: 'white',
                closeOnClick: true,
            });

            dispatch(GeneralActions.removeSuccess(CompanyActions.createCompany.type));
            
            history.goBack();
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                position: "top-right",
                autoClose: 3000,
                color: "white",
                closeOnClick: true,
            });

            dispatch(GeneralActions.removeError(CompanyActions.createCompany.type));
            
        }
    }, [error]);

    const formik = useFormik({
        initialValues: {
            companyName: "",
            companyEmail: "",
            companyContactNumber: "",
            gst: "",
            state: "",
            city: "",
            address: "",
            userEmail: "",
            password: "",
            userName: "",
            phone: "",
            logo: "",
            pincode: "",
            status: 1,
            is_company_user: true,
            user_type: 11
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });
    const handleChangeImage = (e) => {
        const file = e.target.files[0];
        const objectUrl = URL.createObjectURL(file);
        formik.setFieldValue('logo', file);
        setPreview(objectUrl);
    };
    const handleSubmit = (params) => {
       dispatch(CompanyActions.createCompany(params))
    }

    return (
        <>
            <Box>
                <PageTitle isBack={true} title='Create Company' />

                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item lg={4} sm={12} xs={12}>
                            <Card>
                                <Picker className="uploadpicker">
                                    <input
                                        type="file"
                                        id="profile"
                                        name="logo"
                                        onChange={handleChangeImage} />
                                    <label htmlFor="profile" className="cursor-pointer">
                                        <img className="uploadimgfitsize"
                                            alt="profile"
                                            src={preview || Avatar} />
                                        <span>
                                            <CameraAltOutlinedIcon sx={{fontSize: '25', mr: '2'}} />
                                            Upload Photo
                                        </span>
                                    </label>
                                </Picker>
                            
                        </Card>
                    </Grid>
                    <Grid item lg={8} sm={12} xs={12}>
                        <BasicInformation
                            formik={formik}/>
                        <AccountSetting
                            formik={formik}/> 
                        <Grid container justifyContent='flex-end'>
                            <Button
                                size="large"
                                variant="contained"
                                color="primary"
                                type="submit"
                                >
                                {loading?<CircularProgress color="inherit" size={20}/>:<>Submit</> 
                                }
                                </Button>
                        </Grid>
                    </Grid>
                    </Grid>
                </form>
            </Box>


        </>
    );
}
export default AddCompany;