import React, { useEffect, useState } from "react";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
    Box, Card,Table, TableHead, TableRow,TableCell, TableBody
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import { GeneralSelector, SubContractorSelector } from "selectors";
import ListSkeleton from "components/Skeleton/ListSkeleton";
import FormIndex from "../FormIndex";
import { GeneralActions, SubContractorActions } from "slices/actions";

const Content = styled(Card)(() => ({
    marginBottom: 20
}))

function SubcontractorList () {
    const dispatch = useDispatch();
    const subcontractorList = useSelector(SubContractorSelector.getSubContractorList());
    const loading = useSelector(GeneralSelector.loader(SubContractorActions.getSubContractorList.type));
    const [showList,setShowList] = useState(false);
    const success = useSelector(GeneralSelector.success(SubContractorActions.getSubContractorList.type));

 useEffect(() => {
    if (success) {
       dispatch(GeneralActions.removeSuccess(SubContractorActions.getSubContractorList.type));
       setShowList(true)
    }
  }, [success]);

    
  return (
    <Box>
            <FormIndex repotType="Subcontractor" title="Subcontractor Report"/>
            {loading ? (
                <ListSkeleton />
            ) : (
                <Content>
                    {showList && subcontractorList && subcontractorList.length !== 0 ?(
                    <>
                    <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn btn-primary mb-3"
                    table="table-to-xls"
                    filename={"Subcontractor Report"}
                    sheet="tablexls"
                    buttonText="Export"
                     />
                     </>):null}
                    <Table className="table" id="table-to-xls">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>PAN Number</TableCell>
                                <TableCell>Proprietor Name</TableCell>
                                <TableCell>Phone Number</TableCell>
                                <TableCell>Supervisor Name</TableCell>
                                <TableCell>Nature of Work</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(!showList || (subcontractorList && subcontractorList.length === 0)) && (
                                <TableRow>
                                    <TableCell align="center" colSpan={5}>
                                        No Data
                                    </TableCell>
                                </TableRow>
                            )}
                            {showList && subcontractorList && subcontractorList.map((item, i) => (
                                <TableRow
                                    key={i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>{item.subContractorName}</TableCell>
                                    <TableCell>{item.pan}</TableCell>
                                    <TableCell>{item.propriterName}</TableCell>
                                    <TableCell>{item.subContractorPhone}</TableCell>
                                    <TableCell>{item.supervisorName}</TableCell>
                                    <TableCell>{item.natureOfWork}</TableCell>
                                      
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                 
                </Content>
           )} 
        </Box>
  )
}

export default SubcontractorList