import React from 'react';
import FormIndex from '../FormIndex';

export default function FormB() {
    return (
        <>
            <FormIndex 
              formData={"formB"}
              title = {"Format for Wages register (Form B)"}
            />
        </>
    )
}