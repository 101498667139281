import {all, call, put, takeLatest} from 'redux-saga/effects'
import { CompanyService } from '../services';
import { CompanyActions, GeneralActions} from "../slices/actions";

function *getCompanyList({type, payload}) {
try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(CompanyService.CompanyList, payload);

        yield put(CompanyActions.getCompanySuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}
                            
function *getCompanyById({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(CompanyService.GetCompanyById, payload);
        yield put(CompanyActions.getCompanyByIdSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}
function *getCompanyVIewData({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(CompanyService.getCompanyViewById, payload);
        yield put(CompanyActions.getCompanyVIewDataSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}

function *createCompany({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(CompanyService.CreateCompany, payload);
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}

function *updateCompany({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        payload.status = payload.status ? 2 : 1;
        
        const result = yield call(CompanyService.UpdateCompany, payload.id, payload);

        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}

function *updateCompanyUser({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        payload.status = payload.status ? 2 : 1;

        const result = yield call(CompanyService.UpdateCompanyUser, payload.id, payload);

        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}

function *fetchDataByPincode({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(CompanyService.FetchDataByPincode, payload);
        yield put(CompanyActions.fetchDataByPincodeSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
        } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}
function *deleteCompany({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(CompanyService.DeleteCompany,payload);

        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}
function *uploadImage({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(CompanyService.uploadImage,payload.id,payload);

        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}


export function *CompanyWatcher() {
    yield all([
        yield takeLatest(CompanyActions.getCompanyById.type, getCompanyById),
        yield takeLatest(CompanyActions.createCompany.type, createCompany),
        yield takeLatest(CompanyActions.getCompanyList.type, getCompanyList),
        yield takeLatest(CompanyActions.fetchDataByPincode.type, fetchDataByPincode),
        yield takeLatest(CompanyActions.deleteCompany.type, deleteCompany),
        yield takeLatest(CompanyActions.updateCompany.type, updateCompany),
        yield takeLatest(CompanyActions.updateCompanyUser.type, updateCompanyUser),
        yield takeLatest(CompanyActions.getCompanyVIewData.type, getCompanyVIewData),
        yield takeLatest(CompanyActions.uploadImage.type, uploadImage),

    ]);
}