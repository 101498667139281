import {all, call, put, takeLatest} from 'redux-saga/effects';
import {StateService} from "../services";
import {StateActions, GeneralActions} from "../slices/actions";


function *getStateList1({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));        
        const result = yield call(StateService.GetStateList1, payload);
        yield put(StateActions.getStateSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}
function *getStateById({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(StateService.GetStateById, payload);
        yield put(StateActions.getSateByIdSuccess(result.data));
       
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}
function *createState({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));
        const result = yield call(StateService.CreateState, payload);
        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: "Error"
        }));
    }
}
function *updateState({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(StateService.UpdateState, payload.id, payload);

        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.message
        }));
    }
}
function *deleteState({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(StateService.DeleteState, payload);

        yield put(GeneralActions.addSuccess({
            action: type,
            message: result.data.message
        }));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}

// function *GetAllState({type}) {
//     try {
//         yield put(GeneralActions.removeError(type));
//         yield put(GeneralActions.startLoading(type));

//         const result = yield call(StateService.GetAllState);

//         yield put(GeneralActions.addSuccess({
//             action: type,
//             message: result.data.message
//         }));
//         yield put(GeneralActions.stopLoading(type))
//     } catch (err) {
//         yield put(GeneralActions.stopLoading(type));
//         yield put(GeneralActions.addError({
//             action: type,
//             message: err.response?.data?.error
//         }));
//     }
// }

// function *getCompanyList({type, payload}) {
//     try {
//             yield put(GeneralActions.removeError(type));
//             yield put(GeneralActions.startLoading(type));
    
//             const result = yield call(CompanyService.CompanyList, payload);
    
//             yield put(CompanyActions.getCompanySuccess(result.data));
//             yield put(GeneralActions.stopLoading(type))
//         } catch (err) {
//             yield put(GeneralActions.stopLoading(type));
//             yield put(GeneralActions.addError({
//                 action: type,
//                 message: err.response?.data?.error
//             }));
//         }
//     }

function *GetAllState({type, payload}) {
    try {
        yield put(GeneralActions.removeError(type));
        yield put(GeneralActions.startLoading(type));

        const result = yield call(StateService.GetAllState, payload);

        // console.log("stateresult",result.data)
        yield put(StateActions.getStateSuccess(result.data));
        yield put(GeneralActions.stopLoading(type))
    } catch (err) {
        yield put(GeneralActions.stopLoading(type));
        yield put(GeneralActions.addError({
            action: type,
            message: err.response?.data?.error
        }));
    }
}


export function *StateWatcher() {
    yield all([
        yield takeLatest(StateActions.getStateList1.type,getStateList1),
        yield takeLatest(StateActions.getStateById.type, getStateById),
        yield takeLatest(StateActions.createState.type,createState),
        yield takeLatest(StateActions.updateState.type, updateState),
        yield takeLatest(StateActions.deleteState.type, deleteState),
        yield takeLatest(StateActions.GetAllState.type, GetAllState)
    ]);
}