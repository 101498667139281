import React from "react";
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from "prop-types";
import Dialog from '@mui/material/Dialog';
import { AttendanceSelector } from "selectors";
import { useSelector } from "react-redux";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  BulkUplaodInformation.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
  };

  export default function BulkUplaodInformation(props) {
  const {open,onClose} = props
  const successBulkUpload = useSelector(AttendanceSelector.getBulkUplaodInformation());
  return (
             <BootstrapDialog
                 onClose={onClose}
                 aria-labelledby="customized-dialog-title"
                 open={open}>
              <DialogTitle>
              <IconButton
                          aria-label="close"
                          onClick={onClose}
                          sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                          }}
                         >
                           <CloseIcon />
                           </IconButton>
               </DialogTitle>   
              <DialogContent >
              <Typography><b>New Attendance Added</b> : {" "}{successBulkUpload?.newAttendanceAdded}</Typography>
              <Typography><b>Attendance Modified</b> : {" "}{successBulkUpload?.attendanceModified}</Typography>
              <Typography><b>Data Not Found</b> : {" "}{successBulkUpload?.dataNotFound}</Typography>
              <Typography><b>Invalid Data</b> : {" "}{successBulkUpload?.invalidData}</Typography>
              </DialogContent>
              </BootstrapDialog>
     )
 
 }
