import React ,{useEffect} from "react";
import {
    Box,
    Button,
    FormControl, MenuItem,
    Grid,
    Typography,
    useTheme,
    CircularProgress
} from "@mui/material";
import Input from "components/Input";
import SelectField from "components/SelectField";
import PropTypes from "prop-types";
import * as yup from "yup";
import {useFormik} from "formik";
import { GeneralActions, LabourActions } from "slices/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GeneralSelector } from "selectors";
import {toast} from "react-toastify";

PFBasicInfo.propTypes = {
    labourDetails: PropTypes.object
};

export default function PFBasicInfo(props) {
    const { labourDetails } = props;
    const {id} = useParams();
    const theme = useTheme();
    const dispatch = useDispatch();
    const success = useSelector(GeneralSelector.success(LabourActions.updateLabourPFBasicInfo.type));
    const loading = useSelector(GeneralSelector.loader(LabourActions.updateLabourPFBasicInfo.type));
    const error = useSelector(GeneralSelector.error(LabourActions.updateLabourPFBasicInfo.type));
    const validationSchema = yup.object({
        pfBasicInfo_UAN_Number: yup.string().required('This field is required'),
        // pfBasicInfo_Previous_member_ID: yup.string().required('This field is required'),
        pfBasicInfo_Member_Name: yup.string().required('This field is required'),
        pfBasicInfo_Father_Husband_Name: yup.string().required('This field is required'),
        pfBasicInfo_Relationship_with_Member: yup.string().required('This field is required'),
        pfBasicInfo_Phone_Number: yup.string().required('This field is required'),
        // pfBasicInfo_Email: yup.string().required('This field is required'),
        pfBasicInfo_Nationality: yup.string().required('This field is required'),
        pfBasicInfo_Monthly_EPF_Wages_as_on_Joining: yup.string().required('This field is required'),
        // pfBasicInfo_Qualification: yup.string().required('This field is required'),
        pfBasicInfo_Is_International_Worker: yup.string().required('This field is required'),
        pfBasicInfo_Country_Of_Origin: yup.string().required('This field is required'),
        maritalStatus: yup.string().required('This field is required'),
        doj: yup.string().required('This field is required'),
        gender: yup.string().required('This field is required'),
        dateOfBirth: yup.string().required('This field is required'),
    });
    const formik = useFormik({
        initialValues: {
            flag: 'PFBasicInfo',
            pfBasicInfo_UAN_Number: labourDetails?.result?.pfBasicInfo_UAN_Number??"",
            pfBasicInfo_Previous_member_ID: labourDetails?.result?.pfBasicInfo_Previous_member_ID??"",
            pfBasicInfo_Member_Name: `${labourDetails?.result?.firstName} ${""} ${labourDetails?.result?.lastName}`,
            pfBasicInfo_Father_Husband_Name: labourDetails?.result?.gurdianName??"",
            pfBasicInfo_Relationship_with_Member: labourDetails?.result?.pfBasicInfo_Relationship_with_Member?labourDetails.result.pfBasicInfo_Relationship_with_Member:"Father",
            pfBasicInfo_Phone_Number: labourDetails?.result?.mobileNumber??"",
            pfBasicInfo_Email: labourDetails?.result?.pfBasicInfo_Email??"",
            pfBasicInfo_Nationality: "INDIAN",
            pfBasicInfo_Monthly_EPF_Wages_as_on_Joining: labourDetails?.result?.pfBasicInfo_Monthly_EPF_Wages_as_on_Joining?labourDetails.result.pfBasicInfo_Monthly_EPF_Wages_as_on_Joining:15000,
            pfBasicInfo_Qualification: "Graduate",
            pfBasicInfo_Is_International_Worker: labourDetails?.result?.pfBasicInfo_Is_International_Worker??"N",
            pfBasicInfo_Country_Of_Origin: "INDIA",
            maritalStatus: labourDetails?.result?.maritalStatus??"",
            doj: labourDetails?.result?.doj??"",
            gender: labourDetails?.result?.gender??"",
            dateOfBirth: labourDetails?.result?.dateOfBirth??"",
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
           handleSubmit(values);
        }
    });
    const handleSubmit = (values) => {
        const params = {
            id: id,
          ...values,
      };
      dispatch(LabourActions.updateLabourPFBasicInfo(params));
   }


   useEffect(() => {
    if (success) {
        toast.success(`${success?.message ?? "Success"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });

        dispatch(GeneralActions.removeSuccess(LabourActions.updateLabourPFBasicInfo.type));
    }
  }, [success]);

useEffect(() => {
    if (error) {
        toast.error(`${error?.message ?? "Error"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });

        dispatch(GeneralActions.removeError(LabourActions.updateLabourPFBasicInfo.type));
    }
}, [error]);

    return (
        <Box>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="UAN-Number *"
                            name='pfBasicInfo_UAN_Number'
                            value={formik.values.pfBasicInfo_UAN_Number}
                            onChange={formik.handleChange}
                            error={formik.touched.pfBasicInfo_UAN_Number && Boolean(formik.errors.pfBasicInfo_UAN_Number)}
                            helperText={formik.touched.pfBasicInfo_UAN_Number && formik.errors.pfBasicInfo_UAN_Number}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Previous member ID"
                            name='pfBasicInfo_Previous_member_ID'
                            value={formik.values.pfBasicInfo_Previous_member_ID}
                            onChange={formik.handleChange}
                            error={formik.touched.pfBasicInfo_Previous_member_ID && Boolean(formik.errors.pfBasicInfo_Previous_member_ID)}
                            helperText={formik.touched.pfBasicInfo_Previous_member_ID && formik.errors.pfBasicInfo_Previous_member_ID}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Member Name *"
                            name='pfBasicInfo_Member_Name'
                            maxLength = "85"
                            value={formik.values.pfBasicInfo_Member_Name}
                            // onChange={formik.handleChange}
                            error={formik.touched.pfBasicInfo_Member_Name && Boolean(formik.errors.pfBasicInfo_Member_Name)}
                            helperText={formik.touched.pfBasicInfo_Member_Name && formik.errors.pfBasicInfo_Member_Name}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Date of Birth *"
                            name='dateOfBirth'
                            type='date'
                            value={formik.values.dateOfBirth}
                            // onChange={formik.handleChange}
                            error={formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)}
                            helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Date of Joining *"
                            name='doj'
                            type='date'
                            value={formik.values.doj.slice(0,10)}
                            // onChange={formik.handleChange}
                            error={formik.touched.doj && Boolean(formik.errors.doj)}
                            helperText={formik.touched.doj && formik.errors.doj}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Typography sx={{fontSize: 15}}>Gender *</Typography>
                        <SelectField
                            className="text-left"
                            name="gender"
                            value={formik.values.gender}
                            // onChange={formik.handleChange}
                            error={formik.touched.gender && Boolean(formik.errors.gender)}
                            helperText={formik.touched.gender && formik.errors.gender}
                            >
                            <MenuItem value="Male">
                                Male
                            </MenuItem>
                            <MenuItem value="Female">
                                Female
                            </MenuItem>
                        </SelectField>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Father/Husband Name *"
                            name='pfBasicInfo_Father_Husband_Name'
                            value={formik.values.pfBasicInfo_Father_Husband_Name}
                            // onChange={formik.handleChange}
                            error={formik.touched.pfBasicInfo_Father_Husband_Name && Boolean(formik.errors.pfBasicInfo_Father_Husband_Name)}
                            helperText={formik.touched.pfBasicInfo_Father_Husband_Name && formik.errors.pfBasicInfo_Father_Husband_Name}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Typography sx={{fontSize: 15}}>Relationship with the Member *</Typography>
                         <SelectField
                            name="pfBasicInfo_Relationship_with_Member"
                            value={formik.values.pfBasicInfo_Relationship_with_Member}
                            onChange={formik.handleChange}
                            error={formik.touched.pfBasicInfo_Relationship_with_Member && Boolean(formik.errors.pfBasicInfo_Relationship_with_Member)}
                            helperText={formik.touched.pfBasicInfo_Relationship_with_Member && formik.errors.pfBasicInfo_Relationship_with_Member}
                            >
                            <MenuItem value="Father">
                                Father
                            </MenuItem>
                            <MenuItem value="Husband">
                                Husband
                            </MenuItem>
                        </SelectField>

                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                            <Typography variant='caption' sx={{fontSize: "15px"}}>Phone Number *</Typography>
                            <Box sx={{
                                display: 'flex',
                                gap: 1.5
                            }}>
                                <Box sx={{ width: 80 }}>
                                    <Input
                                        sx={{
                                            padding: "10px 8px",
                                            textAlign: 'center',
                                            '& .Mui-disabled': {
                                                fillColor: theme.palette.common.black
                                            }
                                        }}
                                        autoComplete='new-password'
                                        name='phoneCode'
                                        startAdornment='+'
                                        value="91"
                                        />
                                </Box>
                                <Input
                                    name='pfBasicInfo_Phone_Number'
                                    value={formik.values.pfBasicInfo_Phone_Number}
                                    // onChange={formik.handleChange}
                                    error={formik.touched.pfBasicInfo_Phone_Number && Boolean(formik.errors.pfBasicInfo_Phone_Number)}
                                    helperText={formik.touched.pfBasicInfo_Phone_Number && formik.errors.pfBasicInfo_Phone_Number}/>
                            </Box>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Email"
                            name='pfBasicInfo_Email'
                            value={formik.values.pfBasicInfo_Email}
                            onChange={formik.handleChange}
                            error={formik.touched.pfBasicInfo_Email && Boolean(formik.errors.pfBasicInfo_Email)}
                            helperText={formik.touched.pfBasicInfo_Email && formik.errors.pfBasicInfo_Email}
                        />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Nationality *"
                            name='pfBasicInfo_Nationality'
                            value={formik.values.pfBasicInfo_Nationality}
                            // onChange={formik.handleChange}
                            error={formik.touched.pfBasicInfo_Nationality && Boolean(formik.errors.pfBasicInfo_Nationality)}
                            helperText={formik.touched.pfBasicInfo_Nationality && formik.errors.pfBasicInfo_Nationality}
                        />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Monthly EPF Wages as on Joining *"
                            type="number"
                            name='pfBasicInfo_Monthly_EPF_Wages_as_on_Joining'
                            value={formik.values.pfBasicInfo_Monthly_EPF_Wages_as_on_Joining}
                            onChange={formik.handleChange}
                            error={formik.touched.pfBasicInfo_Monthly_EPF_Wages_as_on_Joining && Boolean(formik.errors.pfBasicInfo_Monthly_EPF_Wages_as_on_Joining)}
                            helperText={formik.touched.pfBasicInfo_Monthly_EPF_Wages_as_on_Joining && formik.errors.pfBasicInfo_Monthly_EPF_Wages_as_on_Joining}
                        />
                    </Grid>
                     <Grid item lg={6} xs={12}>
                        <Typography sx={{fontSize: 15}}>Marital Status *</Typography>
                        <SelectField
                            className="text-left"
                            name="maritalStatus"
                            value={formik.values.maritalStatus}
                            // onChange={formik.handleChange}
                            error={formik.touched.maritalStatus && Boolean(formik.errors.maritalStatus)}
                            helperText={formik.touched.maritalStatus && formik.errors.maritalStatus}
                            >
                            <MenuItem value="Married">Married</MenuItem>
                            <MenuItem value="Un-Married">Un-Married</MenuItem>
                            <MenuItem value="Widow/Widower">Widow/Widower</MenuItem>
                            <MenuItem value="Divorced">Divorcee</MenuItem>
                        </SelectField>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Typography sx={{fontSize: 15}}>Is International Worker *</Typography>
                        <SelectField
                            className="text-left"
                            name="pfBasicInfo_Is_International_Worker"
                            value={formik.values.pfBasicInfo_Is_International_Worker}
                            onChange={formik.handleChange}
                            error={formik.touched.pfBasicInfo_Is_International_Worker && Boolean(formik.errors.pfBasicInfo_Is_International_Worker)}
                            helperText={formik.touched.pfBasicInfo_Is_International_Worker && formik.errors.pfBasicInfo_Is_International_Worker}
                            >
                            <MenuItem value="Y">
                                Y
                            </MenuItem>
                            <MenuItem value="N">
                                N
                            </MenuItem>
                        </SelectField>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Country Of Origin *"
                            name='pfBasicInfo_Country_Of_Origin'
                            value={formik.values.pfBasicInfo_Country_Of_Origin}
                            error={formik.touched.pfBasicInfo_Country_Of_Origin && Boolean(formik.errors.pfBasicInfo_Country_Of_Origin)}
                            helperText={formik.touched.pfBasicInfo_Country_Of_Origin && formik.errors.pfBasicInfo_Country_Of_Origin}/>
                    </Grid>
                     {labourDetails?.result?.status !== 99?(
                        <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained">
                              {loading?(
                                   <CircularProgress color="inherit" size={20}/>
                                ):(
                                  <>Update</>  
                                )}
                        </Button>
                    </Grid>):null}
                </Grid>
            </form>
        </Box>
    )
}