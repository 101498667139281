import {createSelector} from "@reduxjs/toolkit";

const siteSelector = (state) => state.site;

const getSiteById = () => createSelector(
  siteSelector,
  site => site.siteListByProject
);
const getSiteDetailsById = () => createSelector(
  siteSelector,
  site => site.siteDetailsById
)
const getAllSiteList = () => createSelector(
  siteSelector,
  site => site.allSiteList
)
const role = () => createSelector(
  siteSelector,
  site => site.role
)

export const SiteSelector = {
    getSiteById,
    getSiteDetailsById,
    getAllSiteList,
    role
  }