import React ,{useEffect} from "react";
import {
    Card,
    Grid,
    Typography, Button
} from "@mui/material";
import Input from "components/Input";
import { useFormik } from "formik";
import { GeneralActions, ProjectActions } from "slices/actions";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { GeneralSelector } from "selectors";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import { useParams } from "react-router-dom";
import * as yup from "yup";


GpaWc.propTypes = {
    projectDetails: PropTypes.object,
};

export default function GpaWc(props) {
    const { projectDetails } = props;
    const dispatch = useDispatch();
    const {id} = useParams();
    const success = useSelector(GeneralSelector.success(ProjectActions.updateProject.type));
    const error = useSelector(GeneralSelector.error(ProjectActions.updateProject.type));
    const loading = useSelector(GeneralSelector.loader(ProjectActions.updateProject.type));

    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(ProjectActions.updateProject.type));
            dispatch(ProjectActions.getProjectById(id));
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeError(ProjectActions.updateProject.type));
        }
    }, [error]);

    const validationSchema = yup.object({
        scopeOfGPA_WCPolicy: yup.string().required('This field is required'),
        gpa_WcNo: yup.string().required('This field is required'),
        gpa_WcValidityFrom: yup.string().required('This field is required'),
        gpa_WcValidityTo: yup.string().required('This field is required'),
    });  

     const formik = useFormik({
        initialValues: {
            scopeOfGPA_WCPolicy: projectDetails.data?.project?.scopeOfGPA_WCPolicy ?? "",
            gpa_WcNo: projectDetails.data?.project?.gpa_WcNo ?? "",
            gpa_WcValidityFrom: projectDetails.data?.project?.gpa_WcValidityFrom ?? "",
            gpa_WcValidityTo: projectDetails.data?.project?.gpa_WcValidityTo ?? "",
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
          handleSubmit(values);
        },
    });
    const handleSubmit = (values) => {
        const params ={
            id: id,
            flag: "gpawc",
            ...values
        }
        dispatch(ProjectActions.updateProject(params))
    };
   return (
        <Card sx={{ mb: 3 }}>
            <Typography variant='h5' sx={{ mb: 4 }}>GPA/WC</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Scope of GPA/WC Policy"
                            name='scopeOfGPA_WCPolicy'
                            value={formik.values.scopeOfGPA_WCPolicy}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.scopeOfGPA_WCPolicy) && Boolean(formik.errors.scopeOfGPA_WCPolicy)}
                            helperText={formik.touched.scopeOfGPA_WCPolicy && formik.errors.scopeOfGPA_WCPolicy}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="GPA/WC No"
                            name='gpa_WcNo'
                            value={formik.values.gpa_WcNo}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.gpa_WcNo) && Boolean(formik.errors.gpa_WcNo)}
                            helperText={formik.touched.gpa_WcNo && formik.errors.gpa_WcNo}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label='GPA/WC Validity From'
                            name='gpa_WcValidityFrom'
                            type='date'
                            value={formik.values.gpa_WcValidityFrom}
                            onChange={formik.handleChange}
                            error={formik.touched.gpa_WcValidityFrom && Boolean(formik.errors.gpa_WcValidityFrom)}
                            helperText={formik.touched.gpa_WcValidityFrom && formik.errors.gpa_WcValidityFrom}
                        />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label='GPA/WC Validity To'
                            name='gpa_WcValidityTo'
                            type='date'
                            inputProps={{
                                min: formik.values.gpa_WcValidityFrom 
                              }}
                            value={formik.values.gpa_WcValidityTo}
                            onChange={formik.handleChange}
                            error={formik.touched.gpa_WcValidityTo && Boolean(formik.errors.gpa_WcValidityTo)}
                            helperText={formik.touched.gpa_WcValidityTo && formik.errors.gpa_WcValidityTo}
                        />
                    </Grid>
                    <Grid container justifyContent="flex-end" sx={{mt: 2}}>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {loading ? (
                            <CircularProgress color="inherit" size={20} />
                            ) : (
                            <>Submit</>
                            )}
                        </Button>
                    </Grid>
                    
                </Grid>
            </form>
        </Card>
    )
}