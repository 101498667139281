import React,{useEffect} from "react";
import {
    Button,
    // Card,
    CircularProgress,
    Grid,
    Typography,
    Link
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import { GeneralActions, LabourActions} from "slices/actions";
import {useFormik} from "formik";
import Input from "components/Input";
import { GeneralSelector, LabourSelector } from "selectors";
import * as yup from "yup";
import { useParams } from "react-router-dom";

export default function Passport() {
    const {id} = useParams();
    const labourDetails = useSelector(LabourSelector.getLabourById());
    const success = useSelector(GeneralSelector.success(LabourActions.updateLabourPassport.type));
    const error = useSelector(GeneralSelector.error(LabourActions.updateLabourPassport.type))
    const loading = useSelector(GeneralSelector.loader(LabourActions.updateLabourPassport.type))
    const dispatch = useDispatch();
    const validationSchema = yup.object({
        passport: yup.string().required('This field is required'),
        passsport_Document: yup.string().required('This field is required'),
    });
    const formik = useFormik({
        initialValues: {
            passport: labourDetails?.result?.passport??"",
            passsport_Document: labourDetails?.result?.passsport_Document??"",
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(LabourActions.updateLabourPassport.type));
            dispatch(LabourActions.getLabourById(id));

        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeError(LabourActions.updateLabourPassport.type));
        }
    }, [error]);

    const handleSubmit = (values) => {
        const params = {
            id: id,
            ...values
        }
        dispatch(LabourActions.updateLabourPassport(params));
    }
    const handleChangeImage = (e) => {
        const file = e.target.files[0];
        formik.setFieldValue('passsport_Document',file)
    };

    return (
        // <Card>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={12} xs={12}>
                        <Typography variant="h6" sx={{textAlign: "left", borderBottom: "1px solid #ddd", m: "0", fontWeight: "normal"}}>Passport</Typography>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Passport *"
                            name='passport'
                            value={formik.values.passport}
                            onChange={formik.handleChange}
                            error={formik.touched.passport && Boolean(formik.errors.passport)}
                            helperText={formik.touched.passport && formik.errors.passport}
                            />
                    </Grid>
                    <Grid item lg={6} xs={12} className="kycpicker text-center uploadinputphoto">
                        <Typography sx={{fontSize: '13', color: '#fff'}}>Upload Passport</Typography>
                        <Input
                            type="file"
                            id="profile"
                            onChange={handleChangeImage}
                            error={formik.touched.passsport_Document && Boolean(formik.errors.passsport_Document)}
                            helperText={formik.touched.passsport_Document && formik.errors.passsport_Document}
                        />
                        {labourDetails?.result?.passsport_Document?(
                                <Link href={labourDetails?.result?.passsport_Document} target='_blank' >Passport Uploaded</Link>
                            ):(
                                null)
                         }
                     </Grid>
                     {labourDetails?.result?.status !== 99 ?(
                      <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained">
                               {loading?(
                                   <CircularProgress color="inherit" size={20}/>
                                ):(
                                  <>Update</>  
                                )}
                            </Button>

                        </Grid>):null}
                        
                </Grid>
            </form>
        // </Card>
    )
}