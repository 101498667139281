import {get,patch} from "../utils/api";

const API_URL = process.env.REACT_APP_API_URL 

const GetMasterData = async () => get(`${API_URL}/project/masterdata`);

const GetNatureOfWork = async () => get(`${API_URL}/user/masterdataScopeOfWork`);

const FetchDataByPincode = async (id) => get(`${API_URL}/location/${id}`);

const GetMotherTounge = async () => get(`${API_URL}/user/masterdataLanguage`);

const GeSubContractorData = async (projectid) => get(`${API_URL}/labour/getLabourMasterData/${projectid}`);

const ChangeStatus = async (id,params) => patch(`${API_URL}/project/statusUpdate/${id}`,params);

const FetchDataByIFSC = async (id) => get(`${API_URL}/bulkUpload/getBankDetails?ifscCode=${id}`);

const GetMonthList = async () => get(`${API_URL}/gstCompliance/month`);

const GetYearList = async () => get(`${API_URL}/gstCompliance/year`);

const GetEmploymentType = async () => get(`${API_URL}/labour/employeeExitReason`);

export const MasterDataService = {
    GetMasterData,
    GetNatureOfWork,
    FetchDataByPincode,
    GetMotherTounge,
    GeSubContractorData,
    ChangeStatus,
    FetchDataByIFSC,
    GetMonthList,
    GetYearList,
    GetEmploymentType
};