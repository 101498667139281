import { createSlice } from "@reduxjs/toolkit";

export const CitySlice = createSlice({
    name: "city",
    initialState: {
    cityList: [],
    pagination: {},
    cityData: {},
    },
    reducers: {
        
        getCityList: () => {},

        getCitySuccess: (state, action) => {
             state.cityData = {};
             state.cityList = action.payload.data;
            state.pagination = action.payload.pagination;
        },
        getCityById: () => {},
        getCityByIdSuccess: (state, action) => {
            state.cityData = action.payload
        },
      
        createCity: () => {},
        updateCity: () => {},
        
        deleteCity: () => {},
    }
});

export default CitySlice;