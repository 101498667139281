import React ,{useEffect,useState} from "react";
import {
    Button,
    Card,
    CircularProgress,
    Grid,
    Typography,
} from "@mui/material";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {GeneralSelector, MasterDataSelector} from "selectors";
import { GeneralActions, LabourActions, MasterDataActions} from "slices/actions";
import {useFormik} from "formik";
import Input from "components/Input";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

BankAccount.propTypes = {
    labourDetails: PropTypes.object,
};

export default function BankAccount(props) {
    const { labourDetails } = props;
    const { id } = useParams();
    const dispatch = useDispatch();
    const success = useSelector(GeneralSelector.success(LabourActions.updateLabourBankDetails.type));
    const error = useSelector(GeneralSelector.error(LabourActions.updateLabourBankDetails.type));
    const loading = useSelector(GeneralSelector.loader(LabourActions.updateLabourBankDetails.type));
    const ifscCodeData = useSelector(MasterDataSelector.getIfscData());
    const [ifsccode,setIfscCode]=useState();

   const handleChangeIfsc = (e) => {
        const code = e.target.value
        formik.setFieldValue('bankIfsc',code)
        setIfscCode(code);
    }
    useEffect(() => {
        if (ifsccode && ifsccode.length === 11) {
            dispatch(MasterDataActions.getIfscData(ifsccode));
        }
    }, [ifsccode]);

    useEffect(() => {
        if (ifscCodeData && ifsccode) {
        formik.setFieldValue('bankAddress', ifscCodeData?.ADDRESS ??"");
        formik.setFieldValue('bankCity', ifscCodeData.CITY?ifscCodeData.CITY :'');
        formik.setFieldValue('bankBranchName', ifscCodeData.BRANCH?ifscCodeData.BRANCH :'');
        formik.setFieldValue('bankState', ifscCodeData.STATE?ifscCodeData.STATE :'');
        formik.setFieldValue('bankName', ifscCodeData.BANK?ifscCodeData.BANK :'');
     }
    }, [ifscCodeData]);

    // window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth',
    // });
    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeSuccess(LabourActions.updateLabourBankDetails.type));
            dispatch(LabourActions.getLabourById(id));

        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

            dispatch(GeneralActions.removeError(LabourActions.updateLabourBankDetails.type));
        }
    }, [error]);

    const validationSchema = yup.object({
        bankAccountNumber: yup.string().required('This field is required'),
        bankIfsc: yup.string().required('This field is required'),
        bankName: yup.string().required('This field is required'),
    });

    const formik = useFormik({
      initialValues: {
        bankAccountNumber: labourDetails?.result?.bankAccountNumber??"",
        bankIfsc: labourDetails?.result?.bankIfsc??"",
        bankName: labourDetails?.result?.bankName??"",
        bankAddress: labourDetails?.result?.bankAddress??"",
        bankBranchName: labourDetails?.result?.bankBranchName??"",
        bankState: labourDetails?.result?.bankState??"",
        bankCity: labourDetails?.result?.bankCity??"",
      },
      enableReinitialize: true,
      validateOnChange: true,
      validationSchema: validationSchema,
      onSubmit: (values) => {
        handleSubmit(values);
      },
    });
   const handleSubmit = (values) => {
        const params = {
              id: labourDetails.result._id,
            ...values,
        };
        dispatch(LabourActions.updateLabourBankDetails(params));
    }

    return (
        <Card>
            <Typography variant='h5' sx={{ mb: 4, fontWeight: '500' }}>Bank Account</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Bank Name *"
                            name='bankName'
                            value={formik.values.bankName}
                            onChange={formik.handleChange}
                            error={formik.touched.bankName && Boolean(formik.errors.bankName)}
                            helperText={formik.touched.bankName && formik.errors.bankName}/>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            label="Account Number *"
                            name='bankAccountNumber'
                            value={formik.values.bankAccountNumber}
                            onChange={formik.handleChange}
                            error={formik.touched.bankAccountNumber && Boolean(formik.errors.bankAccountNumber)}
                            helperText={formik.touched.bankAccountNumber && formik.errors.bankAccountNumber}
                           />
                    </Grid>
                   
                  <Grid item lg={6} xs={12}>
                        <Input
                            label="IFSC Code *"
                            name='bankIfsc'
                            value={formik.values.bankIfsc}
                            onChange={handleChangeIfsc}
                            error={formik.touched.bankIfsc && Boolean(formik.errors.bankIfsc)}
                            helperText={formik.touched.bankIfsc && formik.errors.bankIfsc}
                            />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                    <Input
                            label="Branch Name"
                            name='bankBranchName'
                            value={formik.values.bankBranchName}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.bankBranchName) && Boolean(formik.errors.bankBranchName)}
                            helperText={formik.touched.bankBranchName && formik.errors.bankBranchName}/>
                    </Grid>
                   
                    
                    <Grid item lg={6} xs={12}>
                    <Input
                            label="City Name"
                            name='bankCity'
                            value={formik.values.bankCity}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.bankCity) && Boolean(formik.errors.bankCity)}
                            helperText={formik.touched.bankCity && formik.errors.bankCity}/>
                    </Grid>
                 
                    <Grid item lg={6} xs={12}>
                    <Input
                            label="State Name"
                            name='bankState'
                            value={formik.values.bankState}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.bankState) && Boolean(formik.errors.bankState)}
                            helperText={formik.touched.bankState && formik.errors.bankState}/>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                    <Input
                            label="Address"
                            name='bankAddress'
                            value={formik.values.bankAddress}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.bankAddress) && Boolean(formik.errors.bankAddress)}
                            helperText={formik.touched.bankAddress && formik.errors.bankAddress}/>
                    </Grid>
                    {labourDetails?.result?.status !== 99 ?(
                      <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained">
                                {loading?(
                                   <CircularProgress color="inherit" size={20}/>
                                ):(
                                  <>Update</>  
                                )}
                            </Button>
                        </Grid>):null}
                </Grid>
            </form>
        </Card>
    )
}