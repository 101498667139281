import React, { useEffect, useState } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
    Card, 
    Table, TableHead, TableRow, 
    TableCell,
    TableBody,
    Typography
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { ReportSelector } from 'selectors/ReportSelector';
import { format } from 'date-fns';
import PageTitle from "components/PageTitle";
import { ReportActions } from 'slices/actions';
import { GeneralSelector } from 'selectors';
import ListSkeleton from 'components/Skeleton/ListSkeleton';

export default function FormDResult() {
    const dispatch = useDispatch();
    const reportData = useSelector(ReportSelector.getFormDData());
    const formData = useSelector(ReportSelector.getFormData());
    const loading = useSelector(GeneralSelector.loader(ReportActions.getReportData.type))
    const [formDate,setFormDate] = useState({
        date: "",
    });
    useEffect(() => {
        if (formData) {
           dispatch(ReportActions.getReportData(formData)); 
        }
    },[formData])
   

    useEffect(() => {
        const tempDate = new Date();
        let month = tempDate.getMonth() + 1;
        if (month < 10) {
            month = `0${month}`   
        }
        const year = tempDate.getFullYear()  
        const todayDate =`${year}-${month}`
        formDate.date = `"Form D" ${todayDate}`
        setFormDate({...formDate})
    }, []);
    return (
        <>
        <PageTitle isBack={true} title="Form D" />
        {loading?(
            <ListSkeleton/>
        ):(
         <Card>
            <div className="" style={{maxWidth: '74%', width: '100%', overflowX: 'auto'}}>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn btn-primary mb-3"
                    table="table-to-xls"
                    filename={formDate.date}
                    sheet="tablexls"
                    buttonText="Export"
                />
                <Table className="table Tableform" id="table-to-xls">
                    <TableHead className="thead-dark">
                        <TableRow className="Row_border_none">
                            <TableCell colSpan='40' align='center'>FORM D <br/>
                                FORMAT OF ATTENDANCE REGISTER
                            </TableCell>
                        </TableRow>
                        <TableRow className="Row_border_none">
                            <TableCell colSpan='20' sx={{fontSize: 12}}>
                                Name of the Establishment: {reportData.companyname}< br/>
                            </TableCell>
                            <TableCell colSpan='20' align="right" sx={{fontSize: 12}}>
                                LIN: ........< br/>
                            </TableCell>
                        </TableRow>
                        <TableRow className="Row_border_none">
                            <TableCell colSpan='20' sx={{fontSize: 12}}>
                                For the Period from {''}  
                                {reportData.startDate && format(new Date(reportData.startDate), 'dd/MM/yyyy')} to {''} 
                                {reportData.endDate && format(new Date(reportData.endDate), 'dd/MM/yyyy')}
                            </TableCell>
                            <TableCell colSpan='20' align="right" sx={{fontSize: 12}}>
                                Nature and location of work: {reportData.natureOfWork}
                            </TableCell>
                        </TableRow>
                        <TableRow className="Row_border_none">
                            <TableCell colSpan='20' sx={{fontSize: 11}}>
                                Name and address of establishment in/under which contract is carried on:- {reportData.projectname}
                            </TableCell>
                            <TableCell colSpan='20' align="right" sx={{fontSize: 11}}>
                                {/* Name and address of principal employer  */}
                            </TableCell>
                        </TableRow>
                        <TableRow className="tableform">
                            <TableCell rowSpan='2' className='rotatetd'>Sl. No in Employee Register</TableCell>
                            <TableCell rowSpan='2' width="20%">Name</TableCell>
                            <TableCell rowSpan='2' className='rotatetd'>Relay/Set Work</TableCell>
                            <TableCell rowSpan='2' className='rotatetd'>Place of work</TableCell>
                            <TableCell colSpan='2'>Time</TableCell>
                            <TableCell colSpan='31'>Date</TableCell>
                            <TableCell rowSpan='2' className='rotatetd'>Summary No. of days</TableCell>
                            <TableCell rowSpan='2' className='rotatetd'>Remarks No. of Hours</TableCell>
                            <TableCell rowSpan='2' className='rotatetd'>Signature Register Keeper</TableCell>
                        </TableRow>
                        <TableRow className="tableform">
                            <TableCell>In</TableCell>
                            <TableCell>Out</TableCell>
                            {/* <TableCell>In</TableCell>
                            <TableCell>Out</TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>2</TableCell>
                            <TableCell>3</TableCell>
                            <TableCell>4</TableCell>
                            <TableCell>5</TableCell>
                            <TableCell>6</TableCell>
                            <TableCell>7</TableCell>
                            <TableCell>8</TableCell>
                            <TableCell>9</TableCell>
                            <TableCell>10</TableCell>
                            <TableCell>11</TableCell>
                            <TableCell>12</TableCell>
                            <TableCell>13</TableCell>
                            <TableCell>14</TableCell>
                            <TableCell>15</TableCell>
                            <TableCell>16</TableCell>
                            <TableCell>17</TableCell>
                            <TableCell>18</TableCell>
                            <TableCell>19</TableCell>
                            <TableCell>20</TableCell>
                            <TableCell>21</TableCell>
                            <TableCell>22</TableCell>
                            <TableCell>23</TableCell>
                            <TableCell>24</TableCell>
                            <TableCell>25</TableCell>
                            <TableCell>26</TableCell>
                            <TableCell>27</TableCell>
                            <TableCell>28</TableCell>
                            <TableCell>29</TableCell>
                            <TableCell>30</TableCell>
                            <TableCell>31</TableCell> */}
                {reportData?.days && Array.from(Array(reportData?.days), (e, index) => (
                 <>
                 <TableCell key={index}>
                  <Typography>{index+1}</Typography>
                 </TableCell>
                 </>
               ))}
            </TableRow>
                        
                    </TableHead>
                    <TableBody>
                        {reportData && reportData.data && reportData.data.map((res,index) => <TableRow 
                        key={index} className="tableform">
                            <TableCell align='center'>{index+1}</TableCell>
                            <TableCell width="20%">{res.firstName} {res.lastName}</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                     {res && res.attendance && Object.keys(res.attendance).map((item2,index2) => (
                     <TableCell key={index2}>{res?.attendance[item2]}</TableCell>))}
                      {reportData?.days === 30 && <TableCell></TableCell>}
                      {reportData?.days === 28 && (
                      <>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      </>
                      )}
                      <TableCell>{res.present}</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                       </TableRow>)}
                    </TableBody>   
                </Table>
            </div>
         </Card> 
            )}
        </>
    )
}