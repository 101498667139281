import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CircularProgress,
    Grid,
} from "@mui/material";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { GeneralActions, LabourActions, ProjectActions, SubContractorActions } from "slices/actions";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import LabourAddress from "../../BasicInfoCreateAndDetailForm/LabourAddress";
import LabourBasicInformation from "../../BasicInfoCreateAndDetailForm/LabourBasicInformation";
import { GeneralSelector } from "selectors";
import DialogConfirm from "components/DialogConfirm";
import { sub } from "date-fns/fp"
import { useHistory, useParams } from "react-router-dom";


BasicInfo.propTypes = {
    labourDetails: PropTypes.object,
};

export default function BasicInfo(props) {
    const history = useHistory();
    const { id } = useParams()
    const { labourDetails } = props;
    const dispatch = useDispatch();
    const success = useSelector(GeneralSelector.success(LabourActions.updateLabourBasicInfo.type));
    const loading = useSelector(GeneralSelector.loader(LabourActions.updateLabourBasicInfo.type));
    const error = useSelector(GeneralSelector.error(LabourActions.updateLabourBasicInfo.type));
    const [confirmDialog, setConfirmDialog] = useState(false);

    useEffect(() => {
        if (labourDetails.result.companyId) {
            const params = {
                company_id: labourDetails.result.companyId ? labourDetails.result.companyId : ""
            }
            dispatch(ProjectActions.getProjectList(params))
        }
        if (labourDetails.result.projectId) {
            const params = {
                company_id: labourDetails.result.companyId ? labourDetails.result.companyId : "",
                project_id: labourDetails.result.projectId ? labourDetails.result.projectId : ""
            }
            dispatch(SubContractorActions.getSubContractorList(params))
        }
    }, [labourDetails])

    const validationSchema = yup.object({
        firstName: yup.string().required("This field is required"),
        lastName: yup.string().required("This field is required"),
        labourCodeNo: yup.string().required("This field is required"),
        aadharNo: yup.string().min(12).
            max(12).
            required("This field is required"),
        mobileNumber: yup.string().min(10).
            max(10).
            matches(/^[0-9\b]+$/, "Invalid Phone Number").
            required('This field is required'),
        gender: yup.string().required("This field is required"),
        dateOfBirth: yup.date().required("This field is required").
            max(sub({ years: 18 }, new Date()), "Labour must be over 18 years old"),
        maritalStatus: yup.string().required("This field is required"),
        motherTongue: yup.string().required("This field is required"),
        gurdianName: yup.string().required("This field is required"),
        permanentHomeAddress: yup.string().required("This field is required"),
        permanentAddressState: yup.string().required("This field is required"),
        permanentAddressCity: yup.string().required("This field is required"),
        permanentAddressPincode: yup.string().matches(/^[0-9]+$/, "Must be only digits").
            length(6, 'Pincode must be exactly 6 characters!').
            required('Pincode is required'),
        currentAddress: yup.string().required("This field is required"),
        currentState: yup.string().required("This field is required"),
        currentCity: yup.string().required("This field is required"),
        currentPincode: yup.string().matches(/^[0-9]+$/, "Must be only digits").
            length(6, 'Pincode must be exactly 6 characters!').
            required('Pincode is required'),
        subContractorId: yup.string().required("This field is required"),
        projectId: yup.string().required("This field is required"),
        companyId: yup.string().required("This field is required"),

        // employeementType: yup.string().required("This field is required"),
        // skill_catagory: yup.string().required("This field is required"),
        // doj: yup.string().required("This field is required"),
    });
    const formik = useFormik({
        initialValues: {
            labourCodeNo: labourDetails?.result?.labourCodeNo ?? "",
            aadharNo: labourDetails?.result?.aadharNo ?? "",
            firstName: labourDetails?.result?.firstName ?? "",
            lastName: labourDetails?.result?.lastName ?? "",
            mobileNumber: labourDetails?.result?.mobileNumber ?? "",
            subContractorId: labourDetails?.result?.subContractorId ?? "",
            gender: labourDetails?.result?.gender ?? "",
            dateOfBirth: labourDetails?.result?.dateOfBirth ?? "",
            maritalStatus: labourDetails?.result?.maritalStatus ?? "",
            numberChildren: labourDetails?.result?.numberChildren ?? "",
            motherTongue: labourDetails?.result?.motherTongue ?? "",
            gurdianName: labourDetails?.result?.gurdianName ?? "",
            height: labourDetails?.result?.height ?? "",
            weight: labourDetails?.result?.weight ?? "",
            bloodGroup: labourDetails?.result?.bloodGroup ?? "",
            identificationMark: labourDetails?.result?.identificationMark ?? "",
            permanentAddressPincode: labourDetails?.result?.permanentAddressPincode ?? "",
            permanentHomeAddress: labourDetails?.result?.permanentHomeAddress ?? "",
            permanentAddressState: labourDetails?.result?.permanentAddressState ?? "",
            permanentAddressCity: labourDetails?.result?.permanentAddressCity ?? "",
            currentPincode: labourDetails?.result?.currentPincode ?? "",
            currentAddress: labourDetails?.result?.currentAddress ?? "",
            currentState: labourDetails?.result?.currentState ?? "",
            currentCity: labourDetails?.result?.currentCity ?? "",
            projectId: labourDetails?.result?.projectId ?? "",
            companyId: labourDetails?.result?.companyId ?? "",
            skill_catagory: labourDetails?.result?.skill_catagory ?? "",
            employeementType: labourDetails?.result?.employeementType ?? "",
            doj: labourDetails?.result?.doj ?? "",
            status: labourDetails?.result?.status ?? "",
            projectLabourCode: labourDetails?.result?.projectLabourCode ?? "",

        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = (values) => {
        if (labourDetails && labourDetails.result) {
            if (labourDetails.result.status === 0) {
                const params = {
                    id: labourDetails.result._id,
                    ...values,
                }
                dispatch(LabourActions.updateLabourBasicInfo(params));  
            } else if (labourDetails.result.status === 1) {
                setConfirmDialog(true)  
            } 
            //  if (labourDetails.result.projectId !== values.projectId) {
                    // setConfirmDialog(true)
            // const params = {
            //     id: labourDetails.result._id,
            //     ...values,
            // }
            //    else {
            //      const params = {
            //          id: labourDetails.result._id,
            //         ...values,
            //      }
            // dispatch(LabourActions.updateLabourBasicInfo(params));  

        }
        //    } else {
        //     const params = {
        //             id: labourDetails.result._id,
        //         ...values,

        //     };
        //     console.log('params',params)
        //     dispatch(LabourActions.updateLabourBasicInfo(params));
        //   }
    }
    const handleChangeProject = () => {
        const params = {
            id: labourDetails.result._id,
            ...formik.values,
        };
        dispatch(LabourActions.updateLabourBasicInfo(params));
    }

    useEffect(() => {
        if (success) {
            setConfirmDialog(false)
            toast.success(`${success?.message?.message ?? "Success"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });
            dispatch(GeneralActions.removeSuccess(LabourActions.updateLabourBasicInfo.type));
            if (labourDetails.result.projectId !== formik.values.projectId) {
                dispatch(LabourActions.getLabourById(success.message.data));
                const id = success.message.data
                history.push(`/app/labour/update/${id}`)
            } else {
                dispatch(LabourActions.getLabourById(id));
            }
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`${error?.message ?? "Error"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });

            dispatch(GeneralActions.removeError(LabourActions.updateLabourBasicInfo.type));
        }
    }, [error]);

    return (
        <Card>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                        <LabourBasicInformation formik={formik} id={labourDetails._id} />
                        <LabourAddress formik={formik} />

                        {labourDetails?.result?.status !== 99 && labourDetails?.role && Object.keys(labourDetails.role).map((item1, index) => (
                            <div key={index}>
                                {item1 === "Update" ? (
                                    <Grid sx={{ mt: 3 }} item container justifyContent="flex-end">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained">
                                            {loading ? <CircularProgress color="inherit" size={20} /> : <>Update</>}
                                        </Button>
                                    </Grid>) : null}
                            </div>))}
                    </Grid>
                </Grid>
            </form>
            <DialogConfirm
                title="Exit Project"
                content="Are you sure want to exit this project?"
                open={confirmDialog}
                onClose={() => setConfirmDialog(false)}
                onSubmit={handleChangeProject}
            />

        </Card>
    )
}