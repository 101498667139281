import React from 'react';
import FormIndex from '../FormIndex';


export default function PfCalculation() {
  return (
    <>
    <FormIndex 
      formData={"ecrChalan"}
      title = {"ECR Chalan"}
    />
</>
    )
}