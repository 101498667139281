import React, { useEffect, useState } from "react";
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import { Typography } from "@mui/material";

export default function ScrollTop() {
  const [isVisible, setIsVisible] = useState(false);

  // Top: 0 takes us all the way back to the top of the page
  // Behavior: smooth keeps it smooth!
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

// scroll-to-top classes: fixed, bottom:0, right:0
  return (
    <div className="scroll-top">
      {isVisible && (
        <div onClick={scrollToTop}>
            <Typography variant='h3' title="Scroll To Top">
              <VerticalAlignTopIcon sx={{marginTop: '-10px'}} />
            </Typography>
        </div>
      )}
    </div>
  );
}
