import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,Grid, Typography} from "@mui/material";
import PropTypes from "prop-types";
import Input from "./Input";
import {UserActions } from "slices/actions";
import { useDispatch} from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";

PasswordChange.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    onClose: PropTypes.func,
    id: PropTypes.string,
};

export default function PasswordChange(props) {
    const dispatch = useDispatch();
    const { open, title, onClose,id } = props;

    const validationSchema = yup.object({
        newPassword: yup.string().min(8, 'Password should be of minimum 8 characters length').
        required('Please fill this field'),
        confirmNewPassword: yup.string().min(8, 'Password should be of minimum 8 characters length').
        oneOf([yup.ref('newPassword'), null], 'Passwords must match').
        required('Please fill this field'),
      });
      const formik = useFormik({
        initialValues: {
            newPassword: "",
            confirmNewPassword: "",
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
          handleSubmit(values);
        },
      });
      const handleSubmit = (values) => {
        const params = {
            id: id,
            password: values.newPassword
        };
        dispatch(UserActions.updateUser(params))
        onClose();
      };
     
    const closeDialog = () => {
            formik.setFieldValue('newPassword',"");
            formik.setFieldValue('confirmNewPassword',"");
            formik.errors.newPassword = null
            formik.errors.confirmNewPassword = null
            onClose();
        }

  return (
        <Dialog open={open} onClose={closeDialog} className="pwdDialog">
             <DialogTitle>
                {title}
            </DialogTitle>
            <form>
             <DialogContent>
             <DialogContentText>

                    <Grid item lg={12} xs={12} sx={{mb: 2}}>
                        <Typography sx={{fontSize: 15, color: '#000'}}>New Password</Typography>
                        <Input
                            name="newPassword"
                            value={formik.values.newPassword}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.newPassword) && Boolean(formik.errors.newPassword)}
                            helperText={formik.touched.newPassword && formik.errors.newPassword}
                           />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Typography sx={{fontSize: 15, color: '#000'}}>Confirm Password</Typography>
                        <Input
                            name="confirmNewPassword"
                            value={formik.values.confirmNewPassword}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.confirmNewPassword) && Boolean(formik.errors.confirmNewPassword)}
                            helperText={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}
                        />
                    </Grid>
                    
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid item lg={12} xs={12} sx={{mb: 2, textAlign: 'right', px: 2}}>
                    <Button onClick={closeDialog} sx={{mr: 1}} variant="contained" color="inherit">Cancel</Button>
                    <Button onClick={formik.handleSubmit} variant="contained" color="primary">
                        Change
                    </Button>
                </Grid>
            </DialogActions>
            </form>
        </Dialog>
    )
}