import { get,post,del,patch} from "../utils/api";

const API_URL = process.env.REACT_APP_API_URL 

// const API_URL = "http://192.168.29.117:5000/api"

const CreateCompany = async (params) => post(`${API_URL}/company`,params);

const CompanyList = async (params) => get(`${API_URL}/company/`,params);

const FetchDataByPincode = async (id) => get(`${API_URL}/location/${id}`);

const DeleteCompany = async (id) => del(`${API_URL}/company/${id}`);

const GetCompanyById = async(id) => get(`${API_URL}/company/${id}`);

const UpdateCompany = async (id, params) => patch(`${API_URL}/company/${id}`, params);

const UpdateCompanyUser = async (id, params) => patch(`${API_URL}/user/${id}`, params);

const getCompanyViewById = async(id) => get(`${API_URL}/company/companyViewById/${id}`);

const uploadImage = async(id,params) => patch(`${API_URL}/company/logoUpload/${id}`,params)

export const CompanyService = {
    CreateCompany,
    CompanyList,
    FetchDataByPincode,
    DeleteCompany,
    GetCompanyById,
    getCompanyViewById,
    UpdateCompany,
    UpdateCompanyUser,
    uploadImage
  };