import React, { useEffect,useState } from "react";
import { Button, Grid, Box, Card, Typography, MenuItem, FormHelperText } from "@mui/material";
import PageTitle from "components/PageTitle";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Input from "components/Input";
import { SiteActions, GeneralActions, ProjectActions, CompanyActions } from "slices/actions";
import { GeneralSelector, ProjectSelector } from "selectors";
import CircularProgress from '@mui/material/CircularProgress';
import { CompanySelector } from "selectors/CompanySelector";
import { Constants } from "utils/constants";
import SelectField from "components/SelectField";
import TextareaAutosize from '@mui/material/TextareaAutosize';

export default function CreateSite() {
  const history = useHistory();
  const dispatch = useDispatch();
  const projectDetails = useSelector(ProjectSelector.getProjectById())
  const success = useSelector(GeneralSelector.success(SiteActions.createSite.type));
  const loading = useSelector(GeneralSelector.loader(SiteActions.createSite.type));
  const companyList = useSelector(CompanySelector.getCompanyList());
  const projectList = useSelector(ProjectSelector.getProjectList());
  const userType = localStorage.getItem('userType')
  const companyId = localStorage.getItem('companyID')
  const projectId = localStorage.getItem('projectID')
  const [company,setCompany] = useState();
  const [project,setProject] = useState();

  useEffect(() => {
    if (projectDetails&&projectDetails.data&&projectDetails.data.project) {
      setCompany(projectDetails.data.project.company_id)
      setProject(projectDetails.data.project._id)
    } 
  },[projectDetails])

  useEffect(() => {
    if (!companyId) {
    dispatch(CompanyActions.getCompanyList())
    } else {
      const params = {
        company_id: companyId,
        status: 1
      }
      dispatch(ProjectActions.getProjectList(params))
    } 
    
  },[])
 
 useEffect(() => {
    if (success) {
      toast.success(`${success?.message ?? "Success"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });

      dispatch(GeneralActions.removeSuccess(SiteActions.createSite.type));
      history.goBack()
    }
  }, [success]);

  const handleChangeCompany = (e) => {
    const cId = e.target.value;
    formik.setFieldValue('company',cId)
    formik.setFieldValue('projects',"")
    const params = {
      company_id: cId,
      status: 1
    }
     dispatch(ProjectActions.getProjectList(params))
   }
   
  const handleChangeProject = (e) => {
    const pId = e.target.value
    formik.setFieldValue('projects',pId)
  }

  const validationSchema = yup.object({
    projects: yup.string().required("Project name is required"),
    company: yup.string().required("Company name is required"),
    name: yup.string().required("Site name is required"),
    address: yup.string().required("Adress is required"),
  });
  const formik = useFormik({
    initialValues: {
      projects: project?project:projectId,
      company: company?company:companyId,
      name: "",
      address: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const handleSubmit = (values) => {
      dispatch(SiteActions.createSite(values));
  };

  return (
    <Box className="position-relative">
      <PageTitle isBack={true} title="Site Location" />
        <Grid container justifyContent="center">
            <Card sx={{maxWidth: '500px', width: '100%', margin: '0 auto'}}>
              <form onSubmit={formik.handleSubmit}>
              {userType === Constants.SUPERADMIN ?(
              <Grid item lg={12} sm={12} xs={12}>
                <Typography sx={{fontSize: 15, marginBottom: "5px"}}>Company Name</Typography>
                <SelectField
                    value={formik.values.company}
                    onChange={handleChangeCompany}
                    error={Boolean(formik.touched.company) && Boolean(formik.errors.company)}
                    helperText={formik.touched.company && formik.errors.company}
                    >
                    {companyList && companyList.map((item,index) => (
                    <MenuItem key={index} value={item._id}>
                            {item.name}
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
            {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER ?(
              <Grid item lg={12} sm={12} xs={12} sx={{ my: 2 }}>
                <Typography sx={{fontSize: 15, marginBottom: "5px"}}>Project Name</Typography>
                <SelectField
                    value={formik.values.projects}
                    onChange={handleChangeProject}
                    error={Boolean(formik.touched.projects) && Boolean(formik.errors.projects)}
                    helperText={formik.touched.projects && formik.errors.projects}
                    >
                    {(formik.values.company && projectList) && projectList.map((item,index) => (
                    <MenuItem key={index} value={item._id}>
                            {item.name}
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
              <Grid item lg={12} sm={12} xs={12}>
                <Input sx={{ mb: 2 }}
                  label="Site Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.name) && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <Typography variant='' sx={{fontSize: '15px'}}>Address</Typography>
                <TextareaAutosize
                  style={{
                      textAlign: "left",
                      width: "100%",
                      height: "80px",
                      background: '#f1f1f1',
                      borderRadius: '8px',
                      border: '1px solid #f1f1f1',
                      padding: '5px'
                  }}
                  name="address"
                  maxRows={4}
                  aria-label="maximum height"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  />
                  {(formik.touched.address && formik.errors.address) && (
                    <FormHelperText error={formik.touched.address && Boolean(formik.errors.address)}>{formik.touched.address && formik.errors.address}</FormHelperText>
                  )}
               </Grid>  
                <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      type="submit"
                      >
                      {loading?<CircularProgress color="inherit" size={20}/>:<>Submit</> 
                      }
                    </Button>
                </Grid>
              </form>
            </Card>
          {/* </Grid> */}
        </Grid>
    </Box>
  );
}
