import {createSelector} from "@reduxjs/toolkit";

const companySelector = (state) => state.company;

const getCompanyList = () => createSelector(
    companySelector,
    company => company.companies
);
const getLocationList = () => createSelector(
  companySelector,
  company => company.pinCodeData
);

const getPagination = () => createSelector(
  companySelector,
  state => state.pagination
);
const getCompanyById = () => createSelector(
  companySelector,
  state => state.company
)
const getRole = () => createSelector(
  companySelector,
  state => state.role
);
const getCompanyView = () => createSelector(
  companySelector,
  company => company.companyViewData
);
export const CompanySelector = {
  getCompanyList,
  getLocationList,
  getPagination,
  getCompanyById,
  getRole,
  getCompanyView
}