import React, {useState} from "react";
import {
    Box,
    Card,
    Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import DisabilityDetails from './DisabilityDetails';
import PfBankDetails from "./PfBankDetails";
import IdentityProof from "./IdentityProof";
import PFBasicInfo from "./PFBasicInfo";
import NomineAllInfo from "./NomineInfo/NomineAllInfo";

PFInformation.propTypes = {
    labourDetails: PropTypes.object,
};

export default function PFInformation(props) {
    const { labourDetails } = props;
    const [value, setValue] = useState('1');
    const handleChange = (event,newValue) => {
        setValue(newValue);
      };
      

     return (
        <Card>
            <Typography variant='h5' sx={{ fontWeight: "600" }}>PF Information</Typography>
            <Box sx={{ width: '100%', typography: 'body1', my: 3 }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="tabsNum" style={{fontWeight: "bold"}}>
                        <Tab label="Basic Info" value="1" sx={{fontWeight: "bold", textTransform: "capitalize"}} />
                        <Tab label="Identity proof" value="2" sx={{fontWeight: "bold", textTransform: "capitalize"}} />
                        <Tab label="Differently Abled" value="3" sx={{fontWeight: "bold", textTransform: "capitalize"}} />
                        <Tab label="Bank Details" value="4" sx={{fontWeight: "bold", textTransform: "capitalize"}} />
                        <Tab label="Nominee Info" value="5" sx={{fontWeight: "bold", textTransform: "capitalize"}} />
                    </TabList>
                    </Box>
                    <TabPanel value="1">
                        <PFBasicInfo labourDetails={labourDetails} />
                    </TabPanel>
                    <TabPanel value="2">
                        <IdentityProof labourDetails={labourDetails} />
                    </TabPanel>
                    <TabPanel value="3">
                        <DisabilityDetails labourDetails={labourDetails} />
                    </TabPanel>
                    <TabPanel value="4">
                        <PfBankDetails labourDetails={labourDetails} />
                    </TabPanel>
                    <TabPanel value="5">
                        <NomineAllInfo labourDetails={labourDetails} />
                    </TabPanel>
                  
                </TabContext>
            </Box>
        </Card>
    )
}