import {get, post, patch, del} from "../utils/api";

const API_URL = process.env.REACT_APP_API_URL 

const GetAttendances = async (params) => get(`${API_URL}/labourAttendance`,params);

const GetAttendanceById = async (id) => get(`${API_URL}/attendance/${id}`);

const GetAttendanceUserToday = async () => get(`${API_URL}/attendance/today`);

const CreateAttendance = async (params) => post(`${API_URL}/labourAttendance`, params);

const UpdateAttendance = async (id, params) => patch(`${API_URL}/attendance/${id}`, params);

const DeleteAttendance = async (id) => del(`${API_URL}/attendance/${id}`);

const UpdateAttendanceDocument = async (params) => post(`${API_URL}/labourAttendance/bulkUpload`,params);

const MonthyAttendanceUpdate = async (params) => post(`${API_URL}/labourAttendance/monthlyStatusUpdate`,params);

const createMyAttendance = async (params) => post(`${API_URL}/subAttendance`, params);

const monthlyMyAttendanceUpdate = async (params) => post(`${API_URL}/subAttendance/monthlyStatusUpdate`,params);

export const AttendanceService = {
    GetAttendances,
    GetAttendanceById,
    GetAttendanceUserToday,
    CreateAttendance,
    UpdateAttendance,
    DeleteAttendance,
    UpdateAttendanceDocument,
    MonthyAttendanceUpdate,
    createMyAttendance,
    monthlyMyAttendanceUpdate
};