import React, {useEffect,useState} from "react";
import {
    Box, Card, Link, Table, TableBody, TableCell, TableHead, TableRow,Grid, MenuItem
} from "@mui/material";
import Typography from "@mui/material/Typography";
import FloatingButton from "components/FloatingButton";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {GeneralSelector, MasterDataSelector, ProjectSelector, SubContractorSelector, UserSelector} from "selectors";
import { CompanyActions, MasterDataActions, ProjectActions, SubContractorActions, UserActions} from "slices/actions";
import ListSkeleton from "../../components/Skeleton/ListSkeleton";
import { CompanySelector } from "selectors/CompanySelector";
import { Constants } from "utils/constants";
import SelectField from "components/SelectField";
import styled from "@emotion/styled";


const FilterBox = styled(Box)(() => ({
    width: "100%",
    marginTop: 30,
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-between"
}));

const GstCompliance = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const gstCompilanceListData = useSelector(UserSelector.getGstCompilanceList());
    const loading = useSelector(GeneralSelector.loader(UserActions.getGstComplianceListing.type));
    const role = useSelector(UserSelector.getGstCompilanceRole());
    const companyList = useSelector(CompanySelector.getCompanyList());
    const projectList = useSelector(ProjectSelector.getProjectList());
    const monthList = useSelector(MasterDataSelector.getMonthList());
    const yearList = useSelector(MasterDataSelector.getYearList())
    const userType = localStorage.getItem('userType');
    const companyId = localStorage.getItem('companyID');
    const projectId = localStorage.getItem('projectID');
    const subcontractorId = localStorage.getItem('subcontractorID');
    const [assignedProject,setAssignedProject] = useState("");
    const assignedProjectToSubcontractorList = useSelector(SubContractorSelector.getAssignedSubcontractor());
    const [gstComplianceForm,setGstComplianceForm] = useState({
        company_id: companyId?companyId:"",
        project_id: projectId?projectId:"",
        subcontractor_id: subcontractorId?subcontractorId:"",
        month: "",
        year: "",
        Compliance_type: ""
      })
   
    useEffect(() => {
        dispatch(MasterDataActions.getMonthList());
        dispatch(MasterDataActions.getYearList());
        if (!companyId) {
        dispatch(CompanyActions.getCompanyList())
        } else if (companyId && !projectId) {
            const params = {
                company_id: companyId,
            }
            dispatch(ProjectActions.getProjectList(params))
        } else if ((projectId && companyId) || subcontractorId) {
            const params = {
                subcontractorId: subcontractorId
            }
             dispatch(SubContractorActions.getAssignedSubContractor(params));
        }
        dispatch(UserActions.getGstComplianceListing(gstComplianceForm));
    },[])
    const handleChangeCompany = (e) => {
        const cId = e.target.value;
        gstComplianceForm.company_id = cId;
        gstComplianceForm.project_id = "";
        gstComplianceForm.subcontractor_id = "";
        setGstComplianceForm({...gstComplianceForm})
         const params = {
            company_id: cId,
            status: 1
          }
          dispatch(ProjectActions.getProjectList(params))
        dispatch(UserActions.getGstComplianceListing(gstComplianceForm));
    } 
     
     const handleChangeProject = (e) => {
        if (gstComplianceForm.company_id) {
        const pId = e.target.value
        gstComplianceForm.project_id = pId;
        gstComplianceForm.subcontractor_id = "";
        setGstComplianceForm({...gstComplianceForm})
        const params = {
            company_id: gstComplianceForm.company_id || companyId,
            project_id: pId,
        }
        dispatch(SubContractorActions.getAssignedSubContractor(params))
        dispatch(UserActions.getGstComplianceListing(gstComplianceForm));
        } 
      }

    const handleChangeSubcontractor = (e) => {
        if (gstComplianceForm.project_id) {
          const sId = e.target.value
          gstComplianceForm.subcontractor_id = sId;
          setGstComplianceForm({...gstComplianceForm})
          dispatch(UserActions.getGstComplianceListing(gstComplianceForm));
        } 
    }
    const handleChangeMonth = (e) => {
        gstComplianceForm.month = e.target.value
        setGstComplianceForm({...gstComplianceForm})
        dispatch(UserActions.getGstComplianceListing(gstComplianceForm));
    }
    const handleChangeYear = (e) => {
        gstComplianceForm.year = e.target.value
        setGstComplianceForm({...gstComplianceForm})
        dispatch(UserActions.getGstComplianceListing(gstComplianceForm));
    }
    const handleChangeProjectBySubcontractor = (e) => {
        setAssignedProject(e.target.value)
        for (const i in assignedProjectToSubcontractorList.data) {
            if (assignedProjectToSubcontractorList.data[i].projectId._id === e.target.value) {
                gstComplianceForm.company_id = assignedProjectToSubcontractorList.data[i].companyId._id
                gstComplianceForm.project_id = e.target.value
               setGstComplianceForm({...gstComplianceForm})
               dispatch(UserActions.getGstComplianceListing(gstComplianceForm));
             }
        }
    }
    const handleChangeComplianceType = (e) => {
        gstComplianceForm.Compliance_type = e.target.value
        setGstComplianceForm({...gstComplianceForm})
        dispatch(UserActions.getGstComplianceListing(gstComplianceForm));  
    }
   return (
    <Card>
    <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>GST Compliance</Typography>
    <FilterBox>
        <Grid container spacing={3}>
            {userType === Constants.SUPERADMIN ?(
              <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Company</Typography>
                <SelectField
                    value={gstComplianceForm.company_id}
                    onChange={handleChangeCompany}
                    >
                    {companyList && companyList.map((item,index) => (
                    <MenuItem key={index} value={item._id}>
                            {item.name}
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
            {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER ?(
              <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Project</Typography>
                <SelectField
                    value={gstComplianceForm.project_id}
                    onChange={handleChangeProject}
                    >
                    {(gstComplianceForm.company_id && projectList) && projectList.map((item,index) => (
                    <MenuItem key={index} value={item._id}>
                            {item.name}
                    </MenuItem>
                    ))}
                </SelectField> 
              </Grid>
            ):null
            }
            {userType === Constants.SUPERADMIN || userType === Constants.COMPANY_USER || userType === Constants.PROJECT_USER?(
              <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Subcontractor</Typography>
                <SelectField
                    value={gstComplianceForm.subcontractor_id}
                    onChange={handleChangeSubcontractor}
                    >
                   {(gstComplianceForm.project_id && assignedProjectToSubcontractorList) && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                            <MenuItem key={index} value={item?.subcontractorId?._id}>
                                    {item?.subcontractorId?.subContractorName}
                            </MenuItem>
                            ))}
                </SelectField> 
              </Grid>
            ):null
            }
             {userType === Constants.SUB_CONTRACTOR_USER ?(
                        <Grid item lg={4} sm={12} xs={12}>
                        <Typography sx={{fontSize: 15}}>Project</Typography>
                        <SelectField
                            value={assignedProject}
                            onChange={handleChangeProjectBySubcontractor}
                            >
                            {assignedProjectToSubcontractorList && assignedProjectToSubcontractorList.data && assignedProjectToSubcontractorList.data.map((item,index) => (
                            <MenuItem key={index} value={item?.projectId?._id}>
                                    {item?.projectId?.name}({item?.companyId?.name})
                            </MenuItem>
                            ))}
                        </SelectField> 
                        </Grid>
                    ):null
                    }
            <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Month</Typography>
                <SelectField
                     value={gstComplianceForm.month}
                     onChange={handleChangeMonth}
                    >
                     {monthList && monthList.masterMonthTable && monthList.masterMonthTable.map((item,index) => (
                              <MenuItem value={item.mth} key={index}>
                                    {item.mth}
                              </MenuItem> 
                    ))}
                </SelectField> 
            </Grid>
            <Grid item lg={4} sm={12} xs={12}>
                <Typography sx={{fontSize: 15}}>Year</Typography>
                <SelectField
                   value={gstComplianceForm.year}
                   onChange={handleChangeYear}
                    >
                     {yearList && yearList.masterYearTable && yearList.masterYearTable.map((item,index) => (
                              <MenuItem value={item.yr} key={index}>
                                    {item.yr}
                              </MenuItem> 
                     ))}
                </SelectField> 
            </Grid>
            <Grid item lg={4} sm={12} xs={12}>

            <Typography variant='h5' sx={{color: '#000', fontSize: 15, opacity: 'rgba(0, 0, 0, 0.87)'}}>Compliance Type</Typography>
              <SelectField
                            value={gstComplianceForm.Compliance_type}
                            onChange={handleChangeComplianceType}
                            >
                                <MenuItem value={"GSTR 1"}>
                                    {"GSTR 1"}
                                </MenuItem>
                                <MenuItem value={"GSTR 3"}>
                                    {"GSTR 3"}
                                </MenuItem>
            </SelectField>
            </Grid>
            {/* <Grid item lg={4} sm={12} xs={12} sx={{textAlign: 'right'}}>
                <Button sx={{mt: 4, px: 4, fontWeight: 'bold'}}
                    color="primary"
                    variant="contained"
                    type="reset"
                    onClick={handleReset}
                    >
                    {loading?<CircularProgress color="inherit" size={20}/>:<>Reset</>}
                </Button>
            </Grid> */}
        </Grid>
       
    </FilterBox>
    
    {loading ? (
        <ListSkeleton/>
    ) : (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Project Name</TableCell>
                        <TableCell>Subcontractor Name</TableCell>
                        <TableCell>Month</TableCell>
                        <TableCell>Year</TableCell>
                        <TableCell>Compliance Type</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    
                      {gstCompilanceListData && gstCompilanceListData.data && gstCompilanceListData.data.map((item, i) => (
                        <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {item.company_name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.project_name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                               {item.subcontractor_name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                               {item.month}
                            </TableCell>
                            <TableCell component="th" scope="row">
                               {item.year}
                            </TableCell>
                            <TableCell component="th" scope="row">
                               {item.Compliance_type}
                            </TableCell>
                            <TableCell component="th" scope="row">
                            {role && Object.keys(role).map((item1,index1) => (
                            <div key={index1}>
                                 {item1 === "View document"?(
                               <Link href={item.gstCompliance_url} target='_blank' className="cursor-pointer" >View Document</Link>):null}
                            </div>
                            ))}
                            </TableCell>
                            
                        </TableRow>
                    ))}  
                </TableBody>
            </Table>

       
        </Box>
    )}

{role && Object.keys(role).map((item2,index2) => (
    <div key={index2}>
       {item2 === "Add"?(
    <FloatingButton
        onClick={() => history.push("/app/gstcompliance/create")}/>):null}
    </div>
))}
</Card>
    
  )
}


export default GstCompliance