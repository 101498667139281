import React,{useRef, useEffect} from "react";
import {Box, Card, Typography, Table, TableHead, TableRow, TableBody, TableCell, IconButton, Avatar} from "@mui/material";
import { LocalPrintshopOutlined } from "@mui/icons-material";
import ReactToPrint from 'react-to-print';
import PropTypes from "prop-types";
import { format } from 'date-fns';
import {useParams} from "react-router-dom";
import { LabourActions } from "slices/actions";
import { useDispatch, useSelector } from "react-redux";
import { LabourSelector } from "selectors";

Proforma.propTypes = {
    labourDetails: PropTypes.object,
 };

export default function Proforma(props) {
    const dispatch = useDispatch();
    const { labourDetails} = props;
    let componentRef=useRef(null);
    const pageStyle = `{ size: 297 mm 420 mm }`;
    const {id} = useParams();
    const labourHistoryListing = useSelector(LabourSelector.getLabourHistory())

    useEffect(() => {
        if (id) {
        dispatch(LabourActions.getLabourHistory(id))
        }
        
    },[id])
    return (
        <Box>
            <Typography variant='h5' sx={{mb: 2, fontWeight: '500', display: 'inline-block'}}>Proforma for Screening</Typography>
            <ReactToPrint
                pageStyle={pageStyle}
                trigger = {() => (
                <IconButton sx={{float: 'right'}}>
                <LocalPrintshopOutlined color='primary' sx={{cursor: 'pointer', float: 'right'}} />
                </IconButton>
                )}
                content={() => componentRef}
            />
            <Card ref={el => (componentRef = el)}>
                <div style={{border: '1px solid #000', padding: '20px'}}>
                    <Table className="ForBorderBottom">
                        <TableHead className="thead-dark">
                            <TableRow>
                                <TableCell align='left' sx={{py: 0}}>
                                    <img src={`${"data:image/png;base64,"}${labourDetails?.base64URL.companyLogo}`}
                                     style={{width: '130px', height: '100px', objectFit: 'cover'}} />
                                </TableCell>
                                <TableCell width='40%' align='left' sx={{fontSize: 18, py: 0}}>
                                    {labourDetails && labourDetails.base64URL && labourDetails.base64URL.data && labourDetails.base64URL.data.companyName}
                                    {/* <Typography style={{display: 'block', fontSize: 20,}}>
                                        Site Name : DMRC DC 02 Project
                                    </Typography> */}
                                </TableCell>
                                <TableCell align='right' colSpan='2' sx={{py: 0}}>
                                    <div style={{float: 'right'}}>
                                        {labourDetails?.base64URL?.logo ? (
                                            <img src={`${"data:image/png;base64,"}${labourDetails?.base64URL.logo}`}
                                            style={{width: '51mm', height: '51mm', objectFit: 'cover'}} />
                                        ) : (
                                            <Avatar sx={{ width: '120px', height: '120px', objectFit: 'cover' }} />
                                        )}
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align='center' colSpan='4' sx={{py: 1}}>
                                    <Typography 
                                        sx={{fontSize: 20, fontWeight: 'bold',textTransform: 'uppercase', mb: 3}}
                                    >
                                            <b>Proforma for screening labour engaged by<br/>{labourDetails?.base64URL?.data?.SubcontractorName}</b>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{py: 1}}>
                                    Name of the Contractor/ Sub-Contractor :
                                </TableCell>
                                <TableCell colSpan='3' sx={{py: 1}}>
                                    {/* <span className='BorderSpan'></span> */}
                                    <Typography style={{borderBottom: '1px solid #ccc', paddingBottom: '10px'}}>
                                        {labourDetails?.base64URL?.data?.SubcontractorName}                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{py: 1, width: '27%'}}>
                                    Full Name of the Labour :
                                </TableCell>
                                <TableCell colSpan='3' sx={{py: 1}}>
                                    <Typography style={{borderBottom: '1px solid #ccc', paddingBottom: '10px'}}>
                                        {labourDetails?.result?.firstName} {" "} {labourDetails?.result?.lastName}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{py: 1, width: '27%'}}>
                                    Father / Husband&apos;s Name :
                                </TableCell>
                                <TableCell colSpan='3' sx={{py: 1}}>
                                    <Typography style={{borderBottom: '1px solid #ccc', paddingBottom: '10px'}}>
                                      {labourDetails?.result?.gurdianName} 
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{py: 1, width: '40%'}}>
                                    Permanent Home Address :
                                </TableCell>
                                <TableCell colSpan='3' sx={{py: 1}}>
                                    <Typography style={{borderBottom: '1px solid #ccc', paddingBottom: '10px'}}>
                                       {labourDetails?.result?.permanentHomeAddress},{labourDetails?.result?.permanentAddressCity},{labourDetails?.result?.permanentAddressState},
                                       {labourDetails?.result?.permanentAddressPincode}
                                     </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{py: 1, width: '33%'}}>
                                    Present Address :
                                </TableCell>
                                <TableCell colSpan='3' sx={{py: 1}}>
                                    <Typography style={{borderBottom: '1px solid #ccc', paddingBottom: '10px'}}>
                                       {labourDetails?.result?.currentAddress},{labourDetails?.result?.currentCity},{labourDetails?.result?.currentState},
                                       {labourDetails?.result?.currentPincode}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{py: 1}}>
                                    Gender :
                                </TableCell>
                                <TableCell sx={{py: 1}}>
                                    <Typography style={{borderBottom: '1px solid #ccc', paddingBottom: '10px'}}>
                                      {labourDetails?.result?.gender}
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{py: 1, width: '27%'}}>
                                    UAN/PF No :
                                </TableCell>
                                <TableCell sx={{py: 1}}>
                                    <Typography style={{borderBottom: '1px solid #ccc', paddingBottom: '10px'}}>
                                        {labourDetails?.result?.pfBasicInfo_UAN_Number}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{py: 1, width: '27%'}}>
                                    Date of Birth :
                                </TableCell>
                                <TableCell colSpan='2' sx={{py: 1}}>
                                    <Typography style={{borderBottom: '1px solid #ccc', paddingBottom: '10px'}}>
                                          {labourDetails?.result?.dateOfBirth && format(new Date(labourDetails.result.dateOfBirth), 'dd/MM/yyyy')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan='4' sx={{fontSize: 12}}>
                                    (Please obtain photocopy of birth certificate issued either by School or Gram Panchayat as required under workmen&apos;s compensation Act. 1923)
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{py: 1, width: '30%'}}>
                                    Mobile Number :
                                </TableCell>
                                <TableCell colSpan='2' sx={{py: 1}}>
                                    <Typography style={{borderBottom: '1px solid #ccc', paddingBottom: '10px'}}>
                                        {labourDetails?.result?.mobileNumber}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <div style={{width: '96%', margin: '20px auto', overflowX: 'auto'}}>
                        <Typography sx={{mt: 2, mb: 1}}>
                            <b>KYC Details: Tick the code and mention the document number.</b>
                            <span style={{fontSize: 12}}>(Please obtain photocopy of KYC Document.)</span>
                        </Typography>
                        <Table className="" style={{border: '1px solid #000'}}>
                            <TableHead className="thead-dark">
                                <TableRow className="ForBorder Thead" style={{fontWeight: 'normal'}}>
                                    <TableCell>Code</TableCell>
                                    <TableCell align='center'>N</TableCell>
                                    <TableCell align='center'>A</TableCell>
                                    <TableCell align='center'>P</TableCell>
                                    <TableCell align='center'>B</TableCell>
                                    <TableCell align='center'>T</TableCell>
                                    <TableCell align='center'>D</TableCell>
                                    <TableCell align='center'>E</TableCell>
                                    <TableCell align='center'>R</TableCell>
                                </TableRow>
                                <TableRow className="ForBorder Thead">
                                    <TableCell>Type</TableCell>
                                    <TableCell>National Population Register</TableCell>
                                    <TableCell>Aadhar</TableCell>
                                    <TableCell>Permanent Account Number(PAN)</TableCell>
                                    <TableCell>Bank Account Number</TableCell>
                                    <TableCell>Passport Number / Validity date</TableCell>
                                    <TableCell>Driving license</TableCell>
                                    <TableCell>Election Card</TableCell>
                                    <TableCell>Ration Card</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className="ForBorder">
                                    <TableCell>Yes / No</TableCell>
                                    <TableCell>{labourDetails?.result?.nationalPopulationRegister?"Y":"N"}</TableCell>
                                    <TableCell>{labourDetails?.result?.aadharNo?"Y":"N"}</TableCell>
                                    <TableCell>{labourDetails?.result?.panCard?"Y":"N"}</TableCell>
                                    <TableCell>{labourDetails?.result?.bankAccountNumber?"Y":"N"}</TableCell>
                                    <TableCell>{labourDetails?.result?.passportNumber?"Y":"N"}</TableCell>
                                    <TableCell>{labourDetails?.result?.drivingLicense?"Y":"N"}</TableCell>
                                    <TableCell>{labourDetails?.result?.electionCard?"Y":"N"}</TableCell>
                                    <TableCell>{labourDetails?.result?.rationCard?"Y":"N"}</TableCell>
                                </TableRow>
                                <TableRow className="ForBorder">
                                    <TableCell>Number</TableCell>
                                    <TableCell>{labourDetails?.result?.nationalPopulationRegister}</TableCell>
                                    <TableCell>{labourDetails?.result?.aadharNo}</TableCell>
                                    <TableCell>{labourDetails?.result?.panCard}</TableCell>
                                    <TableCell>{labourDetails?.result?.bankAccountNumber}</TableCell>
                                    <TableCell>{labourDetails?.result?.passportNumber}</TableCell>
                                    <TableCell>{labourDetails?.result?.drivingLicense}</TableCell>
                                    <TableCell>{labourDetails?.result?.electionCard}</TableCell>
                                    <TableCell>{labourDetails?.result?.rationCard}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                    <Table className="ForBorderBottom">
                        <TableHead className="thead-dark">
                            <TableRow>
                                <TableCell align='left'>
                                    <Typography sx={{fontSize: 15}}>
                                        Married / Single / Widow / Widower : 
                                        <span className='BorderSpan'>{labourDetails?.result?.maritalStatus}</span>
                                    </Typography>
                                </TableCell>
                                <TableCell align='left'>
                                    <Typography sx={{fontSize: 15}}>
                                        Number of Children : <span className='BorderSpan'>{labourDetails?.result?.numberChildren}</span>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align='left'>
                                    <Typography sx={{fontSize: 15}}>
                                        Mother Tongue : <span className='BorderSpan'>{labourDetails?.motherTongueName}</span>
                                    </Typography>
                                </TableCell>
                                <TableCell align='left'>
                                    <Typography sx={{fontSize: 15}}>
                                        Other languages Known : <span className='BorderSpan'></span>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align='left'>
                                    <Typography sx={{fontSize: 15}}>
                                        In case of emergency person to be contacted 
                                        <span className='BorderSpan'>
                                            {labourDetails?.result?.emergencyContactPersonPhone}, 
                                            {/* {labourDetails?.result?.emergencyContactAddress}, 
                                            {labourDetails?.result?.emergencyContactCity}, 
                                            {labourDetails?.result?.motherTongue} */}
                                        </span><br/>
                                        (With Address and Telephone Number if any)
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align='left'>
                                    <Typography sx={{fontSize: 15}}>
                                        <span className='BorderSpan'></span><br/>
                                        Signature or Left Thumb Impression<br/>
                                        of the Workman for identification.
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align='left' colSpan='2' sx={{py: 0}}>
                                    <Typography sx={{fontSize: 15, mb: 1}}>
                                        Any Other Identification Mark :
                                        <span className='BorderSpan'>{labourDetails?.result?.identificationMark}</span>
                                    </Typography>
                                    <Typography sx={{fontSize: 15}}>
                                        Weight : <span className='BorderSpan'>{labourDetails?.medicalData?.weight}</span> Kgs.
                                        Height : <span className='BorderSpan'>{labourDetails?.medicalData?.height}</span> Cms.<br/>
                                        Vision : <span className='BorderSpan'>NA</span>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>

                    <div style={{width: '96%', margin: '20px auto', overflowX: 'auto'}}>
                        <Typography sx={{mt: 2, mb: 1, textDecoration: 'underline'}}>
                            <b>Bank Account details</b>
                        </Typography>
                        <Table className="" style={{border: '1px solid #000'}}>
                            <TableHead className="thead-dark">
                                <TableRow className="ForBorder Thead" style={{fontWeight: 'normal'}}>
                                    <TableCell align='center'>Name of the Bank</TableCell>
                                    <TableCell align='center'>Branch</TableCell>
                                    <TableCell align='center'>Branch Address</TableCell>
                                    <TableCell align='center'>SB Acct No</TableCell>
                                    <TableCell align='center'>IFSC Code</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="thead-dark">
                                <TableRow className="ForBorder Thead" style={{fontWeight: 'normal'}}>
                                    <TableCell align='center'>{labourDetails?.result?.bankName}</TableCell>
                                    <TableCell align='center'>{labourDetails?.result?.bankBranchName}</TableCell>
                                    <TableCell align='center'>{labourDetails?.result?.bankAddress}</TableCell>
                                    <TableCell align='center'>{labourDetails?.result?.bankAccountNumber}</TableCell>
                                    <TableCell align='center'>{labourDetails?.result?.bankIfsc}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                    <div style={{width: '96%', margin: '20px auto', overflowX: 'auto'}}>
                        <Typography sx={{mt: 1, mb: 1, textDecoration: 'underline'}}>
                            <b>Education</b>
                        </Typography>
                        <Table className="" style={{border: '1px solid #000'}}>
                            <TableHead className="thead-dark">
                                <TableRow className="ForBorder Thead">
                                    <TableCell align='center'>Examination Passed</TableCell>
                                    <TableCell align='center'>Year</TableCell>
                                    <TableCell align='center'>School/Board</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="thead-dark">
                                {labourDetails?.result?.education?.map((item, index) => (
                                    <TableRow className="ForBorder Thead" key={index} value={item._id}>
                                        <TableCell align='center'>{item.higherEducation}</TableCell>
                                        <TableCell align='center'>{item.passingyear}</TableCell>
                                        <TableCell align='center'>{item.institute}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                    <div style={{width: '96%', margin: '20px auto', overflowX: 'auto'}}>
                        <Typography sx={{mt: 1, mb: 1, textDecoration: 'underline'}}>
                            <b>Previous Work Experience</b>
                        </Typography>
                        <Table className="" style={{border: '1px solid #000'}}>
                            <TableHead className="thead-dark">
                                <TableRow className="ForBorder Thead">
                                    <TableCell align='center' rowSpan='2'>Sl.No</TableCell>
                                    <TableCell align='center' rowSpan='2'>Name of the Subcontractor</TableCell>
                                    <TableCell align='center' rowSpan='2'>Project Site</TableCell>
                                    <TableCell align='center' rowSpan='2'>Category</TableCell>
                                    <TableCell align='center' colSpan='2'>Period</TableCell>
                                    <TableCell align='center' rowSpan='2'>Salary/Wage/Rate</TableCell>
                                </TableRow>
                                <TableRow className="ForBorder Thead">
                                    <TableCell align='center'>From</TableCell>
                                    <TableCell align='center'>To</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="thead-dark">
                                {labourHistoryListing && labourHistoryListing.map((item,index) => (
                                    <TableRow className="ForBorder Thead" key={index} value={item._id}>
                                        <TableCell align='center'>{index+1}</TableCell>
                                        <TableCell align='center'>{item.subContractorName}</TableCell>
                                        <TableCell align='center'>{item.location}</TableCell>
                                        <TableCell align='center'>{item.employeementType}</TableCell>
                                        <TableCell align='center'>
                                            {item.doj && format(new Date(item.doj), 'dd/MM/yyyy')}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {item.exitDate && format(new Date(item.exitDate), 'dd/MM/yyyy')}
                                        </TableCell>
                                        <TableCell align='center'>{item.wages}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                    
                    {/* <p>{JSON.stringify(labourHistoryListing)}</p> */}
                    <Table className="ForBorderBottom">
                        <TableHead className="thead-dark">
                            <TableRow>
                                <TableCell align='left'>
                                    <Typography sx={{fontSize: 15}}>
                                        Referred by/References : <span className='BorderSpan'></span>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align='left'>
                                    <Typography sx={{fontSize: 15}}>
                                        Screened by me. Certificate / Details Verified / Not verified.
                                        <span className='BorderSpan'></span><br/>
                                        Referred to Mr.<span className='BorderSpan'></span> for/on the job trial. Suitable for employment as 
                                        <span className='BorderSpan'></span>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align='left'>
                                    <Typography sx={{fontSize: 18, textAlign: 'center', textTransform: 'uppercase', borderTop: '2px solid #000'}}>
                                        <b>Trial Report</b>
                                    </Typography>
                                    <Typography sx={{fontSize: 15}}>
                                        <span className='BorderSpan' style={{minWidth: '100%'}}></span><br/>
                                        <span className='BorderSpan' style={{minWidth: '100%'}}></span><br/>
                                        Seen and briefed the Safely Rules of the site.<br/>
                                        <span className='BorderSpan' style={{minWidth: '100%'}}></span>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align='right'>
                                    <Typography sx={{textDecoration: 'underline'}}>Safety Engineer / Safely Co-ordinator</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align='left'>
                                    <Typography sx={{fontSize: 15}}>
                                        Approved for employment by M/s.
                                        <span className='BorderSpan'></span>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align='left'>
                                    <Typography sx={{fontSize: 15}}>
                                        Details entered in to Register of Workmen
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align='right'>
                                    <b>Time Keeper</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </div>
            </Card>
        </Box>
    );

}