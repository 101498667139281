import React, { useState,useEffect } from "react";
import {
    Grid, Typography, Button
} from "@mui/material";
import PropTypes from 'prop-types';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AttendanceActions, GeneralActions } from "slices/actions";
import { toast } from "react-toastify";
import { GeneralSelector } from "selectors";

SubContractorUploadDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function SubContractorUploadDialog(props) {
    const { open, onClose } = props
    const dispatch = useDispatch();
    const [document, setDocument] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const success = useSelector(GeneralSelector.success(AttendanceActions.attendanceBulkUpload.type));

    const handleChangeAttendanceDocument = (e) => {
       setIsFilePicked(true);
        const file1 = e.target.files[0];
        const objectUrl = URL.createObjectURL(file1);
        console.log(objectUrl.nam);
        setDocument(file1)
     }
     useEffect(() => {
        if (success) {
           toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true
                });
      
            dispatch(GeneralActions.removeSuccess(AttendanceActions.updateAttendanceDocument.type));
            onClose();
          }
      }, [success]);


    const onSubmit = () => {
        if (document === 'undefined') {
            toast.success(`Upload doc`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: false,
                pauseOnFocusLoss: false
            });
            return;
        }
        const params = {
            bulkUpload: document
        }
        dispatch(AttendanceActions.attendanceBulkUpload(params))
    }
    return (
        <div className="IdcardDialog">
            <BootstrapDialog
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={onClose} sx={{ borderBottom: '1px solid #ccc' }}>
                    {/* <img src={`${process.env.REACT_APP_URL}/img/construction.svg`} style={{width: '80px', height: '50px', objectFit: 'contain'}} /> */}
                    {/* <svg ref={inputRef} className="barcodesvg" /> */}
                    Bulk Upload Subcontractor Data
                </DialogTitle>
                <DialogContent>
                    <form>
                        <Grid container spacing={2} sx={{ pt: 4 }}>
                            <Grid item lg={12} sm={12} xs={12}>
                                <label htmlFor="profile2" className="cursor-pointer w-100">
                                    <input style={{ display: 'none' }}
                                        type="file"
                                        id="profile2"
                                        accept=".xlsx, .xls"
                                        onChange={handleChangeAttendanceDocument}
                                    />
                                   
                                    {/* <img className="Healthuploadfile"
                                        alt="profile2"
                                        src={preview}
                                    /> */}
                                    <Typography className="uploadimglabel">
                                        <AddIcon />
                                        <span style={{ display: 'block' }}>Upload Document</span>
                                    </Typography>
                                </label>
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12}>
                                {isFilePicked ? (
                                        <div>
                                            <Typography sx={{m: 0, fontSize: '15px'}}>Filename: {document.name}</Typography>
                                           
                                            <Typography sx={{m: 0, fontSize: '15px'}}>Size in bytes: {document.size}</Typography>
                                           
                                        </div>
                                    ) : (
                                        <Typography className="text-center" sx={{m: 0, fontSize: '15px'}}>Upload Subcontractor data file</Typography>
                                    )}
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12} sx={{ py: 2, textAlign: 'center' }}>
                                <Link to="/subcontractorBulkData.xlsx" target="_blank">Download Sample Format</Link>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={onClose} variant="contained" color="primary">
                        Close
                    </Button>
                    <Button autoFocus onClick={onSubmit} variant="contained" color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )

}