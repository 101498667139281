import {
    InsertDriveFileOutlined, VerifiedOutlined, FitnessCenterOutlined, SafetyCheckOutlined, BadgeOutlined, PermPhoneMsgOutlined, SchoolOutlined, PostAddOutlined, AccountBalanceOutlined, HealthAndSafetyOutlined, 
    // FolderSharedOutlined,
     ViewAgendaOutlined, DownhillSkiing,TransformOutlined
} from "@mui/icons-material";

import BasicInfo from "../Component/AboutBasicInfoDetails/form/BasicInfo";
import KYC from "../Component/AboutBasicInfoDetails/form/Kyc/KYC";
import EmergencyContactDetails from "../Component/AboutBasicInfoDetails/form/EmergencyContactDetails";
import PFInformation from "../Component/AboutBasicInfoDetails/form/PFInformation/PFInformation";
import BankAccount from "../Component/AboutBasicInfoDetails/form/BankAccount";
import Health from "../Component/AboutBasicInfoDetails/form/Health/HealthListing";
import EducationListing from "../Component/AboutBasicInfoDetails/form/Education/EducationListing";
import Wages from "../Component/AboutBasicInfoDetails/form/Wages/Wages";
import IdCard from "../Component/AboutBasicInfoDetails/form/IdCard/IdCard";
import History from "../Component/AboutBasicInfoDetails/form/History";
import SafetyTrainingListing from "../Component/AboutBasicInfoDetails/form/SafetyTraining/SafetyTrainingListing";
import Fitness from "../Component/AboutBasicInfoDetails/form/Fitness";
import SkillInfoDetails from "../Component/AboutBasicInfoDetails/form/SkillInfoDetails";
import Proforma from "../Component/AboutBasicInfoDetails/form/Proforma";

const menus = [
    {
        id: 'basic',
        name: 'Basic Info',
        icon: InsertDriveFileOutlined,
        component: BasicInfo,
        code: "BasicInfo"

    },
    {
        id: 'skillInfo',
        name: 'Skill Info',
        icon: DownhillSkiing,
        component: SkillInfoDetails,
        code: "SkillInfo"
    },
    {
        id: 'kyc',
        name: 'KYC',
        icon: VerifiedOutlined,
        component: KYC,
        code: "Kyc"
    },
    {
        id: 'safetytraing',
        name: 'Safety Training',
        icon: SafetyCheckOutlined,
        component: SafetyTrainingListing,
        code: "SafetyTraining"
    },
    {
        id: 'emergency',
        name: 'Emergency Contact',
        icon: PermPhoneMsgOutlined,
        component: EmergencyContactDetails,
        code: "Emergencycontact"
    },
    {
        id: 'educaEducationListingtion',
        name: 'Education',
        icon: SchoolOutlined,
        component: EducationListing,
        code: "Education"
    },
   
    {
        id: 'pfinformation ',
        name: 'PF Information',
        icon: PostAddOutlined,
        component: PFInformation,
        code: "PFinformation"
    },
    {
        id: 'bankaccount ',
        name: 'Bank Account',
        icon: AccountBalanceOutlined,
        component: BankAccount,
        code: "Bankaccount"
    },
    {
        id: 'health ',
        name: 'Health',
        icon: HealthAndSafetyOutlined,
        component: Health,
        code: "Health"
    },
    {
        id: 'fitness ',
        name: 'Fitness',
        icon: FitnessCenterOutlined,
        component: Fitness,
        code: "Fitness"
    },
    {
        id: 'idcard ',
        name: 'ID Card',
        icon: BadgeOutlined,
        component: IdCard,
        code: "Idcard"
    },
    // {
    //     id: 'others ',
    //     name: 'Others',
    //     icon: FolderSharedOutlined,
    //     component: Others,
    //     code: "Others"
    // },
    {
        id: 'wages ',
        name: 'Wages',
        icon: ViewAgendaOutlined,
        component: Wages,
        code: "wages"
    },
    {
        id: 'history',
        name: 'History',
        icon: InsertDriveFileOutlined,
        component: History,
        code: "History"
    },
    {
        id: 'proforma',
        name: 'Proforma',
        icon: TransformOutlined,
        component: Proforma,
        code: "Proforma"
    },
];

export default menus;