import React, { useState } from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, InputBase, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {LabourActions } from "slices/actions";
import { useDispatch,useSelector} from "react-redux";
import { GeneralSelector } from "selectors";

ChangeDateDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    extendIdCardList: PropTypes.array
};

export default function ChangeDateDialog(props) {
    const dispatch = useDispatch();
    const {open,onClose,extendIdCardList} = props;
    const [date,setDate] = useState();
    const [errorMsg,setErrorMsg] = useState();
    const loading = useSelector(GeneralSelector.loader(LabourActions.updateIdCardValidationDate.type));

   const onSubmit = () => {
        if (date) {
            const params = {
                extendIdCardList: JSON.stringify(extendIdCardList),
                date: date 
            }
            dispatch(LabourActions.updateIdCardValidationDate(params))
        } else {
            setErrorMsg("Please enter date")
        }
        
    }
    
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <DialogContentText>
                <Typography>Are you sure ?</Typography>
                {/* <TextField
                    id="date"
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    sx={{ width: 220 ,mt: 2}}
                    InputLabelProps={{
                    shrink: true,
                    }}
                /> */}
                        <InputBase
                            className="text-primary"
                            type="date"
                            value={date}
                            sx={{ width: 220 ,mt: 2}}
                            onChange={(e) => {
                                setErrorMsg("")
                                setDate(e.target.value)
                            }
                            }
                            />
                <Typography sx={{color: "red"}}>{errorMsg}</Typography> 
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>No</Button>
                <Button onClick={onSubmit} autoFocus variant="contained" color="primary">
                      {loading?<CircularProgress color="inherit" size={20}/>:<>Renew</>}
                </Button>
            </DialogActions>
        </Dialog>
    )
}